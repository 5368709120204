import { createContext, ReactNode } from 'react';
import { StateSetter } from '../../../../../../types/utils/React';
import { emptyFunction } from '../../../../../../utils/Functions';
import { SharedDocumentType } from '../../../../../../types/document/Document';

export type DeleteModeContextProps = {
  documentsToDelete: SharedDocumentType[];
  isDeleteMode: boolean;
  setDocumentsToDelete: StateSetter<SharedDocumentType[]>;
  setIsDeleteMode: StateSetter<boolean>;
};

const initialState = {
  documentsToDelete: [],
  isDeleteMode: false,
  setDocumentsToDelete: emptyFunction,
  setIsDeleteMode: emptyFunction,
};

const deleteModeContext = createContext<DeleteModeContextProps>(initialState),
  { Provider } = deleteModeContext;

type DeleteModeContextProviderProps = {
  children: ReactNode;
  documentsToDelete: SharedDocumentType[];
  isDeleteMode: boolean;
  setDocumentsToDelete: StateSetter<SharedDocumentType[]>;
  setIsDeleteMode: StateSetter<boolean>;
};

const DeleteModeContextProvider = ({
  children,
  documentsToDelete,
  isDeleteMode,
  setDocumentsToDelete,
  setIsDeleteMode,
}: DeleteModeContextProviderProps): JSX.Element => (
  <Provider
    value={{
      documentsToDelete,
      isDeleteMode,
      setDocumentsToDelete,
      setIsDeleteMode,
    }}
  >
    {children}
  </Provider>
);

export { deleteModeContext, DeleteModeContextProvider };
