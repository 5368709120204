import { useContext } from 'react';
import { SharedDocumentType } from '../../types/document/Document';
import Image from '../Image/Image';
import useStyles from './ImageViewerItem.styles';
import { imageViewerContext, SlideDirectionType } from './ImageViewerContext';
import { Slide } from '@mui/material';
import clsx from 'clsx';

type ImageViewerItemPureProps = {
  hidden: boolean;
  image: SharedDocumentType;
  slideDirection: SlideDirectionType;
};

const ImageViewerItemPure = ({
  hidden,
  image,
  slideDirection,
}: ImageViewerItemPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Slide direction={slideDirection} in={!hidden} timeout={500}>
      <div className={classes.container}>
        <Image
          className={clsx([classes.image, hidden && classes.hidden])}
          src={image.blob.s3URL}
        />
      </div>
    </Slide>
  );
};

type ImageViewerItemProps = {
  image: SharedDocumentType;
};

const ImageViewerItem = ({ image }: ImageViewerItemProps): JSX.Element => {
  const { currentViewedImage, slideDirection } = useContext(imageViewerContext);
  const hidden = currentViewedImage?.id !== image.id;

  return (
    <ImageViewerItemPure
      hidden={hidden}
      image={image}
      slideDirection={slideDirection}
    />
  );
};

export default ImageViewerItem;
