import { gql } from '@apollo/client';
import { WebUserContactType, WebUserType } from '../../types/User';

export const LINK_FRAGMENT = gql`
  fragment linkFields on Link {
    channelKey
    isAdmin
    metadata {
      hasMinimalAndroidVersionForVideoCall
      hasMinimalArdoizCoreVersionForVideoCall
      subscriptionEnabled
    }
    type
    mainUser {
      id
      key
      firstName
      lastName
      emails
      postalAddress {
        address
        city
        zipcode
      }
    }
    selfWebUserId
    userKey
  }
`;

export const WEB_USER_FRAGMENT = gql`
  fragment webUserFields on WebUser {
    id
    firstName
    lastName
    links {
      ...linkFields
    }
    onBoardingCompleted
  }
  ${LINK_FRAGMENT}
`;

export const WEB_USER_CONTACT_FRAGMENT = gql`
  fragment webUserContactFields on WebUser {
    id
    firstName
    lastName
  }
`;

export const GET_ME_QUERY = gql`
  query GetMe {
    me {
      user {
        ...webUserFields
      }
    }
  }
  ${WEB_USER_FRAGMENT}
`;

export type GetMeOutputType = {
  me: {
    user: WebUserType;
  };
};

export const GET_CONTACTS_BY_CHANNEL_QUERY = gql`
  query GetContactsByChannel($channel: String!) {
    webUsers(channel: $channel) {
      errorReason
      webUsers {
        ...webUserContactFields
        __typename
      }
      __typename
    }
  }
  ${WEB_USER_CONTACT_FRAGMENT}
`;

export enum WenUserErrorReason {
  UNAUTHORIZED,
}

export type GetContactsByChannelOutputType = {
  webUsers: {
    errorReason: WenUserErrorReason;
    webUsers: WebUserContactType[];
  };
};
