import { createContext, ReactNode } from 'react';
import { StateSetter } from '../../../types/utils/React';
import { emptyFunction } from '../../../utils/Functions';
import { DayContentModeEnum } from '../../../types/organize/Organizer';
import { EventType } from '../../../types/event/Event';

export type OrganizerContextProps = {
  dayContentMode?: DayContentModeEnum;
  eventToDelete?: EventType;
  eventToEdit?: EventType;
  setDayContentMode: StateSetter<DayContentModeEnum | undefined>;
  setEventToDelete: StateSetter<EventType | undefined>;
  setEventToEdit: StateSetter<EventType | undefined>;
};

const initialState = {
  dayContentMode: undefined,
  eventToDelete: undefined,
  eventToEdit: undefined,
  setDayContentMode: emptyFunction,
  setEventToDelete: emptyFunction,
  setEventToEdit: emptyFunction,
};

const organizerContext = createContext<OrganizerContextProps>(initialState),
  { Provider } = organizerContext;

type OrganizerContextProviderProps = OrganizerContextProps & {
  children: ReactNode;
};

const OrganizerContextProvider = ({
  children,
  ...props
}: OrganizerContextProviderProps): JSX.Element => (
  <Provider value={props}>{children}</Provider>
);

export { organizerContext, OrganizerContextProvider };
