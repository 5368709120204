import { createContext, ReactNode, useCallback } from 'react';
import { MessageType } from '../../../../types/Message';
import { emptyFunction } from '../../../../utils/Functions';
import { StateSetter } from '../../../../types/utils/React';

export type UpdateSelectedMessagesType = (
  message: MessageType,
  isAdd: boolean,
) => void;

export type AdminMessagesContextProps = {
  selectedMessages: MessageType[];
  resetSelectedMessages: () => void;
  setSelectedMessages: StateSetter<MessageType[]>;
  updateSelectedMessages: UpdateSelectedMessagesType;
};

const initialState = {
  selectedMessages: [],
  resetSelectedMessages: emptyFunction,
  setSelectedMessages: emptyFunction,
  updateSelectedMessages: emptyFunction,
};

const adminMessagesContext =
    createContext<AdminMessagesContextProps>(initialState),
  { Provider } = adminMessagesContext;

type AdminMessagesContextProviderProps = {
  children: ReactNode;
  selectedMessages: MessageType[];
  setSelectedMessages: StateSetter<MessageType[]>;
};

const AdminMessagesContextProvider = ({
  children,
  selectedMessages,
  setSelectedMessages,
}: AdminMessagesContextProviderProps): JSX.Element => {
  const updateSelectedMessages = useCallback(
    (message: MessageType, isAdd: boolean) => {
      if (isAdd) {
        setSelectedMessages([...selectedMessages, message]);
      } else {
        const updatedSelectedMessages = selectedMessages.filter(
          (_) => _.id !== message.id,
        );
        setSelectedMessages(updatedSelectedMessages);
      }
    },
    [selectedMessages, setSelectedMessages],
  );

  const resetSelectedMessages = useCallback(
    () => setSelectedMessages([]),
    [setSelectedMessages],
  );

  return (
    <Provider
      value={{
        selectedMessages,
        resetSelectedMessages,
        setSelectedMessages,
        updateSelectedMessages,
      }}
    >
      {children}
    </Provider>
  );
};

export { adminMessagesContext, AdminMessagesContextProvider };
