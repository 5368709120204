import { ChangeEvent, useCallback, useContext } from 'react';
import { eventFormContext } from '../EventFormContext';
import useStyles from './OccurrencesNumberField.styles';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { RecurrenceTypeEnum } from '../../../../../../types/event/Event';

const MIN_OCCURRENCES_VALUE = 2;

type OccurrencesNumberFieldPureProps = {
  disabled: boolean;
  error?: string;
  minValue: number;
  occurrences?: number;
  handleOccurrencesChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const OccurrencesNumberFieldPure = ({
  disabled,
  error,
  minValue,
  occurrences,
  handleOccurrencesChange,
}: OccurrencesNumberFieldPureProps): JSX.Element => {
  const classes = useStyles();
  const inError = !!error;
  const inErrorFieldClassName = inError && classes.inErrorField;

  return (
    <>
      <input
        className={clsx([classes.field, inErrorFieldClassName])}
        disabled={disabled}
        min={minValue}
        step={1}
        type="number"
        value={occurrences}
        onChange={handleOccurrencesChange}
      />

      <span className={classes.occurrenceLabel}>
        <FormattedMessage id="event.form.occurrences" />
        {!disabled && <sup>*</sup>}
      </span>

      {inError && (
        <div className={classes.inErrorLabel}>
          <FormattedMessage id={error} />
        </div>
      )}
    </>
  );
};

const OccurrencesNumberField = (): JSX.Element | null => {
  const {
    daysOfRepeat,
    disabled,
    occurrences,
    recurrenceType,
    setOccurrences,
  } = useContext(eventFormContext);
  const { eventFormErrors, setEventFormErrors } = useContext(eventFormContext);

  const handleOccurrencesChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = parseInt(e.target.value);
      setOccurrences(newValue);

      setEventFormErrors({
        ...eventFormErrors,
        occurrences: undefined,
      });
    },
    [eventFormErrors, setEventFormErrors, setOccurrences],
  );

  if (recurrenceType !== RecurrenceTypeEnum.AFTER) {
    return null;
  }

  const daysOfRepeatCount = daysOfRepeat.filter((_) => _).length;
  const minValue =
    daysOfRepeatCount > MIN_OCCURRENCES_VALUE
      ? daysOfRepeatCount
      : MIN_OCCURRENCES_VALUE;

  return (
    <OccurrencesNumberFieldPure
      disabled={disabled}
      error={eventFormErrors.occurrences}
      minValue={minValue}
      occurrences={occurrences}
      handleOccurrencesChange={handleOccurrencesChange}
    />
  );
};

export default OccurrencesNumberField;
