import useStyles from './Header.styles';
import ProfileButton from '../Profile/ProfileButton';
import { UseStylesProps } from '../../utils/useStyles';
import ChannelLinkSelect from '../ChannelLink/ChannelLinkSelect';
import { Container, Grid } from '@mui/material';
import useIsSmallScreen from '../../utils/useIsSmallScreen';
import MobileHeader from '../../mobile/App/MobileHeader';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import { isSelfLink } from '../../utils/Link';

type HeaderPureProps = UseStylesProps;

const HeaderPure = ({ classes }: HeaderPureProps): JSX.Element => (
  <Container>
    <div className={classes.container}>
      <Grid alignItems="center" className={classes.profileContainer} container>
        <Grid className={classes.gridItem} item md={8} xs={12}>
          <ChannelLinkSelect />
        </Grid>
        <Grid className={classes.gridItem} item md={4} xs={12}>
          <ProfileButton />
        </Grid>
      </Grid>
    </div>
  </Container>
);

const Header = (): JSX.Element | null => {
  const classes = useStyles();
  const isSmallScreen = useIsSmallScreen();
  const { selectedLink } = useSelectedLinkContext();

  if (isSelfLink(selectedLink)) {
    return null;
  } else if (isSmallScreen) {
    return <MobileHeader classes={classes} />;
  }

  return <HeaderPure classes={classes} />;
};

export default Header;
