import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';
import useSettingsModalStyles from './SettingsModal.styles';
import { shareContext } from '../../ShareContext';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { settingsContext } from './SettingsContext';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import MobileExplorerToolbarMenuItemButton from '../../../../../mobile/App/Share/ExplorerToolbar/MobileExplorerToolbarMenuItemButton';

type DeleteOrQuitFolderButtonPureProps = {
  labelId: string;
  handleClick: () => void;
};

const DeleteOrQuitFolderButtonPure = ({
  labelId,
  handleClick,
}: DeleteOrQuitFolderButtonPureProps): JSX.Element => {
  const settingsModalClasses = useSettingsModalStyles();

  return (
    <FilledButton
      className={settingsModalClasses.button}
      color="violet"
      data-test-id="delete-document-button"
      onClick={handleClick}
    >
      <FormattedMessage
        data-test-id="delete-document-button-label"
        id={labelId}
      />
    </FilledButton>
  );
};

const getLabelId = (isFolderAdmin: boolean) =>
  isFolderAdmin
    ? 'share.folder.settings.deleteFolder'
    : 'share.folder.settings.quitFolder';

const DeleteOrQuitFolderButton = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { selectedFolder } = useContext(shareContext);
  const isFolderAdmin = selectedFolder?.creator === selectedLink?.userKey;
  const { setShowDeleteOrQuitModal, setShowSettingsModal } =
    useContext(settingsContext);
  const isSmallScreen = useIsSmallScreen();

  const handleClick = useCallback(() => {
    setShowSettingsModal(false);
    setShowDeleteOrQuitModal(true);
  }, [setShowDeleteOrQuitModal, setShowSettingsModal]);

  const labelId = getLabelId(isFolderAdmin);

  if (isSmallScreen) {
    return (
      <MobileExplorerToolbarMenuItemButton
        messageId={labelId}
        onClick={handleClick}
      />
    );
  }

  return (
    <DeleteOrQuitFolderButtonPure labelId={labelId} handleClick={handleClick} />
  );
};

export default DeleteOrQuitFolderButton;
