import React, { useCallback, useContext, useState } from 'react';
import { Button } from '@mui/material';
import ContactsIcon from '../../../../../images/Share/folder_contacts.svg';
import Image from '../../../../../components/Image/Image';
import useStyles from './EditContactsButton.styles';
import EditContactsModal from './EditContactsModal';
import ContactsCountBadge from './ContactsCountBadge';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { shareContext } from '../../ShareContext';
import { EditContactsContextProvider } from './EditContactsContext';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import MobileEditContactsButton from '../../../../../mobile/App/Share/ExplorerToolbar/EditContacts/MobileEditContactsButton';

type EditContactsButtonPureProps = {
  handleShowModal: () => void;
};

const EditContactsButtonPure = ({
  handleShowModal,
}: EditContactsButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        data-test-id="open-settings-modal-button"
        onClick={handleShowModal}
      >
        <ContactsCountBadge>
          <Image src={ContactsIcon} height={48} />
        </ContactsCountBadge>
      </Button>

      <EditContactsModal />
    </>
  );
};

const EditContactsButton = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { selectedFolder } = useContext(shareContext);
  const isSmallScreen = useIsSmallScreen();

  const [contacts, setContacts] = React.useState<string[]>(
    selectedFolder?.sharedWith.map((_) => _.id) || [],
  );
  const isFolderAdmin = selectedFolder?.creator === selectedLink?.userKey;
  const [showEditContactsModal, setShowEditContactsModal] =
    useState<boolean>(false);

  const handleShowModal = useCallback(
    () => setShowEditContactsModal(true),
    [setShowEditContactsModal],
  );

  if (isFolderAdmin) {
    if (isSmallScreen) {
      return <MobileEditContactsButton />;
    } else {
      return (
        <EditContactsContextProvider
          contacts={contacts}
          showEditContactsModal={showEditContactsModal}
          setContacts={setContacts}
          setShowEditContactsModal={setShowEditContactsModal}
        >
          <EditContactsButtonPure handleShowModal={handleShowModal} />
        </EditContactsContextProvider>
      );
    }
  }

  return null;
};

export default EditContactsButton;
