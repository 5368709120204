import { useContext } from 'react';
import { shareContext } from './ShareContext';
import { deleteModeContext } from './ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';
import AddDocumentButton from './ExplorerToolbar/AddDocument/AddDocumentButton';
import OpenSettingsModalButton from './ExplorerToolbar/SettingsModal/OpenSettingsModalButton';
import OpenCreateFolderModalButton from './ExplorerToolbar/CreateFolder/OpenCreateFolderModalButton';
import ExitDeleteModeButton from './ExplorerToolbar/DeleteFiles/ExitDeleteModeButton';
import DeleteFilesButton from './ExplorerToolbar/DeleteFiles/DeleteFilesButton';
import EditContactsButton from './ExplorerToolbar/EditContacts/EditContactsButton';
import { FolderType } from '../../../types/document/Document';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';
import MobileExplorerToolbar from '../../../mobile/App/Share/MobileExplorerToolbar';
import MobileFolderContentSettings from '../../../mobile/App/Share/ExplorerToolbar/MobileFolderContentSettings';
import MobileOpenCreateFolderModalButton from '../../../mobile/App/Share/ExplorerToolbar/CreateFolder/MobileOpenCreateFolderModalButton';

type GetVisibleComponentsInputType = {
  isDeleteMode: boolean;
  isSmallScreen: boolean;
  selectedFolder: FolderType | undefined;
};

const getToolbar = ({
  isDeleteMode,
  isSmallScreen,
  selectedFolder,
}: GetVisibleComponentsInputType): JSX.Element => {
  if (selectedFolder) {
    if (isDeleteMode) {
      return (
        <>
          <DeleteFilesButton key="DeleteFilesButton" />
          <ExitDeleteModeButton key="ExitDeleteModeButton" />
        </>
      );
    } else {
      if (isSmallScreen) {
        return (
          <MobileFolderContentSettings key="MobileFolderContentSettings" />
        );
      } else {
        return (
          <>
            <AddDocumentButton key="AddDocumentButton" />
            <EditContactsButton key="EditContactsButton" />
            <OpenSettingsModalButton key="OpenSettingsModalButton" />
          </>
        );
      }
    }
  } else {
    if (isSmallScreen) {
      return (
        <MobileExplorerToolbar
          visibleComponents={[
            <MobileOpenCreateFolderModalButton key="MobileOpenCreateFolderModalButton" />,
          ]}
        />
      );
    } else {
      return <OpenCreateFolderModalButton />;
    }
  }
};

const ExplorerToolbar = (): JSX.Element => {
  const { selectedFolder } = useContext(shareContext);
  const { isDeleteMode } = useContext(deleteModeContext);
  const isSmallScreen = useIsSmallScreen();

  return getToolbar({
    isDeleteMode,
    isSmallScreen,
    selectedFolder,
  });
};

export default ExplorerToolbar;
