declare global {
  interface Window {
    ALWAYS_SHOW_ONBOARDING: boolean;
    ArdoizVisioInterface: {
      hangup: Function;
    };
    DISABLE_INCOMING_CALL: boolean;
    ENABLE_NOTIFICATIONS: boolean;
    ga: any;
    GA_TRACKER_ID: string;
    HIBOU_URL: string;
    INCOMING_CALL_POLLING_INTERVAL: number;
    LEGACY_FAMILY_PORTAL_URL: string;
    SHOW_ORGANIZE_PAGE: boolean;
    SHOW_SHARE_PAGE: boolean;
    terminateVideoCallFromDevice: Function;
    VIDEO_CALL_CALLING_IMAGE_CHANGE_DELAY: number;
    VIDEO_CALL_DOMAIN: string;
    VIDEO_CALL_ERROR_REDIRECT_TIMEOUT: number;
    VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT: number;
    VIDEO_CALL_POLLING_INTERVAL: number;
  }
}

export const HIBOU_URL = `${window.HIBOU_URL || ''}/hibou`;

export const ALWAYS_SHOW_ONBOARDING = window.ALWAYS_SHOW_ONBOARDING;

export const DISABLE_INCOMING_CALL = window.DISABLE_INCOMING_CALL;
export const INCOMING_CALL_POLLING_INTERVAL =
  window.INCOMING_CALL_POLLING_INTERVAL;
export const VIDEO_CALL_DOMAIN = window.VIDEO_CALL_DOMAIN;
export const VIDEO_CALL_ERROR_REDIRECT_TIMEOUT =
  window.VIDEO_CALL_ERROR_REDIRECT_TIMEOUT;
export const VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT =
  window.VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT;
export const VIDEO_CALL_CALLING_IMAGE_CHANGE_DELAY =
  window.VIDEO_CALL_CALLING_IMAGE_CHANGE_DELAY;
export const VIDEO_CALL_POLLING_INTERVAL = window.VIDEO_CALL_POLLING_INTERVAL;

window.ArdoizVisioInterface = window.ArdoizVisioInterface || {
  hangup: () => console.error('No ArdoizVisioInterface is defined !'),
};
export const ArdoizVisioInterface = window.ArdoizVisioInterface;

export const LEGACY_FAMILY_PORTAL_URL = window.LEGACY_FAMILY_PORTAL_URL;

export const GA_TRACKER_ID = window.GA_TRACKER_ID;

export const SHOW_SHARE_PAGE = window.SHOW_SHARE_PAGE;

export const SHOW_ORGANIZE_PAGE = window.SHOW_ORGANIZE_PAGE;

export const ENABLE_NOTIFICATIONS = window.ENABLE_NOTIFICATIONS;
