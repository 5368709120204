import { gql } from '@apollo/client';
import { WEB_USER_FRAGMENT } from './WebUserQueries';
import { WebUserType } from '../../types/User';

export const UPDATE_ONBOARDING_COMPLETION_MUTATION = gql`
  mutation updateOnBoardingCompletionMutation(
    $input: UpdateOnBoardingCompletionInput!
  ) {
    updateOnBoardingCompletion(input: $input) {
      me {
        ...webUserFields
      }
    }
  }
  ${WEB_USER_FRAGMENT}
`;

export type UpdateOnBoardingCompletionOutputType = {
  updateOnBoardingCompletion: {
    me: WebUserType;
  };
};
