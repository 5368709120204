import { FormattedMessage } from 'react-intl';
import { Alert } from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  GET_FILTERED_MESSAGES_QUERY,
  GET_RECEIVED_MESSAGES_QUERY,
  GET_SENT_MESSAGES_QUERY,
  GetFilteredMessagesOutputType,
  GetReceivedMessagesOutputType,
  GetSentMessagesOutputType,
} from '../../../../../graphql/admin/adminmessage/AdminMessageQueries';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import AdminMessagesTable from './AdminMessagesTable';

export enum AdminMessagesTableDataTypeEnum {
  FILTERED,
  RECEIVED,
  SENT,
}

const dataQueryError = (
  <Alert severity="error">
    <FormattedMessage id="admin.messages.error.query" />
  </Alert>
);

export const AdminMessagesTableFilteredData = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();

  const { data, loading } = useQuery<GetFilteredMessagesOutputType>(
    GET_FILTERED_MESSAGES_QUERY,
    {
      variables: {
        getFilteredMessagesInput: {
          channel: selectedLink?.channelKey,
        },
      },
    },
  );

  if (!data || loading) {
    return null;
  }

  const resultType = data.getFilteredMessages.__typename;

  if (resultType === 'GetFilteredMessagesErrorOutput') {
    return dataQueryError;
  }

  const allMessages = data.getFilteredMessages.messages;

  return (
    <AdminMessagesTable
      allMessages={allMessages}
      type={AdminMessagesTableDataTypeEnum.FILTERED}
    />
  );
};

export const AdminMessagesTableReceivedData = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();

  const { data, loading } = useQuery<GetReceivedMessagesOutputType>(
    GET_RECEIVED_MESSAGES_QUERY,
    {
      variables: {
        getReceivedMessagesInput: {
          channel: selectedLink?.channelKey,
        },
      },
    },
  );

  if (!data || loading) {
    return null;
  }

  const resultType = data.getReceivedMessages.__typename;

  if (resultType === 'GetReceivedMessagesErrorOutput') {
    return dataQueryError;
  }

  const allMessages = data.getReceivedMessages.messages;

  return (
    <AdminMessagesTable
      allMessages={allMessages}
      type={AdminMessagesTableDataTypeEnum.RECEIVED}
    />
  );
};

export const AdminMessagesTableSentData = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();

  const { data, loading } = useQuery<GetSentMessagesOutputType>(
    GET_SENT_MESSAGES_QUERY,
    {
      variables: {
        getSentMessagesInput: {
          channel: selectedLink?.channelKey,
        },
      },
    },
  );

  if (!data || loading) {
    return null;
  }

  const resultType = data.getSentMessages.__typename;

  if (resultType === 'GetSentMessagesErrorOutput') {
    return dataQueryError;
  }

  const allMessages = data.getSentMessages.messages;

  return (
    <AdminMessagesTable
      allMessages={allMessages}
      type={AdminMessagesTableDataTypeEnum.SENT}
    />
  );
};
