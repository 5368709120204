import { ReactNode, useCallback, useContext, useState } from 'react';
import PageContentHeader from '../../components/PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import PageContentContainer from '../../components/PageContent/PageContentContainer';
import { Grid } from '@mui/material';
import FileTypeSwitcher from './Share/FileTypeSwitcher';
import ShareExplorer from './Share/ShareExplorer';
import useStyles from './Share.styles';
import BuildingPage from '../../components/BuildingPage/BuildingPage';
import { SHOW_SHARE_PAGE } from '../../utils/constants';
import { ShareContextProvider } from './Share/ShareContext';
import { FileTypeSwitcherFileTypes } from '../../types/share/FileTypeSwitcherFileTypes';
import { useMutation, useQuery } from '@apollo/client';
import {
  DOCUMENT_FRAGMENT,
  GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
  GetVisibleFoldersFromFolderTypeQueryOutputType,
} from '../../graphql/document/DocumentQueries';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import { FolderType, SharedDocumentType } from '../../types/document/Document';
import {
  getAcceptedFiles,
  getSortedFolders,
  sendDroppedFiles,
} from './Share/Helpers';
import ExplorerHeader from './Share/ExplorerHeader';
import { useDropzone } from 'react-dropzone';
import { alertContext } from '../../components/Alert/AlertContext';
import { useUploadFileToS3 } from '../../utils/S3Upload';
import {
  ADD_DOCUMENT_MUTATION,
  AddDocumentMutationOutputType,
} from '../../graphql/document/DocumentMutations';
import DocumentOperationResultsModal from './Share/DocumentOperationResultsModal';
import IndeterminateLoading from '../../components/Loading/IndeterminateLoading';
import { DeleteModeContextProvider } from './Share/ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';
import ExplorerToolbar from './Share/ExplorerToolbar';
import { ResultItemProps } from './Share/DocumentOperationResultsModal/ResultItem';
import { MAX_UPLOADED_DOCUMENT_SIZE } from '../../utils/Document';
import { emptyFunction } from '../../utils/Functions';

const Share = (): JSX.Element => {
  const classes = useStyles();
  const { selectedLink } = useSelectedLinkContext();

  const [selectedFileType, setSelectedFileType] =
    useState<FileTypeSwitcherFileTypes>(
      FileTypeSwitcherFileTypes.PICTURE_AND_VIDEO,
    );

  const [loadingMessage, setLoadingMessage] = useState<ReactNode>();

  const [selectedFolder, setSelectedFolder] = useState<FolderType>();

  const [documentOperationResults, setDocumentOperationResults] = useState<
    ResultItemProps[]
  >([]);

  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);

  const [documentsToDelete, setDocumentsToDelete] = useState<
    SharedDocumentType[]
  >([]);

  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);

  const { uploadFileToS3 } = useUploadFileToS3({
    handleProgress: emptyFunction,
    showErrorMessage,
  });

  const [addDocument] = useMutation<AddDocumentMutationOutputType>(
    ADD_DOCUMENT_MUTATION,
    {
      update(cache, { data: updatedData }) {
        const document = updatedData?.addDocument.document;

        if (document) {
          cache.modify({
            fields: {
              visibleDocumentsFromFolder(visibleDocumentsFromFolderResponse) {
                const newDocumentRef = cache.writeFragment({
                  data: document,
                  fragment: DOCUMENT_FRAGMENT,
                  fragmentName: 'documentFields',
                });
                return {
                  ...visibleDocumentsFromFolderResponse,
                  documents: [
                    ...visibleDocumentsFromFolderResponse.documents,
                    newDocumentRef,
                  ],
                };
              },
            },
          });
        }
      },
    },
  );

  const handleSendDroppedFiles = sendDroppedFiles({
    selectedFolder,
    selectedLink,
    addDocument,
    setLoadingMessage,
    setDocumentOperationResults,
    showSuccessMessage,
    uploadFileToS3,
  });

  const handleFileDrop = useCallback(handleSendDroppedFiles, [
    handleSendDroppedFiles,
    selectedFolder?.key,
    selectedLink?.channelKey,
    addDocument,
    setLoadingMessage,
    setDocumentOperationResults,
    showErrorMessage,
    showSuccessMessage,
    uploadFileToS3,
  ]);

  const dropzoneState = useDropzone({
    accept: getAcceptedFiles(selectedFileType),
    maxSize: MAX_UPLOADED_DOCUMENT_SIZE,
    onDrop: handleFileDrop,
  });

  const { data } = useQuery<GetVisibleFoldersFromFolderTypeQueryOutputType>(
    GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
    {
      variables: {
        getVisibleFoldersByFolderTypeInput: {
          channel: selectedLink?.channelKey,
          folderType: selectedFileType,
        },
      },
    },
  );

  if (!SHOW_SHARE_PAGE) {
    return <BuildingPage />;
  }

  const sortedFolders = getSortedFolders(
    data?.visibleFoldersFromFolderType.folders,
  );

  const explorerHeaderXsValue = isDeleteMode ? 0 : 9;
  const explorerToolbarXsValue = isDeleteMode ? 12 : 3;

  return (
    <ShareContextProvider
      documentOperationResults={documentOperationResults}
      dropzoneState={dropzoneState}
      folders={sortedFolders}
      loadingMessage={loadingMessage}
      selectedFileType={selectedFileType}
      selectedFolder={selectedFolder}
      setDocumentOperationResults={setDocumentOperationResults}
      setLoadingMessage={setLoadingMessage}
      setSelectedFileType={setSelectedFileType}
      setSelectedFolder={setSelectedFolder}
    >
      <DeleteModeContextProvider
        documentsToDelete={documentsToDelete}
        isDeleteMode={isDeleteMode}
        setDocumentsToDelete={setDocumentsToDelete}
        setIsDeleteMode={setIsDeleteMode}
      >
        <PageContentContainer>
          <PageContentHeader
            title={<FormattedMessage id="share.description" />}
          />

          <IndeterminateLoading label={loadingMessage} />

          <Grid className={classes.container} container>
            <Grid item xs={12}>
              <FileTypeSwitcher />
            </Grid>

            <Grid
              className={classes.explorerHeader}
              item
              md={6}
              xs={explorerHeaderXsValue}
            >
              <ExplorerHeader />
            </Grid>

            <Grid
              className={classes.explorerToolbarContainer}
              item
              md={6}
              xs={explorerToolbarXsValue}
            >
              <ExplorerToolbar />
            </Grid>

            <Grid className={classes.explorerContainer} item xs={12}>
              <ShareExplorer />
            </Grid>
          </Grid>

          <DocumentOperationResultsModal />
        </PageContentContainer>
      </DeleteModeContextProvider>
    </ShareContextProvider>
  );
};

export default Share;
