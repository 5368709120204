import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    cursor: 'pointer',
    padding: 20,

    [theme.breakpoints.up('md')]: {
      width: 200,
    },

    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  subtitle: {
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  titlesContainer: {
    padding: '5px 10px',
  },
}));
