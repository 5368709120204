import { ReactNode, useCallback, useContext } from 'react';
import { TOUR_STEPS } from '../../../App/OnBoarding/OnBoardingTour';
import { Fade, MenuItem } from '@mui/material';
import Image from '../../../../components/Image/Image';
import useStyles from './Item.styles';
import { menuContext } from '../../../../Context/MenuContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { showNoWebUserLinkToast } from '../../../../utils/Profile';
import { alertContext } from '../../../../components/Alert/AlertContext';

type ItemPureProps = {
  icon: string;
  label: ReactNode;
  menuSelectedClassNames?: string;
  show: boolean;
  handleClick: Function;
};

const ItemPure = ({
  icon,
  label,
  menuSelectedClassNames,
  show,
  handleClick,
}: ItemPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Fade in={show}>
      <MenuItem
        className={menuSelectedClassNames}
        onClick={() => handleClick()}
      >
        <Image className={classes.icon} src={icon} width={25} />

        {label}
      </MenuItem>
    </Fade>
  );
};

type ItemProps = {
  disableIfNoSelectedLink: boolean;
  icon: string;
  labelMessageId: string;
  step?: TOUR_STEPS;
  goToPageFunction: Function;
};

const Item = ({
  disableIfNoSelectedLink,
  goToPageFunction,
  labelMessageId,
  step,
  ...props
}: ItemProps): JSX.Element | null => {
  const { getMenuSelectedClassNames, onMenuItemClick, shouldShowMenu } =
    useContext(menuContext);
  const { selectedLink } = useSelectedLinkContext();
  const label = <FormattedMessage id={labelMessageId} />;
  const intl = useIntl();
  const { showErrorMessage } = useContext(alertContext);

  const handleClick = useCallback(() => {
    if (disableIfNoSelectedLink && !selectedLink) {
      showNoWebUserLinkToast({ selectedLink, showErrorMessage });
    } else {
      trackEvent(
        GAEventCategory.MENU,
        GACommonEventAction.CLICK,
        intl.formatMessage({ id: labelMessageId }),
      );

      onMenuItemClick(goToPageFunction);
    }
  }, [
    disableIfNoSelectedLink,
    intl,
    labelMessageId,
    selectedLink,
    goToPageFunction,
    onMenuItemClick,
    showErrorMessage,
  ]);

  const show = shouldShowMenu(step);
  const menuSelectedClassNames = getMenuSelectedClassNames(step);

  return (
    <ItemPure
      label={label}
      menuSelectedClassNames={menuSelectedClassNames}
      show={show}
      handleClick={handleClick}
      {...props}
    />
  );
};

export default Item;
