import { useMutation, useQuery } from '@apollo/client';
import {
  GetWaitingNotificationAcknowledgeVideoCallOutputType,
  WAITING_NOTIFICATION_ACKNOWLEDGE_VIDEO_CALL_QUERY,
} from '../../../../graphql/communicate/videocall/VideoCallQueries';
import { INCOMING_CALL_POLLING_INTERVAL } from '../../../../utils/constants';
import {
  getVideoCallFromVideoCallQueryOutput,
  VideoCallState,
  VideoCallType,
} from '../../../../types/videocall/VideoCall';
import { useUserContext } from '../../../../Context/UserContext';
import {
  ACKNOWLEDGE_NOTIFICATION_VIDEO_CALL_MUTATION,
  AcknowledgeNotificationVideoCallOutputType,
} from '../../../../graphql/communicate/videocall/VideoCallMutations';
import { StateSetter } from '../../../../types/utils/React';

type WaitingIncomingCallProps = {
  setIncomingCall: StateSetter<VideoCallType | undefined>;
  setNotificationHasBeenAcknowledged: StateSetter<boolean>;
};

const WaitingIncomingCall = ({
  setIncomingCall,
  setNotificationHasBeenAcknowledged,
}: WaitingIncomingCallProps): null => {
  const { webUser } = useUserContext();
  const [acknowledgeNotificationVideoCall] =
    useMutation<AcknowledgeNotificationVideoCallOutputType>(
      ACKNOWLEDGE_NOTIFICATION_VIDEO_CALL_MUTATION,
    );

  useQuery<GetWaitingNotificationAcknowledgeVideoCallOutputType>(
    WAITING_NOTIFICATION_ACKNOWLEDGE_VIDEO_CALL_QUERY,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      pollInterval: INCOMING_CALL_POLLING_INTERVAL,
      onCompleted: (data) => {
        const newIncomingCall = getVideoCallFromVideoCallQueryOutput(
          data?.waitingNotificationAcknowledgeVideoCall?.videoCall,
          webUser,
        );

        if (newIncomingCall) {
          setIncomingCall(newIncomingCall);

          acknowledgeNotificationVideoCall({
            variables: {
              videoCallID: newIncomingCall.id,
            },
          })
            .then(({ data }) =>
              setNotificationHasBeenAcknowledged(
                data?.acknowledgeNotificationVideoCall.videoCall.command
                  .currentState === VideoCallState.WaitingCalledAnswer,
              ),
            )
            .catch((error) => console.error(error));
        }
      },
    },
  );

  return null;
};

export default WaitingIncomingCall;
