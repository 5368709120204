import Image from '../../../../../components/Image/Image';
import ArdoizVisioImage from '../../../../../images/Communicate/VideoCall/ardoiz_visio.png';
import useStyles from './ArdoizVisioLogo.styles';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';

const ArdoizVisioLogo = (): JSX.Element => {
  const classes = useStyles();
  const isSmallScreen = useIsSmallScreen();
  const width = isSmallScreen ? 40 : 60;

  return (
    <Image className={classes.image} src={ArdoizVisioImage} width={width} />
  );
};

export default ArdoizVisioLogo;
