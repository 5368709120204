import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    maxWidth: 1200,
  },
  item: {
    padding: 25,
  },
}));
