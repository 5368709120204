import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  app: {
    '& img, div': {
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      'user-select':
        'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
    },
    '& img': {
      '-webkit-user-drag': 'none',
      '-khtml-user-drag': 'none',
      '-moz-user-drag': 'none',
      '-o-user-drag': 'none',
      'user-drag': 'none',
    },

    [theme.breakpoints.down('md')]: {
      paddingBottom: 55,
    },
  },
  content: {
    margin: '0 auto',
    width: '80%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  profileLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
