import { useContext } from 'react';
import ImageCrop from '../../../../../../components/ImageCrop/ImageCrop';
import { Crop } from 'react-image-crop';
import { cropDataURL } from '../../../../../../utils/Image';
import { POSTCARD_IMAGE_SIZE } from '../Front';
import { postcardContext } from '../../../../../../Context/Communicate/Postcard/PostcardContext';
import FilledButton from '../../../../../../components/Button/FilledButton';
import { Fade, Grid } from '@mui/material';
import {
  S3UploadOutputType,
  UploadFileToS3FnType,
} from '../../../../../../utils/S3Upload';
import { StateSetter } from '../../../../../../types/utils/React';
import { FormattedMessage } from 'react-intl';
import PostcardPlaceholderImage from '../../../../../../images/Communicate/Postcard/postcard_placeholder.png';
import { trackEvent } from '../../../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../../../GoogleAnalytics/GAEvent';

type PostcardImageCropPureProps = {
  crop: Crop;
  imagePreview: string;
  showFront: boolean;
  handleCancel: Function;
  handleCrop: Function;
  setCrop: StateSetter<Crop>;
};

export const PostcardImageCropPure = ({
  crop,
  imagePreview,
  showFront,
  handleCancel,
  handleCrop,
  setCrop,
}: PostcardImageCropPureProps): JSX.Element => (
  <>
    <ImageCrop crop={crop} src={imagePreview} setCrop={setCrop} />

    <Fade in={showFront} timeout={1000}>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <FilledButton
            color="green"
            data-test-id="crop-button"
            onClick={(): void => handleCrop()}
          >
            <FormattedMessage id="postcard.crop" />
          </FilledButton>
        </Grid>

        <Grid item>
          <FilledButton
            color="lightred"
            data-test-id="cancel-crop-button"
            onClick={(): void => handleCancel()}
          >
            <FormattedMessage id="button.cancel" />
          </FilledButton>
        </Grid>
      </Grid>
    </Fade>
  </>
);

type PostcardImageCropProps = {
  crop: Crop;
  showCrop: boolean;
  setCrop: StateSetter<Crop>;
  setShowCrop: StateSetter<boolean>;
  uploadFileToS3: UploadFileToS3FnType;
};

const PostcardImageCrop = ({
  crop,
  showCrop,
  setCrop,
  setShowCrop,
  uploadFileToS3,
}: PostcardImageCropProps): JSX.Element | null => {
  const { imagePreview, showFront, setImagePreview, setS3UploadedImage } =
    useContext(postcardContext);

  if (!showCrop) {
    return null;
  }

  const handleCrop = async (): Promise<void> => {
    trackEvent(
      GAEventCategory.POSTCARD,
      GACommonEventAction.CLICK,
      "Recadrer l'image",
    );

    const newDataUrl = await cropDataURL({
      crop,
      dataURL: imagePreview,
      targetHeight: POSTCARD_IMAGE_SIZE.HEIGHT,
      targetWidth: POSTCARD_IMAGE_SIZE.WIDTH,
    });

    const res: S3UploadOutputType = await uploadFileToS3(
      newDataUrl,
      'image/jpeg',
    );

    setImagePreview(res.dataURL);
    setS3UploadedImage(res);
    setShowCrop(false);
  };

  const handleCancel = () => {
    trackEvent(
      GAEventCategory.POSTCARD,
      GACommonEventAction.CLICK,
      "Annuler le recadrage de l'image",
    );

    setShowCrop(false);
    setImagePreview(PostcardPlaceholderImage);
    setS3UploadedImage(undefined);
  };

  return (
    <PostcardImageCropPure
      crop={crop}
      imagePreview={imagePreview}
      showFront={showFront}
      handleCancel={handleCancel}
      handleCrop={handleCrop}
      setCrop={setCrop}
    />
  );
};

export default PostcardImageCrop;
