import { FormLabel, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './EventFormLabel.styles';
import { GridSize } from '@mui/material/Grid/Grid';

type EventFormLabelProps = {
  mandatory?: boolean;
  messageId: string;
  size: GridSize;
};

const EventFormLabel = ({
  mandatory,
  messageId,
  size,
}: EventFormLabelProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.labelContainer} item xs={size}>
      <FormLabel className={classes.label}>
        <FormattedMessage id={messageId} />
        {mandatory && <sup>*</sup>}
      </FormLabel>
    </Grid>
  );
};

export default EventFormLabel;
