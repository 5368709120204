import { makeStyles } from '@mui/styles';
import { adminFontFamily } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    borderRadius: 0,

    '& .MuiGrid-item': {
      fontFamily: adminFontFamily,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  noMessageContainer: {
    padding: 30,
    textAlign: 'center',
  },
}));
