import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  link: {
    color: COLORS.VIOLET,
    fontStyle: 'italic',
    fontWeight: 600,
  },
}));
