import { MouseEventHandler, ReactNode } from 'react';
import { VideoCallDisabledReasonsType } from '../../../../types/videocall/VideoCallDisabledReasons';
import { Fade } from '@mui/material';
import useStyles from './VideoCallSubCategoryDisabledOverlay.styles';
import { FormattedMessage } from 'react-intl';

type VideoCallSubCategoryDisabledOverlayPureProps = {
  message: ReactNode;
  onMouseLeave: MouseEventHandler;
};

const VideoCallSubCategoryDisabledOverlayPure = ({
  message,
  onMouseLeave,
}: VideoCallSubCategoryDisabledOverlayPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Fade in timeout={500}>
      <div className={classes.overlay} onMouseLeave={onMouseLeave}>
        <div className={classes.message}>{message}</div>
      </div>
    </Fade>
  );
};

const getMessageForReason = (
  reason: VideoCallDisabledReasonsType,
): ReactNode => {
  if (reason === VideoCallDisabledReasonsType.MINIMAL_ANDROID_VERSION) {
    return <FormattedMessage id="videoCall.error.androidVersion" />;
  } else if (
    reason === VideoCallDisabledReasonsType.MINIMAL_ARDOIZ_CORE_VERSION
  ) {
    return <FormattedMessage id="videoCall.error.ardoizCoreVersion" />;
  }
};

type VideoCallSubCategoryDisabledOverlayProps = {
  disabledReason?: VideoCallDisabledReasonsType;
  showHoverComponent: boolean;
  onMouseLeave: MouseEventHandler;
};

const VideoCallSubCategoryDisabledOverlay = ({
  disabledReason,
  showHoverComponent,
  onMouseLeave,
}: VideoCallSubCategoryDisabledOverlayProps): JSX.Element | null => {
  if (disabledReason === undefined || !showHoverComponent) {
    return null;
  }

  const message = getMessageForReason(disabledReason);

  return (
    <VideoCallSubCategoryDisabledOverlayPure
      message={message}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default VideoCallSubCategoryDisabledOverlay;
