import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';
import useSettingsModalStyles from '../SettingsModal/SettingsModal.styles';
import { deleteModeContext } from '../../ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';
import { settingsContext } from '../SettingsModal/SettingsContext';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import MobileExplorerToolbarMenuItemButton from '../../../../../mobile/App/Share/ExplorerToolbar/MobileExplorerToolbarMenuItemButton';

type DeleteDocumentButtonPureProps = {
  handleDeleteFiles: () => void;
};

const DeleteDocumentButtonPure = ({
  handleDeleteFiles,
}: DeleteDocumentButtonPureProps): JSX.Element => {
  const settingsModalClasses = useSettingsModalStyles();

  return (
    <FilledButton
      className={settingsModalClasses.button}
      color="violet"
      data-test-id="delete-document-button"
      onClick={handleDeleteFiles}
    >
      <FormattedMessage id="share.folder.settings.deleteFile" />
    </FilledButton>
  );
};

const DeleteDocumentButton = (): JSX.Element => {
  const { setIsDeleteMode } = useContext(deleteModeContext);
  const { setShowSettingsModal } = useContext(settingsContext);
  const isSmallScreen = useIsSmallScreen();

  const handleDeleteFiles = useCallback(() => {
    setShowSettingsModal(false);
    setIsDeleteMode(true);
  }, [setIsDeleteMode, setShowSettingsModal]);

  if (isSmallScreen) {
    return (
      <MobileExplorerToolbarMenuItemButton
        messageId="share.folder.settings.deleteFile"
        onClick={handleDeleteFiles}
      />
    );
  }

  return <DeleteDocumentButtonPure handleDeleteFiles={handleDeleteFiles} />;
};

export default DeleteDocumentButton;
