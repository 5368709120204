import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';

export default makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
  },
  sendButton: {
    padding: '15px 30px',
    textTransform: 'none',
  },
  subtitle: {
    fontSize: 18,
    paddingBottom: 10,
    paddingTop: 20,
  },
  title: {
    color: COLORS.VIOLET,
    fontSize: 18,
  },
}));
