import { useContext } from 'react';
import { calendarContext } from '../../../../../components/Calendar/CalendarContext';
import { EventOrTimeslotArrayType } from '../../../../../types/event/Event';
import DayEvent from './DayEvent';
import useStyles from './DayEventList.styles';

type DayEventListPureProps = {
  selectedDayEventsAndTimeslots: EventOrTimeslotArrayType;
};

const DayEventListPure = ({
  selectedDayEventsAndTimeslots,
}: DayEventListPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {selectedDayEventsAndTimeslots.map((eventOrTimeslot, index) => (
        <DayEvent eventOrTimeslot={eventOrTimeslot} key={index} />
      ))}
    </div>
  );
};

const DayEventList = (): JSX.Element | null => {
  const { selectedDayEventsAndTimeslots } = useContext(calendarContext);

  return (
    <DayEventListPure
      selectedDayEventsAndTimeslots={selectedDayEventsAndTimeslots}
    />
  );
};

export default DayEventList;
