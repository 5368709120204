import { makeExecutableSchema } from '@graphql-tools/schema';
import { typeDefs } from './typeDefs';
import { resolvers } from './resolvers';
import { GraphQLSchema } from 'graphql';

const mockSchema: GraphQLSchema = makeExecutableSchema({
  typeDefs,
  resolvers,
});

export default mockSchema;
