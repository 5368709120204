export const onboardingMessages = {
  /* ONBOARDING */
  /* -LANDING */
  'onboarding.landing.welcome': 'Bienvenue sur le site famille ardoiz',
  'onboarding.landing.description1':
    "Votre proche est utilisateur d'une tablette ardoiz au quotidien.",
  'onboarding.landing.description2':
    "Avec le site famille, vous avez accès à de nombreuses fonctionnalités pour l'accompagner et rester en contact avec lui.",
  /* -ASSOCIATE */
  'onboarding.associate.title': 'Première étape',
  'onboarding.associate.subtitle':
    'Associez-vous à la tablette de votre proche',
  'onboarding.associate.description':
    "pour l'accompagner dans son utilisation et bénéficier de toutes les fonctionnalités du site",

  /* -STEPS */
  'onboarding.step.discover.title': 'Découvrir le site famille',
  'onboarding.step.discover.content':
    'Découvrez les fonctionnalités que vous offre le site famille.',
  'onboarding.step.discover.exit': 'Passer cette étape',

  'onboarding.step.communicate.content':
    'Passez un appel vidéo en un clic ou envoyez une carte postale personnalisée à votre proche, il la recevra directement dans sa tablette.',

  'onboarding.step.help.content': `
      Une question sur l'utilisation de la tablette ?
      <br></br>
      <br></br>
      Retrouvez ici le manuel d'utilisation de la tablette ainsi que des tutoriels ardoiz. Grâce à ces documents, vous pourrez répondre aux questions de votre proche et l'accompagner dans son utilisation de la tablette.
      <br></br>
      <br></br>
      Vous pourrez également visualiser l'écran de la tablette et guider votre proche pas à pas grâce à TeamViewer.
    `,

  'onboarding.step.organize.content':
    "Organisez des rendez-vous et enregistrez-les directement dans l'agenda de la tablette de votre proche.",

  'onboarding.step.share.content':
    'Créez des albums et partagez vos photos directement dans la tablette de votre proche. <br></br>Vous pourrez bien sûr partager de nombreuses choses avec lui : des documents, des musiques, des vidéos…',

  'onboarding.step.admin.content':
    "Si votre proche vous octroie le rôle d'administrateur de sa tablette, vous aurez accès à distance à toutes les fonctionnalités d'ardoiz pour l'aider au quotidien.",

  'onboarding.step.profile.content':
    'Gérez ici vos informations de compte ainsi que les tablettes auxquelles vous êtes rattachées.',
};
