import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  file: {
    alignItems: 'center',
    borderRadius: 16,
    boxShadow: '0px 3px 5px 1px rgb(0 0 0 / 25%)',
    cursor: 'pointer',
    display: 'flex',
    height: 180,
    justifyContent: 'center',
    margin: '20px auto',
    textAlign: 'center',
    transform: 'scale(1)',
    transition: 'all .2s ease-in-out',
    width: '75%',

    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  green: {
    backgroundColor: COLORS.GREEN,
  },
  link: {
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  red: {
    backgroundColor: COLORS.RED,
  },
  title: {
    color: COLORS.WHITE,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '22px',
    padding: '15px 15px 0',
    textAlign: 'center',
  },
  violet: {
    backgroundColor: COLORS.VIOLET,
  },
  yellow: {
    backgroundColor: COLORS.YELLOW,
  },
}));
