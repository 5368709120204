import { initializeApp } from 'firebase/app';
import {
  getMessaging as getMessagingInWindow,
  getToken,
  isSupported as isSupportedInWindow,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import {
  getMessaging as getMessagingInSw,
  isSupported as isSupportedInSw,
} from 'firebase/messaging/sw';

function initializeFirebaseApp() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  return initializeApp(firebaseConfig);
}

export async function initializeFirebaseInWindow(
  registration: ServiceWorkerRegistration,
  handleNotificationMessageReceived: (message: MessagePayload) => void,
): Promise<void> {
  const isSupportedInWindowBool = await isSupportedInWindow();

  if (isSupportedInWindowBool) {
    const app = initializeFirebaseApp();
    const messaging = getMessagingInWindow(app);

    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log('[firebase] currentToken', currentToken);
          //TODO: send to server FP-286
        } else {
          console.log(
            '[firebase] No registration token available. Request permission to generate one.',
          );
        }
      })
      .catch((err) => {
        console.log(
          '[firebase] An error occurred while retrieving token. ',
          err,
        );
      });

    onMessage(messaging, (payload) => {
      console.log('[firebase] Message received. ', payload);
      handleNotificationMessageReceived(payload);
    });
  } else {
    console.log('[firebase] no supported in window');
  }
}

export async function initializeFirebaseInServiceWorker(): Promise<void> {
  const isSupportedInSwBool = await isSupportedInSw();

  if (isSupportedInSwBool) {
    const app = initializeFirebaseApp();
    getMessagingInSw(app);
  } else {
    console.log('[firebase] no supported in service worker');
  }
}
