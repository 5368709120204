import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  changeModeButton: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    padding: '15px 30px',
    textTransform: 'none',
    width: '75%',

    '&:hover': {
      backgroundColor: COLORS.BLUE,
    },

    [theme.breakpoints.down(1500)]: {
      padding: '5px 10px',
    },
  },
  changeModeButtonContainer: {
    position: 'absolute',
    top: '77%',
    width: '100%',
  },
  changeModeContainer: {
    position: 'relative',
    textAlign: 'center',
  },
  changeModeImage: {
    marginLeft: '4.5%',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      marginLeft: '3.6%',
      width: '40%',
    },
  },
  container: {
    height: '100vh',

    [theme.breakpoints.down('md')]: {
      padding: 0,

      '& .changeModeButton': {
        fontSize: 10,
      },

      '& .changeModeContainer': {
        position: 'absolute',
      },
    },
  },
  icon: {
    padding: 10,
  },
  menu: {
    outline: 'none',
    padding: '20px 10px',

    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontWeight: 'bold',
    },

    '&.tour': {
      '& .tour-step-communicate.selected': {
        border: `2px solid ${COLORS.VIOLET}`,
      },
      '& .tour-step-share.selected': {
        border: `2px solid ${COLORS.GREEN}`,
      },
      '& .tour-step-organize.selected': {
        border: `2px solid ${COLORS.LIGHT_RED}`,
      },
      '& .tour-step-help.selected': {
        border: `2px solid ${COLORS.YELLOW}`,
      },
    },
  },

  title: {
    fontSize: 24,
  },
}));
