import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  exitButton: {
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'bold',
    left: 180,
    marginTop: 40,
    position: 'absolute',
  },
  lastButton: {
    color: COLORS.WHITE,
    fontSize: 14,
  },
  mask: {
    display: 'none',
  },
  nextButton: {
    color: COLORS.WHITE,
    fontSize: 14,
  },
  tour: {
    '&.reactour__helper': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      maxWidth: '50%',
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: 50,
    },

    /*** STEPS ***/

    '&.tour-step-communicate, &.tour-step-discover, &.tour-step-profile': {
      '& [data-tour-elem="left-arrow"]': {
        color: COLORS.VIOLET,
      },

      '& [data-tour-elem="right-arrow"]': {
        backgroundColor: COLORS.VIOLET,
      },

      '& .title': {
        color: COLORS.VIOLET,
      },
    },

    '&.tour-step-share': {
      '& [data-tour-elem="left-arrow"]': {
        color: COLORS.GREEN,
      },

      '& [data-tour-elem="right-arrow"]': {
        backgroundColor: COLORS.GREEN,
      },

      '& .title': {
        color: COLORS.GREEN,
      },
    },

    '&.tour-step-organize': {
      '& [data-tour-elem="left-arrow"]': {
        color: COLORS.LIGHT_RED,
      },

      '& [data-tour-elem="right-arrow"]': {
        backgroundColor: COLORS.LIGHT_RED,
      },

      '& .title': {
        color: COLORS.LIGHT_RED,
      },
    },

    '&.tour-step-help': {
      '& [data-tour-elem="left-arrow"]': {
        color: COLORS.YELLOW,
      },

      '& [data-tour-elem="right-arrow"]': {
        backgroundColor: COLORS.YELLOW,
      },

      '& .title': {
        color: COLORS.YELLOW,
      },
    },

    '&.tour-step-admin': {
      '& [data-tour-elem="left-arrow"]': {
        color: COLORS.BLUE,
      },

      '& [data-tour-elem="right-arrow"]': {
        backgroundColor: COLORS.BLUE,
      },

      '& .title': {
        color: COLORS.BLUE,
      },
    },

    /*** END STEPS ***/

    '& [data-tour-elem="badge"]': {
      display: 'none',
    },

    '& .reactour__close': {
      display: 'none',
    },

    '& [data-tour-elem="navigation"]': {
      display: 'none',
    },

    '& [data-tour-elem="controls"]': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },

    '& [data-tour-elem="left-arrow"]': {
      marginLeft: 50,

      '&[disabled]': {
        display: 'none',
      },

      '&:focus': {
        outline: 'none',
      },
    },

    '& [data-tour-elem="right-arrow"]': {
      borderRadius: 10,
      float: 'left',
      margin: 0,
      padding: '15px 35px',

      '&:focus': {
        outline: 'none',
      },
    },

    '& div': {
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      'user-select':
        'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
    },
  },
}));
