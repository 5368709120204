import { useContext } from 'react';
import { eventFormContext } from './EventFormContext';
import EventFormLabel from './EventFormLabel';
import RecurrenceDays from './RecurrenceForm/RecurrenceDays';
import RecurrenceEndSelector from './RecurrenceForm/RecurrenceEndSelector';

const RecurrenceFormPure = (): JSX.Element => (
  <>
    <EventFormLabel messageId="event.form.recurrence_days" size={12} />
    <RecurrenceDays />

    <EventFormLabel messageId="end" size={12} />
    <RecurrenceEndSelector />
  </>
);

const RecurrenceForm = (): JSX.Element | null => {
  const { hasRecurrence } = useContext(eventFormContext);

  if (!hasRecurrence) {
    return null;
  }

  return <RecurrenceFormPure />;
};

export default RecurrenceForm;
