import PageContentHeader from '../../../components/PageContent/PageContentHeader';
import PageContentContainer from '../../../components/PageContent/PageContentContainer';
import PostcardWidget from './Postcard/PostcardWidget';
import { PostcardContextProvider } from '../../../Context/Communicate/Postcard/PostcardContext';
import { FormattedMessage } from 'react-intl';

const PostcardPure = (): JSX.Element => (
  <PageContentContainer>
    <PageContentHeader title={<FormattedMessage id="postcard.description" />} />

    <PostcardWidget />
  </PageContentContainer>
);

const Postcard = (): JSX.Element => {
  return (
    <PostcardContextProvider>
      <PostcardPure />
    </PostcardContextProvider>
  );
};

export default Postcard;
