import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  text: {
    fontSize: '1.4em',
    lineHeight: '1.4em',
    paddingTop: 40,
  },
  title: {
    fontSize: '3em',
    fontWeight: 'bold',
  },
}));
