import { createContext, ReactNode } from 'react';
import { StateSetter } from '../../../types/utils/React';
import { emptyFunction } from '../../../utils/Functions';
import { FileTypeSwitcherFileTypes } from '../../../types/share/FileTypeSwitcherFileTypes';
import { FolderType } from '../../../types/document/Document';
import { DropzoneState } from 'react-dropzone';
import { ResultItemProps } from './DocumentOperationResultsModal/ResultItem';

export type ShareContextProps = {
  documentOperationResults: ResultItemProps[];
  dropzoneState?: DropzoneState;
  folders?: FolderType[];
  loadingMessage?: ReactNode;
  selectedFileType: FileTypeSwitcherFileTypes;
  selectedFolder?: FolderType;
  setDocumentOperationResults: StateSetter<ResultItemProps[]>;
  setLoadingMessage: StateSetter<ReactNode | undefined>;
  setSelectedFileType: StateSetter<FileTypeSwitcherFileTypes>;
  setSelectedFolder: StateSetter<FolderType | undefined>;
};

const initialState = {
  documentOperationResults: [],
  dropzoneState: undefined,
  folders: [],
  loadingMessage: undefined,
  selectedFileType: FileTypeSwitcherFileTypes.PICTURE_AND_VIDEO,
  selectedFolder: undefined,
  setDocumentOperationResults: emptyFunction,
  setLoadingMessage: emptyFunction,
  setSelectedFileType: emptyFunction,
  setSelectedFolder: emptyFunction,
};

const shareContext = createContext<ShareContextProps>(initialState),
  { Provider } = shareContext;

type ShareContextProviderProps = {
  children: ReactNode;
  documentOperationResults: ResultItemProps[];
  dropzoneState: DropzoneState;
  folders?: FolderType[];
  loadingMessage?: ReactNode;
  selectedFileType: FileTypeSwitcherFileTypes;
  selectedFolder?: FolderType;
  setDocumentOperationResults: StateSetter<ResultItemProps[]>;
  setLoadingMessage: StateSetter<ReactNode | undefined>;
  setSelectedFileType: StateSetter<FileTypeSwitcherFileTypes>;
  setSelectedFolder: StateSetter<FolderType | undefined>;
};

const ShareContextProvider = ({
  children,
  documentOperationResults,
  dropzoneState,
  folders,
  loadingMessage,
  selectedFileType,
  selectedFolder,
  setDocumentOperationResults,
  setLoadingMessage,
  setSelectedFileType,
  setSelectedFolder,
}: ShareContextProviderProps): JSX.Element => (
  <Provider
    value={{
      documentOperationResults,
      dropzoneState,
      folders,
      loadingMessage,
      selectedFileType,
      selectedFolder,
      setDocumentOperationResults,
      setLoadingMessage,
      setSelectedFileType,
      setSelectedFolder,
    }}
  >
    {children}
  </Provider>
);

export { shareContext, ShareContextProvider };
