import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useContext } from 'react';
import { eventFormContext } from '../EventFormContext';
import { StateSetter } from '../../../../../../types/utils/React';
import { FormattedMessage } from 'react-intl';
import useStyles from './RecurrenceEndSelector.styles';
import OccurrencesNumberField from './OccurrencesNumberField';
import UntilDatePicker from './UntilDatePicker';
import { RecurrenceTypeEnum } from '../../../../../../types/event/Event';

type RecurrenceTypeItemProps = {
  disabled: boolean;
  labelId: string;
  value: RecurrenceTypeEnum;
};

const RecurrenceTypeItem = ({
  disabled,
  labelId,
  value,
}: RecurrenceTypeItemProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.label}
      control={<Radio className={classes.radio} />}
      disabled={disabled}
      label={<FormattedMessage id={labelId} />}
      value={value}
    />
  );
};

type RecurrenceEndSelectorPureProps = {
  disabled: boolean;
  recurrenceType: RecurrenceTypeEnum;
  setRecurrenceType: StateSetter<RecurrenceTypeEnum>;
};

const RecurrenceEndSelectorPure = ({
  disabled,
  recurrenceType,
  setRecurrenceType,
}: RecurrenceEndSelectorPureProps): JSX.Element => {
  return (
    <RadioGroup
      value={recurrenceType}
      onChange={(e) => setRecurrenceType(e.target.value as RecurrenceTypeEnum)}
    >
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <RecurrenceTypeItem
            disabled={disabled}
            labelId="never"
            value={RecurrenceTypeEnum.ALWAYS}
          />
        </Grid>

        <Grid item xs={4}>
          <RecurrenceTypeItem
            disabled={disabled}
            labelId="after"
            value={RecurrenceTypeEnum.AFTER}
          />
        </Grid>
        <Grid item xs={8}>
          <OccurrencesNumberField />
        </Grid>

        <Grid item xs={4}>
          <RecurrenceTypeItem
            disabled={disabled}
            labelId="until"
            value={RecurrenceTypeEnum.UNTIL}
          />
        </Grid>
        <Grid item xs={8}>
          <UntilDatePicker />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

const RecurrenceEndSelector = (): JSX.Element => {
  const { disabled, recurrenceType, setRecurrenceType } =
    useContext(eventFormContext);

  return (
    <RecurrenceEndSelectorPure
      disabled={disabled}
      recurrenceType={recurrenceType}
      setRecurrenceType={setRecurrenceType}
    />
  );
};

export default RecurrenceEndSelector;
