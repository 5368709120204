import { useCallback, useContext, useEffect, useState } from 'react';
import {
  ACCEPT_VIDEO_CALL_MUTATION,
  AcceptVideoCallOutputType,
  REFUSE_VIDEO_CALL_MUTATION,
  RefuseVideoCallOutputType,
} from '../../../graphql/communicate/videocall/VideoCallMutations';
import { useMutation } from '@apollo/client';
import {
  getOtherParticipantFullName,
  VideoCallType,
} from '../../../types/videocall/VideoCall';
import useStyles from './IncomingCall.styles';
import { Fade, Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../components/Button/FilledButton';
import VideoCameraImage from '../../../images/Communicate/VideoCall/video-camera.png';
import PhoneHangUpImage from '../../../images/Communicate/VideoCall/phone-hang-up.png';
import Image from '../../../components/Image/Image';
import { alertContext } from '../../../components/Alert/AlertContext';
import {
  acceptIncomingCall,
  playNotificationSound,
  refuseIncomingCall,
} from './IncomingCall/handlers';
import WaitingIncomingCall from './IncomingCall/WaitingIncomingCall';
import { useUserContext } from '../../../Context/UserContext';
import { TimeoutType } from '../../../types/utils/Timeout';
import {
  DISABLE_INCOMING_CALL,
  VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT,
} from '../../../utils/constants';
import { StateSetter } from '../../../types/utils/React';

type IncomingCallPureProps = {
  callerFullName?: string;
  handleAccept: Function;
  handleRefuse: Function;
};
export const IncomingCallPure = ({
  callerFullName,
  handleAccept,
  handleRefuse,
}: IncomingCallPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Fade in>
      <div className={classes.mask}>
        <audio autoPlay loop src="/sounds/ring.mp3" />

        <Paper className={classes.container}>
          <Typography variant="subtitle1">
            <FormattedMessage id="videoCall.incomingcall" />
          </Typography>

          <Typography className={classes.fullName} variant="subtitle2">
            {callerFullName}
          </Typography>

          <FilledButton
            className={classes.declineButton}
            color="red"
            data-test-id="decline-button"
            onClick={() => handleRefuse()}
          >
            <Image className={classes.buttonIcon} src={PhoneHangUpImage} />
            <FormattedMessage id="button.decline" />
          </FilledButton>

          <FilledButton
            className={classes.acceptButton}
            color="green"
            data-test-id="accept-button"
            onClick={() => handleAccept()}
          >
            <Image className={classes.buttonIcon} src={VideoCameraImage} />
            <FormattedMessage id="button.accept" />
          </FilledButton>
        </Paper>
      </div>
    </Fade>
  );
};

export type IncomingCallManagerProps = {
  disabled: boolean;
  incomingCall: VideoCallType | undefined;
  notificationHasBeenAcknowledged: boolean;
  handleAccept: () => void;
  handleRefuse: () => void;
  setIncomingCall: StateSetter<VideoCallType | undefined>;
  setNotificationHasBeenAcknowledged: StateSetter<boolean>;
};

export const IncomingCallManager = ({
  disabled,
  incomingCall,
  notificationHasBeenAcknowledged,
  handleAccept,
  handleRefuse,
  setIncomingCall,
  setNotificationHasBeenAcknowledged,
}: IncomingCallManagerProps): JSX.Element | null => {
  if (!disabled) {
    if (!incomingCall) {
      return (
        <WaitingIncomingCall
          setIncomingCall={setIncomingCall}
          setNotificationHasBeenAcknowledged={
            setNotificationHasBeenAcknowledged
          }
        />
      );
    } else if (notificationHasBeenAcknowledged) {
      return (
        <IncomingCallPure
          callerFullName={getOtherParticipantFullName(incomingCall)}
          handleAccept={handleAccept}
          handleRefuse={handleRefuse}
        />
      );
    }
  }

  return null;
};

const IncomingCall = (): JSX.Element => {
  const { showErrorMessage } = useContext(alertContext);
  const { webUser } = useUserContext();
  const [notificationHasBeenAcknowledged, setNotificationHasBeenAcknowledged] =
    useState<boolean>(false);
  const [incomingCall, setIncomingCall] = useState<VideoCallType>();
  const [timeoutHandler, setTimeoutHandler] = useState<TimeoutType>();

  const [acceptVideoCall] = useMutation<AcceptVideoCallOutputType>(
    ACCEPT_VIDEO_CALL_MUTATION,
  );

  const [refuseVideoCall] = useMutation<RefuseVideoCallOutputType>(
    REFUSE_VIDEO_CALL_MUTATION,
  );

  const resetIncomingCall = useCallback(() => {
    setIncomingCall(undefined);
    setNotificationHasBeenAcknowledged(false);
  }, [setIncomingCall, setNotificationHasBeenAcknowledged]);

  const handleAccept = useCallback(async () => {
    if (incomingCall) {
      acceptIncomingCall({
        incomingCall,
        webUser,
        acceptVideoCall,
        resetIncomingCall,
        showErrorMessage,
      });
    }
  }, [
    incomingCall,
    webUser,
    acceptVideoCall,
    resetIncomingCall,
    showErrorMessage,
  ]);

  const handleRefuse = useCallback(() => {
    if (incomingCall) {
      refuseIncomingCall({
        incomingCall,
        refuseVideoCall,
        resetIncomingCall,
        showErrorMessage,
      });
    }
  }, [incomingCall, refuseVideoCall, resetIncomingCall, showErrorMessage]);

  useEffect(() => {
    if (incomingCall && notificationHasBeenAcknowledged && !timeoutHandler) {
      setTimeoutHandler(
        setTimeout(() => {
          resetIncomingCall();
          playNotificationSound();
        }, VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT),
      );
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [
    incomingCall,
    notificationHasBeenAcknowledged,
    timeoutHandler,
    resetIncomingCall,
    setTimeoutHandler,
  ]);

  return (
    <IncomingCallManager
      disabled={DISABLE_INCOMING_CALL}
      incomingCall={incomingCall}
      notificationHasBeenAcknowledged={notificationHasBeenAcknowledged}
      handleAccept={handleAccept}
      handleRefuse={handleRefuse}
      setIncomingCall={setIncomingCall}
      setNotificationHasBeenAcknowledged={setNotificationHasBeenAcknowledged}
    />
  );
};

export default IncomingCall;
