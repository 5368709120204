import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  button: {
    borderRadius: 14,
    fontSize: 16,
    float: 'right',
    marginRight: 20,
    textTransform: 'none',
    width: 200,

    [theme.breakpoints.down('md')]: {
      margin: 20,
      width: 120,
    },
  },
}));
