import { LinkMetadataType, LinkType, LinkTypeType } from '../types/Link';
import { UserType, WebUserContactType, WebUserType } from '../types/User';
import {
  VideoCallParticipantType,
  VideoCallState,
  VideoCallType,
} from '../types/videocall/VideoCall';
import {
  FolderType,
  SharedDocumentType,
  SharedDocumentTypeEnum,
} from '../types/document/Document';
import { PartnerAccountType } from '../types/partnerdocuments/PartnerAccount';
import { PartnerContentType } from '../types/partnerdocuments/PartnerDocument';
import {
  EventType,
  EventTypeEnum,
  RecurrenceTypeEnum,
  TimeslotType,
} from '../types/event/Event';
import { AlarmEnum, DaysOfRepeatType } from '../types/organize/Organizer';
import { BlobType } from '../types/Blob';

const casual = require('casual-browserify').fr_FR;

const provider = {
  features: (number: number, isEnabled: boolean, isIncluded: boolean) => {
    const features = [];
    for (let i = 0; i < number; i++)
      features.push(casual.Feature(isEnabled, isIncluded));

    return features;
  },
};

casual.register_provider(provider);

type MockedLinkInputType = {
  isAdmin?: boolean;
  metadata?: LinkMetadataType;
  selfWebUserId?: string;
  type: LinkTypeType;
  user?: UserType;
};

const createLink = ({
  isAdmin,
  metadata,
  selfWebUserId,
  type,
  user,
}: MockedLinkInputType): LinkType => ({
  channelKey: casual.word,
  isAdmin: isAdmin || false,
  metadata: {
    hasMinimalAndroidVersionForVideoCall: casual.bool,
    hasMinimalArdoizCoreVersionForVideoCall: casual.bool,
    subscriptionEnabled: true,
    ...metadata,
  },
  type,
  mainUser: user || casual.User,
  selfWebUserId: selfWebUserId || casual.uuid,
  userKey: user?.key || casual.uuid,
});

casual.define('Link', createLink);

const createUser = (): UserType => ({
  id: casual.uuid,
  emails: [casual.email],
  firstName: casual.first_name,
  key: casual.word,
  lastName: casual.last_name,
  postalAddress: {
    address: casual.address,
    city: casual.city,
    zipcode: casual.zip,
  },
});

casual.define('User', createUser);

type GetCreateVideoCallLinksInputType = {
  expectedParticipantType: VideoCallParticipantType;
  participantType: VideoCallParticipantType;
  withSelfLink?: boolean;
};

const getCreateVideoCallLinks = ({
  expectedParticipantType,
  participantType,
  withSelfLink,
}: GetCreateVideoCallLinksInputType) => {
  if (withSelfLink && expectedParticipantType === participantType) {
    return [casual.Link({ type: LinkTypeType.Self })];
  }

  return [];
};

type MockedVideoCallInputType = {
  currentState: VideoCallState;
  missingCallNotificationHasBeenSent?: boolean;
  participantType: VideoCallParticipantType;
  withSelfLink?: boolean;
};

const createVideoCall = ({
  currentState,
  missingCallNotificationHasBeenSent,
  participantType,
  withSelfLink,
}: MockedVideoCallInputType): VideoCallType => ({
  id: casual.uuid,
  called: {
    id: casual.uuid,
    firstName: casual.first_name,
    lastName: casual.last_name,
    links: getCreateVideoCallLinks({
      expectedParticipantType: VideoCallParticipantType.CALLED,
      participantType,
      withSelfLink,
    }),
  },
  caller: {
    id: casual.uuid,
    firstName: casual.first_name,
    lastName: casual.last_name,
    links: getCreateVideoCallLinks({
      expectedParticipantType: VideoCallParticipantType.CALLER,
      participantType,
      withSelfLink,
    }),
  },
  command: {
    currentState,
  },
  missingCallNotificationHasBeenSent,
  participantType,
  roomName: casual.word,
});

casual.define('VideoCall', createVideoCall);

type MockedWebUserInputType = {
  links: Array<LinkType>;
  onBoardingCompleted?: boolean;
};

const createWebUser = ({
  links,
  onBoardingCompleted,
}: MockedWebUserInputType): WebUserType => ({
  id: casual.uuid,
  firstName: casual.first_name,
  lastName: casual.last_name,
  links,
  onBoardingCompleted: !!onBoardingCompleted,
});

casual.define('WebUser', createWebUser);

const createWebUserContact = (): WebUserContactType => ({
  id: casual.uuid,
  firstName: casual.first_name,
  lastName: casual.last_name,
});

casual.define('WebUserContact', createWebUserContact);

type MockedFolderInputType = {
  creator?: string;
  sharedWith?: Array<WebUserContactType>;
};

const createFolder = ({
  creator,
  sharedWith,
}: MockedFolderInputType): FolderType => ({
  id: casual.uuid,
  creator: creator || casual.word,
  key: casual.word,
  name: casual.word,
  sharedWith: sharedWith || [],
});

casual.define('Folder', createFolder);

type MockedDocumentInputType = {
  name?: string;
  type?: SharedDocumentTypeEnum;
};

const createDocument = ({
  name,
  type,
}: MockedDocumentInputType): SharedDocumentType => ({
  id: casual.uuid,
  blob: casual.Blob({}),
  creator: casual.word,
  key: casual.word,
  name: name || casual.word,
  type: type || casual.random_value(SharedDocumentTypeEnum),
});

casual.define('Document', createDocument);

const createPartnerAccount = (): PartnerAccountType => ({
  id: casual.uuid,
  partnerProfile: {
    id: casual.uuid,
    name: casual.word,
    thumbnailBlob: casual.Blob({}),
  },
});

casual.define('PartnerAccount', createPartnerAccount);

const createPartnerContent = (): PartnerContentType => ({
  id: casual.uuid,
  blob: casual.Blob({}),
  content: '',
  publicationStartDate: casual.unix_time,
  thumbnailBlob: casual.Blob({}),
  title: casual.word,
});

casual.define('PartnerContent', createPartnerContent);

type MockedEventInputType = {
  creator?: WebUserType;
  date?: number;
  duration?: number;
  isAllDay?: boolean;
  recurrence?: RecurrenceTypeEnum;
};

const createEvent = ({
  creator,
  date,
  duration,
  isAllDay,
  recurrence,
}: MockedEventInputType): EventType => {
  const daysOfRepeat: DaysOfRepeatType = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  return {
    id: casual.uuid,
    alarm: AlarmEnum.NO_ALARM,
    comments: '',
    creator: creator || casual.WebUser({}),
    date: date || casual.unix_time,
    daysOfRepeat,
    done: false,
    duration: duration || 1,
    endOfRepeatDate: undefined,
    ignoreDate: [],
    isAllDay: isAllDay || false,
    name: casual.word,
    occurrence: 2,
    recurrence: recurrence || RecurrenceTypeEnum.NONE,
    type: EventTypeEnum.DATE,
    untilDate: undefined,
  };
};

casual.define('Event', createEvent);

type MockedTimeslotInputType = {
  date?: number;
  duration?: number;
};

const createTimeslot = ({
  date,
  duration,
}: MockedTimeslotInputType): TimeslotType => ({
  date: date || casual.unix_time,
  duration: duration || 1,
});

casual.define('Timeslot', createTimeslot);

type MockedBlobInputType = {
  contentType?: string;
  filename?: string;
};

const createBlob = ({
  contentType,
  filename,
}: MockedBlobInputType): BlobType => ({
  id: casual.uuid,
  contentType: contentType || casual.mime_type,
  filename: filename || `${casual.word}.${casual.file_extension}`,
  key: casual.uuid,
  s3URL: casual.url,
});

casual.define('Blob', createBlob);

export default casual;
