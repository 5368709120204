import useStyles from './PartnerDocumentItem.styles';
import { PartnerDocumentType } from '../../../../../../types/partnerdocuments/PartnerDocument';
import DocumentPreview from '../../../../../../components/DocumentPreview/DocumentPreview';

type PartnerDocumentItemProps = {
  document: PartnerDocumentType;
};

const PartnerDocumentItem = ({
  document,
}: PartnerDocumentItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <DocumentPreview document={document} useBlobForExtension />
      <div className={classes.title}>{document.name}</div>
    </div>
  );
};

export default PartnerDocumentItem;
