import { ReactNode } from 'react';
import { LinearProgress, Paper, Typography } from '@mui/material';
import useStyles from './IndeterminateLoading.styles';

export type IndeterminateLoadingProps = {
  label?: ReactNode;
};

const IndeterminateLoading = ({
  label,
}: IndeterminateLoadingProps): JSX.Element | null => {
  const classes = useStyles();

  if (!label) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.modal}>
        <div className={classes.label}>
          <Typography variant="h2">{label}</Typography>
        </div>
        <LinearProgress color="primary" />
      </Paper>
    </div>
  );
};

export default IndeterminateLoading;
