import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  closeButton: {
    float: 'right',

    '&:hover': {
      background: 'none',
    },
  },
  container: {
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
  contactsSelectContainer: {
    paddingTop: 20,
    textAlign: 'center',
  },
  folderNameContainer: {
    padding: '20px 0 40px 0',
    textAlign: 'center',
  },
  modal: {
    borderRadius: 24,
    height: 500,
    left: '50%',
    padding: 20,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      bottom: 0,
      height: 'auto',
      left: 0,
      padding: 10,
      right: 0,
      top: 0,
      transform: 'none',
      width: 'auto',
    },
  },
  padding: {
    paddingTop: 25,
  },
  resultsContainer: {
    padding: '10px 50px 30px',
  },
  resultsModal: {
    height: 'auto',
  },
  text: {
    fontSize: 18,
    textAlign: 'center',
  },
}));
