import { useState } from 'react';
import { Button, Slide } from '@mui/material';
import Menu from '../../../views/Navigation/MainMenu/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from './MainMobileMenu.styles';
import { MainMobileMenuContextProvider } from '../../../Context/MainMobileMenu';

const MainMobileMenu = (): JSX.Element => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);

  const onMenuItemClick = (goToPageFn: Function): void => {
    setIsOpened(false);
    goToPageFn();
  };

  const menuElement = isOpened && (
    <Slide direction="right" in={isOpened}>
      <div>
        <Menu setIsOpened={setIsOpened} onMenuItemClick={onMenuItemClick} />
      </div>
    </Slide>
  );

  return (
    <MainMobileMenuContextProvider value={{ isOpened, setIsOpened }}>
      {menuElement}
      <Button
        className={classes.showMenuButton}
        onClick={(): void => setIsOpened(!isOpened)}
      >
        <MenuIcon className={classes.showMenuIcon} />
      </Button>
    </MainMobileMenuContextProvider>
  );
};

export default MainMobileMenu;
