import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  avatar: {
    fontSize: 24,
    height: 60,
    margin: 'auto',
    width: 60,
  },
  contact: {
    fontSize: 18,
    padding: 10,
    textAlign: 'center',
  },
  contactName: {
    paddingTop: 10,
    wordBreak: 'break-word',
  },
  existingContacts: {
    overflow: 'auto',
    maxHeight: 205,
    marginTop: 10,
    padding: '0px 10px',
  },
}));
