import { ChangeEventHandler, ReactNode, useCallback, useContext } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import useStyles from '../EventForm.styles';
import { eventFormContext } from './EventFormContext';
import { EventFormErrorsType } from './EventFormHelpers';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

type EventFormTextFieldPureProps = {
  className?: string;
  errorMsg?: ReactNode;
  inError: boolean;
  textFieldProps?: TextFieldProps;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

const EventFormTextFieldPure = ({
  className,
  errorMsg,
  inError,
  textFieldProps,
  value,
  onChange,
}: EventFormTextFieldPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TextField
      {...textFieldProps}
      className={clsx([className, classes.field])}
      error={inError}
      helperText={errorMsg}
      size="small"
      value={value}
      onChange={onChange}
    />
  );
};

type EventFormTextFieldProps = {
  className?: string;
  formProperty: keyof EventFormErrorsType;
  textFieldProps?: TextFieldProps;
  value: any;
  setValue: (newValue: any) => void;
};

const EventFormTextField = ({
  className,
  formProperty,
  textFieldProps,
  value,
  setValue,
}: EventFormTextFieldProps): JSX.Element => {
  const { eventFormErrors, setEventFormErrors } = useContext(eventFormContext);
  const inError = !!eventFormErrors[formProperty];
  const errorMsg = inError ? (
    <FormattedMessage id={eventFormErrors[formProperty]} />
  ) : undefined;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(e.target.value);

      if (inError) {
        setEventFormErrors({
          ...eventFormErrors,
          [formProperty]: undefined,
        });
      }
    },
    [eventFormErrors, formProperty, inError, setEventFormErrors, setValue],
  );

  return (
    <EventFormTextFieldPure
      className={className}
      errorMsg={errorMsg}
      inError={inError}
      textFieldProps={textFieldProps}
      value={value}
      onChange={onChange}
    />
  );
};

export default EventFormTextField;
