import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  dayEventsContainer: {
    bottom: 10,
    left: 10,
    position: 'absolute',
    right: 10,
    textAlign: 'right',

    [theme.breakpoints.down('md')]: {
      bottom: 5,
      left: 5,
      right: 5,
    },
  },
  dayEvent: {
    borderRadius: 6,
    display: 'inline-block',
    height: 12,
    marginLeft: 5,
    verticalAlign: 'middle',
    width: 12,

    [theme.breakpoints.down('md')]: {
      height: 7,
      marginLeft: 1,
      verticalAlign: 'baseline',
      width: 7,
    },
  },
  busyEvent: {
    backgroundColor: COLORS.RED,
  },
  guestEvent: {
    backgroundColor: COLORS.YELLOW,
  },
  ownedEvent: {
    backgroundColor: COLORS.GREEN,
  },
  extraEventsOrTimeslots: {
    display: 'inline-block',
    fontSize: 14,
    marginLeft: 5,

    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      marginLeft: 2,
    },
  },
}));
