import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  audioControls: {
    borderRadius: 12,
    boxShadow: '0px 3px 8px 0px rgb(0 0 0 / 40%)',
    marginTop: 5,
  },
  image: {
    cursor: 'pointer',
    maxHeight: 120,
    maxWidth: 150,
  },
}));
