import { createCtxWithMandatoryValue } from './utils';
import { StateSetter } from '../types/utils/React';

interface MainMobileMenuContextProps {
  isOpened: boolean;
  setIsOpened: StateSetter<boolean>;
}

const userContext = createCtxWithMandatoryValue<MainMobileMenuContextProps>(),
  [useMainMobileMenuContext, MainMobileMenuContextProvider] = userContext;

export { useMainMobileMenuContext, MainMobileMenuContextProvider };
