import { useCallback, useContext } from 'react';
import { Button, Grid } from '@mui/material';
import useStyles from './EventForm.styles';
import {
  DayContentModeEnum,
  DaysOfRepeatType,
  HandleDaysOfRepeatChangeIndexType,
  HandleDaysOfRepeatChangeType,
} from '../../../../types/organize/Organizer';
import { EventFormContextProvider } from './EventForm/EventFormContext';
import { StateSetter } from '../../../../types/utils/React';
import EventFormLabel from './EventForm/EventFormLabel';
import SwitchButtons from '../../../../components/Button/SwitchButtons';
import { FormattedMessage } from 'react-intl';
import RecurrenceForm from './EventForm/RecurrenceForm';
import TimeForm from './EventForm/TimeForm';
import {
  saveEvent,
  useEventFormStates,
  useSaveEventMutations,
} from './EventForm/EventFormHelpers';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { calendarContext } from '../../../../components/Calendar/CalendarContext';
import { alertContext } from '../../../../components/Alert/AlertContext';
import { organizerContext } from '../OrganizerContext';
import EventFormTextField from './EventForm/EventFormTextField';
import AlarmForm from './EventForm/AlarmForm';
import { useLazyQuery } from '@apollo/client';
import {
  GET_EVENTS_QUERY,
  GetEventsQueryOutputType,
} from '../../../../graphql/event/EventQueries';

type EventFormPureProps = {
  comments: string;
  disabled: boolean;
  hasRecurrence: boolean;
  isAllDay: boolean;
  name: string;
  setComments: StateSetter<string>;
  setHasRecurrence: StateSetter<boolean>;
  setIsAllDay: StateSetter<boolean>;
  setName: StateSetter<string>;
  handleCancelClick: () => void;
  handleSave: () => void;
};

const EventFormPure = ({
  comments,
  disabled,
  hasRecurrence,
  isAllDay,
  name,
  setComments,
  setHasRecurrence,
  setIsAllDay,
  setName,
  handleCancelClick,
  handleSave,
}: EventFormPureProps): JSX.Element => {
  const classes = useStyles();
  const cancelButtonLabelId = disabled ? 'button.close' : 'button.cancel';

  return (
    <>
      <Button
        className={classes.cancelButton}
        variant="outlined"
        onClick={handleCancelClick}
      >
        <FormattedMessage id={cancelButtonLabelId} />
      </Button>

      <Grid className={classes.container} container>
        <EventFormLabel mandatory={!disabled} messageId="title" size={12} />
        <Grid item xs={12}>
          <EventFormTextField
            formProperty="name"
            textFieldProps={{
              disabled,
            }}
            value={name}
            setValue={setName}
          />
        </Grid>

        <EventFormLabel messageId="event.form.all_day" size={12} />
        <SwitchButtons
          currentValue={isAllDay}
          disabled={disabled}
          setValue={setIsAllDay}
        />

        <TimeForm />

        <AlarmForm />

        <EventFormLabel messageId="event.form.recurrence" size={12} />
        <SwitchButtons
          currentValue={hasRecurrence}
          disabled={disabled}
          setValue={setHasRecurrence}
        />

        <RecurrenceForm />

        <EventFormLabel messageId="comment" size={12} />
        <Grid item xs={12}>
          <EventFormTextField
            formProperty="comments"
            textFieldProps={{
              disabled,
            }}
            value={comments}
            setValue={setComments}
          />
        </Grid>

        {!disabled && (
          <>
            <Grid className={classes.mandatoryFieldsLabel} item xs={12}>
              * <FormattedMessage id="event.form.mandatory_fields" />
            </Grid>

            <Button
              className={classes.saveButton}
              variant="contained"
              onClick={handleSave}
            >
              <FormattedMessage id="event.form.save_event" />
            </Button>
          </>
        )}
      </Grid>
    </>
  );
};

const EventForm = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { currentDate, selectedDate } = useContext(calendarContext);
  const { showSuccessMessage } = useContext(alertContext);
  const { dayContentMode, eventToEdit, setDayContentMode, setEventToEdit } =
    useContext(organizerContext);

  const eventFormStates = useEventFormStates({
    dayContentMode,
    initialEvent: eventToEdit,
    selectedDate,
  });

  const saveEventMutations = useSaveEventMutations();

  const handleDaysOfRepeatChange: HandleDaysOfRepeatChangeType = useCallback(
    (index: HandleDaysOfRepeatChangeIndexType, value: boolean) => {
      const newDaysOfRepeat: DaysOfRepeatType = [
        ...eventFormStates.daysOfRepeat,
      ];

      newDaysOfRepeat.fill(value, index, index + 1);
      eventFormStates.setDaysOfRepeat(newDaysOfRepeat);

      const daysOfRepeatCount = newDaysOfRepeat.filter((_) => _).length;

      if (daysOfRepeatCount > eventFormStates.occurrences) {
        eventFormStates.setOccurrences(daysOfRepeatCount);
      }
    },
    [eventFormStates],
  );

  const [getEvents] = useLazyQuery<GetEventsQueryOutputType>(GET_EVENTS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const messageId =
        dayContentMode === DayContentModeEnum.ADD
          ? 'event.form.save_event_success'
          : 'event.form.edit_event_success';

      showSuccessMessage(<FormattedMessage id={messageId} />);
      setDayContentMode(undefined);
      setEventToEdit(undefined);
    },
  });

  const channelKey = selectedLink?.channelKey;

  const handleCreateEventSuccess = useCallback(async () => {
    if (dayContentMode === DayContentModeEnum.EDIT && eventToEdit) {
      const eventToDeleteId = eventToEdit.originalEvent?.id || eventToEdit.id;

      await saveEventMutations.deleteAllEventOccurrences({
        variables: {
          deleteAllEventOccurrencesInput: {
            channel: channelKey,
            originalEventId: eventToDeleteId,
          },
        },
      });
    }

    await getEvents({
      variables: {
        getEventsInput: {
          channel: channelKey,
          month: currentDate.month() + 1,
          year: currentDate.year(),
        },
      },
    });
  }, [
    channelKey,
    currentDate,
    dayContentMode,
    eventToEdit,
    getEvents,
    saveEventMutations,
  ]);

  const handleSave = useCallback(
    () =>
      saveEvent({
        alarm: eventFormStates.alarm,
        channel: channelKey,
        comments: eventFormStates.comments,
        dateBegin: eventFormStates.dateBegin,
        dateEnd: eventFormStates.dateEnd,
        daysOfRepeat: eventFormStates.daysOfRepeat,
        hasRecurrence: eventFormStates.hasRecurrence,
        isAllDay: eventFormStates.isAllDay,
        name: eventFormStates.name,
        occurrences: eventFormStates.occurrences,
        recurrenceType: eventFormStates.recurrenceType,
        recurrenceUntilDate: eventFormStates.recurrenceUntilDate,
        saveEventMutations,
        selectedDate,
        handleCreateEventSuccess,
        setEventFormErrors: eventFormStates.setEventFormErrors,
      }),
    [
      channelKey,
      eventFormStates,
      saveEventMutations,
      selectedDate,
      handleCreateEventSuccess,
    ],
  );

  const handleCancelClick = useCallback(
    () => setDayContentMode(undefined),
    [setDayContentMode],
  );

  const disabled = dayContentMode === DayContentModeEnum.VIEW;

  return (
    <EventFormContextProvider
      {...eventFormStates}
      disabled={disabled}
      handleDaysOfRepeatChange={handleDaysOfRepeatChange}
    >
      <EventFormPure
        comments={eventFormStates.comments}
        disabled={disabled}
        hasRecurrence={eventFormStates.hasRecurrence}
        isAllDay={eventFormStates.isAllDay}
        name={eventFormStates.name}
        setHasRecurrence={eventFormStates.setHasRecurrence}
        setIsAllDay={eventFormStates.setIsAllDay}
        setComments={eventFormStates.setComments}
        setName={eventFormStates.setName}
        handleSave={handleSave}
        handleCancelClick={handleCancelClick}
      />
    </EventFormContextProvider>
  );
};

export default EventForm;
