import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  button: {
    border: `2px solid ${COLORS.BLUE}`,
    borderRadius: 12,
    color: COLORS.BLUE,
    position: 'absolute',
    textTransform: 'initial',
    width: 250,

    '&:hover': {
      border: `2px solid ${COLORS.BLUE}`,
    },

    [theme.breakpoints.up('md')]: {
      bottom: 25,
      left: '50%',
      padding: '10px 0',
      transform: 'translate(-50%, 0)',
    },

    [theme.breakpoints.down('md')]: {
      border: '1px solid #2254B0',
      top: 10,
      fontSize: 12,
      padding: '5px 0',
      right: 10,
      width: 150,
    },
  },
}));
