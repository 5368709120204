import { MessageType } from '../../../../../../types/Message';
import { Grid } from '@mui/material';
import { formatDate, getFormattedEmailLabel } from '../AdminMessagesTable';
import { AdminMessagesTableDataTypeEnum } from '../AdminMessagesTableData';
import SelectMessageCheckbox from '../SelectMessageCheckbox';
import MessageBodyPreviewLoader from '../MessageBodyPreviewLoader';
import useStyles from './AdminMessagesMobileTableRow.styles';
import AttachmentIcon from '../../AttachmentIcon';

type AdminMessagesMobileTableRowProps = {
  message: MessageType;
  type: AdminMessagesTableDataTypeEnum;
  handleRowClick: (message: MessageType) => void;
};

const AdminMessagesMobileTableRow = ({
  message,
  type,
  handleRowClick,
}: AdminMessagesMobileTableRowProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" className={classes.container} container>
      <Grid item xs={2} />
      <Grid
        className={classes.email}
        item
        xs={8}
        onClick={() => handleRowClick(message)}
      >
        {getFormattedEmailLabel(message, type)}
      </Grid>
      <Grid
        className={classes.attachment}
        item
        xs={2}
        onClick={() => handleRowClick(message)}
      >
        <AttachmentIcon message={message} small />
      </Grid>

      <Grid item xs={2}>
        <SelectMessageCheckbox message={message} />
      </Grid>
      <Grid item xs={7} onClick={() => handleRowClick(message)}>
        {message.subject}
      </Grid>
      <Grid
        className={classes.date}
        item
        xs={3}
        onClick={() => handleRowClick(message)}
      >
        {formatDate(message.date)}
      </Grid>

      <Grid item xs={2} />
      <Grid
        className={classes.message}
        item
        xs={10}
        onClick={() => handleRowClick(message)}
      >
        <MessageBodyPreviewLoader message={message} />
      </Grid>
    </Grid>
  );
};

export default AdminMessagesMobileTableRow;
