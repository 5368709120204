import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  label: {
    color: COLORS.BLUE,
    display: 'inline-block',
    fontSize: 14,

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  labelContainer: {
    paddingBottom: 5,
    paddingTop: 15,
  },
}));
