import React, { useCallback, useContext } from 'react';
import { MessageType } from '../../../../../types/Message';
import { Checkbox } from '@mui/material';
import { adminMessagesContext } from '../AdminMessagesContext';
import useStyles from './SelectMessageCheckbox.styles';

type SelectMessageCheckboxPureProps = {
  isChecked: boolean;
  handleCheckChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

const SelectMessageCheckboxPure = ({
  isChecked,
  handleCheckChange,
}: SelectMessageCheckboxPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Checkbox
      checked={isChecked}
      className={classes.checkbox}
      onChange={handleCheckChange}
    />
  );
};

type SelectMessageCheckboxProps = {
  message: MessageType;
};

const SelectMessageCheckbox = ({
  message,
}: SelectMessageCheckboxProps): JSX.Element => {
  const { selectedMessages, updateSelectedMessages } =
    useContext(adminMessagesContext);

  const handleCheckChange = useCallback(
    (_e, checked) => updateSelectedMessages(message, checked),
    [message, updateSelectedMessages],
  );

  const isChecked = !!selectedMessages.find((_) => _.id === message.id);

  return (
    <SelectMessageCheckboxPure
      isChecked={isChecked}
      handleCheckChange={handleCheckChange}
    />
  );
};

export default SelectMessageCheckbox;
