import { ReactNode } from 'react';
import useStyles from './Step.styles';
import clsx from 'clsx';
import { UseStylesProps } from '../../../../utils/useStyles';

type StepPureProps = StepProps & UseStylesProps;

const StepPure = ({ classes, content, title }: StepPureProps): JSX.Element => (
  <div>
    <div className={clsx([classes.title, 'title'])}>{title}</div>
    <div className={classes.text}>{content}</div>
  </div>
);

interface StepProps {
  content: ReactNode;
  title: ReactNode;
}

const Step = (props: StepProps): JSX.Element => {
  const classes = useStyles();
  return <StepPure classes={classes} {...props} />;
};

export default Step;
