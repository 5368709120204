import { createContext, useState } from 'react';
import { emptyFunction } from '../../../utils/Functions';
import { StateSetter } from '../../../types/utils/React';
import { VideoCallType } from '../../../types/videocall/VideoCall';

interface VideoCallContextProps {
  api: any;
  isFullScreen: boolean;
  isPreview: boolean;
  numberOfParticipants: number;
  videoCall: VideoCallType | undefined;
  setApi: StateSetter<any>;
  setIsFullScreen: StateSetter<boolean>;
  setIsPreview: StateSetter<boolean>;
  setNumberOfParticipants: StateSetter<number>;
  setVideoCall: StateSetter<VideoCallType | undefined>;
}

const initialState = {
  api: undefined,
  isFullScreen: false,
  isPreview: true,
  numberOfParticipants: 1,
  videoCall: undefined,
  setApi: emptyFunction,
  setIsFullScreen: emptyFunction,
  setIsPreview: emptyFunction,
  setNumberOfParticipants: emptyFunction,
  setVideoCall: emptyFunction,
};

const videoCallContext = createContext<VideoCallContextProps>(initialState),
  { Provider } = videoCallContext;

interface VideoCallContextProviderProps {
  mockedApi?: any;
  children: React.ReactNode;
  isPreview: boolean;
  videoCall: VideoCallType | undefined;
  setIsPreview: StateSetter<boolean>;
  setVideoCall: StateSetter<VideoCallType | undefined>;
}

const VideoCallContextProvider = ({
  children,
  isPreview,
  mockedApi,
  videoCall,
  setIsPreview,
  setVideoCall,
}: VideoCallContextProviderProps): JSX.Element => {
  const [api, setApi] = useState<any>(mockedApi);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(1);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  return (
    <Provider
      value={{
        api,
        isFullScreen,
        isPreview,
        numberOfParticipants,
        videoCall,
        setApi,
        setIsFullScreen,
        setIsPreview,
        setNumberOfParticipants,
        setVideoCall,
      }}
    >
      {children}
    </Provider>
  );
};

export { videoCallContext, VideoCallContextProvider };
