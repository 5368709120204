export const adminMessagesMessages = {
  'admin.messages.title': 'Boite de réception de {firstName} {lastName}',
  'admin.messages.error.query': 'Erreur lors de la récupération des emails',

  'admin.messages.filter.filtered': 'Filtrés',
  'admin.messages.filter.received': 'Reçus',
  'admin.messages.filter.sent': 'Envoyés',
  'admin.messages.noMessage': 'Aucun message',

  'admin.messages.column.date': 'Date',
  'admin.messages.column.message': 'Message',
  'admin.messages.column.recipient': 'Destinataire',
  'admin.messages.column.sender': 'Expéditeur',

  'admin.messages.delete.multiple.error':
    'Erreur lors de la suppression des messages',
  'admin.messages.delete.multiple.partialSuccess':
    "Certains messages n'ont pas pu être supprimés",
  'admin.messages.delete.multiple.success': 'Messages supprimés',

  'admin.messages.delete.unique.error':
    'Erreur lors de la suppression du message',
  'admin.messages.delete.unique.success': 'Message supprimé',

  'admin.messages.messageView.from': 'De',
  'admin.messages.messageView.subject': 'Objet',
  'admin.messages.messageView.to': 'À',

  'admin.messages.messageView.attachments': 'Pièces jointes',
};
