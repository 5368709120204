enum Route {
  ROOT = '/',
  ERROR = '/error',
  HOME = '/:selectedChannel',

  PROFILE = '/:selectedChannel/profil',
  ACCEPT_RELATIONSHIP = '/association/:uuid',

  COMMUNICATE = '/:selectedChannel/communiquer',
  POSTCARD = '/:selectedChannel/communiquer/carte-postale',
  VIDEO_CALL = '/:selectedChannel/communiquer/visio/:videoCallID',

  SHARE = '/:selectedChannel/partager',

  ORGANIZE = '/:selectedChannel/organiser',

  FAMILY_PORTAL_TUTORIALS = '/:selectedChannel/accompagner/documents-aide/tutoriels-site-famille',
  GETTING_STARTED = '/:selectedChannel/accompagner/documents-aide/guides-de-demarrage',
  HELP = '/:selectedChannel/accompagner',
  HELP_DOCUMENTS = '/:selectedChannel/accompagner/documents-aide',
  PARTNER_CONTENTS = '/:selectedChannel/contenus-partenaire/:partnerId',
  PARTNER_LIST = '/:selectedChannel/partenaires',
  TABLET_TUTORIALS = '/:selectedChannel/accompagner/documents-aide/tutoriels-ardoiz',
  TEAMVIEWER = '/:selectedChannel/accompagner/teamviewer',

  ADMIN = '/:selectedChannel/administration',
  ADMIN_MESSAGES = '/:selectedChannel/administration/messages',
  ADMIN_MESSAGE_VIEWER = '/:selectedChannel/administration/messages/:key',
  ADMIN_WORKBOOK = '/:selectedChannel/administration/classeur',
  ADMIN_ORGANIZER = '/:selectedChannel/administration/agenda',
  ADMIN_BOOKMARKS = '/:selectedChannel/administration/favoris',
  ADMIN_APPLICATIONS = '/:selectedChannel/administration/applications',
  ADMIN_SETTINGS = '/:selectedChannel/administration/reglages',
  ADMIN_CONTACTS = '/:selectedChannel/administration/contacts',
}

export default Route;

export enum Params {
  KEY = 'key',
  PARTNER_ID = 'partnerId',
  SELECTED_CHANNEL = 'selectedChannel',
  UUID = 'uuid',
  VIDEO_CALL_ID = 'videoCallID',
}

type ParamsToReplaceType = Partial<Record<Params, string>>;

const reduceParams = (
  currentRoute: string,
  tuple: [string, string],
): string => {
  const [key, value] = tuple;
  const replaceValue = value ? `/${value}` : value;

  return currentRoute.replace(`/:${key}`, replaceValue);
};

export const replaceRouteParams = (
  route: Route,
  paramsToReplace: ParamsToReplaceType,
): string => Object.entries(paramsToReplace).reduce(reduceParams, route);
