import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './RelationshipTextField.styles';
import { RelationshipSuccessMessage } from '../../App/OnBoarding/OnBoardingTour/Relationship/RelationshipSuccessMessage';

type OnBoardingRelationshipTextFieldProps = {
  email: string;
  inError: boolean;
  relationshipHasBeenAdded: boolean;
  setEmail: (newEmail: string) => void;
};

const RelationshipTextField = ({
  email,
  inError,
  relationshipHasBeenAdded,
  setEmail,
}: OnBoardingRelationshipTextFieldProps): JSX.Element => {
  const classes = useStyles();

  const helperText = inError ? (
    <FormattedMessage id="relationship.textfield.error" />
  ) : undefined;

  return (
    <>
      <TextField
        className={classes.textField}
        error={inError}
        helperText={helperText}
        value={email}
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
      />

      <RelationshipSuccessMessage
        className={classes.successMessage}
        show={relationshipHasBeenAdded}
      />
    </>
  );
};

export default RelationshipTextField;
