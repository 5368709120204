import { ReactNode, useContext } from 'react';
import { Alert as MUIAlert, Slide, Snackbar } from '@mui/material';
import { alertContext, DEFAULT_TRANSITION_DURATION } from './AlertContext';
import { StateSetter } from '../../types/utils/React';
import useStyles from './AlertContainer.styles';
import { withReactContext } from 'storybook-react-context';
import { AlertColor } from '@mui/material/Alert/Alert';

type AlertContainerPureProps = {
  autoHideDuration: number | null;
  children: ReactNode;
  open: boolean;
  severity: AlertColor;
  transitionDuration?: number;
  setOpen: StateSetter<boolean>;
};

export const AlertContainerPure = ({
  autoHideDuration,
  children,
  open,
  severity,
  transitionDuration,
  setOpen,
}: AlertContainerPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={autoHideDuration}
      className={classes.container}
      open={open}
      transitionDuration={transitionDuration || DEFAULT_TRANSITION_DURATION}
      TransitionComponent={Slide}
      onClose={() => setOpen(false)}
    >
      <MUIAlert
        className={classes.alert}
        data-test-id="alert"
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={() => setOpen(false)}
      >
        {children}
      </MUIAlert>
    </Snackbar>
  );
};

const AlertContainer = (): JSX.Element | null => {
  const {
    autoHideDuration,
    message,
    open,
    severity,
    transitionDuration,
    setOpen,
  } = useContext(alertContext);

  if (!message || !severity) {
    return null;
  }

  return (
    <AlertContainerPure
      autoHideDuration={autoHideDuration}
      open={open}
      severity={severity}
      transitionDuration={transitionDuration}
      setOpen={setOpen}
    >
      {message}
    </AlertContainerPure>
  );
};

AlertContainer.decorators = [
  withReactContext({
    initialState: {
      title: 'Initial #1',
    },
  }),
];

export default AlertContainer;
