import { useCallback } from 'react';
import useStyles from './PartnerBookmarkItem.styles';
import { PartnerDocumentType } from '../../../../../../types/partnerdocuments/PartnerDocument';

type PartnerBookmarkItemProps = {
  bookmark: PartnerDocumentType;
};

const PartnerBookmarkItem = ({
  bookmark,
}: PartnerBookmarkItemProps): JSX.Element => {
  const classes = useStyles();
  const handleBookmarkClick = useCallback(
    () => window.open(bookmark.uri),
    [bookmark.uri],
  );

  return (
    <div className={classes.container}>
      <div className={classes.title}>{bookmark.name}</div>

      <div className={classes.bookmark} onClick={handleBookmarkClick}>
        {bookmark.uri}
      </div>
    </div>
  );
};

export default PartnerBookmarkItem;
