import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: 15,
  },
  select: {
    width: '80%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 8,
    },

    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderSize: 1,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
