import { createContext, ReactNode } from 'react';
import { StateSetter } from '../../../../../types/utils/React';
import { emptyFunction } from '../../../../../utils/Functions';

export type CreateFolderModalContextProps = {
  contacts: string[];
  folderName: string;
  textFieldError?: string;
  resetCreateFolderFields: () => void;
  setContacts: StateSetter<string[]>;
  setFolderName: StateSetter<string>;
  setTextFieldError: StateSetter<string | undefined>;
};

const initialState = {
  contacts: [],
  folderName: '',
  textFieldError: undefined,
  resetCreateFolderFields: emptyFunction,
  setContacts: emptyFunction,
  setFolderName: emptyFunction,
  setTextFieldError: emptyFunction,
};

const createFolderModalContext =
    createContext<CreateFolderModalContextProps>(initialState),
  { Provider } = createFolderModalContext;

type CreateFolderModalContextProviderProps = {
  children: ReactNode;
  contacts: string[];
  folderName: string;
  textFieldError?: string;
  resetCreateFolderFields: () => void;
  setContacts: StateSetter<string[]>;
  setFolderName: StateSetter<string>;
  setTextFieldError: StateSetter<string | undefined>;
};

const CreateFolderModalContextProvider = ({
  children,
  contacts,
  folderName,
  textFieldError,
  resetCreateFolderFields,
  setContacts,
  setFolderName,
  setTextFieldError,
}: CreateFolderModalContextProviderProps): JSX.Element => (
  <Provider
    value={{
      contacts,
      folderName,
      textFieldError,
      resetCreateFolderFields,
      setContacts,
      setFolderName,
      setTextFieldError,
    }}
  >
    {children}
  </Provider>
);

export { createFolderModalContext, CreateFolderModalContextProvider };
