import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  label: {
    '& .MuiFormControlLabel-label, & .MuiFormControlLabel-label.Mui-disabled': {
      color: COLORS.BLUE,
      fontSize: 14,
      textTransform: 'capitalize',
    },
  },
  radio: {
    color: COLORS.BLUE,

    '&.Mui-checked': {
      color: COLORS.BLUE,
    },
  },
}));
