import { useCallback, useContext } from 'react';
import { Button, Paper } from '@mui/material';
import useStyles from './DeleteEventModal.styles';
import { organizerContext } from '../../../OrganizerContext';
import {
  EventType,
  RecurrenceTypeEnum,
} from '../../../../../../types/event/Event';
import DeleteRecurrentEventModal from './DeleteRecurrentEventModal';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../../components/Button/FilledButton';
import { useMutation } from '@apollo/client';
import {
  DELETE_ALL_EVENT_OCCURRENCES_MUTATION,
  DELETE_EVENT_OCCURRENCE_MUTATION,
  DeleteAllEventOccurrencesOutputOrError,
  DeleteEventOccurrenceOutputOrError,
} from '../../../../../../graphql/event/EventMutations';
import { useSelectedLinkContext } from '../../../../../../Context/SelectedLinkContext';
import { alertContext } from '../../../../../../components/Alert/AlertContext';
import { MutationFunction } from '../../../../../../types/utils/GraphQL';
import { LinkType } from '../../../../../../types/Link';

type DeleteEventModalPureProps = {
  handleDelete: () => void;
  handleHide: () => void;
};

export const DeleteEventModalPure = ({
  handleDelete,
  handleHide,
}: DeleteEventModalPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.deletionMessage}>
            <FormattedMessage id="event.delete.confirm" />
          </div>

          <div className={classes.buttonToolbar}>
            <FilledButton
              className={classes.deleteButton}
              color="red"
              size="small"
              onClick={handleDelete}
            >
              <FormattedMessage id="button.delete" />
            </FilledButton>

            <Button className={classes.cancelButton} onClick={handleHide}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export enum DeleteEventOccurrenceEnum {
  ALL_OCCURRENCES = 'ALL_OCCURRENCES',
  THIS_AND_LATER_OCCURRENCES = 'THIS_AND_LATER_OCCURRENCES',
  THIS_OCCURRENCE = 'THIS_OCCURRENCE',
}

type DeleteEventInputType = {
  deleteEventOccurrenceType: DeleteEventOccurrenceEnum;
  eventToDelete: EventType;
  selectedLink: LinkType;
  deleteAllEventOccurrences: MutationFunction<DeleteAllEventOccurrencesOutputOrError>;
  deleteEventOccurrence: MutationFunction<DeleteEventOccurrenceOutputOrError>;
};

const deleteEvent = ({
  deleteEventOccurrenceType,
  eventToDelete,
  selectedLink,
  deleteAllEventOccurrences,
  deleteEventOccurrence,
}: DeleteEventInputType): Promise<any> => {
  const eventToDeleteId = eventToDelete.originalEvent?.id || eventToDelete.id;

  switch (deleteEventOccurrenceType) {
    case DeleteEventOccurrenceEnum.THIS_AND_LATER_OCCURRENCES:
    case DeleteEventOccurrenceEnum.THIS_OCCURRENCE:
      return deleteEventOccurrence({
        variables: {
          deleteEventOccurrenceInput: {
            channel: selectedLink.channelKey,
            deleteEventOccurrenceType,
            originalEventId: eventToDeleteId,
            timestamp: eventToDelete.date,
          },
        },
      });

    case DeleteEventOccurrenceEnum.ALL_OCCURRENCES:
      return deleteAllEventOccurrences({
        variables: {
          deleteAllEventOccurrencesInput: {
            channel: selectedLink.channelKey,
            originalEventId: eventToDeleteId,
          },
        },
      });
  }
};

const DeleteEventModal = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { eventToDelete, setEventToDelete } = useContext(organizerContext);
  const [deleteAllEventOccurrences] =
    useMutation<DeleteAllEventOccurrencesOutputOrError>(
      DELETE_ALL_EVENT_OCCURRENCES_MUTATION,
      {
        refetchQueries: ['GetEvents'],
      },
    );
  const [deleteEventOccurrence] =
    useMutation<DeleteEventOccurrenceOutputOrError>(
      DELETE_EVENT_OCCURRENCE_MUTATION,
      {
        refetchQueries: ['GetEvents'],
      },
    );
  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);

  const handleHide = useCallback(
    () => setEventToDelete(undefined),
    [setEventToDelete],
  );

  const handleDelete = useCallback(
    (deleteEventOccurrenceType: DeleteEventOccurrenceEnum) => {
      if (eventToDelete && selectedLink) {
        deleteEvent({
          deleteEventOccurrenceType,
          eventToDelete,
          selectedLink,
          deleteAllEventOccurrences,
          deleteEventOccurrence,
        }).then(() => {
          setEventToDelete(undefined);
          showSuccessMessage(<FormattedMessage id="event.delete.success" />);
        });
      } else {
        showErrorMessage(<FormattedMessage id="event.delete.error" />);
      }
    },
    [
      eventToDelete,
      selectedLink,
      deleteAllEventOccurrences,
      deleteEventOccurrence,
      setEventToDelete,
      showErrorMessage,
      showSuccessMessage,
    ],
  );

  if (!eventToDelete) {
    return null;
  }

  if (eventToDelete.recurrence !== RecurrenceTypeEnum.NONE) {
    return (
      <DeleteRecurrentEventModal
        handleDelete={handleDelete}
        handleHide={handleHide}
      />
    );
  }

  return (
    <DeleteEventModalPure
      handleDelete={() =>
        handleDelete(DeleteEventOccurrenceEnum.ALL_OCCURRENCES)
      }
      handleHide={handleHide}
    />
  );
};

export default DeleteEventModal;
