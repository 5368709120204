import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    background: COLORS.WHITE,
    height: '100%',
    position: 'fixed',
    width: '100%',
    zIndex: 10,
  },
  image: {
    background: 'transparent center/100% url("../img/loading.png") no-repeat',
    height: '60%',
    margin: 'auto',
    width: '25%',

    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  text: {
    fontSize: 40,
    marginLeft: '15%',
    paddingTop: '5%',

    [theme.breakpoints.down('md')]: {
      fontSize: 25,
    },
  },
}));
