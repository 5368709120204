import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    color: COLORS.WHITE,
    zIndex: 10,
  },
  mask: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
}));
