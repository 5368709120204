import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    maxWidth: '100vw',
  },
  hidden: {
    display: 'none',
  },
}));
