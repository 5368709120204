import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 12,
    paddingLeft: 20,
    paddingTop: 10,
  },
  icon: {
    height: 15,
  },
  label: {
    textTransform: 'lowercase',
  },
}));
