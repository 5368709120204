import { makeStyles } from '@mui/styles';
import { MOBILE_POSTCARD_IMAGE_SIZE } from './MobilePostcardWidget';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  actionButton: {
    marginLeft: 15,
  },
  actionButtonSend: {
    float: 'right',
    marginBottom: 15,
    marginRight: 15,
    width: 100,
  },
  content: {
    border: `1px solid ${COLORS.BLACK}`,
    borderRadius: 10,
    height: 160,
    padding: 10,
    width: '100%',

    '& .MuiInput-underline:after, & .MuiInput-underline:before, & .MuiInput-underline:hover:before':
      {
        border: 'none',
        outline: 'none',
      },
  },
  contentContainer: {
    display: 'flex',
    padding: '10px 15px',
  },
  dragZone: {
    height: MOBILE_POSTCARD_IMAGE_SIZE.HEIGHT,
    margin: 15,
    width: MOBILE_POSTCARD_IMAGE_SIZE.WIDTH,
  },
  fadedImage: {
    opacity: 0.5,
  },
  image: {
    height: MOBILE_POSTCARD_IMAGE_SIZE.HEIGHT,
    width: MOBILE_POSTCARD_IMAGE_SIZE.WIDTH,
  },
}));
