import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    borderRadius: 16,
    display: 'inline-block',
    padding: '10px 0',
    width: 250,
    wordBreak: 'break-word',
  },
  imageContainer: {
    padding: 10,
    position: 'relative',
    textAlign: 'center',
  },
  labelContainer: {
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'center',
  },
  selectedDocumentPreview: {
    opacity: 0.5,
  },
  selectingContainer: {
    cursor: 'pointer',
  },
}));
