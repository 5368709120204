import { WebUserType } from '../User';
import { LinkType } from '../Link';
import { isSelfLink } from '../../utils/Link';

export type ParticipantType = {
  id: string;
  firstName: string;
  lastName: string;
  links?: Array<LinkType>;
};

export type VideoCallQueryOutputType = {
  id: string;
  called: ParticipantType;
  calledID: string;
  caller: ParticipantType;
  callerID: string;
  command: {
    currentState: VideoCallState;
  };
  roomName: string;
};

export type VideoCallType = {
  id: string;
  called: ParticipantType;
  caller: ParticipantType;
  command: {
    currentState: VideoCallState;
  };
  missingCallNotificationHasBeenSent?: boolean;
  participantType: VideoCallParticipantType;
  roomName: string;
};

export enum VideoCallState {
  Initial = 'Initial',
  WaitingNotificationAcknowledge = 'WaitingNotificationAcknowledge',
  NotificationAcknowledgeTimeout = 'NotificationAcknowledgeTimeout',
  NotificationAcknowledgeBusy = 'NotificationAcknowledgeBusy',
  WaitingCalledAnswer = 'WaitingCalledAnswer',
  CallRefused = 'CallRefused',
  CalledAnswerTimeout = 'CalledAnswerTimeout',
  WaitingCallStart = 'WaitingCallStart',
  CallStartTimeout = 'CallStartTimeout',
  Running = 'Running',
  Ended = 'Ended',
  Interrupted = 'Interrupted',
  EXIT_WITH_ERROR = 'EXIT_WITH_ERROR',
}

export enum VideoCallParticipantType {
  CALLED,
  CALLER,
}

export const getVideoCallFromVideoCallQueryOutput = (
  videoCallQueryOutput: VideoCallQueryOutputType | undefined,
  webUser: WebUserType,
  missingCallNotificationHasBeenSent?: boolean,
): VideoCallType | undefined => {
  if (!videoCallQueryOutput) {
    return;
  }

  const { calledID, callerID, ...otherProps } = videoCallQueryOutput;
  const participantType =
    callerID === webUser.id
      ? VideoCallParticipantType.CALLER
      : VideoCallParticipantType.CALLED;

  return {
    ...otherProps,
    missingCallNotificationHasBeenSent,
    participantType,
  };
};

export const getParticipantFullName = (participant: ParticipantType): string =>
  `${participant.firstName} ${participant.lastName}`;

export const getOtherParticipantFullName = (
  videoCall?: VideoCallType,
): string | undefined => {
  if (videoCall) {
    return videoCall.participantType === VideoCallParticipantType.CALLED
      ? getParticipantFullName(videoCall.caller)
      : getParticipantFullName(videoCall.called);
  }
};

export const calledHasSelfLink = (videoCall?: VideoCallType): boolean => {
  if (videoCall) {
    return !!videoCall.called.links?.find((link) => isSelfLink(link));
  }
  return false;
};
