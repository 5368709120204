import { useCallback } from 'react';
import {
  PartnerAccountType,
  PartnerProfileType,
} from '../../../../types/partnerdocuments/PartnerAccount';
import { Paper } from '@mui/material';
import useStyles from './PartnerListItem.styles';
import Image from '../../../../components/Image/Image';
import FilledButton from '../../../../components/Button/FilledButton';
import { goToPartnerContentsPage } from '../../../../utils/history';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';

type PartnerListItemPureProps = {
  partnerProfile: PartnerProfileType;
  handlePartnerItemClick: () => void;
};

const PartnerListItemPure = ({
  partnerProfile,
  handlePartnerItemClick,
}: PartnerListItemPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Image
        className={classes.image}
        src={partnerProfile.thumbnailBlob.s3URL}
      />

      <div className={classes.name}>{partnerProfile.name}</div>

      <FilledButton
        className={classes.button}
        color="green"
        onClick={handlePartnerItemClick}
      >
        Découvrir
      </FilledButton>
    </Paper>
  );
};

type PartnerListItemProps = {
  partnerAccount: PartnerAccountType;
};

const PartnerListItem = ({
  partnerAccount,
}: PartnerListItemProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();

  const handlePartnerItemClick = useCallback(
    () => goToPartnerContentsPage(selectedLink, partnerAccount.id),
    [partnerAccount, selectedLink],
  );

  return (
    <PartnerListItemPure
      partnerProfile={partnerAccount.partnerProfile}
      handlePartnerItemClick={handlePartnerItemClick}
    />
  );
};

export default PartnerListItem;
