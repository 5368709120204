import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    cursor: 'pointer',
  },
  icon: {
    float: 'left',
  },
  profileText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'end',
  },
  selected: {
    border: `2px solid ${COLORS.VIOLET}`,
  },
}));
