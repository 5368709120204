import { Crop } from 'react-image-crop';

type ResizeDataURLInputType = {
  dataURL: string;
  targetHeight: number;
  targetWidth: number;
};

export const resizeDataURL = ({
  dataURL,
  targetHeight,
  targetWidth,
}: ResizeDataURLInputType): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      const imageOldWidth = img.width;
      const imageOldHeight = img.height;

      const imageOldRatio = imageOldWidth / imageOldHeight;
      const targetRatio = targetWidth / targetHeight;

      let imageNewWidth;
      let imageNewHeight;

      if (targetRatio > imageOldRatio && imageOldHeight > targetHeight) {
        // Image is vertically too big
        imageNewHeight = targetHeight;
        imageNewWidth = targetHeight * imageOldRatio;
      } else if (targetRatio < imageOldRatio && imageOldWidth > targetWidth) {
        // Image is horizontally too big
        imageNewWidth = targetWidth;
        imageNewHeight = targetWidth / imageOldRatio;
      } else if (imageOldWidth > targetWidth) {
        // Ratio is good but image is too big
        imageNewWidth = targetWidth;
        imageNewHeight = targetHeight;
      } else {
        // Image is smaller than target size
        imageNewWidth = imageOldWidth;
        imageNewHeight = imageOldHeight;
      }

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');

      if (ctx) {
        // We draw new image with a gap if needed to center it and prevent image distortion
        ctx.drawImage(
          img,
          0,
          0,
          imageOldWidth,
          imageOldHeight,
          (targetWidth - imageNewWidth) / 2,
          (targetHeight - imageNewHeight) / 2,
          imageNewWidth,
          imageNewHeight,
        );

        const newDataURL = canvas.toDataURL('image/png');
        resolve(newDataURL);
      } else {
        reject('Error resizing image');
      }
    };
    img.src = dataURL;
  });

export type CropHTMLImageElementInputType = {
  crop: Crop;
  img: HTMLImageElement;
  targetHeight: number;
  targetWidth: number;
};

export const cropHTMLImageElement = ({
  crop,
  img,
  targetHeight,
  targetWidth,
}: CropHTMLImageElementInputType): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const imageOldWidth = img.width;
    const imageOldHeight = img.height;

    const canvas = document.createElement('canvas');
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext('2d');

    if (ctx) {
      // We draw new image with a gap if needed to center it and prevent image distortion
      ctx.drawImage(
        img,
        crop.x || 0,
        crop.y || 0,
        crop.width || imageOldWidth,
        crop.height || imageOldHeight,
        0,
        0,
        targetWidth,
        targetHeight,
      );

      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    } else {
      reject('Error resizing image');
    }
  });

export type CropDataURLInputType = {
  crop: Crop;
  dataURL: string;
  targetHeight: number;
  targetWidth: number;
};

export const cropDataURL = ({
  crop,
  dataURL,
  targetHeight,
  targetWidth,
}: CropDataURLInputType): Promise<string> =>
  new Promise<string>((resolve, _reject) => {
    const img = document.createElement('img');

    img.onload = async (): Promise<void> => {
      const newDataURL = await cropHTMLImageElement({
        crop,
        img,
        targetHeight,
        targetWidth,
      });

      resolve(newDataURL);
    };

    img.src = dataURL;
  });

export const dataURLtoBlob = (dataURL: string, contentType: string): Blob => {
  const binary = atob(dataURL.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: contentType });
};
