import { useContext } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import useStyles from '../Modals.styles';
import { FormattedMessage } from 'react-intl';
import Image from '../../../../../components/Image/Image';
import CloseImage from '../../../../../images/icons/close.png';
import FolderContactsSelect from '../CreateFolder/CreateFolderModal/FolderContactsSelect';
import ExistingContacts from './ExistingContacts';
import clsx from 'clsx';
import ValidateButton from './ValidateButton';
import { editContactsContext } from './EditContactsContext';
import { StateSetter } from '../../../../../types/utils/React';

type EditContactsModalPureProps = {
  contacts: string[];
  setContacts: StateSetter<string[]>;
  handleClose: () => void;
};

const EditContactsModalPure = ({
  contacts,
  setContacts,
  handleClose,
}: EditContactsModalPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper className={classes.modal}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              className={classes.closeButton}
              data-test-id="close-button"
              onClick={handleClose}
            >
              <Image src={CloseImage} />
            </Button>
          </Grid>

          <ExistingContacts />

          <Grid className={clsx([classes.text, classes.padding])} item xs={12}>
            <FormattedMessage id="share.folder.contacts.edit.share_with" />
          </Grid>

          <Grid className={classes.contactsSelectContainer} item xs={12}>
            <FolderContactsSelect
              contacts={contacts}
              setContacts={setContacts}
            />
          </Grid>

          <ValidateButton />
        </Grid>
      </Paper>
    </div>
  );
};

const EditContactsModal = (): JSX.Element | null => {
  const {
    contacts,
    showEditContactsModal,
    setContacts,
    setShowEditContactsModal,
  } = useContext(editContactsContext);

  if (!showEditContactsModal) {
    return null;
  }

  return (
    <EditContactsModalPure
      contacts={contacts}
      setContacts={setContacts}
      handleClose={() => setShowEditContactsModal(false)}
    />
  );
};

export default EditContactsModal;
