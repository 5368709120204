import { LinkType } from '../types/Link';
import { createCtxWithMandatoryValue } from './utils';

export type HandleSelectedLinkChangeInputProps = {
  isAdmin?: boolean;
  newSelectedLink: LinkType;
};

export type HandleSelectedLinkChangeType = (
  input: HandleSelectedLinkChangeInputProps,
) => void;

interface SelectedLinkContextProps {
  selectedLink?: LinkType;
  handleSelectedLinkChange: HandleSelectedLinkChangeType;
}

const selectedLinkContext =
    createCtxWithMandatoryValue<SelectedLinkContextProps>(),
  [useSelectedLinkContext, SelectedLinkContextProvider] = selectedLinkContext;

export { useSelectedLinkContext, SelectedLinkContextProvider };
