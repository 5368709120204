import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  changeModeButton: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    fontSize: 13,
    padding: '5px',
    textTransform: 'none',
    width: '100%',

    '&:hover': {
      backgroundColor: COLORS.BLUE,
    },
  },
  changeModeButtonContainer: {
    position: 'absolute',

    [theme.breakpoints.down('md')]: {
      left: '50%',
      top: '80%',
      transform: 'translate(-54%, 0)',
    },

    [theme.breakpoints.up('md')]: {
      left: '7%',
      top: '77%',
      width: 200,
    },
  },
  changeModeContainer: {
    [theme.breakpoints.up(1480)]: {
      position: 'relative',
      width: 260,
    },
  },
  changeModeSubContainer: {
    position: 'relative',
    textAlign: 'center',
  },
  changeModeImage: {
    width: '100%',

    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  selected: {
    border: `2px solid ${COLORS.BLUE}`,
  },
}));
