import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  button: {
    fontWeight: 600,
    margin: '20px 0',
    padding: '15px 50px',
    textTransform: 'none',
  },
  firstGrid: {
    marginTop: 30,
    padding: 10,
  },
  launchApp: {
    color: COLORS.VIOLET,
    fontWeight: 600,
  },
  p: {
    padding: '10px 0',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
  secondGrid: {
    padding: 10,
  },
  teamviewerButtonContainer: {
    padding: 10,

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));
