import { createTheme } from '@mui/material';

export enum COLORS {
  BLACK = '#000000',
  BLUE = '#2254B0',
  DARKER_BLUE = '#152B50',
  DARK_BLUE = '#1E3863',
  DARK_RED = '#C0392B',
  GREEN = '#00B894',
  GREY = '#555',
  LIGHT_BLUE = '#F5FAFE',
  LIGHT_GREEN = 'rgba(0, 184, 148, 0.5)',
  LIGHT_GREY = '#EEEEEE',
  LIGHT_VIOLET = '#F2F3FF',
  LIGHTER_GREY = '#F3F3F3',
  LIGHT_RED = 'rgba(237, 106, 90, 0.5)',
  LIGHT_YELLOW = 'rgba(242, 205, 93, 0.5)',
  MAIN = '#172B4D',
  MEDIUM_GREY = '#EBEBEB',
  ORANGE = '#FF694C',
  RED = '#ED6A5A',
  SECONDARY = '#11CDEF',
  TRANSPARENT_BLUE = 'rgba(34, 84, 176, 0.2)',
  VIOLET = '#6C5CE7',
  WHITE = '#FFFFFF',
  YELLOW = '#F2CD5D',
}

const MainTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: COLORS.BLUE,
    },
    secondary: {
      main: COLORS.GREEN,
    },
  },
  typography: {
    fontFamily: 'Metropolis,Roboto,Helvetica,Arial,sans-serif',
    body1: {
      color: COLORS.MAIN,
      fontSize: 16,
    },
    body2: {
      color: 'white',
      fontSize: 16,
    },
    h2: {
      color: COLORS.MAIN,
      fontSize: 34,
      fontWeight: 800,
    },
    h3: {
      color: COLORS.VIOLET,
      fontSize: 34,
      fontWeight: 800,
    },
    subtitle1: {
      color: COLORS.MAIN,
      fontSize: 20,
    },
    subtitle2: {
      color: COLORS.BLACK,
      fontSize: 40,
      fontWeight: 800,
    },
  },
});

export const adminFontFamily = 'Roboto,Metropolis,sans-serif';

export default MainTheme;
