import { useContext } from 'react';
import EventFormLabel from './EventFormLabel';
import { eventFormContext } from './EventFormContext';
import { Grid } from '@mui/material';
import AlarmSelect from './AlarmSelect';

const AlarmFormPure = (): JSX.Element => (
  <>
    <EventFormLabel messageId="alarm" size={12} />
    <Grid item xs={12}>
      <AlarmSelect />
    </Grid>
  </>
);

const AlarmForm = (): JSX.Element | null => {
  const { isAllDay } = useContext(eventFormContext);

  if (isAllDay) {
    return null;
  }

  return <AlarmFormPure />;
};

export default AlarmForm;
