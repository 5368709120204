import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Grid } from '@mui/material';
import useModalStyles from '../Modals.styles';
import { shareContext } from '../../ShareContext';
import { useUserContext } from '../../../../../Context/UserContext';
import {
  getPersonFullName,
  WebUserContactType,
} from '../../../../../types/User';
import useStyles from './ExistingContacts.styles';
import { getAvatarProps } from '../../../../../utils/Avatar';

type ExistingContactsPureProps = { contacts: WebUserContactType[] };

const ExistingContactsPure = ({
  contacts,
}: ExistingContactsPureProps): JSX.Element => {
  const classes = useStyles();
  const modalClasses = useModalStyles();

  return (
    <>
      <Grid className={modalClasses.text} item xs={12}>
        <FormattedMessage id="share.folder.contacts.edit.already_shared" />
      </Grid>

      <Grid item xs={12}>
        <Grid
          className={classes.existingContacts}
          container
          justifyContent="center"
        >
          {contacts.map((_) => (
            <Grid className={classes.contact} item key={_.id} xs={3}>
              <Avatar {...getAvatarProps(_)} className={classes.avatar} />
              <div className={classes.contactName}>{getPersonFullName(_)}</div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

const ExistingContacts = (): JSX.Element | null => {
  const { selectedFolder } = useContext(shareContext);
  const { webUser } = useUserContext();
  const contacts = (selectedFolder?.sharedWith || []).filter(
    (_) => _.id !== webUser.id,
  );

  if (contacts.length === 0) {
    return null;
  }

  return <ExistingContactsPure contacts={contacts} />;
};

export default ExistingContacts;
