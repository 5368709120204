import { useCallback } from 'react';
import PageContentHeader from '../../../../../../components/PageContent/PageContentHeader';
import PageContentContainer from '../../../../../../components/PageContent/PageContentContainer';
import { FormattedMessage } from 'react-intl';
import { usePartnerContentContext } from './PartnerContentContext';
import PartnerContentArticle from './PartnerContentView/PartnerContentArticle';
import useStyles from './PartnerContentView.styles';
import { HandleHistoryBackType } from '../../../../../../components/HistoryBack/HistoryBackButton';
import clsx from 'clsx';
import PartnerContentArticleList from './PartnerContentView/PartnerContentArticleList';

type PartnerContentViewPureProps = {
  partnerAccountName: string;
  handleHistoryBack: HandleHistoryBackType;
};

const PartnerContentViewPure = ({
  partnerAccountName,
  handleHistoryBack,
}: PartnerContentViewPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <PageContentContainer>
      <PageContentHeader
        title={
          <FormattedMessage
            id="partner.publications.content.view.title"
            values={{
              partnerAccountName,
            }}
          />
        }
        handleHistoryBack={handleHistoryBack}
      />

      <div className={classes.container}>
        <div className={clsx([classes.item, classes.leftItem])}>
          <PartnerContentArticle />
        </div>
        <div className={clsx([classes.item, classes.rightItem])}>
          <PartnerContentArticleList />
        </div>
      </div>
    </PageContentContainer>
  );
};

const PartnerContentView = (): JSX.Element => {
  const { partnerAccount, setSelectedContent } = usePartnerContentContext();

  const handleHistoryBack = useCallback(
    () => setSelectedContent(undefined),
    [setSelectedContent],
  );

  return (
    <PartnerContentViewPure
      partnerAccountName={partnerAccount.partnerProfile.name}
      handleHistoryBack={handleHistoryBack}
    />
  );
};

export default PartnerContentView;
