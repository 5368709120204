import { makeStyles } from '@mui/styles';
import { POSTCARD_IMAGE_SIZE } from './Front';

export default makeStyles(() => ({
  container: {
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  dragZone: {
    height: POSTCARD_IMAGE_SIZE.HEIGHT,
    width: POSTCARD_IMAGE_SIZE.WIDTH,
  },
  fadedImage: {
    opacity: 0.5,
  },
  image: {
    height: POSTCARD_IMAGE_SIZE.HEIGHT,
    width: POSTCARD_IMAGE_SIZE.WIDTH,

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
    },
  },
}));
