import { Container, MenuList } from '@mui/material';
import { getGoToSelectedChannelPageFunction } from '../../../utils/history';
import EmailIcon from '../../../images/icons/email_outlined_violet.svg';
import DocumentsIcon from '../../../images/icons/documents_outlined_vert.svg';
import CalendarIcon from '../../../images/icons/calendar_outlined_rouge.svg';
import InfoIcon from '../../../images/icons/info_outlined_jaune.svg';
import { useContext } from 'react';
import useStyles from './Menu.styles';
import { tourContext } from '../../../Context/TourContext';
import clsx from 'clsx';
import {
  getBaseClassNameSuffixForStep,
  TOUR_STEPS,
} from '../../App/OnBoarding/OnBoardingTour';
import ChangeModeButton from './Menu/ChangeModeButton';
import Item from './Menu/Item';
import { MenuContextProvider } from '../../../Context/MenuContext';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';
import ChannelLinkSelect from '../../ChannelLink/ChannelLinkSelect';
import Route from '../../../routes/Route';
import { LinkType } from '../../../types/Link';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import ArdoizLogo from '../../ArdoizLogo';
import { StateSetter } from '../../../types/utils/React';
import { useAppContext } from '../../../Context/AppContext';
import AdminMenu from './AdminMenu';

type MenuPureProps = {
  isTourOpen: boolean;
  selectedLink?: LinkType;
  goToHomeClick: Function;
  setIsOpened?: StateSetter<boolean>;
};

export const MenuPure = ({
  isTourOpen,
  selectedLink,
  goToHomeClick,
  setIsOpened,
}: MenuPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <ArdoizLogo goToHomeClick={goToHomeClick} />

      {useIsSmallScreen() && (
        <Container>
          <ChannelLinkSelect />
        </Container>
      )}

      <MenuList className={clsx([classes.menu, isTourOpen && 'tour'])}>
        <Item
          disableIfNoSelectedLink={false}
          icon={EmailIcon}
          labelMessageId="category.communicate.label"
          step={TOUR_STEPS.COMMUNICATE}
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.COMMUNICATE,
          )}
        />

        <Item
          disableIfNoSelectedLink={true}
          icon={DocumentsIcon}
          labelMessageId="category.share.label"
          step={TOUR_STEPS.SHARE}
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.SHARE,
          )}
        />

        <Item
          disableIfNoSelectedLink={true}
          icon={CalendarIcon}
          labelMessageId="category.organize.label"
          step={TOUR_STEPS.ORGANIZE}
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ORGANIZE,
          )}
        />

        <Item
          disableIfNoSelectedLink={false}
          icon={InfoIcon}
          labelMessageId="category.help.label"
          step={TOUR_STEPS.HELP}
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.HELP,
          )}
        />

        <ChangeModeButton setIsMenuOpened={setIsOpened} />
      </MenuList>
    </Container>
  );
};

type MenuProps = {
  onMenuItemClick: (goToPageFn: Function) => void;
  setIsOpened?: StateSetter<boolean>;
};

const Menu = ({ onMenuItemClick, setIsOpened }: MenuProps): JSX.Element => {
  const { currentStep, isTourOpen } = useContext(tourContext);
  const { selectedLink } = useSelectedLinkContext();
  const { isAdminMode, setIsAdminMode } = useAppContext();

  const getMenuSelectedClassNames = (step?: TOUR_STEPS): string => {
    const baseClassName = getBaseClassNameSuffixForStep(step);

    if (isTourOpen && step === currentStep) {
      return `${baseClassName} selected`;
    }

    return baseClassName;
  };

  const shouldShowMenu = (step?: number): boolean =>
    !isTourOpen || !step || step <= currentStep;

  const goToHomeClick = (): void => {
    if (setIsOpened !== undefined) {
      setIsOpened(false);
    }

    setIsAdminMode(false);

    getGoToSelectedChannelPageFunction(selectedLink, Route.HOME)();
  };

  if (isAdminMode) {
    return (
      <AdminMenu
        getMenuSelectedClassNames={getMenuSelectedClassNames}
        goToHomeClick={goToHomeClick}
        onMenuItemClick={onMenuItemClick}
        setIsOpened={setIsOpened}
        shouldShowMenu={shouldShowMenu}
      />
    );
  }

  return (
    <MenuContextProvider
      getMenuSelectedClassNames={getMenuSelectedClassNames}
      onMenuItemClick={onMenuItemClick}
      shouldShowMenu={shouldShowMenu}
    >
      <MenuPure
        isTourOpen={isTourOpen}
        selectedLink={selectedLink}
        goToHomeClick={goToHomeClick}
        setIsOpened={setIsOpened}
      />
    </MenuContextProvider>
  );
};

export default Menu;
