import BuildingPage from '../../components/BuildingPage/BuildingPage';
import PageContentHeader from '../../components/PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import PageContentContainer from '../../components/PageContent/PageContentContainer';
import { SHOW_ORGANIZE_PAGE } from '../../utils/constants';
import Organizer from './Organize/Organizer';

const OrganizePure = () => (
  <PageContentContainer>
    <PageContentHeader title={<FormattedMessage id="organize_page.title" />} />

    <Organizer />
  </PageContentContainer>
);

const Organize = (): JSX.Element => {
  if (!SHOW_ORGANIZE_PAGE) {
    return <BuildingPage />;
  }

  return <OrganizePure />;
};

export default Organize;
