import { useContext } from 'react';
import { FolderType } from '../../../../types/document/Document';
import FolderItemIcon from './FolderItem/FolderItemIcon';
import useStyles from './FolderItem.styles';
import { shareContext } from '../ShareContext';
import useIsSmallScreen from '../../../../utils/useIsSmallScreen';
import clsx from 'clsx';

type FolderItemProps = {
  folder: FolderType;
};

const FolderItem = ({ folder }: FolderItemProps): JSX.Element => {
  const classes = useStyles();
  const { setSelectedFolder } = useContext(shareContext);
  const isSmallScreen = useIsSmallScreen();
  const folderNameClassName = isSmallScreen
    ? clsx([classes.folderName, classes.folderNameSmall])
    : classes.folderName;

  return (
    <div
      className={classes.container}
      onClick={() => setSelectedFolder(folder)}
    >
      <FolderItemIcon folder={folder} />
      <div className={folderNameClassName}>{folder.name}</div>
    </div>
  );
};

export default FolderItem;
