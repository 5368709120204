import { useContext } from 'react';
import moment, { Moment } from 'moment';
import { calendarContext } from '../../CalendarContext';
import { EventOrTimeslotArrayType } from '../../../../types/event/Event';
import CalendarUtils from '../../../../utils/CalendarUtils';
import useStyles from './DayEvents.styles';
import DayEvent from './DayEvent';

const MAX_EVENTS_TO_DISPLAY = 3;

type ExtraEventsOrTimeslotsProps = {
  total: number;
};

const ExtraEventsOrTimeslots = ({
  total,
}: ExtraEventsOrTimeslotsProps): JSX.Element | null => {
  const classes = useStyles();

  if (total > 0) {
    return (
      <div
        className={classes.extraEventsOrTimeslots}
        data-test-id="extra-events-or-timeslots-div"
      >
        +{total}
      </div>
    );
  }

  return null;
};

type DayEventsPureProps = {
  dayEventsAndTimeslots: EventOrTimeslotArrayType;
};

const DayEventsPure = ({
  dayEventsAndTimeslots,
}: DayEventsPureProps): JSX.Element => {
  const classes = useStyles();
  const eventsOrTimeslotsToDisplay = dayEventsAndTimeslots.slice(
    0,
    MAX_EVENTS_TO_DISPLAY,
  );
  const extraEventsOrTimeslots = dayEventsAndTimeslots.slice(
    MAX_EVENTS_TO_DISPLAY,
    dayEventsAndTimeslots.length,
  );

  return (
    <div className={classes.dayEventsContainer}>
      {eventsOrTimeslotsToDisplay.map((_, index) => (
        <DayEvent eventOrTimeslot={_} key={index} />
      ))}

      <ExtraEventsOrTimeslots total={extraEventsOrTimeslots.length} />
    </div>
  );
};

type DayEventsProps = {
  date: Moment;
};

const DayEvents = ({ date }: DayEventsProps): JSX.Element => {
  const { currentMonthEventsAndTimeslots } = useContext(calendarContext);

  const dayEventsAndTimeslots = currentMonthEventsAndTimeslots.filter(
    (eventOrTimeslot) =>
      CalendarUtils.isSameDate(moment(eventOrTimeslot.date), date),
  );

  return <DayEventsPure dayEventsAndTimeslots={dayEventsAndTimeslots} />;
};

export default DayEvents;
