import { useCallback, useContext, useState } from 'react';
import FilledButton from '../../../../../../components/Button/FilledButton';
import Image from '../../../../../../components/Image/Image';
import MicOnImage from '../../../../../../images/Communicate/VideoCall/mic-on.png';
import MicOffImage from '../../../../../../images/Communicate/VideoCall/mic-off.png';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './CommonButton.styles';
import { useControlToolbarContext } from '../../../../../../Context/Communicate/VideoCall/VideoContent/ControlToolbarContext';
import clsx from 'clsx';
import { trackEvent } from '../../../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../../../GoogleAnalytics/GAEvent';

type ToggleMicButtonPureProps = {
  isMicOn: boolean;
  small: boolean;
  toggleMic: Function;
};

const ToggleMicButtonPure = ({
  isMicOn,
  small,
  toggleMic,
}: ToggleMicButtonPureProps): JSX.Element => {
  const classes = useStyles();
  const micImage = isMicOn ? MicOnImage : MicOffImage;
  const label = isMicOn ? (
    <FormattedMessage id="videoCall.activated.male" />
  ) : (
    <FormattedMessage id="videoCall.deactivated.male" />
  );
  const imageHeight = small ? 24 : 38;

  return (
    <Grid container direction="column">
      <Grid className={clsx(classes.label, small && 'small')} item>
        <FormattedMessage id="videoCall.mic" />
        <br />
        {label}
      </Grid>
      <Grid item>
        <FilledButton
          className={clsx(classes.button, small && 'small')}
          color="white"
          data-test-id="toggle-mic-button"
          onClick={(): void => toggleMic()}
        >
          <Image src={micImage} height={imageHeight} />
        </FilledButton>
      </Grid>
    </Grid>
  );
};

const ToggleMicButton = (): JSX.Element => {
  const { api } = useContext(videoCallContext);
  const [isMicOn, setIsMicOn] = useState<boolean>(true);
  const { small } = useControlToolbarContext();

  const toggleMic = useCallback(() => {
    api.executeCommand('toggleAudio');
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.CLICK,
      isMicOn ? 'Désactiver le micro' : 'Réactiver le micro',
    );
    setIsMicOn(!isMicOn);
  }, [api, isMicOn, setIsMicOn]);

  return (
    <ToggleMicButtonPure
      isMicOn={isMicOn}
      small={small}
      toggleMic={toggleMic}
    />
  );
};

export default ToggleMicButton;
