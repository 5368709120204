import { makeStyles } from '@mui/styles';
import { adminFontFamily } from '../../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  audioContainer: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  audioControls: {
    borderRadius: 12,
    boxShadow: '0px 3px 8px 0px rgb(0 0 0 / 40%)',
    left: '50%',
    position: 'absolute',
    top: '60%',
    transform: 'translate(-50%, 0)',
    visibility: 'hidden',
    width: 200,
    zIndex: 1,

    '&.visible': {
      visibility: 'visible',
    },
  },
  container: {
    padding: '20px 40px',
  },
  filename: {
    bottom: 5,
    left: '50%',
    maxWidth: 150,
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    transform: 'translate(-50%, 0)',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  image: {
    cursor: 'pointer',
    maxHeight: 120,
    maxWidth: 150,

    [theme.breakpoints.down('md')]: {
      maxHeight: 60,
      maxWidth: 75,
    },
  },
  item: {
    height: 170,
    padding: 20,
    position: 'relative',
    textAlign: 'center',
    width: 180,

    [theme.breakpoints.down('md')]: {
      height: 120,
      width: '100%',
    },
  },
  label: {
    fontFamily: adminFontFamily,
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));
