import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  button: {
    borderRadius: 16,
    boxShadow: '0px 3px 8px 0px rgb(0 0 0 / 40%)',
    color: COLORS.WHITE,
    fontSize: 20,
    fontWeight: 'bold',
    padding: '25px 0',
    textTransform: 'none',
    transform: 'scale(0.95)',
    transition: 'all .2s ease-in-out',
    width: 220,

    '&:hover': {
      transform: 'scale(1)',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: 10,
      width: 'auto',
    },
  },
  green: {
    backgroundColor: COLORS.LIGHT_GREEN,

    '&:hover': {
      backgroundColor: COLORS.GREEN,
      transform: 'scale(1)',
    },
  },
  'green-selected': {
    backgroundColor: COLORS.GREEN,
    transform: 'scale(1)',

    '&:hover': {
      backgroundColor: COLORS.GREEN,
    },
  },
  red: {
    backgroundColor: COLORS.LIGHT_RED,

    '&:hover': {
      backgroundColor: COLORS.RED,
    },
  },
  'red-selected': {
    backgroundColor: COLORS.RED,
    transform: 'scale(1)',

    '&:hover': {
      backgroundColor: COLORS.RED,
    },
  },
  yellow: {
    backgroundColor: COLORS.LIGHT_YELLOW,

    '&:hover': {
      backgroundColor: COLORS.YELLOW,
    },
  },
  'yellow-selected': {
    backgroundColor: COLORS.YELLOW,
    transform: 'scale(1)',

    '&:hover': {
      backgroundColor: COLORS.YELLOW,
    },
  },
}));
