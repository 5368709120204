import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    paddingTop: 20,
  },
  subtitle2: {
    paddingTop: 20,
    '@media (min-width: 1200px)': {
      paddingTop: 100,
    },
  },
  text: {
    paddingTop: 20,
  },
  textContainer: {
    padding: 15,
  },
}));
