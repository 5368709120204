import {
  SharedDocumentType,
  SharedDocumentTypeEnum,
} from '../types/document/Document';
import { PartnerPublishedContent } from '../types/partnerdocuments/PartnerDocument';

const getExtension = (fileName: string): string | undefined =>
  fileName.split('.')?.[1];

export const AUDIO_EXTENSIONS = [
  'aiff',
  'au',
  'flac',
  'm4a',
  'mid',
  'mp3',
  'oga',
  'ogg',
  'opus',
  'wav',
  'weba',
  'webm',
  'wma',
];

export const IMAGE_EXTENSIONS = [
  'avif',
  'bmp',
  'gif',
  'ico',
  'jfif',
  'jpg',
  'jpeg',
  'jxl',
  'pjp',
  'pjpeg',
  'png',
  'svgz',
  'tif',
  'tiff',
  'xbm',
  'svg',
  'webp',
];

export const VIDEO_EXTENSIONS = [
  'asx',
  'avi',
  'm4v',
  'mov',
  'mp4',
  'mpeg',
  'mpg',
  'ogm',
  'ogv',
  'wmv',
];

export const WORD_EXTENSIONS = ['doc', 'docx'];

export const POWERPOINT_EXTENSIONS = ['pps', 'ppt', 'ppsx', 'pptx'];

export const EXCEL_EXTENSIONS = ['xls', 'xlsx'];

export const EML_EXTENSIONS = ['eml'];

export const PDF_EXTENSIONS = ['pdf'];

export const getFileTypeFromExtension = (
  fileName: string,
): SharedDocumentTypeEnum => {
  const ext = getExtension(fileName) || '';

  if (IMAGE_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.PICTURE;
  } else if (AUDIO_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.MUSIC;
  } else if (VIDEO_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.VIDEO;
  } else if (WORD_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.WORD;
  } else if (EML_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.EML;
  } else if (PDF_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.PDF;
  } else if (POWERPOINT_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.POWERPOINT;
  } else if (EXCEL_EXTENSIONS.includes(ext)) {
    return SharedDocumentTypeEnum.EXCEL;
  } else {
    return SharedDocumentTypeEnum.UNKNOWN;
  }
};

export const MAX_UPLOADED_DOCUMENT_SIZE = 20000000;

const compareContentsByPublicationStartDate = <
  T extends PartnerPublishedContent,
>(
  a: T,
  b: T,
) => {
  const aPublicationStartDate = a?.publicationStartDate || 0;
  const bPublicationStartDate = b?.publicationStartDate || 0;

  return aPublicationStartDate > bPublicationStartDate ? 1 : -1;
};

export const sortContentsByPublicationStartDate = <
  T extends PartnerPublishedContent,
>(
  contents: T[],
): T[] => contents.sort(compareContentsByPublicationStartDate);

type GetPartnerContentContentFromS3InputType = {
  contentId: string;
  s3URL: string | undefined;
  callback: (value: string | undefined) => void;
};

export const getPartnerContentContentFromS3 = ({
  contentId,
  s3URL,
  callback,
}: GetPartnerContentContentFromS3InputType): void => {
  if (s3URL) {
    fetch(s3URL)
      .then((response) => {
        response
          .text()
          .then((value) => callback(value))
          .catch((error) =>
            console.error(`Error getting text content ${contentId}`, error),
          );
      })
      .catch((error) =>
        console.error(`Error retrieving content ${contentId}`, error),
      );
  } else {
    callback(undefined);
  }
};

export const filterImageDocument = (
  documents: SharedDocumentType[],
): SharedDocumentType[] =>
  documents.filter(
    (document) => document.type === SharedDocumentTypeEnum.PICTURE,
  );

export const downloadFileFromURL = (url: string, fileName?: string) => {
  fetch(url)
    .then(async (response) => {
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;

        if (fileName) {
          a.download = fileName;
        }

        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
    .catch((error) => console.error(`Error getting blob ${url}`, error));
};

export const parseFileName = (filename: string) => {
  const filenameParts = filename.split('/');
  return filenameParts[filenameParts.length - 1];
};
