import { ClassNameMap, Grid, Link } from '@mui/material';
import { FileGridType } from './FileGrid';
import useStyles from './FileItem.styles';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';
import clsx from 'clsx';
import FileIcon from '../../images/icons/file.svg';
import Image from '../Image/Image';

const ITEMS_PER_ROW = 4;

const getBgClassName = (classes: ClassNameMap, index: number) => {
  const rows = Math.floor(index / ITEMS_PER_ROW);
  const colorIndex = (index + rows) % ITEMS_PER_ROW;

  switch (colorIndex) {
    case 0:
      return classes.violet;
    case 1:
      return classes.red;
    case 2:
      return classes.yellow;
    case 3:
      return classes.green;
  }
};

const onFileGridClick = (file: FileGridType): void => {
  trackEvent(GAEventCategory.HELP, GACommonEventAction.CLICK, file.fileName);
  window.open(`${file.filePath}/${file.fileName}`);
};

type FileItemProps = {
  file: FileGridType;
  index: number;
};

const FileItem = ({ file, index }: FileItemProps): JSX.Element => {
  const classes = useStyles();
  const bgClassName = getBgClassName(classes, index);

  return (
    <Grid item md={3} xs={6}>
      <Link className={classes.link} onClick={() => onFileGridClick(file)}>
        <Grid
          alignContent="center"
          className={clsx([classes.file, bgClassName])}
          container
        >
          <Grid item xs={12}>
            <Image src={FileIcon} />
          </Grid>

          <Grid className={classes.title} item xs={12}>
            {file.label}
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
};

export default FileItem;
