import { Box, Chip } from '@mui/material';
import {
  getPersonFullName,
  WebUserContactType,
} from '../../../../../../../types/User';
import { FormattedMessage } from 'react-intl';

type SelectedContactLabelsProps = {
  channelContacts: WebUserContactType[];
  selectedContacts: string[];
};

const SelectedContactLabels = ({
  channelContacts,
  selectedContacts,
}: SelectedContactLabelsProps): JSX.Element => {
  if (!selectedContacts?.length) {
    return (
      <FormattedMessage id="share.folder.create.select-contacts-placeholder" />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selectedContacts.map((contactId) => {
        const contact = channelContacts.find((_) => _.id === contactId);

        if (contact) {
          return <Chip key={contactId} label={getPersonFullName(contact)} />;
        }

        return null;
      })}
    </Box>
  );
};

export default SelectedContactLabels;
