import { UserType } from './User';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export enum RightEnum {
  ACCESS_MAIN_USER = 'ACCESS_MAIN_USER',
  ADMIN = 'ADMIN',
  B2B = 'B2B',
  EDITOR = 'EDITOR',
  N1 = 'N1',
  SAV = 'SAV',
  SUPPORT = 'SUPPORT',
  TEAM_VIEWER = 'TEAM_VIEWER',
}

export type LinkMetadataType = {
  hasMinimalAndroidVersionForVideoCall: boolean;
  hasMinimalArdoizCoreVersionForVideoCall: boolean;
  subscriptionEnabled: boolean;
};

export type LinkType = {
  channelKey: string;
  isAdmin: boolean;
  metadata: LinkMetadataType;
  type: LinkTypeType;
  mainUser?: UserType;
  selfWebUserId: string;
  userKey: string;
};

export enum LinkTypeType {
  Contact = 'Contact',
  Self = 'Self',
}

export const linkHasAdminAccess = (link: LinkType | undefined): boolean =>
  !!link?.isAdmin;

export const getLinkLabel = (link: LinkType): ReactNode => {
  const firstName = link?.mainUser?.firstName;
  const lastName = link?.mainUser?.lastName;

  const baseLabel = `${firstName} ${lastName}`;

  if (!link.metadata.subscriptionEnabled) {
    return (
      <>
        {baseLabel} (<FormattedMessage id="channelLink.subscriptionDisabled" />)
      </>
    );
  }

  return baseLabel;
};
