import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  field: {
    border: `1px solid ${COLORS.BLUE}`,
    borderRadius: 4,
    color: COLORS.BLUE,
    padding: 5,
    outline: 'none',
    width: 50,
  },
  inErrorField: {
    border: `1px solid ${COLORS.DARK_RED}`,
  },
  inErrorLabel: {
    color: COLORS.DARK_RED,
    fontSize: '0.75rem',
    lineHeight: 1.4,
  },
  occurrenceLabel: {
    color: COLORS.BLUE,
    fontSize: 14,
    marginLeft: 5,
  },
}));
