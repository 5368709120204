import { adminMessagesMessages } from './admin/messages';

export const adminMessages = {
  'admin.home.welcome': 'Bienvenue dans la partie administrateur',
  'admin.home.description':
    "Vous avez la possibilité de gérer à distance de nombreuses fonctionnalités de la tablette à la place de l'utilisateur",

  'admin.menu.home': 'Accueil',
  'admin.menu.messages': 'Messages',
  'admin.menu.workbook': 'Classeur',
  'admin.menu.organizer': 'Agenda',
  'admin.menu.bookmarks': 'Favoris Internet',
  'admin.menu.applications': 'Applications',
  'admin.menu.settings': 'Réglages',
  'admin.menu.contacts': 'Contacts',

  'admin.category.messages.description':
    'Envoyer des e-mails aux contacts de la tablette',
  'admin.category.workbook.description':
    'Gérer les documents, photos et musiques sur la tablette',
  'admin.category.organizer.description':
    'Accéder aux évènements de la tablette, en ajouter...',
  'admin.category.bookmarks.description':
    'Gérer les favoris internet de la tablette',
  'admin.category.applications.description':
    'Ajouter et supprimer des applications sur la tablette',
  'admin.category.settings.description':
    'Paramétrer les réglages de la tablette',
  'admin.category.contacts.description': 'Gérer les contacts de la tablette',

  ...adminMessagesMessages,
};
