import { useCallback, useState } from 'react';
import useStyles from './DeleteEventModal.styles';
import {
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material';
import FilledButton from '../../../../../../components/Button/FilledButton';
import { FormattedMessage } from 'react-intl';
import { DeleteEventOccurrenceEnum } from './DeleteEventModal';
import { StateSetter } from '../../../../../../types/utils/React';

type DeleteRecurrentEventModalPureProps = {
  deleteEventOccurrenceType: DeleteEventOccurrenceEnum;
  handleDeleteClick: () => void;
  handleHide: () => void;
  setDeleteEventOccurrenceType: StateSetter<DeleteEventOccurrenceEnum>;
};

const DeleteRecurrentEventModalPure = ({
  deleteEventOccurrenceType,
  handleDeleteClick,
  handleHide,
  setDeleteEventOccurrenceType,
}: DeleteRecurrentEventModalPureProps): JSX.Element => {
  const classes = useStyles();
  const radioButton = <Radio className={classes.radio} />;

  return (
    <div className={classes.container}>
      <Paper className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.deletionMessage}>
            <FormattedMessage id="event.delete.recurrenceMessage" />
          </div>

          <RadioGroup
            className={classes.radioGroup}
            data-test-id="radio-group"
            value={deleteEventOccurrenceType}
            onChange={(e) =>
              setDeleteEventOccurrenceType(
                e.target.value as DeleteEventOccurrenceEnum,
              )
            }
          >
            <FormControlLabel
              control={radioButton}
              label={<FormattedMessage id="event.delete.THIS_OCCURRENCE" />}
              value={DeleteEventOccurrenceEnum.THIS_OCCURRENCE}
            />
            <FormControlLabel
              control={radioButton}
              label={
                <FormattedMessage id="event.delete.THIS_AND_LATER_OCCURRENCES" />
              }
              value={DeleteEventOccurrenceEnum.THIS_AND_LATER_OCCURRENCES}
            />
            <FormControlLabel
              control={radioButton}
              label={<FormattedMessage id="event.delete.ALL_OCCURRENCES" />}
              value={DeleteEventOccurrenceEnum.ALL_OCCURRENCES}
            />
          </RadioGroup>

          <div className={classes.buttonToolbar}>
            <FilledButton
              className={classes.deleteButton}
              color="red"
              data-test-id="delete-button"
              size="small"
              onClick={handleDeleteClick}
            >
              <FormattedMessage id="button.delete" />
            </FilledButton>

            <Button
              className={classes.cancelButton}
              data-test-id="cancel-button"
              onClick={handleHide}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

type DeleteRecurrentEventModalProps = {
  handleDelete: (deleteEventOccurrenceType: DeleteEventOccurrenceEnum) => void;
  handleHide: () => void;
};

const DeleteRecurrentEventModal = ({
  handleDelete,
  handleHide,
}: DeleteRecurrentEventModalProps): JSX.Element => {
  const [deleteEventOccurrenceType, setDeleteEventOccurrenceType] =
    useState<DeleteEventOccurrenceEnum>(
      DeleteEventOccurrenceEnum.THIS_OCCURRENCE,
    );

  const handleDeleteClick = useCallback(
    () => handleDelete(deleteEventOccurrenceType),
    [deleteEventOccurrenceType, handleDelete],
  );

  return (
    <DeleteRecurrentEventModalPure
      deleteEventOccurrenceType={deleteEventOccurrenceType}
      setDeleteEventOccurrenceType={setDeleteEventOccurrenceType}
      handleDeleteClick={handleDeleteClick}
      handleHide={handleHide}
    />
  );
};

export default DeleteRecurrentEventModal;
