import { createContext, ReactNode } from 'react';
import moment, { Moment } from 'moment';
import { StateSetter } from '../../types/utils/React';
import { emptyFunction } from '../../utils/Functions';
import { EventOrTimeslotArrayType } from '../../types/event/Event';

export type CalendarContextProps = {
  currentDate: Moment;
  currentMonthEventsAndTimeslots: EventOrTimeslotArrayType;
  disabled: boolean;
  selectedDate?: Moment;
  selectedDayEventsAndTimeslots: EventOrTimeslotArrayType;
  setCurrentDate: StateSetter<Moment>;
  handleSelectedDateChange: (newSelectedDate: Moment | undefined) => void;
};

const initialState = {
  currentDate: moment(),
  currentMonthEventsAndTimeslots: [],
  disabled: false,
  selectedDate: undefined,
  selectedDayEventsAndTimeslots: [],
  setCurrentDate: emptyFunction,
  handleSelectedDateChange: emptyFunction,
};

const calendarContext = createContext<CalendarContextProps>(initialState),
  { Provider } = calendarContext;

type CalendarContextProviderProps = {
  children: ReactNode;
  currentDate: Moment;
  currentMonthEventsAndTimeslots: EventOrTimeslotArrayType;
  disabled: boolean;
  selectedDate?: Moment;
  selectedDayEventsAndTimeslots: EventOrTimeslotArrayType;
  setCurrentDate: StateSetter<Moment>;
  handleSelectedDateChange: (newSelectedDate: Moment | undefined) => void;
};

const CalendarContextProvider = ({
  children,
  ...props
}: CalendarContextProviderProps): JSX.Element => (
  <Provider
    value={{
      ...props,
    }}
  >
    {children}
  </Provider>
);

export { calendarContext, CalendarContextProvider };
