import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  folderName: {
    fontSize: 18,
    maxWidth: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  folderNameSmall: {
    fontSize: 12,
    maxWidth: 50,
  },
}));
