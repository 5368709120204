import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  deleteButton: {
    background: COLORS.WHITE,
    borderRadius: 10,
    color: COLORS.DARKER_BLUE,
    fontFamily: adminFontFamily,
    fontSize: 18,
    fontWeight: 400,
    marginTop: 25,
    padding: '10px 30px',
    textTransform: 'capitalize',

    '&:hover': {
      background: COLORS.WHITE,
    },
  },
  redButton: {
    background: COLORS.DARK_RED,
    color: COLORS.WHITE,

    '&:hover': {
      background: COLORS.DARK_RED,
    },
  },
}));
