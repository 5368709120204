import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  input: {
    '&.MuiTextField-root': {
      width: '95%',
    },
  },
  sup: {
    color: COLORS.BLUE,
  },
}));
