import { gql } from '@apollo/client';
import { VideoCallQueryOutputType } from '../../../types/videocall/VideoCall';
import { LINK_FRAGMENT } from '../../webuser/WebUserQueries';

export const VIDEO_CALL_FRAGMENT = gql`
  fragment videoCallFragment on VideoCall {
    id
    called {
      id
      firstName
      lastName
      links {
        ...linkFields
      }
    }
    calledID
    caller {
      id
      firstName
      lastName
    }
    callerID
    command {
      currentState
    }
    roomName
  }
  ${LINK_FRAGMENT}
`;

export const GET_VIDEO_CALL_QUERY = gql`
  query GetVideoCallQuery($id: String!) {
    videoCall(id: $id) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type GetVideoCallOutputType = {
  videoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const WAITING_NOTIFICATION_ACKNOWLEDGE_VIDEO_CALL_QUERY = gql`
  query GetWaitingNotificationAcknowledgeVideoCallQuery {
    waitingNotificationAcknowledgeVideoCall {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type GetWaitingNotificationAcknowledgeVideoCallOutputType = {
  waitingNotificationAcknowledgeVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};
