import { useCallback } from 'react';
import FilledButton from '../../../components/Button/FilledButton';
import { FormattedMessage } from 'react-intl';
import useStyles from './OnBoardingReplayButton.styles';
import { useMutation } from '@apollo/client';
import {
  UPDATE_ONBOARDING_COMPLETION_MUTATION,
  UpdateOnBoardingCompletionOutputType,
} from '../../../graphql/webuser/WebUserMutations';
import { emptyFunction } from '../../../utils/Functions';

type OnBoardingReplayButtonPureProps = {
  onClick: Function;
};

const OnBoardingReplayButtonPure = ({
  onClick,
}: OnBoardingReplayButtonPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <FilledButton className={classes.button} color="violet" onClick={onClick}>
      <FormattedMessage id="profile.on_boarding_replay.button" />
    </FilledButton>
  );
};

const OnBoardingReplayButton = (): JSX.Element => {
  const [updateOnBoardingCompletion] =
    useMutation<UpdateOnBoardingCompletionOutputType>(
      UPDATE_ONBOARDING_COMPLETION_MUTATION,
      {
        refetchQueries: ['GetMe'],
      },
    );
  const handleClick = useCallback(() => {
    updateOnBoardingCompletion({
      variables: {
        input: {
          onBoardingCompleted: false,
        },
      },
    }).then(emptyFunction);
  }, [updateOnBoardingCompletion]);

  return <OnBoardingReplayButtonPure onClick={handleClick} />;
};

export default OnBoardingReplayButton;
