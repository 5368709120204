import { useEffect } from 'react';
import PageContentHeader from '../../../components/PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import PageContentContainer from '../../../components/PageContent/PageContentContainer';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { useQuery } from '@apollo/client';
import {
  GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY,
  GetPartnerAccountsWithFamilyPortalOutputType,
} from '../../../graphql/partnerdocuments/PartnerDocumentsQueries';
import { getGoToSelectedChannelPageFunction } from '../../../utils/history';
import Route from '../../../routes/Route';
import { Grid } from '@mui/material';
import { PartnerAccountType } from '../../../types/partnerdocuments/PartnerAccount';
import PartnerListItem from './Partner/PartnerListItem';
import useStyles from './PartnerList.styles';

type PartnerListPureProps = {
  partnerAccounts: PartnerAccountType[];
};

const PartnerListPure = ({
  partnerAccounts,
}: PartnerListPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <PageContentContainer>
      <PageContentHeader
        title={<FormattedMessage id="partner.description" />}
      />

      <Grid className={classes.container} container>
        {partnerAccounts.map((partnerAccount) => (
          <Grid className={classes.item} item key={partnerAccount.id} md={4}>
            <PartnerListItem partnerAccount={partnerAccount} />
          </Grid>
        ))}
      </Grid>
    </PageContentContainer>
  );
};

const PartnerList = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { data, loading } =
    useQuery<GetPartnerAccountsWithFamilyPortalOutputType>(
      GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY,
      {
        variables: {
          channel: selectedLink?.channelKey,
        },
      },
    );

  const partnerAccounts =
    data?.getPartnerAccountsWithFamilyPortal?.partnerAccounts;

  useEffect(() => {
    if (!loading && !partnerAccounts?.length) {
      getGoToSelectedChannelPageFunction(selectedLink, Route.HELP)();
    }
  }, [loading, partnerAccounts, selectedLink]);

  if (loading || !partnerAccounts?.length) {
    return null;
  }

  return <PartnerListPure partnerAccounts={partnerAccounts} />;
};

export default PartnerList;
