import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    borderBottom: `solid 1px ${COLORS.LIGHT_GREY}`,
    padding: '20px 30px',
    textTransform: 'capitalize',
  },
  editButtonContainer: {
    paddingBottom: 5,
  },
  event: {
    borderRadius: 6,
    display: 'inline-block',
    float: 'left',
    height: 12,
    verticalAlign: 'middle',
    width: 12,
  },
  busyEvent: {
    backgroundColor: COLORS.RED,
  },
  eventHour: {
    display: 'inline-block',
    fontSize: 12,
    paddingLeft: 25,
    paddingTop: 10,
    textTransform: 'lowercase',

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  eventLabel: {
    fontSize: 14,
    paddingLeft: 25,
    textTransform: 'lowercase',

    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  guestEvent: {
    backgroundColor: COLORS.YELLOW,
  },
  ownedEvent: {
    backgroundColor: COLORS.GREEN,
  },
}));
