import { useCallback, useContext } from 'react';
import {
  SharedDocumentType,
  SharedDocumentTypeEnum,
} from '../../types/document/Document';
import AudioIcon from '../../images/Share/ShareExplorer/Documents/audio-icon.png';
import EmlIcon from '../../images/Share/ShareExplorer/Documents/eml-icon.png';
import ExcelIcon from '../../images/Share/ShareExplorer/Documents/excel-icon.png';
import PdfIcon from '../../images/Share/ShareExplorer/Documents/pdf-icon.png';
import PowerpointIcon from '../../images/Share/ShareExplorer/Documents/powerpoint-icon.png';
import UnknownIcon from '../../images/Share/ShareExplorer/Documents/unknown-icon.png';
import WordIcon from '../../images/Share/ShareExplorer/Documents/word-icon.png';
import Image from '../Image/Image';
import useStyles from './DocumentPreview.styles';
import {
  downloadFileFromURL,
  getFileTypeFromExtension,
} from '../../utils/Document';
import CreatorIcon from '../../views/App/Share/ShareExplorer/FolderContentExplorer/CreatorIcon';
import { deleteModeContext } from '../../views/App/Share/ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';
import { imageViewerContext } from '../ImageViewer/ImageViewerContext';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

type DocumentPreviewPureProps = {
  document: SharedDocumentType;
  icon: string;
};

export const DocumentPreviewPure = ({
  document,
  icon,
}: DocumentPreviewPureProps): JSX.Element => {
  const { isDeleteMode } = useContext(deleteModeContext);
  const classes = useStyles();

  const handleDocumentClick = useCallback(() => {
    if (!isDeleteMode) {
      window.open(document.blob.s3URL);
    }
  }, [document.blob.s3URL, isDeleteMode]);

  return (
    <>
      <Image
        className={classes.image}
        src={icon}
        onClick={handleDocumentClick}
      />
      <CreatorIcon document={document} />
    </>
  );
};

type ImagePreviewProps = {
  document: SharedDocumentType;
  icon: string;
};

export const ImagePreview = ({
  document,
  icon,
}: ImagePreviewProps): JSX.Element => {
  const classes = useStyles();
  const { isDeleteMode } = useContext(deleteModeContext);
  const { handleCurrentViewedImage } = useContext(imageViewerContext);

  const handleImageClick = useCallback(() => {
    if (!isDeleteMode) {
      handleCurrentViewedImage(document);
    }
  }, [document, isDeleteMode, handleCurrentViewedImage]);

  return (
    <>
      <Image className={classes.image} src={icon} onClick={handleImageClick} />
      <CreatorIcon document={document} />
    </>
  );
};

type AudioPreviewPureProps = {
  document: SharedDocumentType;
};

export const AudioPreviewPure = ({
  document,
}: AudioPreviewPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <DocumentPreviewPure document={document} icon={AudioIcon} />

      <AudioPlayer
        className={classes.audioControls}
        url={document.blob.s3URL}
        handleDownload={() =>
          downloadFileFromURL(document.blob.s3URL, document.name)
        }
      />
    </>
  );
};

type VideoPreviewPureProps = {
  document: SharedDocumentType;
};

export const VideoPreviewPure = ({
  document,
}: VideoPreviewPureProps): JSX.Element => (
  <>
    <video width="150" controls preload="metadata">
      <source src={document.blob.s3URL} type="video/mp4" />
    </video>

    <CreatorIcon document={document} />
  </>
);

const getIconFromDocumentType = (
  document: SharedDocumentType,
  documentType: SharedDocumentTypeEnum,
) => {
  if (document.type === SharedDocumentTypeEnum.PICTURE) {
    return document.blob.s3URL;
  }

  switch (documentType) {
    case SharedDocumentTypeEnum.EML:
      return EmlIcon;
    case SharedDocumentTypeEnum.EXCEL:
      return ExcelIcon;
    case SharedDocumentTypeEnum.PICTURE:
      return document.blob.s3URL;
    case SharedDocumentTypeEnum.PDF:
      return PdfIcon;
    case SharedDocumentTypeEnum.POWERPOINT:
      return PowerpointIcon;
    case SharedDocumentTypeEnum.WORD:
      return WordIcon;
    case SharedDocumentTypeEnum.UNKNOWN:
    default:
      return UnknownIcon;
  }
};

type DocumentPreviewProps = {
  document: SharedDocumentType;
  useBlobForExtension?: boolean;
};

const DocumentPreview = ({
  document,
  useBlobForExtension,
}: DocumentPreviewProps): JSX.Element => {
  const fileName = useBlobForExtension ? document.blob.id : document.name;
  const documentType = getFileTypeFromExtension(fileName);

  if (documentType === SharedDocumentTypeEnum.MUSIC) {
    return <AudioPreviewPure document={document} />;
  }

  if (documentType === SharedDocumentTypeEnum.VIDEO) {
    return <VideoPreviewPure document={document} />;
  }

  if (document.type === SharedDocumentTypeEnum.PICTURE) {
    return (
      <ImagePreview
        document={document}
        icon={getIconFromDocumentType(document, documentType)}
      />
    );
  }

  return (
    <DocumentPreviewPure
      document={document}
      icon={getIconFromDocumentType(document, documentType)}
    />
  );
};

export default DocumentPreview;
