import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  titles: {
    [theme.breakpoints.down('md')]: {
      float: 'right',
      padding: 10,

      '& .MuiTypography-h3': {
        fontSize: 18,
      },
      '& .MuiTypography-subtitle2': {
        fontSize: 20,
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 40,
    },
  },
}));
