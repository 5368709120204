import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  datePicker: {
    '& .Mui-selected': {
      backgroundColor: COLORS.BLUE,

      '&:focus': {
        backgroundColor: COLORS.BLUE,
      },

      '&:hover': {
        backgroundColor: COLORS.BLUE,
      },
    },
  },
  textField: {
    color: COLORS.BLUE,
    width: '100%',

    '& .MuiOutlinedInput-root, &.MuiOutlinedInput-root': {
      '& input': {
        color: COLORS.BLUE,
      },
      '& fieldset': {
        border: `1px solid ${COLORS.BLUE}`,
      },
      '&:hover fieldset': {
        border: `1px solid ${COLORS.BLUE}`,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${COLORS.BLUE}`,
      },
      '& .MuiSvgIcon-root': {
        color: COLORS.BLUE,
      },
    },
  },
}));
