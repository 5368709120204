import { useContext } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import Image from '../../../../components/Image/Image';
import clsx from 'clsx';
import useStyles from './MobilePostcardWidget.styles';
import { postcardContext } from '../../../../Context/Communicate/Postcard/PostcardContext';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import {
  getOnKeyPressFunction,
  POSTCARD_CONTENT_MAX_LENGTH,
} from '../../../../views/App/Communicate/Postcard/PostcardWidget/handlers';
import PostcardPlaceholderImage from '../../../../images/Communicate/Postcard/postcard_placeholder.png';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';
import SendPostcardButton from '../../../../views/App/Communicate/Postcard/PostcardWidget/SendPostcardButton';
import LoadPhotoButton from '../../../../views/App/Communicate/Postcard/PostcardWidget/LoadPhotoButton';

export const MOBILE_POSTCARD_IMAGE_SIZE = {
  HEIGHT: 'auto',
  WIDTH: '80%',
};

export type MobilePostcardWidgetPureProps = {
  canSendPostcard: boolean;
  imagePreview: string;
  postcardContent: string;
  handleContentChange: Function;
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  onKeyPress: Function;
  sendPostcard: Function;
};

const MobilePostcardWidgetPure = ({
  canSendPostcard,
  imagePreview,
  postcardContent,
  handleContentChange,
  onDrop,
  onKeyPress,
  sendPostcard,
}: MobilePostcardWidgetPureProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const dropzoneState = useDropzone({ accept: 'image/*', onDrop });
  const { getRootProps, getInputProps } = dropzoneState;
  const onLoadPhotoClick = () => {
    trackEvent(
      GAEventCategory.POSTCARD,
      GACommonEventAction.CLICK,
      'Charger une photo',
    );

    dropzoneState.open();
  };

  return (
    <>
      <div
        className={classes.dragZone}
        data-test-id="dropzone"
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        {imagePreview !== PostcardPlaceholderImage && (
          <Image className={clsx(classes.image)} src={imagePreview} />
        )}
      </div>

      <LoadPhotoButton
        className={classes.actionButton}
        onLoadPhotoClick={onLoadPhotoClick}
      />

      <div className={classes.contentContainer}>
        <TextField
          className={classes.content}
          data-test-id="postcard-content-textfield"
          margin={'normal'}
          maxRows={8}
          multiline
          placeholder={intl.formatMessage({
            id: 'form.placeholder.writeTextHere',
          })}
          value={postcardContent}
          onChange={(e): void => handleContentChange(e.target.value)}
          onKeyPress={(e) => onKeyPress(e)}
        />
      </div>

      <SendPostcardButton
        canSendPostcard={canSendPostcard}
        className={classes.actionButtonSend}
        sendPostcard={(): void => sendPostcard()}
      />
    </>
  );
};

export type MobilePostcardWidgetProps = {
  canSendPostcard: boolean;
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  sendPostcard: Function;
};

const MobilePostcardWidget = ({
  canSendPostcard,
  onDrop,
  sendPostcard,
}: MobilePostcardWidgetProps): JSX.Element => {
  const { imagePreview, postcardContent, setPostcardContent } =
    useContext(postcardContext);

  const handleContentChange = (value: string) => {
    if (value.length <= POSTCARD_CONTENT_MAX_LENGTH) {
      setPostcardContent(value);
    }
  };

  const onKeyPress = getOnKeyPressFunction({ postcardContent });

  return (
    <MobilePostcardWidgetPure
      canSendPostcard={canSendPostcard}
      imagePreview={imagePreview}
      postcardContent={postcardContent}
      handleContentChange={handleContentChange}
      onDrop={onDrop}
      onKeyPress={onKeyPress}
      sendPostcard={sendPostcard}
    />
  );
};
export default MobilePostcardWidget;
