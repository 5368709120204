import { gql } from '@apollo/client';
import {
  AcceptRelationshipErrorType,
  AddRelationshipErrorType,
  RelationshipType,
} from '../../types/Relationship';
import { RELATIONSHIP_FRAGMENT } from './RelationshipQueries';

export const ACCEPT_RELATIONSHIP_MUTATION = gql`
  mutation acceptRelationshipMutation($uuid: String!) {
    acceptRelationship(uuid: $uuid) {
      errorReason
      relationship {
        ...relationshipFields
      }
    }
  }
  ${RELATIONSHIP_FRAGMENT}
`;

export type AcceptRelationshipOutputType = {
  acceptRelationship: {
    errorReason?: AcceptRelationshipErrorType;
    relationship?: RelationshipType;
  };
};

export const ADD_RELATIONSHIP_MUTATION = gql`
  mutation addRelationshipMutation($input: AddRelationshipInput!) {
    addRelationship(input: $input) {
      errorReason
      relationship {
        ...relationshipFields
      }
    }
  }
  ${RELATIONSHIP_FRAGMENT}
`;

export type AddRelationshipOutputType = {
  addRelationship: {
    errorReason?: AddRelationshipErrorType;
    relationship?: RelationshipType;
  };
};
