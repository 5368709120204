import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  deleteButton: {
    background: COLORS.DARK_RED,
    borderRadius: 17,
    float: 'right',
    marginRight: 20,
    marginTop: 5,
    padding: 5,
    height: 36,
    minHeight: 36,
    minWidth: 36,
    width: 36,

    '&.Mui-disabled': {
      opacity: 0.7,
    },

    '&:hover': {
      background: COLORS.DARK_RED,
    },
  },
}));
