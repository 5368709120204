import { AlarmEnum, DaysOfRepeatType } from '../organize/Organizer';
import { WebUserType } from '../User';

export enum EventTypeEnum {
  ALARM,
  BIRTHDAY,
  DATE,
  REMINDER,
  TODO,
}

export enum RecurrenceTypeEnum {
  AFTER = 'AFTER',
  ALWAYS = 'ALWAYS',
  NONE = 'NONE',
  UNTIL = 'UNTIL',
}

export type ShortEventType = {
  id: string;
};

export type EventType = {
  id: string;
  alarm: AlarmEnum;
  comments?: string;
  creator: WebUserType;
  date: number;
  daysOfRepeat?: DaysOfRepeatType;
  done: boolean;
  duration: number;
  endOfRepeatDate?: number;
  ignoreDate: number[];
  isAllDay: boolean;
  name: string;
  occurrence?: number;
  originalEvent?: ShortEventType;
  recurrence: RecurrenceTypeEnum;
  type: EventTypeEnum;
  untilDate?: number;
};

export type TimeslotType = {
  date: number;
  duration: number;
};

export type EventOrTimeslotType = EventType | TimeslotType;

export type EventOrTimeslotArrayType = EventOrTimeslotType[];

export const isEvent = (
  eventOrTimeslot: EventType | TimeslotType,
): eventOrTimeslot is EventType => !!(eventOrTimeslot as EventType).id;

export const isEventOwner = (
  event: EventType,
  currentWebUser: WebUserType,
): boolean => currentWebUser.id === event.creator.id;
