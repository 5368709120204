import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',

    '& button': {
      backgroundColor: COLORS.VIOLET,
      borderRadius: 16,
      color: COLORS.WHITE,
      fontSize: 16,
      fontWeight: 'bold',
      padding: '18px 50px',
      textTransform: 'capitalize',
    },

    '& button:hover': {
      backgroundColor: COLORS.VIOLET,
    },
  },
  content: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: '45%',
  },
  description: {
    fontSize: '2em',
    lineHeight: '1.4em',
    margin: 'auto',
    maxWidth: '70%',
    padding: 50,
    textAlign: 'center',
  },
  fullScreenContainer: {
    alignItems: 'center',
    background: COLORS.WHITE,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  },
  grandpaImage: {
    height: '40%',
    position: 'absolute',
    right: 50,
    top: '5%',
  },
  title: {
    color: COLORS.VIOLET,
    fontSize: '4em',
    fontWeight: 'bold',
    left: '15%',
    position: 'absolute',
    right: '15%',
    textAlign: 'center',
    top: '30%',
  },
  womanImage: {
    bottom: '16%',
    height: '50%',
    left: 50,
    position: 'absolute',
  },
}));
