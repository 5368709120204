import { useCallback, useContext } from 'react';
import FilledButton from '../../../../../components/Button/FilledButton';
import { FormattedMessage } from 'react-intl';
import useStyles from './ExitDeleteModeButton.styles';
import { deleteModeContext } from '../../ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';

const ExitDeleteModeButton = (): JSX.Element => {
  const classes = useStyles();
  const { setDocumentsToDelete, setIsDeleteMode } =
    useContext(deleteModeContext);

  const handleCancel = useCallback(() => {
    setDocumentsToDelete([]);
    setIsDeleteMode(false);
  }, [setDocumentsToDelete, setIsDeleteMode]);

  return (
    <FilledButton
      className={classes.button}
      color="violet"
      data-test-id="exit-delete-mode-button"
      onClick={handleCancel}
    >
      <FormattedMessage id="button.cancel" />
    </FilledButton>
  );
};

export default ExitDeleteModeButton;
