import { PartnerContentType } from '../../../../../../../types/partnerdocuments/PartnerDocument';
import useStyles from './PartnerContentArticleList.styles';
import PartnerContentArticleListItem from './PartnerContentArticleListItem';
import { usePartnerContentContext } from '../PartnerContentContext';
import { FormattedMessage } from 'react-intl';

type PartnerContentArticleListPureProps = {
  contents: PartnerContentType[];
};

const PartnerContentArticleListPure = ({
  contents,
}: PartnerContentArticleListPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.scrollContainer}>
      <div className={classes.title}>
        <FormattedMessage id="partner.publications.content.view.newArticles" />
      </div>

      {contents.map((content) => (
        <PartnerContentArticleListItem content={content} key={content.id} />
      ))}
    </div>
  );
};

const PartnerContentArticleList = (): JSX.Element => {
  const { contents } = usePartnerContentContext();

  return <PartnerContentArticleListPure contents={contents} />;
};

export default PartnerContentArticleList;
