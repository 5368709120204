import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  cancelButton: {
    borderRadius: 8,
    float: 'right',
    padding: '5px 20px',
    textTransform: 'none',
  },
  content: {
    paddingTop: 10,

    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
  },
  description: {
    fontSize: 18,
    lineHeight: '24px',
    padding: '30px 55px',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      padding: '30px 10px',
    },
  },
  modal: {
    borderRadius: 24,
    height: 320,
    left: '50%',
    padding: 20,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      bottom: 0,
      height: 'auto',
      left: 0,
      padding: 10,
      right: 0,
      top: 0,
      transform: 'none',
      width: 'auto',
    },
  },
  validateButton: {
    fontSize: 18,
    textTransform: 'none',
    width: 350,

    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  validateButtonContainer: {
    textAlign: 'center',
  },
}));
