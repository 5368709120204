import {
  EVENT_FORM_OCCURRENCES_MAX_VALUE,
  EVENT_FORM_TITLE_MAX_LENGTH,
} from '../../views/App/Organize/Organizer/EventForm/EventFormHelpers';

export const organizeMessages = {
  'organize_page.title': "Organiser l'agenda de votre proche",
  busy: 'occupé',
  'event.add': 'Ajouter un événement',

  'event.all_day': 'toute la journée',
  'event.form.all_day': 'toute la journée ?',
  'event.form.begin_hour': 'heure de début',
  'event.form.end_hour': 'heure de fin',
  'event.form.recurrence': 'récurrence',
  'event.form.recurrence_days': 'jours de récurrence',
  'event.form.occurrences': 'occurrences',
  'event.form.save_event': "enregistrer l'événement",
  'event.form.save_event_success': 'Événement créé avec succès',
  'event.form.edit_event_success': 'Événement modifié avec succès',
  'event.form.mandatory_fields': 'Champs obligatoires',

  'event.form.errors.name.empty': 'Veuillez saisir un titre',
  'event.form.errors.name.tooLong': `Le titre est limité à ${EVENT_FORM_TITLE_MAX_LENGTH} caractères`,
  'event.form.errors.dateBegin.empty':
    "Veuillez choisir l'heure de début de l'événement",
  'event.form.errors.dateEnd.empty':
    "Veuillez choisir l'heure de fin de l'événement",
  'event.form.errors.dateEnd.afterDateBegin':
    "L'heure de fin doit être après l'heure de début de l'événement",
  'event.form.errors.daysOfRepeat.empty':
    'La répétition doit au moins concerner le jour sélectionné',
  'event.form.errors.recurrenceUntilDate.empty':
    'Veuillez choisir la date de fin de récurrence',
  'event.form.errors.occurrences.atLeast2':
    'Veuillez sélectionner au minimum 2 occurrences',
  'event.form.errors.occurrences.tooMuch': `Vous avez dépassé la valeur maximale de ${EVENT_FORM_OCCURRENCES_MAX_VALUE} occurrences`,

  'event.delete.confirm':
    'Êtes-vous sûr(e) de vouloir supprimer cet événement ?',
  'event.delete.recurrenceMessage':
    'Vous êtes sur le point de supprimer un événement récurrent. Que voulez-vous supprimer ?',
  'event.delete.ALL_OCCURRENCES': 'toute la série',
  'event.delete.THIS_AND_LATER_OCCURRENCES':
    'cette occurrence et les suivantes',
  'event.delete.THIS_OCCURRENCE': 'cette occurrence',
  'event.delete.success': 'Événement supprimé',
  'event.delete.error': "Erreur lors de la suppression de l'événement",

  'alarm.BEFORE_15_MIN': '15 minutes avant',
  'alarm.BEFORE_30_MIN': '30 minutes avant',
  'alarm.BEFORE_60_MIN': '1 heure avant',
  'alarm.NO_ALARM': "Pas d'alerte",
  'alarm.ON_TIME': "À l'heure",
};
