import { ReactNode, useContext } from 'react';
import useStyles from './DayContainer.styles';
import { Paper } from '@mui/material';
import CalendarUtils from '../../../../utils/CalendarUtils';
import { calendarContext } from '../../../../components/Calendar/CalendarContext';

type DayContainerProps = {
  children: ReactNode;
};

const DayContainer = ({ children }: DayContainerProps): JSX.Element | null => {
  const { selectedDate } = useContext(calendarContext);
  const classes = useStyles();

  if (!selectedDate) {
    return null;
  }

  const selectedDateLabel =
    CalendarUtils.formatDateToDayFullMonthAndYearString(selectedDate);

  return (
    <Paper className={classes.dayContentContainer}>
      <div className={classes.day}>{selectedDateLabel}</div>
      {children}
    </Paper>
  );
};

export default DayContainer;
