import { FormattedMessage } from 'react-intl';
import useStyles from './DayHeader.styles';

const dayHeaderLabelIds = [
  'calendar.days.MONDAY',
  'calendar.days.TUESDAY',
  'calendar.days.WEDNESDAY',
  'calendar.days.THURSDAY',
  'calendar.days.FRIDAY',
  'calendar.days.SATURDAY',
  'calendar.days.SUNDAY',
];

const DayHeader = (): JSX.Element => {
  const classes = useStyles();

  return (
    <tr>
      {dayHeaderLabelIds.map((labelId, index) => (
        <th className={classes.header} key={index}>
          <FormattedMessage id={labelId} />
        </th>
      ))}
    </tr>
  );
};

export default DayHeader;
