import { MouseEventHandler } from 'react';
import { Grid } from '@mui/material';
import Image from '../Image/Image';
import { UseStylesProps } from '../../utils/useStyles';
import useStyles from './SubCategory.styles';
import { GridSize } from '@mui/material/Grid/Grid';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import { LinkType } from '../../types/Link';

type SubCategoryPureProps = UseStylesProps & SubCategoryProps;

const SubCategoryPure = ({
  alt,
  classes,
  image,
  subCategorySize,
  onClick,
  onMouseOver,
}: SubCategoryPureProps): JSX.Element => (
  <Grid item md={subCategorySize} xs={12}>
    <Image
      alt={alt}
      className={classes.subCategory}
      src={image}
      width="100%"
      onClick={onClick}
      onMouseOver={onMouseOver}
    />
  </Grid>
);

export type GetOnClickFunctionInput = {
  enableEvenIfNoSelectedLink?: boolean;
  selectedLink?: LinkType;
  onClick?: MouseEventHandler;
};

const getOnClickFunction = ({
  enableEvenIfNoSelectedLink,
  selectedLink,
  onClick,
}: GetOnClickFunctionInput): MouseEventHandler | undefined => {
  if (enableEvenIfNoSelectedLink || selectedLink) {
    return onClick;
  }
};

export type SubCategoryProps = {
  alt: string;
  enableEvenIfNoSelectedLink?: boolean;
  image: string;
  subCategorySize: GridSize;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
};

const SubCategory = ({
  enableEvenIfNoSelectedLink,
  onClick,
  ...props
}: SubCategoryProps): JSX.Element => {
  const classes = useStyles();
  const { selectedLink } = useSelectedLinkContext();

  return (
    <SubCategoryPure
      classes={classes}
      onClick={getOnClickFunction({
        enableEvenIfNoSelectedLink,
        selectedLink,
        onClick,
      })}
      {...props}
    />
  );
};

export default SubCategory;
