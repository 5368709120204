export const GenericErrorImage = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="freepik--Floor--inject-46">
      <ellipse
        id="freepik--floor--inject-46"
        cx="250"
        cy="378.78"
        rx="230.68"
        ry="112.11"
        style={{ fill: '#f5f5f5' }}
      ></ellipse>
    </g>
    <g id="freepik--Shadows--inject-46">
      <path
        id="freepik--Shadow--inject-46"
        d="M152.14,433.48a2,2,0,0,1-2-1.63l-.45-2.42a24.58,24.58,0,0,1-3.07-.75h-.07l-4.26,2a2.47,2.47,0,0,1-1,.21,2.42,2.42,0,0,1-1.21-.31l-4.24-2.45a1.68,1.68,0,0,1-.15-2.88l2.57-1.84a9.54,9.54,0,0,1-.64-1l-5.41-.34a1.94,1.94,0,0,1-1.81-1.93V417a1.94,1.94,0,0,1,1.82-1.93l5.4-.34a9.54,9.54,0,0,1,.64-1l-2.57-1.84a1.74,1.74,0,0,1-.74-1.48,1.71,1.71,0,0,1,.89-1.4l4.24-2.45a2.42,2.42,0,0,1,1.21-.31,2.31,2.31,0,0,1,1,.21l4.26,2h.05s0,0,0,0a26.67,26.67,0,0,1,3.11-.77l.45-2.41a2.05,2.05,0,0,1,2-1.64h6.24a2,2,0,0,1,2,1.64l.46,2.41a26.48,26.48,0,0,1,3.07.76H164l4.26-2a2.31,2.31,0,0,1,1-.21,2.42,2.42,0,0,1,1.21.31l4.24,2.45a1.71,1.71,0,0,1,.89,1.4,1.76,1.76,0,0,1-.74,1.48l-2.57,1.84a9.54,9.54,0,0,1,.64,1l5.4.34a1.94,1.94,0,0,1,1.82,1.93v3.16a1.94,1.94,0,0,1-1.82,1.93l-5.4.34a9.54,9.54,0,0,1-.64,1l2.57,1.84a1.76,1.76,0,0,1,.74,1.48,1.72,1.72,0,0,1-.89,1.4l-4.24,2.45a2.42,2.42,0,0,1-1.21.31h0a2.47,2.47,0,0,1-1-.21l-4.26-2h0s0,0,0,0a26,26,0,0,1-3.1.76l-.46,2.42a2,2,0,0,1-2,1.63Zm3.12-19.39a13.16,13.16,0,0,0-6.48,1.53c-1.45.84-2.28,1.91-2.28,2.95s.83,2.11,2.28,2.95a14.49,14.49,0,0,0,13,0c1.45-.84,2.28-1.91,2.28-2.95s-.83-2.11-2.28-2.95A13.16,13.16,0,0,0,155.26,414.09Z"
        style={{ fill: '#e6e6e6' }}
      ></path>
      <path
        id="freepik--shadow--inject-46"
        d="M200.64,405.59a2.25,2.25,0,0,1-1.6-.7l-1.69-1.77-1.25,0c-.42,0-.83,0-1.24,0l-1.8,1.89a2,2,0,0,1-1.46.58,2.5,2.5,0,0,1-.54-.07l-4.2-1a1.84,1.84,0,0,1-1.24-1,1.6,1.6,0,0,1,0-1.37l.67-1.32c-.36-.2-.7-.41-1-.63l-4,.66a1.39,1.39,0,0,1-.3,0,2.14,2.14,0,0,1-1.69-.89l-1.59-2.21a1.84,1.84,0,0,1-.35-1,1.62,1.62,0,0,1,1.14-1.57l3.06-1a.66.66,0,0,1,0-.14l-2.95-.94a1.71,1.71,0,0,1-1.26-1.5v-.11a1.53,1.53,0,0,1,.3-.92l1.73-2.42a2,2,0,0,1,1.63-.77,2,2,0,0,1,.35,0l3.91.66c.28-.19.59-.38.91-.56l.12-.07-.62-1.23a1.62,1.62,0,0,1-.19-.69l0-.21,0-.11a1.65,1.65,0,0,1,1.25-1.41l4.39-1a1.49,1.49,0,0,1,.43-.05,2.22,2.22,0,0,1,1.59.7l1.7,1.77c.42,0,.85,0,1.27,0s.81,0,1.21,0l1.8-1.89a2,2,0,0,1,1.46-.58,1.88,1.88,0,0,1,.53.07l4.21,1a1.71,1.71,0,0,1,1.38,1.63,1.41,1.41,0,0,1-.15.69l-.68,1.35.14.08a10.26,10.26,0,0,1,.88.55l4-.66a1.36,1.36,0,0,1,.29,0,2.17,2.17,0,0,1,1.7.88l1.59,2.22a1.73,1.73,0,0,1,.35,1v.18l0,.12a1.62,1.62,0,0,1-1.1,1.3l-3.08,1v.14l3,.93a1.72,1.72,0,0,1,1,2.53c-.51.79-1.1,1.53-1.64,2.29l-.09.13a2,2,0,0,1-1.63.77l-.36,0-3.9-.65c-.33.22-.67.43-1,.63l.62,1.23a1.67,1.67,0,0,1,0,1.51,1.63,1.63,0,0,1-1.13.91l-4.36,1.05A2.06,2.06,0,0,1,200.64,405.59ZM190,395a3.29,3.29,0,0,0,.83.84,4.32,4.32,0,0,0,.5.33,7.8,7.8,0,0,0,2.12.81,11.25,11.25,0,0,0,2.62.3,9.87,9.87,0,0,0,4.73-1.1,5.65,5.65,0,0,0,.51-.33,3.16,3.16,0,0,0,.76-.74,1.35,1.35,0,0,0,.13-1.6l-.08-.12-.1-.15-.11-.13,0,0,0,0,0,0a6.51,6.51,0,0,0-3.11-1.56,11.32,11.32,0,0,0-2.62-.3c-2.62,0-5.09.89-6,2.17a1.37,1.37,0,0,0-.23,1.38l0,.11.11.19Z"
        style={{ fill: '#e6e6e6' }}
      ></path>
      <path
        id="freepik--shadow--inject-46"
        d="M257.82,424.33l35.88-20.72a1.17,1.17,0,0,0,0-2.22l-8.8-5.08a4.28,4.28,0,0,0-3.85,0L245.18,417a1.17,1.17,0,0,0,0,2.22l4.39,2.54-45,26c-1.06.61-.61.82-.12,1.11s.87.54,1.93-.07l45-26,2.59,1.5A4.28,4.28,0,0,0,257.82,424.33Z"
        style={{ fill: '#e6e6e6' }}
      ></path>
      <path
        id="freepik--shadow--inject-46"
        d="M244.29,384.57a16.13,16.13,0,0,1-8-1.95c-.25-.15-.49-.3-.72-.46l-1.32-.93,11.29-1.66,1-2.13-3.05-1.79-10.76,1.58.35-1a7,7,0,0,1,3.31-3.56,16.27,16.27,0,0,1,7.9-1.88,18.75,18.75,0,0,1,3.62.35l36.81-21.25a4.43,4.43,0,0,1-.31-3,6.67,6.67,0,0,1,3.42-4,16.34,16.34,0,0,1,7.91-1.88,16.14,16.14,0,0,1,8,2c.26.15.49.3.72.46l1.33.93-11.29,1.65-1,2.14,3.05,1.78,10.76-1.57-.35,1a7,7,0,0,1-3.31,3.55,16.27,16.27,0,0,1-7.9,1.88,18.83,18.83,0,0,1-3.62-.34l-36.81,21.25a4.35,4.35,0,0,1,.31,3,6.64,6.64,0,0,1-3.42,3.95A16.17,16.17,0,0,1,244.29,384.57Z"
        style={{ fill: '#e6e6e6' }}
      ></path>
      <ellipse
        id="freepik--shadow--inject-46"
        cx="387.02"
        cy="400.95"
        rx="57.04"
        ry="32.93"
        style={{ fill: '#e6e6e6' }}
      ></ellipse>
      <polygon
        id="freepik--shadow--inject-46"
        points="143.07 320.2 205.13 356.03 263.01 322.5 200.95 286.68 143.07 320.2"
        style={{ fill: '#e6e6e6' }}
      ></polygon>
      <polygon
        id="freepik--shadow--inject-46"
        points="119.51 407 203.19 358.69 129.97 316.42 46.3 364.73 119.51 407"
        style={{ fill: '#e6e6e6' }}
      ></polygon>
    </g>
    <g id="freepik--Gate--inject-46">
      <g id="freepik--gate--inject-46">
        <polygon
          points="205.12 353.37 146.37 319.44 146.37 316.88 200.37 285.7 259.12 319.62 259.12 322.19 205.12 353.37"
          style={{ fill: '#ED6A5A' }}
        ></polygon>
        <polygon
          points="146.37 316.88 205.12 350.8 259.12 319.62 200.37 285.7 146.37 316.88"
          style={{ fill: '#ED6A5A' }}
        ></polygon>
        <polygon
          points="146.37 316.88 205.12 350.8 259.12 319.62 200.37 285.7 146.37 316.88"
          style={{ opacity: 0.15 }}
        ></polygon>
        <path
          d="M219.68,331a4.34,4.34,0,0,0,3.94,0,1.2,1.2,0,0,0,0-2.27,4.34,4.34,0,0,0-3.94,0A1.2,1.2,0,0,0,219.68,331Z"
          style={{ fill: '#37474f' }}
        ></path>
        <polygon
          points="205.12 350.8 205.12 353.37 146.37 319.44 146.37 316.88 205.12 350.8"
          style={{ opacity: 0.30000000000000004 }}
        ></polygon>
      </g>
    </g>
    <g id="freepik--Server--inject-46">
      <g id="freepik--server--inject-46">
        <path
          d="M128.34,115.9l65.46,37.79a4.85,4.85,0,0,1,2.19,3.79V356.29a4.85,4.85,0,0,1-2.19,3.8L123,401a4.83,4.83,0,0,1-4.38,0L53.16,363.17A4.83,4.83,0,0,1,51,359.38V160.57a4.85,4.85,0,0,1,2.19-3.8L124,115.9A4.83,4.83,0,0,1,128.34,115.9Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M51,160.56V359.38a4.87,4.87,0,0,0,2.19,3.79L118.62,401a4.36,4.36,0,0,0,2.19.53V197.62a4.49,4.49,0,0,1-2.19-.53L53.16,159.3l-.6-.5-.78-.64A4.57,4.57,0,0,0,51,160.56Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M51,160.56V359.38a4.87,4.87,0,0,0,2.19,3.79L118.62,401a4.36,4.36,0,0,0,2.19.53V197.62a4.49,4.49,0,0,1-2.19-.53L53.16,159.3l-.6-.5-.78-.64A4.57,4.57,0,0,0,51,160.56Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M53.16,156.77,124,115.9a4.81,4.81,0,0,1,4.37,0l65.46,37.79a1.34,1.34,0,0,1,0,2.53L123,197.1a4.89,4.89,0,0,1-4.38,0L53.16,159.3A1.34,1.34,0,0,1,53.16,156.77Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M53.16,156.77,124,115.9a4.81,4.81,0,0,1,4.37,0l65.46,37.79a1.34,1.34,0,0,1,0,2.53L123,197.1a4.89,4.89,0,0,1-4.38,0L53.16,159.3A1.34,1.34,0,0,1,53.16,156.77Z"
          style={{ opacity: 0.15 }}
        ></path>
        <polygon
          points="188.13 291.62 129.38 325.54 129.38 387.89 188.13 353.97 188.13 291.62"
          style={{ fill: '#455a64' }}
        ></polygon>
        <path
          d="M159.26,336.16a.62.62,0,0,0-.61.06L134.75,350a1.79,1.79,0,0,0-.61.64l-1.6-.93a1.77,1.77,0,0,1,.6-.64l23.4-13.45a1.85,1.85,0,0,1,1.71,0Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M133.89,351.46v26.77c0,.55.39.77.86.5L158.65,365a1.9,1.9,0,0,0,.86-1.48V336.72c0-.55-.39-.77-.86-.5L134.75,350A1.89,1.89,0,0,0,133.89,351.46Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M134.12,378.78l-1-.57a1.89,1.89,0,0,1-.85-1.48v-26.2a1.7,1.7,0,0,1,.25-.85l1.6.93a1.81,1.81,0,0,0-.25.85v26.77A.65.65,0,0,0,134.12,378.78Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M135.8,376.32a.46.46,0,0,1-.2-.05.51.51,0,0,1-.22-.46V352a1.34,1.34,0,0,1,.61-1l21.31-12.25a.5.5,0,0,1,.51,0,.51.51,0,0,1,.22.46v23.79a1.32,1.32,0,0,1-.6,1.05l-21.31,12.25A.64.64,0,0,1,135.8,376.32Zm21.8-37.44a.41.41,0,0,0-.18.05L136.1,351.19a1.08,1.08,0,0,0-.48.83v23.79c0,.12,0,.21.09.24a.26.26,0,0,0,.26,0h0l21.31-12.25a1.08,1.08,0,0,0,.48-.83V339.14c0-.12,0-.21-.09-.24A.12.12,0,0,0,157.6,338.88Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M137.48,373.19a.21.21,0,0,1-.12,0,.26.26,0,0,1-.13-.25V352.72a.72.72,0,0,1,.29-.5l18.21-10.49a.28.28,0,0,1,.29,0,.29.29,0,0,1,.13.26l0,20.22a.64.64,0,0,1-.29.51l-18.19,10.45A.32.32,0,0,1,137.48,373.19Zm0-.24Zm18.4-31h0l-18.22,10.5a.5.5,0,0,0-.16.28V373l0,0,18.18-10.45a.39.39,0,0,0,.17-.29h0l0-20.22A.06.06,0,0,0,155.89,341.93Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M138.71,346.89a.25.25,0,0,1-.24-.25v-5.7a.24.24,0,1,1,.48,0v5.7A.25.25,0,0,1,138.71,346.89Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M142.7,344.6a.24.24,0,0,1-.24-.24v-5.71a.24.24,0,0,1,.24-.24.24.24,0,0,1,.24.24v5.71A.24.24,0,0,1,142.7,344.6Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M146.69,342.31a.24.24,0,0,1-.25-.24v-5.7a.25.25,0,0,1,.49,0v5.7A.24.24,0,0,1,146.69,342.31Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M150.67,340a.24.24,0,0,1-.24-.24v-5.71a.25.25,0,0,1,.49,0v5.71A.25.25,0,0,1,150.67,340Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M154.66,337.74a.24.24,0,0,1-.24-.24v-5.7a.24.24,0,0,1,.24-.24.23.23,0,0,1,.24.24v5.7A.23.23,0,0,1,154.66,337.74Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M159.26,315.1a.6.6,0,0,0-.61.06l-23.9,13.75a1.84,1.84,0,0,0-.61.63l-1.6-.92a1.69,1.69,0,0,1,.6-.64l23.4-13.46a1.91,1.91,0,0,1,1.71,0Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M133.89,330.39v13.5c0,.55.39.77.86.5l23.9-13.75a1.9,1.9,0,0,0,.86-1.48V315.65c0-.54-.39-.76-.86-.49l-23.9,13.75A1.88,1.88,0,0,0,133.89,330.39Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M134.12,344.44l-1-.57a1.89,1.89,0,0,1-.85-1.48V329.46a1.7,1.7,0,0,1,.25-.84l1.6.92a1.84,1.84,0,0,0-.25.85V343.9A.64.64,0,0,0,134.12,344.44Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M135.8,342a.46.46,0,0,1-.2-.05.51.51,0,0,1-.22-.46V331a1.34,1.34,0,0,1,.61-1l21.31-12.26a.46.46,0,0,1,.73.43v10.51a1.32,1.32,0,0,1-.6,1.05l-21.31,12.25A.64.64,0,0,1,135.8,342Zm21.8-24.17a.32.32,0,0,0-.18.06L136.1,330.13a1.08,1.08,0,0,0-.48.83v10.51c0,.12,0,.21.09.24a.26.26,0,0,0,.26,0h0l21.31-12.25a1.08,1.08,0,0,0,.48-.83V318.08a.29.29,0,0,0-.09-.25Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M137.48,338.86a.21.21,0,0,1-.12,0,.26.26,0,0,1-.13-.25v-6.91a.76.76,0,0,1,.29-.51l18.21-10.49a.3.3,0,0,1,.29,0,.27.27,0,0,1,.13.25l0,7a.64.64,0,0,1-.29.51l-18.19,10.45A.33.33,0,0,1,137.48,338.86Zm0-.25Zm18.4-17.75,0,0-18.22,10.49a.56.56,0,0,0-.16.29v6.95l0,0,18.18-10.45a.39.39,0,0,0,.17-.29h0l0-7S155.89,320.87,155.89,320.86Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,312.35a3.14,3.14,0,0,0-1.42,2.45c0,.9.64,1.26,1.42.81a3.14,3.14,0,0,0,1.41-2.44C165.5,312.27,164.86,311.9,164.09,312.35Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,336.35a3.14,3.14,0,0,0-1.42,2.45c0,.9.64,1.26,1.42.81a3.14,3.14,0,0,0,1.41-2.44C165.5,336.27,164.86,335.9,164.09,336.35Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,342.81a3.12,3.12,0,0,0-1.42,2.44c0,.9.64,1.26,1.42.81a3.12,3.12,0,0,0,1.41-2.44C165.5,342.72,164.86,342.36,164.09,342.81Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,349.26a3.14,3.14,0,0,0-1.42,2.45c0,.89.64,1.26,1.42.81a3.14,3.14,0,0,0,1.41-2.44C165.5,349.18,164.86,348.81,164.09,349.26Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,355.72a3.12,3.12,0,0,0-1.42,2.44c0,.9.64,1.26,1.42.81a3.14,3.14,0,0,0,1.41-2.44C165.5,355.63,164.86,355.27,164.09,355.72Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,318.18a3.14,3.14,0,0,0-1.42,2.45c0,.9.64,1.26,1.42.81A3.14,3.14,0,0,0,165.5,319C165.5,318.1,164.86,317.73,164.09,318.18Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164.09,324a3.14,3.14,0,0,0-1.42,2.45c0,.9.64,1.26,1.42.81a3.14,3.14,0,0,0,1.41-2.44C165.5,323.93,164.86,323.56,164.09,324Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M179.52,315.4l-.14-1.31a.14.14,0,0,0-.18-.13,2.94,2.94,0,0,1-.65.07.3.3,0,0,0-.26.13l-.29.43,1.54.88A.14.14,0,0,1,179.52,315.4Zm1.11-9.18a3,3,0,0,1,0,.31,5,5,0,0,1-2.28,4l-.28.15c.14,1.22,1.1,1.67,2.26,1a5,5,0,0,0,2.28-3.95C182.63,306.33,181.76,305.73,180.63,306.22Zm4.94-1.16-1.55-.9-.23.47a.33.33,0,0,0,0,.29,3,3,0,0,1,.27.6.14.14,0,0,0,.2.09l1.2-.53a.12.12,0,0,1,.1,0Zm-3-3.42-.87-.51h-.13l0,0s0,0,0,0l0,0-.71,1.07-.9-.52h0l-.65-.38h0l-.07,0-.93.54-.42.24-.05,0,0,.06,0,0,0,0h0a.41.41,0,0,0,0,.08l-.13,1.51a.17.17,0,0,1,0,.05.11.11,0,0,1,0,.05.61.61,0,0,1-.11.18,8.13,8.13,0,0,0-.76.79.14.14,0,0,1-.11.07.26.26,0,0,1-.15,0l-.87-.3h-.15l-.05,0,0,0-.41.71-.43.75-.15.26a.21.21,0,0,0,0,.13.23.23,0,0,0,.07.13l.7.6.05.07a.23.23,0,0,1,0,.11.19.19,0,0,1,0,.08,8.41,8.41,0,0,0-.32,1.05.51.51,0,0,1-.08.17l-.09.1-1.23.86s-.06.06-.09.1a.34.34,0,0,0,0,.17v1.56a.12.12,0,0,0,0,.1l1.57.9-.57,1.15a.18.18,0,0,0,.07.23l1.9,1.1.08,0a.46.46,0,0,1-.07-.07.2.2,0,0,1,0-.17l.8-1.61a.14.14,0,0,0,0-.07.29.29,0,0,0,0-.22,3.29,3.29,0,0,1-.26-.6.14.14,0,0,0-.2-.09l-.61.27-.6.27a.09.09,0,0,1-.1,0,.1.1,0,0,1-.05-.1v-1.56a.33.33,0,0,1,.05-.16s0-.08.09-.1l1.23-.87a.32.32,0,0,0,.1-.1.37.37,0,0,0,.07-.16,8.41,8.41,0,0,1,.32-1.05.25.25,0,0,0,0-.09.27.27,0,0,0-.08-.18l-.7-.6a.16.16,0,0,1-.06-.12.2.2,0,0,1,0-.13l.29-.49.34-.61.36-.62a.25.25,0,0,1,.16-.1h.09l.86.3a.22.22,0,0,0,.16,0,.26.26,0,0,0,.11-.07,8.22,8.22,0,0,1,.75-.8.42.42,0,0,0,.11-.17.23.23,0,0,0,0-.11l.14-1.5a.24.24,0,0,1,0-.08.34.34,0,0,1,.14-.17l.25-.15.54-.31.56-.32a.1.1,0,0,1,.09,0h0s.05.05.06.09l.07.69.06.62a.14.14,0,0,0,.18.13,3,3,0,0,1,.65-.07.24.24,0,0,0,.19-.06.16.16,0,0,0,.07-.07l1-1.5a.17.17,0,0,1,.23-.05Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M179.54,303.46a.24.24,0,0,0,0,.08l-.14,1.5a.23.23,0,0,1,0,.11l-2-1.15h0l0-.05a.17.17,0,0,0,0-.05l.13-1.51a.41.41,0,0,1,0-.08Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M178.38,306.19a.22.22,0,0,1-.16,0l-.86-.3h-.09l-2-1.13h.06l.87.3a.26.26,0,0,0,.15,0Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M176.94,308.73l-2-1.14h0s0-.06,0-.1l-.05-.07-.7-.6a.23.23,0,0,1-.07-.13l2,1.15a.16.16,0,0,0,.06.12l.7.6A.27.27,0,0,1,176.94,308.73Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M176.93,313.29a.14.14,0,0,1,0,.07l-.8,1.61a.2.2,0,0,0,0,.17v0h0l-1.89-1.1a.18.18,0,0,1-.07-.23l.57-1.15.42.24h0a.09.09,0,0,0,.1,0l.6-.27Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M179.52,315.4a.14.14,0,0,0,0,.07l-1.54-.88.29-.43a.3.3,0,0,1,.26-.13,2.94,2.94,0,0,0,.65-.07.14.14,0,0,1,.18.13Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M183.64,302.28a.17.17,0,0,0-.23.05l-1,1.5a.16.16,0,0,1-.07.07l-.56-.32-.52-.3-.07-.69s0-.08-.06-.09h0l-.4-.24.71-1.07,0,0s0,0,0,0l0,0h0a.22.22,0,0,1,.11,0l.87.51Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M176.54,310a.32.32,0,0,1-.1.1l-1.23.87s-.06.06-.09.1l-2-1.15s.05-.08.09-.1l1.23-.86.09-.1Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M185.48,305.07l-1.2.54a.14.14,0,0,1-.2-.09,3,3,0,0,0-.27-.6.33.33,0,0,1,0-.29l.8-1.61a.19.19,0,0,0-.07-.24l-.87-.5a.18.18,0,0,0-.24.05l-1,1.5a.3.3,0,0,1-.26.13,3,3,0,0,0-.65.07.14.14,0,0,1-.18-.13l-.13-1.31a.1.1,0,0,0-.16-.08l-1.35.78a.33.33,0,0,0-.16.25l-.14,1.5a.47.47,0,0,1-.14.28,8.22,8.22,0,0,0-.75.8.27.27,0,0,1-.27.07l-.86-.3a.22.22,0,0,0-.25.09l-1,1.72a.21.21,0,0,0,0,.25l.7.6a.27.27,0,0,1,.07.27,8.41,8.41,0,0,0-.32,1.05.41.41,0,0,1-.17.26l-1.23.87a.34.34,0,0,0-.14.26v1.56a.1.1,0,0,0,.15.1l1.21-.54a.14.14,0,0,1,.2.09,3.29,3.29,0,0,0,.26.6.3.3,0,0,1,0,.29l-.8,1.61a.19.19,0,0,0,.07.24l.87.5a.18.18,0,0,0,.24,0l1-1.5a.3.3,0,0,1,.26-.13,2.94,2.94,0,0,0,.65-.07.14.14,0,0,1,.18.13l.14,1.31c0,.09.07.13.16.08l1.35-.78a.38.38,0,0,0,.16-.25l.13-1.5a.47.47,0,0,1,.14-.28,7.16,7.16,0,0,0,.75-.8.27.27,0,0,1,.27-.07l.87.3a.21.21,0,0,0,.24-.09l1-1.72a.2.2,0,0,0,0-.25l-.7-.6a.27.27,0,0,1-.07-.27,8.41,8.41,0,0,0,.32-1.05.46.46,0,0,1,.17-.26l1.23-.87a.34.34,0,0,0,.14-.26v-1.56A.1.1,0,0,0,185.48,305.07Zm-5.13,6.56c-1.26.73-2.28.14-2.28-1.32a5,5,0,0,1,2.28-3.95c1.26-.73,2.28-.14,2.28,1.32A5,5,0,0,1,180.35,311.63Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M176.19,318.62l-.06,0,0,0h0l-.32-.18-.91-.53,0,0-.08,0h-.12l-.85.08h-.08l0,0,0,0,0,0s0,0,0,0l-.46,1.23a.23.23,0,0,1-.07.1l-.11.08-.28.14-.88-.51h0a.16.16,0,0,0-.09,0l-.66.7-.27.28a.13.13,0,0,0,0,.06l0,.06a.11.11,0,0,0,0,.05v0l.26.88s0,.07,0,.11a.42.42,0,0,1,0,.1,6.17,6.17,0,0,0-.43.74.24.24,0,0,1-.1.1.24.24,0,0,1-.1.05l-.8.2-.06,0a.1.1,0,0,0-.05,0l0,0a.07.07,0,0,0,0,.06l-.3,1-.1.36c0,.05,0,.08,0,.1h0l.53.31.07,0,.29.16h0c0,.12,0,.24,0,.36a.28.28,0,0,1-.07.21l-.81,1a.34.34,0,0,0,0,.11h0a.12.12,0,0,0,0,.1l.38.84,0,0h0l1.61.94.08,0v0l0-.08-.19-.41-.16-.36a.2.2,0,0,1,0-.11.14.14,0,0,1,.05-.09l.81-1a.31.31,0,0,0,0-.1.3.3,0,0,0,0-.12,4.09,4.09,0,0,1,0-.7.12.12,0,0,0-.12-.12l-.73,0-.06,0h0a.09.09,0,0,1,0-.09l.18-.61,0,0,.2-.7,0-.06a.26.26,0,0,1,.15-.09l.8-.2a.16.16,0,0,0,.1-.05.19.19,0,0,0,.1-.1,7.89,7.89,0,0,1,.43-.74.3.3,0,0,0,0-.1.22.22,0,0,0,0-.11l-.26-.88a.17.17,0,0,1,0-.1h0a.17.17,0,0,1,.06-.1l.63-.67.3-.32s.1-.06.13,0h0l0,0,.43.66s.11,0,.18,0a4.26,4.26,0,0,1,.53-.31.27.27,0,0,0,.1-.06.26.26,0,0,0,.08-.11l.46-1.24,0-.06.31-.08.68-.07h.07Zm-3.6,9a.13.13,0,0,0-.08-.15,1.51,1.51,0,0,1-.37-.22.18.18,0,0,0-.2,0l-.86.79a.21.21,0,0,1-.1,0l.85.49.53.31a.09.09,0,0,1,0-.08Zm3.8-8.92a.1.1,0,0,1,0,.09l-.27,1.21a.14.14,0,0,0,.09.15,2,2,0,0,1,.37.22s.12,0,.19,0l.86-.8c.05,0,.1-.05.13,0Zm-1.13,9-.43-.66c0-.05-.11-.05-.18,0l-.26.16.89.52Zm.69-5.51a.85.85,0,0,0-.67-.53h-.1a1.39,1.39,0,0,0-.6.13,1.69,1.69,0,0,1,0,.23,3.74,3.74,0,0,1-1.71,2.95l-.19.11a1.24,1.24,0,0,0,.11.44.84.84,0,0,0,.8.54h.33a2.42,2.42,0,0,0,1.1-.72A3.23,3.23,0,0,0,176,322.19Zm1.34,0c0,.12,0,.23,0,.34a.12.12,0,0,0,.13.12l.72,0,.06,0Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M175.26,327.7l0,0-.89-.52.26-.16c.07-.05.15-.05.18,0Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M172.32,328.88a.09.09,0,0,0,0,.08l-.53-.31-.85-.49a.21.21,0,0,0,.1,0l.86-.79a.18.18,0,0,1,.2,0,1.51,1.51,0,0,0,.37.22.13.13,0,0,1,.08.15Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M171.46,326a.28.28,0,0,1-.06.09l-.82,1a.18.18,0,0,0,0,.1l-1.71-1a.51.51,0,0,1,0-.12l.81-1s0-.06.05-.08Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M171.47,325.17c0,.24,0,.47,0,.7a.26.26,0,0,1,0,.12l-1.72-1a.21.21,0,0,0,0-.13c0-.12,0-.24,0-.36l.8.47h0l.06,0,.73,0A.12.12,0,0,1,171.47,325.17Z"
          style={{ opacity: 0.05 }}
        ></path>
        <path
          d="M172,323.15a.19.19,0,0,1-.11,0l-.8.2a.23.23,0,0,0-.15.1l-1.71-1,0,0s0,0,.05,0l.06,0,.8-.19a.27.27,0,0,0,.1-.06Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M172.59,322.09a.22.22,0,0,1,0,.11l-1.71-1a.24.24,0,0,0,0-.12l-.26-.88a0,0,0,0,1,0,0s0,0,0-.05l1.72,1a.38.38,0,0,0,0,.1Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M175.32,318.88l0,.06-.45,1.23a.31.31,0,0,1-.09.12l-1.72-1,0,0,.07-.09.47-1.24,0,0s0,0,0,0Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M175.32,318.88a.27.27,0,0,1,.14-.07h.17"
          style={{ fill: 'none' }}
        ></path>
        <path
          d="M178.18,322.71l-.06,0-.72,0a.12.12,0,0,1-.13-.12c0-.11,0-.22,0-.34s0-.24,0-.36a.34.34,0,0,1,.08-.21l.82-1a.22.22,0,0,0,0-.2l-.38-.85,0,0s-.08,0-.13,0l-.86.8c-.07.05-.15.07-.19,0a2,2,0,0,0-.37-.22.14.14,0,0,1-.09-.15l.27-1.21a.1.1,0,0,0,0-.09.11.11,0,0,0-.08,0l-.68.07h-.17a.27.27,0,0,0-.14.07l0,.06-.46,1.24a.26.26,0,0,1-.08.11.27.27,0,0,1-.1.06,4.26,4.26,0,0,0-.53.31c-.07,0-.15,0-.18,0l-.43-.66,0,0s-.08,0-.13,0l-.3.32-.63.67a.26.26,0,0,0-.07.1.17.17,0,0,0,0,.1l.26.88a.22.22,0,0,1,0,.11.3.3,0,0,1,0,.1,7.89,7.89,0,0,0-.43.74.19.19,0,0,1-.1.1.16.16,0,0,1-.1.05l-.8.2a.26.26,0,0,0-.15.09l0,.06-.2.7,0,0-.18.61a.09.09,0,0,0,0,.09h0l.06,0,.73,0a.12.12,0,0,1,.12.12,4.09,4.09,0,0,0,0,.7.3.3,0,0,1,0,.12.31.31,0,0,1,0,.1l-.81,1a.23.23,0,0,0-.05.2l.16.36,0,.05.05.11.07.14,0,.13,0,.06v0s0,0,0,0a.21.21,0,0,0,.1,0l.86-.79a.18.18,0,0,1,.2,0,1.51,1.51,0,0,0,.37.22.13.13,0,0,1,.08.15l-.27,1.22a.09.09,0,0,0,0,.08.11.11,0,0,0,.08,0l.85-.08a.27.27,0,0,0,.19-.13l.46-1.23a.32.32,0,0,1,.18-.18l.27-.15.26-.16c.07-.05.15-.05.18,0l.43.66,0,0a.12.12,0,0,0,.14,0l.93-1a.21.21,0,0,0,.07-.2l-.26-.88a.34.34,0,0,1,.05-.21,7.89,7.89,0,0,0,.43-.74.28.28,0,0,1,.2-.15l.8-.19a.31.31,0,0,0,.18-.15l.4-1.36A.08.08,0,0,0,178.18,322.71Zm-4.25,3.3h-.33a.84.84,0,0,1-.8-.54,1.24,1.24,0,0,1-.11-.44,2.11,2.11,0,0,1,0-.25,3.83,3.83,0,0,1,1.05-2.41,2.75,2.75,0,0,1,.65-.52l.21-.11a1.39,1.39,0,0,1,.6-.13h.1a.85.85,0,0,1,.67.53,3.23,3.23,0,0,1-.92,3.1A2.42,2.42,0,0,1,173.93,326Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M175.34,341.7a6.41,6.41,0,0,0-3.62,3,5.94,5.94,0,0,0,.19,5.64,6.5,6.5,0,0,0-1,3.49,7.74,7.74,0,0,0,5.1,6.89l.29.1.87-.5a8.29,8.29,0,0,1-4.68-4.3,5.85,5.85,0,0,1,.15-4.59A7.35,7.35,0,0,0,174.3,353c1.74,1.14,5.13,2,6.72.17a2.84,2.84,0,0,0-.13-3.71,5.56,5.56,0,0,0-4-1.87,5.62,5.62,0,0,0-4.16,1.75,5.46,5.46,0,0,1-.41-2.58,4.92,4.92,0,0,1,3.52-4.13c1.44,2.24,4.62,4.28,7.07,2.37s-1.46-3.64-3.1-3.79a10.71,10.71,0,0,0-3.39.19,3.68,3.68,0,0,1-.22-2,4.39,4.39,0,0,1,1.67-2.6,5.6,5.6,0,0,1,6.6-.4.36.36,0,1,0,.46-.56C181,332.24,173.28,336.65,175.34,341.7Zm-.62,7.42a4.17,4.17,0,0,1,3.69-.17c1,.45,2.2,1.4,2,2.66-.28,2.19-3.23,1.58-4.61,1a5.79,5.79,0,0,1-2.59-2.24A4.77,4.77,0,0,1,174.72,349.12Zm3-6.91a7.28,7.28,0,0,1,3.08.22c.47.15,1.81.6,1.74,1.28,0,.42-.65.74-1,.88a2.54,2.54,0,0,1-1.61.08,5.49,5.49,0,0,1-2.78-2l-.23-.33A6.84,6.84,0,0,1,177.69,342.21Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M175.34,341.7a6.41,6.41,0,0,0-3.62,3,5.94,5.94,0,0,0,.19,5.64,6.5,6.5,0,0,0-1,3.49,7.74,7.74,0,0,0,5.1,6.89l.29.1.87-.5a8.29,8.29,0,0,1-4.68-4.3,5.85,5.85,0,0,1,.15-4.59A7.35,7.35,0,0,0,174.3,353c1.74,1.14,5.13,2,6.72.17a2.84,2.84,0,0,0-.13-3.71,5.56,5.56,0,0,0-4-1.87,5.62,5.62,0,0,0-4.16,1.75,5.46,5.46,0,0,1-.41-2.58,4.92,4.92,0,0,1,3.52-4.13c1.44,2.24,4.62,4.28,7.07,2.37s-1.46-3.64-3.1-3.79a10.71,10.71,0,0,0-3.39.19,3.68,3.68,0,0,1-.22-2,4.39,4.39,0,0,1,1.67-2.6,5.6,5.6,0,0,1,6.6-.4.36.36,0,1,0,.46-.56C181,332.24,173.28,336.65,175.34,341.7Zm-.62,7.42a4.17,4.17,0,0,1,3.69-.17c1,.45,2.2,1.4,2,2.66-.28,2.19-3.23,1.58-4.61,1a5.79,5.79,0,0,1-2.59-2.24A4.77,4.77,0,0,1,174.72,349.12Zm3-6.91a7.28,7.28,0,0,1,3.08.22c.47.15,1.81.6,1.74,1.28,0,.42-.65.74-1,.88a2.54,2.54,0,0,1-1.61.08,5.49,5.49,0,0,1-2.78-2l-.23-.33A6.84,6.84,0,0,1,177.69,342.21Z"
          style={{ fill: '#fff', opacity: 0.7000000000000001 }}
        ></path>
        <path
          d="M172.27,352.4a5.43,5.43,0,0,1,.35-1,3.86,3.86,0,0,1-.71-1.09,7,7,0,0,0-.54,1.06A8.6,8.6,0,0,0,172.27,352.4Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M174.6,343.21a7.24,7.24,0,0,1,1.22-.59,5.7,5.7,0,0,1-.48-.92,7.31,7.31,0,0,0-1.36.63A6.94,6.94,0,0,0,174.6,343.21Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M176.28,360.81l.87-.5a10.72,10.72,0,0,1-1.08-.52l-.93.53a7.2,7.2,0,0,0,.85.39Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M199.46,344.67a6.45,6.45,0,0,0-4.52-1.29,6,6,0,0,0-4.54,3.36,6.44,6.44,0,0,0-2.28.5,7.83,7.83,0,0,0-1.17.64,7.58,7.58,0,0,0-2.93,7.2c0,.31.09.61.15.91l.06.22.77-.44,0-.24a7.81,7.81,0,0,1,1.08-5.11,5.7,5.7,0,0,1,2.07-1.86,6,6,0,0,1,1.79-.62,6.89,6.89,0,0,0-.33,2.28c0,2.08,1.26,5.36,3.67,5.63a2.84,2.84,0,0,0,3-2.22,5.47,5.47,0,0,0-.74-4.36,5.63,5.63,0,0,0-3.79-2.44,5.4,5.4,0,0,1,1.88-1.8,4.92,4.92,0,0,1,5.4.56c-1,2.46-.9,6.22,2.06,7.15s2.16-3.26,1.37-4.69a11.14,11.14,0,0,0-2.08-2.69,3.64,3.64,0,0,1,1.54-1.33,4.36,4.36,0,0,1,3.08-.1,5.62,5.62,0,0,1,4.08,5.21.36.36,0,1,0,.72.06C210.47,344,202.45,340.11,199.46,344.67Zm-6.46,3.7a4.16,4.16,0,0,1,2.23,2.94c.21,1.1.1,2.61-1,3.19-2,1-3.14-1.77-3.45-3.24a5.87,5.87,0,0,1,.38-3.4A4.84,4.84,0,0,1,193,348.37Zm7.37-1.48a7.41,7.41,0,0,1,1.57,2.66c.14.47.54,1.84-.08,2.16-.36.2-1-.11-1.28-.31a2.47,2.47,0,0,1-1-1.28,5.44,5.44,0,0,1,.08-3.43,3,3,0,0,1,.13-.39C200,346.5,200.2,346.69,200.37,346.89Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M199.46,344.67a6.45,6.45,0,0,0-4.52-1.29,6,6,0,0,0-4.54,3.36,6.44,6.44,0,0,0-2.28.5,7.83,7.83,0,0,0-1.17.64,7.58,7.58,0,0,0-2.93,7.2c0,.31.09.61.15.91l.06.22.77-.44,0-.24a7.81,7.81,0,0,1,1.08-5.11,5.7,5.7,0,0,1,2.07-1.86,6,6,0,0,1,1.79-.62,6.89,6.89,0,0,0-.33,2.28c0,2.08,1.26,5.36,3.67,5.63a2.84,2.84,0,0,0,3-2.22,5.47,5.47,0,0,0-.74-4.36,5.63,5.63,0,0,0-3.79-2.44,5.4,5.4,0,0,1,1.88-1.8,4.92,4.92,0,0,1,5.4.56c-1,2.46-.9,6.22,2.06,7.15s2.16-3.26,1.37-4.69a11.14,11.14,0,0,0-2.08-2.69,3.64,3.64,0,0,1,1.54-1.33,4.36,4.36,0,0,1,3.08-.1,5.62,5.62,0,0,1,4.08,5.21.36.36,0,1,0,.72.06C210.47,344,202.45,340.11,199.46,344.67Zm-6.46,3.7a4.16,4.16,0,0,1,2.23,2.94c.21,1.1.1,2.61-1,3.19-2,1-3.14-1.77-3.45-3.24a5.87,5.87,0,0,1,.38-3.4A4.84,4.84,0,0,1,193,348.37Zm7.37-1.48a7.41,7.41,0,0,1,1.57,2.66c.14.47.54,1.84-.08,2.16-.36.2-1-.11-1.28-.31a2.47,2.47,0,0,1-1-1.28,5.44,5.44,0,0,1,.08-3.43,3,3,0,0,1,.13-.39C200,346.5,200.2,346.69,200.37,346.89Z"
          style={{ fill: '#fff', opacity: 0.7000000000000001 }}
        ></path>
        <path
          d="M188.91,348.21a5.68,5.68,0,0,1,1-.27,4.22,4.22,0,0,1,.49-1.2,6.85,6.85,0,0,0-1.18.17A8.27,8.27,0,0,0,188.91,348.21Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M197.79,344.92a6,6,0,0,1,1.18.67,6.92,6.92,0,0,1,.49-.93,7.66,7.66,0,0,0-1.31-.73A5.86,5.86,0,0,0,197.79,344.92Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M184.23,356.21l-.06-.22c-.06-.3-.11-.6-.15-.91l.95.45,0,.24Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M185.94,245,131.56,276.4a4.84,4.84,0,0,0-2.18,3.79v18.66c0,1.4,1,2,2.19,1.27l54.37-31.39a4.87,4.87,0,0,0,2.19-3.79V246.27C188.13,244.88,187.15,244.31,185.94,245Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M184,253.23a4.7,4.7,0,0,1-2.19,3.64l-46.16,26.65c-1.21.69-2.19.2-2.19-1.11a4.7,4.7,0,0,1,2.19-3.64l46.16-26.65C183,251.43,184,251.92,184,253.23Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M184,262.72a4.7,4.7,0,0,1-2.19,3.64L135.67,293c-1.21.7-2.19.21-2.19-1.1a4.7,4.7,0,0,1,2.19-3.64l46.16-26.65C183,260.91,184,261.41,184,262.72Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M181.83,261.61l-19.59,11.31v4.74l19.59-11.3a4.7,4.7,0,0,0,2.19-3.64C184,261.41,183,260.91,181.83,261.61Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M110.54,201.07c-2.27-1.31-4.11-.12-4.11,2.68V304.13a9.4,9.4,0,0,0,4.11,7.44c2.27,1.32,4.11.12,4.11-2.67V208.51A9.43,9.43,0,0,0,110.54,201.07Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M110.54,201.07c-2.27-1.31-4.11-.12-4.11,2.68V304.13a9.4,9.4,0,0,0,4.11,7.44c2.27,1.32,4.11.12,4.11-2.67V208.51A9.43,9.43,0,0,0,110.54,201.07Z"
          style={{ opacity: 0.5 }}
        ></path>
        <path
          d="M110.54,309.17a4.7,4.7,0,0,1-2.05-3.71V204.77c0-1.4.92-2,2.05-1.35a4.69,4.69,0,0,1,2,3.72V307.83C112.59,309.23,111.67,309.83,110.54,309.17Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M110.54,309.17a4.7,4.7,0,0,1-2.05-3.71v-49.2l4.1,2.37v49.2C112.59,309.23,111.67,309.83,110.54,309.17Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M98.22,194c-2.27-1.32-4.11-.12-4.11,2.67V297a9.43,9.43,0,0,0,4.11,7.44c2.26,1.31,4.1.11,4.1-2.68V201.39A9.41,9.41,0,0,0,98.22,194Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M98.22,194c-2.27-1.32-4.11-.12-4.11,2.67V297a9.43,9.43,0,0,0,4.11,7.44c2.26,1.31,4.1.11,4.1-2.68V201.39A9.41,9.41,0,0,0,98.22,194Z"
          style={{ opacity: 0.5 }}
        ></path>
        <path
          d="M98.22,302.06a4.7,4.7,0,0,1-2.06-3.72V197.65c0-1.4.92-2,2.06-1.34a4.7,4.7,0,0,1,2,3.71V300.71C100.27,302.11,99.35,302.71,98.22,302.06Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M85.89,186.84c-2.27-1.31-4.11-.12-4.11,2.68V289.9a9.4,9.4,0,0,0,4.11,7.44c2.27,1.32,4.11.12,4.11-2.67V194.28A9.43,9.43,0,0,0,85.89,186.84Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M85.89,186.84c-2.27-1.31-4.11-.12-4.11,2.68V289.9a9.4,9.4,0,0,0,4.11,7.44c2.27,1.32,4.11.12,4.11-2.67V194.28A9.43,9.43,0,0,0,85.89,186.84Z"
          style={{ opacity: 0.5 }}
        ></path>
        <path
          d="M85.89,294.94a4.7,4.7,0,0,1-2-3.71V190.54c0-1.4.92-2,2-1.35A4.7,4.7,0,0,1,88,192.91V293.6C87.94,295,87,295.6,85.89,294.94Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M85.89,294.94a4.7,4.7,0,0,1-2-3.71V218.11L88,220.49V293.6C87.94,295,87,295.6,85.89,294.94Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M73.57,179.73c-2.27-1.32-4.11-.12-4.11,2.67V282.79a9.43,9.43,0,0,0,4.11,7.44c2.26,1.31,4.1.11,4.1-2.68V187.16A9.39,9.39,0,0,0,73.57,179.73Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M73.57,179.73c-2.27-1.32-4.11-.12-4.11,2.67V282.79a9.43,9.43,0,0,0,4.11,7.44c2.26,1.31,4.1.11,4.1-2.68V187.16A9.39,9.39,0,0,0,73.57,179.73Z"
          style={{ opacity: 0.5 }}
        ></path>
        <path
          d="M73.57,287.83a4.7,4.7,0,0,1-2.06-3.72V183.42c0-1.4.92-2,2.06-1.34a4.7,4.7,0,0,1,2.05,3.71V286.48C75.62,287.88,74.7,288.48,73.57,287.83Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M73.57,287.83a4.7,4.7,0,0,1-2.06-3.72V265.75l4.11,2.37v18.36C75.62,287.88,74.7,288.48,73.57,287.83Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M61.24,172.61c-2.27-1.32-4.11-.12-4.11,2.68V275.67a9.4,9.4,0,0,0,4.11,7.44c2.27,1.32,4.11.12,4.11-2.67V180.05A9.43,9.43,0,0,0,61.24,172.61Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M61.24,172.61c-2.27-1.32-4.11-.12-4.11,2.68V275.67a9.4,9.4,0,0,0,4.11,7.44c2.27,1.32,4.11.12,4.11-2.67V180.05A9.43,9.43,0,0,0,61.24,172.61Z"
          style={{ opacity: 0.5 }}
        ></path>
        <path
          d="M61.24,280.71A4.7,4.7,0,0,1,59.19,277V176.3c0-1.39.92-2,2.05-1.34a4.7,4.7,0,0,1,2.06,3.72V279.37C63.3,280.76,62.38,281.37,61.24,280.71Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M61.24,280.71A4.7,4.7,0,0,1,59.19,277V223.05l4.11,2.38v53.94C63.3,280.76,62.38,281.37,61.24,280.71Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M185.94,169.32l-54.38,31.39a4.82,4.82,0,0,0-2.18,3.79V267c0,1.39,1,2,2.19,1.26l54.37-31.39a4.87,4.87,0,0,0,2.19-3.79V170.58C188.13,169.18,187.15,168.62,185.94,169.32Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M130,202.43a4.29,4.29,0,0,0-.59,2.07V267c0,1.39,1,2,2.19,1.26l54.37-31.39a4.58,4.58,0,0,0,1.59-1.72Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M131.56,200.71a4.82,4.82,0,0,0-2.18,3.79V267l54.37-31.39a4.87,4.87,0,0,0,2.18-3.8V169.32Z"
          style={{ fill: '#455a64' }}
        ></path>
        <g id="freepik--dead-emoji--inject-46">
          <path
            d="M149.47,217.14a1.1,1.1,0,0,1-.78-.31l-7.23-7a1.12,1.12,0,1,1,1.56-1.62l7.23,6.95a1.13,1.13,0,0,1-.78,1.94Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M142.24,221.31a1.05,1.05,0,0,1-.48-.11,1.13,1.13,0,0,1-.54-1.5l7.23-15.3a1.12,1.12,0,0,1,1.5-.53,1.13,1.13,0,0,1,.54,1.5l-7.23,15.3A1.13,1.13,0,0,1,142.24,221.31Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M173.07,203.51a1.12,1.12,0,0,1-.78-.31l-7.23-6.95a1.13,1.13,0,0,1,1.57-1.63l7.22,6.95a1.14,1.14,0,0,1,0,1.6A1.1,1.1,0,0,1,173.07,203.51Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M165.84,207.69a1.2,1.2,0,0,1-.48-.11,1.14,1.14,0,0,1-.54-1.5l7.23-15.3a1.13,1.13,0,0,1,2,1L166.86,207A1.13,1.13,0,0,1,165.84,207.69Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M141.92,238.65a1.14,1.14,0,0,1-1-.57,1.13,1.13,0,0,1,.42-1.54l31.47-18.17a1.13,1.13,0,0,1,1.54.41,1.14,1.14,0,0,1-.42,1.55l-31.47,18.16A1,1,0,0,1,141.92,238.65Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M163.85,239.16a3.11,3.11,0,0,1-1.58-.42,4.17,4.17,0,0,1-1.81-3.79v-8.79a1.14,1.14,0,0,1,1.13-1.13h0a1.14,1.14,0,0,1,1.13,1.13V235a2.07,2.07,0,0,0,.68,1.83,1.65,1.65,0,0,0,1.56-.24,7.85,7.85,0,0,0,3.37-6.14v-8.78a1.13,1.13,0,1,1,2.25,0v8.78a10.09,10.09,0,0,1-4.49,8.09A4.46,4.46,0,0,1,163.85,239.16Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M165.52,233.5a1.13,1.13,0,0,1-1.13-1.13v-8.48a1.13,1.13,0,0,1,2.26,0v8.48A1.12,1.12,0,0,1,165.52,233.5Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
        </g>
        <g id="freepik--server--inject-46">
          <path
            d="M66.16,363.4a2.11,2.11,0,0,1,1,1.67c0,.62-.43.87-1,.56a2.16,2.16,0,0,1-1-1.67C65.19,363.34,65.63,363.09,66.16,363.4Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M74.24,368.07a2.11,2.11,0,0,1,1,1.67c0,.61-.43.86-1,.56a2.13,2.13,0,0,1-1-1.68C73.27,368,73.7,367.76,74.24,368.07Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M82.17,372.65a2.1,2.1,0,0,1,1,1.66c0,.62-.43.87-1,.56a2.13,2.13,0,0,1-1-1.67C81.21,372.59,81.64,372.34,82.17,372.65Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M90.18,377.27a2.13,2.13,0,0,1,1,1.66c0,.61-.43.86-1,.56a2.13,2.13,0,0,1-1-1.66C89.22,377.21,89.65,377,90.18,377.27Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M56.74,315.92,65,320.63a5.17,5.17,0,0,1,2.32,4v4.71c0,1.48-1,2.09-2.32,1.34L56.74,326a5.16,5.16,0,0,1-2.33-4v-4.71C54.41,315.78,55.46,315.18,56.74,315.92Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M59,321.23l1.07-.62h0a1.41,1.41,0,0,1,1.42.14,4.48,4.48,0,0,1,2,3.51,1.4,1.4,0,0,1-.59,1.3h0l-1,.57-.18-.3a1.81,1.81,0,0,1-.58-.22,4.46,4.46,0,0,1-2-3.5,2.06,2.06,0,0,1,.06-.52Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M59,321.23l1.07-.62h0a1.41,1.41,0,0,1,1.42.14,4.48,4.48,0,0,1,2,3.51,1.4,1.4,0,0,1-.59,1.3h0l-1,.57-.18-.3a1.81,1.81,0,0,1-.58-.22,4.46,4.46,0,0,1-2-3.5,2.06,2.06,0,0,1,.06-.52Z"
            style={{ opacity: 0.2 }}
          ></path>
          <path
            d="M60.42,321.34a4.49,4.49,0,0,1,2,3.51c0,1.28-.91,1.81-2,1.16a4.47,4.47,0,0,1-2-3.5C58.4,321.22,59.31,320.7,60.42,321.34Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M56.74,293.25,65,298a5.14,5.14,0,0,1,2.32,4v4.7c0,1.49-1,2.09-2.32,1.35l-8.21-4.72a5.15,5.15,0,0,1-2.33-4v-4.71C54.41,293.11,55.46,292.51,56.74,293.25Z"
            style={{ fill: '#455a64' }}
          ></path>
          <path
            d="M59,298.56l1.07-.62h0a1.42,1.42,0,0,1,1.42.15,4.45,4.45,0,0,1,2,3.5,1.43,1.43,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.12,2.12,0,0,1-.58-.23,4.45,4.45,0,0,1-2-3.5,2.12,2.12,0,0,1,.06-.52Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M59,298.56l1.07-.62h0a1.42,1.42,0,0,1,1.42.15,4.45,4.45,0,0,1,2,3.5,1.43,1.43,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.12,2.12,0,0,1-.58-.23,4.45,4.45,0,0,1-2-3.5,2.12,2.12,0,0,1,.06-.52Z"
            style={{ opacity: 0.2 }}
          ></path>
          <path
            d="M60.42,298.68a4.46,4.46,0,0,1,2,3.5c0,1.29-.91,1.81-2,1.17a4.51,4.51,0,0,1-2-3.51C58.4,298.55,59.31,298,60.42,298.68Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M185.57,276.07l-38,21.95a5.66,5.66,0,0,0-2.55,4.43v5.17c0,1.62,1.14,2.28,2.55,1.47l38-21.95a5.67,5.67,0,0,0,2.56-4.43v-5.16C188.13,275.92,187,275.26,185.57,276.07Z"
            style={{ fill: '#455a64' }}
          ></path>
          <path
            d="M185.43,280.45V283a1.13,1.13,0,0,1-.51.89l-.57.33c-.29.16-.52,0-.52-.29v-2.51a1.13,1.13,0,0,1,.52-.89l.57-.33C185.2,280,185.43,280.12,185.43,280.45Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M182.85,281.94v2.5a1.13,1.13,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.29v-2.51a1.15,1.15,0,0,1,.51-.89l.58-.33C182.62,281.48,182.85,281.61,182.85,281.94Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M180.27,283.42v2.51a1.15,1.15,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.3v-2.5a1.13,1.13,0,0,1,.51-.89l.58-.33C180,283,180.27,283.1,180.27,283.42Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M177.69,284.91v2.51a1.11,1.11,0,0,1-.51.88l-.58.34c-.28.16-.51,0-.51-.3v-2.51a1.13,1.13,0,0,1,.51-.88l.58-.33C177.46,284.45,177.69,284.59,177.69,284.91Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M175.1,286.4v2.51a1.1,1.1,0,0,1-.51.88l-.57.33c-.28.17-.51,0-.51-.29v-2.51a1.13,1.13,0,0,1,.51-.88l.57-.33C174.88,285.94,175.1,286.08,175.1,286.4Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M172.52,287.89v2.51a1.13,1.13,0,0,1-.51.88l-.57.33c-.29.17-.51,0-.51-.29v-2.51a1.1,1.1,0,0,1,.51-.88l.57-.34C172.29,287.43,172.52,287.56,172.52,287.89Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M169.94,289.38v2.5a1.13,1.13,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.29V290.3a1.15,1.15,0,0,1,.51-.89l.58-.33C169.71,288.92,169.94,289.05,169.94,289.38Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M167.36,290.87v2.5a1.13,1.13,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.3v-2.5a1.15,1.15,0,0,1,.51-.89l.58-.33C167.13,290.41,167.36,290.54,167.36,290.87Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M164.78,292.35v2.51a1.15,1.15,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.3v-2.5a1.13,1.13,0,0,1,.51-.89l.58-.33C164.55,291.9,164.78,292,164.78,292.35Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M162.2,293.84v2.51a1.1,1.1,0,0,1-.52.88l-.57.34c-.28.16-.51,0-.51-.3v-2.51a1.13,1.13,0,0,1,.51-.88l.57-.33C162,293.38,162.2,293.52,162.2,293.84Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M159.61,295.33v2.51a1.13,1.13,0,0,1-.51.88l-.57.33c-.28.17-.51,0-.51-.29v-2.51a1.13,1.13,0,0,1,.51-.88l.57-.33C159.38,294.87,159.61,295,159.61,295.33Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M157,296.82v2.51a1.13,1.13,0,0,1-.51.88l-.57.33c-.29.17-.51,0-.51-.29v-2.51a1.1,1.1,0,0,1,.51-.88l.57-.34C156.8,296.36,157,296.49,157,296.82Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M154.45,298.31v2.5a1.13,1.13,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.29v-2.51a1.15,1.15,0,0,1,.51-.89l.58-.33C154.22,297.85,154.45,298,154.45,298.31Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M151.87,299.8v2.5a1.13,1.13,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.3v-2.5a1.15,1.15,0,0,1,.51-.89l.58-.33C151.64,299.34,151.87,299.47,151.87,299.8Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M149.29,301.28v2.51a1.15,1.15,0,0,1-.51.89l-.58.33c-.28.16-.51,0-.51-.3v-2.5a1.13,1.13,0,0,1,.51-.89l.58-.33C149.06,300.83,149.29,301,149.29,301.28Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M141.15,306.31l-1.06-.62h0a1.41,1.41,0,0,0-1.42.14,4.49,4.49,0,0,0-2,3.51,1.42,1.42,0,0,0,.59,1.3h0l1,.56.18-.3a2,2,0,0,0,.58-.22,4.49,4.49,0,0,0,2-3.5,2.66,2.66,0,0,0-.06-.52Z"
            style={{ fill: '#455a64' }}
          ></path>
          <path
            d="M139.68,306.42a4.51,4.51,0,0,0-2,3.51c0,1.29.91,1.81,2,1.16a4.45,4.45,0,0,0,2-3.5C141.7,306.3,140.8,305.78,139.68,306.42Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M139.68,306.42a4.51,4.51,0,0,0-2,3.51c0,1.29.91,1.81,2,1.16a4.45,4.45,0,0,0,2-3.5C141.7,306.3,140.8,305.78,139.68,306.42Z"
            style={{ fill: '#fff', opacity: 0.8 }}
          ></path>
          <path
            d="M132.38,311.37l-1.07-.61h0a1.44,1.44,0,0,0-1.42.15,4.45,4.45,0,0,0-2,3.5,1.41,1.41,0,0,0,.59,1.31h0l1,.57.18-.3a1.94,1.94,0,0,0,.58-.23,4.45,4.45,0,0,0,2-3.5,2,2,0,0,0-.06-.51Z"
            style={{ fill: '#455a64' }}
          ></path>
          <path
            d="M130.9,311.49a4.47,4.47,0,0,0-2,3.5c0,1.29.91,1.81,2,1.17a4.47,4.47,0,0,0,2-3.5C132.93,311.37,132,310.84,130.9,311.49Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M130.9,311.49a4.47,4.47,0,0,0-2,3.5c0,1.29.91,1.81,2,1.17a4.47,4.47,0,0,0,2-3.5C132.93,311.37,132,310.84,130.9,311.49Z"
            style={{ fill: '#fff', opacity: 0.8 }}
          ></path>
          <path
            d="M73.87,301.3l39.79,23a2.85,2.85,0,0,1,1.29,2.23v11c0,.82-.58,1.15-1.29.74l-39.79-23A2.83,2.83,0,0,1,72.58,313v-11C72.58,301.22,73.15,300.89,73.87,301.3Z"
            style={{ fill: '#455a64' }}
          ></path>
          <path
            d="M75,306.14a2.74,2.74,0,0,0,1.29,2.14l34.95,20.17c.71.41,1.29.11,1.29-.66a2.77,2.77,0,0,0-1.29-2.14L76.29,305.49C75.57,305.08,75,305.37,75,306.14Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M75,311.73a2.74,2.74,0,0,0,1.29,2.14L111.24,334c.71.41,1.29.12,1.29-.65a2.77,2.77,0,0,0-1.29-2.14L76.29,311.08C75.57,310.67,75,311,75,311.73Z"
            style={{ fill: '#263238' }}
          ></path>
          <path
            d="M76.29,311.08,95.6,322.21V325L76.29,313.87A2.74,2.74,0,0,1,75,311.73C75,311,75.57,310.67,76.29,311.08Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M56.74,293.25,65,298a5.14,5.14,0,0,1,2.32,4v4.7c0,1.49-1,2.09-2.32,1.35l-8.21-4.72a5.15,5.15,0,0,1-2.33-4v-4.71C54.41,293.11,55.46,292.51,56.74,293.25Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M59,298.56l1.07-.62h0a1.42,1.42,0,0,1,1.42.15,4.45,4.45,0,0,1,2,3.5,1.43,1.43,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.12,2.12,0,0,1-.58-.23,4.45,4.45,0,0,1-2-3.5,2.12,2.12,0,0,1,.06-.52Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M59,298.56l1.07-.62h0a1.42,1.42,0,0,1,1.42.15,4.45,4.45,0,0,1,2,3.5,1.43,1.43,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.12,2.12,0,0,1-.58-.23,4.45,4.45,0,0,1-2-3.5,2.12,2.12,0,0,1,.06-.52Z"
            style={{ opacity: 0.2 }}
          ></path>
          <path
            d="M60.42,298.68a4.46,4.46,0,0,1,2,3.5c0,1.29-.91,1.81-2,1.17a4.51,4.51,0,0,1-2-3.51C58.4,298.55,59.31,298,60.42,298.68Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M73.87,301.3l39.79,23a2.85,2.85,0,0,1,1.29,2.23v11c0,.82-.58,1.15-1.29.74l-39.79-23A2.83,2.83,0,0,1,72.58,313v-11C72.58,301.22,73.15,300.89,73.87,301.3Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M75,306.14a2.74,2.74,0,0,0,1.29,2.14l34.95,20.17c.71.41,1.29.11,1.29-.66a2.77,2.77,0,0,0-1.29-2.14L76.29,305.49C75.57,305.08,75,305.37,75,306.14Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M75,311.73a2.74,2.74,0,0,0,1.29,2.14L111.24,334c.71.41,1.29.12,1.29-.65a2.77,2.77,0,0,0-1.29-2.14L76.29,311.08C75.57,310.67,75,311,75,311.73Z"
            style={{ fill: '#263238' }}
          ></path>
          <path
            d="M76.29,311.08,95.6,322.21V325L76.29,313.87A2.74,2.74,0,0,1,75,311.73C75,311,75.57,310.67,76.29,311.08Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M73.87,324l39.79,23a2.89,2.89,0,0,1,1.29,2.24v11c0,.82-.58,1.15-1.29.74L73.87,338a2.84,2.84,0,0,1-1.29-2.24v-11C72.58,324,73.15,323.62,73.87,324Z"
            style={{ fill: '#37474f' }}
          ></path>
          <path
            d="M75,328.87A2.74,2.74,0,0,0,76.29,331l34.95,20.16c.71.42,1.29.12,1.29-.65a2.77,2.77,0,0,0-1.29-2.14L76.29,328.22C75.57,327.81,75,328.1,75,328.87Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
          <path
            d="M75,334.46a2.74,2.74,0,0,0,1.29,2.14l34.95,20.16c.71.41,1.29.12,1.29-.65a2.77,2.77,0,0,0-1.29-2.14L76.29,333.81C75.57,333.4,75,333.69,75,334.46Z"
            style={{ fill: '#263238' }}
          ></path>
          <path
            d="M76.29,333.81l24.15,13.92v2.79L76.29,336.6A2.74,2.74,0,0,1,75,334.46C75,333.69,75.57,333.4,76.29,333.81Z"
            style={{ fill: '#ED6A5A' }}
          ></path>
        </g>
        <path
          d="M56.74,338.72,65,343.43a5.15,5.15,0,0,1,2.32,4v4.71c0,1.48-1,2.08-2.32,1.34l-8.21-4.71a5.18,5.18,0,0,1-2.33-4v-4.71C54.41,338.58,55.46,338,56.74,338.72Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M59,344l1.07-.62h0a1.41,1.41,0,0,1,1.42.14,4.46,4.46,0,0,1,2,3.5,1.41,1.41,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.08,2.08,0,0,1-.58-.22,4.48,4.48,0,0,1-2-3.51,2,2,0,0,1,.06-.51Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M59,344l1.07-.62h0a1.41,1.41,0,0,1,1.42.14,4.46,4.46,0,0,1,2,3.5,1.41,1.41,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.08,2.08,0,0,1-.58-.22,4.48,4.48,0,0,1-2-3.51,2,2,0,0,1,.06-.51Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M60.42,344.14a4.46,4.46,0,0,1,2,3.5c0,1.29-.91,1.81-2,1.17a4.47,4.47,0,0,1-2-3.5C58.4,344,59.31,343.5,60.42,344.14Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M56.74,338.72,65,343.43a5.15,5.15,0,0,1,2.32,4v4.71c0,1.48-1,2.08-2.32,1.34l-8.21-4.71a5.18,5.18,0,0,1-2.33-4v-4.71C54.41,338.58,55.46,338,56.74,338.72Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M59,344l1.07-.62h0a1.41,1.41,0,0,1,1.42.14,4.46,4.46,0,0,1,2,3.5,1.41,1.41,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.08,2.08,0,0,1-.58-.22,4.48,4.48,0,0,1-2-3.51,2,2,0,0,1,.06-.51Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M59,344l1.07-.62h0a1.41,1.41,0,0,1,1.42.14,4.46,4.46,0,0,1,2,3.5,1.41,1.41,0,0,1-.59,1.31h0l-1,.57-.18-.3a2.08,2.08,0,0,1-.58-.22,4.48,4.48,0,0,1-2-3.51,2,2,0,0,1,.06-.51Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M60.42,344.14a4.46,4.46,0,0,1,2,3.5c0,1.29-.91,1.81-2,1.17a4.47,4.47,0,0,1-2-3.5C58.4,344,59.31,343.5,60.42,344.14Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M73.87,346.77l39.79,23A2.85,2.85,0,0,1,115,372v11c0,.83-.58,1.16-1.29.75l-39.79-23a2.83,2.83,0,0,1-1.29-2.23v-11C72.58,346.69,73.15,346.35,73.87,346.77Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M75,351.61a2.74,2.74,0,0,0,1.29,2.14l34.95,20.16c.71.41,1.29.12,1.29-.65a2.77,2.77,0,0,0-1.29-2.14L76.29,351C75.57,350.54,75,350.84,75,351.61Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M75,357.19a2.76,2.76,0,0,0,1.29,2.15l34.95,20.16c.71.41,1.29.12,1.29-.65a2.79,2.79,0,0,0-1.29-2.15L76.29,356.54C75.57,356.13,75,356.42,75,357.19Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M76.29,356.54,95.6,367.67v2.8L76.29,359.34A2.76,2.76,0,0,1,75,357.19C75,356.42,75.57,356.13,76.29,356.54Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M177.29,158.41l-53.07,30.64a3.07,3.07,0,0,1-2.74,0L68.41,158.41a.84.84,0,0,1,0-1.59l53.07-29.58a3.07,3.07,0,0,1,2.74,0l53.07,29.58A.84.84,0,0,1,177.29,158.41Z"
          style={{ opacity: 0.1 }}
        ></path>
        <path
          d="M124.16,187.37l50.78-29.32a2.9,2.9,0,0,0,1.32-2.27v-1.71a2.91,2.91,0,0,0-1.32-2.28l-50.78-29.32a2.88,2.88,0,0,0-2.62,0L70.76,151.79a2.91,2.91,0,0,0-1.32,2.28v1.71a2.9,2.9,0,0,0,1.32,2.27l50.78,29.32A2.88,2.88,0,0,0,124.16,187.37Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M124.16,187.37l50.78-29.32a2.9,2.9,0,0,0,1.32-2.27v-1.71a2.91,2.91,0,0,0-1.32-2.28l-50.78-29.32a2.88,2.88,0,0,0-2.62,0L70.76,151.79a2.91,2.91,0,0,0-1.32,2.28v1.71a2.9,2.9,0,0,0,1.32,2.27l50.78,29.32A2.88,2.88,0,0,0,124.16,187.37Z"
          style={{ fill: '#fff', opacity: 0.5 }}
        ></path>
        <path
          d="M124.16,187.37l50.78-29.32a2.9,2.9,0,0,0,1.32-2.27v-1.71a2.91,2.91,0,0,0-1.32-2.28l-50.78-29.32a2.88,2.88,0,0,0-2.62,0L70.76,151.79a2.91,2.91,0,0,0-1.32,2.28v1.71a2.9,2.9,0,0,0,1.32,2.27l50.78,29.32A2.88,2.88,0,0,0,124.16,187.37Z"
          style={{ opacity: 0.05 }}
        ></path>
        <path
          d="M174.94,153.31l-50.78,29.31a2.88,2.88,0,0,1-2.62,0L70.76,153.31a.8.8,0,0,1,0-1.52l50.78-28.3a2.88,2.88,0,0,1,2.62,0l50.78,28.3A.8.8,0,0,1,174.94,153.31Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M174.94,153.31l-50.78,29.31a2.88,2.88,0,0,1-2.62,0L70.76,153.31a.8.8,0,0,1,0-1.52l50.78-28.3a2.88,2.88,0,0,1,2.62,0l50.78,28.3A.8.8,0,0,1,174.94,153.31Z"
          style={{ fill: '#fff', opacity: 0.7000000000000001 }}
        ></path>
        <path
          d="M122.85,182.94v4.74a2.67,2.67,0,0,0,1.31-.31l50.78-29.32a2.91,2.91,0,0,0,1.32-2.28v-1.71a2.92,2.92,0,0,0-1.07-2.1c.47.41.39,1-.25,1.34l-50.78,29.32A2.57,2.57,0,0,1,122.85,182.94Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M122.85,182.94v4.74a2.67,2.67,0,0,0,1.31-.31l50.78-29.32a2.91,2.91,0,0,0,1.32-2.28v-1.71a2.92,2.92,0,0,0-1.07-2.1c.47.41.39,1-.25,1.34l-50.78,29.32A2.57,2.57,0,0,1,122.85,182.94Z"
          style={{ fill: '#fff', opacity: 0.6000000000000001 }}
        ></path>
        <path
          d="M124.16,173.14l34.35-19.83a2.89,2.89,0,0,0,1.31-2.28v-1.71a2.88,2.88,0,0,0-1.31-2.27l-22-12.72a8.71,8.71,0,0,1-2.22-2l-10.52-14.17a1.74,1.74,0,0,0-2.21-.46L87.19,137.56a2.89,2.89,0,0,0-1.31,2.27V151a2.92,2.92,0,0,0,1.31,2.28l34.35,19.83A2.88,2.88,0,0,0,124.16,173.14Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M121.54,168.39l-22-12.71a7.19,7.19,0,0,1-1.15-.85,7.87,7.87,0,0,1-1.07-1.13L86.78,139.53a1.31,1.31,0,0,1,.41-2l34.35-19.83a1.74,1.74,0,0,1,2.21.46l10.52,14.17a8.28,8.28,0,0,0,2,2l22.2,12.64a.79.79,0,0,1,0,1.51l-34.35,19.83A2.88,2.88,0,0,1,121.54,168.39Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M87.19,137.56l34.35-19.83a1.74,1.74,0,0,1,2.21.46l10.52,14.17a8,8,0,0,0,1.08,1.12s-37,21.35-37,21.34a7.84,7.84,0,0,1-1.07-1.12L86.78,139.53A1.31,1.31,0,0,1,87.19,137.56Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M150.18,147.39a3.41,3.41,0,0,0-3.08,0c-.86.5-.86,1.29,0,1.78a3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,150.18,147.39Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M144,151a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,144,151Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M137.85,154.51a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,137.85,154.51Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M131.69,158.07a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,131.69,158.07Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M145.56,144.73a3.37,3.37,0,0,0-3.09,0,.93.93,0,0,0,0,1.77,3.37,3.37,0,0,0,3.09,0A.93.93,0,0,0,145.56,144.73Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M139.39,148.28a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,139.39,148.28Z"
          style={{ fill: '#263238' }}
        ></path>
        <ellipse
          cx="131.69"
          cy="152.73"
          rx="2.18"
          ry="1.26"
          style={{ fill: '#ED6A5A' }}
        ></ellipse>
        <path
          d="M127.07,155.4a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,127.07,155.4Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M140.93,142.06a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,140.93,142.06Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M134.77,145.62a3.35,3.35,0,0,0-3.08,0,.93.93,0,0,0,0,1.77,3.35,3.35,0,0,0,3.08,0A.93.93,0,0,0,134.77,145.62Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M128.61,149.17a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,128.61,149.17Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M125.49,161.55a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,125.49,161.55Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <ellipse
          cx="119.33"
          cy="159.77"
          rx="2.18"
          ry="1.26"
          style={{ fill: '#263238' }}
        ></ellipse>
        <path
          d="M122.45,152.73a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,122.45,152.73Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M136.31,139.39a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,136.31,139.39Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M130.15,143a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,130.15,143Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M124,146.51a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,124,146.51Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M117.83,150.06a3.41,3.41,0,0,0-3.08,0c-.86.5-.86,1.29,0,1.78a3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,117.83,150.06Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M116.25,156.22a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,116.25,156.22Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M111.63,153.55a3.41,3.41,0,0,0-3.08,0,.94.94,0,0,0,0,1.78,3.41,3.41,0,0,0,3.08,0A.94.94,0,0,0,111.63,153.55Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M122.78,168.71v4.73a2.72,2.72,0,0,0,1.38-.3l34.35-19.84a2.86,2.86,0,0,0,1.31-2.27v-1.71a2.89,2.89,0,0,0-1.07-2.1c.48.41.4,1-.24,1.34l-34.35,19.83A2.63,2.63,0,0,1,122.78,168.71Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M129.73,131.76l0,0a1.17,1.17,0,0,0-1.48-.31L98.33,148.67a.89.89,0,0,0-.27,1.32l0,0a1.18,1.18,0,0,0,1.48.31l29.88-17.26A.88.88,0,0,0,129.73,131.76Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M129.73,131.76l0,0a1.17,1.17,0,0,0-1.48-.31L106,144.24l1.24,1.67,22.21-12.83A.88.88,0,0,0,129.73,131.76Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M127.26,128.45l0,0a1.16,1.16,0,0,0-1.48-.31L95.86,145.36a.87.87,0,0,0-.27,1.31l0,0a1.16,1.16,0,0,0,1.48.31L127,129.77A.88.88,0,0,0,127.26,128.45Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M127.26,128.45l0,0a1.16,1.16,0,0,0-1.48-.31l-7.43,4.29,1.24,1.66,7.42-4.28A.88.88,0,0,0,127.26,128.45Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M124.79,125.13l0,0a1.16,1.16,0,0,0-1.48-.31L93.39,142a.89.89,0,0,0-.27,1.32l0,0a1.16,1.16,0,0,0,1.48.3l29.88-17.25A.88.88,0,0,0,124.79,125.13Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M124.79,125.13l0,0a1.16,1.16,0,0,0-1.48-.31l-15.57,9L109,135.4l15.56-9A.88.88,0,0,0,124.79,125.13Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M122.32,121.81l0,0a1.16,1.16,0,0,0-1.48-.3L90.92,138.72a.88.88,0,0,0-.27,1.32l0,0a1.16,1.16,0,0,0,1.48.31L122,123.13A.88.88,0,0,0,122.32,121.81Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M122.32,121.81l0,0a1.16,1.16,0,0,0-1.48-.3L93.68,137.13l1.24,1.66L122,123.13A.88.88,0,0,0,122.32,121.81Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
      </g>
    </g>
    <g id="freepik--Gears--inject-46">
      <g id="freepik--gears--inject-46">
        <path
          d="M132.4,412.71v7.54a.56.56,0,0,0,.51.55l3.93.24v5.54h0a.33.33,0,0,0,.19.28l4.15,2.39a1,1,0,0,0,.52.11h0a.83.83,0,0,0,.39-.08l4.2-1.94a1.58,1.58,0,0,1,1-.06,24.6,24.6,0,0,0,3.26.78.78.78,0,0,1,.61.59l.52,2.76a.69.69,0,0,0,.23.37h0a.62.62,0,0,0,.39.14h6.09a.55.55,0,0,0,.34-.13.19.19,0,0,0,.08-.07.73.73,0,0,0,.09-.08v-.06c0-.06.09-.1.1-.17l.53-2.76a.77.77,0,0,1,.6-.59,24.23,24.23,0,0,0,3.26-.78,1.58,1.58,0,0,1,1,.06l4.2,1.94a.71.71,0,0,0,.26,0v0H169a1,1,0,0,0,.4-.1h0l4.14-2.39a.41.41,0,0,0,.15-.14h0a.35.35,0,0,0,0-.11h0v-2.36h0v-3.18h0l3.86-.25h.07a.93.93,0,0,0,.1-.05.53.53,0,0,0,.26-.13.41.41,0,0,0,.08-.19c0-.06.08-.11.08-.18h0v-3.08h0v-3.95h0v-3.08a.56.56,0,0,0-.52-.55l-3.93-.25v-5.53h0a.34.34,0,0,0-.19-.3l-4.14-2.39a1.14,1.14,0,0,0-.92,0l-4.2,1.94a1.5,1.5,0,0,1-1,.06,24.21,24.21,0,0,0-3.26-.77.79.79,0,0,1-.6-.59L159,399a.65.65,0,0,0-.61-.51h-6.09a.66.66,0,0,0-.62.51l-.52,2.77a.8.8,0,0,1-.61.59,24.58,24.58,0,0,0-3.26.77,1.51,1.51,0,0,1-1-.06l-4.2-1.94a1.11,1.11,0,0,0-.91,0l-4.14,2.39a.35.35,0,0,0-.2.3h0v5.52l-3.93.25a.56.56,0,0,0-.51.55v2.46A.41.41,0,0,0,132.4,412.71Zm15.91,2a15.48,15.48,0,0,1,14,0q.44.25.81.51a8.14,8.14,0,0,1-.81.54,15.46,15.46,0,0,1-14,0,7.58,7.58,0,0,1-.81-.53A8.52,8.52,0,0,1,148.31,414.67Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M132.39,412.71l0,7.54a.55.55,0,0,0,.51.54l3.93.25v5.54a.33.33,0,0,0,.19.28l4.15,2.39a1,1,0,0,0,.52.11h0a.83.83,0,0,0,.39-.08l4.2-1.94a1.46,1.46,0,0,1,.4-.1h0a1.47,1.47,0,0,1,.56,0,24.6,24.6,0,0,0,3.26.78.64.64,0,0,1,.26.1v0a.79.79,0,0,1,.35.46l.52,2.76a.63.63,0,0,0,.23.36h0a.62.62,0,0,0,.39.14h6.2a.69.69,0,0,0,.4-.27v-.06c0-.06.09-.1.1-.17l.53-2.76a.7.7,0,0,1,.24-.4h0a.77.77,0,0,1,.36-.18,24.23,24.23,0,0,0,3.26-.78,1.69,1.69,0,0,1,.64,0v0a1.27,1.27,0,0,1,.32.07l4.2,1.94a.71.71,0,0,0,.26,0v0h0a1.07,1.07,0,0,0,.65-.1h0l4.14-2.39.09-.06.07-.09a.27.27,0,0,0,0-.12h0v-5.54l.72-.05,3.14-.2h.07a.55.55,0,0,0,.52-.54v-7a.55.55,0,0,1-.52.54l-5.52.35a1,1,0,0,0-.74.5,8.28,8.28,0,0,1-1.18,1.63.43.43,0,0,0,.07.67l3.28,2.36a.31.31,0,0,1,0,.56l-4.14,2.39a1.1,1.1,0,0,1-.92,0l-4.2-1.94a1.57,1.57,0,0,0-1-.06,23.87,23.87,0,0,1-3.26.78.79.79,0,0,0-.6.59l-.53,2.76a.65.65,0,0,1-.61.51h-6.09a.66.66,0,0,1-.62-.51l-.52-2.76a.81.81,0,0,0-.61-.59,24.6,24.6,0,0,1-3.26-.78,1.58,1.58,0,0,0-1,.06l-4.2,1.94a1.07,1.07,0,0,1-.91,0L137,419.83a.31.31,0,0,1,0-.56l3.28-2.36a.45.45,0,0,0,.06-.68,7.89,7.89,0,0,1-1.17-1.62,1,1,0,0,0-.75-.5l-5.52-.34a.56.56,0,0,1-.51-.55v-.62A.43.43,0,0,0,132.39,412.71Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M136.85,403.82v5.52l1.59-.1a1,1,0,0,0,.75-.5,8.17,8.17,0,0,1,1.17-1.62.45.45,0,0,0-.06-.68L137,404.08a.37.37,0,0,1-.17-.26Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M147.51,415.19l.35-.25,0,0,.41-.25a15.48,15.48,0,0,1,14,0l.37.22c.15.1.3.19.44.3,3-2.25,2.76-5.49-.81-7.55a15.4,15.4,0,0,0-14,0C144.74,409.7,144.47,413,147.51,415.19Z"
          style={{ opacity: 0.4 }}
        ></path>
        <path
          d="M170.26,407.12a8.23,8.23,0,0,1,1.18,1.62,1,1,0,0,0,.74.5l1.59.1v-5.53a.39.39,0,0,1-.17.27l-3.28,2.36A.44.44,0,0,0,170.26,407.12Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M168.52,401.09l-4.2,1.94a1.5,1.5,0,0,1-1,.06,24.21,24.21,0,0,0-3.26-.77.79.79,0,0,1-.6-.59L159,399a.65.65,0,0,0-.61-.51h-6.09a.66.66,0,0,0-.62.51l-.52,2.77a.8.8,0,0,1-.61.59,24.58,24.58,0,0,0-3.26.77,1.51,1.51,0,0,1-1-.06l-4.2-1.94a1.11,1.11,0,0,0-.91,0l-4.14,2.39a.31.31,0,0,0,0,.56l3.28,2.36a.45.45,0,0,1,.06.68,8.17,8.17,0,0,0-1.17,1.62,1,1,0,0,1-.75.5l-5.52.35a.56.56,0,0,0-.51.55v3.08a.56.56,0,0,0,.51.55l5.52.34a1,1,0,0,1,.75.5,8.22,8.22,0,0,0,1.17,1.63.44.44,0,0,1-.06.67L137,419.27a.31.31,0,0,0,0,.56l4.14,2.39a1.07,1.07,0,0,0,.91,0l4.2-1.94a1.58,1.58,0,0,1,1-.06,24.6,24.6,0,0,0,3.26.78.78.78,0,0,1,.61.59l.52,2.76a.66.66,0,0,0,.62.51h6.09a.65.65,0,0,0,.61-.51l.53-2.76a.77.77,0,0,1,.6-.59,24.23,24.23,0,0,0,3.26-.78,1.57,1.57,0,0,1,1,.06l4.2,1.94a1.1,1.1,0,0,0,.92,0l4.14-2.39a.31.31,0,0,0,0-.56l-3.28-2.36a.42.42,0,0,1-.06-.67,8.22,8.22,0,0,0,1.17-1.63,1,1,0,0,1,.74-.5l5.52-.34a.56.56,0,0,0,.52-.55v-3.08a.56.56,0,0,0-.52-.55l-5.52-.35a1,1,0,0,1-.74-.5,8.17,8.17,0,0,0-1.17-1.62.43.43,0,0,1,.06-.68l3.28-2.36a.31.31,0,0,0,0-.56l-4.14-2.39A1.14,1.14,0,0,0,168.52,401.09Zm-6.21,6.55c3.87,2.23,3.87,5.85,0,8.08a15.46,15.46,0,0,1-14,0c-3.87-2.23-3.87-5.85,0-8.08A15.46,15.46,0,0,1,162.31,407.64Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M173.77,419.55a.35.35,0,0,0-.16-.28L172,418.08v-3.91a.9.9,0,0,1,.22-.06l5.52-.35a.55.55,0,0,0,.52-.54v7a.55.55,0,0,1-.52.54h-.07l-3.14.2-.72.05S173.78,419.65,173.77,419.55Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M173.77,419.55a.35.35,0,0,0-.16-.28L172,418.08v-3.91a.9.9,0,0,1,.22-.06l5.52-.35a.55.55,0,0,0,.52-.54v7a.55.55,0,0,1-.52.54h-.07l-3.14.2-.72.05S173.78,419.65,173.77,419.55Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M164,420.23a1.26,1.26,0,0,1,.31.09l4.2,1.94a1.06,1.06,0,0,0,.54.07v7a.88.88,0,0,1-.28,0h0v0a.71.71,0,0,1-.26,0l-4.2-1.94a1.29,1.29,0,0,0-.31-.07Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M164,420.23a1.26,1.26,0,0,1,.31.09l4.2,1.94a1.06,1.06,0,0,0,.54.07v7a.88.88,0,0,1-.28,0h0v0a.71.71,0,0,1-.26,0l-4.2-1.94a1.29,1.29,0,0,0-.31-.07Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M158.89,424.59a.5.5,0,0,0,.08-.2l.53-2.76a.49.49,0,0,1,.08-.21v7a.54.54,0,0,0-.08.21l-.53,2.76c0,.06-.05.09-.08.14Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M158.89,424.59a.5.5,0,0,0,.08-.2l.53-2.76a.49.49,0,0,1,.08-.21v7a.54.54,0,0,0-.08.21l-.53,2.76c0,.06-.05.09-.08.14Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M151.05,421.42a.66.66,0,0,1,.08.21l.52,2.76a1.19,1.19,0,0,0,.08.2v7a1.19,1.19,0,0,1-.08-.2l-.52-2.76a.64.64,0,0,0-.08-.19Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M141.56,422.33a1.06,1.06,0,0,0,.54-.07l4.2-1.94a1.35,1.35,0,0,1,.32-.09v7a1,1,0,0,0-.32.08l-4.2,1.94a.83.83,0,0,1-.39.08h-.15Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M132.92,413.77l5.52.34a1,1,0,0,1,.23.06v3.91L137,419.27a.36.36,0,0,0-.17.28V421l-3.93-.25a.55.55,0,0,1-.51-.54l0-7.54a.43.43,0,0,1,0-.11v.62A.56.56,0,0,0,132.92,413.77Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M213.17,391.05h0l0-.1h0v-5h0a.4.4,0,0,0-.09-.24l-1.55-2.16a.71.71,0,0,0-.63-.3l-4.06.68a1.3,1.3,0,0,1-.77-.22,9.1,9.1,0,0,0-.8-.51V380h0c0-.13-.12-.25-.32-.3l-4.12-1a.79.79,0,0,0-.66.12l-2,2.07a.88.88,0,0,1-.64.2,21.77,21.77,0,0,0-2.68,0,1.1,1.1,0,0,1-.73-.32L192.36,379a.79.79,0,0,0-.68-.25l-4.28,1a.27.27,0,0,0-.22.28v3.21a9.23,9.23,0,0,0-1,.61,1.12,1.12,0,0,1-.7.12l-3.93-.66a.69.69,0,0,0-.62.18l-1.7,2.37a.15.15,0,0,0,0,.11v0c0,.36,0,6.13,0,6.16a.29.29,0,0,0,0,.1v5a.41.41,0,0,0,.09.26l1.55,2.16a.74.74,0,0,0,.63.3l4-.69a1.26,1.26,0,0,1,.78.23c.25.17.52.34.8.5v3.21c0,.14.12.26.32.31l4.11,1a.77.77,0,0,0,.67-.13l2-2.07a.88.88,0,0,1,.64-.2,24,24,0,0,0,2.68,0,1,1,0,0,1,.72.31l1.77,1.85a.82.82,0,0,0,.68.26l4.28-1a.29.29,0,0,0,.22-.23c0-.07,0-3.26,0-3.26a11.2,11.2,0,0,0,1-.6,1,1,0,0,1,.7-.13l3.93.67a.67.67,0,0,0,.62-.19l1.7-2.37a.17.17,0,0,0,0-.11c0-.11,0-6,0-6.17Zm-22.89.13c.19-.13.39-.26.61-.39a11.8,11.8,0,0,1,10.64,0c.21.13.41.25.59.38a5.89,5.89,0,0,1-.64.42,11.82,11.82,0,0,1-8.16.92,9.18,9.18,0,0,1-2.41-.92C190.69,391.46,190.47,391.32,190.28,391.18Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M213.17,391.05h0l0-.1h0v-5h0a.4.4,0,0,0-.09-.24l-1.55-2.16a.71.71,0,0,0-.63-.3l-4.06.68a1.3,1.3,0,0,1-.77-.22,9.1,9.1,0,0,0-.8-.51V380h0c0-.13-.12-.25-.32-.3l-4.12-1a.79.79,0,0,0-.66.12l-2,2.07a.88.88,0,0,1-.64.2,21.77,21.77,0,0,0-2.68,0,1.1,1.1,0,0,1-.73-.32L192.36,379a.79.79,0,0,0-.68-.25l-4.28,1a.27.27,0,0,0-.22.28v3.21a9.23,9.23,0,0,0-1,.61,1.12,1.12,0,0,1-.7.12l-3.93-.66a.69.69,0,0,0-.62.18l-1.7,2.37a.15.15,0,0,0,0,.11v0c0,.36,0,6.13,0,6.16a.29.29,0,0,0,0,.1v5a.41.41,0,0,0,.09.26l1.55,2.16a.74.74,0,0,0,.63.3l4-.69a1.26,1.26,0,0,1,.78.23c.25.17.52.34.8.5v3.21c0,.14.12.26.32.31l4.11,1a.77.77,0,0,0,.67-.13l2-2.07a.88.88,0,0,1,.64-.2,24,24,0,0,0,2.68,0,1,1,0,0,1,.72.31l1.77,1.85a.82.82,0,0,0,.68.26l4.28-1a.29.29,0,0,0,.22-.23c0-.07,0-3.26,0-3.26a11.2,11.2,0,0,0,1-.6,1,1,0,0,1,.7-.13l3.93.67a.67.67,0,0,0,.62-.19l1.7-2.37a.17.17,0,0,0,0-.11c0-.11,0-6,0-6.17Zm-22.89.13c.19-.13.39-.26.61-.39a11.8,11.8,0,0,1,10.64,0c.21.13.41.25.59.38a5.89,5.89,0,0,1-.64.42,11.82,11.82,0,0,1-8.16.92,9.18,9.18,0,0,1-2.41-.92C190.69,391.46,190.47,391.32,190.28,391.18Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M179.23,386h0v0s0,0,0,0A.09.09,0,0,0,179.23,386Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M188.12,382.74c-.33.15-.64.31-.94.48V380a.28.28,0,0,0,0,.17l1,2A.38.38,0,0,1,188.12,382.74Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M183.41,387.81a4,4,0,0,0,0,1.34.42.42,0,0,1-.27.47l-3.7,1.17a.26.26,0,0,0-.18.24c0,.07,0,1.2,0,1.2l0-.09c0-2.1,0-5.61,0-5.92V386c0,.12.12.23.3.28l3.49,1.11A.49.49,0,0,1,183.41,387.81Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M185.72,393v6.18h-.15l-4,.68a.47.47,0,0,1-.2,0v-6.18a.5.5,0,0,0,.21,0l4.05-.68Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M187.15,400c-.28-.16-.55-.33-.8-.5a1.51,1.51,0,0,0-.63-.23V393a1.42,1.42,0,0,1,.64.23,12.15,12.15,0,0,0,1.64.94c.24.12.37.33.29.49l-1.11,2.2a.3.3,0,0,0,0,.1C187.16,397.12,187.15,400,187.15,400Z"
          style={{ opacity: 0.25 }}
        ></path>
        <path
          d="M194.47,395.94v6.18a.55.55,0,0,0-.25.14l-2,2.07a.54.54,0,0,1-.36.14v-6.18a.55.55,0,0,0,.37-.14l2-2.07A.76.76,0,0,1,194.47,395.94Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M197.8,395.92v6.18a.61.61,0,0,0-.26,0,24,24,0,0,1-2.68,0,1,1,0,0,0-.39.06v-6.18a1.08,1.08,0,0,1,.4-.06,21.78,21.78,0,0,0,2.68,0A.59.59,0,0,1,197.8,395.92Z"
          style={{ opacity: 0.25 }}
        ></path>
        <path
          d="M200.42,398.27v6.18a.86.86,0,0,1-.39-.23l-1.76-1.85a1.06,1.06,0,0,0-.47-.27v-6.18a1.16,1.16,0,0,1,.47.27L200,398A.83.83,0,0,0,200.42,398.27Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M205.21,397.05V400h0v3.26a.29.29,0,0,1-.22.23l-4.28,1a.49.49,0,0,1-.29,0v-6.18a.54.54,0,0,0,.3,0l4.28-1A.25.25,0,0,0,205.21,397.05Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M205.21,397.05V400h0v3.26a.29.29,0,0,1-.22.23l-4.28,1a.49.49,0,0,1-.29,0v-6.18a.54.54,0,0,0,.3,0l4.28-1A.25.25,0,0,0,205.21,397.05Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M206.59,393.05v6.18a.91.91,0,0,0-.42.13,11.2,11.2,0,0,1-1,.6s0-2.83,0-3a.41.41,0,0,0-.05-.17l-1-2a.36.36,0,0,1,.12-.51,11.52,11.52,0,0,0,1.9-1.09A.87.87,0,0,1,206.59,393.05Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M213.17,391.06c0,.21,0,6.06,0,6.17a.17.17,0,0,1,0,.11l-1.7,2.37a.52.52,0,0,1-.29.17v-6.17a.66.66,0,0,0,.3-.17l1.7-2.38A.21.21,0,0,0,213.17,391.06Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M213.17,391.06c0,.21,0,6.06,0,6.17a.17.17,0,0,1,0,.11l-1.7,2.37a.52.52,0,0,1-.29.17v-6.17a.66.66,0,0,0,.3-.17l1.7-2.38A.21.21,0,0,0,213.17,391.06Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M213.17,391.06c0,.21,0,6.06,0,6.17a.17.17,0,0,1,0,.11l-1.7,2.37a.52.52,0,0,1-.29.17v-6.17a.66.66,0,0,0,.3-.17l1.7-2.38A.21.21,0,0,0,213.17,391.06Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M187.18,380v0a.37.37,0,0,0,0,.16l1,2a.37.37,0,0,1-.13.52c-.33.15-.64.31-.94.48a9.23,9.23,0,0,0-1,.61,1.12,1.12,0,0,1-.7.12l-3.93-.66a.69.69,0,0,0-.62.18l-1.7,2.37a.15.15,0,0,0,0,.11v0c0,.11.12.21.3.27l3.49,1.11a.49.49,0,0,1,.39.46,4,4,0,0,0,0,1.34.42.42,0,0,1-.27.47l-3.7,1.17a.25.25,0,0,0-.17.24.49.49,0,0,0,.08.24l1.55,2.16a.76.76,0,0,0,.64.3l4.05-.68a1.2,1.2,0,0,1,.78.22,12.15,12.15,0,0,0,1.64.94c.24.12.37.33.29.49l-1.11,2.2c-.08.16.06.33.3.4l4.11,1a.77.77,0,0,0,.67-.13l2-2.07a1,1,0,0,1,.64-.2,24,24,0,0,0,2.68,0,1,1,0,0,1,.72.31L200,398a.82.82,0,0,0,.68.26l4.28-1a.29.29,0,0,0,.17-.45l-1-2a.35.35,0,0,1,.13-.51,11.84,11.84,0,0,0,1.89-1.09,1,1,0,0,1,.71-.12l3.92.66a.69.69,0,0,0,.63-.19l1.7-2.37a.14.14,0,0,0,0-.1h0l0-.1h0a.47.47,0,0,0-.27-.19l-3.5-1.1c-.24-.08-.41-.29-.38-.47a4.56,4.56,0,0,0,0-1.33.43.43,0,0,1,.27-.48l3.7-1.17a.23.23,0,0,0,.17-.22h0a.4.4,0,0,0-.09-.24l-1.55-2.16a.71.71,0,0,0-.63-.3l-4.06.68a1.3,1.3,0,0,1-.77-.22,9.1,9.1,0,0,0-.8-.51c-.27-.15-.55-.3-.84-.44s-.38-.33-.3-.48l1.12-2.21a.29.29,0,0,0,0-.09c0-.13-.12-.25-.32-.3l-4.12-1a.79.79,0,0,0-.66.12l-2,2.07a.88.88,0,0,1-.64.2,21.77,21.77,0,0,0-2.68,0,1.1,1.1,0,0,1-.73-.32L192.36,379a.79.79,0,0,0-.68-.25l-4.28,1A.27.27,0,0,0,187.18,380Zm15.55,6.35a1.14,1.14,0,0,1,.11.11l.07.07.15.19c.06.07.11.15.16.22l.09.15,0,.06a2.65,2.65,0,0,1-.21,3,4.45,4.45,0,0,1-1,1,5.89,5.89,0,0,1-.64.42,11.82,11.82,0,0,1-8.16.92,9.18,9.18,0,0,1-2.41-.92c-.22-.13-.44-.27-.63-.41a4.26,4.26,0,0,1-1.28-1.43s0,0,0,0a.2.2,0,0,1,0-.08.36.36,0,0,1,0-.09.36.36,0,0,1,0-.09,2.66,2.66,0,0,1,.38-2.63c1.59-2.21,6-3.27,9.82-2.35A7.61,7.61,0,0,1,202.73,386.36Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
      </g>
    </g>
    <g id="freepik--Wrench--inject-46">
      <g id="freepik--wrench--inject-46">
        <path
          d="M236.85,372.18c-.85.49-2.44.47-2.94,1.05v2.08l9.68-1.41,3.66,2.14L245.86,379l-9.78-.65v2.08a6.19,6.19,0,0,0,.66.42,16.48,16.48,0,0,0,14.92.07c2.09-1.19,3.14-2.76,3.14-4.33h0v-2.08l-.58.09-.06-.11,36.63-21.15a16.63,16.63,0,0,0,11-1.38,6.13,6.13,0,0,0,2.94-3.13V346.7l-9.68,3.49-3.66-2.14,1.39-2.91,9.78-1.43v-2.09s-11.43-.76-15.58,1.6a6.89,6.89,0,0,0-2.59,2.39l-.55-.2v2.07h0a4,4,0,0,0,.64,2.17L247.87,370.8A16.6,16.6,0,0,0,236.85,372.18Z"
          style={{ fill: '#37474f' }}
        ></path>
        <polygon
          points="290.79 353.29 290.8 351.2 254.16 372.35 254.16 374.44 290.79 353.29"
          style={{ fill: '#37474f' }}
        ></polygon>
        <path
          d="M284.51,347.57l-36.63,21.15a16.57,16.57,0,0,0-11,1.38,6.1,6.1,0,0,0-2.94,3.13l9.68-1.42,3.66,2.15-1.39,2.91-9.78,1.43c.21.14.42.28.66.42a16.48,16.48,0,0,0,14.92.06c3-1.73,3.87-4.26,2.5-6.43l36.63-21.15a16.57,16.57,0,0,0,11-1.38,6.12,6.12,0,0,0,2.94-3.12l-9.68,1.41L291.42,346l1.39-2.9,9.78-1.44-.66-.42a16.51,16.51,0,0,0-14.92-.06C284,342.87,283.14,345.4,284.51,347.57Z"
          style={{ fill: '#455a64' }}
        ></path>
        <polygon
          points="292.2 346.42 291.42 345.96 292.81 343.06 302.59 341.62 302.59 343.71 292.81 345.14 292.2 346.42"
          style={{ fill: '#263238' }}
        ></polygon>
        <polygon
          points="246.47 375.59 247.25 373.96 243.59 371.81 233.91 373.23 233.91 375.31 243.59 373.9 246.47 375.59"
          style={{ fill: '#263238' }}
        ></polygon>
      </g>
    </g>
    <g id="freepik--Screwdriver--inject-46">
      <g id="freepik--screwdriver--inject-46">
        <path
          d="M259.26,403l7.66,13.26,25.83-14.91c1.06-.53,1.73-1.77,1.73-3.58a12,12,0,0,0-5.42-9.38,3.82,3.82,0,0,0-3.84-.38h0Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M257.72,405.71h0c-.11,2-3,2.45-3,2.45l5.41,9.38s1.81-2.22,3.62-1.34h0c2.67,1.16,4.74-.28,4.74-3.46a12,12,0,0,0-5.42-9.39C260.33,401.75,258.06,402.82,257.72,405.71Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M257.72,405.71h0c-.11,2-3,2.45-3,2.45l5.41,9.38s1.81-2.22,3.62-1.34h0c2.67,1.16,4.74-.28,4.74-3.46a12,12,0,0,0-5.42-9.39C260.33,401.75,258.06,402.82,257.72,405.71Z"
          style={{ opacity: 0.05 }}
        ></path>
        <path
          d="M256,407.76a5.79,5.79,0,0,1-1.29.4l5.41,9.38a6,6,0,0,1,1-.91A12.1,12.1,0,0,0,256,407.76Z"
          style={{ opacity: 0.05 }}
        ></path>
        <path
          d="M249.11,408.82l.45.79a5.69,5.69,0,0,0-.23,1.68,12,12,0,0,0,5.42,9.38,5.36,5.36,0,0,0,1.57.64l.45.78,1.81-1h0a3.81,3.81,0,0,0,1.58-3.51,12,12,0,0,0-5.41-9.38,3.81,3.81,0,0,0-3.83-.38h0Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M258.36,418.59a12,12,0,0,0-5.42-9.39c-3-1.73-5.42-.32-5.42,3.13a12,12,0,0,0,5.42,9.38C255.93,423.44,258.36,422,258.36,418.59Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M258.36,418.59a12,12,0,0,0-5.42-9.39c-3-1.73-5.42-.32-5.42,3.13a12,12,0,0,0,5.42,9.38C255.93,423.44,258.36,422,258.36,418.59Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M250.83,420a10.68,10.68,0,0,0,1.34,1.2l1.27-3.61-1.53-1.13Z"
          style={{ opacity: 0.15 }}
        ></path>
        <path
          d="M290.07,394h0a1.49,1.49,0,0,1-.55,2l-18.45,10.65a1.49,1.49,0,0,1-2-.55h0a1.49,1.49,0,0,1,.55-2L288,393.47A1.49,1.49,0,0,1,290.07,394Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M286.74,390.05h0a.87.87,0,0,1-.07,1.53l-18.79,10.85a2.06,2.06,0,0,1-2.18-.23h0a.88.88,0,0,1,.07-1.54l18.8-10.85A2,2,0,0,1,286.74,390.05Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M291.84,398.89h0a.87.87,0,0,0-1.36-.71L271.68,409a2.06,2.06,0,0,0-.88,2h0a.87.87,0,0,0,1.36.71L291,400.89A2.05,2.05,0,0,0,291.84,398.89Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M205.43,440.47v3.61l9-2.93s1.81-3.32,3.61-4.37L252.39,417l1.05-3-1.05-.61-34.32,19.81a3.61,3.61,0,0,1-3.61-.19Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M206.49,441.08v3.61l9-2.93s1.81-3.32,3.61-4.36l34.31-19.81V414l-34.31,19.81a3.59,3.59,0,0,1-3.61-.19Z"
          style={{ fill: '#37474f' }}
        ></path>
        <polygon
          points="206.49 444.69 205.43 444.08 205.43 440.47 206.49 441.08 206.49 444.69"
          style={{ fill: '#263238' }}
        ></polygon>
        <polygon
          points="205.43 440.47 206.49 441.08 215.52 433.59 214.46 432.98 205.43 440.47"
          style={{ fill: '#455a64' }}
        ></polygon>
      </g>
    </g>
    <g id="freepik--Character--inject-46">
      <g id="freepik--character--inject-46">
        <path
          d="M357.56,210c5.84-6,16.41-19.63,16.41-19.63l6-20.71c.15.06-3.05.07-4,0-6.46.43-8.36,1-12.38,5.38-3.19,3.49-15.36,20.12-19.6,25.71l-11.23-14.31c-2.69-4.07-2.25-8.62-3.65-11.94s-3.65-4.79-5.46-7.8-2.24-5-3.72-3.43.23,5.68.45,6.62,2.48,3.75-1.3,2.29-9.62-8.37-11.13-9.79-2.3.78-2,2.51-1.28,5.63-.69,8.49c.62,3,1.07,4.43,4,7.38,2.72,2.75,9.4,6.11,12,9.72s8.18,14.81,11.59,21.56c4.32,8.58,8,11.41,12.88,8.52S355.39,212.23,357.56,210Z"
          style={{ fill: '#ffa8a7' }}
        ></path>
        <path
          d="M378.05,169.46a21.46,21.46,0,0,0-9.74,1.33c-3.51,1.58-5.89,5-9.31,9.56S347,196,347,196a25.79,25.79,0,0,1,10.42,14.65l20.9-23.1Z"
          style={{ fill: '#e0e0e0' }}
        ></path>
        <path
          d="M344,200.76A13.6,13.6,0,0,1,348,208.3s1.26-4.15-3.11-8.82Z"
          style={{ fill: '#f28f8f' }}
        ></path>
        <path
          d="M398.16,169.39c2.27.55,8.37,1.33,12.39,2.48,3,.85,6,4.53,6.58,7.25,2,8.71.22,14.19-.52,21.82s-.79,45.7-.79,45.7c-4.23,8.26-36.28,9.94-51.79-1.39,0,0,1.9-50.68,1.9-58.57s4.38-17.36,16.48-18.07Z"
          style={{ fill: '#f5f5f5' }}
        ></path>
        <path
          d="M384.46,144.43a1.68,1.68,0,1,0,1.67-1.74A1.71,1.71,0,0,0,384.46,144.43Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M386.13,138.77l3.46,1.91a2.07,2.07,0,0,0-.8-2.75A1.93,1.93,0,0,0,386.13,138.77Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M384.88,154.65,381,156.1a2,2,0,0,0,2.63,1.29A2.17,2.17,0,0,0,384.88,154.65Z"
          style={{ fill: '#f28f8f' }}
        ></path>
        <path
          d="M371.1,140.16l3.15-2.43a1.91,1.91,0,0,0-2.74-.42A2.09,2.09,0,0,0,371.1,140.16Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M371.81,144.43a1.62,1.62,0,1,0,1.62-1.68A1.65,1.65,0,0,0,371.81,144.43Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="380.58 141.64 380.03 151.82 374.71 150.01 380.58 141.64"
          style={{ fill: '#f28f8f' }}
        ></polygon>
        <path
          d="M384.48,142.09l23.81-1.76c4.61,3.49,13.17,10.83,13.16,12.86h0v1.41h0a.48.48,0,0,1-.05.18c-.84,1.6-3.88,2.59-8.61,2.67-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63l1.2-.09Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M384.48,142.09l23.81-1.76c4.61,3.49,13.17,10.83,13.16,12.86h0v1.41h0a.48.48,0,0,1-.05.18c-.84,1.6-3.88,2.59-8.61,2.67-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63l1.2-.09Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M408.29,141.67c4.81,3.65,14,11.5,13.11,13.11s-3.88,2.59-8.61,2.67c-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M408.29,141.67c4.81,3.65,14,11.5,13.11,13.11s-3.88,2.59-8.61,2.67c-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63Z"
          style={{ opacity: 0.4 }}
        ></path>
        <path
          d="M408.29,141.67c.08.69-2.35,2.13-2.35,2.13l.68-4.18Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M408.29,141.67c.08.69-2.35,2.13-2.35,2.13l.68-4.18Z"
          style={{ opacity: 0.30000000000000004 }}
        ></path>
        <path
          d="M402,127.76s3.72.52,5.09,3.32c1.18,2.44.76,8.33-.83,14.75a59.07,59.07,0,0,1-4,12,4.9,4.9,0,0,1-2.84,2.28l.19-7.62.28-5.3s-3.41-4.65-3.78-8.46c-.49-4.91.55-6.91.55-6.91Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M396,135.13a14.22,14.22,0,0,1-14.26,14.17c-7.85,0-13.74-6.47-13.71-14.32s5.95-14.14,13.8-14.11A14.22,14.22,0,0,1,396,135.13Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M399.71,146.39c1.28,1.11,2.32-1.76,3.4-2.88s4.59-2.64,6.32,1.05-1.53,9-4.23,10.07a3.84,3.84,0,0,1-4.58-1.4V170.1c-4,7.29-11.18,7.07-15,6.65s-4.65-4.37-1.86-7.57l0-5.22a30.05,30.05,0,0,1-6.41.31c-3.49-.55-5.32-3.19-6.36-7-1.69-6.08-2.36-16.3,0-28.11,3.91-3,17.48-2.53,25.69,2.68C396.09,142.34,398.44,145.28,399.71,146.39Z"
          style={{ fill: '#ffa8a7' }}
        ></path>
        <path
          d="M372.46,124a16.75,16.75,0,0,1,5.77-4,14.62,14.62,0,0,1,5.55-1.18c2.48,0,5-.37,7.43-.29,4.3.14,9.5,1.89,11.44,6.07.89,1.92,1,3.94-.72,5.26a12.14,12.14,0,0,1-5.24,2c-3.19.55-6.35,1.25-9.56,1.68a28.57,28.57,0,0,1-10.35-.15,13.61,13.61,0,0,1-4.5-1.92,12,12,0,0,1-1.94-1.62c-.23-.23-.84-.71-.91-1s.34-1,.51-1.26A15.87,15.87,0,0,1,372.46,124Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M383.79,164s7.81-1.54,10.54-3a9,9,0,0,0,3.78-3.72,12.37,12.37,0,0,1-2.15,4.39c-2,2.54-12.18,4.39-12.18,4.39Z"
          style={{ fill: '#f28f8f' }}
        ></path>
        <path
          d="M385.9,143.76a1.65,1.65,0,1,0,1.65-1.71A1.68,1.68,0,0,0,385.9,143.76Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M382.14,156.42a1.65,1.65,0,1,0,1.65-1.71A1.67,1.67,0,0,0,382.14,156.42Z"
          style={{ fill: '#f28f8f' }}
        ></path>
        <path
          d="M388.15,137.39l3.17,2.48A2.12,2.12,0,0,0,391,137,2,2,0,0,0,388.15,137.39Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M371.31,139.92l3.06-2.66a2,2,0,0,0-2.82-.27A2.14,2.14,0,0,0,371.31,139.92Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M372.35,143.27a1.65,1.65,0,1,0,1.65-1.71A1.68,1.68,0,0,0,372.35,143.27Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="381.39 140.45 380.77 152.13 375.23 150.67 381.39 140.45"
          style={{ fill: '#f28f8f' }}
        ></polygon>
        <path
          d="M409.31,135.88a20.63,20.63,0,0,1-1,5.91,7.23,7.23,0,0,0-1.46-1.65A35,35,0,0,0,396.13,134h0c-9-3.48-19.5-4-27.89-1.06a18.87,18.87,0,0,1,1.12-5c3.06-8,9.25-12.15,19.13-11.12C400.06,118,409.62,124.42,409.31,135.88Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M388.1,128.83c-2.69-.91-9.55-1.44-11.55-.81a12.35,12.35,0,0,1-2.41-2.66c-.94-1.49-.62-3.36.26-3.75s.94.78,4.35,2.47a5.68,5.68,0,0,1-.28-3.45c.56-1.38,1.73,1.1,4.27,2.71S387.78,125.75,388.1,128.83Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M423.2,162.72c2.24-1.84,15.82,14.25,10.14,18.88s-12.62-7-11.68-12.74S423.2,162.72,423.2,162.72Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M434.12,153.68c-3.4-4.17-12.43-14.19-14.43-16s-3.2-1.57-4.44-2a10.28,10.28,0,0,1-3.71-2.32,22.53,22.53,0,0,1-1.79-2.1A32.4,32.4,0,0,0,403,125c-2.68-1.85-5.62-3.22-8.36-5-1.64-1.05-3.42-2.82-1.56-4.63a4.48,4.48,0,0,1,2-1.07c4.65-1.22,8.81.22,13.45,1.2a39.46,39.46,0,0,1,5.93,1.73,10.13,10.13,0,0,1,2.65,1.38,36.9,36.9,0,0,1,4.35,4.4c3.19,3.91,5.89,5.12,9,7.83,3.67,3.17,20.12,14.8,23.59,18.26,2.88,2.87,2,5.24,1.62,6.06-2.05,4.71-4.49,6.46-7.37,9.44-.95,1-1.91,2-2.87,3-3.74,3.79-7.5,7.36-11.4,11-4.16,3.88-5.86,5.74-9.57,9.48a29.41,29.41,0,0,0-5.6,8.27c-6.71,1.53-11.29-12.67-8.89-20.9a10.64,10.64,0,0,1,3.9-5.13C420.71,164.86,434.12,153.68,434.12,153.68Z"
          style={{ fill: '#ffa8a7' }}
        ></path>
        <path
          d="M434.12,153.68a17.86,17.86,0,0,0,8.51,5.34s-4.27,1.28-10.4-3.77Z"
          style={{ fill: '#f28f8f' }}
        ></path>
        <path
          d="M410.55,171.87c1.53-1.2,5.25-3.76,12.65-9.15,0,0-1,2.56,1.21,8.7s6.42,9.88,8.93,10.18c0,0-4.11,3.66-7.85,7.74s-6.85,7-8.88,11.6c0,0-5.77-1.12-7.62-10.66C407.68,183.58,407.75,175.63,410.55,171.87Z"
          style={{ fill: '#e0e0e0' }}
        ></path>
        <path
          d="M397.62,415.78c-.36.38-.13,3.14.32,3.77s3,2.73,7.64,2.81c4.45.08,8.38-.73,10.9-2.53s3.67-3.68,3.78-6.22-.3-5,.8-7c1-1.85,2.4-3.68,2.74-4.68a12.73,12.73,0,0,0,0-5.43Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M408.42,394.36a11.7,11.7,0,0,1-.39,2.84,25.51,25.51,0,0,1-2.42,5.7,23.18,23.18,0,0,1-1.7,2.71c-1.26,1.67-2.86,3.07-4.19,4.67a9.08,9.08,0,0,0-2.45,5.59c0,3.49,3.84,4.47,6.77,4.83a21.34,21.34,0,0,0,9.42-1,8.69,8.69,0,0,0,6-7.27c.11-1,0-2,.06-2.94a13.73,13.73,0,0,1,2.37-6.23,18.23,18.23,0,0,0,1.9-3.42c.79-2.2-.06-4.59-.76-6.7-.6-1.83-1.08-4-1.72-3.82,0,.38,0,1.19,0,1.19-.2.42-.66.72-.77,1.19a8.22,8.22,0,0,1-.45,1.46,3.77,3.77,0,0,1-1.45,1.79c-.12-1.31-.23-2.62-.35-3.93a1.79,1.79,0,0,0-.36-1.14,1.81,1.81,0,0,0-1.14-.42,24.67,24.67,0,0,0-7.47-.22,1.35,1.35,0,0,0-1,.49,1.44,1.44,0,0,0-.11.9A36,36,0,0,1,408.42,394.36Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M408.42,394.36a11.7,11.7,0,0,1-.39,2.84,25.51,25.51,0,0,1-2.42,5.7,23.18,23.18,0,0,1-1.7,2.71c-1.26,1.67-2.86,3.07-4.19,4.67a9.08,9.08,0,0,0-2.45,5.59c0,3.49,3.84,4.47,6.77,4.83a21.34,21.34,0,0,0,9.42-1,8.69,8.69,0,0,0,6-7.27c.11-1,0-2,.06-2.94a13.73,13.73,0,0,1,2.37-6.23,18.23,18.23,0,0,0,1.9-3.42c.79-2.2-.06-4.59-.76-6.7-.6-1.83-1.08-4-1.72-3.82,0,.38,0,1.19,0,1.19-.2.42-.66.72-.77,1.19a8.22,8.22,0,0,1-.45,1.46,3.77,3.77,0,0,1-1.45,1.79c-.12-1.31-.23-2.62-.35-3.93a1.79,1.79,0,0,0-.36-1.14,1.81,1.81,0,0,0-1.14-.42,24.67,24.67,0,0,0-7.47-.22,1.35,1.35,0,0,0-1,.49,1.44,1.44,0,0,0-.11.9A36,36,0,0,1,408.42,394.36Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M405.18,403.66c1.25-1,4.15-1.21,5.74-1.13a9.74,9.74,0,0,1,4.6,1.34,1,1,0,0,0,1.32-.18h0a1,1,0,0,0-.18-1.47,9.77,9.77,0,0,0-5.17-1.61c-3.92-.06-5,.67-5,.67S404.74,402.52,405.18,403.66Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M402.4,407.38c1.57-1.05,4.77-1.08,6.37-1a9.15,9.15,0,0,1,4.48,1.43,1,1,0,0,0,1.32-.17h0a1,1,0,0,0-.18-1.48,10.2,10.2,0,0,0-5.29-1.75c-3.92-.06-4.9.79-4.9.79A3.24,3.24,0,0,0,402.4,407.38Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M412.84,396.79a9.65,9.65,0,0,0-4.88.68c-.67.42-1.07,1.53-.61,1.79a9,9,0,0,1,4.32-.84,13,13,0,0,1,4.28.94,11.77,11.77,0,0,1,1.11.49.93.93,0,0,0,1.26-.47h0a.87.87,0,0,0-.4-1.1A12.54,12.54,0,0,0,412.84,396.79Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M343.7,401.82a4.22,4.22,0,0,0,.34,3.08c.48.88,5.36,3.3,11.71,2.52a26.56,26.56,0,0,0,12.95-5.32c2.35-1.74,4.86-2.18,8-2.53s5.52-1.45,6.13-3-.32-4-.32-4Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M368.47,381.6a13.6,13.6,0,0,1-1.69-.48,1.42,1.42,0,0,0-.95-.06,1.4,1.4,0,0,0-.62,1.1,16.25,16.25,0,0,1-.55,2.81,6.92,6.92,0,0,1-1.69,2,28.79,28.79,0,0,1-4.93,3.57c-2,1.23-4,2.21-6.12,3.3s-5,1.86-6.68,3.11c-2.41,1.82-2.51,6.08.43,7.6,2.54,1.32,9.1,2.53,15.79.24,3.65-1.24,7.54-5.42,12.38-6.06,3.07-.41,7.17-.89,9-3.34.67-1.08.08-4.09-.47-7.66-.49-3.2-.94-8.2-2-7.87,0,0-.38,1.18-.45,1.31a9.65,9.65,0,0,1-.85,1.14,11.13,11.13,0,0,1-.87.82,12.79,12.79,0,0,1-1.09,1.12,3.11,3.11,0,0,1-1.78.74,1.71,1.71,0,0,1-.89-.19c-.53-.3-.6-1-1-1.42a5.07,5.07,0,0,0-1.72-1.12,9.48,9.48,0,0,0-2.79-.56Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M368.47,381.6a13.6,13.6,0,0,1-1.69-.48,1.42,1.42,0,0,0-.95-.06,1.4,1.4,0,0,0-.62,1.1,16.25,16.25,0,0,1-.55,2.81,6.92,6.92,0,0,1-1.69,2,28.79,28.79,0,0,1-4.93,3.57c-2,1.23-4,2.21-6.12,3.3s-5,1.86-6.68,3.11c-2.41,1.82-2.51,6.08.43,7.6,2.54,1.32,9.1,2.53,15.79.24,3.65-1.24,7.54-5.42,12.38-6.06,3.07-.41,7.17-.89,9-3.34.67-1.08.08-4.09-.47-7.66-.49-3.2-.94-8.2-2-7.87,0,0-.38,1.18-.45,1.31a9.65,9.65,0,0,1-.85,1.14,11.13,11.13,0,0,1-.87.82,12.79,12.79,0,0,1-1.09,1.12,3.11,3.11,0,0,1-1.78.74,1.71,1.71,0,0,1-.89-.19c-.53-.3-.6-1-1-1.42a5.07,5.07,0,0,0-1.72-1.12,9.48,9.48,0,0,0-2.79-.56Z"
          style={{ opacity: 0.2 }}
        ></path>
        <path
          d="M360.83,388.68A2.87,2.87,0,0,1,363,387c1.39-.34,3.93.56,5.54,1.75a1.15,1.15,0,0,1-.06,1.91h0a1.14,1.14,0,0,1-1.32-.07A8.79,8.79,0,0,0,360.83,388.68Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M356.61,391.35a3.3,3.3,0,0,1,2.63-1.54s3.67-.09,6.06,1.9a1.13,1.13,0,0,1-.06,1.85h0a1.08,1.08,0,0,1-1.27-.08A8.9,8.9,0,0,0,356.61,391.35Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M351.92,393.81a3.69,3.69,0,0,1,2.74-1.41,9.86,9.86,0,0,1,5.81,1.94,1.13,1.13,0,0,1-.06,1.85h0a1.12,1.12,0,0,1-1.27-.08A8.42,8.42,0,0,0,351.92,393.81Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M362.17,243.55c-.17,14.61.77,63.89,1.09,71.24.28,6.44,1.4,69.82,1.4,69.82,7.38,2.29,17.48-.08,17.48-.08S387.28,345,387.61,338a119.81,119.81,0,0,0-1-19.28l3.78-40.33s4.13,30.2,6.12,43.42c2.28,15.15,10.81,73.17,10.81,73.17,6.79,2.64,16.3-1.11,16.3-1.11s-.49-45.14-1.17-60.48c-.48-10.59-3.43-15.74-3.75-18.68,0,0-1.47-55-1.63-68.94-.1-9.34-1.11-19.6-1.11-19.6s-10.2.82-11.1-2.15,0-31.52.38-37.07c0,0-21.44-4.77-40-1.25C365.25,185.66,362.35,228.94,362.17,243.55Z"
          style={{ fill: '#455a64' }}
        ></path>
        <path
          d="M405.31,232c-.11,9.71,1.6,15.74,4.18,17.84s7.66,1.94,7.66,1.94v-1.45s-4.8.71-6.95-1.54S406.09,243.3,405.31,232Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M372.31,196s11.13,0,21.66.72c0,0,.07,8.13-.84,11.09s-7.92,7.39-10.83,7.38c-2.52,0-9.53-5-10.08-7.62S372.31,196,372.31,196Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M390.37,278.35l-1-6.24c-3-.45-10.73-5.25-15.29-9.45a36.22,36.22,0,0,0,12.79,11.78l.32,39.8Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M410.55,171.87c-2-.51-3.13-.09-4.14,2.44s-2.17,10.75-2.56,18.44c0,0-4.63.84-7.84-.52,0,0,.39-10.1,1.47-15.31a18.61,18.61,0,0,1,1.5-4.44,4.64,4.64,0,0,1,5-2.53l7.32,1.34Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M401.79,189.35a1.82,1.82,0,1,1-1.82-1.82A1.82,1.82,0,0,1,401.79,189.35Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
        <path
          d="M383.76,169.18a8.43,8.43,0,0,0-7.44,5.13c-1.51,3.84-1.52,12.36-1.61,16.59a10.1,10.1,0,0,1-7.19,0s0-13.41,2.29-17.85,6.7-4.71,9-4.88a41.71,41.71,0,0,1,4.93,0Z"
          style={{ fill: '#37474f' }}
        ></path>
        <path
          d="M373.13,187.93a1.82,1.82,0,1,1-1.82-1.82A1.82,1.82,0,0,1,373.13,187.93Z"
          style={{ fill: '#ED6A5A' }}
        ></path>
      </g>
    </g>
  </svg>
);
