import { useContext, useState } from 'react';
import useStyles from './Front.styles';
import { UseStylesProps } from '../../../../../utils/useStyles';
import { postcardContext } from '../../../../../Context/Communicate/Postcard/PostcardContext';
import { DropzoneState } from 'react-dropzone';
import Image from '../../../../../components/Image/Image';
import clsx from 'clsx';
import { Crop } from 'react-image-crop';
import PostcardImageCrop from './Front/PostcardImageCrop';
import {
  UploadFileToS3FnType,
  useUploadFileToS3,
} from '../../../../../utils/S3Upload';
import { StateSetter } from '../../../../../types/utils/React';
import { alertContext } from '../../../../../components/Alert/AlertContext';

export const POSTCARD_IMAGE_SIZE = {
  HEIGHT: 400,
  WIDTH: 712,
};

type FrontPureProps = UseStylesProps & {
  crop: Crop;
  dropzoneState: DropzoneState;
  imagePreview: string;
  showCrop: boolean;
  setCrop: StateSetter<Crop>;
  setShowCrop: StateSetter<boolean>;
  uploadFileToS3: UploadFileToS3FnType;
};

const FrontPure = ({
  classes,
  crop,
  dropzoneState,
  imagePreview,
  showCrop,
  setCrop,
  setShowCrop,
  uploadFileToS3,
}: FrontPureProps): JSX.Element => {
  const { getRootProps, getInputProps, isDragActive } = dropzoneState;

  return (
    <div className={classes.container}>
      {!showCrop && (
        <div
          className={classes.dragZone}
          data-test-id="dropzone"
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <Image
            className={clsx(classes.image, isDragActive && classes.fadedImage)}
            src={imagePreview}
          />
        </div>
      )}

      <PostcardImageCrop
        crop={crop}
        showCrop={showCrop}
        setCrop={setCrop}
        setShowCrop={setShowCrop}
        uploadFileToS3={uploadFileToS3}
      />
    </div>
  );
};

type FrontPropsType = {
  dropzoneState: DropzoneState;
};

const Front = ({ dropzoneState }: FrontPropsType): JSX.Element | null => {
  const classes = useStyles();
  const { imagePreview, showCrop, setShowCrop } = useContext(postcardContext);
  const { showErrorMessage } = useContext(alertContext);
  const { uploadFileToS3 } = useUploadFileToS3({
    handleProgress: (_percent: number) => {},
    showErrorMessage,
  });
  const [crop, setCrop] = useState<Crop>({
    aspect: 1.78,
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
  });

  return (
    <FrontPure
      classes={classes}
      crop={crop}
      dropzoneState={dropzoneState}
      imagePreview={imagePreview}
      showCrop={showCrop}
      setCrop={setCrop}
      setShowCrop={setShowCrop}
      uploadFileToS3={uploadFileToS3}
    />
  );
};

export default Front;
