import { useCallback } from 'react';
import history from '../../utils/history';
import { Button } from '@mui/material';
import useStyles from './HistoryBackButton.styles';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';
import { FormattedMessage } from 'react-intl';

export type HandleHistoryBackType = () => void;

type HistoryBackButtonPureProps = {
  handleHistoryBack: HandleHistoryBackType;
};

const HistoryBackButtonPure = ({
  handleHistoryBack,
}: HistoryBackButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      data-test-id="history-back-button"
      variant="outlined"
      onClick={handleHistoryBack}
    >
      <FormattedMessage id="button.back" />
    </Button>
  );
};

export type HistoryBackButtonProps = {
  onClick?: HandleHistoryBackType;
};

const HistoryBackButton = ({
  onClick,
}: HistoryBackButtonProps): JSX.Element => {
  const handleHistoryBack = useCallback(() => {
    trackEvent(GAEventCategory.BUTTON, GACommonEventAction.CLICK, 'Retour');
    history.goBack();
  }, []);

  return (
    <HistoryBackButtonPure handleHistoryBack={onClick || handleHistoryBack} />
  );
};

export default HistoryBackButton;
