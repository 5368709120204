import { useCallback, useContext } from 'react';
import { imageViewerContext } from './ImageViewerContext';
import { Button } from '@mui/material';
import NextImage from '../../images/components/ImageViewer/next.svg';
import Image from '../Image/Image';
import useStyles from './NextImageButton.styles';
import useIsSmallScreen from '../../utils/useIsSmallScreen';

type NextImageButtonPureProps = {
  handleNextImageClick: () => void;
};

const NextImageButtonPure = ({
  handleNextImageClick,
}: NextImageButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      data-test-id="next-image-button"
      onClick={handleNextImageClick}
    >
      <Image src={NextImage} width={80} />
    </Button>
  );
};

const NextImageButton = (): JSX.Element | null => {
  const { currentViewedImage, imageDocuments, handleCurrentViewedImage } =
    useContext(imageViewerContext);

  const isSmallScreen = useIsSmallScreen();

  const currentViewedImageIndex = imageDocuments.findIndex(
    (image) => image.id === currentViewedImage?.id,
  );
  const nextImage = imageDocuments[currentViewedImageIndex + 1];

  const handleNextImageClick = useCallback(
    () => handleCurrentViewedImage(nextImage),
    [nextImage, handleCurrentViewedImage],
  );

  if (
    isSmallScreen ||
    currentViewedImageIndex === -1 ||
    currentViewedImageIndex === imageDocuments.length - 1
  ) {
    return null;
  }

  return <NextImageButtonPure handleNextImageClick={handleNextImageClick} />;
};

export default NextImageButton;
