import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    borderRadius: 14,
    fontSize: 16,
    float: 'right',
    padding: '10px 20px',
    textTransform: 'none',
    width: 250,
  },
}));
