import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  checkbox: {
    color: COLORS.BLUE,
    padding: 5,

    '&.Mui-checked': {
      color: COLORS.BLUE,
    },
  },
  container: {
    paddingLeft: 5,
  },
  label: {
    '& .MuiFormControlLabel-label': {
      color: COLORS.BLUE,
      fontSize: 14,
    },
  },
}));
