export const helpMessages = {
  /* HELP */
  'category.help.description': 'Accompagner votre proche',
  'category.help.label': 'Accompagner',
  'category.help.teamviewer': 'TeamViewer',
  'category.help.documents.getting_started': 'Guides de démarrage',
  'category.help.documents.getting_started.tablet_guide':
    'Guide de démarrage de la tablette',
  'category.help.documents.getting_started.dock_guide':
    'Guide de démarrage de la station',

  /* HELP DOCUMENTS */
  'category.help.documents.description': "Tous les supports d'aides",
  'category.help.documents.subdescription':
    "Retrouvez ici divers documents utiles pour accompagner l'utilisateur de la tablette",
  'category.help.documents.tablet_tutorials': 'Tutoriels ardoiz',
  'category.help.documents.tablet-tutorials.description':
    'Tutoriels page par page',
  'category.help.documents.user_guide': "Guide d'utilisation de la tablette",

  /* HELP DOCUMENTS TUTORIALS */
  'category.help.documents.tablet-tutorials.gmail':
    'Paramétrer un compte Gmail',
  'category.help.documents.tablet-tutorials.instagram': 'Découvrir Instagram',
  'category.help.documents.tablet-tutorials.internet': 'Internet',
  'category.help.documents.tablet-tutorials.play': 'Jouer',
  'category.help.documents.tablet-tutorials.mail': "L'e-mail",
  'category.help.documents.tablet-tutorials.guided_interface':
    "L'affichage guidé",
  'category.help.documents.tablet-tutorials.touch_screen': "L'écran tactile",
  'category.help.documents.tablet-tutorials.messenger': 'Installer Messenger',
  'category.help.documents.tablet-tutorials.playstore':
    'Accéder au catalogue <br></br>« Google Play »',
  'category.help.documents.tablet-tutorials.skype': 'Se connecter sur Skype',
  'category.help.documents.tablet-tutorials.contacts':
    "Votre carnet d'adresses",
  'category.help.documents.tablet-tutorials.whatsapp': 'Installer WhatsApp',
  'category.help.documents.tablet-tutorials.zoom': 'Installer ZOOM',

  'category.help.documents.family_portal_tutorials':
    'Tutoriels du site famille',

  'category.help.documents.family-portal-tutorials.discover':
    'Découvrir le site famille',
  'category.help.documents.family-portal-tutorials.visio':
    'Utiliser Ardoiz Visio',

  /* TEAMVIEWER */
  'teamviewer.button': 'Demander le partage',
  'teamviewer.description': 'Accompagnement à distance avec TeamViewer',
  'teamviewer.p1':
    'Pour intervenir à distance sur la tablette de votre proche, vous devez télécharger et installer le logiciel Teamviewer Quick Support sur votre ordinateur.',
  'teamviewer.p2': 'Téléchargez-le directement à partir de ',
  'teamviewer.p3':
    "Une fois l'installation faite, lancer la demande d'autorisation sur la tablette de votre proche.",
  'teamviewer.p4':
    "L'écran ci-contre apparait sur la tablette de votre proche, il doit alors appuyer sur le bouton ",
  'teamviewer.p4.launchApp': "LANCER L'APPLICATION",
  'teamviewer.p5':
    'Des instructions vont apparaitre sur la tablette (un numéro ID).',
  'teamviewer.p6':
    'Votre proche devra alors vous communiquer ce numéro par téléphone.',
  'teamviewer.p7':
    "Saisissez ce numéro dans l'application Teamviewer et commencez l'accompagnement de votre proche. Son écran de tablette apparaitra alors dans Teamviewer.",
  'teamviewer.p8':
    'Si vous rencontrez des complications lors de la mise en route de Teamviewer, référez-vous au guide détaillé ci-joint : ',
  'teamviewer.p8.link': 'Guide détaillé',
  'teamviewer.errorMessage': "Erreur lors de la demande d'accès",
  'teamviewer.successMessage': "Demande d'accès Teamviewer envoyée",

  /* PARTNER */
  'partner.description':
    'Pour suivre le quotidien de votre proche, découvrez les informations',
  'partner.publications.description':
    'Retrouvez ci-après les informations pour accompagner votre proche.',
  'partner.publications.bookmark.title': 'Les liens internet',
  'partner.publications.content.title': 'Les actualités',
  'partner.publications.content.readArticle': "Lire l'article",
  'partner.publications.content.view.title':
    'Les actualités : {partnerAccountName}',
  'partner.publications.content.view.newArticles': 'Articles récents',
  'partner.publications.document.title': 'Les documents',
};
