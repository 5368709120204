import { ReactNode, useContext } from 'react';
import { useUserContext } from '../../Context/UserContext';
import { LinkType } from '../../types/Link';
import SimpleSelect, {
  SimpleSelectOptionType,
} from '../../components/Select/SimpleSelect';
import { ADMIN_LINK_CHANNEL_KEY } from '../../utils/Link';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import ChannelLinkLabel from './ChannelLinkLabel';
import { UseStylesProps } from '../../utils/useStyles';
import useStyles from './ChannelLinkSelect.styles';
import TabletImage from '../../images/ChannelLink/tablet.png';
import Image from '../../components/Image/Image';
import { FormattedMessage } from 'react-intl';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';
import { tourContext } from '../../Context/TourContext';

type ChannelLinkSelectPureProps = UseStylesProps & {
  options: SimpleSelectOptionType<LinkType>[];
  value: string;
  handleChange: Function;
};

const ChannelLinkSelectPure = ({
  classes,
  options,
  value,
  handleChange,
}: ChannelLinkSelectPureProps): JSX.Element => (
  <>
    <Image
      alt="Tablette Ardoiz"
      className={classes.tabletImage}
      src={TabletImage}
    />
    <SimpleSelect
      className={classes.select}
      options={options}
      value={value}
      handleChange={handleChange}
    />
  </>
);

const getLinkLabel = (link: LinkType): ReactNode => {
  const baseLabel = (
    <FormattedMessage
      id="channelLink.tabletOwner"
      values={{
        firstName: link?.mainUser?.firstName,
        lastName: link?.mainUser?.lastName,
      }}
    />
  );

  if (!link.metadata.subscriptionEnabled) {
    return (
      <>
        {baseLabel} (<FormattedMessage id="channelLink.subscriptionDisabled" />)
      </>
    );
  }

  return baseLabel;
};

const ChannelLinkSelect = (): JSX.Element | null => {
  const { webUser } = useUserContext();
  const { selectedLink, handleSelectedLinkChange } = useSelectedLinkContext();
  const { isTourOpen } = useContext(tourContext);
  const classes = useStyles();

  const webUserLinks = webUser.links.filter(
    (link) => link.channelKey !== ADMIN_LINK_CHANNEL_KEY,
  );

  if (isTourOpen) {
    return null;
  }

  if (webUserLinks.length === 0 || !selectedLink) {
    return (
      <ChannelLinkLabel
        label={
          <span className={classes.noWebUserLink}>
            <FormattedMessage id="channelLink.noWebUserLink" />
          </span>
        }
      />
    );
  }

  if (webUserLinks.length === 1) {
    return <ChannelLinkLabel label={getLinkLabel(selectedLink)} />;
  }

  const options = webUserLinks.map((link) => ({
    disabled: !link.metadata.subscriptionEnabled,
    fullValue: link,
    label: getLinkLabel(link),
    value: link.channelKey,
  }));

  const handleChange = (val: LinkType): void => {
    trackEvent(
      GAEventCategory.CHANNEL_LINK,
      GACommonEventAction.SELECT,
      'Sélectionner une tablette',
    );
    handleSelectedLinkChange({ newSelectedLink: val });
  };

  return (
    <ChannelLinkSelectPure
      classes={classes}
      options={options}
      value={selectedLink.channelKey}
      handleChange={handleChange}
    />
  );
};

export default ChannelLinkSelect;
