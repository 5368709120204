import { FunctionComponent, ReactNode } from 'react';
import { Link } from '@mui/material';
import useStyles from './HrefLink.styles';

export type HrefLinkProps = {
  children: ReactNode;
  href: string;
};

const HrefLink: FunctionComponent<HrefLinkProps> = ({
  children,
  href,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Link className={classes.link} href={href} target="_blank">
      {children}
    </Link>
  );
};

export default HrefLink;
