import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    borderRadius: 12,
    cursor: 'pointer',
    padding: 25,

    '&:hover': {
      backgroundColor: COLORS.LIGHT_BLUE,
    },
  },
  icon: {
    maxWidth: 60,
  },
  iconContainer: {
    textAlign: 'right',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  description: {
    fontSize: 16,
    fontWeight: 200,
    height: 50,
    paddingTop: 10,
  },
}));
