import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  cell: {
    cursor: 'pointer',
    height: 70,
    padding: 0,
    position: 'relative',
    verticalAlign: 'top',

    [theme.breakpoints.down('md')]: {
      height: 25,
    },
  },
  cellNotSelected: {
    border: `1px solid ${COLORS.TRANSPARENT_BLUE}`,
    height: 70,
    padding: 10,

    '&:hover': {
      border: `1px solid ${COLORS.BLUE}`,
    },

    [theme.breakpoints.down('md')]: {
      height: 25,
    },
  },
  cellSelected: {
    border: `2px solid ${COLORS.RED}`,
    height: 69,
    padding: '9px 10px 10px 9px',

    [theme.breakpoints.down('md')]: {
      height: 24,
    },
  },
  label: {
    color: COLORS.BLUE,
    fontSize: 20,
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  labelOutsideRange: {
    color: '#CDCDCD',
    fontSize: 20,
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  labelToday: {
    color: COLORS.GREEN,
    fontSize: 24,
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
}));
