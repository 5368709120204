import ScrollbarTrackBackgroundImage from '../images/Scrollbar/scrollbar-track-background.png';
import { COLORS } from '../theme/MainTheme';

export const scrollContainer = {
  height: '100%',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    width: 7,
  },
  '&::-webkit-scrollbar-track': {
    backgroundImage: `url(${ScrollbarTrackBackgroundImage})`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: COLORS.VIOLET,
    borderRadius: 11,
    width: 6,
  },
};
