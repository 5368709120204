import Play from './AudioPlayerPlay';
import Pause from './AudioPlayerPause';
import Bar from './AudioPlayerBar';
import useAudioPlayer from './useAudioPlayer';
import casual from '../../mocks/casual';
import clsx from 'clsx';
import useStyles from './AudioPlayer.styles';
import Download from '@mui/icons-material/Download';
import useControlButtonStyles from './AudioPlayerControlButton.styles';

export type AudioPlayerProps = {
  className?: string;
  url: string;
  handleDownload: () => void;
};

const AudioPlayer = ({ className, url, handleDownload }: AudioPlayerProps) => {
  const elementId = `audio-player-${casual.uuid}`;
  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer(elementId);
  const classes = useStyles();
  const controlButtonClasses = useControlButtonStyles();

  return (
    <div className={clsx([className, classes.player])}>
      <audio id={elementId}>
        <source src={url} />
        Your browser does not support the <code>audio</code> element.
      </audio>

      <div className={classes.controls}>
        <Bar
          curTime={curTime}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />

        {playing ? (
          <Pause handleClick={() => setPlaying(false)} />
        ) : (
          <Play handleClick={() => setPlaying(true)} />
        )}

        <button
          className={controlButtonClasses.button}
          onClick={handleDownload}
        >
          <Download />
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;
