import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  bookmark: {
    paddingTop: 10,
    wordBreak: 'break-all',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    boxShadow: '0px 3px 5px 1px rgb(0 0 0 / 10%)',
    cursor: 'pointer',
    padding: 15,
  },
  title: {
    fontWeight: 'bold',
  },
}));
