import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from './Description.styles';
import { deleteModeContext } from './DeleteModeContext';
import { shareContext } from '../../../ShareContext';
import { FileTypeSwitcherFileTypes } from '../../../../../../types/share/FileTypeSwitcherFileTypes';

type DescriptionPureProps = {
  description: string;
  description2: string;
};

const DescriptionPure = ({
  description,
  description2,
}: DescriptionPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.description}>
        <FormattedMessage id={description} />
      </div>

      <div>
        <FormattedMessage id={description2} />
      </div>
    </>
  );
};

type GetDescriptionMessagesIdOutputType = {
  description: string;
  description2: string;
};

const getDescriptionMessagesId = (
  selectedFileType: FileTypeSwitcherFileTypes,
): GetDescriptionMessagesIdOutputType => {
  switch (selectedFileType) {
    case FileTypeSwitcherFileTypes.PICTURE_AND_VIDEO:
      return {
        description: 'share.document.delete.description.photo',
        description2: 'share.document.delete.description2.photo',
      };
    case FileTypeSwitcherFileTypes.MUSIC:
      return {
        description: 'share.document.delete.description.music',
        description2: 'share.document.delete.description2.music',
      };
    default:
      return {
        description: 'share.document.delete.description.document',
        description2: 'share.document.delete.description2.document',
      };
  }
};

const Description = (): JSX.Element | null => {
  const { isDeleteMode } = useContext(deleteModeContext);
  const { selectedFileType } = useContext(shareContext);
  const { description, description2 } =
    getDescriptionMessagesId(selectedFileType);

  if (!isDeleteMode) {
    return null;
  }

  return (
    <DescriptionPure description={description} description2={description2} />
  );
};

export default Description;
