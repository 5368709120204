import { LinkType } from './Link';
import { EmailType } from './utils/String';
import { VideoCallType } from './videocall/VideoCall';

export type PostalAddressType = {
  address: string;
  city: string;
  zipcode: string;
};

export type UserType = {
  id: string;
  key: string;
  emails: EmailType[];
  firstName: string;
  lastName: string;
  postalAddress: PostalAddressType;
};

export type WebUserType = {
  id: string;
  firstName: string;
  lastName: string;
  links: LinkType[];
  onBoardingCompleted: boolean;
};

export type WebUserContactType = {
  id: string;
  firstName: string;
  lastName: string;
};

export const webUserIsCalled = (
  webUser: WebUserType,
  videoCall?: VideoCallType,
): boolean => webUser.id === videoCall?.called.id;

export const webUserIsCaller = (
  webUser: WebUserType,
  videoCall?: VideoCallType,
): boolean => webUser.id === videoCall?.caller.id;

export const getPersonFullName = (
  person: UserType | WebUserType | WebUserContactType,
): string => `${person.firstName} ${person.lastName}`;
