import { gql } from '@apollo/client';
import {
  MessageFullFieldsType,
  MessageHtmlContentType,
  MessageTextContentType,
  MessageType,
} from '../../../types/Message';
import { BLOB_FRAGMENT } from '../../s3/S3Mutations';

export const MESSAGE_FRAGMENT = gql`
  fragment messageFields on Message {
    id
    attachments {
      ...blobFields
    }
    date
    destAddress
    hasAttachment
    key
    read
    senderAddress
    subject
  }
  ${BLOB_FRAGMENT}
`;

export const GET_RECEIVED_MESSAGES_QUERY = gql`
  query GetReceivedMessages(
    $getReceivedMessagesInput: GetReceivedMessagesInput!
  ) {
    getReceivedMessages(getReceivedMessagesInput: $getReceivedMessagesInput) {
      __typename
      ... on GetReceivedMessagesErrorOutput {
        errorReason
      }
      ... on GetReceivedMessagesOutput {
        messages {
          ...messageFields
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export enum GetReceivedMessagesError {
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetReceivedMessagesOutputType = {
  getReceivedMessages: {
    __typename: string;
    errorReason: GetReceivedMessagesError;
    messages: MessageType[];
  };
};

export const GET_FILTERED_MESSAGES_QUERY = gql`
  query GetFilteredMessages(
    $getFilteredMessagesInput: GetFilteredMessagesInput!
  ) {
    getFilteredMessages(getFilteredMessagesInput: $getFilteredMessagesInput) {
      __typename
      ... on GetFilteredMessagesErrorOutput {
        errorReason
      }
      ... on GetFilteredMessagesOutput {
        messages {
          ...messageFields
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export enum GetFilteredMessagesError {
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetFilteredMessagesOutputType = {
  getFilteredMessages: {
    __typename: string;
    errorReason: GetFilteredMessagesError;
    messages: MessageType[];
  };
};

export const GET_SENT_MESSAGES_QUERY = gql`
  query GetSentMessages($getSentMessagesInput: GetSentMessagesInput!) {
    getSentMessages(getSentMessagesInput: $getSentMessagesInput) {
      __typename
      ... on GetSentMessagesErrorOutput {
        errorReason
      }
      ... on GetSentMessagesOutput {
        messages {
          ...messageFields
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export enum GetSentMessagesError {
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetSentMessagesOutputType = {
  getSentMessages: {
    __typename: string;
    errorReason: GetSentMessagesError;
    messages: MessageType[];
  };
};

export const GET_MESSAGE_HTML_CONTENT_QUERY = gql`
  query GetMessageHtmlContent($getMessageInput: GetMessageInput!) {
    getMessage(getMessageInput: $getMessageInput) {
      __typename
      ... on GetMessageErrorOutput {
        errorReason
      }
      ... on GetMessageOutput {
        message {
          id
          generateHTML
        }
      }
    }
  }
`;

export enum GetMessageError {
  DOES_NOT_BELONG_TO_TABLET = 'DOES_NOT_BELONG_TO_TABLET',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetMessageHtmlContentOutputType = {
  getMessage: {
    __typename: string;
    errorReason: GetMessageError;
    message: MessageHtmlContentType;
  };
};

export const GET_MESSAGE_TEXT_CONTENT_QUERY = gql`
  query GetMessageTextContent($getMessageInput: GetMessageInput!) {
    getMessage(getMessageInput: $getMessageInput) {
      __typename
      ... on GetMessageErrorOutput {
        errorReason
      }
      ... on GetMessageOutput {
        message {
          id
          generateText
        }
      }
    }
  }
`;

export type GetMessageTextContentOutputType = {
  getMessage: {
    __typename: string;
    errorReason: GetMessageError;
    message: MessageTextContentType;
  };
};

export const GET_MESSAGE_QUERY = gql`
  query GetMessage($getMessageInput: GetMessageInput!) {
    getMessage(getMessageInput: $getMessageInput) {
      __typename
      ... on GetMessageErrorOutput {
        errorReason
      }
      ... on GetMessageOutput {
        message {
          ...messageFields
          generateHTML
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export type GetMessageOutputType = {
  getMessage: {
    __typename: string;
    errorReason: GetMessageError;
    message: MessageFullFieldsType;
  };
};
