import { gql } from '@apollo/client';

export const BLOB_FRAGMENT = gql`
  fragment blobFields on Blob {
    id
    contentType
    filename
    key
    s3URL
  }
`;

export const GET_UPLOAD_TEMPORARY_ACCESS_MUTATION = gql`
  mutation getUploadTemporaryAccess(
    $contentType: String!
    $checksum: String!
    $contentLength: Long!
  ) {
    getUploadTemporaryAccess(
      contentType: $contentType
      checksum: $checksum
      contentLength: $contentLength
    ) {
      downloadURL
      key
      url
    }
  }
`;
