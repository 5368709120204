import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 20,

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      cursor: 'pointer',

      '& svg': {
        color: COLORS.LIGHT_BLUE,
      },
    },

    '& svg': {
      color: COLORS.WHITE,
      fontSize: '4em',
      height: 20,
      width: 20,
    },
  },
}));
