import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from './EventQueries';
import { EventType } from '../../types/event/Event';

/********************************* CREATION *********************************/
export enum CreateEventError {
  AFTER_MUST_HAVE_POS_RECURRENCE = 'AFTER_MUST_HAVE_POS_RECURRENCE',
  CREATION_DATE_SHOULD_REPEAT = 'CREATION_DATE_SHOULD_REPEAT',
  DAYNUMBER_OUT_OF_BOUND = 'DAYNUMBER_OUT_OF_BOUND',
  DURATION_MUST_BE_POS = 'DURATION_MUST_BE_POS',
  NAME_MUST_BE_NONEMPTY = 'NAME_MUST_BE_NONEMPTY',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  UNTIL_DATE_MUST_BE_POSTERIOR = 'UNTIL_DATE_MUST_BE_POSTERIOR',
  WEEKNUMBER_OUT_OF_BOUND = 'WEEKNUMBER_OUT_OF_BOUND',
}

export type CreateEventOutputOrError = {
  errorReason: CreateEventError;
  event: EventType;
};

/** Recurrence type NONE **/
export const CREATE_BASIC_EVENT_MUTATION = gql`
  mutation CreateBasicEvent($basicEventInput: BasicEventInput!) {
    createBasicEvent(basicEventInput: $basicEventInput) {
      __typename

      ... on CreateEventErrorOutput {
        errorReason
      }

      ... on CreateEventOutput {
        event {
          ...eventFields
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

/** Recurrence type AFTER **/
export const CREATE_AFTER_WEEKLY_MUTATION = gql`
  mutation CreateAfterWeeklyRecurrentEvent(
    $afterWeeklyRecurrentEventInput: AfterWeeklyRecurrentEventInput!
  ) {
    createAfterWeeklyRecurrentEvent(
      afterWeeklyRecurrentEventInput: $afterWeeklyRecurrentEventInput
    ) {
      __typename

      ... on CreateEventErrorOutput {
        errorReason
      }

      ... on CreateEventOutput {
        event {
          ...eventFields
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

/** Recurrence type ALWAYS **/
export const CREATE_ALWAYS_WEEKLY_MUTATION = gql`
  mutation CreateAlwaysWeeklyRecurrentEvent(
    $alwaysWeeklyRecurrentEventInput: AlwaysWeeklyRecurrentEventInput!
  ) {
    createAlwaysWeeklyRecurrentEvent(
      alwaysWeeklyRecurrentEventInput: $alwaysWeeklyRecurrentEventInput
    ) {
      __typename

      ... on CreateEventErrorOutput {
        errorReason
      }

      ... on CreateEventOutput {
        event {
          ...eventFields
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

/** Recurrence type UNTIL **/
export const CREATE_UNTIL_WEEKLY_MUTATION = gql`
  mutation CreateUntilWeeklyRecurrentEvent(
    $untilWeeklyRecurrentEventInput: UntilWeeklyRecurrentEventInput!
  ) {
    createUntilWeeklyRecurrentEvent(
      untilWeeklyRecurrentEventInput: $untilWeeklyRecurrentEventInput
    ) {
      __typename

      ... on CreateEventErrorOutput {
        errorReason
      }

      ... on CreateEventOutput {
        event {
          ...eventFields
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

/********************************* DELETION *********************************/
export enum DeleteAllEventOccurrencesError {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const DELETE_ALL_EVENT_OCCURRENCES_MUTATION = gql`
  mutation DeleteAllEventOccurrences(
    $deleteAllEventOccurrencesInput: DeleteAllEventOccurrencesInput!
  ) {
    deleteAllEventOccurrences(
      deleteAllEventOccurrencesInput: $deleteAllEventOccurrencesInput
    ) {
      __typename

      ... on DeleteAllEventOccurrencesErrorOutput {
        errorReason
      }

      ... on DeleteAllEventOccurrencesOutput {
        deleted
      }
    }
  }
`;

export type DeleteAllEventOccurrencesOutputOrError = {
  deleted: boolean;
  errorReason: DeleteAllEventOccurrencesError;
};

export enum DeleteEventOccurrenceError {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const DELETE_EVENT_OCCURRENCE_MUTATION = gql`
  mutation DeleteEventOccurrence(
    $deleteEventOccurrenceInput: DeleteEventOccurrenceInput!
  ) {
    deleteEventOccurrence(
      deleteEventOccurrenceInput: $deleteEventOccurrenceInput
    ) {
      __typename

      ... on DeleteEventOccurrenceErrorOutput {
        errorReason
      }

      ... on DeleteEventOccurrenceOutput {
        updatedEvent {
          ...eventFields
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

export type DeleteEventOccurrenceOutputOrError = {
  errorReason: DeleteEventOccurrenceError;
  updatedEvent: EventType;
};
