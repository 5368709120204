import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    margin: '50px 0',
    padding: '0 10px',
  },
  gridContainer: {
    marginTop: 20,
    overflow: 'auto',
  },
  gridItem: {
    padding: '20px 0',
  },
  itemBackground: {
    backgroundColor: 'rgba(118, 104, 233, 0.05)',
    borderRadius: 16,
    padding: 30,
  },
}));
