import { ReactNode, useContext } from 'react';
import { shareContext } from '../../ShareContext';
import { Badge } from '@mui/material';
import useStyles from './ContactsCountBadge.styles';

type ContactsCountBadgeProps = {
  children: ReactNode;
};

const ContactsCountBadge = ({
  children,
}: ContactsCountBadgeProps): JSX.Element => {
  const classes = useStyles();
  const { selectedFolder } = useContext(shareContext);
  const contactsCount = (selectedFolder?.sharedWith.length || 1) - 1; // Subtract folder admin

  if (contactsCount > 0) {
    return (
      <Badge badgeContent={contactsCount} className={classes.badge}>
        {children}
      </Badge>
    );
  }

  return <>{children}</>;
};

export default ContactsCountBadge;
