import { Grid } from '@mui/material';
import Image from '../components/Image/Image';
import ArdoizIcon from '../images/ardoiz_icon.svg';
import { FormattedMessage } from 'react-intl';
import useStyles from './ArdoizLogo.styles';
import ArdoizLogoImage from '../images/ardoiz_logo.svg';

type ArdoizLogoProps = {
  goToHomeClick: Function;
};

const ArdoizLogo = ({ goToHomeClick }: ArdoizLogoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      onClick={() => goToHomeClick()}
    >
      <Grid item>
        <Image alt="Icône Ardoiz" src={ArdoizIcon} width={50} />
      </Grid>

      <Grid item className={classes.titlesContainer}>
        <Image alt="Ardoiz" src={ArdoizLogoImage} width={80} />
        <br />
        <span className={classes.subtitle}>
          <FormattedMessage id="app.name" />
        </span>
      </Grid>
    </Grid>
  );
};
export default ArdoizLogo;
