import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import useStyles from './SwitchButton.styles';
import clsx from 'clsx';
import { StateSetter } from '../../../types/utils/React';

type SelectedButtonPureProps = {
  className: string;
  disabled: boolean;
  labelId: string;
  onClick: () => void;
};

const SelectedButtonPure = ({
  className,
  disabled,
  labelId,
  onClick,
}: SelectedButtonPureProps): JSX.Element => (
  <Button
    className={className}
    disabled={disabled}
    variant="contained"
    onClick={onClick}
  >
    <FormattedMessage id={labelId} />
  </Button>
);

type NotSelectedButtonPureProps = {
  className: string;
  disabled: boolean;
  labelId: string;
  onClick: () => void;
};

const NotSelectedButtonPure = ({
  className,
  disabled,
  labelId,
  onClick,
}: NotSelectedButtonPureProps): JSX.Element => (
  <Button
    className={className}
    disabled={disabled}
    variant="outlined"
    onClick={onClick}
  >
    <FormattedMessage id={labelId} />
  </Button>
);

const getLabelId = (value: boolean) => (value ? 'yes' : 'no');

type SwitchButtonProps = {
  currentValue: boolean;
  disabled: boolean;
  value: boolean;
  setValue: StateSetter<boolean>;
};

const SwitchButton = ({
  currentValue,
  disabled,
  value,
  setValue,
}: SwitchButtonProps): JSX.Element => {
  const classes = useStyles();

  const onClick = useCallback(() => setValue(value), [value, setValue]);

  const labelId = getLabelId(value);

  if (currentValue === value) {
    const className = clsx([
      classes.selectedButton,
      !value && classes.rightButton,
    ]);

    return (
      <SelectedButtonPure
        className={className}
        disabled={disabled}
        labelId={labelId}
        onClick={onClick}
      />
    );
  } else {
    const className = clsx([
      classes.notSelectedButton,
      !value && classes.rightButton,
    ]);

    return (
      <NotSelectedButtonPure
        className={className}
        disabled={disabled}
        labelId={labelId}
        onClick={onClick}
      />
    );
  }
};

export default SwitchButton;
