import { Grid } from '@mui/material';
import SwitchButton from './SwitchButtons/SwitchButton';
import { StateSetter } from '../../types/utils/React';

export type SwitchButtonsProps = {
  currentValue: boolean;
  disabled: boolean;
  setValue: StateSetter<boolean>;
};

const SwitchButtons = ({
  currentValue,
  disabled,
  setValue,
}: SwitchButtonsProps): JSX.Element => (
  <Grid item xs={12}>
    <SwitchButton
      currentValue={currentValue}
      disabled={disabled}
      value={true}
      setValue={setValue}
    />
    <SwitchButton
      currentValue={currentValue}
      disabled={disabled}
      value={false}
      setValue={setValue}
    />
  </Grid>
);

export default SwitchButtons;
