import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    float: 'right',
    marginRight: 25,
    padding: 0,

    '&:hover': {
      background: 'none',
    },
  },
}));
