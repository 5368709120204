import { createContext, useState } from 'react';
import { emptyFunction } from '../utils/Functions';
import { StateSetter } from '../types/utils/React';

interface TourContextProps {
  currentStep: number;
  isTourOpen: boolean;
  setCurrentStep: StateSetter<number>;
  setIsTourOpen: StateSetter<boolean>;
}

const initialState = {
  currentStep: 0,
  isTourOpen: true,
  setCurrentStep: emptyFunction,
  setIsTourOpen: emptyFunction,
};

const tourContext = createContext<TourContextProps>(initialState),
  { Provider } = tourContext;

interface TourContextProviderProps {
  children: React.ReactNode;
  initialIsTourOpen: boolean;
}

const TourContextProvider = ({
  children,
  initialIsTourOpen,
}: TourContextProviderProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(initialState.currentStep),
    [isTourOpen, setIsTourOpen] = useState(initialIsTourOpen);

  return (
    <Provider
      value={{ currentStep, isTourOpen, setCurrentStep, setIsTourOpen }}
    >
      {children}
    </Provider>
  );
};

export { tourContext, TourContextProvider };
