import { communicateMessages } from './fr/communicate';
import { onboardingMessages } from './fr/onboarding';
import { helpMessages } from './fr/help';
import { profileMessages } from './fr/profile';
import { relationshipMessages } from './fr/relationship';
import { shareMessages } from './fr/share';
import { organizeMessages } from './fr/organize';
import { calendarMessages } from './fr/calendar';
import { adminMessages } from './fr/admin';

export const fr = {
  locale: 'fr',
  messages: {
    /* APP */
    'app.name': 'Site famille',

    /* BUTTONS */
    'button.accept': 'Accepter',
    'button.adminMode': 'Mode Administrateur',
    'button.back': 'Retour',
    'button.backToHome': "Retour vers l'accueil",
    'button.cancel': 'Annuler',
    'button.close': 'Fermer',
    'button.decline': 'Décliner',
    'button.delete': 'Supprimer',
    'button.disconnect': 'Déconnexion',
    'button.discover': 'Découvrir',
    'button.end': 'Terminé',
    'button.exitAdminMode': 'Quitter le Mode Administrateur',
    'button.next': 'Suivant',
    'button.previous': 'Précédent',
    'button.reloadPage': 'Recharger la page',
    'button.resume': 'Continuer',
    'button.send': 'Envoyer',
    'button.sending': 'Envoi en cours...',
    'button.userAccount': 'Mon compte',
    'button.validate': 'Valider',

    at: 'à',

    /* FORM */
    'form.placeholder.writeTextHere': 'Saisissez votre texte ici',

    /* ORGANIZE */
    'category.organize.label': 'Organiser',

    /* Channel Link */
    'channelLink.noWebUserLink': 'Aucune tablette associée',
    'channelLink.noWebUserLinkToast':
      "Votre compte n'est actuellement associé à aucune tablette.<br></br>Vous pouvez suivre ou effectuer vos demandes d'association<br></br>dans la rubrique « <a>Mon compte</a> ».",
    'channelLink.tabletOwner': 'Tablette de {firstName} {lastName}',
    'channelLink.adminManageTablet': 'Gestion de la tablette de ',
    'channelLink.subscriptionDisabled': 'Abonnement expiré',

    /* ERRORS */
    'error.uploadError': "Erreur lors de l'envoi du fichier au serveur",
    'error.userNotFound': 'Utilisateur non trouvé',

    /* HOME */
    'home.welcome': 'Hello {firstName} {lastName}',
    'home.whatToDo': "Que souhaitez-vous faire aujourd'hui ?",

    /* LOADING */
    loading: 'Chargement en cours',

    /* BUILDING PAGE */
    'building_page.title':
      'Page en cours de construction par les équipes Ardoiz',
    'building_page.subtitle1': 'Nouvel inscrit sur le site famille ardoiz ?',
    'building_page.text1':
      "Cette fonctionnalité sera disponible très prochainement. Dès qu'elle sera prête, nous vous tiendrons informé(e). Merci de votre patience !",
    'building_page.subtitle2': 'Déjà utilisateur du site famille ?',
    'building_page.text2': `Si vous avez besoin de cette fonctionnalité, elle reste disponible dans notre ancienne version du site famille : <a>cliquez-ici pour y accéder</a> !`,

    /* Error page */
    'error_page.title': 'Oups, une erreur est survenue',
    'offline_page.title': 'Vous êtes actuellement hors connexion',

    /* Common translations */
    after: 'après',
    alarm: 'alarme',
    comment: 'commentaire',
    end: 'fin',
    never: 'jamais',
    no: 'Non',
    occurrence: 'occurrence',
    title: 'titre',
    until: "jusqu'au",
    yes: 'oui',

    /* Date and Time pickers */
    'select.date': 'Sélectionner une date',
    'select.hour': 'Sélectionner une heure',

    ...adminMessages,
    ...calendarMessages,
    ...communicateMessages,
    ...helpMessages,
    ...onboardingMessages,
    ...organizeMessages,
    ...profileMessages,
    ...relationshipMessages,
    ...shareMessages,
  },
};
