import { useCallback, useContext, useState } from 'react';
import { calendarContext } from '../CalendarContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import { TextField } from '@mui/material';
import useStyles from './CurrentMonth.styles';
import {
  DateTimePickerType,
  getDateTimeBaseProps,
} from '../../../utils/DateAndTimePicker';
import { StateSetter } from '../../../types/utils/React';

type CurrentMonthPureProps = {
  currentDate: Moment;
  isOpen: boolean;
  handleCurrentMonthChange: (newDate: Moment | null) => void;
  setIsOpen: StateSetter<boolean>;
};

const CurrentMonthPure = ({
  currentDate,
  isOpen,
  handleCurrentMonthChange,
  setIsOpen,
}: CurrentMonthPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...getDateTimeBaseProps(DateTimePickerType.Date)}
        open={isOpen}
        views={['month', 'year']}
        value={currentDate}
        onChange={handleCurrentMonthChange}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        renderInput={(params) => {
          const InputProps = {
            ...params.InputProps,
            disableUnderline: true,
          };
          const inputProps = {
            ...params.inputProps,
            readOnly: true,
          };

          return (
            <TextField
              {...params}
              className={classes.textField}
              InputProps={InputProps}
              inputProps={inputProps}
              variant="standard"
              onClick={() => setIsOpen(true)}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

const CurrentMonth = (): JSX.Element => {
  const { currentDate, handleSelectedDateChange, setCurrentDate } =
    useContext(calendarContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCurrentMonthChange = useCallback(
    (newDate: Moment | null) => {
      if (newDate) {
        handleSelectedDateChange(newDate);
        setCurrentDate(newDate);
      }
    },
    [handleSelectedDateChange, setCurrentDate],
  );

  return (
    <CurrentMonthPure
      currentDate={currentDate}
      isOpen={isOpen}
      handleCurrentMonthChange={handleCurrentMonthChange}
      setIsOpen={setIsOpen}
    />
  );
};

export default CurrentMonth;
