import { ReactNode } from 'react';
import useStyles from './PartnerListContainer.styles';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

type PartnerListContainerProps = {
  children: ReactNode;
  isRightContainer?: boolean;
  titleMessageId: string;
};

const PartnerListContainer = ({
  children,
  isRightContainer,
  titleMessageId,
}: PartnerListContainerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={clsx([
        classes.container,
        isRightContainer && classes.rightContainer,
      ])}
    >
      <div className={classes.scrollContainer}>
        <div className={classes.title}>
          <FormattedMessage id={titleMessageId} />
        </div>

        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default PartnerListContainer;
