import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 25,
    padding: 20,
    width: 'auto',

    '&.small': {
      padding: 10,
    },
  },
  divContainer: {
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 21,
  },
  item: {
    textAlign: 'center',
    width: 100,
  },
}));
