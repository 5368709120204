import { Grid } from '@mui/material';
import PreviousMonth from './MonthSelector/PreviousMonth';
import CurrentMonth from './MonthSelector/CurrentMonth';
import NextMonth from './MonthSelector/NextMonth';
import useStyles from './MonthSelector.styles';

const MonthSelector = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.previousMonth} item xs={4}>
        <PreviousMonth />
      </Grid>
      <Grid item xs={4}>
        <CurrentMonth />
      </Grid>
      <Grid className={classes.nextMonth} item xs={4}>
        <NextMonth />
      </Grid>
    </Grid>
  );
};

export default MonthSelector;
