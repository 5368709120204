import { useCallback, useEffect } from 'react';
import FilledButton from '../../../../components/Button/FilledButton';
import { FormattedMessage } from 'react-intl';
import VideoCamera from '../../../../images/Communicate/VideoCall/video-camera.png';
import Image from '../../../../components/Image/Image';
import useStyles from './CallButton.styles';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { isSelfLink } from '../../../../utils/Link';
import clsx from 'clsx';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';

type CallButtonPureProps = {
  hidden: boolean;
  onClick: Function;
};

export const CallButtonPure = ({
  hidden,
  onClick,
}: CallButtonPureProps): JSX.Element | null => {
  const classes = useStyles();

  return (
    <div
      className={clsx([classes.container, hidden && classes.hidden])}
      data-test-id="call-button-container"
    >
      <FilledButton
        className={classes.button}
        color="violet"
        data-test-id="call-button"
        onClick={onClick}
      >
        <Image className={classes.image} src={VideoCamera} />
        <FormattedMessage id="videoCall.button.call" />
      </FilledButton>
    </div>
  );
};

export type CallButtonProps = {
  show: boolean;
  onClick: Function;
};

const CallButton = ({ show, onClick }: CallButtonProps): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const selectedLinkIsSelfLink = isSelfLink(selectedLink);

  useEffect(() => {
    if (selectedLinkIsSelfLink) {
      onClick();
    }
  });

  const handleButtonClick = useCallback(() => {
    onClick();
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.CLICK,
      'Appeler',
    );
  }, [onClick]);

  if (selectedLinkIsSelfLink) {
    return null;
  }

  return <CallButtonPure hidden={!show} onClick={handleButtonClick} />;
};

export default CallButton;
