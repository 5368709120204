import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    fontSize: 18,
    textTransform: 'none',
    width: 350,
  },
  buttonContainer: {
    paddingTop: 40,
    textAlign: 'center',
  },
  modal: {
    borderRadius: 24,
    height: 350,
    left: '50%',
    padding: 20,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
  },
}));
