export enum AlarmEnum {
  BEFORE_15_MIN = 'BEFORE_15_MIN',
  BEFORE_30_MIN = 'BEFORE_30_MIN',
  BEFORE_60_MIN = 'BEFORE_60_MIN',
  NO_ALARM = 'NO_ALARM',
  ON_TIME = 'ON_TIME',
}

export enum DayContentModeEnum {
  ADD,
  EDIT,
  VIEW,
}

export type DaysOfRepeatType = [
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
];

export type HandleDaysOfRepeatChangeIndexType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type HandleDaysOfRepeatChangeType = (
  index: HandleDaysOfRepeatChangeIndexType,
  value: boolean,
) => void;
