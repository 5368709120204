import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
  },
  errorContainer: {
    textAlign: 'center',
  },
  title: {
    fontSize: '3em',
    fontWeight: 'bold',
    padding: 50,
  },
}));
