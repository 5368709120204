import { useContext } from 'react';
import { postcardContext } from '../../../../../Context/Communicate/Postcard/PostcardContext';
import Image from '../../../../../components/Image/Image';
import PhotoImage from '../../../../../images/icons/photo.png';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';

type LoadPhotoButtonPureProps = {
  className: string;
  onLoadPhotoClick: Function;
};

const LoadPhotoButtonPure = ({
  className,
  onLoadPhotoClick,
}: LoadPhotoButtonPureProps): JSX.Element => (
  <FilledButton
    className={className}
    color="green"
    data-test-id="load-image-button"
    onClick={(): void => onLoadPhotoClick()}
  >
    <Image src={PhotoImage} width={20} />
    &nbsp; <FormattedMessage id="postcard.button.loadPhoto" />
  </FilledButton>
);

type LoadPhotoButtonProps = {
  className: string;
  onLoadPhotoClick: Function;
};

const LoadPhotoButton = ({
  className,
  onLoadPhotoClick,
}: LoadPhotoButtonProps): JSX.Element | null => {
  const { sending } = useContext(postcardContext);

  if (sending) {
    return null;
  }

  return (
    <LoadPhotoButtonPure
      className={className}
      onLoadPhotoClick={onLoadPhotoClick}
    />
  );
};

export default LoadPhotoButton;
