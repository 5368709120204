import { ReactNode } from 'react';

type AdminMessagesTablePanelProps = {
  children: ReactNode;
  currentValue: number;
  value: number;
};

const AdminMessagesTablePanel = ({
  children,
  currentValue,
  value,
}: AdminMessagesTablePanelProps): JSX.Element | null => {
  if (currentValue === value) {
    return <>{children}</>;
  }

  return null;
};

export default AdminMessagesTablePanel;
