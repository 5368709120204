import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { adminMessagesContext } from '../AdminMessagesContext';
import useStyles from './AdminMessagesDeleteButton.styles';
import { ApolloCache, useMutation } from '@apollo/client';
import {
  DELETE_MESSAGES_MUTATION,
  DeleteMessagesOutputType,
} from '../../../../../graphql/admin/adminmessage/AdminMessageMutations';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { trackEvent } from '../../../../../GoogleAnalytics/GATracker';
import {
  GAAdminMessagesEventActions,
  GAEventCategory,
} from '../../../../../GoogleAnalytics/GAEvent';
import { alertContext } from '../../../../../components/Alert/AlertContext';
import {
  GET_FILTERED_MESSAGES_QUERY,
  GET_RECEIVED_MESSAGES_QUERY,
  GET_SENT_MESSAGES_QUERY,
  GetFilteredMessagesOutputType,
  GetReceivedMessagesOutputType,
  GetSentMessagesOutputType,
} from '../../../../../graphql/admin/adminmessage/AdminMessageQueries';
import { MessageType } from '../../../../../types/Message';
import clsx from 'clsx';
import { getGoToSelectedChannelPageFunction } from '../../../../../utils/history';
import Route from '../../../../../routes/Route';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import AdminMessagesMobileDeleteButton from './AdminMessagesMobileDeleteButton';

const filterDeletedMessages = (
  currentMessages: MessageType[],
  deletedMessages: string[],
) => currentMessages.filter((_) => !deletedMessages.includes(_.key));

const updateFilteredData = (
  cache: ApolloCache<any>,
  channel: string | undefined,
  deletedMessages: string[],
) => {
  const variables = {
    getFilteredMessagesInput: {
      channel,
    },
  };

  const getMessagesData = cache.readQuery<GetFilteredMessagesOutputType>({
    query: GET_FILTERED_MESSAGES_QUERY,
    variables,
  });

  const currentMessages = getMessagesData?.getFilteredMessages?.messages || [];
  const messages = filterDeletedMessages(currentMessages, deletedMessages);

  const updatedData = {
    ...getMessagesData,
    getFilteredMessages: {
      messages,
    },
  };

  cache.writeQuery({
    data: updatedData,
    query: GET_FILTERED_MESSAGES_QUERY,
    variables,
  });
};

const updateReceivedData = (
  cache: ApolloCache<any>,
  channel: string | undefined,
  deletedMessages: string[],
) => {
  const variables = {
    getReceivedMessagesInput: {
      channel,
    },
  };

  const getMessagesData = cache.readQuery<GetReceivedMessagesOutputType>({
    query: GET_RECEIVED_MESSAGES_QUERY,
    variables,
  });

  const currentMessages = getMessagesData?.getReceivedMessages?.messages || [];
  const messages = filterDeletedMessages(currentMessages, deletedMessages);

  const updatedData = {
    ...getMessagesData,
    getReceivedMessages: {
      messages,
    },
  };

  cache.writeQuery({
    data: updatedData,
    query: GET_RECEIVED_MESSAGES_QUERY,
    variables,
  });
};

const updateSentData = (
  cache: ApolloCache<any>,
  channel: string | undefined,
  deletedMessages: string[],
) => {
  const variables = {
    getSentMessagesInput: {
      channel,
    },
  };

  const getMessagesData = cache.readQuery<GetSentMessagesOutputType>({
    query: GET_SENT_MESSAGES_QUERY,
    variables,
  });

  const currentMessages = getMessagesData?.getSentMessages?.messages || [];
  const messages = filterDeletedMessages(currentMessages, deletedMessages);

  const updatedData = {
    ...getMessagesData,
    getSentMessages: {
      messages,
    },
  };

  cache.writeQuery({
    data: updatedData,
    query: GET_SENT_MESSAGES_QUERY,
    variables,
  });
};

type AdminMessagesDeleteButtonPureProps = {
  disabled: boolean;
  isUniqueDeletion: boolean;
  handleDeleteMessages: () => void;
};

const AdminMessagesDeleteButtonPure = ({
  disabled,
  isUniqueDeletion,
  handleDeleteMessages,
}: AdminMessagesDeleteButtonPureProps): JSX.Element => {
  const classes = useStyles();
  const buttonShouldBeRed = isUniqueDeletion || !disabled;

  return (
    <Button
      className={clsx([
        classes.deleteButton,
        buttonShouldBeRed && classes.redButton,
      ])}
      disabled={disabled}
      onClick={handleDeleteMessages}
    >
      <FormattedMessage id="button.delete" />
    </Button>
  );
};

const getDeletionResultMessages = (isUniqueDeletion: boolean) => {
  if (isUniqueDeletion) {
    return {
      error: 'admin.messages.delete.unique.error',
      partialSuccess: 'admin.messages.delete.multiple.partialSuccess',
      success: 'admin.messages.delete.unique.success',
    };
  } else {
    return {
      error: 'admin.messages.delete.multiple.error',
      partialSuccess: 'admin.messages.delete.multiple.partialSuccess',
      success: 'admin.messages.delete.multiple.success',
    };
  }
};

type AdminMessagesDeleteButtonProps = {
  isUniqueDeletion?: boolean;
};

const AdminMessagesDeleteButton = ({
  isUniqueDeletion,
}: AdminMessagesDeleteButtonProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { showErrorMessage, showSuccessMessage, showWarningMessage } =
    useContext(alertContext);
  const { selectedMessages, updateSelectedMessages, resetSelectedMessages } =
    useContext(adminMessagesContext);
  const isSmallScreen = useIsSmallScreen();

  const channel = selectedLink?.channelKey;

  const [deleteMessages, { loading }] = useMutation<DeleteMessagesOutputType>(
    DELETE_MESSAGES_MUTATION,
    {
      update(cache, { data }) {
        const deletedMessages = data.deleteMessages.successes;

        if (deletedMessages.length > 0) {
          updateFilteredData(cache, channel, deletedMessages);
          updateReceivedData(cache, channel, deletedMessages);
          updateSentData(cache, channel, deletedMessages);
        }

        if (isUniqueDeletion) {
          getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_MESSAGES,
          )();
        }
      },
    },
  );

  const deletionResultMessages = getDeletionResultMessages(isUniqueDeletion);

  const handleDeleteMessages = useCallback(() => {
    deleteMessages({
      variables: {
        deleteMessagesInput: {
          channel,
          messageKeys: selectedMessages.map((_) => _.key),
        },
      },
    })
      .then(({ data }) => {
        if (
          data.deleteMessages?.errorReason ||
          data.deleteMessages?.successes?.length === 0
        ) {
          showErrorMessage(
            <FormattedMessage id={deletionResultMessages.error} />,
          );
        } else if (data.deleteMessages?.failures?.length > 0) {
          data.deleteMessages.failures.forEach((_) => {
            const message = selectedMessages.find((m) => m.key === _.subject);

            if (message) {
              updateSelectedMessages(message, false);
            }
          });

          showWarningMessage(
            <FormattedMessage id={deletionResultMessages.partialSuccess} />,
          );
        } else {
          resetSelectedMessages();

          showSuccessMessage(
            <FormattedMessage id={deletionResultMessages.success} />,
          );

          trackEvent(
            GAEventCategory.ADMIN_MESSAGES,
            GAAdminMessagesEventActions.DELETE_MESSAGES,
            'Messages',
          );
        }
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(
          <FormattedMessage id={deletionResultMessages.error} />,
        );
      });
  }, [
    channel,
    deletionResultMessages,
    selectedMessages,
    deleteMessages,
    resetSelectedMessages,
    showErrorMessage,
    showSuccessMessage,
    showWarningMessage,
    updateSelectedMessages,
  ]);

  const disabled = loading || selectedMessages.length === 0;

  if (isSmallScreen) {
    return (
      <AdminMessagesMobileDeleteButton
        disabled={disabled}
        handleDeleteMessages={handleDeleteMessages}
      />
    );
  }

  return (
    <AdminMessagesDeleteButtonPure
      disabled={disabled}
      isUniqueDeletion={isUniqueDeletion}
      handleDeleteMessages={handleDeleteMessages}
    />
  );
};

export default AdminMessagesDeleteButton;
