import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    borderRadius: 15,
    minWidth: 0,
    padding: 15,

    '&:hover': {
      opacity: 0.7,
    },
  },
  blue: {
    backgroundColor: COLORS.BLUE,
    borderColor: COLORS.BLUE,
    color: COLORS.WHITE,

    '&:hover': {
      backgroundColor: COLORS.BLUE,
    },
  },
  green: {
    backgroundColor: COLORS.GREEN,
    borderColor: COLORS.GREEN,
    color: COLORS.WHITE,

    '&:hover': {
      backgroundColor: COLORS.GREEN,
    },
  },
  lightred: {
    backgroundColor: COLORS.LIGHT_RED,
    borderColor: COLORS.LIGHT_RED,
    color: COLORS.WHITE,

    '&:hover': {
      backgroundColor: COLORS.LIGHT_RED,
    },
  },
  red: {
    backgroundColor: COLORS.RED,
    borderColor: COLORS.RED,
    color: COLORS.WHITE,

    '&:hover': {
      backgroundColor: COLORS.RED,
    },
  },
  violet: {
    backgroundColor: COLORS.VIOLET,
    borderColor: COLORS.VIOLET,
    color: COLORS.WHITE,

    '&:hover': {
      backgroundColor: COLORS.VIOLET,
    },
  },
  white: {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.WHITE,
    color: COLORS.BLACK,

    '&:hover': {
      backgroundColor: COLORS.WHITE,
    },
  },
}));
