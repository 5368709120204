import { createContext, useState } from 'react';
import { emptyFunction } from '../../../utils/Functions';
import PostcardPlaceholderImage from '../../../images/Communicate/Postcard/postcard_placeholder.png';
import { StateSetter } from '../../../types/utils/React';
import { S3UploadOutputType } from '../../../utils/S3Upload';

interface PostcardContextProps {
  imagePreview: string;
  postcardContent: string;
  s3UploadedImage: S3UploadOutputType | undefined;
  sending: boolean;
  showCrop: boolean;
  showFront: boolean;
  setImagePreview: StateSetter<string>;
  setPostcardContent: StateSetter<string>;
  setS3UploadedImage: StateSetter<S3UploadOutputType | undefined>;
  setSending: StateSetter<boolean>;
  setShowCrop: StateSetter<boolean>;
  setShowFront: StateSetter<boolean>;
}

const initialState = {
  imagePreview: PostcardPlaceholderImage,
  postcardContent: '',
  s3UploadedImage: undefined,
  sending: false,
  showCrop: false,
  showFront: true,
  setImagePreview: emptyFunction,
  setPostcardContent: emptyFunction,
  setS3UploadedImage: emptyFunction,
  setSending: emptyFunction,
  setShowCrop: emptyFunction,
  setShowFront: emptyFunction,
};

const postcardContext = createContext<PostcardContextProps>(initialState),
  { Provider } = postcardContext;

interface PostcardContextProviderProps {
  children: React.ReactNode;
}

const PostcardContextProvider = ({
  children,
}: PostcardContextProviderProps): JSX.Element => {
  const [showFront, setShowFront] = useState<boolean>(true);
  const [imagePreview, setImagePreview] = useState<string>(
    PostcardPlaceholderImage,
  );
  const [showCrop, setShowCrop] = useState<boolean>(false);
  const [postcardContent, setPostcardContent] = useState<string>('');
  const [s3UploadedImage, setS3UploadedImage] = useState<S3UploadOutputType>();
  const [sending, setSending] = useState<boolean>(false);

  return (
    <Provider
      value={{
        imagePreview,
        postcardContent,
        s3UploadedImage,
        sending,
        showCrop,
        showFront,
        setImagePreview,
        setPostcardContent,
        setS3UploadedImage,
        setSending,
        setShowCrop,
        setShowFront,
      }}
    >
      {children}
    </Provider>
  );
};

export { postcardContext, PostcardContextProvider };
