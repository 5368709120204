import { isEmailValid } from '../../../../../utils/String';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { AddRelationshipErrorType } from '../../../../../types/Relationship';
import { MutationFunction } from '@apollo/client';
import { StateSetter } from '../../../../../types/utils/React';

const getErrorMessageId = (reason: AddRelationshipErrorType): string => {
  if (
    [
      AddRelationshipErrorType.ALREADY_EXISTS,
      AddRelationshipErrorType.MAIN_USER_NOT_FOUND,
      AddRelationshipErrorType.MINIMAL_VERSION_NOT_SATISFIED,
      AddRelationshipErrorType.WEB_USER_ALREADY_LINKED_TO_CHANNEL,
    ].includes(reason)
  ) {
    return `relationship.error.${reason}`;
  } else {
    return 'relationship.error.internal';
  }
};

type GetHandleAssociateFunctionInputType = {
  email: string;
  addRelationshipMutation: MutationFunction;
  setHasEmailError: StateSetter<boolean>;
  setRelationshipHasBeenAdded: StateSetter<boolean>;
  showErrorMessage: (msg: ReactNode) => void;
};

export const addRelationship = ({
  email,
  addRelationshipMutation,
  setHasEmailError,
  setRelationshipHasBeenAdded,
  showErrorMessage,
}: GetHandleAssociateFunctionInputType): void => {
  if (!isEmailValid(email)) {
    setHasEmailError(true);
  } else {
    addRelationshipMutation({
      variables: {
        input: {
          mainUserEmail: email,
        },
      },
    })
      .then(({ data }) => {
        if (data?.addRelationship?.errorReason) {
          const errorMessageId = getErrorMessageId(
            data.addRelationship.errorReason,
          );

          showErrorMessage(<FormattedMessage id={errorMessageId} />);
        } else if (data?.addRelationship?.relationship) {
          setRelationshipHasBeenAdded(true);
        }
      })
      .catch(() =>
        showErrorMessage(<FormattedMessage id="relationship.error.internal" />),
      );
  }
};
