export enum FileTypeSwitcherFileTypes {
  ADD = 'ADD',
  APP = 'APP',
  BOOK = 'BOOK',
  BOOKMARK = 'BOOKMARK',
  EXPLORER = 'EXPLORER',
  GAME = 'GAME',
  INFO = 'INFO',
  MUSIC = 'MUSIC',
  NOTE_ONLY = 'NOTE_ONLY',
  OFFICE = 'OFFICE',
  PICTURE_AND_VIDEO = 'PICTURE_AND_VIDEO',
  PICTURE_ONLY = 'PICTURE_ONLY',
}
