import React, {
  KeyboardEvent,
  KeyboardEventHandler,
  TouchEvent,
  TouchEventHandler,
  useCallback,
  useContext,
} from 'react';
import useStyles from './ImageViewer.styles';
import { imageViewerContext } from './ImageViewerContext';
import { SharedDocumentType } from '../../types/document/Document';
import PreviousImageButton from './PreviousImageButton';
import NextImageButton from './NextImageButton';
import CloseButton from './CloseButton';
import ImageViewerItem from './ImageViewerItem';

const TOUCH_PX_SENSIBILITY = 150;

const containerRef: React.RefObject<HTMLDivElement> = React.createRef();

type ImageViewerPureProps = {
  images: SharedDocumentType[];
  handleKeyDown: KeyboardEventHandler;
  handleTouchEnd: TouchEventHandler;
  handleTouchMove: TouchEventHandler;
  handleTouchStart: TouchEventHandler;
};

const ImageViewerPure = ({
  images,
  handleKeyDown,
  handleTouchEnd,
  handleTouchMove,
  handleTouchStart,
}: ImageViewerPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      tabIndex={0}
      ref={containerRef}
      onLoad={() => containerRef?.current?.focus()}
      onKeyDown={handleKeyDown}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
    >
      <CloseButton />
      <PreviousImageButton />

      {images.map((image) => (
        <ImageViewerItem image={image} key={image.id} />
      ))}

      <NextImageButton />
    </div>
  );
};

export type ImageViewerProps = {};

const ImageViewer = (): JSX.Element | null => {
  const { currentViewedImage, imageDocuments, handleCurrentViewedImage } =
    useContext(imageViewerContext);

  const currentViewedImageIndex = imageDocuments.findIndex(
    (image) => image.id === currentViewedImage?.id,
  );

  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  const handleTouchStart = useCallback(
    (e: TouchEvent) => setTouchStart(e.targetTouches[0].clientX),
    [setTouchStart],
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => setTouchEnd(e.targetTouches[0].clientX),
    [setTouchEnd],
  );

  const hasNextImage =
    currentViewedImageIndex > -1 &&
    currentViewedImageIndex < imageDocuments.length - 1;

  const hasPreviousImage = currentViewedImageIndex >= 1;

  const handleTouchEnd = useCallback(() => {
    const touchDelta = touchStart - touchEnd;
    if (touchDelta > TOUCH_PX_SENSIBILITY && hasNextImage) {
      const nextImage = imageDocuments[currentViewedImageIndex + 1];
      handleCurrentViewedImage(nextImage);
    } else if (touchDelta < -TOUCH_PX_SENSIBILITY && hasPreviousImage) {
      const previousImage = imageDocuments[currentViewedImageIndex - 1];
      handleCurrentViewedImage(previousImage);
    }
  }, [
    currentViewedImageIndex,
    hasNextImage,
    hasPreviousImage,
    imageDocuments,
    touchEnd,
    touchStart,
    handleCurrentViewedImage,
  ]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLImageElement>): void => {
      if (e.key === 'ArrowLeft' && hasPreviousImage) {
        const previousImage = imageDocuments[currentViewedImageIndex - 1];
        handleCurrentViewedImage(previousImage);
      } else if (e.key === 'ArrowRight' && hasNextImage) {
        const nextImage = imageDocuments[currentViewedImageIndex + 1];
        handleCurrentViewedImage(nextImage);
      } else if (e.key === 'Escape') {
        handleCurrentViewedImage(undefined);
      }
    },
    [
      currentViewedImageIndex,
      hasNextImage,
      hasPreviousImage,
      imageDocuments,
      handleCurrentViewedImage,
    ],
  );

  if (!currentViewedImage) {
    return null;
  }

  return (
    <ImageViewerPure
      images={imageDocuments}
      handleKeyDown={handleKeyDown}
      handleTouchEnd={handleTouchEnd}
      handleTouchMove={handleTouchMove}
      handleTouchStart={handleTouchStart}
    />
  );
};

export default ImageViewer;
