import useStyles from './ErrorPage.styles';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GenericErrorImage } from '../../images/Errors/GenericErrorImage';

const OfflinePage = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.errorContainer} item xs={12}>
        <div className={classes.title}>
          <FormattedMessage id="offline_page.title" />
        </div>
      </Grid>

      <Grid item xs={4} />
      <Grid item xs={4}>
        <GenericErrorImage />
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default OfflinePage;
