import { gql } from '@apollo/client';

export const DELETE_MESSAGES_MUTATION = gql`
  mutation deleteMessagesMutation($deleteMessagesInput: DeleteMessagesInput!) {
    deleteMessages(deleteMessagesInput: $deleteMessagesInput) {
      __typename
      ... on DeleteMessagesOutput {
        successes
        failures {
          subject
          reason
        }
      }
      ... on DeleteMessagesQueryErrorOutput {
        errorReason
      }
    }
  }
`;

export enum AdminQueryError {
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum DeleteMessagesProcessError {
  DELETE = 'DELETE',
  DOES_NOT_BELONG_TO_TABLET = 'DOES_NOT_BELONG_TO_TABLET',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type DeleteMessagesOutputType = {
  deleteMessages: {
    errorReason?: AdminQueryError;
    failures: {
      subject: string;
      reason: DeleteMessagesProcessError;
    }[];
    successes: string[];
  };
};
