import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  adminMenu: {
    boxShadow: '0px 3px 5px 1px rgb(0 0 0 / 25%)',
    height: '100%',
  },
  menu: {
    background:
      'linear-gradient(-185deg, #FFFFFF, 75%, rgba(75, 85, 222, 0.3))',
    height: '100%',
  },
  menuBg: {
    backgroundColor: COLORS.WHITE,
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      position: 'fixed',
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '20%',
    },
  },
}));
