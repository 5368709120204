import { gql } from '@apollo/client';

export const SEND_POSTCARD_MUTATION = gql`
  mutation sendPostcard($input: SendPostcardInput!) {
    sendPostcard(input: $input) {
      ... on SendPostcardErrorOutput {
        errorReason
        __typename
      }

      ... on SendPostcardOutput {
        sent
        __typename
      }
    }
  }
`;

export enum SendPostcardError {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  WRONG_INPUT = 'WRONG_INPUT',
}

export type SendPostcardOutputType = {
  sendPostcard: {
    errorReason: SendPostcardError;
    sent: boolean;
  };
};
