import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import {
  COMMIT_CALL_INTERRUPTED_VIDEO_CALL_MUTATION,
  COMMIT_CALL_START_TIMEOUT_VIDEO_CALL_MUTATION,
  CommitCallInterruptedVideoCallOutputType,
  CommitCallStartTimeoutVideoCallOutputType,
  SEND_NOTIFICATION_VIDEO_CALL_MUTATION,
  SendNotificationVideoCallOutputType,
  START_VIDEO_CALL_MUTATION,
  StartVideoCallOutputType,
  STOP_VIDEO_CALL_MUTATION,
  StopVideoCallOutputType,
  WAIT_ACCEPT_VIDEO_CALL_MUTATION,
  WAIT_NOTIFICATION_ACKNOWLEDGMENT_VIDEO_CALL_MUTATION,
  WAIT_START_VIDEO_CALL_MUTATION,
  WaitAcceptVideoCallOutputType,
  WaitNotificationAcknowledgmentVideoCallOutputType,
  WaitStartVideoCallOutputType,
} from '../../../../graphql/communicate/videocall/VideoCallMutations';
import { MutationFunction } from '../../../../types/utils/GraphQL';
import { MutationResult, useMutation } from '@apollo/client';
import { StateSetter } from '../../../../types/utils/React';
import {
  getVideoCallFromVideoCallQueryOutput,
  VideoCallParticipantType,
  VideoCallState,
  VideoCallType,
} from '../../../../types/videocall/VideoCall';
import {
  ArdoizVisioInterface,
  VIDEO_CALL_ERROR_REDIRECT_TIMEOUT,
  VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT,
} from '../../../../utils/constants';
import { TimeoutType } from '../../../../types/utils/Timeout';
import { WebUserType } from '../../../../types/User';
import { LinkType } from '../../../../types/Link';
import Route from '../../../../routes/Route';
import { getGoToSelectedChannelPageFunction } from '../../../../utils/history';
import { isSelfLink } from '../../../../utils/Link';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
  GAVideoCallEventAction,
} from '../../../../GoogleAnalytics/GAEvent';

type UseActionMutationsOutputType = {
  sendNotificationVideoCall: MutationFunction<SendNotificationVideoCallOutputType>;
  sendNotificationVideoCallResult: MutationResult<SendNotificationVideoCallOutputType>;
  commitCallInterruptedVideoCall: MutationFunction<CommitCallInterruptedVideoCallOutputType>;
  commitCallInterruptedVideoCallResult: MutationResult<CommitCallInterruptedVideoCallOutputType>;
  commitCallStartTimeoutVideoCall: MutationFunction<CommitCallStartTimeoutVideoCallOutputType>;
  commitCallStartTimeoutVideoCallResult: MutationResult<CommitCallStartTimeoutVideoCallOutputType>;
  startVideoCall: MutationFunction<StartVideoCallOutputType>;
  startVideoCallResult: MutationResult<StartVideoCallOutputType>;
  stopVideoCall: MutationFunction<StopVideoCallOutputType>;
  stopVideoCallResult: MutationResult<StopVideoCallOutputType>;
  waitAcceptVideoCall: MutationFunction<WaitAcceptVideoCallOutputType>;
  waitAcceptVideoCallResult: MutationResult<WaitAcceptVideoCallOutputType>;
  waitNotificationAcknowledgmentVideoCall: MutationFunction<WaitNotificationAcknowledgmentVideoCallOutputType>;
  waitNotificationAcknowledgmentVideoCallResult: MutationResult<WaitNotificationAcknowledgmentVideoCallOutputType>;
  waitStartVideoCall: MutationFunction<WaitStartVideoCallOutputType>;
  waitStartVideoCallResult: MutationResult<WaitStartVideoCallOutputType>;
};

export const useActionMutations = (): UseActionMutationsOutputType => {
  const [commitCallInterruptedVideoCall, commitCallInterruptedVideoCallResult] =
    useMutation<CommitCallInterruptedVideoCallOutputType>(
      COMMIT_CALL_INTERRUPTED_VIDEO_CALL_MUTATION,
    );

  const [
    commitCallStartTimeoutVideoCall,
    commitCallStartTimeoutVideoCallResult,
  ] = useMutation<CommitCallStartTimeoutVideoCallOutputType>(
    COMMIT_CALL_START_TIMEOUT_VIDEO_CALL_MUTATION,
  );

  const [sendNotificationVideoCall, sendNotificationVideoCallResult] =
    useMutation<SendNotificationVideoCallOutputType>(
      SEND_NOTIFICATION_VIDEO_CALL_MUTATION,
    );

  const [startVideoCall, startVideoCallResult] =
    useMutation<StartVideoCallOutputType>(START_VIDEO_CALL_MUTATION);

  const [stopVideoCall, stopVideoCallResult] =
    useMutation<StopVideoCallOutputType>(STOP_VIDEO_CALL_MUTATION);

  const [waitAcceptVideoCall, waitAcceptVideoCallResult] =
    useMutation<WaitAcceptVideoCallOutputType>(WAIT_ACCEPT_VIDEO_CALL_MUTATION);

  const [
    waitNotificationAcknowledgmentVideoCall,
    waitNotificationAcknowledgmentVideoCallResult,
  ] = useMutation<WaitNotificationAcknowledgmentVideoCallOutputType>(
    WAIT_NOTIFICATION_ACKNOWLEDGMENT_VIDEO_CALL_MUTATION,
  );

  const [waitStartVideoCall, waitStartVideoCallResult] =
    useMutation<WaitStartVideoCallOutputType>(WAIT_START_VIDEO_CALL_MUTATION);

  return {
    sendNotificationVideoCall,
    sendNotificationVideoCallResult,
    commitCallInterruptedVideoCall,
    commitCallInterruptedVideoCallResult,
    commitCallStartTimeoutVideoCall,
    commitCallStartTimeoutVideoCallResult,
    startVideoCall,
    startVideoCallResult,
    stopVideoCall,
    stopVideoCallResult,
    waitAcceptVideoCall,
    waitAcceptVideoCallResult,
    waitNotificationAcknowledgmentVideoCall,
    waitNotificationAcknowledgmentVideoCallResult,
    waitStartVideoCall,
    waitStartVideoCallResult,
  };
};

export type InitiateOrResumeVideoCallInputType = {
  numberOfParticipants: number;
  videoCall: VideoCallType;
  webUser: WebUserType;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  showErrorMessage: (msg: ReactNode) => void;
  sendNotificationVideoCall: MutationFunction<SendNotificationVideoCallOutputType>;
  sendNotificationVideoCallResult: MutationResult<SendNotificationVideoCallOutputType>;
  startVideoCall: MutationFunction<StartVideoCallOutputType>;
  startVideoCallResult: MutationResult<StartVideoCallOutputType>;
  waitAcceptVideoCall: MutationFunction<WaitAcceptVideoCallOutputType>;
  waitAcceptVideoCallResult: MutationResult<WaitAcceptVideoCallOutputType>;
  waitNotificationAcknowledgmentVideoCall: MutationFunction<WaitNotificationAcknowledgmentVideoCallOutputType>;
  waitNotificationAcknowledgmentVideoCallResult: MutationResult<WaitNotificationAcknowledgmentVideoCallOutputType>;
  waitStartVideoCall: MutationFunction<WaitStartVideoCallOutputType>;
  waitStartVideoCallResult: MutationResult<WaitStartVideoCallOutputType>;
};

const mutationHasAlreadyBeenCalled = (mutationResult: MutationResult) =>
  mutationResult.called;

export const initiateOrResumeVideoCall = async ({
  numberOfParticipants,
  videoCall,
  webUser,
  sendNotificationVideoCall,
  sendNotificationVideoCallResult,
  setVideoCall,
  showErrorMessage,
  startVideoCall,
  startVideoCallResult,
  waitAcceptVideoCall,
  waitAcceptVideoCallResult,
  waitNotificationAcknowledgmentVideoCall,
  waitNotificationAcknowledgmentVideoCallResult,
  waitStartVideoCall,
  waitStartVideoCallResult,
}: InitiateOrResumeVideoCallInputType): Promise<void> => {
  switch (videoCall.command.currentState) {
    case VideoCallState.Initial:
      if (!mutationHasAlreadyBeenCalled(sendNotificationVideoCallResult)) {
        await sendNotification({
          videoCall,
          webUser,
          sendNotificationVideoCall,
          setVideoCall,
          showErrorMessage,
        });
      }

      break;

    case VideoCallState.WaitingNotificationAcknowledge:
      if (
        !mutationHasAlreadyBeenCalled(
          waitNotificationAcknowledgmentVideoCallResult,
        )
      ) {
        await waitNotificationAcknowledgment({
          videoCall,
          webUser,
          setVideoCall,
          showErrorMessage,
          waitNotificationAcknowledgmentVideoCall,
        });
      }

      break;

    case VideoCallState.WaitingCalledAnswer:
      if (!mutationHasAlreadyBeenCalled(waitAcceptVideoCallResult)) {
        await waitAccept({
          videoCall,
          webUser,
          setVideoCall,
          showErrorMessage,
          waitAcceptVideoCall,
        });
      }

      break;

    case VideoCallState.WaitingCallStart:
      if (
        videoCall.participantType === VideoCallParticipantType.CALLER &&
        numberOfParticipants > 1 &&
        !mutationHasAlreadyBeenCalled(startVideoCallResult)
      ) {
        await start({
          videoCall,
          webUser,
          setVideoCall,
          showErrorMessage,
          startVideoCall,
        });
      } else if (
        videoCall.participantType === VideoCallParticipantType.CALLED &&
        !mutationHasAlreadyBeenCalled(waitStartVideoCallResult)
      ) {
        await waitStart({
          videoCall,
          webUser,
          setVideoCall,
          showErrorMessage,
          waitStartVideoCall,
        });
      }
      break;
  }
};

type SendNotificationInputType = {
  videoCall: VideoCallType;
  webUser: WebUserType;
  sendNotificationVideoCall: MutationFunction<SendNotificationVideoCallOutputType>;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  showErrorMessage: (msg: ReactNode) => void;
};

const sendNotification = ({
  videoCall,
  webUser,
  sendNotificationVideoCall,
  setVideoCall,
  showErrorMessage,
}: SendNotificationInputType): Promise<boolean> =>
  new Promise((resolve) =>
    sendNotificationVideoCall({
      variables: { videoCallID: videoCall.id },
    })
      .then(({ data }) => {
        const updatedVideoCall = data?.sendNotificationVideoCall?.videoCall;
        const isSuccessful =
          updatedVideoCall?.command?.currentState ===
          VideoCallState.WaitingNotificationAcknowledge;

        setVideoCall(
          getVideoCallFromVideoCallQueryOutput(updatedVideoCall, webUser),
        );

        resolve(isSuccessful);

        trackErrorIfNeeded({
          isSuccessful,
          errorMessage: 'Notification non reçue',
        });
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(
          <FormattedMessage id="videoCall.error.sendNotification" />,
        );
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.ERROR,
          'Envoi de notification',
        );
        setVideoCall({
          ...videoCall,
          command: {
            currentState: VideoCallState.EXIT_WITH_ERROR,
          },
        });
        resolve(false);
      }),
  );

type WaitNotificationAcknowledgmentInputType = {
  videoCall: VideoCallType;
  webUser: WebUserType;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  showErrorMessage: (msg: ReactNode) => void;
  waitNotificationAcknowledgmentVideoCall: MutationFunction<WaitNotificationAcknowledgmentVideoCallOutputType>;
};

const waitNotificationAcknowledgment = ({
  videoCall,
  webUser,
  setVideoCall,
  showErrorMessage,
  waitNotificationAcknowledgmentVideoCall,
}: WaitNotificationAcknowledgmentInputType): Promise<boolean> =>
  new Promise((resolve) => {
    waitNotificationAcknowledgmentVideoCall({
      variables: { videoCallID: videoCall.id },
    })
      .then(({ data }) => {
        const updatedVideoCall =
          data?.waitNotificationAcknowledgmentVideoCall?.videoCall;
        const isSuccessful =
          updatedVideoCall?.command?.currentState ===
          VideoCallState.WaitingCalledAnswer;

        setVideoCall(
          getVideoCallFromVideoCallQueryOutput(
            updatedVideoCall,
            webUser,
            data?.waitNotificationAcknowledgmentVideoCall
              ?.missingCallNotificationHasBeenSent,
          ),
        );

        resolve(isSuccessful);

        trackErrorIfNeeded({
          isSuccessful,
          errorMessage: 'Correspondant non joignable',
        });
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(
          <FormattedMessage id="videoCall.error.waitNotificationAcknowledgment" />,
        );
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.ERROR,
          'Attente de disponibilité',
        );
        setVideoCall({
          ...videoCall,
          command: {
            currentState: VideoCallState.EXIT_WITH_ERROR,
          },
        });
        resolve(false);
      });
  });

type WaitAcceptInputType = {
  videoCall: VideoCallType;
  webUser: WebUserType;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  showErrorMessage: (msg: ReactNode) => void;
  waitAcceptVideoCall: MutationFunction<WaitAcceptVideoCallOutputType>;
};

const waitAccept = ({
  videoCall,
  webUser,
  setVideoCall,
  showErrorMessage,
  waitAcceptVideoCall,
}: WaitAcceptInputType): Promise<boolean> =>
  new Promise((resolve) => {
    waitAcceptVideoCall({
      variables: { videoCallID: videoCall.id },
    })
      .then(({ data }) => {
        const updatedVideoCall = data?.waitAcceptVideoCall?.videoCall;
        const isSuccessful =
          updatedVideoCall?.command?.currentState ===
          VideoCallState.WaitingCallStart;

        setVideoCall(
          getVideoCallFromVideoCallQueryOutput(updatedVideoCall, webUser),
        );

        resolve(isSuccessful);

        trackErrorIfNeeded({
          isSuccessful,
          errorMessage: 'Appel refusé',
        });
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(<FormattedMessage id="videoCall.error.waitAccept" />);
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.ERROR,
          "Attente d'acceptation",
        );
        setVideoCall({
          ...videoCall,
          command: {
            currentState: VideoCallState.EXIT_WITH_ERROR,
          },
        });
        resolve(false);
      });
  });

type StartInputType = {
  videoCall: VideoCallType;
  webUser: WebUserType;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  showErrorMessage: (msg: ReactNode) => void;
  startVideoCall: MutationFunction<StartVideoCallOutputType>;
};

const start = ({
  videoCall,
  webUser,
  setVideoCall,
  showErrorMessage,
  startVideoCall,
}: StartInputType): Promise<boolean> =>
  new Promise((resolve) => {
    startVideoCall({
      variables: { videoCallID: videoCall.id },
    })
      .then(({ data }) => {
        const updatedVideoCall = data?.startVideoCall?.videoCall;
        const isSuccessful =
          updatedVideoCall?.command?.currentState === VideoCallState.Running;

        setVideoCall(
          getVideoCallFromVideoCallQueryOutput(updatedVideoCall, webUser),
        );
        resolve(isSuccessful);

        trackEventOrError({
          isSuccessful,
          errorMessage: GAVideoCallEventAction.VIDEO_CALL_START,
          successAction: GAVideoCallEventAction.VIDEO_CALL_START,
          successMessage: GAVideoCallEventAction.VIDEO_CALL_START,
        });
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(<FormattedMessage id="videoCall.error.start" />);
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.ERROR,
          GAVideoCallEventAction.VIDEO_CALL_START,
        );
        setVideoCall({
          ...videoCall,
          command: {
            currentState: VideoCallState.EXIT_WITH_ERROR,
          },
        });
        resolve(false);
      });
  });

type ManageMissingParticipantTimeoutInputType = {
  numberOfParticipants: number;
  timeoutHandler: TimeoutType | undefined;
  videoCall: VideoCallType;
  videoCallMatchState: VideoCallState;
  videoCallTargetState: VideoCallState;
  setTimeoutHandler: StateSetter<TimeoutType | undefined>;
  setVideoCall: StateSetter<VideoCallType | undefined>;
};

export const manageMissingParticipantTimeout = ({
  numberOfParticipants,
  timeoutHandler,
  videoCallMatchState,
  videoCallTargetState,
  videoCall,
  setTimeoutHandler,
  setVideoCall,
}: ManageMissingParticipantTimeoutInputType): void => {
  if (
    videoCall.command.currentState === videoCallMatchState &&
    numberOfParticipants < 2
  ) {
    const newTimeoutHandler = setTimeout(
      () =>
        setVideoCall({
          ...videoCall,
          command: {
            ...videoCall.command,
            currentState: videoCallTargetState,
          },
        }),
      VIDEO_CALL_MISSING_PARTICIPANT_TIMEOUT,
    );
    setTimeoutHandler(newTimeoutHandler);
  } else if (timeoutHandler !== undefined) {
    clearTimeout(timeoutHandler);
    setTimeoutHandler(undefined);
  }
};

type WaitStartInputType = {
  videoCall: VideoCallType;
  webUser: WebUserType;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  showErrorMessage: (msg: ReactNode) => void;
  waitStartVideoCall: MutationFunction<WaitStartVideoCallOutputType>;
};

const waitStart = ({
  videoCall,
  webUser,
  setVideoCall,
  showErrorMessage,
  waitStartVideoCall,
}: WaitStartInputType): Promise<boolean> =>
  new Promise((resolve) => {
    waitStartVideoCall({
      variables: { videoCallID: videoCall.id },
    })
      .then(({ data }) => {
        const updatedVideoCall = data?.waitStartVideoCall?.videoCall;
        const isSuccessful =
          updatedVideoCall?.command?.currentState === VideoCallState.Running;

        setVideoCall(
          getVideoCallFromVideoCallQueryOutput(updatedVideoCall, webUser),
        );

        resolve(isSuccessful);

        trackErrorIfNeeded({
          isSuccessful,
          errorMessage: "Attente du début d'appel",
        });
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(<FormattedMessage id="videoCall.error.start" />);
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.ERROR,
          "Attente du début d'appel",
        );
        setVideoCall({
          ...videoCall,
          command: {
            currentState: VideoCallState.EXIT_WITH_ERROR,
          },
        });
        resolve(false);
      });
  });

type StopInputType = {
  api: any;
  videoCall: VideoCallType | undefined;
  webUser: WebUserType;
  setVideoCall: StateSetter<VideoCallType | undefined>;
  stopVideoCall: MutationFunction<StopVideoCallOutputType>;
};

export const stop = ({
  api,
  videoCall,
  webUser,
  setVideoCall,
  stopVideoCall,
}: StopInputType): void => {
  if (videoCall) {
    if (videoCall?.command.currentState === VideoCallState.Running) {
      stopVideoCall({
        variables: {
          videoCallID: videoCall?.id,
        },
      }).then(({ data }) => {
        setVideoCall(
          getVideoCallFromVideoCallQueryOutput(
            data?.stopVideoCall.videoCall,
            webUser,
          ),
        );

        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GAVideoCallEventAction.VIDEO_CALL_END,
          'Appel terminé',
        );

        api.executeCommand('hangup');
      });
    } else {
      setVideoCall({
        ...videoCall,
        command: {
          ...videoCall.command,
          currentState: VideoCallState.Ended,
        },
      });

      api.executeCommand('hangup');
    }
  }
};

export const exitVideoCall = (selectedLink?: LinkType): void => {
  setTimeout(() => {
    if (isSelfLink(selectedLink)) {
      ArdoizVisioInterface.hangup();
    } else {
      getGoToSelectedChannelPageFunction(selectedLink, Route.COMMUNICATE)();
    }
  }, VIDEO_CALL_ERROR_REDIRECT_TIMEOUT);
};

type TrackEventOrErrorInputType = {
  isSuccessful: boolean;
  successAction: GAVideoCallEventAction;
  successMessage: string;
  errorMessage: string;
};

const trackEventOrError = ({
  isSuccessful,
  errorMessage,
  successAction,
  successMessage,
}: TrackEventOrErrorInputType): void => {
  if (isSuccessful) {
    trackEvent(GAEventCategory.VIDEO_CALL, successAction, successMessage);
  } else {
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.ERROR,
      errorMessage,
    );
  }
};

type TrackErrorIfNeededInputType = {
  isSuccessful: boolean;
  errorMessage: string;
};

const trackErrorIfNeeded = ({
  isSuccessful,
  errorMessage,
}: TrackErrorIfNeededInputType): void => {
  if (!isSuccessful) {
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.ERROR,
      errorMessage,
    );
  }
};
