import { createCtxWithMandatoryValue } from './utils';
import { StateSetter } from '../types/utils/React';

interface AppContextProps {
  isAdminMode: boolean;
  setIsAdminMode: StateSetter<boolean>;
}

const appContext = createCtxWithMandatoryValue<AppContextProps>(),
  [useAppContext, AppContextProvider] = appContext;

export { useAppContext, AppContextProvider };
