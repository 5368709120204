import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';

export default makeStyles(() => ({
  emailIcon: {
    width: 25,
  },
  successMessage: {
    color: COLORS.GREEN,
    fontSize: 22,
    fontWeight: 'bold',
    paddingTop: 30,
    textAlign: 'center',
  },
  textField: {
    width: '100%',

    '& .MuiInputBase-root': {
      background: COLORS.WHITE,
      borderRadius: 8,
    },
  },
}));
