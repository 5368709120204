export const shareMessages = {
  /* SHARE */
  'category.share.label': 'Partager',
  'share.description': 'Partager avec votre proche',

  'share.explorer.empty.0':
    'Voici les étapes à suivre pour partager du contenu avec votre proche',
  'share.explorer.empty.1': 'Créer un nouveau dossier',
  'share.explorer.empty.2': 'Nommer le dossier',
  'share.explorer.empty.3':
    "Inviter d'autres proches de la tablette ( ayant un compte sur le site famille) - optionnel",
  'share.explorer.empty.4': 'Valider',

  'share.explorer.header.root': 'Vos dossiers partagés avec la tablette',
  'share.explorer.header.shared-folder': 'Dossier partagé',

  'share.switcher.document': 'Documents',
  'share.switcher.music': 'Musiques',
  'share.switcher.photo': 'Photos et vidéos',

  'share.folder.create.button': 'Créer un nouveau dossier',
  'share.folder.create.title': 'Nom du dossier partagé',
  'share.folder.create.select-contacts':
    'Sélectionnez les contacts qui auront accès au dossier',
  'share.folder.create.select-contacts-placeholder':
    'Contacts rattachés à la tablette...',

  'share.folder.create.error': 'Erreur lors de la création du dossier',
  'share.folder.create.error.DUPLICATE_FOLDERNAME':
    'Un dossier portant le même nom existe déjà',
  'share.folder.create.error.UNAUTHORIZED':
    "Vous n'êtes pas autorisé à créer un dossier",
  'share.folder.create.error.UNAUTHORIZED_OTHER_USERS':
    "Vous n'êtes pas autorisé à partager le dossier avec ces utilisateurs",
  'share.folder.create.error.MANDATORY_FOLDER_NAME':
    'Veuillez saisir le nom du dossier à créer',

  'share.folder.create.success': 'Dossier créé avec succès',

  'share.document.add.button': 'Ajouter un fichier',
  'share.document.add.success': 'Fichier(s) partagé(s)',
  'share.document.add.loading': 'Partage en cours...',

  'share.document.add.result.DUPLICATE_FILENAME':
    'Un fichier portant le même nom existe déjà ({fileName})',
  'share.document.add.result.SAVED': 'Fichier {fileName} partagé',
  'share.document.add.result.NOT_SAVED':
    'Problème de sauvegarde du fichier {fileName}',
  'share.document.add.result.NOT_SENT':
    "Problème d'envoi du fichier {fileName}",
  'share.document.add.result.NOT_UPLOADED':
    'Problème de transfert du fichier {fileName}',
  'share.document.add.result.TEMP_BLOB_NOT_FOUND':
    'Erreur de récupération du fichier {fileName} transféré',
  'share.document.add.result.TOO_LARGE':
    'Le fichier {fileName} est trop volumineux',
  'share.document.add.result.TOO_MANY_FILES': 'Trop de fichiers à partager',
  'share.document.add.result.UNAUTHORIZED': 'Action non autorisée ({fileName})',
  'share.document.add.result.UNKNOWN': 'Erreur inconnue ({fileName})',
  'share.document.add.result.UNKNOWN_DOCUMENT':
    'Fichier {fileName} non reconnu',
  'share.document.add.result.WRONG_FILE_TYPE':
    'Extension du fichier {fileName} non autorisée',

  'share.folder.settings.title': 'Gestion du dossier partagé',
  'share.folder.settings.deleteFile': 'Supprimer des fichiers',
  'share.folder.settings.deleteFolder': 'Supprimer le dossier partagé',
  'share.folder.settings.quitFolder': 'Quitter le dossier partagé',

  'share.document.delete.description.photo':
    'Sélectionnez les photos et vidéos puis cliquez sur Supprimer',
  'share.document.delete.description2.photo':
    'Vous ne pouvez supprimer que les photos et vidéos que vous avez ajoutées',
  'share.document.delete.description.music':
    'Sélectionnez les musiques puis cliquez sur Supprimer',
  'share.document.delete.description2.music':
    'Vous ne pouvez supprimer que les musiques que vous avez ajoutées',
  'share.document.delete.description.document':
    'Sélectionnez les documents puis cliquez sur Supprimer',
  'share.document.delete.description2.document':
    'Vous ne pouvez supprimer que les documents que vous avez ajoutés',

  'share.document.delete.loading': 'Suppression en cours...',
  'share.document.delete.result.success': 'Fichier(s) supprimé(s)',

  'share.document.delete.result.OK': 'Fichier {fileName} supprimé',
  'share.document.delete.result.UNAUTHORIZED':
    'Action non autorisée ({fileName})',
  'share.document.delete.result.UNKNOWN_ERROR': 'Erreur inconnue ({fileName})',
  'share.document.delete.result.UNKNOWN_DOCUMENT':
    'Fichier {fileName} non reconnu',

  'share.folder.delete.result.success': 'Dossier supprimé',
  'share.folder.delete.result.error':
    'Un problème a été rencontré lors de la suppression du dossier',
  'share.folder.delete.description':
    'Cette action entraine la suppression complète du dossier auprès de la tablette de votre proche et de tous les autres membres qui ont accès à ce dossier partagé.<br></br> Cette modification est irréversible.',

  'share.folder.quit.result.success': 'Vous avez quitté le dossier',
  'share.folder.quit.result.error':
    'Un problème a été rencontré pour quitter le dossier partagé',
  'share.folder.quit.description':
    'En vous désabonnant, vous n’aurez définitivement plus accès au dossier partagé.<br></br> \n' +
    'Les photos que vous avez partagées dans ce dossier restent accessibles aux autres membres à moins que vous décidiez de les supprimer avant de vous désabonner.',

  'share.folder.contacts.edit.already_shared':
    'Personnes ayant accès à ce dossier partagé',
  'share.folder.contacts.edit.share_with':
    "Inviter d'autres contacts dans ce dossier",
  'share.folder.contacts.edit.button': 'Modifier les contacts',

  'share.folder.share.error': 'Erreur lors du partage du dossier',
  'share.folder.share.success': 'Dossier partagé',
};
