import { useContext } from 'react';
import useStyles from './Message.styles';
import { FormattedMessage } from 'react-intl';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import {
  calledHasSelfLink,
  getOtherParticipantFullName,
} from '../../../../../../types/videocall/VideoCall';
import useNotificationAcknowledgeTimeoutStyles from './NotificationAcknowledgeTimeout.styles';
import { ClassNameMap } from '@mui/material';

type NotificationAcknowledgeTimeoutPureProps = {
  classes: ClassNameMap;
  fullName?: string;
};

export const NotificationAcknowledgeTimeoutPure = ({
  classes,
  fullName,
}: NotificationAcknowledgeTimeoutPureProps): JSX.Element => (
  <div className={classes.messageContainer}>
    <audio autoPlay loop src="/sounds/notification.mp3" />
    <div className={classes.fullName}>{fullName}</div>
    <FormattedMessage id="videoCall.error.NotificationAcknowledgeTimeout" />
  </div>
);

export const NotificationAcknowledgeTimeoutWithMissingCallNotificationPure = ({
  classes,
  fullName,
}: NotificationAcknowledgeTimeoutPureProps): JSX.Element => {
  const notificationAcknowledgeTimeoutClasses =
    useNotificationAcknowledgeTimeoutStyles();

  return (
    <div className={classes.messageContainer}>
      <audio autoPlay loop src="/sounds/notification.mp3" />
      <div className={classes.fullName}>{fullName}</div>
      <FormattedMessage id="videoCall.error.NotificationAcknowledgeTimeout" />
      <div
        className={
          notificationAcknowledgeTimeoutClasses.missingCallNotification
        }
      >
        <FormattedMessage id="videoCall.error.notificationSent" />
      </div>
    </div>
  );
};

export const NotificationAcknowledgeTimeoutTabletPure = ({
  classes,
  fullName,
}: NotificationAcknowledgeTimeoutPureProps): JSX.Element => (
  <div className={classes.messageContainer}>
    <audio autoPlay loop src="/sounds/notification.mp3" />
    <FormattedMessage id="videoCall.error.NotificationAcknowledgeTimeout1.tablet" />
    <div className={classes.fullName}>{fullName}</div>
    <FormattedMessage id="videoCall.error.NotificationAcknowledgeTimeout2.tablet" />
  </div>
);

const NotificationAcknowledgeTimeout = (): JSX.Element => {
  const { videoCall } = useContext(videoCallContext);
  const fullName = getOtherParticipantFullName(videoCall);
  const classes = useStyles();

  if (calledHasSelfLink(videoCall)) {
    return (
      <NotificationAcknowledgeTimeoutTabletPure
        classes={classes}
        fullName={fullName}
      />
    );
  } else {
    if (videoCall?.missingCallNotificationHasBeenSent) {
      return (
        <NotificationAcknowledgeTimeoutWithMissingCallNotificationPure
          classes={classes}
          fullName={fullName}
        />
      );
    } else {
      return (
        <NotificationAcknowledgeTimeoutPure
          classes={classes}
          fullName={fullName}
        />
      );
    }
  }
};

export default NotificationAcknowledgeTimeout;
