import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
  label: {
    paddingBottom: 10,
    textAlign: 'center',
  },
  modal: {
    borderRadius: 24,
    left: '50%',
    padding: 40,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,

    [theme.breakpoints.down('md')]: {
      left: '5%',
      right: '5%',
      transform: 'translate(0, -50%)',
      width: 'auto',
    },
  },
}));
