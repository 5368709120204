import { createContext, ReactNode, useCallback, useState } from 'react';
import { StateSetter } from '../../types/utils/React';
import { emptyFunction } from '../../utils/Functions';
import { SharedDocumentType } from '../../types/document/Document';

export enum SlideDirectionType {
  LEFT = 'left',
  RIGHT = 'right',
}

export type ImageViewerContextProps = {
  currentViewedImage?: SharedDocumentType;
  imageDocuments: SharedDocumentType[];
  slideDirection: SlideDirectionType;
  handleCurrentViewedImage: (
    viewedImage: SharedDocumentType | undefined,
  ) => void;
};

const initialState = {
  currentViewedImage: undefined,
  imageDocuments: [],
  slideDirection: SlideDirectionType.LEFT,
  handleCurrentViewedImage: emptyFunction,
};

const imageViewerContext = createContext<ImageViewerContextProps>(initialState),
  { Provider } = imageViewerContext;

type ImageViewerContextProviderProps = {
  children: ReactNode;
  currentViewedImage?: SharedDocumentType;
  imageDocuments: SharedDocumentType[];
  setCurrentViewedImage: StateSetter<SharedDocumentType | undefined>;
};

const ImageViewerContextProvider = ({
  children,
  currentViewedImage,
  imageDocuments,
  setCurrentViewedImage,
}: ImageViewerContextProviderProps): JSX.Element => {
  const [slideDirection, setSlideDirection] = useState<SlideDirectionType>(
    SlideDirectionType.LEFT,
  );

  const handleCurrentViewedImage = useCallback(
    (viewedImage: SharedDocumentType | undefined) => {
      if (currentViewedImage && viewedImage) {
        const currentViewedImageIndex = imageDocuments.findIndex(
          (_) => _.id === currentViewedImage.id,
        );
        const viewedImageIndex = imageDocuments.findIndex(
          (_) => _.id === viewedImage.id,
        );
        const direction: SlideDirectionType =
          currentViewedImageIndex > viewedImageIndex
            ? SlideDirectionType.RIGHT
            : SlideDirectionType.LEFT;

        setSlideDirection(direction);
      }

      setCurrentViewedImage(viewedImage);
    },
    [
      currentViewedImage,
      imageDocuments,
      setCurrentViewedImage,
      setSlideDirection,
    ],
  );

  return (
    <Provider
      value={{
        currentViewedImage,
        imageDocuments,
        slideDirection,
        handleCurrentViewedImage,
      }}
    >
      {children}
    </Provider>
  );
};

export { imageViewerContext, ImageViewerContextProvider };
