import { gql } from '@apollo/client';
import { FolderType, SharedDocumentType } from '../../types/document/Document';
import { BLOB_FRAGMENT } from '../s3/S3Mutations';
import { WEB_USER_CONTACT_FRAGMENT } from '../webuser/WebUserQueries';

export const FOLDER_FRAGMENT = gql`
  fragment folderFields on Document {
    creator
    id
    key
    name
    sharedWith {
      ...webUserContactFields
    }
  }
  ${WEB_USER_CONTACT_FRAGMENT}
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment documentFields on Document {
    id
    blob {
      ...blobFields
    }
    creator
    key
    name
    type
  }
  ${BLOB_FRAGMENT}
`;

export const GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY = gql`
  query GetVisibleDocumentsFromFolderQuery(
    $getVisibleFoldersByFolderTypeInput: GetVisibleFoldersByFolderTypeInput!
  ) {
    visibleFoldersFromFolderType(
      getVisibleFoldersFromFolderTypeInput: $getVisibleFoldersByFolderTypeInput
    ) {
      folders {
        ...folderFields
      }
      errorReason
    }
  }
  ${FOLDER_FRAGMENT}
`;

export enum VisibleFoldersFromFolderTypeError {
  NOT_LINKED = 'NOT_LINKED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type GetVisibleFoldersFromFolderTypeQueryOutputType = {
  visibleFoldersFromFolderType: {
    errorReason: VisibleFoldersFromFolderTypeError;
    folders: FolderType[];
  };
};

export const GET_VISIBLE_DOCUMENTS_FROM_FOLDER_QUERY = gql`
  query GetVisibleDocumentsFromFolderQuery(
    $getVisibleDocumentsFromFolderInput: GetVisibleDocumentsFromFolderInput!
  ) {
    visibleDocumentsFromFolder(
      getVisibleDocumentsFromFolderInput: $getVisibleDocumentsFromFolderInput
    ) {
      documents {
        ...documentFields
      }
      errorReason
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export enum VisibleDocumentsFromFolderError {
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type GetVisibleDocumentsFromFolderQueryOutputType = {
  visibleDocumentsFromFolder: {
    documents: SharedDocumentType[];
    errorReason: VisibleDocumentsFromFolderError;
  };
};
