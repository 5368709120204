import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

type FormattedResultType = {
  icon: ReactNode;
  message: ReactNode;
};

type ResultItemPureProps = {
  formattedResult: FormattedResultType;
};

const ResultItemPure = ({
  formattedResult,
}: ResultItemPureProps): JSX.Element => (
  <>
    <Grid item xs={1}>
      {formattedResult.icon}
    </Grid>
    <Grid item xs={11}>
      {formattedResult.message}
    </Grid>
  </>
);

export type ResultItemProps = {
  fileName: string;
  inError: boolean;
  messageId: string;
};

const ResultItem = ({
  fileName,
  inError,
  messageId,
}: ResultItemProps): JSX.Element => {
  const formattedResult = {
    icon: inError ? <CloseIcon color="error" /> : <CheckIcon color="success" />,
    message: <FormattedMessage id={messageId} values={{ fileName }} />,
  };

  return <ResultItemPure formattedResult={formattedResult} />;
};

export default ResultItem;
