import NodeCalendar from 'node-calendar';
import moment, { Moment } from 'moment';

const calendarInstance = new NodeCalendar.Calendar();

export enum CalendarDays {
  MONDAY = NodeCalendar.MONDAY,
  TUESDAY = NodeCalendar.TUESDAY,
  WEDNESDAY = NodeCalendar.WEDNESDAY,
  THURSDAY = NodeCalendar.THURSDAY,
  FRIDAY = NodeCalendar.FRIDAY,
  SATURDAY = NodeCalendar.SATURDAY,
  SUNDAY = NodeCalendar.SUNDAY,
}

export enum CalendarMonths {
  JANUARY = NodeCalendar.JANUARY,
  FEBRUARY = NodeCalendar.FEBRUARY,
  MARCH = NodeCalendar.MARCH,
  APRIL = NodeCalendar.APRIL,
  MAY = NodeCalendar.MAY,
  JUNE = NodeCalendar.JUNE,
  JULY = NodeCalendar.JULY,
  AUGUST = NodeCalendar.AUGUST,
  SEPTEMBER = NodeCalendar.SEPTEMBER,
  OCTOBER = NodeCalendar.OCTOBER,
  NOVEMBER = NodeCalendar.NOVEMBER,
  DECEMBER = NodeCalendar.DECEMBER,
}

const CalendarUtils = {
  isSameDate: (date1: Moment, date2: Moment): boolean =>
    date1.isSame(date2, 'day'),

  isSameMonth: (date1: Moment, date2: Moment): boolean =>
    date1.isSame(date2, 'month'),

  isToday: (date: Moment): boolean => date.isSame(moment(), 'day'),

  formatDateToDayInMonthString: (date: Moment): string =>
    moment(date).format('D'),

  formatDateToISOString: (date: Moment): string =>
    moment(date).format('YYYY-MM-DD'),

  formatDateToMonthAndYearString: (date: Moment): string =>
    date.format('MMMM YYYY'),

  formatDateToDayFullMonthAndYearString: (date: Moment): string =>
    date.format('D MMMM YYYY'),

  formatDateToHourString: (date: Moment): string => date.format('H[h]mm'),

  getFullWeekMonthDates: (date: Moment): Moment[][] => {
    const dates = calendarInstance.monthdatescalendar(
      date.year(),
      date.month() + 1,
    );

    return dates.map((row) => row.map((value) => moment(value)));
  },
};

export default CalendarUtils;
