import React, { useContext, useEffect } from 'react';
import { VIDEO_CALL_DOMAIN } from '../../../../utils/constants';
import { videoCallContext } from '../../../../Context/Communicate/VideoCall/VideoCallContext';
import { useUserContext } from '../../../../Context/UserContext';
import { getWebUserFullName } from '../../../../utils/WebUser';
import {
  getVideoCallFromVideoCallQueryOutput,
  VideoCallState,
} from '../../../../types/videocall/VideoCall';
import useStyles from './VideoContent.styles';
import VideoStateManager from './VideoContent/VideoStateManager';
import ArdoizVisioLogo from './VideoContent/ArdoizVisioLogo';
import ControlToolbar from './VideoContent/ControlToolbar';
import { stop } from './Actions';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  STOP_VIDEO_CALL_MUTATION,
  StopVideoCallOutputType,
} from '../../../../graphql/communicate/videocall/VideoCallMutations';
import {
  GET_VIDEO_CALL_QUERY,
  GetVideoCallOutputType,
} from '../../../../graphql/communicate/videocall/VideoCallQueries';

declare const JitsiMeetExternalAPI: any;

const containerRef: React.RefObject<HTMLDivElement> = React.createRef();
const divRef: React.RefObject<HTMLDivElement> = React.createRef();

type VideoContentPureProps = {
  isPreview: boolean;
  isSelf?: boolean;
};

const VideoContentPure = ({
  isPreview,
  isSelf,
}: VideoContentPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div
          className={
            isSelf ? classes.selfContainer : classes.fullScreenableContainer
          }
          ref={containerRef}
        >
          <div className={classes.videoContainer} ref={divRef} />
          <ArdoizVisioLogo />
          <div className={classes.overlay}>
            <VideoStateManager isPreview={isPreview} />
            <ControlToolbar containerRef={containerRef} />
          </div>
        </div>
      </div>
    </>
  );
};

type VideoContentProps = {
  isPreview: boolean;
  isSelf?: boolean;
};

const VideoContent = ({
  isPreview,
  isSelf,
}: VideoContentProps): JSX.Element | null => {
  const { api, videoCall, setApi, setNumberOfParticipants, setVideoCall } =
    useContext(videoCallContext);
  const { webUser } = useUserContext();
  const [stopVideoCall] = useMutation<StopVideoCallOutputType>(
    STOP_VIDEO_CALL_MUTATION,
  );
  const [getVideoCall] = useLazyQuery<GetVideoCallOutputType>(
    GET_VIDEO_CALL_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: async (data) => {
        const videoCallResult = getVideoCallFromVideoCallQueryOutput(
          data.videoCall.videoCall,
          webUser,
        );

        if (videoCallResult?.command.currentState === VideoCallState.Running) {
          setNumberOfParticipants(api.getNumberOfParticipants());
        }

        setVideoCall(videoCallResult);
      },
    },
  );

  useEffect(
    () => {
      if (videoCall) {
        if (!api) {
          const options = {
            roomName: videoCall.roomName,
            width: '100%',
            height: '100%',
            parentNode: divRef.current,
            configOverwrite: {},
            interfaceConfigOverwrite: {},
          };
          const jitsiApi = new JitsiMeetExternalAPI(VIDEO_CALL_DOMAIN, options);

          jitsiApi.addEventListener('videoConferenceLeft', () => {
            jitsiApi.dispose();
          });

          jitsiApi.addEventListener('videoConferenceJoined', () => {
            jitsiApi.executeCommand('displayName', getWebUserFullName(webUser));
            setNumberOfParticipants(jitsiApi.getNumberOfParticipants());
          });

          setApi(jitsiApi);

          jitsiApi.addListener('participantJoined', () => {
            setNumberOfParticipants(jitsiApi.getNumberOfParticipants());
          });

          jitsiApi.addListener('participantLeft', () => {
            getVideoCall({
              variables: { id: videoCall?.id },
            });
          });
        }
      }

      if (api && !window.terminateVideoCallFromDevice) {
        window.terminateVideoCallFromDevice = () =>
          stop({
            api,
            videoCall,
            webUser,
            setVideoCall,
            stopVideoCall,
          });
      }
    },
    // Need to remove timeoutHandler to avoid recursively calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, videoCall, webUser, setApi, setVideoCall],
  );

  if (!videoCall) {
    return null;
  }

  return <VideoContentPure isPreview={isPreview} isSelf={isSelf} />;
};

export default VideoContent;
