import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  controls: {
    flexGrow: 1,
    margin: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadButton: {},
  player: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    backgroundColor: COLORS.DARKER_BLUE,
  },
}));
