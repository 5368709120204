import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_CONTACTS_BY_CHANNEL_QUERY,
  GetContactsByChannelOutputType,
} from '../../../../../../graphql/webuser/WebUserQueries';
import { useSelectedLinkContext } from '../../../../../../Context/SelectedLinkContext';
import {
  getPersonFullName,
  WebUserContactType,
} from '../../../../../../types/User';
import { Avatar, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useStyles from './FolderContactsSelect.styles';
import SelectedContactLabels from './FolderContactsSelect/SelectedContactLabels';
import { StateSetter } from '../../../../../../types/utils/React';
import { useUserContext } from '../../../../../../Context/UserContext';
import { getAvatarProps } from '../../../../../../utils/Avatar';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type FolderContactsSelectPureProps = {
  channelContacts: WebUserContactType[];
  contacts: string[];
  handleContactsChange: (event: SelectChangeEvent<string[]>) => void;
};

export const FolderContactsSelectPure = ({
  channelContacts,
  contacts,
  handleContactsChange,
}: FolderContactsSelectPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Select
      className={classes.select}
      data-test-id="folder-contacts-select"
      displayEmpty
      multiple
      value={contacts}
      onChange={handleContactsChange}
      renderValue={(selectedContactIds: string[]) => (
        <SelectedContactLabels
          channelContacts={channelContacts}
          selectedContacts={selectedContactIds}
        />
      )}
      MenuProps={MenuProps}
    >
      {channelContacts.map((contact) => (
        <MenuItem key={contact.id} value={contact.id}>
          <Avatar className={classes.avatar} {...getAvatarProps(contact)} />
          {getPersonFullName(contact)}
        </MenuItem>
      ))}
    </Select>
  );
};

type FolderContactsSelectProps = {
  contacts: string[];
  setContacts: StateSetter<string[]>;
};

const FolderContactsSelect = ({
  contacts,
  setContacts,
}: FolderContactsSelectProps): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { webUser } = useUserContext();

  const { data } = useQuery<GetContactsByChannelOutputType>(
    GET_CONTACTS_BY_CHANNEL_QUERY,
    {
      variables: {
        channel: selectedLink?.channelKey,
      },
    },
  );

  const handleContactsChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;

      if (Array.isArray(value)) {
        setContacts(value);
      } else {
        setContacts([value]);
      }
    },
    [setContacts],
  );

  if (data?.webUsers?.errorReason) {
    return (
      <div>
        Erreur de récupération des contacts. Veuillez réessayer ultérieurement.
      </div>
    );
  }

  const channelContacts = data?.webUsers?.webUsers;

  if (!channelContacts) {
    return null;
  }

  const channelContactsWithoutMe = channelContacts.filter(
    (_) => _.id !== webUser.id,
  );

  return (
    <FolderContactsSelectPure
      channelContacts={channelContactsWithoutMe}
      contacts={contacts}
      handleContactsChange={handleContactsChange}
    />
  );
};

export default FolderContactsSelect;
