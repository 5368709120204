import { createContext, ReactNode, useCallback, useState } from 'react';
import { emptyFunction } from '../../utils/Functions';
import { StateSetter } from '../../types/utils/React';
import { AlertColor } from '@mui/material/Alert/Alert';

export const DEFAULT_AUTO_HIDE_DURATION = 3000;
export const DEFAULT_TRANSITION_DURATION = 500;

export type ShowMessageFunctionType = (
  msg: ReactNode,
  autoHideDurationParam?: number | null,
) => void;

export type AlertContextProps = {
  autoHideDuration: number | null;
  message?: ReactNode;
  open: boolean;
  severity?: AlertColor;
  transitionDuration?: number;
  setOpen: StateSetter<boolean>;
  showErrorMessage: ShowMessageFunctionType;
  showSuccessMessage: ShowMessageFunctionType;
  showWarningMessage: ShowMessageFunctionType;
};

const initialState = {
  autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
  message: undefined,
  open: false,
  severity: undefined,
  transitionDuration: DEFAULT_TRANSITION_DURATION,
  setOpen: emptyFunction,
  showErrorMessage: emptyFunction,
  showSuccessMessage: emptyFunction,
  showWarningMessage: emptyFunction,
};

const alertContext = createContext<AlertContextProps>(initialState),
  { Provider } = alertContext;

type AlertContextProviderProps = {
  children: ReactNode;
  transitionDuration?: number;
};

const AlertContextProvider = ({
  children,
  transitionDuration,
}: AlertContextProviderProps): JSX.Element => {
  const [autoHideDuration, setAutoHideDuration] = useState<number | null>(
    DEFAULT_AUTO_HIDE_DURATION,
  );
  const [message, setMessage] = useState<ReactNode>();
  const [open, setOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>();

  const showErrorMessage = useCallback(
    (
      msg: ReactNode,
      autoHideDurationParam: number | null = DEFAULT_AUTO_HIDE_DURATION,
    ) => {
      setAutoHideDuration(autoHideDurationParam);
      setMessage(msg);
      setOpen(true);
      setSeverity('error');
    },
    [setMessage, setSeverity],
  );
  const showSuccessMessage = useCallback(
    (
      msg: ReactNode,
      autoHideDurationParam: number | null = DEFAULT_AUTO_HIDE_DURATION,
    ) => {
      setAutoHideDuration(autoHideDurationParam);
      setMessage(msg);
      setOpen(true);
      setSeverity('success');
    },
    [setMessage, setSeverity],
  );
  const showWarningMessage = useCallback(
    (
      msg: ReactNode,
      autoHideDurationParam: number | null = DEFAULT_AUTO_HIDE_DURATION,
    ) => {
      setAutoHideDuration(autoHideDurationParam);
      setMessage(msg);
      setOpen(true);
      setSeverity('warning');
    },
    [setMessage, setSeverity],
  );

  return (
    <Provider
      value={{
        autoHideDuration,
        message,
        open,
        severity,
        transitionDuration,
        showErrorMessage,
        setOpen,
        showSuccessMessage,
        showWarningMessage,
      }}
    >
      {children}
    </Provider>
  );
};

export { alertContext, AlertContextProvider };
