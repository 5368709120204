import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));
