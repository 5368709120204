import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  header: {
    fontSize: 18,
    fontWeight: 'normal',
    padding: '20px 0',
    textTransform: 'capitalize',
    width: 'calc(100% / 7)',

    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      padding: '10px 0',
    },
  },
}));
