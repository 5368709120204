import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  fullName: {
    fontSize: 32,
    fontWeight: 'bold',
    padding: '50px 50px 0 50px',

    '@media (max-width: 1199px)': {
      fontSize: 30,
    },
  },
}));
