import { WebUserContactType } from '../User';
import { BlobType } from '../Blob';

export enum SharedDocumentTypeEnum {
  APP = 'APP',
  BOOK = 'BOOK',
  BOOKMARK = 'BOOKMARK',
  EML = 'EML',
  EXCEL = 'EXCEL',
  FOLDER = 'FOLDER',
  GAME = 'GAME',
  INFO = 'INFO',
  MUSIC = 'MUSIC',
  NOTE = 'NOTE',
  PDF = 'PDF',
  PICTURE = 'PICTURE',
  POWERPOINT = 'POWERPOINT',
  UNDEFINED = 'UNDEFINED',
  UNKNOWN = 'UNKNOWN',
  VIDEO = 'VIDEO',
  WORD = 'WORD',
}

export type FolderType = {
  id: string;
  creator: string;
  key: string;
  name: string;
  sharedWith: WebUserContactType[];
};

export type SharedDocumentType = {
  id: string;
  blob: BlobType;
  key: string;
  creator: string;
  name: string;
  type: SharedDocumentTypeEnum;
};
