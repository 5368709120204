import { ReactNode, useEffect } from 'react';
import Loading from '../components/Loading/Loading';
import { useParams } from 'react-router';
import { AssociateParams } from '../types/route/Params';
import { useMutation } from '@apollo/client';
import {
  ACCEPT_RELATIONSHIP_MUTATION,
  AcceptRelationshipOutputType,
} from '../graphql/relationship/RelationshipMutations';
import {
  AcceptRelationshipErrorType,
  RelationshipStateType,
} from '../types/Relationship';
import { FormattedMessage } from 'react-intl';
import { emptyFunction } from '../utils/Functions';
import useStyles from './AcceptRelationship.styles';
import { Alert, Grid } from '@mui/material';
import FilledButton from '../components/Button/FilledButton';
import { getGoToChannelPageFunction, goTo } from '../utils/history';
import Route from '../routes/Route';
import ArdoizLogo from './ArdoizLogo';

type AcceptRelationshipContainerProps = {
  children: ReactNode;
};

const AcceptRelationshipContainer = ({
  children,
}: AcceptRelationshipContainerProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.logo}>
        <ArdoizLogo goToHomeClick={() => goTo(Route.ROOT)} />
      </div>

      <div className={classes.container}>{children}</div>
    </>
  );
};

type AcceptRelationshipErrorProps = {
  error: ReactNode;
};

const AcceptRelationshipError = ({ error }: AcceptRelationshipErrorProps) => (
  <AcceptRelationshipContainer>
    <Alert severity="error">{error}</Alert>
  </AcceptRelationshipContainer>
);

type AcceptAssociateSuccessProps = {
  channel: string;
};

const AcceptAssociateSuccess = ({
  channel,
}: AcceptAssociateSuccessProps): JSX.Element => {
  const classes = useStyles();
  return (
    <AcceptRelationshipContainer>
      <Grid container direction="column">
        <Grid item>
          <div className={classes.successContainer}>
            <Alert severity="success">
              <FormattedMessage id="relationship.accept.success" />
            </Alert>
          </div>
        </Grid>

        <Grid className={classes.successDescriptionContainer} item>
          <FormattedMessage id="relationship.accept.success.description" />
        </Grid>

        <Grid className={classes.successButtonContainer} item>
          <FilledButton
            color="violet"
            onClick={getGoToChannelPageFunction(channel, Route.HOME)}
          >
            <FormattedMessage id="button.backToHome" />
          </FilledButton>
        </Grid>
      </Grid>
    </AcceptRelationshipContainer>
  );
};

const AcceptRelationship = (): JSX.Element | null => {
  const { uuid } = useParams<AssociateParams>();
  const [acceptRelationship, { called, data, error, loading }] =
    useMutation<AcceptRelationshipOutputType>(ACCEPT_RELATIONSHIP_MUTATION);

  useEffect(() => {
    if (!called) {
      acceptRelationship({
        variables: {
          uuid,
        },
      }).then(emptyFunction);
    }
  });

  if (loading) {
    return <Loading />;
  }

  if (
    data?.acceptRelationship?.relationship?.state ===
    RelationshipStateType.CONNECTED
  ) {
    return (
      <AcceptAssociateSuccess
        channel={data.acceptRelationship.relationship.channel}
      />
    );
  }

  if (error) {
    const networkError = error.networkError;

    if (
      networkError &&
      'statusCode' in networkError &&
      networkError?.statusCode === 401
    ) {
      return null;
    }

    return (
      <AcceptRelationshipError
        error={<FormattedMessage id="relationship.error.other" />}
      />
    );
  }

  switch (data?.acceptRelationship?.errorReason) {
    case AcceptRelationshipErrorType.ALREADY_ACCEPTED:
      return (
        <AcceptRelationshipError
          error={<FormattedMessage id="relationship.error.already_accepted" />}
        />
      );

    case AcceptRelationshipErrorType.NOT_FOUND:
      return (
        <AcceptRelationshipError
          error={<FormattedMessage id="relationship.error.not_found" />}
        />
      );

    case AcceptRelationshipErrorType.OTHER:
      return (
        <AcceptRelationshipError
          error={<FormattedMessage id="relationship.error.other" />}
        />
      );
    default:
      break;
  }

  return null;
};

export default AcceptRelationship;
