import { Button } from '@mui/material';
import useStyles from './AdminMessagesMobileDeleteButton.styles';
import clsx from 'clsx';
import TrashIcon from '../../../../../images/icons/trash.svg';
import Image from '../../../../../components/Image/Image';

type AdminMessagesDeleteButtonPureProps = {
  disabled: boolean;
  handleDeleteMessages: () => void;
};

const AdminMessagesMobileDeleteButton = ({
  disabled,
  handleDeleteMessages,
}: AdminMessagesDeleteButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={clsx([classes.deleteButton])}
      disabled={disabled}
      onClick={handleDeleteMessages}
    >
      <Image src={TrashIcon} width={18} />
    </Button>
  );
};

export default AdminMessagesMobileDeleteButton;
