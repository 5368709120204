import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    backgroundColor: 'rgb(0, 0, 0, 0.8)',
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
}));
