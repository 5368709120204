import { MouseEventHandler } from 'react';
import clsx from 'clsx';
import useStyles from './Image.styles';
import { ClassNameMap } from '@mui/styles/withStyles';

type ImagePureProps = ImageProps & {
  classes: ClassNameMap;
};

const ImagePure = ({
  alt,
  classes,
  className,
  height,
  src,
  width,
  onClick,
  onMouseOver,
}: ImagePureProps): JSX.Element => (
  <img
    alt={alt}
    className={clsx([className, onClick && classes.clickable])}
    height={height}
    src={src}
    width={width}
    onClick={onClick}
    onMouseOver={onMouseOver}
  />
);

export type ImageProps = {
  alt?: string;
  className?: string;
  height?: number;
  src: string;
  width?: number | string;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
};

const Image = (props: ImageProps): JSX.Element => {
  const classes = useStyles();
  return <ImagePure classes={classes} {...props} />;
};

export default Image;
