import { makeStyles } from '@mui/styles';

import { POSTCARD_IMAGE_SIZE } from './PostcardWidget/Front';
export default makeStyles(() => ({
  actionButton: {
    width: 220,
  },
  actionButtonSend: {
    width: 160,
  },
  buttonSmallContainer: {
    height: 75,
  },
  container: {
    marginTop: 80,
  },
  flipCard: {
    perspective: 1200,
  },
  flipCardInner: {
    boxShadow: '2px 3px 10px 3px rgb(0 0 0 / 16%)',
    height: POSTCARD_IMAGE_SIZE.HEIGHT,
    transformStyle: 'preserve-3d',
    transition: 'transform 2s',
    width: POSTCARD_IMAGE_SIZE.WIDTH,
  },
  flipForward: {
    transform: 'rotateY(-180deg)',
  },
  sendButtonContainer: {
    alignItems: 'flex-end',
    display: 'inline-flex',
    height: 'calc(100% - 150px)',
  },
  toolbar: {
    height: '100%',
  },
}));
