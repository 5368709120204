import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { COLORS } from '../../../../../theme/MainTheme';
import { scrollContainer } from '../../../../../utils/Scroll';

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: COLORS.LIGHT_VIOLET,
    borderRadius: 16,
    height: 290,
    padding: 20,
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  content: {
    paddingRight: 10,
    paddingTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  rightContainer: {
    height: 640,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  scrollContainer: {
    ...scrollContainer,

    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
    },
  },
}));
