import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 376px - 62px)',
      minHeight: 450,
      padding: '20px 40px',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px 30px',
    },
  },
}));
