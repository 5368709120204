import { stitchSchemas } from '@graphql-tools/stitch';
import { introspectSchema, wrapSchema } from '@graphql-tools/wrap';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SchemaLink } from '@apollo/client/link/schema';
import { setContext } from '@apollo/client/link/context';
import { GraphQLSchema, print } from 'graphql';
import { HIBOU_URL } from '../utils/constants';
import initMockedData from './mock/initMockedData';
import { schemaExtensionResolvers } from './mock/schema/resolvers';
import mockSchema from './mock/schema/schema';
import { typeExtensions } from './mock/schema/typeDefs';
import Cookies from 'js-cookie';
import { AsyncExecutor } from '@graphql-tools/utils';
import React from 'react';

export const ApolloClientContext = React.createContext<
  ApolloClient<NormalizedCacheObject> | undefined
>(undefined);

const referrerPolicy = 'no-referrer-when-downgrade';

const redirectToLoginPage = (response: Response) => {
  const location = response?.headers?.get('Location');

  if (location) {
    window.location.replace(location);
  }
};

const getInMemoryCache = () =>
  new InMemoryCache({
    typePolicies: {
      Document: {
        fields: {
          sharedWith: {
            merge: (_existing, incoming) => incoming,
          },
        },
      },
    },
  });

const errorLink = (errorHandler: (error: string) => void) =>
  onError(({ graphQLErrors, networkError }) => {
    if (
      graphQLErrors?.find(
        ({ extensions }) => extensions?.code === 'Unauthorized',
      )
    ) {
      console.log('graphQLErrors catched in errorLink', graphQLErrors);
      errorHandler(graphQLErrors.toString());
    } else if (networkError) {
      if (
        'statusCode' in networkError &&
        networkError?.statusCode === 401 &&
        networkError?.response?.headers?.has('Location') === true
      ) {
        redirectToLoginPage(networkError.response);
      } else {
        console.log('networkError catched in errorLink', networkError);
        errorHandler(networkError.toString());
      }
    }
  });

const csrfLink: ApolloLink = setContext((_, { headers }) => {
  return {
    headers: withCSRFHeader(headers),
  };
});

const getLink = (errorHandler: (error: string) => void): ApolloLink => {
  const httpLink = new HttpLink({
    credentials: 'include',
    uri: `${HIBOU_URL}/graphql?is_ajax_request=true`,
    fetchOptions: {
      referrerPolicy,
    },
  });

  return csrfLink.concat(errorLink(errorHandler).concat(httpLink));
};

const defaultHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
};

const withCSRFHeader = (
  headers: Record<string, string>,
): Record<string, string> => {
  const pac4jCsrfToken = Cookies.get('pac4jCsrfToken');
  return {
    ...headers,
    ...(pac4jCsrfToken && { pac4jCsrfToken }),
  };
};

const headersWithCSRFHeader = withCSRFHeader(defaultHeaders);

const adminIntrospectionExecutor: AsyncExecutor = async ({
  document,
  variables,
}) => {
  const query = print(document),
    fetchResult = await fetch(`${HIBOU_URL}/graphql?is_ajax_request=true`, {
      method: 'POST',
      headers: headersWithCSRFHeader,
      credentials: 'include',
      body: JSON.stringify({ query, variables }),
      referrerPolicy,
    }).then((response) => {
      if (response.status === 401) {
        // or handle 400 errors
        redirectToLoginPage(response);
      }
      return response;
    });
  return fetchResult.json();
};

const introspectionExecutor: AsyncExecutor = async ({
  document,
  variables,
}) => {
  const query = print(document),
    response = await fetch(`${HIBOU_URL}/graphql?is_ajax_request=true`, {
      method: 'POST',
      headers: headersWithCSRFHeader,
      credentials: 'include',
      body: JSON.stringify({ query, variables }),
      referrerPolicy,
    });

  if (response.status === 401) {
    redirectToLoginPage(response);
  }

  return response.json();
};

const getClient = (
  mergedSchema: GraphQLSchema,
  errorHandler: (error: string) => void,
): ApolloClient<NormalizedCacheObject> => {
  const schemaLink = new SchemaLink({ schema: mergedSchema });
  return new ApolloClient({
    link: errorLink(errorHandler).concat(schemaLink),
    cache: getInMemoryCache(),
  });
};

const mergeMockedSchema = async (
  errorHandler: (error: string) => void,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  const schema = await introspectSchema(adminIntrospectionExecutor),
    hibouSchema = wrapSchema({ schema, executor: introspectionExecutor }),
    mergedSchema = stitchSchemas({
      resolvers: schemaExtensionResolvers,
      subschemas: [hibouSchema, mockSchema],
      typeDefs: typeExtensions,
    });

  return getClient(mergedSchema, errorHandler);
};

export const initGraphql = (
  errorHandler: (error: string) => void,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  initMockedData();
  const apolloLink = getLink(errorHandler);

  if (process.env.NODE_ENV !== 'production') {
    return mergeMockedSchema(errorHandler);
  } else {
    const apolloClient = new ApolloClient({
      link: apolloLink,
      cache: getInMemoryCache(),
    });

    return Promise.resolve(apolloClient);
  }
};
