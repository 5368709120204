import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    right: '2%',
    position: 'absolute',
    top: '5%',
    zIndex: 1000,
  },
  label: {
    color: COLORS.WHITE,
    textTransform: 'none',
  },
}));
