import { createContext } from 'react';
import { TOUR_STEPS } from '../views/App/OnBoarding/OnBoardingTour';

interface MenuContextProps {
  getMenuSelectedClassNames: (step?: TOUR_STEPS) => string;
  onMenuItemClick: (goToPageFn: Function) => void;
  shouldShowMenu: (step?: number) => boolean;
}

const initialState = {
  getMenuSelectedClassNames: (_step?: TOUR_STEPS) => '',
  onMenuItemClick: (_goToPageFn: Function) => {},
  shouldShowMenu: (_step?: number) => false,
};

const menuContext = createContext<MenuContextProps>(initialState),
  { Provider } = menuContext;

interface MenuContextProviderProps {
  children: React.ReactNode;
  getMenuSelectedClassNames: (step?: TOUR_STEPS) => string;
  onMenuItemClick: (goToPageFn: Function) => void;
  shouldShowMenu: (step?: number) => boolean;
}

const MenuContextProvider = ({
  children,
  getMenuSelectedClassNames,
  onMenuItemClick,
  shouldShowMenu,
}: MenuContextProviderProps): JSX.Element => {
  return (
    <Provider
      value={{ getMenuSelectedClassNames, onMenuItemClick, shouldShowMenu }}
    >
      {children}
    </Provider>
  );
};

export { menuContext, MenuContextProvider };
