import { createCtxWithMandatoryValue } from '../../../../../../Context/utils';
import { PartnerContentType } from '../../../../../../types/partnerdocuments/PartnerDocument';
import { StateSetter } from '../../../../../../types/utils/React';
import { PartnerAccountType } from '../../../../../../types/partnerdocuments/PartnerAccount';

interface PartnerContentContextProps {
  contents: PartnerContentType[];
  partnerAccount: PartnerAccountType;
  selectedContent: PartnerContentType;
  setSelectedContent: StateSetter<PartnerContentType | undefined>;
}

const partnerContentContext =
    createCtxWithMandatoryValue<PartnerContentContextProps>(),
  [usePartnerContentContext, PartnerContentContextProvider] =
    partnerContentContext;

export { usePartnerContentContext, PartnerContentContextProvider };
