import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import useStyles from './RelationshipManagement.styles';
import OnBoardingReplayButton from './OnBoardingReplay/OnBoardingReplayButton';

const OnBoardingReplay = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography color="textPrimary" variant="h2">
        <FormattedMessage id="profile.on_boarding_replay.title" />
      </Typography>
      <OnBoardingReplayButton />
    </div>
  );
};

export default OnBoardingReplay;
