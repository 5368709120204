import { ReactNode } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import MainTheme from './theme/MainTheme';
import { IntlProvider } from 'react-intl';
import { fr } from './locale/fr';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './components/Error/ErrorPage';
import { AlertContextProvider } from './components/Alert/AlertContext';
import AlertContainer from './components/Alert/AlertContainer';

type ProvidersProps = {
  children: ReactNode;
};

const Providers = ({ children }: ProvidersProps): JSX.Element => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={MainTheme}>
      <IntlProvider
        defaultLocale={fr.locale}
        defaultRichTextElements={{
          br: () => <br />,
        }}
        locale={fr.locale}
        messages={fr.messages}
      >
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <AlertContextProvider>
            <AlertContainer />

            {children}
          </AlertContextProvider>
        </ErrorBoundary>
      </IntlProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Providers;
