import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';
import useStyles from './ValidateButton.styles';
import { shareContext } from '../../ShareContext';
import { arrayEquals } from '../../../../../utils/Array';
import { useMutation } from '@apollo/client';
import {
  SHARE_FOLDER_MUTATION,
  ShareFolderMutationOutputType,
  UNSHARE_FOLDER_MUTATION,
  UnshareFolderMutationOutputType,
} from '../../../../../graphql/document/DocumentMutations';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { alertContext } from '../../../../../components/Alert/AlertContext';
import { editContactsContext } from './EditContactsContext';
import { getHandleValidateFunction, updateCache } from './EditContactsHelpers';

type ValidateButtonPureProps = {
  disabled: boolean;
  handleValidate: () => void;
};

const ValidateButtonPure = ({
  disabled,
  handleValidate,
}: ValidateButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FilledButton
      className={classes.button}
      color="violet"
      data-test-id="validate-contacts-edit-button"
      disabled={disabled}
      onClick={handleValidate}
    >
      <FormattedMessage id="button.validate" />
    </FilledButton>
  );
};

const ValidateButton = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const {
    selectedFileType,
    selectedFolder,
    setLoadingMessage,
    setSelectedFolder,
  } = useContext(shareContext);
  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);
  const { contacts, setShowEditContactsModal } =
    useContext(editContactsContext);

  const initialContacts = (selectedFolder?.sharedWith || []).map((_) => _.id);
  const contactsHasChanged = !arrayEquals<string>(contacts, initialContacts);
  const addedContacts = contacts.filter((_) => !initialContacts.includes(_));
  const removedContacts = initialContacts.filter((_) => !contacts.includes(_));

  const [shareFolder] = useMutation<ShareFolderMutationOutputType>(
    SHARE_FOLDER_MUTATION,
    {
      update(cache, { data }) {
        updateCache({
          cache,
          folder: data?.shareFolder.folder,
          selectedFileType,
          selectedLink,
        });
      },
    },
  );

  const [unshareFolder] = useMutation<UnshareFolderMutationOutputType>(
    UNSHARE_FOLDER_MUTATION,
    {
      update(cache, { data }) {
        updateCache({
          cache,
          folder: data?.unshareFolder.folder,
          selectedFileType,
          selectedLink,
        });
      },
    },
  );

  const handleErrorResult = () => {
    showErrorMessage(<FormattedMessage id={`share.folder.share.error`} />);
    setLoadingMessage(undefined);
  };

  const handleValidateFunction = getHandleValidateFunction({
    addedContacts,
    removedContacts,
    selectedFolder,
    selectedLink,
    shareFolder,
    handleErrorResult,
    setLoadingMessage,
    setSelectedFolder,
    setShowEditContactsModal,
    showSuccessMessage,
    unshareFolder,
  });

  const handleValidate = useCallback(handleValidateFunction, [
    addedContacts,
    removedContacts,
    selectedFolder,
    selectedLink,
    shareFolder,
    handleErrorResult,
    handleValidateFunction,
    setLoadingMessage,
    setSelectedFolder,
    setShowEditContactsModal,
    showSuccessMessage,
    unshareFolder,
  ]);

  return (
    <ValidateButtonPure
      disabled={!contactsHasChanged}
      handleValidate={handleValidate}
    />
  );
};

export default ValidateButton;
