import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  buttonsContainer: {
    paddingTop: 20,
  },
  description: {
    fontSize: '2em',
    lineHeight: '1.4em',
    margin: 'auto',
    maxWidth: '70%',
    paddingTop: 50,
    textAlign: 'center',
  },
  exitButton: {
    color: COLORS.VIOLET,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'bold',
    padding: '20px 30px',
  },
  fullScreenContainer: {
    alignItems: 'center',
    background: COLORS.WHITE,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  },
  subtitle: {
    fontWeight: 'bold',
  },

  textFieldContainer: {
    margin: 'auto',
    maxWidth: '60%',
    paddingTop: 50,
  },
  title: {
    color: COLORS.VIOLET,
    fontSize: '4em',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  validateButton: {
    fontSize: 16,
    textTransform: 'capitalize',
    width: 160,
  },
}));
