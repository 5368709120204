import { FormattedMessage } from 'react-intl';
import FileGrid, {
  FileGridType,
} from '../../../../components/FileGrid/FileGrid';

const TUTORIALS_PATH_PREFIX = '/pdf/help/family-portal-tutorials';

const tabletTutorials: Array<FileGridType> = [
  {
    fileName: `Découvrez le site Famille Ardoiz.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.family-portal-tutorials.discover" />
    ),
  },
  {
    fileName: `Passez des appels visio avec Ardoiz Visio.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.family-portal-tutorials.visio" />
    ),
  },
];

export const FamilyPortalTutorials = (): JSX.Element => (
  <FileGrid
    files={tabletTutorials}
    pageTitle="category.help.documents.family_portal_tutorials"
  />
);

export default FamilyPortalTutorials;
