import AddDocumentButton from '../../../../views/App/Share/ExplorerToolbar/AddDocument/AddDocumentButton';
import EditContactsButton from '../../../../views/App/Share/ExplorerToolbar/EditContacts/EditContactsButton';
import DeleteDocumentButton from '../../../../views/App/Share/ExplorerToolbar/DeleteFiles/DeleteDocumentButton';
import DeleteOrQuitFolderButton from '../../../../views/App/Share/ExplorerToolbar/SettingsModal/DeleteOrQuitFolderButton';
import MobileExplorerToolbar from '../MobileExplorerToolbar';

const MobileFolderContentSettings = (): JSX.Element => {
  const visibleComponents = [
    <AddDocumentButton key="AddDocumentButton" />,
    <EditContactsButton key="EditContactsButton" />,
    <DeleteDocumentButton key="DeleteDocumentButton" />,
    <DeleteOrQuitFolderButton key="DeleteOrQuitFolderButton" />,
  ];

  return <MobileExplorerToolbar visibleComponents={visibleComponents} />;
};

export default MobileFolderContentSettings;
