import { useContext } from 'react';
import HangUpButton from './ControlToolbar/HangUpButton';
import useStyles from './ControlToolbar.styles';
import { Grid } from '@mui/material';
import ToggleMicButton from './ControlToolbar/ToggleMicButton';
import ToggleCameraButton from './ControlToolbar/ToggleCameraButton';
import { VideoCallState } from '../../../../../types/videocall/VideoCall';
import { videoCallContext } from '../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { isSelfLink } from '../../../../../utils/Link';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import clsx from 'clsx';
import { ControlToolbarContextProvider } from '../../../../../Context/Communicate/VideoCall/VideoContent/ControlToolbarContext';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import FullScreenButton from './ControlToolbar/FullScreenButton';

type ControlToolbarPureProps = {
  small: boolean;
};

const ControlToolbarPure = ({
  small,
}: ControlToolbarPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.divContainer}>
      <Grid
        alignItems="flex-end"
        className={clsx(classes.container, small && 'small')}
        container
        justifyContent="center"
      >
        <HangUpButton />

        <Grid className={classes.item} item>
          <ToggleMicButton />
        </Grid>

        <Grid className={classes.item} item>
          <ToggleCameraButton />
        </Grid>

        <FullScreenButton containerClassName={classes.item} />
      </Grid>
    </div>
  );
};

const ShowableVideoCallStates: VideoCallState[] = [
  VideoCallState.Initial,
  VideoCallState.WaitingNotificationAcknowledge,
  VideoCallState.WaitingCalledAnswer,
  VideoCallState.WaitingCallStart,
  VideoCallState.Running,
];

export type ControlToolbarPropsType = {
  containerRef: React.RefObject<HTMLDivElement>;
};

const ControlToolbar = ({
  containerRef,
}: ControlToolbarPropsType): JSX.Element | null => {
  const { isFullScreen, videoCall } = useContext(videoCallContext);
  const { selectedLink } = useSelectedLinkContext();
  const isSmallScreen = useIsSmallScreen();
  const small = isSmallScreen || (!isFullScreen && !isSelfLink(selectedLink));

  if (
    !videoCall ||
    !ShowableVideoCallStates.includes(videoCall.command.currentState)
  ) {
    return null;
  }

  return (
    <ControlToolbarContextProvider value={{ containerRef, small }}>
      <ControlToolbarPure small={small} />
    </ControlToolbarContextProvider>
  );
};

export default ControlToolbar;
