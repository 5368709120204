import { ReactNode } from 'react';
import useStyles from './MenuBackground.styles';
import { Grid } from '@mui/material';
import { useAppContext } from '../../../Context/AppContext';

type MenuBackgroundProps = {
  children: ReactNode;
};

const MenuBackground = ({ children }: MenuBackgroundProps): JSX.Element => {
  const classes = useStyles();
  const { isAdminMode } = useAppContext();
  const menuClassName = isAdminMode ? classes.adminMenu : classes.menu;

  return (
    <Grid item className={classes.menuBg}>
      <div className={menuClassName}>{children}</div>
    </Grid>
  );
};

export default MenuBackground;
