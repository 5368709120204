import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import useStyles from './RelationshipManagement.styles';
import AddRelationship from './RelationshipManagement/AddRelationship';
import RelationshipList from './RelationshipManagement/RelationshipList';

const RelationshipManagement = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography color="textPrimary" variant="h2">
        <FormattedMessage id="profile.relationship_management.title" />
      </Typography>

      <Grid className={classes.gridContainer} container>
        <Grid item md={2} />
        <Grid className={classes.gridItem} item md={10}>
          <div className={classes.itemBackground}>
            <RelationshipList />
          </div>
        </Grid>

        <Grid item md={2} />
        <Grid className={classes.gridItem} item md={10}>
          <div className={classes.itemBackground}>
            <AddRelationship />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default RelationshipManagement;
