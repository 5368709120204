import { useContext } from 'react';
import { Paper } from '@mui/material';
import MonthSelector from './MonthSelector';
import useStyles from './Calendar.styles';
import DayHeader from './DayHeader';
import CalendarContent from './CalendarContent';
import { calendarContext } from './CalendarContext';

const Calendar = (): JSX.Element => {
  const classes = useStyles();
  const { disabled } = useContext(calendarContext);

  return (
    <Paper className={classes.container}>
      <MonthSelector />

      <table className={classes.calendar}>
        <tbody>
          <DayHeader />

          <CalendarContent />
        </tbody>
      </table>

      {disabled && <div className={classes.mask} />}
    </Paper>
  );
};

export default Calendar;
