import { useEffect, useState } from 'react';
import useStyles from './Loading.styles';
import { TimeoutType } from '../../types/utils/Timeout';
import { FormattedMessage } from 'react-intl';

type LoadingPureProps = {
  dotsText: string;
};

const LoadingPure = ({ dotsText }: LoadingPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <FormattedMessage id="loading" />
        <span id="loading-text-dots">{dotsText}</span>
      </div>

      <div className={classes.image} />
    </div>
  );
};

const Loading = (): JSX.Element => {
  const [numberOfDots, setNumberOfDots] = useState(3);
  const [handler, setHandler] = useState<TimeoutType>();

  useEffect(
    () => {
      clearInterval(handler as NodeJS.Timeout);
      setHandler(
        setInterval(() => {
          if (numberOfDots === 3) {
            setNumberOfDots(0);
          } else {
            setNumberOfDots(numberOfDots + 1);
          }
        }, 500),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numberOfDots, setHandler, setNumberOfDots],
  );

  const dotsText = '.'.repeat(numberOfDots);

  return <LoadingPure dotsText={dotsText} />;
};

export default Loading;
