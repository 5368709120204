import { ApolloCache } from '@apollo/client';
import { FolderType } from '../../../../../types/document/Document';
import {
  GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
  GetVisibleFoldersFromFolderTypeQueryOutputType,
} from '../../../../../graphql/document/DocumentQueries';
import { LinkType } from '../../../../../types/Link';
import { FileTypeSwitcherFileTypes } from '../../../../../types/share/FileTypeSwitcherFileTypes';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { MutationFunction } from '../../../../../types/utils/GraphQL';
import {
  ShareFolderMutationOutputType,
  UnshareFolderMutationOutputType,
} from '../../../../../graphql/document/DocumentMutations';
import { ShowMessageFunctionType } from '../../../../../components/Alert/AlertContext';
import { StateSetter } from '../../../../../types/utils/React';
import { trackEvent } from '../../../../../GoogleAnalytics/GATracker';
import {
  GAEventCategory,
  GAShareEventActions,
} from '../../../../../GoogleAnalytics/GAEvent';

type UpdateCacheInputType<T> = {
  cache: ApolloCache<T>;
  folder?: FolderType;
  selectedFileType?: FileTypeSwitcherFileTypes;
  selectedLink?: LinkType;
};

export const updateCache = <T,>({
  cache,
  folder,
  selectedFileType,
  selectedLink,
}: UpdateCacheInputType<T>): void => {
  if (folder) {
    const variables = {
      getVisibleFoldersByFolderTypeInput: {
        channel: selectedLink?.channelKey,
        folderType: selectedFileType,
      },
    };

    const data =
      cache.readQuery<GetVisibleFoldersFromFolderTypeQueryOutputType>({
        query: GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
        variables,
      });

    const updatedData = {
      ...data,
      visibleFoldersFromFolderType: {
        ...data?.visibleFoldersFromFolderType,
        folders: data?.visibleFoldersFromFolderType.folders.map((_) =>
          _.id === folder.id ? folder : _,
        ),
      },
    };

    cache.writeQuery({
      data: updatedData,
      query: GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
      variables,
    });
  }
};

type GetHandleValidateFunctionInputType = {
  addedContacts: string[];
  removedContacts: string[];
  selectedFolder?: FolderType;
  selectedLink?: LinkType;
  shareFolder: MutationFunction<ShareFolderMutationOutputType>;
  handleErrorResult: () => void;
  setLoadingMessage: StateSetter<ReactNode | undefined>;
  setSelectedFolder: StateSetter<FolderType | undefined>;
  setShowEditContactsModal: StateSetter<boolean>;
  showSuccessMessage: ShowMessageFunctionType;
  unshareFolder: MutationFunction<UnshareFolderMutationOutputType>;
};

export const getHandleValidateFunction =
  ({
    addedContacts,
    removedContacts,
    selectedFolder,
    selectedLink,
    shareFolder,
    handleErrorResult,
    setLoadingMessage,
    setSelectedFolder,
    setShowEditContactsModal,
    showSuccessMessage,
    unshareFolder,
  }: GetHandleValidateFunctionInputType) =>
  (): void => {
    const handleMutationError = (error: any) => {
      console.error(error);
      handleErrorResult();
    };

    setShowEditContactsModal(false);
    setLoadingMessage(<FormattedMessage id="share.document.add.loading" />);

    shareFolder({
      variables: {
        shareFolderInput: {
          channel: selectedLink?.channelKey,
          folderKey: selectedFolder?.key,
          otherWebUserIds: addedContacts,
        },
      },
    })
      .then(({ data: shareData }) => {
        if (shareData?.shareFolder?.errorReason) {
          handleErrorResult();
        } else {
          trackEvent(
            GAEventCategory.SHARE,
            GAShareEventActions.EDIT_SHARED_FOLDER_ACCESS,
            `${addedContacts.length} contact(s) ajouté(s)`,
          );

          unshareFolder({
            variables: {
              unshareFolderInput: {
                channel: selectedLink?.channelKey,
                folderKey: selectedFolder?.key,
                otherWebUserIds: removedContacts,
              },
            },
          })
            .then(({ data: unShareData }) => {
              if (unShareData?.unshareFolder?.errorReason) {
                handleErrorResult();
              } else {
                trackEvent(
                  GAEventCategory.SHARE,
                  GAShareEventActions.EDIT_SHARED_FOLDER_ACCESS,
                  `${removedContacts.length} contact(s) retiré(s)`,
                );

                showSuccessMessage(
                  <FormattedMessage id="share.folder.share.success" />,
                );

                setSelectedFolder(unShareData?.unshareFolder.folder);
                setLoadingMessage(undefined);
              }
            })
            .catch(handleMutationError);
        }
      })
      .catch(handleMutationError);
  };
