import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    backgroundColor: COLORS.BLUE,
    borderRadius: 14,
    float: 'right',
    padding: '2px 4px',
  },
  icon: {
    width: 20,
  },
}));
