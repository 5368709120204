import { useContext } from 'react';
import { eventFormContext } from '../EventFormContext';
import { Moment } from 'moment';
import { StateSetter } from '../../../../../../types/utils/React';
import DatePicker from '../../../../../../components/DatePicker/DatePicker';
import useStyles from './UntilDatePicker.styles';
import EventFormTextField from '../EventFormTextField';
import { RecurrenceTypeEnum } from '../../../../../../types/event/Event';

type UntilDatePickerPureProps = {
  disabled: boolean;
  recurrenceUntilDate: Moment | null;
  setRecurrenceUntilDate: StateSetter<Moment | null>;
};

const UntilDatePickerPure = ({
  disabled,
  recurrenceUntilDate,
  setRecurrenceUntilDate,
}: UntilDatePickerPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <DatePicker
        date={recurrenceUntilDate}
        renderInput={(params) => {
          const textFieldProps = {
            ...params,
            inputProps: {
              ...params.inputProps,
              readOnly: true,
            },
            InputProps: {
              ...params.InputProps,
              endAdornment: disabled
                ? undefined
                : params.InputProps?.endAdornment,
            },
          };

          return (
            <EventFormTextField
              className={classes.input}
              formProperty={'recurrenceUntilDate'}
              textFieldProps={textFieldProps}
              value={recurrenceUntilDate}
              setValue={setRecurrenceUntilDate}
            />
          );
        }}
        setDate={setRecurrenceUntilDate}
      />

      {!disabled && <sup className={classes.sup}>*</sup>}
    </>
  );
};

const UntilDatePicker = (): JSX.Element | null => {
  const {
    disabled,
    recurrenceUntilDate,
    recurrenceType,
    setRecurrenceUntilDate,
  } = useContext(eventFormContext);

  if (recurrenceType !== RecurrenceTypeEnum.UNTIL) {
    return null;
  }

  return (
    <UntilDatePickerPure
      disabled={disabled}
      recurrenceUntilDate={recurrenceUntilDate}
      setRecurrenceUntilDate={setRecurrenceUntilDate}
    />
  );
};

export default UntilDatePicker;
