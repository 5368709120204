import { ReactNode, useContext } from 'react';
import useStyles from './Content.styles';
import { tourContext } from '../../Context/TourContext';
import clsx from 'clsx';

type ContentPureProps = {
  children: ReactNode;
  isTourOpen: boolean;
};

const ContentPure = ({
  children,
  isTourOpen,
}: ContentPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, isTourOpen && classes.hidden)}>
      {children}
    </div>
  );
};

type ContentProps = {
  children: ReactNode;
};

const Content = ({ children }: ContentProps): JSX.Element | null => {
  const { isTourOpen } = useContext(tourContext);

  return <ContentPure isTourOpen={isTourOpen}>{children}</ContentPure>;
};

export default Content;
