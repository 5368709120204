import { FunctionComponent, ReactNode } from 'react';
import useStyles from './PageContentContainer.styles';
import clsx from 'clsx';

export type PageContentContainerProps = {
  children: ReactNode;
  className?: string;
};

const PageContentContainer: FunctionComponent<PageContentContainerProps> = ({
  className,
  children,
}): JSX.Element => {
  const classes = useStyles();
  return <div className={clsx([classes.container, className])}>{children}</div>;
};

export default PageContentContainer;
