import { gql } from '@apollo/client';
import { FolderType, SharedDocumentType } from '../../types/document/Document';
import { DOCUMENT_FRAGMENT, FOLDER_FRAGMENT } from './DocumentQueries';

export const CREATE_FOLDER_MUTATION = gql`
  mutation CreateFolderMutation($createFolderInput: CreateFolderInput!) {
    createFolder(createFolderInput: $createFolderInput) {
      errorReason
      folder {
        ...folderFields
      }
    }
  }
  ${FOLDER_FRAGMENT}
`;

export enum CreateFolderError {
  DUPLICATE_FOLDERNAME = 'DUPLICATE_FOLDERNAME',
  NOT_LINKED = 'NOT_LINKED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type CreateFolderMutationOutputType = {
  createFolder: {
    errorReason: CreateFolderError;
    folder: FolderType;
  };
};

export const ADD_DOCUMENT_MUTATION = gql`
  mutation AddDocumentMutation($addDocumentInput: AddDocumentInput!) {
    addDocument(addDocumentInput: $addDocumentInput) {
      errorReason
      document {
        ...documentFields
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export enum AddDocumentError {
  DUPLICATE_FILENAME = 'DUPLICATE_FILENAME',
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  TEMP_BLOB_NOT_FOUND = 'TEMP_BLOB_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type AddDocumentMutationOutputType = {
  addDocument: {
    errorReason: AddDocumentError;
    document: SharedDocumentType;
  };
};

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocumentMutation($deleteDocumentInput: DeleteDocumentInput!) {
    deleteDocument(deleteDocumentInput: $deleteDocumentInput) {
      errorReason
      isDeleted
    }
  }
`;

export enum DeleteDocumentError {
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  NOT_PARENT_FOLDER = 'NOT_PARENT_FOLDER',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type DeleteDocumentMutationOutputType = {
  deleteDocument: {
    errorReason: DeleteDocumentError;
    isDeleted: boolean;
  };
};

export const DELETE_FOLDER_MUTATION = gql`
  mutation DeleteFolderMutation($deleteFolderInput: DeleteFolderInput!) {
    deleteFolder(deleteFolderInput: $deleteFolderInput) {
      errorReason
      isDeleted
    }
  }
`;

export enum DeleteFolderError {
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type DeleteFolderMutationOutputType = {
  deleteFolder: {
    errorReason: DeleteFolderError;
    isDeleted: boolean;
  };
};

export const SELF_UNSUBSCRIBE_MUTATION = gql`
  mutation SelfUnsubscribeMutation(
    $selfUnsubscribeInput: SelfUnsubscribeInput!
  ) {
    selfUnsubscribe(selfUnsubscribeInput: $selfUnsubscribeInput) {
      errorReason
      folder {
        ...folderFields
      }
    }
  }
  ${FOLDER_FRAGMENT}
`;

export enum SelfUnsubscribeError {
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type SelfUnsubscribeMutationOutputType = {
  selfUnsubscribe: {
    errorReason: SelfUnsubscribeError;
    folder: FolderType;
  };
};

export const SHARE_FOLDER_MUTATION = gql`
  mutation ShareFolderMutation($shareFolderInput: ShareFolderInput!) {
    shareFolder(shareFolderInput: $shareFolderInput) {
      errorReason
      folder {
        ...folderFields
      }
    }
  }
  ${FOLDER_FRAGMENT}
`;

export enum ShareFolderError {
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHORIZED_OTHER_USERS = 'UNAUTHORIZED_OTHER_USERS',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type ShareFolderMutationOutputType = {
  shareFolder: {
    errorReason: ShareFolderError;
    folder: FolderType;
  };
};

export const UNSHARE_FOLDER_MUTATION = gql`
  mutation UnshareFolderMutation($unshareFolderInput: UnshareFolderInput!) {
    unshareFolder(unshareFolderInput: $unshareFolderInput) {
      errorReason
      folder {
        ...folderFields
      }
    }
  }
  ${FOLDER_FRAGMENT}
`;

export enum UnshareFolderError {
  NOT_A_FOLDER = 'NOT_A_FOLDER',
  NOT_LINKED = 'NOT_LINKED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHORIZED_OTHER_USERS = 'UNAUTHORIZED_OTHER_USERS',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type UnshareFolderMutationOutputType = {
  unshareFolder: {
    errorReason: UnshareFolderError;
    folder: FolderType;
  };
};
