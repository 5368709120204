import { getPersonFullName, WebUserContactType } from '../types/User';
import { stringToColor } from './String';
import { AvatarProps } from '@mui/material/Avatar/Avatar';

export const getAvatarProps = (contact: WebUserContactType): AvatarProps => {
  const initials = contact.firstName.charAt(0) + contact.lastName.charAt(0);

  return {
    children: initials.toUpperCase(),
    sx: {
      bgcolor: stringToColor(getPersonFullName(contact)),
    },
  };
};
