import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  calendar: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: '100%',
  },
  container: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    boxShadow: '2px 3px 10px 3px rgb(0 0 0 / 16%)',
    position: 'relative',
  },
  mask: {
    backgroundColor: COLORS.WHITE,
    height: '100%',
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));
