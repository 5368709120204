import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    alignItems: 'center',
    color: COLORS.WHITE,
    display: 'flex',
    fontSize: 18,
    height: 42,
  },
  fullName: {
    fontFamily: adminFontFamily,
    fontWeight: 'bold',
  },
  noWebUserLink: {
    color: COLORS.WHITE,
    fontFamily: adminFontFamily,
  },
  select: {
    height: 42,

    '& .MuiSelect-select': {
      color: COLORS.WHITE,
      fontFamily: adminFontFamily,
      fontSize: 17,
      fontWeight: 500,
    },
    '& .MuiSelect-icon': {
      color: COLORS.WHITE,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));
