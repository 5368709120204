import { useCallback, useContext } from 'react';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { shareContext } from '../../ShareContext';
import { useMutation } from '@apollo/client';
import {
  DELETE_FOLDER_MUTATION,
  DeleteFolderMutationOutputType,
  SELF_UNSUBSCRIBE_MUTATION,
  SelfUnsubscribeMutationOutputType,
} from '../../../../../graphql/document/DocumentMutations';
import { alertContext } from '../../../../../components/Alert/AlertContext';
import { settingsContext } from './SettingsContext';
import {
  getHandleValidateFunction,
  getModalMessagesId,
  getUpdateCacheFunction,
} from './DeleteOrQuitFolderHelpers';
import { Grid, Paper } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';
import useStyles from '../Modals.styles';
import useDeleteOrQuitFolderModalStyles from './DeleteOrQuitFolderConfirmationModal.styles';

type DeleteOrQuitFolderConfirmationModalPureProps = {
  descriptionMessageId: string;
  titleMessageId: string;
  handleHide: () => void;
  handleValidate: () => void;
};

export const DeleteOrQuitFolderConfirmationModalPure = ({
  descriptionMessageId,
  titleMessageId,
  handleHide,
  handleValidate,
}: DeleteOrQuitFolderConfirmationModalPureProps): JSX.Element => {
  const classes = useStyles();
  const deleteOrQuitFolderModalStyles = useDeleteOrQuitFolderModalStyles();

  return (
    <div className={classes.container}>
      <Paper className={deleteOrQuitFolderModalStyles.modal}>
        <FilledButton
          className={deleteOrQuitFolderModalStyles.cancelButton}
          color={'red'}
          data-test-id="cancel-button"
          onClick={handleHide}
        >
          <FormattedMessage id="button.cancel" />
        </FilledButton>

        <Grid className={deleteOrQuitFolderModalStyles.content} container>
          <Grid className={classes.text} item xs={12}>
            <FormattedMessage id={titleMessageId} />
          </Grid>

          <Grid
            className={deleteOrQuitFolderModalStyles.description}
            item
            xs={12}
          >
            <FormattedMessage id={descriptionMessageId} />
          </Grid>

          <Grid
            className={deleteOrQuitFolderModalStyles.validateButtonContainer}
            item
            xs={12}
          >
            <FilledButton
              className={deleteOrQuitFolderModalStyles.validateButton}
              color="violet"
              data-test-id="delete-or-quit-folder-button"
              onClick={handleValidate}
            >
              <FormattedMessage id={titleMessageId} />
            </FilledButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const DeleteOrQuitFolderConfirmationModal = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { selectedFileType, selectedFolder, setSelectedFolder } =
    useContext(shareContext);
  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);
  const { showDeleteOrQuitModal, setShowDeleteOrQuitModal } =
    useContext(settingsContext);

  const isFolderAdmin = selectedFolder?.creator === selectedLink?.userKey;

  const updateCache = getUpdateCacheFunction({
    selectedFileType,
    selectedFolder,
    selectedLink,
    setSelectedFolder,
    setShowDeleteOrQuitModal,
  });

  const [deleteFolder] = useMutation<DeleteFolderMutationOutputType>(
    DELETE_FOLDER_MUTATION,
    {
      update(cache, { data }) {
        const isDeleted = data?.deleteFolder.isDeleted;

        if (isDeleted) {
          updateCache(cache);
        }
      },
    },
  );

  const [selfUnsubscribe] = useMutation<SelfUnsubscribeMutationOutputType>(
    SELF_UNSUBSCRIBE_MUTATION,
    {
      update(cache, { data }) {
        const folder = data?.selfUnsubscribe.folder;

        if (folder) {
          updateCache(cache);
        }
      },
    },
  );

  const handleValidateFunction = getHandleValidateFunction({
    isFolderAdmin,
    selectedLink,
    selectedFolder,
    deleteFolder,
    selfUnsubscribe,
    showErrorMessage,
    showSuccessMessage,
  });

  const handleValidate = useCallback(handleValidateFunction, [
    isFolderAdmin,
    selectedLink,
    selectedFolder,
    deleteFolder,
    handleValidateFunction,
    selfUnsubscribe,
    showErrorMessage,
    showSuccessMessage,
  ]);

  const handleHide = useCallback(
    () => setShowDeleteOrQuitModal(false),
    [setShowDeleteOrQuitModal],
  );

  if (!showDeleteOrQuitModal) {
    return null;
  }

  const { descriptionMessageId, titleMessageId } = getModalMessagesId({
    isFolderAdmin,
  });

  return (
    <DeleteOrQuitFolderConfirmationModalPure
      descriptionMessageId={descriptionMessageId}
      titleMessageId={titleMessageId}
      handleHide={handleHide}
      handleValidate={handleValidate}
    />
  );
};

export default DeleteOrQuitFolderConfirmationModal;
