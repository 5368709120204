import { useEffect } from 'react';
import { usePartnerPublicationsContext } from './PartnerPublicationsContext';
import { useQuery } from '@apollo/client';
import {
  GET_PARTNER_CONTENTS_QUERY,
  GetPartnerContentsOutputType,
} from '../../../../../graphql/partnerdocuments/PartnerDocumentsQueries';
import PartnerListContainer from './PartnerListContainer';
import { Grid } from '@mui/material';
import PartnerContentItem from './PartnerContent/PartnerContentItem';
import { sortContentsByPublicationStartDate } from '../../../../../utils/Document';
import { PartnerContentType } from '../../../../../types/partnerdocuments/PartnerDocument';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';

type PartnerContentListPureProps = {
  contents: PartnerContentType[];
};

const PartnerContentListPure = ({
  contents,
}: PartnerContentListPureProps): JSX.Element => (
  <PartnerListContainer
    isRightContainer
    titleMessageId="partner.publications.content.title"
  >
    <Grid container spacing={3}>
      {contents.map((_) => (
        <Grid item key={_.id} xs={12}>
          <PartnerContentItem content={_} />
        </Grid>
      ))}
    </Grid>
  </PartnerListContainer>
);

const PartnerContentList = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { contents, partnerAccount, setContents } =
    usePartnerPublicationsContext();

  const { data, loading } = useQuery<GetPartnerContentsOutputType>(
    GET_PARTNER_CONTENTS_QUERY,
    {
      variables: {
        getPartnerContentsInput: {
          partnerId: partnerAccount.id,
          tabletChannel: selectedLink?.channelKey,
        },
      },
    },
  );

  const retrievedContents = data?.getPartnerContents.contents || [];
  const filteredAndSortedContents = sortContentsByPublicationStartDate([
    ...retrievedContents,
  ]);

  useEffect(() => {
    if (filteredAndSortedContents?.length > 0 && contents.length === 0) {
      setContents(filteredAndSortedContents);
    }
  }, [contents.length, filteredAndSortedContents, setContents]);

  if (loading) {
    return null;
  }

  return <PartnerContentListPure contents={filteredAndSortedContents} />;
};

export default PartnerContentList;
