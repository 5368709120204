import { useCallback, useContext, useEffect, useState } from 'react';
import FilledButton from '../../../../../../components/Button/FilledButton';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { emptyFunction } from '../../../../../../utils/Functions';
import { isSelfLink } from '../../../../../../utils/Link';
import { useSelectedLinkContext } from '../../../../../../Context/SelectedLinkContext';
import { useControlToolbarContext } from '../../../../../../Context/Communicate/VideoCall/VideoContent/ControlToolbarContext';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import Image from '../../../../../../components/Image/Image';
import useStyles from './CommonButton.styles';
import FullScreenOnImage from '../../../../../../images/Communicate/VideoCall/fullscreen_on.png';
import FullScreenOffImage from '../../../../../../images/Communicate/VideoCall/fullscreen_off.png';
import { trackEvent } from '../../../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../../../GoogleAnalytics/GAEvent';

type FullScreenButtonPropsType = {
  containerClassName: string;
};

const FullScreenButton = ({
  containerClassName,
}: FullScreenButtonPropsType): JSX.Element | null => {
  const { containerRef } = useControlToolbarContext();
  const container = containerRef.current;
  const { isFullScreen, isPreview, setIsFullScreen } =
    useContext(videoCallContext);
  const [hasListener, setHasListener] = useState<boolean>(false);
  const { selectedLink } = useSelectedLinkContext();
  const classes = useStyles();
  const { small } = useControlToolbarContext();

  const image = isFullScreen ? FullScreenOffImage : FullScreenOnImage;
  const imageWidth = small ? 24 : 36;
  const label = isFullScreen ? (
    <FormattedMessage id="videoCall.fullscreen.deactivated" />
  ) : (
    <FormattedMessage id="videoCall.fullscreen.activated" />
  );

  const handleClick = useCallback(() => {
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.CLICK,
      isFullScreen ? 'Mode réduit' : 'Mode plein écran',
    );

    if (!isFullScreen) {
      if (container) {
        container.requestFullscreen().then(emptyFunction);
      }
    } else {
      document.exitFullscreen().then(emptyFunction);
    }
  }, [container, isFullScreen]);

  useEffect(() => {
    if (container && !hasListener) {
      container.addEventListener('fullscreenchange', () =>
        setIsFullScreen(!!document.fullscreenElement),
      );

      setHasListener(true);
    }
  }, [container, hasListener, setHasListener, setIsFullScreen]);

  if (!container || isPreview || isSelfLink(selectedLink)) {
    return null;
  }

  return (
    <Grid className={containerClassName} item>
      <Grid container direction="column">
        <Grid className={clsx(classes.label, small && 'small')} item>
          {label}
        </Grid>
        <Grid item>
          <FilledButton
            className={clsx(classes.button, small && 'small')}
            color="white"
            onClick={handleClick}
          >
            <Image src={image} width={imageWidth} />
          </FilledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FullScreenButton;
