import { ReactNode } from 'react';
import PageContentHeader from '../PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import PageContentContainer from '../PageContent/PageContentContainer';
import { LEGACY_FAMILY_PORTAL_URL } from '../../utils/constants';
import { Grid, Typography } from '@mui/material';
import BuildingPageImage from '../../images/components/BuildingPage/building-page.png';
import Image from '../Image/Image';
import useStyles from './BuildingPage.styles';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';

export type BuildingPageProps = {};

const BuildingPage = (): JSX.Element => {
  const classes = useStyles();

  return (
    <PageContentContainer>
      <PageContentHeader
        title={<FormattedMessage id="building_page.title" />}
      />

      <Grid
        alignItems="center"
        className={classes.container}
        container
        justifyContent="center"
      >
        <Grid className={classes.textContainer} item md={6}>
          <Typography variant="subtitle1">
            <FormattedMessage id="building_page.subtitle1" />
          </Typography>

          <Typography className={classes.text} variant="body1">
            <FormattedMessage id="building_page.text1" />
          </Typography>

          <Typography className={classes.subtitle2} variant="subtitle1">
            <FormattedMessage id="building_page.subtitle2" />
          </Typography>

          <Typography className={classes.text} variant="body1">
            <FormattedMessage
              id="building_page.text2"
              values={{
                a: (parts: ReactNode[]) => (
                  <>
                    <a
                      href={LEGACY_FAMILY_PORTAL_URL}
                      onClick={() =>
                        trackEvent(
                          GAEventCategory.LINK,
                          GACommonEventAction.CLICK,
                          'Ancien site famille',
                        )
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {parts}
                    </a>
                  </>
                ),
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Image src={BuildingPageImage} width="100%" />
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};

export default BuildingPage;
