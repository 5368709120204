import { useContext } from 'react';
import { postcardContext } from '../../../../../Context/Communicate/Postcard/PostcardContext';
import Image from '../../../../../components/Image/Image';
import RotatingArrowImage from '../../../../../images/icons/rotating_arrow.png';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';

type FlipPostcardButtonPureProps = {
  className: string;
  handleShowFrontChange: Function;
};

const FlipPostcardButtonPure = ({
  className,
  handleShowFrontChange,
}: FlipPostcardButtonPureProps): JSX.Element => (
  <FilledButton
    className={className}
    color="green"
    data-test-id="flip-button"
    onClick={(): void => handleShowFrontChange()}
  >
    <Image src={RotatingArrowImage} width={20} />
    &nbsp; <FormattedMessage id="postcard.button.flipCard" />
  </FilledButton>
);

type FlipPostcardButtonProps = {
  className: string;
  handleShowFrontChange: Function;
};

const FlipPostcardButton = ({
  className,
  handleShowFrontChange,
}: FlipPostcardButtonProps): JSX.Element | null => {
  const { sending } = useContext(postcardContext);

  if (sending) {
    return null;
  }

  return (
    <FlipPostcardButtonPure
      className={className}
      handleShowFrontChange={handleShowFrontChange}
    />
  );
};

export default FlipPostcardButton;
