import { useCallback, useContext } from 'react';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './CreateEventButton.styles';
import { organizerContext } from '../../OrganizerContext';
import { DayContentModeEnum } from '../../../../../types/organize/Organizer';

type CreateEventButtonPureProps = {
  onClick: () => void;
};

const CreateEventButtonPure = ({
  onClick,
}: CreateEventButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button className={classes.button} variant="outlined" onClick={onClick}>
      <FormattedMessage id="event.add" />
    </Button>
  );
};

const CreateEventButton = (): JSX.Element => {
  const { setDayContentMode } = useContext(organizerContext);

  const onClick = useCallback(
    () => setDayContentMode(DayContentModeEnum.ADD),
    [setDayContentMode],
  );

  return <CreateEventButtonPure onClick={onClick} />;
};

export default CreateEventButton;
