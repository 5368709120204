import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  acceptButton: {
    marginLeft: 25,
  },
  buttonIcon: {
    paddingRight: 10,
    width: 20,
  },
  container: {
    borderRadius: 16,
    padding: '40px 70px',
    textAlign: 'center',
  },
  declineButton: {
    marginRight: 25,
  },
  fullName: {
    paddingBottom: 25,
    paddingTop: 15,
  },
  mask: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
}));
