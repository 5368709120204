import { useContext } from 'react';
import { shareContext } from './ShareContext';
import { FormattedMessage } from 'react-intl';
import useStyles from './ExplorerHeader.styles';
import BackButton from './ExplorerHeader/BackButton';
import { deleteModeContext } from './ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';

type BreadcrumbHeaderPureProps = {
  folderName: string;
};

const BreadcrumbHeaderPure = ({
  folderName,
}: BreadcrumbHeaderPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.text}>
        <FormattedMessage id="share.explorer.header.shared-folder" /> {'> '}
        {folderName}
      </span>

      <BackButton />
    </>
  );
};

const ExplorerHeaderPure = (): JSX.Element => {
  const classes = useStyles();

  return (
    <span className={classes.text}>
      <FormattedMessage id="share.explorer.header.root" />
    </span>
  );
};

const ExplorerHeader = (): JSX.Element | null => {
  const { folders, selectedFolder } = useContext(shareContext);
  const { isDeleteMode } = useContext(deleteModeContext);

  if (!folders || folders.length === 0 || isDeleteMode) {
    return null;
  }

  if (selectedFolder) {
    return <BreadcrumbHeaderPure folderName={selectedFolder.name} />;
  }

  return <ExplorerHeaderPure />;
};

export default ExplorerHeader;
