import FilledButton from '../../components/Button/FilledButton';
import { HIBOU_URL } from '../../utils/constants';
import { FormattedMessage } from 'react-intl';
import useStyles from './DisconnectButton.styles';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';

type DisconnectButtonPureProps = {
  onClick: Function;
};

const DisconnectButtonPure = ({
  onClick,
}: DisconnectButtonPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <FilledButton className={classes.button} color="violet" onClick={onClick}>
      <FormattedMessage id="button.disconnect" />
    </FilledButton>
  );
};

const DisconnectButton = (): JSX.Element => {
  const disconnect = () => {
    trackEvent(
      GAEventCategory.BUTTON,
      GACommonEventAction.CLICK,
      'Déconnexion',
    );
    window.location.href = `${HIBOU_URL}/logout`;
  };

  return <DisconnectButtonPure onClick={() => disconnect()} />;
};

export default DisconnectButton;
