import { MouseEventHandler, useEffect, useState } from 'react';
import useStyles from './Landing.styles';
import { UseStylesProps } from '../../../../utils/useStyles';
import { Button, Fade, Slide } from '@mui/material';
import GrandpaImage from '../../../../images/OnBoarding/grandpa-with-ardoiz.svg';
import WomanImage from '../../../../images/OnBoarding/woman.svg';
import Image from '../../../../components/Image/Image';
import { FormattedMessage } from 'react-intl';
import { TimeoutType } from '../../../../types/utils/Timeout';

type LandingPureProps = LandingProps &
  UseStylesProps & {
    showAllContent: boolean;
    showTitle: boolean;
  };

const LandingPure = ({
  classes,
  closeLanding,
  showAllContent,
  showTitle,
}: LandingPureProps): JSX.Element => (
  <div className={classes.fullScreenContainer}>
    <div className={classes.container}>
      <Slide in={showTitle} direction="up" timeout={1000}>
        <div className={classes.title}>
          <FormattedMessage id="onboarding.landing.welcome" />
        </div>
      </Slide>

      <Fade in={showAllContent} timeout={1000}>
        <div>
          <Image
            alt="Grandpa"
            className={classes.grandpaImage}
            src={GrandpaImage}
          />

          <Image alt="Woman" className={classes.womanImage} src={WomanImage} />
        </div>
      </Fade>

      <Slide in={showAllContent} direction="up" timeout={1000}>
        <div className={classes.content}>
          <div className={classes.description}>
            <FormattedMessage id="onboarding.landing.description1" />
            <br />
            <FormattedMessage id="onboarding.landing.description2" />
          </div>

          <div className={classes.buttonContainer}>
            <Button onClick={closeLanding}>
              <FormattedMessage id="button.discover" />
            </Button>
          </div>
        </div>
      </Slide>
    </div>
  </div>
);

interface LandingProps {
  closeLanding: MouseEventHandler;
}

const Landing = ({ closeLanding }: LandingProps): JSX.Element => {
  const classes = useStyles();
  const [showAllContent, setShowAllContent] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showAllContentTimeoutHandler, setShowAllContentTimeoutHandler] =
    useState<TimeoutType>();
  const [showTitleTimeoutHandler, setShowTitleTimeoutHandler] =
    useState<TimeoutType>();

  useEffect(() => {
    const newShowAllContentTimeoutHandler = setTimeout(
      (): void => setShowAllContent(true),
      2500,
    );
    const newShowTitleTimeoutHandler = setTimeout(
      (): void => setShowTitle(true),
      500,
    );

    if (!showAllContentTimeoutHandler) {
      setShowAllContentTimeoutHandler(newShowAllContentTimeoutHandler);
    }

    if (!showTitleTimeoutHandler) {
      setShowTitleTimeoutHandler(newShowTitleTimeoutHandler);
    }

    return () => {
      clearTimeout(
        showAllContentTimeoutHandler || newShowAllContentTimeoutHandler,
      );
      clearTimeout(showTitleTimeoutHandler || newShowTitleTimeoutHandler);
    };
  }, [
    showAllContentTimeoutHandler,
    showTitleTimeoutHandler,
    setShowAllContentTimeoutHandler,
    setShowTitleTimeoutHandler,
  ]);

  return (
    <LandingPure
      classes={classes}
      closeLanding={closeLanding}
      showAllContent={showAllContent}
      showTitle={showTitle}
    />
  );
};

export default Landing;
