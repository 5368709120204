import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  noWebUserLink: {
    color: COLORS.LIGHT_RED,
  },
  select: {
    height: 42,

    '& .MuiSelect-select': {
      fontSize: 14,
      fontWeight: 'bold',
    },
    '& .MuiSelect-icon': {
      color: COLORS.VIOLET,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  tabletImage: {
    paddingRight: 10,
    verticalAlign: 'bottom',
  },
}));
