import { Container, MenuList } from '@mui/material';
import { getGoToSelectedChannelPageFunction } from '../../../utils/history';
import HomeIcon from '../../../images/Admin/home.svg';
import MessageIcon from '../../../images/Admin/message.svg';
import WorkbookIcon from '../../../images/Admin/workbook.svg';
import OrganizerIcon from '../../../images/Admin/organizer.svg';
import BookmarkIcon from '../../../images/Admin/bookmark.svg';
import ApplicationIcon from '../../../images/Admin/application.svg';
import SettingsIcon from '../../../images/Admin/settings.svg';
import ContactsIcon from '../../../images/Admin/contacts.svg';

import useStyles from './Menu.styles';
import useAdminStyles from './AdminMenu.styles';
import { TOUR_STEPS } from '../../App/OnBoarding/OnBoardingTour';
import ChangeModeButton from './Menu/ChangeModeButton';
import Item from './Menu/Item';
import { MenuContextProvider } from '../../../Context/MenuContext';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';
import ChannelLinkSelect from '../../ChannelLink/ChannelLinkSelect';
import Route from '../../../routes/Route';
import { LinkType } from '../../../types/Link';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { StateSetter } from '../../../types/utils/React';
import Image from '../../../components/Image/Image';
import ArdoizLogoImage from '../../../images/ardoiz_logo.svg';

type AdminMenuPureProps = {
  selectedLink?: LinkType;
  goToHomeClick: Function;
  setIsOpened?: StateSetter<boolean>;
};

const AdminMenuPure = ({
  selectedLink,
  goToHomeClick,
  setIsOpened,
}: AdminMenuPureProps): JSX.Element => {
  const adminClasses = useAdminStyles();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Image
        alt="Ardoiz"
        className={adminClasses.logo}
        src={ArdoizLogoImage}
        onClick={() => goToHomeClick()}
        width={150}
      />

      {useIsSmallScreen() && (
        <Container>
          <ChannelLinkSelect />
        </Container>
      )}

      <MenuList className={adminClasses.menu}>
        <Item
          disableIfNoSelectedLink={true}
          icon={HomeIcon}
          labelMessageId="admin.menu.home"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={MessageIcon}
          labelMessageId="admin.menu.messages"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_MESSAGES,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={ContactsIcon}
          labelMessageId="admin.menu.contacts"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_CONTACTS,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={OrganizerIcon}
          labelMessageId="admin.menu.organizer"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_ORGANIZER,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={WorkbookIcon}
          labelMessageId="admin.menu.workbook"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_WORKBOOK,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={BookmarkIcon}
          labelMessageId="admin.menu.bookmarks"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_BOOKMARKS,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={ApplicationIcon}
          labelMessageId="admin.menu.applications"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_APPLICATIONS,
          )}
        />
        <Item
          disableIfNoSelectedLink={true}
          icon={SettingsIcon}
          labelMessageId="admin.menu.settings"
          goToPageFunction={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.ADMIN_SETTINGS,
          )}
        />

        <ChangeModeButton setIsMenuOpened={setIsOpened} />
      </MenuList>
    </Container>
  );
};

type AdminMenuProps = {
  getMenuSelectedClassNames: (step?: TOUR_STEPS) => string;
  goToHomeClick: () => void;
  onMenuItemClick: (goToPageFn: Function) => void;
  setIsOpened?: StateSetter<boolean>;
  shouldShowMenu: (step?: number) => boolean;
};

const AdminMenu = ({
  getMenuSelectedClassNames,
  goToHomeClick,
  onMenuItemClick,
  setIsOpened,
  shouldShowMenu,
}: AdminMenuProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();

  return (
    <MenuContextProvider
      getMenuSelectedClassNames={getMenuSelectedClassNames}
      onMenuItemClick={onMenuItemClick}
      shouldShowMenu={shouldShowMenu}
    >
      <AdminMenuPure
        selectedLink={selectedLink}
        goToHomeClick={goToHomeClick}
        setIsOpened={setIsOpened}
      />
    </MenuContextProvider>
  );
};

export default AdminMenu;
