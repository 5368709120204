import { createContext, ReactNode } from 'react';
import { StateSetter } from '../../../../../types/utils/React';
import { emptyFunction } from '../../../../../utils/Functions';

export type OpenCreateFolderModalButtonContextProps = {
  showCreateFolderModal: boolean;
  setShowCreateFolderModal: StateSetter<boolean>;
};

const initialState = {
  showCreateFolderModal: false,
  setShowCreateFolderModal: emptyFunction,
};

const openCreateFolderModalButtonContext =
    createContext<OpenCreateFolderModalButtonContextProps>(initialState),
  { Provider } = openCreateFolderModalButtonContext;

type OpenCreateFolderModalButtonContextProviderProps = {
  children: ReactNode;
  showCreateFolderModal: boolean;
  setShowCreateFolderModal: StateSetter<boolean>;
};

const OpenCreateFolderModalButtonContextProvider = ({
  children,
  showCreateFolderModal,
  setShowCreateFolderModal,
}: OpenCreateFolderModalButtonContextProviderProps): JSX.Element => (
  <Provider
    value={{
      showCreateFolderModal,
      setShowCreateFolderModal,
    }}
  >
    {children}
  </Provider>
);
export {
  openCreateFolderModalButtonContext,
  OpenCreateFolderModalButtonContextProvider,
};
