import { ReactNode } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type SimpleSelectOptionType<ValueType> = {
  disabled?: boolean;
  fullValue: ValueType;
  label: ReactNode;
  value: string | number;
};

type SimpleSelectPureProps<ValueType> = {
  className?: string;
  options: SimpleSelectOptionType<ValueType>[];
  value: string | number;
  handleChange: (event: SelectChangeEvent<string | number>) => void;
};

const SimpleSelectPure = function <ValueType>({
  className,
  options,
  value,
  handleChange,
}: SimpleSelectPureProps<ValueType>): JSX.Element {
  return (
    <Select
      className={className}
      IconComponent={ExpandMoreIcon}
      value={value}
      onChange={handleChange}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          disabled={option.disabled}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export type SimpleSelectProps<ValueType> = {
  className?: string;
  options: SimpleSelectOptionType<ValueType>[];
  value: string | number;
  handleChange: Function;
};

const SimpleSelect = function <ValueType>({
  className,
  options,
  value,
  handleChange,
}: SimpleSelectProps<ValueType>): JSX.Element {
  const handleGetFullValueThenChange = (
    event: SelectChangeEvent<string | number>,
  ): void => {
    const newValue = event.target.value;
    const newOption = options.find((option) => newValue === option.value);

    handleChange(newOption?.fullValue);
  };

  return (
    <SimpleSelectPure
      className={className}
      options={options}
      value={value}
      handleChange={handleGetFullValueThenChange}
    />
  );
};

export default SimpleSelect;
