import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  image: {
    bottom: 0,
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
  },
}));
