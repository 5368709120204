import { useCallback, useContext } from 'react';
import { shareContext } from './ShareContext';
import { Button, Grid, Paper } from '@mui/material';
import useStyles from './ExplorerToolbar/Modals.styles';
import Image from '../../../components/Image/Image';
import CloseImage from '../../../images/icons/close.png';
import clsx from 'clsx';
import ResultItem, {
  ResultItemProps,
} from './DocumentOperationResultsModal/ResultItem';

type DocumentOperationResultsModalPureProps = {
  results: ResultItemProps[];
  handleClose: () => void;
};

const DocumentOperationResultsModalPure = ({
  results,
  handleClose,
}: DocumentOperationResultsModalPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper className={clsx([classes.modal, classes.resultsModal])}>
        <Button className={classes.closeButton} onClick={handleClose}>
          <Image src={CloseImage} />
        </Button>

        <Grid
          alignItems="center"
          className={classes.resultsContainer}
          container
        >
          {results.map((result, index) => (
            <ResultItem key={index} {...result} />
          ))}
        </Grid>
      </Paper>
    </div>
  );
};

const DocumentOperationResultsModal = (): JSX.Element | null => {
  const { documentOperationResults, setDocumentOperationResults } =
    useContext(shareContext);

  const handleClose = useCallback(() => {
    setDocumentOperationResults([]);
  }, [setDocumentOperationResults]);

  if (documentOperationResults.length === 0) {
    return null;
  }

  return (
    <DocumentOperationResultsModalPure
      results={documentOperationResults}
      handleClose={handleClose}
    />
  );
};

export default DocumentOperationResultsModal;
