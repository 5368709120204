import AdminMessagesContainer from './AdminMessagesContainer';
import { MessageType } from '../../../../types/Message';
import { getDateAndHourString } from '../../../../utils/Date';
import { FormattedMessage } from 'react-intl';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import useStyles from './AdminMessageViewer.styles';
import AdminMessagesDeleteButton from './AdminMessagesDeleteButton/AdminMessagesDeleteButton';
import { AdminMessagesContextProvider } from './AdminMessagesContext';
import { getGoToSelectedChannelPageFunction } from '../../../../utils/history';
import Route from '../../../../routes/Route';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import AttachmentIcon from './AttachmentIcon';
import AdminMessageViewerAttachments from './AdminMessageViewer/AdminMessageViewerAttachments';
import AdminMessageBodyLoader from './AdminMessageViewer/AdminMessageBodyLoader';
import useIsSmallScreen from '../../../../utils/useIsSmallScreen';
import { useQuery } from '@apollo/client';
import {
  GET_MESSAGE_QUERY,
  GetMessageOutputType,
} from '../../../../graphql/admin/adminmessage/AdminMessageQueries';
import { AdminMessagesParams } from '../../../../types/route/Params';
import { useParams } from 'react-router';

type AdminMessageViewerPureProps = {
  bodyUrl: string | undefined;
  date: ReactNode;
  destEmails: string;
  isSmallScreen: boolean;
  message: MessageType;
  handleGoBackClick: () => void;
};

const AdminMessageViewerPure = ({
  bodyUrl,
  date,
  destEmails,
  isSmallScreen,
  message,
  handleGoBackClick,
}: AdminMessageViewerPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AdminMessagesContainer>
      <Grid container>
        <Grid item xs={6}>
          <Button className={classes.goBackButton} onClick={handleGoBackClick}>
            <FormattedMessage id="button.back" />
          </Button>
        </Grid>

        <Grid className={classes.deleteButtonContainer} item xs={6}>
          <AdminMessagesDeleteButton isUniqueDeletion />
        </Grid>

        <Grid className={classes.messageContainer} item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.attachmentIconContainer}>
              <AttachmentIcon message={message} small={isSmallScreen} />
            </div>

            <Grid container>
              <Grid className={classes.subject} item md={10} xs={12}>
                <FormattedMessage id="admin.messages.messageView.subject" /> :{' '}
                {message.subject}
              </Grid>
              <Grid className={classes.date} item md={2} xs={12}>
                {date}
              </Grid>

              <Grid className={classes.from} item xs={12}>
                <FormattedMessage id="admin.messages.messageView.from" /> :{' '}
                {message.senderAddress}
              </Grid>

              <Grid className={classes.to} item xs={12}>
                <FormattedMessage id="admin.messages.messageView.to" /> :{' '}
                {destEmails}
              </Grid>

              <Grid className={classes.body} item xs={12}>
                <AdminMessageBodyLoader s3URL={bodyUrl} />
              </Grid>
            </Grid>

            <AdminMessageViewerAttachments attachments={message.attachments} />
          </Paper>
        </Grid>
      </Grid>
    </AdminMessagesContainer>
  );
};

const AdminMessageViewer = (): JSX.Element => {
  const { key } = useParams<AdminMessagesParams>();
  const { selectedLink } = useSelectedLinkContext();
  const isSmallScreen = useIsSmallScreen();

  const [selectedMessages, setSelectedMessages] = useState<MessageType[]>([]);

  const { data, loading } = useQuery<GetMessageOutputType>(GET_MESSAGE_QUERY, {
    variables: {
      getMessageInput: {
        channel: selectedLink?.channelKey,
        messageKey: key,
      },
    },
  });

  // For developping pupose
  // const message: MessageFullFieldsType = useMemo(
  //   () => ({
  //     id: casual.uuid,
  //     attachments: [
  //       {
  //         id: casual.uuid,
  //         contentType: 'image/png',
  //         filename: '/tooti/path/test.png',
  //         key: casual.uuid,
  //         s3URL:
  //           'http://objetspublics.com/wp-content/uploads/revslider/mountainparallaxheader/mountainpng-1.png',
  //       },
  //       {
  //         id: casual.uuid,
  //         contentType: 'application/pdf',
  //         filename: 'hiveryveryverylongname.pdf',
  //         key: casual.uuid,
  //         s3URL:
  //           'https://www.ardoiz.com/wp-content/uploads/2022/02/CGV-ardoiz-au-010221-web.pdf',
  //       },
  //       {
  //         id: casual.uuid,
  //         contentType: '/tooti/path/text/html',
  //         filename:
  //           'hiveryveryverylonefzefzefeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeegname.html',
  //         key: casual.uuid,
  //         s3URL: 'https://www.ardoiz.com/',
  //       },
  //       {
  //         id: casual.uuid,
  //         contentType: 'video/mp4',
  //         filename: '/tooti/path/Chute.mp4',
  //         key: casual.uuid,
  //         s3URL:
  //           'https://drive.google.com/u/0/uc?id=1NP8Nqq6LpX9FDi6WpK0kk7Qdz4UZtAqm&export=download',
  //       },
  //     ],
  //     date: +casual.moment,
  //     destAddress: [casual.email, casual.email, casual.email],
  //     hasAttachment: true,
  //     key: casual.uuid,
  //     senderAddress: casual.email,
  //     subject: casual.words(10),
  //     generateHTML: casual.url,
  //   }),
  //   [],
  // );

  const message = data?.getMessage?.message;

  useEffect(() => {
    if (message) {
      setSelectedMessages([message]);
    }
  }, [message]);

  const handleGoBackClick = useCallback(
    () =>
      getGoToSelectedChannelPageFunction(selectedLink, Route.ADMIN_MESSAGES)(),
    [selectedLink],
  );

  if (!message || loading) {
    return null;
  }

  const date = getDateAndHourString(message.date);
  const destEmails = message.destAddress.join(', ');

  return (
    <AdminMessagesContextProvider
      selectedMessages={selectedMessages}
      setSelectedMessages={setSelectedMessages}
    >
      <AdminMessageViewerPure
        bodyUrl={message.generateHTML}
        date={date}
        destEmails={destEmails}
        isSmallScreen={isSmallScreen}
        message={message}
        handleGoBackClick={handleGoBackClick}
      />
    </AdminMessagesContextProvider>
  );
};

export default AdminMessageViewer;
