import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  message: {
    fontSize: 25,
    lineHeight: 1.75,
    padding: '10%',
    textAlign: 'center',

    '@media (max-width: 1199px)': {
      fontSize: 20,
    },
  },
  overlay: {
    alignItems: 'center',
    background: COLORS.MEDIUM_GREY,
    borderRadius: 15,
    boxShadow: '0px 3px 5px 1px rgb(0 0 0 / 25%)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    // Prevent 1x border from underlying image
    marginLeft: -1,
    maxWidth: 601,
    position: 'absolute',
    top: 0,
    transform: 'scale(0.95)',
  },
}));
