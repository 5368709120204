import { IntlShape } from 'react-intl';
import { getFormattedDate } from '../utils/Date';
import { EmailType } from './utils/String';

export enum AcceptRelationshipErrorType {
  ALREADY_ACCEPTED = 'ALREADY_ACCEPTED',
  NOT_FOUND = 'NOT_FOUND',
  OTHER = 'OTHER',
}

export enum RelationshipStateType {
  CONNECTED = 'CONNECTED',
  WAITING_TABLET_ACCEPTANCE = 'WAITING_TABLET_ACCEPTANCE',
  WAITING_WEB_USER_ACCEPTANCE = 'WAITING_WEB_USER_ACCEPTANCE',
}

export type RelationshipType = {
  id: string;
  channel: string;
  created: number;
  email: string;
  state: RelationshipStateType;
  uuid: string;
  mainUser: {
    emails: EmailType[];
  };
  user: {
    familyAccess: boolean;
    mainUserAccess: boolean;
  } | null;
};

export enum AddRelationshipErrorType {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  MAIN_USER_NOT_FOUND = 'MAIN_USER_NOT_FOUND',
  MINIMAL_VERSION_NOT_SATISFIED = 'MINIMAL_VERSION_NOT_SATISFIED',
  NOTIFICATION_ERROR = 'NOTIFICATION_ERROR',
  NO_CHANNEL_ON_MAIN_USER = 'NO_CHANNEL_ON_MAIN_USER',
  USER_ALREADY_LINKED_TO_A_WEB_USER = 'USER_ALREADY_LINKED_TO_A_WEB_USER',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_WITHOUT_FAMILY_ACCESS = 'USER_WITHOUT_FAMILY_ACCESS',
  WEB_USER_ALREADY_LINKED_TO_CHANNEL = 'WEB_USER_ALREADY_LINKED_TO_CHANNEL',
}

export const userHasAdminAccess = (relationship: RelationshipType): boolean =>
  !!relationship.user?.familyAccess && relationship.user.mainUserAccess;

export const getUserAccessLabel = (
  relationship: RelationshipType,
  intl: IntlShape,
): string => {
  if (
    relationship.state === RelationshipStateType.CONNECTED &&
    relationship.user
  ) {
    return userHasAdminAccess(relationship)
      ? intl.formatMessage({ id: 'relationship.access.admin' })
      : intl.formatMessage({ id: 'relationship.access.classic' });
  }

  return '';
};

export const getRelationshipStateLabel = (
  relationship: RelationshipType,
  intl: IntlShape,
): string =>
  intl.formatMessage(
    {
      id: `relationship.state.${relationship.state}`,
    },
    { createdDate: getFormattedDate(relationship.created) },
  );
