import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';

export default makeStyles(() => ({
  logo: {
    left: '50%',
    marginTop: 20,
    position: 'relative',
    transform: 'translate(-50%, 0)',
  },
  menu: {
    outline: 'none',
    padding: '20px 10px',

    '& .MuiMenuItem-root': {
      color: COLORS.GREY,
      fontSize: 20,
    },
  },
}));
