import { useContext } from 'react';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { useMutation } from '@apollo/client';
import {
  STOP_VIDEO_CALL_MUTATION,
  StopVideoCallOutputType,
} from '../../../../../../graphql/communicate/videocall/VideoCallMutations';
import FilledButton from '../../../../../../components/Button/FilledButton';
import useStyles from './CommonButton.styles';
import useControlToolbarStyles from '../ControlToolbar.styles';
import Image from '../../../../../../components/Image/Image';
import PhoneHangUpImage from '../../../../../../images/Communicate/VideoCall/phone-hang-up.png';
import { VideoCallState } from '../../../../../../types/videocall/VideoCall';
import { Grid } from '@mui/material';
import { useControlToolbarContext } from '../../../../../../Context/Communicate/VideoCall/VideoContent/ControlToolbarContext';
import clsx from 'clsx';
import { useUserContext } from '../../../../../../Context/UserContext';
import { stop } from '../../Actions';
import { trackEvent } from '../../../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../../../GoogleAnalytics/GAEvent';

type HangUpButtonPureProps = {
  small: boolean;
  handleHangUpClick: Function;
};

const HangUpButtonPure = ({
  small,
  handleHangUpClick,
}: HangUpButtonPureProps): JSX.Element => {
  const classes = useStyles();
  const controlToolbarClasses = useControlToolbarStyles();
  const imageWidth = small ? 27 : 35;

  return (
    <Grid className={controlToolbarClasses.item} item>
      <FilledButton
        className={clsx(classes.button, small && 'small')}
        color="red"
        data-test-id="hangup-button"
        onClick={(): void => handleHangUpClick()}
      >
        <Image src={PhoneHangUpImage} width={imageWidth} />
      </FilledButton>
    </Grid>
  );
};

export const ShowableVideoCallStates: VideoCallState[] = [
  VideoCallState.WaitingNotificationAcknowledge,
  VideoCallState.WaitingCalledAnswer,
  VideoCallState.WaitingCallStart,
  VideoCallState.Running,
];

const HangUpButton = (): JSX.Element | null => {
  const { api, videoCall, setVideoCall } = useContext(videoCallContext);
  const { small } = useControlToolbarContext();
  const [stopVideoCall] = useMutation<StopVideoCallOutputType>(
    STOP_VIDEO_CALL_MUTATION,
  );
  const { webUser } = useUserContext();

  if (
    !videoCall ||
    !ShowableVideoCallStates.includes(videoCall.command.currentState)
  ) {
    return null;
  }

  const handleHangUpClick = () => {
    stop({
      api,
      videoCall,
      webUser,
      setVideoCall,
      stopVideoCall,
    });
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.CLICK,
      'Raccrocher',
    );
  };

  return (
    <HangUpButtonPure small={small} handleHangUpClick={handleHangUpClick} />
  );
};

export default HangUpButton;
