import crypto from 'crypto';
import { ab2str } from './Blob';
import { GET_UPLOAD_TEMPORARY_ACCESS_MUTATION } from '../graphql/s3/S3Mutations';
import { UploadTemporaryAccessOutputType } from '../types/s3/UploadTemporaryAccessOutputType';
import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import { ExecutionResult } from 'graphql';
// @ts-ignore
import S3Upload from 'react-s3-uploader/s3upload';
import { dataURLtoBlob } from './Image';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { Buffer } from 'buffer';

type GetUploadTemporaryAccessThenUploadType = UseUploadFileToS3Type & {
  contentType: string;
  dataURL: string;
  getUploadTemporaryAccessMutation: (
    options?:
      | MutationFunctionOptions<
          UploadTemporaryAccessOutputType,
          Record<string, any>
        >
      | undefined,
  ) => Promise<ExecutionResult<UploadTemporaryAccessOutputType>>;
};

export type S3UploadOutputType = {
  contentType: string;
  dataURL: string;
  key: string;
  s3Url: string;
};

const getUploadTemporaryAccessThenUpload = async ({
  contentType,
  dataURL,
  getUploadTemporaryAccessMutation,
  handleProgress,
  showErrorMessage,
}: GetUploadTemporaryAccessThenUploadType): Promise<S3UploadOutputType> => {
  return new Promise((resolve, reject) => {
    const file = dataURLtoBlob(dataURL, contentType);
    const reader = new FileReader();

    reader.readAsBinaryString(file);
    reader.onload = async function (evt) {
      const result = evt?.target?.result;

      const fileContent =
        result instanceof ArrayBuffer ? ab2str(result) : result;

      if (fileContent) {
        const fileData = Buffer.from(fileContent, 'binary');
        const hash = crypto.createHash('md5');
        const md5 = hash.update(fileData).digest('base64');

        getUploadTemporaryAccessMutation({
          variables: {
            checksum: md5,
            contentType: file.type,
            contentLength: file.size,
          },
        })
          .then(async ({ data }) => {
            if (data) {
              const { key, url } = data.getUploadTemporaryAccess;
              return new S3Upload({
                files: [file],
                getSignedUrl: (_f: File, callback: Function) => {
                  callback({ signedUrl: url });
                },
                uploadRequestHeaders: {
                  'Content-MD5': md5,
                  'Cache-Control': 'no-cache',
                },
                onFinishS3Put: (): void =>
                  resolve({
                    contentType,
                    dataURL,
                    key,
                    s3Url: url,
                  }),
                onProgress: handleProgress,
                onError: (status: string): void => {
                  showErrorMessage(<FormattedMessage id="error.uploadError" />);
                  reject(status);
                },
              });
            } else {
              showErrorMessage(<FormattedMessage id="error.uploadError" />);
              reject('Response data is empty');
            }
          })
          .catch((error) => {
            showErrorMessage(<FormattedMessage id="error.uploadError" />);
            reject(error);
          });
      }
    };
  });
};

type UseUploadFileToS3Type = {
  handleProgress?: Function;
  showErrorMessage: (msg: ReactNode) => void;
};

export type UploadFileToS3FnType = (
  dataURL: string,
  contentType: string,
) => Promise<S3UploadOutputType>;

type UseUploadFileToS3OutputType = {
  getUploadTemporaryAccessStatus: {
    error: ApolloError | undefined;
    loading: boolean;
  };
  uploadFileToS3: UploadFileToS3FnType;
};

export const useUploadFileToS3 = (
  props: UseUploadFileToS3Type,
): UseUploadFileToS3OutputType => {
  const [getUploadTemporaryAccessMutation, { error, loading }] =
    useMutation<UploadTemporaryAccessOutputType>(
      GET_UPLOAD_TEMPORARY_ACCESS_MUTATION,
    );

  const uploadFileToS3 = (
    dataURL: string,
    contentType: string,
  ): Promise<S3UploadOutputType> =>
    getUploadTemporaryAccessThenUpload({
      contentType,
      dataURL,
      getUploadTemporaryAccessMutation,
      ...props,
    });

  return { getUploadTemporaryAccessStatus: { error, loading }, uploadFileToS3 };
};
