import ProfileIcon from '../../../../../images/Profile/profile.svg';
import Image from '../../../../../components/Image/Image';
import useStyles from './CreatorIcon.styles';
import { SharedDocumentType } from '../../../../../types/document/Document';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';

const CreatorIconPure = (): JSX.Element => {
  const classes = useStyles();

  return <Image className={classes.icon} src={ProfileIcon} />;
};

type CreatorIconProps = {
  document: SharedDocumentType;
};

const CreatorIcon = ({ document }: CreatorIconProps): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();

  if (document.creator !== selectedLink?.userKey) {
    return null;
  }

  return <CreatorIconPure />;
};

export default CreatorIcon;
