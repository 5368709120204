import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  fullScreenableContainer: {
    height: 500,
    marginTop: 20,
    position: 'relative',
    width: 900,

    '@media (max-width: 1199px)': {
      height: 'calc(100vh)',
      width: 'calc(100vw)',
    },
  },
  overlay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  selfContainer: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  videoContainer: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
}));
