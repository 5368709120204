import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    borderRadius: 16,
    cursor: 'pointer',
    fontSize: 11,
    fontWeight: 'bold',
    padding: 15,
    textAlign: 'center',
    width: 110,
  },
  title: {
    paddingTop: 20,
  },
}));
