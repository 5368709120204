import React, { useCallback, useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SvgIconComponent } from '@mui/icons-material';
import { COLORS } from '../../../../../../theme/MainTheme';
import useStyles from './ActionEventButton.styles';
import FilledButton from '../../../../../../components/Button/FilledButton';
import { organizerContext } from '../../../OrganizerContext';
import { useDayEventContext } from './DayEventContext';
import { isEvent, isEventOwner } from '../../../../../../types/event/Event';
import { DayContentModeEnum } from '../../../../../../types/organize/Organizer';
import { useUserContext } from '../../../../../../Context/UserContext';

type EditOrViewEventButtonPureProps = {
  icon: SvgIconComponent;
  handleEventEditClick: () => void;
};

const EditOrViewEventButtonPure = ({
  icon,
  handleEventEditClick,
}: EditOrViewEventButtonPureProps): JSX.Element => {
  const classes = useStyles();
  const iconComponent = React.createElement(icon, {
    className: classes.icon,
    htmlColor: COLORS.WHITE,
  });

  return (
    <FilledButton
      className={classes.button}
      color="blue"
      data-test-id="edit-event-button"
      onClick={handleEventEditClick}
    >
      {iconComponent}
    </FilledButton>
  );
};

const EditOrViewEventButton = (): JSX.Element | null => {
  const { webUser } = useUserContext();
  const { dayEventOrTimeslot } = useDayEventContext();
  const { setDayContentMode, setEventToEdit } = useContext(organizerContext);

  const handleEventEditClick = useCallback(() => {
    if (isEvent(dayEventOrTimeslot)) {
      const mode = isEventOwner(dayEventOrTimeslot, webUser)
        ? DayContentModeEnum.EDIT
        : DayContentModeEnum.VIEW;

      setDayContentMode(mode);
      setEventToEdit(dayEventOrTimeslot);
    }
  }, [dayEventOrTimeslot, webUser, setDayContentMode, setEventToEdit]);

  if (!isEvent(dayEventOrTimeslot)) {
    return null;
  }

  const icon = isEventOwner(dayEventOrTimeslot, webUser)
    ? EditIcon
    : VisibilityIcon;

  return (
    <EditOrViewEventButtonPure
      icon={icon}
      handleEventEditClick={handleEventEditClick}
    />
  );
};

export default EditOrViewEventButton;
