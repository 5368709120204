import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import MainMenu from './Navigation/MainMenu';
import { Grid } from '@mui/material';
import useStyles from './App.styles';
import Content from './App/Content';
import { TourContextProvider } from '../Context/TourContext';
import {
  HandleSelectedLinkChangeInputProps,
  SelectedLinkContextProvider,
} from '../Context/SelectedLinkContext';
import OnBoardingTour, {
  isOnBoardingShowable,
} from './App/OnBoarding/OnBoardingTour';
import { useQuery } from '@apollo/client';
import {
  GET_ME_QUERY,
  GetMeOutputType,
} from '../graphql/webuser/WebUserQueries';
import { UserContextProvider, useUserContext } from '../Context/UserContext';
import { LinkType } from '../types/Link';
import Header from './App/Header';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { RouteParams } from '../types/route/Params';
import history, { getGoToSelectedChannelPageFunction } from '../utils/history';
import Loading from '../components/Loading/Loading';
import { ADMIN_LINK_CHANNEL_KEY } from '../utils/Link';
import {
  GA_USER_TYPE_DIMENSION,
  setCustomDimension,
} from '../GoogleAnalytics/GATracker';
import { getGAUserType } from '../GoogleAnalytics/GAHelpers';
import IncomingCall from './App/Communicate/IncomingCall';
import useIsSmallScreen from '../utils/useIsSmallScreen';
import { alertContext } from '../components/Alert/AlertContext';
import { showNoWebUserLinkToast } from '../utils/Profile';
import * as serviceWorker from '../serviceWorkerRegistration';
import { MessagePayload } from 'firebase/messaging';
import { ENABLE_NOTIFICATIONS } from '../utils/constants';
import { AppContextProvider, useAppContext } from '../Context/AppContext';
import AdminHeader from './App/Admin/AdminHeader';
import Route from '../routes/Route';

type AppPureProps = {
  children: ReactNode;
  HeaderComponent: () => JSX.Element | null;
};

const AppPure = ({ children, HeaderComponent }: AppPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <OnBoardingTour />
      <Grid container>
        <MainMenu />

        <Grid item className={classes.content}>
          <HeaderComponent />

          <Content>{children}</Content>
        </Grid>
      </Grid>
    </div>
  );
};

type GetSelectedLinkInputType = {
  links: LinkType[];
  selectedChannel: string | undefined;
};

const getSelectedLink = ({
  links,
  selectedChannel,
}: GetSelectedLinkInputType): LinkType | undefined => {
  if (selectedChannel) {
    return links.find(
      (_) => _.channelKey === selectedChannel && _.metadata.subscriptionEnabled,
    );
  }
};

type AppWithDataProps = {
  children: ReactNode;
};

const AppWithData = ({ children }: AppWithDataProps): JSX.Element => {
  const { webUser } = useUserContext();
  const { selectedChannel } = useParams<RouteParams>();
  const { isAdminMode, setIsAdminMode } = useAppContext();

  const webUserLinks = webUser.links.filter(
    (link) => link.channelKey !== ADMIN_LINK_CHANNEL_KEY,
  );
  const selectedLinkFromUrl = getSelectedLink({
    links: webUserLinks,
    selectedChannel,
  });
  const isMobileDevice = useIsSmallScreen();
  const { showErrorMessage } = useContext(alertContext);

  const [selectedLink, setSelectedLink] = useState<LinkType | undefined>(
    selectedLinkFromUrl || undefined,
  );

  const handleSelectedLinkChange = useCallback(
    ({
      isAdmin,
      newSelectedLink,
    }: HandleSelectedLinkChangeInputProps): void => {
      const newIsAdminMode = isAdmin && newSelectedLink.isAdmin;
      const route = newIsAdminMode ? Route.ADMIN : Route.HOME;

      getGoToSelectedChannelPageFunction(newSelectedLink, route)();
      setIsAdminMode(newIsAdminMode);
      setSelectedLink(newSelectedLink);
    },
    [setIsAdminMode, setSelectedLink],
  );

  useEffect(() => {
    if (!selectedLinkFromUrl) {
      handleSelectedLinkChange({
        newSelectedLink: webUserLinks?.[0],
      });
    }

    if (webUserLinks.length === 0) {
      showNoWebUserLinkToast({ selectedLink, showErrorMessage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLink]);

  const HeaderComponent = isAdminMode ? AdminHeader : Header;

  return (
    <SelectedLinkContextProvider
      value={{ selectedLink, handleSelectedLinkChange }}
    >
      <TourContextProvider
        initialIsTourOpen={isOnBoardingShowable({
          isMobileDevice,
          selectedLink,
          webUser,
        })}
      >
        <AppPure HeaderComponent={HeaderComponent}>{children}</AppPure>
      </TourContextProvider>
      <IncomingCall />
    </SelectedLinkContextProvider>
  );
};

type AppProps = {
  children?: ReactNode;
};

const App = ({ children }: AppProps): JSX.Element => {
  const { data, error, loading } = useQuery<GetMeOutputType>(GET_ME_QUERY);
  const { showSuccessMessage, showWarningMessage } = useContext(alertContext);

  const initialUrlIsAdmin =
    history.location.pathname.includes('administration');
  const [isAdminMode, setIsAdminMode] = useState<boolean>(initialUrlIsAdmin);

  const handleNotificationMessageReceived = useCallback(
    (message: MessagePayload) => {
      if (ENABLE_NOTIFICATIONS) {
        if (message.notification) {
          showSuccessMessage(
            <>
              <h4>{message.notification.title}</h4>
              <div>{message.notification.body}</div>
            </>,
          );
        } else {
          showWarningMessage('FCM message sans notification reçu');
        }
      }
    },
    [showSuccessMessage, showWarningMessage],
  );

  useEffect(() => {
    serviceWorker.register({
      handleNotificationMessageReceived,
    });
  });

  if (error || loading) {
    return <Loading />;
  }

  const webUser = data?.me?.user;

  if (!webUser) {
    return <FormattedMessage id="error.userNotFound" />;
  }

  setCustomDimension(GA_USER_TYPE_DIMENSION, getGAUserType(webUser));

  return (
    <AppContextProvider value={{ isAdminMode, setIsAdminMode }}>
      <UserContextProvider value={{ webUser }}>
        <AppWithData>{children}</AppWithData>
      </UserContextProvider>
    </AppContextProvider>
  );
};

export default App;
