import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { MessageType } from '../../../../../types/Message';
import moment from 'moment';
import useStyles from './AdminMessagesTable.styles';
import AttachmentIcon from '../AttachmentIcon';
import { StateSetter } from '../../../../../types/utils/React';
import AdminMessagesTablePagination from './AdminMessagesTablePagination';
import MessageBodyPreviewLoader from './MessageBodyPreviewLoader';
import { FormattedMessage } from 'react-intl';
import { AdminMessagesTableDataTypeEnum } from './AdminMessagesTableData';
import SelectMessageCheckbox from './SelectMessageCheckbox';
import { goToAdminMessageViewerPage } from '../../../../../utils/history';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import clsx from 'clsx';
import { adminMessagesContext } from '../AdminMessagesContext';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import AdminMessagesMobileTable from './AdminMessagesMobileTable';

const ITEMS_PER_PAGE = 10;
export const formatDate = (date: number): string =>
  moment(date).format('D MMM YYYY');

export const getFormattedEmailLabel = (
  message: MessageType,
  type: AdminMessagesTableDataTypeEnum,
): string => {
  if (type === AdminMessagesTableDataTypeEnum.SENT) {
    return message.destAddress
      .map((_) => {
        const parts = _.split('@');
        return parts[0];
      })
      .join(', ');
  }

  return message.senderAddress;
};

type AdminMessagesTablePureProps = {
  areAllMessagesSelected: boolean;
  currentPage: number;
  emailAddressLabelId: string;
  messages: MessageType[];
  pageCount: number;
  type: AdminMessagesTableDataTypeEnum;
  handleRowClick: (message: MessageType) => void;
  handleSelectAllMessages: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  setCurrentPage: StateSetter<number>;
};

const AdminMessagesTablePure = ({
  areAllMessagesSelected,
  currentPage,
  emailAddressLabelId,
  messages,
  pageCount,
  type,
  handleRowClick,
  handleSelectAllMessages,
  setCurrentPage,
}: AdminMessagesTablePureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.attachmentTh}>
              {messages.length > 0 && (
                <Checkbox
                  checked={areAllMessagesSelected}
                  onChange={handleSelectAllMessages}
                />
              )}
            </TableCell>

            <TableCell className={classes.emailAddressTh}>
              <FormattedMessage id={emailAddressLabelId} />
            </TableCell>

            <TableCell className={classes.attachmentTh} />

            <TableCell>
              <FormattedMessage id="admin.messages.column.message" />
            </TableCell>

            <TableCell align="right" className={classes.dateTh}>
              <FormattedMessage id="admin.messages.column.date" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {messages.map((message) => (
            <TableRow
              className={classes.row}
              key={message.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <SelectMessageCheckbox message={message} />
              </TableCell>

              <TableCell
                className={clsx(
                  classes.clickableTd,
                  classes.emailAddressTd,
                  !message.read && 'bold',
                )}
                onClick={() => handleRowClick(message)}
              >
                {getFormattedEmailLabel(message, type)}
              </TableCell>

              <TableCell
                className={classes.clickableTd}
                onClick={() => handleRowClick(message)}
              >
                <AttachmentIcon message={message} />
              </TableCell>

              <TableCell
                className={clsx(classes.clickableTd, classes.messageContainer)}
                onClick={() => handleRowClick(message)}
              >
                <span className={clsx([!message.read && classes.bold])}>
                  {message.subject}
                </span>

                <span className={classes.message}>
                  <MessageBodyPreviewLoader message={message} />
                </span>
              </TableCell>

              <TableCell
                align="right"
                className={clsx(classes.clickableTd, classes.dateTd)}
                onClick={() => handleRowClick(message)}
              >
                {formatDate(message.date)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AdminMessagesTablePagination
        currentPage={currentPage}
        pageCount={pageCount}
        setCurrentPage={setCurrentPage}
      />
    </TableContainer>
  );
};

type GetAreAllMessagesSelectedInput = {
  messages: MessageType[];
  selectedMessages: MessageType[];
};

const getAreAllMessagesSelected = ({
  messages,
  selectedMessages,
}: GetAreAllMessagesSelectedInput): boolean =>
  messages.every((message) => selectedMessages.includes(message));

const sortMessagesFn = (a: MessageType, b: MessageType) =>
  a.date > b.date ? -1 : 1;

const getMessages = (
  isSmallScreen: boolean,
  allMessages: MessageType[],
  currentPage: number,
) => {
  const sortedMessages = allMessages.sort(sortMessagesFn);

  if (isSmallScreen) {
    return sortedMessages;
  }

  const firstElementIndex = currentPage * ITEMS_PER_PAGE;

  return sortedMessages.slice(
    firstElementIndex,
    firstElementIndex + ITEMS_PER_PAGE,
  );
};

type AdminMessagesTableProps = {
  allMessages: MessageType[];
  type: AdminMessagesTableDataTypeEnum;
};

const AdminMessagesTable = ({
  allMessages,
  type,
}: AdminMessagesTableProps): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const isSmallScreen = useIsSmallScreen();
  const { selectedMessages, resetSelectedMessages, setSelectedMessages } =
    useContext(adminMessagesContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  // const allMessages: MessageType[] = generateMessages(50); // TODO remove when message management is ready

  const messages = getMessages(isSmallScreen, allMessages, currentPage);

  const handleRowClick = useCallback(
    (message: MessageType) =>
      goToAdminMessageViewerPage(selectedLink, message.key),
    [selectedLink],
  );

  const handleSelectAllMessages = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectedMessages(messages);
      } else {
        resetSelectedMessages();
      }
    },
    [messages, resetSelectedMessages, setSelectedMessages],
  );

  if (isSmallScreen) {
    return (
      <AdminMessagesMobileTable
        messages={messages}
        type={type}
        handleRowClick={handleRowClick}
      />
    );
  }

  const emailAddressLabelId =
    type === AdminMessagesTableDataTypeEnum.SENT
      ? 'admin.messages.column.recipient'
      : 'admin.messages.column.sender';

  const pageCount = Math.ceil(allMessages.length / ITEMS_PER_PAGE);

  const areAllMessagesSelected = getAreAllMessagesSelected({
    messages,
    selectedMessages,
  });

  return (
    <AdminMessagesTablePure
      areAllMessagesSelected={areAllMessagesSelected}
      currentPage={currentPage}
      emailAddressLabelId={emailAddressLabelId}
      messages={messages}
      pageCount={pageCount}
      type={type}
      handleRowClick={handleRowClick}
      handleSelectAllMessages={handleSelectAllMessages}
      setCurrentPage={setCurrentPage}
    />
  );
};

// TODO remove when message management is ready
// const generateMessages = (count: number): MessageType[] => {
//   const messages: MessageType[] = [];
//
//   for (let i = 0; i < count; i++) {
//     messages.push({
//       id: casual.uuid,
//       attachments: [
//         {
//           id: casual.uuid,
//           contentType: 'image/png',
//           filename: 'test.png',
//           key: casual.uuid,
//           s3URL:
//             'http://objetspublics.com/wp-content/uploads/revslider/mountainparallaxheader/mountainpng-1.png',
//         },
//         {
//           id: casual.uuid,
//           contentType: 'application/pdf',
//           filename: 'hiveryveryverylongname.pdf',
//           key: casual.uuid,
//           s3URL:
//             'https://www.ardoiz.com/wp-content/uploads/2022/02/CGV-ardoiz-au-010221-web.pdf',
//         },
//         {
//           id: casual.uuid,
//           contentType: 'text/html',
//           filename:
//             'hiveryveryverylonefzefzefeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeegname.html',
//           key: casual.uuid,
//           s3URL: 'https://www.ardoiz.com/',
//         },
//         {
//           id: casual.uuid,
//           contentType: 'video/mp4',
//           filename: 'Chute.mp4',
//           key: casual.uuid,
//           s3URL:
//             'https://drive.google.com/u/0/uc?id=1NP8Nqq6LpX9FDi6WpK0kk7Qdz4UZtAqm&export=download',
//         },
//       ],
//       date: +casual.moment,
//       destAddress: [casual.email, casual.email, casual.email],
//       hasAttachment: true,
//       key: casual.uuid,
//       senderAddress: casual.email,
//       subject: casual.title,
//     });
//   }
//
//   return messages;
// };

export default AdminMessagesTable;
