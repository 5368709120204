import { useContext } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import useStyles from '../Modals.styles';
import Image from '../../../../../components/Image/Image';
import CloseImage from '../../../../../images/icons/close.png';
import { FormattedMessage } from 'react-intl';
import useSettingsModalStyles from './SettingsModal.styles';
import DeleteOrQuitFolderButton from './DeleteOrQuitFolderButton';
import { settingsContext } from './SettingsContext';
import DeleteDocumentButton from '../DeleteFiles/DeleteDocumentButton';

type SettingsModalPureProps = {
  handleHide: () => void;
};

const SettingsModalPure = ({
  handleHide,
}: SettingsModalPureProps): JSX.Element => {
  const classes = useStyles();
  const settingsModalClasses = useSettingsModalStyles();

  return (
    <div className={classes.container}>
      <Paper className={settingsModalClasses.modal}>
        <Button className={classes.closeButton} onClick={handleHide}>
          <Image src={CloseImage} />
        </Button>

        <Grid container>
          <Grid className={classes.text} item xs={12}>
            <FormattedMessage id="share.folder.settings.title" />
          </Grid>

          <Grid className={settingsModalClasses.buttonContainer} item xs={12}>
            <DeleteDocumentButton />
          </Grid>

          <Grid className={settingsModalClasses.buttonContainer} item xs={12}>
            <DeleteOrQuitFolderButton />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const SettingsModal = (): JSX.Element | null => {
  const { showSettingsModal, setShowSettingsModal } =
    useContext(settingsContext);

  if (!showSettingsModal) {
    return null;
  }

  return <SettingsModalPure handleHide={() => setShowSettingsModal(false)} />;
};

export default SettingsModal;
