import Route, { Params, replaceRouteParams } from '../routes/Route';
import { GAEventAction, GAEventCategory } from './GAEvent';

export const GA_USER_TYPE_DIMENSION = 'dimension1';

export const pageView = (path: string): void => {
  tryGACommand('set', 'page', path);
  tryGACommand('send', 'pageview');
};

export const trackRouteWithParamsView = (route: Route): void => {
  const withoutParamsPath = replaceRouteParams(route, {
    [Params.PARTNER_ID]: '',
    [Params.SELECTED_CHANNEL]: '',
    [Params.UUID]: '',
    [Params.VIDEO_CALL_ID]: '',
  });

  pageView(withoutParamsPath);
};

export const trackEvent = (
  category: GAEventCategory,
  action: GAEventAction,
  label: string,
): void => tryGACommand('send', 'event', category, action, label);

export const setCustomDimension = (
  name: string,
  value: string | number,
): void => tryGACommand('set', name, value);

const tryGACommand = (...props: any): void => {
  if (window.ga) {
    window.ga(...props);
  }
};
