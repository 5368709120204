import { KeyboardEvent, useContext } from 'react';
import useStyles from './Back.styles';
import { UseStylesProps } from '../../../../../utils/useStyles';
import { Grid, TextField } from '@mui/material';
import PostcardStamp from '../../../../../images/Communicate/Postcard/postcard_stamp.png';
import Image from '../../../../../components/Image/Image';
import { useUserContext } from '../../../../../Context/UserContext';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { UserType } from '../../../../../types/User';
import { postcardContext } from '../../../../../Context/Communicate/Postcard/PostcardContext';
import { useIntl } from 'react-intl';
import { getOnKeyPressFunction, POSTCARD_CONTENT_MAX_LENGTH } from './handlers';

type BackPureProps = UseStylesProps & {
  postcardContent: string;
  recipient: UserType | undefined;
  sender: string;
  handleContentChange: (value: string) => void;
  onKeyPress: (e: KeyboardEvent) => void;
};

export const BackPure = ({
  classes,
  postcardContent,
  recipient,
  sender,
  handleContentChange,
  onKeyPress,
}: BackPureProps): JSX.Element => {
  const intl = useIntl();
  return (
    <div className={classes.container}>
      <Grid className={classes.inner} container>
        <Grid className={classes.leftPart} item xs={8}>
          <Grid className={classes.leftPartContainer} container>
            <Grid className={classes.contentContainer} item xs={12}>
              <TextField
                className={classes.content}
                data-test-id="postcard-content-textfield"
                maxRows={10}
                multiline
                placeholder={intl.formatMessage({
                  id: 'form.placeholder.writeTextHere',
                })}
                value={postcardContent}
                onChange={(e): void => handleContentChange(e.target.value)}
                onKeyPress={(e) => onKeyPress(e)}
              />
            </Grid>

            <Grid item xs={12}>
              <div
                className={classes.contentLength}
                data-test-id="postcard-content-length"
              >
                ({postcardContent.length}/{POSTCARD_CONTENT_MAX_LENGTH})
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.sender} data-test-id="postcard-sender">
                {sender}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.rightPart} item xs={4}>
          <div className={classes.rightPartContainer}>
            <Image className={classes.stamp} height={120} src={PostcardStamp} />

            <Grid className={classes.recipient} container>
              <Grid
                className={classes.address}
                data-test-id="postcard-recipient"
                item
                xs={12}
              >
                {recipient?.firstName} {recipient?.lastName}
              </Grid>

              <Grid
                className={classes.address}
                data-test-id="postcard-recipient-address"
                item
                xs={12}
              >
                {recipient?.postalAddress.address.trim()}
              </Grid>

              <Grid
                className={classes.address}
                data-test-id="postcard-recipient-city"
                item
                xs={12}
              >
                {recipient?.postalAddress.zipcode}{' '}
                {recipient?.postalAddress.city}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const Back = (): JSX.Element | null => {
  const classes = useStyles();

  const { postcardContent, setPostcardContent } = useContext(postcardContext);
  const { webUser } = useUserContext();
  const { selectedLink } = useSelectedLinkContext();
  const sender = `${webUser.firstName} ${webUser.lastName}`;

  if (!selectedLink) {
    return null;
  }

  const handleContentChange = (value: string) => {
    if (value.length <= POSTCARD_CONTENT_MAX_LENGTH) {
      setPostcardContent(value);
    }
  };

  const onKeyPress = getOnKeyPressFunction({ postcardContent });

  return (
    <BackPure
      classes={classes}
      postcardContent={postcardContent}
      recipient={selectedLink.mainUser}
      sender={sender}
      handleContentChange={handleContentChange}
      onKeyPress={onKeyPress}
    />
  );
};

export default Back;
