import { useEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { useErrorHandler } from 'react-error-boundary';
import { ApolloClientContext, initGraphql } from './init';
import Router from '../routes/Router';
import Loading from '../components/Loading/Loading';

export const ApolloLoader = (): JSX.Element => {
  const [apolloClient, setApolloClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (apolloClient === undefined) {
      initGraphql(errorHandler).then((client) => setApolloClient(client));
    }
  });

  if (apolloClient !== undefined) {
    return (
      <ApolloProvider client={apolloClient}>
        <ApolloClientContext.Provider value={apolloClient}>
          <Router />
        </ApolloClientContext.Provider>
      </ApolloProvider>
    );
  } else {
    return <Loading />;
  }
};
