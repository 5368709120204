import {
  VideoCallState,
  VideoCallType,
} from '../../../../types/videocall/VideoCall';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { MutationFunction } from '@apollo/client';
import { WebUserType } from '../../../../types/User';
import { getGoToVideoCallPageFunction } from '../../../../utils/history';

type AcceptIncomingCallInputType = {
  incomingCall: VideoCallType;
  webUser: WebUserType;
  acceptVideoCall: MutationFunction;
  resetIncomingCall: Function;
  showErrorMessage: (msg: ReactNode) => void;
};

export const acceptIncomingCall = ({
  incomingCall,
  webUser,
  acceptVideoCall,
  resetIncomingCall,
  showErrorMessage,
}: AcceptIncomingCallInputType): void => {
  const sendError = () => {
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.ERROR,
      'Accepter un appel entrant',
    );

    showErrorMessage(<FormattedMessage id="videoCall.error.accept" />);

    resetIncomingCall();
  };

  acceptVideoCall({
    variables: {
      videoCallID: incomingCall.id,
    },
  })
    .then(({ data }) => {
      resetIncomingCall();

      const videoCall = data?.acceptVideoCall.videoCall;

      if (videoCall.command.currentState === VideoCallState.WaitingCallStart) {
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.CLICK,
          'Accepter un appel entrant',
        );

        const link = webUser.links.find(
          (_) => _.selfWebUserId === videoCall.callerID,
        );

        if (link) {
          const goToVideoCall = getGoToVideoCallPageFunction(
            link,
            videoCall.id,
          );
          goToVideoCall();
        }
      } else {
        sendError();
      }
    })
    .catch((error) => {
      console.error(error);
      sendError();
    });
};
type RefuseIncomingCallInputType = {
  incomingCall: VideoCallType;
  refuseVideoCall: MutationFunction;
  resetIncomingCall: Function;
  showErrorMessage: (msg: ReactNode) => void;
};

export const refuseIncomingCall = ({
  incomingCall,
  refuseVideoCall,
  resetIncomingCall,
  showErrorMessage,
}: RefuseIncomingCallInputType): void => {
  const sendError = () => {
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.ERROR,
      'Décliner un appel entrant',
    );
  };

  refuseVideoCall({
    variables: {
      videoCallID: incomingCall.id,
    },
  })
    .then(({ data }) => {
      if (
        data?.refuseVideoCall.videoCall.command.currentState ===
        VideoCallState.CallRefused
      ) {
        trackEvent(
          GAEventCategory.VIDEO_CALL,
          GACommonEventAction.CLICK,
          'Décliner un appel entrant',
        );

        playNotificationSound();
      } else {
        sendError();
        showErrorMessage(<FormattedMessage id="videoCall.error.refuse" />);
      }

      resetIncomingCall();
    })
    .catch((error) => {
      console.error(error);
      sendError();
      resetIncomingCall();
    });
};

export const playNotificationSound = (): void => {
  const audio = new Audio('/sounds/notification.mp3');
  audio.play();
};
