import { useContext } from 'react';
import SubCategory from '../../components/SubCategory/SubCategory';
import {
  getGoToSelectedChannelPageFunction,
  getGoToVideoCallPageFunction,
} from '../../utils/history';
import PostcardImage from '../../images/Communicate/SubCategories/postcard.png';
import SubCategories from '../../components/SubCategory/SubCategories';
import { FormattedMessage } from 'react-intl';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import Route from '../../routes/Route';
import { LinkType } from '../../types/Link';
import { useMutation } from '@apollo/client';
import {
  CREATE_VIDEO_CALL_MUTATION,
  CreateVideoCallOutputType,
} from '../../graphql/communicate/videocall/VideoCallMutations';
import { alertContext } from '../../components/Alert/AlertContext';
import VideoCallSubCategory from './Communicate/VideoCallSubCategory';

type CommunicatePureProps = {
  selectedLink?: LinkType;
  handleVideoCallClick: Function;
};

const CommunicatePure = ({
  selectedLink,
  handleVideoCallClick,
}: CommunicatePureProps): JSX.Element => (
  <SubCategories
    title={<FormattedMessage id="category.communicate.description" />}
  >
    <VideoCallSubCategory handleVideoCallClick={handleVideoCallClick} />
    <SubCategory
      alt="Carte postale"
      image={PostcardImage}
      subCategorySize={6}
      onClick={getGoToSelectedChannelPageFunction(selectedLink, Route.POSTCARD)}
    />
  </SubCategories>
);

const Communicate = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { showErrorMessage } = useContext(alertContext);
  const [createVideoCall] = useMutation<CreateVideoCallOutputType>(
    CREATE_VIDEO_CALL_MUTATION,
  );

  const handleVideoCallClick = () => {
    createVideoCall({
      variables: {
        input: {
          calledChannel: selectedLink?.channelKey,
        },
      },
    })
      .then(({ data }) => {
        const videoCall = data?.createVideoCall?.videoCall;

        if (videoCall) {
          const goToVideoCall = getGoToVideoCallPageFunction(
            selectedLink,
            videoCall.id,
          );
          goToVideoCall();
        } else {
          showErrorMessage(
            <FormattedMessage id="videoCall.error.initialization" />,
          );
        }
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage(
          <FormattedMessage id="videoCall.error.initialization" />,
        );
      });
  };

  return (
    <CommunicatePure
      selectedLink={selectedLink}
      handleVideoCallClick={handleVideoCallClick}
    />
  );
};

export default Communicate;
