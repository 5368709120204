import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    height: 'calc(100vh - 250px)',
    marginTop: 20,
  },
  explorerContainer: {
    height: 'calc(100% - 140px)',
    minHeight: 250,
  },
  explorerHeader: {
    alignSelf: 'flex-end',
  },
  explorerToolbarContainer: {
    alignSelf: 'flex-end',
  },
}));
