import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    borderBottom: `solid 1px ${COLORS.LIGHT_GREY}`,
    color: '#A5A5A5',
    fontSize: 18,
    fontWeight: 'bold',
    padding: '30px 20px',
    textTransform: 'capitalize',

    [theme.breakpoints.down('md')]: {
      fontSize: 11,
      padding: '20px 20px 10px 20px',
    },
  },
  nextMonth: {
    cursor: 'pointer',
    textAlign: 'right',
  },
  previousMonth: {
    cursor: 'pointer',
    textAlign: 'left',
  },
}));
