import { useCallback, useContext } from 'react';
import Image from '../../../../components/Image/Image';
import AdminModeImage from '../../../../images/Menu/mode_admin.png';
import { Button, ClassNameMap, Fade } from '@mui/material';
import { UseStylesProps } from '../../../../utils/useStyles';
import { tourContext } from '../../../../Context/TourContext';
import useStyles from './ChangeModeButton.styles';
import { FormattedMessage } from 'react-intl';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { getGoToSelectedChannelPageFunction } from '../../../../utils/history';
import Route from '../../../../routes/Route';
import { StateSetter } from '../../../../types/utils/React';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';
import { TOUR_STEPS } from '../../../App/OnBoarding/OnBoardingTour';
import clsx from 'clsx';
import { linkHasAdminAccess } from '../../../../types/Link';
import { useAppContext } from '../../../../Context/AppContext';

type ChangeModeButtonPureProps = UseStylesProps & {
  buttonLabelId: string;
  classes: ClassNameMap;
  selectedStepClassName?: string;
  show: boolean;
  onClick: Function;
};

const ChangeModeButtonPure = ({
  buttonLabelId,
  classes,
  selectedStepClassName,
  show,
  onClick,
}: ChangeModeButtonPureProps): JSX.Element => (
  <Fade in={show}>
    <div className={classes.changeModeContainer}>
      <div
        className={clsx([
          'changeModeContainer',
          classes.changeModeSubContainer,
          'tour-step-admin',
        ])}
      >
        <Image
          alt="Mode"
          className={clsx([classes.changeModeImage, selectedStepClassName])}
          src={AdminModeImage}
        />

        <div className={classes.changeModeButtonContainer}>
          <Button
            className={`${classes.changeModeButton} changeModeButton`}
            variant="contained"
            onClick={() => onClick()}
          >
            <FormattedMessage id={buttonLabelId} />
          </Button>
        </div>
      </div>
    </div>
  </Fade>
);

type ChangeModeButtonProps = {
  setIsMenuOpened?: StateSetter<boolean>;
};
const ChangeModeButton = ({
  setIsMenuOpened,
}: ChangeModeButtonProps): JSX.Element | null => {
  const classes = useStyles();
  const { currentStep, isTourOpen } = useContext(tourContext);
  const { selectedLink } = useSelectedLinkContext();
  const { isAdminMode, setIsAdminMode } = useAppContext();
  const shouldShowChangeModeButton =
    (isTourOpen && currentStep >= TOUR_STEPS.ADMIN) ||
    (!isTourOpen && linkHasAdminAccess(selectedLink));

  const onClick = useCallback(() => {
    trackEvent(
      GAEventCategory.BUTTON,
      GACommonEventAction.CLICK,
      'Mode Administrateur',
    );

    if (setIsMenuOpened !== undefined) {
      setIsMenuOpened(false);
    }

    setIsAdminMode(!isAdminMode);

    if (isAdminMode) {
      getGoToSelectedChannelPageFunction(selectedLink, Route.HOME)();
    } else {
      getGoToSelectedChannelPageFunction(selectedLink, Route.ADMIN)();
    }
  }, [isAdminMode, selectedLink, setIsAdminMode, setIsMenuOpened]);

  const buttonLabelId = isAdminMode
    ? 'button.exitAdminMode'
    : 'button.adminMode';

  return (
    <ChangeModeButtonPure
      buttonLabelId={buttonLabelId}
      classes={classes}
      selectedStepClassName={
        isTourOpen && currentStep === TOUR_STEPS.ADMIN
          ? classes.selected
          : undefined
      }
      show={shouldShowChangeModeButton}
      onClick={onClick}
    />
  );
};

export default ChangeModeButton;
