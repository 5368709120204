import { FormattedMessage, useIntl } from 'react-intl';
import SubCategory from '../../../components/SubCategory/SubCategory';
import GettingStartedImage from '../../../images/Help/documents/SubCategories/getting_stated.svg';
import TabletTutorialsImage from '../../../images/Help/documents/SubCategories/tablet_tutorials.svg';
import UserGuideImage from '../../../images/Help/documents/SubCategories/user_guide.svg';
import FamilyPortalTutorialsImage from '../../../images/Help/documents/SubCategories/family_portail_tutorials.svg';
import { getGoToSelectedChannelPageFunction } from '../../../utils/history';
import Route from '../../../routes/Route';
import SubCategories from '../../../components/SubCategory/SubCategories';
import { LinkType } from '../../../types/Link';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { trackEvent } from '../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../GoogleAnalytics/GAEvent';
import { Grid } from '@mui/material';

const GUIDES_PATH_PREFIX = '/pdf/help';

type HelpDocumentsPureProps = {
  selectedLink?: LinkType;
};

const HelpDocumentsPure = ({
  selectedLink,
}: HelpDocumentsPureProps): JSX.Element => {
  const intl = useIntl();

  const onUserGuideClick = () => {
    const userGuideFile = 'Guide utilisation Interface Guidée.pdf';

    trackEvent(GAEventCategory.HELP, GACommonEventAction.CLICK, userGuideFile);

    window.open(`${GUIDES_PATH_PREFIX}/${userGuideFile}`);
  };

  return (
    <SubCategories
      title={<FormattedMessage id="category.help.documents.description" />}
    >
      <SubCategory
        alt={intl.formatMessage({ id: 'category.help.documents.user_guide' })}
        enableEvenIfNoSelectedLink
        image={UserGuideImage}
        subCategorySize={4}
        onClick={() => onUserGuideClick()}
      />

      <SubCategory
        alt={intl.formatMessage({
          id: 'category.help.documents.tablet_tutorials',
        })}
        enableEvenIfNoSelectedLink
        image={TabletTutorialsImage}
        subCategorySize={4}
        onClick={getGoToSelectedChannelPageFunction(
          selectedLink,
          Route.TABLET_TUTORIALS,
        )}
      />

      <Grid item md={4} xs={12} />

      <SubCategory
        alt={intl.formatMessage({
          id: 'category.help.documents.getting_started',
        })}
        enableEvenIfNoSelectedLink
        image={GettingStartedImage}
        subCategorySize={4}
        onClick={getGoToSelectedChannelPageFunction(
          selectedLink,
          Route.GETTING_STARTED,
        )}
      />

      <SubCategory
        alt={intl.formatMessage({
          id: 'category.help.documents.family_portal_tutorials',
        })}
        enableEvenIfNoSelectedLink
        image={FamilyPortalTutorialsImage}
        subCategorySize={4}
        onClick={getGoToSelectedChannelPageFunction(
          selectedLink,
          Route.FAMILY_PORTAL_TUTORIALS,
        )}
      />
    </SubCategories>
  );
};

const HelpDocuments = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();

  return <HelpDocumentsPure selectedLink={selectedLink} />;
};

export default HelpDocuments;
