import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  categoriesContainer: {
    backgroundColor: COLORS.DARKER_BLUE,
    position: 'relative',
    zIndex: 0,
  },
  container: {
    backgroundColor: COLORS.LIGHT_BLUE,
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      height: 400,
      paddingLeft: 100,
    },
  },
  image: {
    width: 850,

    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  imageContainer: {
    textAlign: 'center',
  },
  text: {
    fontFamily: adminFontFamily,
    fontWeight: 300,
  },
  textContainer: {
    fontSize: 28,

    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      padding: '50px 20px 0 20px',
    },
  },
  title: {
    fontFamily: adminFontFamily,
    fontSize: 28,
    fontWeight: 'bold',
  },
}));
