import { ChangeEvent, useCallback, useContext } from 'react';
import { TextField } from '@mui/material';
import useStyles from './FolderNameTextField.styles';
import { createFolderModalContext } from '../CreateFolderModalContext';

const FolderNameTextField = (): JSX.Element => {
  const classes = useStyles();
  const { folderName, textFieldError, setFolderName, setTextFieldError } =
    useContext(createFolderModalContext);

  const handleFolderNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
      setFolderName(e?.target.value || '');
      setTextFieldError(undefined);
    },
    [setFolderName, setTextFieldError],
  );

  return (
    <TextField
      className={classes.textField}
      data-test-id="folder-name-textfield"
      error={!!textFieldError}
      helperText={textFieldError}
      required
      value={folderName}
      variant="outlined"
      onChange={handleFolderNameChange}
    />
  );
};

export default FolderNameTextField;
