import { useState } from 'react';
import { Button } from '@mui/material';
import useStyles from './OpenCreateFolderModalButton.styles';
import { FormattedMessage } from 'react-intl';
import CreateFolderModal from './CreateFolderModal';
import { OpenCreateFolderModalButtonContextProvider } from './OpenCreateFolderModalButtonContext';

type OpenCreateFolderModalButtonPureProps = {
  handleShowCreateFolderModal: () => void;
};

const OpenCreateFolderModalButtonPure = ({
  handleShowCreateFolderModal,
}: OpenCreateFolderModalButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        data-test-id="open-create-folder-modal-button"
        variant="outlined"
        onClick={handleShowCreateFolderModal}
      >
        <FormattedMessage id="share.folder.create.button" />
      </Button>

      <CreateFolderModal />
    </>
  );
};

const OpenCreateFolderModalButton = (): JSX.Element => {
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);

  return (
    <OpenCreateFolderModalButtonContextProvider
      showCreateFolderModal={showCreateFolderModal}
      setShowCreateFolderModal={setShowCreateFolderModal}
    >
      <OpenCreateFolderModalButtonPure
        handleShowCreateFolderModal={() => setShowCreateFolderModal(true)}
      />
    </OpenCreateFolderModalButtonContextProvider>
  );
};

export default OpenCreateFolderModalButton;
