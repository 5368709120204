import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  attachmentIconContainer: {
    left: 12,
    position: 'absolute',
    top: 37,

    [theme.breakpoints.down('md')]: {
      left: 7,
      top: 27,
    },
  },
  body: {
    fontSize: 18,
    padding: '30px 40px',
    wordBreak: 'break-all',

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      overflow: 'auto',
      padding: 20,
    },
  },
  date: {
    fontSize: 16,
    paddingRight: 40,
    paddingTop: 30,
    textAlign: 'end',

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      paddingRight: 15,
      paddingTop: 10,
    },
  },
  deleteButtonContainer: {
    textAlign: 'end',
  },
  from: {
    color: COLORS.DARK_BLUE,
    fontSize: 14,
    padding: '10px 0 10px 40px',

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: '0 0 0 30px',
    },
  },
  to: {
    borderBottom: `1px solid ${COLORS.MEDIUM_GREY}`,
    color: COLORS.DARK_BLUE,
    fontSize: 14,
    padding: '0 0 30px 40px',

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: '5px 0 10px 30px',
    },
  },
  goBackButton: {
    background: COLORS.WHITE,
    borderRadius: 10,
    color: COLORS.DARKER_BLUE,
    fontFamily: adminFontFamily,
    fontSize: 18,
    fontWeight: 400,
    marginTop: 25,
    padding: '10px 30px',
    textTransform: 'capitalize',

    '&:hover': {
      background: COLORS.WHITE,
    },

    [theme.breakpoints.down('md')]: {
      borderRadius: 4,
      fontSize: 12,
      marginLeft: 20,
      marginTop: 5,
      padding: '8px 20px',
    },
  },
  messageContainer: {
    paddingTop: 20,
  },
  paper: {
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
  },
  subject: {
    fontSize: 28,
    fontFamily: adminFontFamily,
    fontWeight: 400,
    paddingLeft: 40,
    paddingTop: 30,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      paddingLeft: 30,
      paddingRight: 5,
      paddingTop: 25,
    },
  },
}));
