import { makeStyles } from '@mui/styles';
import PaperTextureImage from '../../../../../images/Communicate/Postcard/paper_texture.png';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  address: {
    borderBottom: '2px solid black',
    fontFamily:
      'fineliner_scriptregular, Metropolis, Arial, Helvetica, sans-serif ',
    fontSize: 20,
    height: 25,
    marginTop: 20,
    overflow: 'hidden',
    padding: '0 5px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  container: {
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
    background: `url(${PaperTextureImage}) repeat`,
    height: '100%',
    position: 'absolute',
    transform: 'rotateY(-180deg)',
    width: '100%',
  },
  content: {
    height: '100%',
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none',
    },

    '& .MuiInputBase-root': {
      alignItems: 'start',
      height: '100%',
      padding: 0,
    },

    '& textarea': {
      color: COLORS.GREY,
      fontFamily:
        'fineliner_scriptregular, Metropolis, Arial, Helvetica, sans-serif ',
      fontSize: 28,
      lineHeight: '32px',
      maxHeight: '100%',
      resize: 'none',
    },
  },
  contentContainer: {
    height: '80%',
    width: '100%',
  },
  contentLength: {
    color: COLORS.GREEN,
    float: 'right',
    fontSize: 14,
    marginTop: 10,
  },
  leftPart: {
    padding: 10,
  },
  leftPartContainer: {
    height: '100%',
  },
  inner: {
    height: '100%',
    padding: 10,
    width: '100%',
  },
  recipient: {
    padding: '50px 0 0 10px',
  },
  rightPart: {
    borderLeft: '3px solid black',
  },
  rightPartContainer: {
    height: '100%',
  },
  sender: {
    float: 'right',
    fontFamily:
      'fineliner_scriptregular, Metropolis, Arial, Helvetica, sans-serif',
    fontSize: 18,
  },
  stamp: {
    float: 'right',
  },
  stampContainer: {},
}));
