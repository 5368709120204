import { createBrowserHistory } from 'history';
import Route, { Params, replaceRouteParams } from '../routes/Route';
import { LinkType } from '../types/Link';
import { NO_LINK_SELECTED } from './Link';
import {
  pageView,
  trackRouteWithParamsView,
} from '../GoogleAnalytics/GATracker';

const browserHistory = createBrowserHistory();
export default browserHistory;

export const goTo = (path: string, hasSelectedChannelParam = false): void => {
  browserHistory.push(path);

  window.dispatchEvent(new CustomEvent('urlchanged'));

  if (!hasSelectedChannelParam) {
    pageView(path);
  }
};

export const getGoToSelectedChannelPageFunction =
  (selectedLink: LinkType | undefined, route: Route) => (): void => {
    const newRoute = replaceRouteParams(route, {
      [Params.SELECTED_CHANNEL]: selectedLink?.channelKey || NO_LINK_SELECTED,
    });

    goTo(newRoute, true);
    trackRouteWithParamsView(route);
  };

export const getGoToChannelPageFunction =
  (channel: string, route: Route) => (): void => {
    const newRoute = replaceRouteParams(route, {
      [Params.SELECTED_CHANNEL]: channel,
    });

    goTo(newRoute, true);
    trackRouteWithParamsView(route);
  };

export const getGoToVideoCallPageFunction =
  (selectedLink: LinkType | undefined, videoCallID: string) => (): void => {
    const newRoute = replaceRouteParams(Route.VIDEO_CALL, {
      [Params.SELECTED_CHANNEL]: selectedLink?.channelKey || NO_LINK_SELECTED,
      [Params.VIDEO_CALL_ID]: videoCallID,
    });

    goTo(newRoute, true);
  };

export const goToAssociatePage = (uuid: string): void => {
  const newRoute = replaceRouteParams(Route.ACCEPT_RELATIONSHIP, {
    [Params.UUID]: uuid,
  });

  goTo(newRoute, false);
};

export const goToPartnerContentsPage = (
  selectedLink: LinkType | undefined,
  partnerId: string,
): void => {
  const newRoute = replaceRouteParams(Route.PARTNER_CONTENTS, {
    [Params.PARTNER_ID]: partnerId,
    [Params.SELECTED_CHANNEL]: selectedLink?.channelKey,
  });

  goTo(newRoute, true);
  trackRouteWithParamsView(Route.PARTNER_CONTENTS);
};

export const goToAdminMessageViewerPage = (
  selectedLink: LinkType | undefined,
  key: string,
): void => {
  const newRoute = replaceRouteParams(Route.ADMIN_MESSAGE_VIEWER, {
    [Params.KEY]: key,
    [Params.SELECTED_CHANNEL]: selectedLink?.channelKey,
  });

  goTo(newRoute, true);
  trackRouteWithParamsView(Route.ADMIN_MESSAGE_VIEWER);
};
