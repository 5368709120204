import FilledButton from '../../../../components/Button/FilledButton';
import useStyles from './MobileExplorerToolbarMenuItemButton.styles';
import { FormattedMessage } from 'react-intl';

type MobileExplorerToolbarMenuItemButtonProps = {
  messageId: string;
  onClick: Function;
};

const MobileExplorerToolbarMenuItemButton = ({
  messageId,
  onClick,
}: MobileExplorerToolbarMenuItemButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FilledButton
      className={classes.button}
      color="violet"
      data-test-id="open-settings-modal-button"
      onClick={() => onClick(true)}
    >
      <FormattedMessage id={messageId} />
    </FilledButton>
  );
};

export default MobileExplorerToolbarMenuItemButton;
