import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled';
import useStyles from './AudioPlayerControlButton.styles';

type PauseProps = {
  handleClick: () => void;
};

const AudioPlayerPause = ({ handleClick }: PauseProps) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={() => handleClick()}>
      <PauseCircleFilled />
    </button>
  );
};

export default AudioPlayerPause;
