import { usePartnerPublicationsContext } from './PartnerPublicationsContext';
import { useQuery } from '@apollo/client';
import {
  GET_PARTNER_BOOKMARKS_QUERY,
  GetPartnerBookmarksOutputType,
} from '../../../../../graphql/partnerdocuments/PartnerDocumentsQueries';
import PartnerListContainer from './PartnerListContainer';
import { Grid } from '@mui/material';
import PartnerBookmarkItem from './PartnerBookmark/PartnerBookmarkItem';
import { sortContentsByPublicationStartDate } from '../../../../../utils/Document';
import { PartnerDocumentType } from '../../../../../types/partnerdocuments/PartnerDocument';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';

type PartnerBookmarkListPureProps = {
  bookmarks: PartnerDocumentType[];
};

const PartnerBookmarkListPure = ({
  bookmarks,
}: PartnerBookmarkListPureProps): JSX.Element => (
  <PartnerListContainer titleMessageId="partner.publications.bookmark.title">
    <Grid container spacing={3}>
      {bookmarks.map((_) => (
        <Grid item key={_.id} xs={12}>
          <PartnerBookmarkItem bookmark={_} />
        </Grid>
      ))}
    </Grid>
  </PartnerListContainer>
);

const PartnerBookmarkList = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { partnerAccount } = usePartnerPublicationsContext();

  const { data, loading } = useQuery<GetPartnerBookmarksOutputType>(
    GET_PARTNER_BOOKMARKS_QUERY,
    {
      variables: {
        getPartnerBookmarksInput: {
          partnerId: partnerAccount.id,
          tabletChannel: selectedLink?.channelKey,
        },
      },
    },
  );

  if (loading) {
    return null;
  }

  const bookmarks = data?.getPartnerBookmarks.bookmarks || [];
  const filteredAndSortedBookmarks =
    sortContentsByPublicationStartDate<PartnerDocumentType>([...bookmarks]);

  return <PartnerBookmarkListPure bookmarks={filteredAndSortedBookmarks} />;
};

export default PartnerBookmarkList;
