import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: COLORS.LIGHT_GREY,
      boxShadow: '1px 2px 5px 0px rgb(0 0 0 / 40%)',
      color: COLORS.BLACK,
      fontWeight: 'bold',
      right: 6,
      top: 43,
    },
  },
}));
