import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
  },
  logo: {
    left: '5%',
    position: 'fixed',
    top: '5%',
    zIndex: 10,
  },
  successContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  successDescriptionContainer: {
    paddingTop: 50,
    textAlign: 'center',
  },
  successButtonContainer: {
    paddingTop: 20,
    textAlign: 'center',
  },
}));
