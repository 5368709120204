import React, { useCallback, useContext, useState } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { openCreateFolderModalButtonContext } from './OpenCreateFolderModalButtonContext';
import useStyles from '../Modals.styles';
import { FormattedMessage } from 'react-intl';
import Image from '../../../../../components/Image/Image';
import CloseImage from '../../../../../images/icons/close.png';
import FolderNameTextField from './CreateFolderModal/FolderNameTextField';
import FolderContactsSelect from './CreateFolderModal/FolderContactsSelect';
import { CreateFolderModalContextProvider } from './CreateFolderModalContext';
import CreateFolderButton from './CreateFolderModal/CreateFolderButton';
import { StateSetter } from '../../../../../types/utils/React';

type CreateFolderModalPureProps = {
  contacts: string[];
  setContacts: StateSetter<string[]>;
  handleClose: () => void;
};

const CreateFolderModalPure = ({
  contacts,
  setContacts,
  handleClose,
}: CreateFolderModalPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper className={classes.modal}>
        <Grid container>
          <Grid item xs={12}>
            <Button className={classes.closeButton} onClick={handleClose}>
              <Image src={CloseImage} />
            </Button>
          </Grid>

          <Grid className={classes.text} item xs={12}>
            <FormattedMessage id="share.folder.create.title" />
          </Grid>

          <Grid className={classes.folderNameContainer} item xs={12}>
            <FolderNameTextField />
          </Grid>

          <Grid className={classes.text} item xs={12}>
            <FormattedMessage id="share.folder.create.select-contacts" />
          </Grid>

          <Grid className={classes.contactsSelectContainer} item xs={12}>
            <FolderContactsSelect
              contacts={contacts}
              setContacts={setContacts}
            />
          </Grid>

          <CreateFolderButton />
        </Grid>
      </Paper>
    </div>
  );
};

const CreateFolderModal = (): JSX.Element | null => {
  const { showCreateFolderModal, setShowCreateFolderModal } = useContext(
    openCreateFolderModalButtonContext,
  );

  const [contacts, setContacts] = React.useState<string[]>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [textFieldError, setTextFieldError] = useState<string>();

  const resetCreateFolderFields = useCallback(() => {
    setContacts([]);
    setFolderName('');
    setTextFieldError(undefined);
  }, [setContacts, setFolderName, setTextFieldError]);

  const handleClose = useCallback(() => {
    resetCreateFolderFields();
    setShowCreateFolderModal(false);
  }, [resetCreateFolderFields, setShowCreateFolderModal]);

  if (!showCreateFolderModal) {
    return null;
  }

  return (
    <CreateFolderModalContextProvider
      contacts={contacts}
      folderName={folderName}
      textFieldError={textFieldError}
      resetCreateFolderFields={resetCreateFolderFields}
      setContacts={setContacts}
      setFolderName={setFolderName}
      setTextFieldError={setTextFieldError}
    >
      <CreateFolderModalPure
        contacts={contacts}
        setContacts={setContacts}
        handleClose={handleClose}
      />
    </CreateFolderModalContextProvider>
  );
};

export default CreateFolderModal;
