import { useEffect, useState } from 'react';
import { usePartnerContentContext } from '../PartnerContentContext';
import { getPartnerContentContentFromS3 } from '../../../../../../../utils/Document';
import Interweave from 'interweave';
import useStyles from './PartnerContentArticle.styles';
import { getTextFormattedDate } from '../../../../../../../utils/Date';
import Image from '../../../../../../../components/Image/Image';

type PartnerContentArticlePureProps = {
  articleContent: string | undefined;
  publicationDate: string;
  thumbnail: string | undefined;
  title: string;
};

const PartnerContentArticlePure = ({
  articleContent,
  publicationDate,
  thumbnail,
  title,
}: PartnerContentArticlePureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>{title}</div>

      <div className={classes.publicationDate}>{publicationDate}</div>

      <div className={classes.content}>
        {thumbnail && <Image className={classes.thumbnail} src={thumbnail} />}
        <Interweave content={articleContent} />
      </div>
    </>
  );
};

const PartnerContentArticle = (): JSX.Element => {
  const { selectedContent } = usePartnerContentContext();
  const [articleContent, setArticleContent] = useState<string | undefined>();
  const [loadedContentId, setLoadedContentId] = useState<string | undefined>();
  const s3URL = selectedContent?.blob?.s3URL;

  useEffect(() => {
    if (loadedContentId !== selectedContent.id) {
      getPartnerContentContentFromS3({
        contentId: selectedContent.id,
        s3URL,
        callback: (value: string | undefined) => {
          setArticleContent(value);
          setLoadedContentId(selectedContent.id);
        },
      });
    }
  }, [
    loadedContentId,
    selectedContent.id,
    s3URL,
    setArticleContent,
    setLoadedContentId,
  ]);

  const publicationDate = getTextFormattedDate(
    selectedContent.publicationStartDate,
  );

  return (
    <PartnerContentArticlePure
      articleContent={articleContent}
      publicationDate={publicationDate}
      thumbnail={selectedContent.thumbnailBlob?.s3URL}
      title={selectedContent.title}
    />
  );
};

export default PartnerContentArticle;
