import SelectedDocumentToDeleteIcon from '../../../../../../images/Share/ShareExplorer/selected-document-to-delete.svg';
import Image from '../../../../../../components/Image/Image';
import useStyles from './DocumentToDeleteOverlay.styles';

const SelectedDocumentPure = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Image
      className={classes.image}
      src={SelectedDocumentToDeleteIcon}
      width={70}
    />
  );
};

type SelectedDocumentProps = {
  selected: boolean;
};

const DocumentToDeleteOverlay = ({
  selected,
}: SelectedDocumentProps): JSX.Element | null => {
  if (!selected) {
    return null;
  }

  return <SelectedDocumentPure />;
};

export default DocumentToDeleteOverlay;
