import { SharedDocumentType } from '../../../../../types/document/Document';
import { FetchResult, MutationFunction } from '@apollo/client';
import {
  DeleteDocumentError,
  DeleteDocumentMutationOutputType,
} from '../../../../../graphql/document/DocumentMutations';
import { StateSetter } from '../../../../../types/utils/React';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { ResultItemProps } from '../../DocumentOperationResultsModal/ResultItem';
import { ShowMessageFunctionType } from '../../../../../components/Alert/AlertContext';
import * as DeleteFilesHelpers from './DeleteFilesHelpers';
import { trackEvent } from '../../../../../GoogleAnalytics/GATracker';
import {
  GAEventCategory,
  GAShareEventActions,
} from '../../../../../GoogleAnalytics/GAEvent';

export enum DeleteDocumentSuccess {
  OK = 'OK',
}

export type HandleDeleteDocumentInputType = {
  channel: string | undefined;
  document: SharedDocumentType;
  folderKey: string | undefined;
  deleteDocument: MutationFunction<DeleteDocumentMutationOutputType>;
  resolve: (value: ResultItemProps | PromiseLike<ResultItemProps>) => void;
};

export const handleDeleteDocument = ({
  channel,
  document,
  folderKey,
  deleteDocument,
  resolve,
}: HandleDeleteDocumentInputType): Promise<void> =>
  deleteDocument({
    variables: {
      deleteDocumentInput: {
        channel,
        folderKey,
        documentKey: document.key,
      },
    },
  }).then(({ data }: FetchResult<DeleteDocumentMutationOutputType>) => {
    if (data?.deleteDocument.isDeleted) {
      trackEvent(
        GAEventCategory.SHARE,
        GAShareEventActions.DELETE_DOCUMENT,
        document.type,
      );

      resolve({
        fileName: document.name,
        inError: false,
        messageId: DeleteFilesHelpers.getDeletedDocumentMessageId(
          DeleteDocumentSuccess.OK,
        ),
      });
    } else {
      resolve({
        fileName: document.name,
        inError: false,
        messageId: DeleteFilesHelpers.getDeletedDocumentMessageId(
          data?.deleteDocument.errorReason || DeleteDocumentError.UNKNOWN_ERROR,
        ),
      });
    }
  });

type DeleteFilesInputType = {
  channel: string | undefined;
  documentsToDelete: SharedDocumentType[];
  folderKey: string | undefined;
  deleteDocument: MutationFunction<DeleteDocumentMutationOutputType>;
  refreshDocuments: () => void;
  setDocumentOperationResults: StateSetter<ResultItemProps[]>;
  setLoadingMessage: StateSetter<ReactNode | undefined>;
  showSuccessMessage: ShowMessageFunctionType;
};

export const deleteFiles =
  ({
    channel,
    documentsToDelete,
    folderKey,
    deleteDocument,
    refreshDocuments,
    setDocumentOperationResults,
    setLoadingMessage,
    showSuccessMessage,
  }: DeleteFilesInputType) =>
  async (): Promise<void> => {
    if (channel && folderKey) {
      setLoadingMessage(
        <FormattedMessage id="share.document.delete.loading" />,
      );

      const results: ResultItemProps[] = await Promise.all<ResultItemProps>(
        documentsToDelete.map((document) => {
          return new Promise<ResultItemProps>((resolve) =>
            DeleteFilesHelpers.handleDeleteDocument({
              channel,
              document,
              folderKey,
              deleteDocument,
              resolve,
            }),
          );
        }),
      );

      if (!results.find((_) => _.inError)) {
        showSuccessMessage(
          <FormattedMessage id="share.document.delete.result.success" />,
        );
      } else {
        setDocumentOperationResults(results);
      }

      refreshDocuments();
      setLoadingMessage(undefined);
    }
  };

export const getDeletedDocumentMessageId = (
  status: DeleteDocumentError | DeleteDocumentSuccess,
): string => {
  switch (status) {
    case DeleteDocumentSuccess.OK:
    case DeleteDocumentError.UNAUTHORIZED:
    case DeleteDocumentError.UNKNOWN_DOCUMENT:
      return `share.document.delete.result.${status}`;
    default:
      return `share.document.delete.result.UNKNOWN_ERROR`;
  }
};
