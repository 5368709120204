import { FunctionComponent, ReactNode } from 'react';
import { UseStylesProps } from '../../utils/useStyles';
import useStyles from './SubCategories.styles';
import { Grid } from '@mui/material';
import PageContentHeader from '../PageContent/PageContentHeader';
import PageContentContainer from '../PageContent/PageContentContainer';

type SubCategoriesPureProps = UseStylesProps & SubCategoriesProps;

const SubCategoriesPure: FunctionComponent<SubCategoriesPureProps> = ({
  classes,
  children,
  title,
}): JSX.Element => (
  <PageContentContainer>
    <PageContentHeader title={title} />

    <Grid alignItems="stretch" className={classes.subCategories} container>
      {children}
    </Grid>
  </PageContentContainer>
);

export type SubCategoriesProps = {
  children: ReactNode;
  title: ReactNode;
};

const SubCategories: FunctionComponent<SubCategoriesProps> = ({
  children,
  ...props
}): JSX.Element => {
  const classes = useStyles();
  return (
    <SubCategoriesPure classes={classes} {...props}>
      {children}
    </SubCategoriesPure>
  );
};

export default SubCategories;
