import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import useStyles from './AudioPlayerControlButton.styles';

type AudioPlayerPlayProps = {
  handleClick: () => void;
};

const AudioPlayerPlay = ({ handleClick }: AudioPlayerPlayProps) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={() => handleClick()}>
      <PlayCircleFilled />
    </button>
  );
};

export default AudioPlayerPlay;
