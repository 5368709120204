import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    border: `3px solid ${COLORS.VIOLET}`,
    borderRadius: 14,
    color: COLORS.VIOLET,
    fontSize: 16,
    float: 'right',
    padding: '10px 20px',
    textTransform: 'none',

    '&:hover': {
      border: `3px solid ${COLORS.VIOLET}`,
    },
  },
}));
