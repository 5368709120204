import Content from './Home/Content';
import { Container, Typography } from '@mui/material';
import { useUserContext } from '../../Context/UserContext';
import { UseStylesProps } from '../../utils/useStyles';
import { WebUserType } from '../../types/User';
import useStyles from './Home.styles';
import { FormattedMessage } from 'react-intl';

type HomePureProps = UseStylesProps & {
  webUser: WebUserType;
};

const HomePure = ({ classes, webUser }: HomePureProps): JSX.Element => (
  <Container>
    <div className={classes.titles}>
      <Typography variant="h3">
        <FormattedMessage
          id="home.welcome"
          values={{ firstName: webUser.firstName, lastName: webUser.lastName }}
        />
      </Typography>
      <Typography variant="subtitle2">
        <FormattedMessage id="home.whatToDo" />
      </Typography>
    </div>

    <Content />
  </Container>
);

const Home = (): JSX.Element => {
  const { webUser } = useUserContext();
  const classes = useStyles();

  return <HomePure classes={classes} webUser={webUser} />;
};

export default Home;
