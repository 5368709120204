import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles((theme: Theme) => ({
  buttonToolbar: {
    float: 'right',
    paddingTop: 30,
  },
  cancelButton: {
    color: COLORS.BLUE,
    textTransform: 'capitalize',
  },
  container: {
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
  content: {
    [theme.breakpoints.down('md')]: {
      padding: 20,
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
  },
  deleteButton: {
    padding: '10px 15px',
    textTransform: 'capitalize',
  },
  deletionMessage: {
    color: COLORS.BLUE,
  },
  modal: {
    borderRadius: 24,
    left: '50%',
    padding: '40px 40px 20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      bottom: 0,
      height: 'auto',
      left: 0,
      padding: 10,
      right: 0,
      top: 0,
      transform: 'none',
      width: 'auto',
    },
  },
  radio: {
    color: COLORS.BLUE,

    '&.Mui-checked': {
      color: COLORS.BLUE,
    },
  },
  radioGroup: {
    padding: '15px 0 0 15px',

    '& .MuiFormControlLabel-label': {
      color: COLORS.BLUE,
      fontSize: 15,
    },
  },
}));
