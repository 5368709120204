import { useCallback, useContext } from 'react';
import MobileExplorerToolbarMenuItemButton from '../MobileExplorerToolbarMenuItemButton';
import { editContactsContext } from '../../../../../views/App/Share/ExplorerToolbar/EditContacts/EditContactsContext';

type MobileEditContactsButtonPureProps = {
  handleClick: () => void;
};

const MobileEditContactsButtonPure = ({
  handleClick,
}: MobileEditContactsButtonPureProps): JSX.Element => (
  <MobileExplorerToolbarMenuItemButton
    messageId="share.folder.contacts.edit.button"
    onClick={handleClick}
  />
);

const MobileEditContactsButton = (): JSX.Element => {
  const { setShowEditContactsModal } = useContext(editContactsContext);
  const handleClick = useCallback(
    () => setShowEditContactsModal(true),
    [setShowEditContactsModal],
  );

  return <MobileEditContactsButtonPure handleClick={handleClick} />;
};

export default MobileEditContactsButton;
