import { useEffect, useState } from 'react';
import { MessageType } from '../../../../../types/Message';
import { useQuery } from '@apollo/client';
import {
  GET_MESSAGE_TEXT_CONTENT_QUERY,
  GetMessageTextContentOutputType,
} from '../../../../../graphql/admin/adminmessage/AdminMessageQueries';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';

type MessageBodyPreviewLoaderPureProps = {
  content: string;
};

const MessageBodyPreviewLoaderPure = ({
  content,
}: MessageBodyPreviewLoaderPureProps): JSX.Element => <>{content}</>;

type MessageBodyLoaderProps = {
  message: MessageType;
};

const MessageBodyPreviewLoader = ({
  message,
}: MessageBodyLoaderProps): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const [content, setContent] = useState<string>();
  const { data } = useQuery<GetMessageTextContentOutputType>(
    GET_MESSAGE_TEXT_CONTENT_QUERY,
    {
      variables: {
        getMessageInput: {
          channel: selectedLink?.channelKey,
          messageKey: message.key,
        },
      },
    },
  );
  const s3Url = data?.getMessage?.message?.generateText;

  useEffect(() => {
    if (s3Url && !content) {
      fetch(s3Url)
        .then((response) => {
          if (response.ok) {
            response.text().then((value) => setContent(value));
          }
        })
        .catch((error) =>
          console.error(`Error getting message body text ${s3Url}`, error),
        )
        .catch((error) =>
          console.error(`Error retrieving message body ${s3Url}`, error),
        );
    }
  }, [content, s3Url]);

  if (!content) {
    return null;
  }

  return <MessageBodyPreviewLoaderPure content={content} />;
};

export default MessageBodyPreviewLoader;
