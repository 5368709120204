import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';

export default makeStyles(() => ({
  td: {
    border: 'none',
    color: COLORS.BLACK,
    fontSize: 18,
    padding: '16px 0',

    '&.success': {
      color: COLORS.GREEN,
    },

    '&.warning': {
      color: COLORS.ORANGE,
    },

    '& .accept-button': {
      padding: '5px 10px',
      textTransform: 'capitalize',
    },
  },
  th: {
    border: 'none',
    color: COLORS.VIOLET,
    fontSize: 18,
    padding: 0,
  },
}));
