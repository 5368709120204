import ReactDOM from 'react-dom';
import './index.css';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/fr';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/fr';
import { listenAxeptioCookies } from './GoogleAnalytics/init';
import { ApolloLoader } from './graphql/ApolloLoader';
import 'moment/locale/fr';
import Providers from './Providers';
import OfflinePage from './components/Error/OfflinePage';
import Notifications from 'react-notify-toast';

(window as any).global = window;

listenAxeptioCookies();

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('offline', () =>
  ReactDOM.render(
    <Providers>
      <OfflinePage />
    </Providers>,

    document.getElementById('root'),
  ),
);

window.addEventListener('online', () => window.location.reload());

ReactDOM.render(
  <Providers>
    <ApolloLoader />
  </Providers>,
  document.getElementById('root'),
);

ReactDOM.render(<Notifications />, document.getElementById('notifications'));
