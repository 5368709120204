import { MouseEventHandler, ReactNode, useContext } from 'react';
import { Grid } from '@mui/material';
import Image from '../../../components/Image/Image';
import CommunicateImg from '../../../images/Home/communicate.svg';
import HelpImg from '../../../images/Home/help.svg';
import OrganizeImg from '../../../images/Home/organize.svg';
import ShareImg from '../../../images/Home/share.svg';
import { getGoToSelectedChannelPageFunction } from '../../../utils/history';
import useStyles from './Content.styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useIntl } from 'react-intl';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import Route from '../../../routes/Route';
import { LinkType } from '../../../types/Link';
import { trackEvent } from '../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../GoogleAnalytics/GAEvent';
import { alertContext } from '../../../components/Alert/AlertContext';
import { showNoWebUserLinkToast } from '../../../utils/Profile';

interface Category {
  alt: string;
  goToPage: MouseEventHandler;
  image: string;
}

interface ContentPureProps {
  categories: Category[];
  classes: ClassNameMap;
}

const ContentPure = ({
  classes,
  categories,
}: ContentPureProps): JSX.Element => (
  <Grid container alignItems="baseline">
    {categories.map((_: Category, index: number) => (
      <Grid key={index} item md={6} xs={12}>
        <Image
          alt={_.alt}
          className={classes.category}
          src={_.image}
          onClick={_.goToPage}
        />
      </Grid>
    ))}
  </Grid>
);

const getHandleCategoryClickFn =
  (
    selectedLink: LinkType | undefined,
    showErrorMessage: (
      msg: ReactNode,
      autoHideDurationParam?: number | null,
    ) => void,
  ) =>
  (route: Route, eventLabel: string, disableIfNoSelectedLink: boolean) =>
  () => {
    if (disableIfNoSelectedLink && !selectedLink) {
      showNoWebUserLinkToast({ selectedLink, showErrorMessage });
    } else {
      const goToPageFn = getGoToSelectedChannelPageFunction(
        selectedLink,
        route,
      );

      trackEvent(
        GAEventCategory.CATEGORY,
        GACommonEventAction.CLICK,
        eventLabel,
      );
      goToPageFn();
    }
  };

const Content = (): JSX.Element => {
  const intl = useIntl();
  const { selectedLink } = useSelectedLinkContext();
  const { showErrorMessage } = useContext(alertContext);
  const classes = useStyles();
  const getHandleCategoryClick = getHandleCategoryClickFn(
    selectedLink,
    showErrorMessage,
  );

  const categories: Category[] = [
    {
      alt: intl.formatMessage({ id: 'category.communicate.label' }),
      goToPage: getHandleCategoryClick(
        Route.COMMUNICATE,
        intl.formatMessage({ id: 'category.communicate.label' }),
        false,
      ),
      image: CommunicateImg,
    },
    {
      alt: intl.formatMessage({ id: 'category.share.label' }),
      goToPage: getHandleCategoryClick(
        Route.SHARE,
        intl.formatMessage({ id: 'category.share.label' }),
        true,
      ),
      image: ShareImg,
    },
    {
      alt: intl.formatMessage({ id: 'category.organize.label' }),
      goToPage: getHandleCategoryClick(
        Route.ORGANIZE,
        intl.formatMessage({ id: 'category.organize.label' }),
        true,
      ),
      image: OrganizeImg,
    },
    {
      alt: intl.formatMessage({ id: 'category.help.label' }),
      goToPage: getHandleCategoryClick(
        Route.HELP,
        intl.formatMessage({ id: 'category.help.label' }),
        false,
      ),
      image: HelpImg,
    },
  ].filter((_: Category | undefined): _ is Category => _ !== undefined);

  return <ContentPure categories={categories} classes={classes} />;
};

export default Content;
