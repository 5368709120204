import { createContext, ReactNode } from 'react';
import { StateSetter } from '../../../../../types/utils/React';
import { emptyFunction } from '../../../../../utils/Functions';

export type EditContactsContextProps = {
  contacts: string[];
  showEditContactsModal: boolean;
  setContacts: StateSetter<string[]>;
  setShowEditContactsModal: StateSetter<boolean>;
};

const initialState = {
  contacts: [],
  showEditContactsModal: false,
  setContacts: emptyFunction,
  setShowEditContactsModal: emptyFunction,
};

const editContactsContext =
    createContext<EditContactsContextProps>(initialState),
  { Provider } = editContactsContext;

type EditContactsContextProviderProps = {
  children: ReactNode;
  contacts: string[];
  showEditContactsModal: boolean;
  setContacts: StateSetter<string[]>;
  setShowEditContactsModal: StateSetter<boolean>;
};

const EditContactsContextProvider = ({
  children,
  contacts,
  showEditContactsModal,
  setContacts,
  setShowEditContactsModal,
}: EditContactsContextProviderProps): JSX.Element => (
  <Provider
    value={{
      contacts,
      showEditContactsModal,
      setContacts,
      setShowEditContactsModal,
    }}
  >
    {children}
  </Provider>
);

export { editContactsContext, EditContactsContextProvider };
