export const communicateMessages = {
  /* COMMUNICATE */
  'category.communicate.description': 'Contacter votre proche',
  'category.communicate.label': 'Contacter',

  /* Postcard */
  'postcard.button.flipCard': 'Retourner la carte',
  'postcard.button.loadPhoto': 'Charger une photo',
  'postcard.description': 'Envoyer une carte postale',
  'postcard.crop': 'Recadrer',
  'postcard.sent': 'Carte postale envoyée',
  'postcard.saveError': 'Erreur de sauvegarde de la carte postale',
  'postcard.tryLater':
    "Echec d'envoi de la carte postale, veuillez réessayer ultérieurement",

  /* Video call */
  'videoCall.incomingcall': 'Appel vidéo entrant',
  'videoCall.button.call': 'Appeler',
  'videoCall.calling': 'Appel visio en cours',
  'videoCall.description': 'Appeler en visio',
  'videoCall.activated.male': 'activé',
  'videoCall.activated.female': 'activée',
  'videoCall.deactivated.male': 'désactivé',
  'videoCall.deactivated.female': 'désactivée',
  'videoCall.mic': 'Micro',
  'videoCall.camera': 'Caméra',
  'videoCall.fullscreen.activated': 'Mode<br></br>plein écran',
  'videoCall.fullscreen.deactivated': 'Mode<br></br>réduit',

  'videoCall.error.androidVersion':
    'Nous sommes désolés, la tablette Ardoiz de votre proche est trop ancienne pour bénéficier de la nouvelle fonctionnalité « appel vidéo »',

  'videoCall.error.ardoizCoreVersion':
    "Pour passer des appels vidéo avec le détenteur de la tablette, celui-ci doit d'abord accepter la dernière mise à jour Ardoiz",

  'videoCall.error.initialization':
    "Erreur lors de l'initialisation de l'appel visio",

  'videoCall.error.sendNotification': "Erreur lors de la tentative d'appel",

  'videoCall.error.waitNotificationAcknowledgment':
    "Erreur lors de l'attente de réception d'appel",

  'videoCall.error.NotificationAcknowledgeBusy':
    '{fullName} est indisponible /  occupée',

  'videoCall.error.NotificationAcknowledgeTimeout1.tablet': 'La tablette de',

  'videoCall.error.NotificationAcknowledgeTimeout2.tablet':
    "n'est pas connectée",

  'videoCall.error.NotificationAcknowledgeTimeout': "n'est pas connecté(e)",

  'videoCall.error.notificationSent':
    'Votre contact va être notifié par email de votre appel et pourra vous rappeler très prochainement',

  'videoCall.error.waitAccept': "Problème pendant l'attente d'acceptation",

  'videoCall.message.Ended1': 'Appel visio avec',

  'videoCall.message.Ended2': 'terminé',

  'videoCall.message.Interrupted1': 'Appel vers',

  'videoCall.message.Interrupted2': 'interrompu',

  'videoCall.error.CallAnswerTimeout': 'Pas de réponse de la part de',

  'videoCall.error.CallStartTimeout':
    "{fullName} n'a pas pu se connecter au salon visio dans le temps imparti, veuillez réessayer",

  'videoCall.error.CallRefused': 'a décliné votre appel',

  'videoCall.error.getVideoCall':
    "Problème de récupération du salon d'appel visio",

  'videoCall.error.accept': "Erreur lors de l'acceptation de l'appel",

  'videoCall.error.refuse': "Erreur lors du refus de l'appel",
};
