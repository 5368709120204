import { useContext } from 'react';
import EmptyShareExplorer from './ShareExplorer/EmptyShareExplorer';
import useStyles from './ShareExplorer.styles';
import { shareContext } from './ShareContext';
import { FolderType } from '../../../types/document/Document';
import FolderItem from './ShareExplorer/FolderItem';
import FolderContentExplorer from './ShareExplorer/FolderContentExplorer';

type ShareExplorerPureProps = {
  folders: FolderType[];
};

export const ShareExplorerPure = ({
  folders,
}: ShareExplorerPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.explorerContainer}>
        <div className={classes.scrollContainer}>
          <div className={classes.folderItemsContainer}>
            {folders.map((folder) => (
              <div className={classes.folderItemContainer} key={folder.id}>
                <FolderItem folder={folder} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShareFolderContentExplorerPure = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.explorerContainer}>
        <div className={classes.scrollContainer}>
          <div className={classes.folderItemsContainer}>
            <FolderContentExplorer />
          </div>
        </div>
      </div>
    </div>
  );
};

export const EmptyShareExplorerPure = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.explorerContainer}>
        <div className={classes.folderItemsContainer}>
          <EmptyShareExplorer />
        </div>
      </div>
    </div>
  );
};

const ShareExplorer = (): JSX.Element | null => {
  const { folders, selectedFolder } = useContext(shareContext);

  if (!folders) {
    return null;
  }

  if (folders.length === 0) {
    return <EmptyShareExplorerPure />;
  }

  if (selectedFolder) {
    return <ShareFolderContentExplorerPure />;
  }

  return <ShareExplorerPure folders={folders} />;
};

export default ShareExplorer;
