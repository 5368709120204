import moment from 'moment';
import { StateSetter } from '../../types/utils/React';
import useStyles from './AudioPlayerBar.styles';
import { COLORS } from '../../theme/MainTheme';
import casual from '../../mocks/casual';

const formatDuration = (duration) => {
  const momentDuration = moment.duration(duration, 'seconds');

  return moment.utc(momentDuration.asMilliseconds()).format('mm:ss');
};

type AudioPlayerBarProps = {
  duration: number;
  curTime: number;
  onTimeUpdate: StateSetter<number>;
};

const AudioPlayerBar = ({
  duration,
  curTime,
  onTimeUpdate,
}: AudioPlayerBarProps) => {
  const curPercentage = (curTime / duration) * 100;
  const barProgressId = `bar-progress-${casual.uuid}`;

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(`#${barProgressId}`) as HTMLElement;

    if (bar) {
      const barStart = bar.getBoundingClientRect().left + window.scrollX;
      const barWidth = bar.offsetWidth;
      const clickPositionInBar = clickPositionInPage - barStart;
      const timePerPixel = duration / barWidth;

      return timePerPixel * clickPositionInBar;
    }
  };

  const handleTimeDrag = (e) => {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  const classes = useStyles();

  return (
    <div className={classes.bar}>
      <span className={classes.time}>{formatDuration(curTime)}</span>
      <div
        className={classes.progress}
        id={barProgressId}
        style={{
          background: `linear-gradient(to right, ${COLORS.ORANGE} ${curPercentage}%, ${COLORS.WHITE} 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className={classes.progressKnob}
          style={{ left: `${curPercentage - 2}%` }}
        />
      </div>
      <span className={classes.time}>{formatDuration(duration)}</span>
    </div>
  );
};

export default AudioPlayerBar;
