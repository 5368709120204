import { FunctionComponent, useCallback, useContext } from 'react';
import useStyles from './PostcardWidget.styles';
import Back from './PostcardWidget/Back';
import Front from './PostcardWidget/Front';
import { Grid } from '@mui/material';
import { postcardContext } from '../../../../Context/Communicate/Postcard/PostcardContext';
import clsx from 'clsx';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { useMutation } from '@apollo/client';
import {
  SEND_POSTCARD_MUTATION,
  SendPostcardOutputType,
} from '../../../../graphql/communicate/postcard/PostcardMutations';
import {
  getHandleShowFrontChangeFunction,
  getSendPostcardFunction,
  handleDrop,
} from './PostcardWidget/handlers';
import { alertContext } from '../../../../components/Alert/AlertContext';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';
import useIsSmallScreen from '../../../../utils/useIsSmallScreen';
import MobilePostcardWidget from '../../../../mobile/App/Communicate/Postcard/MobilePostcardWidget';
import {
  S3UploadOutputType,
  useUploadFileToS3,
} from '../../../../utils/S3Upload';
import { StateSetter } from '../../../../types/utils/React';
import SendPostcardButton from './PostcardWidget/SendPostcardButton';
import FlipPostcardButton from './PostcardWidget/FlipPostcardButton';
import LoadPhotoButton from './PostcardWidget/LoadPhotoButton';

export type PostcardWidgetPureProps = {
  canSendPostcard: boolean;
  showFront: boolean;
  handleShowFrontChange: Function;
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  sendPostcard: Function;
  setShowFront: StateSetter<boolean>;
};

export const PostcardWidgetPure: FunctionComponent<PostcardWidgetPureProps> = ({
  canSendPostcard,
  showFront,
  handleShowFrontChange,
  onDrop,
  sendPostcard,
  setShowFront,
}): JSX.Element => {
  const classes = useStyles();
  const dropzoneState = useDropzone({ accept: 'image/*', onDrop });
  const onLoadPhotoClick = () => {
    setShowFront(true);

    trackEvent(
      GAEventCategory.POSTCARD,
      GACommonEventAction.CLICK,
      'Charger une photo',
    );

    dropzoneState.open();
  };

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.flipCard} item lg={8}>
        <div
          className={clsx(
            classes.flipCardInner,
            !showFront && classes.flipForward,
          )}
        >
          <Front dropzoneState={dropzoneState} />
          <Back />
        </div>
      </Grid>

      <Grid item lg={4}>
        <Grid className={classes.toolbar} container>
          <Grid className={classes.buttonSmallContainer} item xs={12}>
            <FlipPostcardButton
              className={classes.actionButton}
              handleShowFrontChange={handleShowFrontChange}
            />
          </Grid>

          <Grid className={classes.buttonSmallContainer} item xs={12}>
            <LoadPhotoButton
              className={classes.actionButton}
              onLoadPhotoClick={onLoadPhotoClick}
            />
          </Grid>

          <Grid className={classes.sendButtonContainer} item xs={12}>
            <SendPostcardButton
              canSendPostcard={canSendPostcard}
              className={classes.actionButtonSend}
              sendPostcard={sendPostcard}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PostcardWidget = (): JSX.Element | null => {
  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);
  const {
    postcardContent,
    s3UploadedImage,
    showFront,
    setImagePreview,
    setPostcardContent,
    setSending,
    setShowCrop,
    setShowFront,
    setS3UploadedImage,
  } = useContext(postcardContext);

  const { uploadFileToS3 } = useUploadFileToS3({
    handleProgress: (_percent: number) => {},
    showErrorMessage,
  });

  const handleShowFrontChange = getHandleShowFrontChangeFunction({
    showFront,
    setShowFront,
  });

  const isSmallScreen = useIsSmallScreen();

  const onDrop = useCallback(
    async (acceptedFiles: File[]): Promise<void> => {
      const dataUrl = await handleDrop({
        acceptedFiles,
        isSmallScreen,
        setImagePreview,
        setShowCrop,
      });

      if (isSmallScreen && dataUrl) {
        const res: S3UploadOutputType = await uploadFileToS3(
          dataUrl,
          'image/jpg',
        );

        setImagePreview(res.dataURL);
        setS3UploadedImage(res);
      }
    },
    [
      isSmallScreen,
      setImagePreview,
      setS3UploadedImage,
      setShowCrop,
      uploadFileToS3,
    ],
  );

  const { selectedLink } = useSelectedLinkContext();

  const [sendPostcardMutation] = useMutation<SendPostcardOutputType>(
    SEND_POSTCARD_MUTATION,
  );

  if (!selectedLink) {
    return null;
  }

  const canSendPostcard = !!postcardContent && !!s3UploadedImage;

  const sendPostcard = getSendPostcardFunction({
    postcardContent,
    s3UploadedImage,
    selectedLink,
    sendPostcardMutation,
    setImagePreview,
    setPostcardContent,
    setSending,
    showErrorMessage,
    showSuccessMessage,
    setS3UploadedImage,
  });

  if (isSmallScreen) {
    return (
      <MobilePostcardWidget
        canSendPostcard={canSendPostcard}
        onDrop={onDrop}
        sendPostcard={sendPostcard}
      />
    );
  }

  return (
    <PostcardWidgetPure
      canSendPostcard={canSendPostcard}
      showFront={showFront}
      handleShowFrontChange={handleShowFrontChange}
      onDrop={onDrop}
      sendPostcard={sendPostcard}
      setShowFront={setShowFront}
    />
  );
};

export default PostcardWidget;
