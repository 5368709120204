import { useContext, useState } from 'react';
import { shareContext } from '../ShareContext';
import { useQuery } from '@apollo/client';
import {
  GET_VISIBLE_DOCUMENTS_FROM_FOLDER_QUERY,
  GetVisibleDocumentsFromFolderQueryOutputType,
} from '../../../../graphql/document/DocumentQueries';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { getSortedDocuments } from '../Helpers';
import { SharedDocumentType } from '../../../../types/document/Document';
import DocumentItem from './FolderContentExplorer/DocumentItem';
import useStyles from './FolderContentExplorer.styles';
import { DropzoneState } from 'react-dropzone';
import Description from './FolderContentExplorer/DeleteMode/Description';
import { deleteModeContext } from './FolderContentExplorer/DeleteMode/DeleteModeContext';
import { ImageViewerContextProvider } from '../../../../components/ImageViewer/ImageViewerContext';
import ImageViewer from '../../../../components/ImageViewer/ImageViewer';
import { filterImageDocument } from '../../../../utils/Document';

type FolderContentExplorerPureProps = {
  documents: SharedDocumentType[];
  dropzoneState: DropzoneState;
};

const FolderContentExplorerPure = ({
  documents,
  dropzoneState,
}: FolderContentExplorerPureProps): JSX.Element => {
  const classes = useStyles();
  const { getRootProps, getInputProps } = dropzoneState;

  return (
    <>
      <div
        className={classes.dragZone}
        data-test-id="dropzone"
        {...getRootProps({
          onClick: (event) => event.stopPropagation(),
        })}
      >
        <Description />

        <input {...getInputProps()} />

        {documents.map((document) => (
          <DocumentItem document={document} key={document.id} />
        ))}
      </div>

      <ImageViewer />
    </>
  );
};

type FilterDocumentsInputType = {
  currentUserKey: string | undefined;
  documents: SharedDocumentType[];
  isDeleteMode: boolean;
};

const filterDocuments = ({
  currentUserKey,
  documents,
  isDeleteMode,
}: FilterDocumentsInputType) => {
  if (isDeleteMode && currentUserKey) {
    return documents.filter((document) => document.creator === currentUserKey);
  }

  return documents;
};

const FolderContentExplorer = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { dropzoneState, selectedFolder } = useContext(shareContext);
  const { isDeleteMode } = useContext(deleteModeContext);
  const [currentViewedImage, setCurrentViewedImage] =
    useState<SharedDocumentType>();

  const { data } = useQuery<GetVisibleDocumentsFromFolderQueryOutputType>(
    GET_VISIBLE_DOCUMENTS_FROM_FOLDER_QUERY,
    {
      variables: {
        getVisibleDocumentsFromFolderInput: {
          channel: selectedLink?.channelKey,
          folderKey: selectedFolder?.key,
        },
      },
    },
  );

  if (!dropzoneState) {
    return null;
  }

  const sortedDocuments = getSortedDocuments(
    data?.visibleDocumentsFromFolder.documents || [],
  );

  const imageDocuments = filterImageDocument(sortedDocuments);

  const filteredAndSortedDocuments = filterDocuments({
    currentUserKey: selectedLink?.userKey,
    documents: sortedDocuments,
    isDeleteMode,
  });

  return (
    <ImageViewerContextProvider
      currentViewedImage={currentViewedImage}
      imageDocuments={imageDocuments}
      setCurrentViewedImage={setCurrentViewedImage}
    >
      <FolderContentExplorerPure
        documents={filteredAndSortedDocuments}
        dropzoneState={dropzoneState}
      />
    </ImageViewerContextProvider>
  );
};

export default FolderContentExplorer;
