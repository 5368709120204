import { useContext } from 'react';
import useStyles from './Message.styles';
import { FormattedMessage } from 'react-intl';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { getOtherParticipantFullName } from '../../../../../../types/videocall/VideoCall';

const NotificationAcknowledgeBusy = (): JSX.Element => {
  const { videoCall } = useContext(videoCallContext);
  const fullName = getOtherParticipantFullName(videoCall);
  const classes = useStyles();

  return (
    <div className={classes.messageContainer}>
      <audio autoPlay loop src="/sounds/notification.mp3" />
      <div className={classes.fullName}>{fullName}</div>
      <FormattedMessage
        id="videoCall.error.NotificationAcknowledgeBusy"
        values={{ fullName }}
      />
    </div>
  );
};

export default NotificationAcknowledgeBusy;
