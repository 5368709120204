import { ReactNode } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import {
  GET_RELATIONSHIPS_QUERY,
  GetRelationshipsOutputType,
} from '../../../graphql/relationship/RelationshipQueries';
import {
  getRelationshipStateLabel,
  getUserAccessLabel,
  RelationshipStateType,
  RelationshipType,
} from '../../../types/Relationship';
import useStyles from './RelationshipList.styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';
import { goToAssociatePage } from '../../../utils/history';
import FilledButton from '../../../components/Button/FilledButton';

type RelationshipRowType = {
  accessType: ReactNode;
  email: string;
  stateClassName: 'success' | 'warning';
  stateLabel: string;
};

type RelationshipListPureProps = {
  relationships: RelationshipRowType[];
};

const RelationshipListPure = ({
  relationships,
}: RelationshipListPureProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.th}>
            {intl.formatMessage({
              id: 'profile.relationship_management.list.header.tablet',
            })}
          </TableCell>
          <TableCell className={classes.th}>
            {intl.formatMessage({
              id: 'profile.relationship_management.list.header.state',
            })}
          </TableCell>
          <TableCell className={classes.th}>
            {intl.formatMessage({
              id: 'profile.relationship_management.list.header.accessType',
            })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {relationships.map((relationship, index) => (
          <TableRow key={index}>
            <TableCell className={classes.td}>{relationship.email}</TableCell>
            <TableCell
              className={clsx(classes.td, relationship.stateClassName)}
            >
              {relationship.stateLabel}
            </TableCell>
            <TableCell className={classes.td}>
              {relationship.accessType}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const getAccessTypeContent = (
  relationship: RelationshipType,
  intl: IntlShape,
): ReactNode => {
  if (
    relationship.state === RelationshipStateType.WAITING_WEB_USER_ACCEPTANCE
  ) {
    return (
      <FilledButton
        className="accept-button"
        color="green"
        onClick={() => goToAssociatePage(relationship.uuid)}
      >
        <FormattedMessage id="button.accept" />
      </FilledButton>
    );
  } else {
    return getUserAccessLabel(relationship, intl);
  }
};

const RelationshipList = (): JSX.Element => {
  const { data } = useQuery<GetRelationshipsOutputType>(
    GET_RELATIONSHIPS_QUERY,
  );

  const intl = useIntl();

  const relationships: RelationshipRowType[] = (
    data?.relationships.relationships || []
  ).map((relationship) => ({
    id: relationship.id,
    accessType: getAccessTypeContent(relationship, intl),
    email: relationship.mainUser.emails[0],
    stateClassName:
      relationship.state === RelationshipStateType.CONNECTED
        ? 'success'
        : 'warning',
    stateLabel: getRelationshipStateLabel(relationship, intl),
  }));

  return <RelationshipListPure relationships={relationships} />;
};

export default RelationshipList;
