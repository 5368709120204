import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { shareContext } from '../../ShareContext';
import FilledButton from '../../../../../components/Button/FilledButton';
import useStyles from './AddDocumentButton.styles';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import MobileExplorerToolbarMenuItemButton from '../../../../../mobile/App/Share/ExplorerToolbar/MobileExplorerToolbarMenuItemButton';

const AddDocumentButton = (): JSX.Element | null => {
  const classes = useStyles();
  const { dropzoneState } = useContext(shareContext);
  const isSmallScreen = useIsSmallScreen();
  const handleAddDocument = useCallback(
    () => dropzoneState?.open(),
    [dropzoneState],
  );

  if (!dropzoneState) {
    return null;
  }

  if (isSmallScreen) {
    return (
      <MobileExplorerToolbarMenuItemButton
        messageId="share.document.add.button"
        onClick={handleAddDocument}
      />
    );
  }

  return (
    <FilledButton
      className={classes.button}
      color="violet"
      data-test-id="add-document-button"
      onClick={handleAddDocument}
    >
      <FormattedMessage id="share.document.add.button" />
    </FilledButton>
  );
};

export default AddDocumentButton;
