import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    bottom: 40,
    left: '50%',
    padding: '20px 50px',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
  },
}));
