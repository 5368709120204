import { WebUserType } from '../types/User';
import { createCtxWithMandatoryValue } from './utils';

interface UserContextProps {
  webUser: WebUserType;
}

const userContext = createCtxWithMandatoryValue<UserContextProps>(),
  [useUserContext, UserContextProvider] = userContext;

export { useUserContext, UserContextProvider };
