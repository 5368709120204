import { useCallback, useContext, useState } from 'react';
import { alertContext } from '../../../components/Alert/AlertContext';
import { useMutation } from '@apollo/client';
import {
  ADD_RELATIONSHIP_MUTATION,
  AddRelationshipOutputType,
} from '../../../graphql/relationship/RelationshipMutations';
import { addRelationship } from '../../App/OnBoarding/OnBoardingTour/Relationship/Helpers';
import RelationshipTextField from './RelationshipTextField';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../components/Button/FilledButton';
import useStyles from './AddRelationship.styles';

type AddRelationshipPureProps = {
  email: string;
  hasEmailError: boolean;
  relationshipHasBeenAdded: boolean;
  handleAddRelationship: Function;
  setEmail: (newEmail: string) => void;
};

const AddRelationshipPure = ({
  email,
  hasEmailError,
  relationshipHasBeenAdded,
  handleAddRelationship,
  setEmail,
}: AddRelationshipPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>
        <FormattedMessage id="profile.relationship_management.associate.title" />
      </div>

      <div className={classes.subtitle}>
        <FormattedMessage id="profile.relationship_management.associate.subtitle" />
      </div>

      <RelationshipTextField
        email={email}
        inError={hasEmailError}
        relationshipHasBeenAdded={relationshipHasBeenAdded}
        setEmail={setEmail}
      />

      <div className={classes.buttonContainer}>
        <FilledButton
          className={classes.sendButton}
          color="violet"
          onClick={handleAddRelationship}
        >
          <FormattedMessage id="profile.relationship_management.sendButton" />
        </FilledButton>
      </div>
    </>
  );
};

const AddRelationship = (): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const { showErrorMessage } = useContext(alertContext);
  const [addRelationshipMutation] = useMutation<AddRelationshipOutputType>(
    ADD_RELATIONSHIP_MUTATION,
  );
  const [relationshipHasBeenAdded, setRelationshipHasBeenAdded] =
    useState<boolean>(false);

  const handleAddRelationship = useCallback(
    () =>
      addRelationship({
        email,
        addRelationshipMutation,
        setHasEmailError,
        setRelationshipHasBeenAdded,
        showErrorMessage,
      }),
    [
      email,
      addRelationshipMutation,
      setHasEmailError,
      setRelationshipHasBeenAdded,
      showErrorMessage,
    ],
  );

  const handleEmailChange = useCallback(
    (newEmail: string) => {
      setEmail(newEmail);
      setHasEmailError(false);
    },
    [setEmail, setHasEmailError],
  );

  return (
    <AddRelationshipPure
      email={email}
      hasEmailError={hasEmailError}
      relationshipHasBeenAdded={relationshipHasBeenAdded}
      handleAddRelationship={handleAddRelationship}
      setEmail={handleEmailChange}
    />
  );
};

export default AddRelationship;
