import { useCallback, useEffect, useState } from 'react';
import useStyles from './PartnerContentItem.styles';
import { PartnerContentType } from '../../../../../../types/partnerdocuments/PartnerDocument';
import { Button, Paper } from '@mui/material';
import Interweave from 'interweave';
import { FormattedMessage } from 'react-intl';
import { usePartnerPublicationsContext } from '../PartnerPublicationsContext';
import { getPartnerContentContentFromS3 } from '../../../../../../utils/Document';

type PartnerContentItemPureProps = {
  preview?: string;
  title: string;
  handleContentClick: () => void;
};

const PartnerContentItemPure = ({
  preview,
  title,
  handleContentClick,
}: PartnerContentItemPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.container}
      data-test-id="partner-content-item"
      onClick={handleContentClick}
    >
      <div className={classes.title}>{title}</div>

      <div
        className={classes.preview}
        data-test-id="partner-content-item-preview"
      >
        <Interweave content={preview} noHtml />
      </div>

      <Button className={classes.button} onClick={handleContentClick}>
        <FormattedMessage id={'partner.publications.content.readArticle'} />
      </Button>
    </Paper>
  );
};

type PartnerContentItemProps = {
  content: PartnerContentType;
};

const PartnerContentItem = ({
  content,
}: PartnerContentItemProps): JSX.Element => {
  const { setSelectedContent } = usePartnerPublicationsContext();
  const [preview, setPreview] = useState<string | undefined>(content?.content);
  const s3URL = content?.blob?.s3URL;

  useEffect(() => {
    if (!preview) {
      getPartnerContentContentFromS3({
        contentId: content.id,
        s3URL,
        callback: setPreview,
      });
    }
  }, [content.id, s3URL, preview, setPreview]);

  const handleContentClick = useCallback(
    () => setSelectedContent(content),
    [content, setSelectedContent],
  );

  return (
    <PartnerContentItemPure
      preview={preview}
      title={content.title}
      handleContentClick={handleContentClick}
    />
  );
};

export default PartnerContentItem;
