import useStyles from './Admin.styles';
import { Grid } from '@mui/material';
import Image from '../../components/Image/Image';
import OrientedTabletImage from '../../images/Admin/oriented_tablet.svg';
import AdminCategories from './Admin/AdminCategories';
import { FormattedMessage } from 'react-intl';

const Admin = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Grid
        alignContent="center"
        alignItems="center"
        className={classes.container}
        container
      >
        <Grid className={classes.textContainer} item md={6} xs={12}>
          <div className={classes.title}>
            <FormattedMessage id="admin.home.welcome" />
          </div>
          <div className={classes.text}>
            <FormattedMessage id="admin.home.description" />
          </div>
        </Grid>

        <Grid className={classes.imageContainer} item md={6} xs={12}>
          <Image className={classes.image} src={OrientedTabletImage} />
        </Grid>
      </Grid>

      <div className={classes.categoriesContainer}>
        <AdminCategories />
      </div>
    </>
  );
};

export default Admin;
