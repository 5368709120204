import { useCallback, useContext } from 'react';
import { Grid, Paper } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Image from '../../../../components/Image/Image';
import useStyles from './AdminCategory.styles';
import Route from '../../../../routes/Route';
import { showNoWebUserLinkToast } from '../../../../utils/Profile';
import { trackEvent } from '../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../GoogleAnalytics/GAEvent';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { alertContext } from '../../../../components/Alert/AlertContext';
import { getGoToSelectedChannelPageFunction } from '../../../../utils/history';

type AdminCategoryPureProps = {
  descriptionMessageId: string;
  icon: string;
  titleMessageId: string;
  handleClick: () => void;
};

const AdminCategoryPure = ({
  descriptionMessageId,
  icon,
  titleMessageId,
  handleClick,
}: AdminCategoryPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item md={3} xs={12}>
      <Paper
        className={classes.container}
        data-test-id="category-paper"
        onClick={handleClick}
      >
        <Grid container>
          <Grid className={classes.title} item xs={8}>
            <FormattedMessage id={titleMessageId} />
          </Grid>

          <Grid className={classes.iconContainer} item xs={4}>
            <Image className={classes.icon} src={icon} />
          </Grid>

          <Grid className={classes.description} item xs={12}>
            <FormattedMessage id={descriptionMessageId} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export type AdminCategoryProps = {
  descriptionMessageId: string;
  icon: string;
  route: Route;
  titleMessageId: string;
};

const AdminCategory = ({
  descriptionMessageId,
  icon,
  route,
  titleMessageId,
}: AdminCategoryProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { showErrorMessage } = useContext(alertContext);
  const intl = useIntl();

  const handleClick = useCallback(() => {
    if (!selectedLink) {
      showNoWebUserLinkToast({ selectedLink, showErrorMessage });
    } else {
      trackEvent(
        GAEventCategory.CATEGORY,
        GACommonEventAction.CLICK,
        intl.formatMessage({ id: titleMessageId }),
      );

      getGoToSelectedChannelPageFunction(selectedLink, route)();
    }
  }, [intl, route, selectedLink, titleMessageId, showErrorMessage]);

  return (
    <AdminCategoryPure
      descriptionMessageId={descriptionMessageId}
      icon={icon}
      titleMessageId={titleMessageId}
      handleClick={handleClick}
    />
  );
};

export default AdminCategory;
