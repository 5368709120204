import { useCallback, useContext, useState } from 'react';
import Tour, { ReactourStep } from 'reactour';
import { tourContext } from '../../../Context/TourContext';
import Step from './OnBoardingTour/Step';
import useStyles from './OnBoardingTour.styles';
import clsx from 'clsx';
import Landing from './OnBoardingTour/Landing';
import { ALWAYS_SHOW_ONBOARDING } from '../../../utils/constants';
import { StateSetter } from '../../../types/utils/React';
import { FormattedMessage } from 'react-intl';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { isSelfLink } from '../../../utils/Link';
import Relationship from './OnBoardingTour/Relationship';
import { LinkType } from '../../../types/Link';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';
import { useUserContext } from '../../../Context/UserContext';
import { WebUserType } from '../../../types/User';
import { useMutation } from '@apollo/client';
import {
  UPDATE_ONBOARDING_COMPLETION_MUTATION,
  UpdateOnBoardingCompletionOutputType,
} from '../../../graphql/webuser/WebUserMutations';
import { emptyFunction } from '../../../utils/Functions';
import { ClassNameMap } from '@mui/material';

export enum TOUR_STEPS {
  DISCOVER,
  COMMUNICATE,
  SHARE,
  ORGANIZE,
  HELP,
  ADMIN,
  PROFILE,
}

export enum PAGE_STEPS {
  LANDING,
  ASSOCIATION,
  TOUR,
}

export const getBaseClassNameSuffixForStep = (step?: TOUR_STEPS): string => {
  const getSuffix = () => {
    switch (step) {
      case TOUR_STEPS.DISCOVER:
        return 'discover';
      case TOUR_STEPS.COMMUNICATE:
        return 'communicate';
      case TOUR_STEPS.SHARE:
        return 'share';
      case TOUR_STEPS.ORGANIZE:
        return 'organize';
      case TOUR_STEPS.HELP:
        return 'help';
      case TOUR_STEPS.ADMIN:
        return 'admin';
      case TOUR_STEPS.PROFILE:
        return 'profile';
      default:
        return '';
    }
  };

  return `tour-step-${getSuffix()}`;
};

const getSteps = (
  classes: ClassNameMap,
  handleClose: Function,
): ReactourStep[] => [
  {
    selector: '',
    content: (
      <>
        <Step
          content={<FormattedMessage id="onboarding.step.discover.content" />}
          title={<FormattedMessage id="onboarding.step.discover.title" />}
        />
        <span className={classes.exitButton} onClick={() => handleClose()}>
          <FormattedMessage id="onboarding.step.discover.exit" />
        </span>
      </>
    ),
  },
  {
    selector: '.tour-step-communicate',
    content: (
      <Step
        content={<FormattedMessage id="onboarding.step.communicate.content" />}
        title={<FormattedMessage id="category.communicate.label" />}
      />
    ),
  },
  {
    selector: '.tour-step-share',
    content: (
      <Step
        content={<FormattedMessage id="onboarding.step.share.content" />}
        title={<FormattedMessage id="category.share.label" />}
      />
    ),
  },
  {
    selector: '.tour-step-organize',
    content: (
      <Step
        content={<FormattedMessage id="onboarding.step.organize.content" />}
        title={<FormattedMessage id="category.organize.label" />}
      />
    ),
  },
  {
    selector: '.tour-step-help',
    content: (
      <Step
        content={<FormattedMessage id="onboarding.step.help.content" />}
        title={<FormattedMessage id="category.help.label" />}
      />
    ),
  },
  {
    selector: '.tour-step-admin',
    content: (
      <Step
        content={<FormattedMessage id="onboarding.step.admin.content" />}
        title={<FormattedMessage id="button.adminMode" />}
      />
    ),
  },
  {
    selector: '.tour-step-profile',
    content: (
      <Step
        content={<FormattedMessage id="onboarding.step.profile.content" />}
        title={<FormattedMessage id="button.userAccount" />}
      />
    ),
  },
];

const getNextButton = (className: string, step: TOUR_STEPS): JSX.Element => {
  const messageId =
    step === TOUR_STEPS.DISCOVER ? 'button.discover' : 'button.next';

  return (
    <div className={className}>
      <FormattedMessage id={messageId} />
    </div>
  );
};

const previousButton = <FormattedMessage id="button.previous" />;

const getLastButton = (className: string): JSX.Element => (
  <div className={className}>
    <FormattedMessage id="button.end" />
  </div>
);

type OnBoardingTourPureProps = {
  currentStep: number;
  isTourOpen: boolean;
  handleClose: Function;
  setCurrentStep: StateSetter<number>;
};

const OnBoardingTourPure = ({
  currentStep,
  isTourOpen,
  handleClose,
  setCurrentStep,
}: OnBoardingTourPureProps): JSX.Element => {
  const classes = useStyles();
  const steps = getSteps(classes, handleClose);

  return (
    <Tour
      className={clsx([
        classes.tour,
        getBaseClassNameSuffixForStep(currentStep),
      ])}
      isOpen={isTourOpen}
      lastStepNextButton={getLastButton(classes.lastButton)}
      maskClassName={classes.mask}
      nextButton={getNextButton(classes.nextButton, currentStep)}
      prevButton={previousButton}
      steps={steps}
      showCloseButton={currentStep === TOUR_STEPS.DISCOVER}
      getCurrentStep={(curr: number): void => setCurrentStep(curr)}
      onRequestClose={(): void => handleClose()}
    />
  );
};

type IsOnBoardingShowableInputProps = {
  isMobileDevice: boolean;
  selectedLink: LinkType | undefined;
  webUser: WebUserType;
};
export const isOnBoardingShowable = ({
  isMobileDevice,
  selectedLink,
  webUser,
}: IsOnBoardingShowableInputProps): boolean =>
  ALWAYS_SHOW_ONBOARDING ||
  (!isMobileDevice &&
    !isSelfLink(selectedLink) &&
    !webUser.onBoardingCompleted);

const OnBoardingTour = (): JSX.Element | null => {
  const { currentStep, isTourOpen, setCurrentStep, setIsTourOpen } =
    useContext(tourContext);
  const [pageStep, setPageStep] = useState<PAGE_STEPS>(PAGE_STEPS.LANDING);
  const { selectedLink } = useSelectedLinkContext();
  const { webUser } = useUserContext();
  const isMobileDevice = useIsSmallScreen();
  const [updateOnBoardingCompletion] =
    useMutation<UpdateOnBoardingCompletionOutputType>(
      UPDATE_ONBOARDING_COMPLETION_MUTATION,
    );

  const handleClose = useCallback(() => {
    setIsTourOpen(false);
    updateOnBoardingCompletion({
      variables: {
        input: {
          onBoardingCompleted: true,
        },
      },
    }).then(emptyFunction);
  }, [setIsTourOpen, updateOnBoardingCompletion]);

  if (!isOnBoardingShowable({ isMobileDevice, selectedLink, webUser })) {
    return null;
  }

  if (isTourOpen) {
    if (pageStep === PAGE_STEPS.LANDING) {
      return (
        <Landing
          closeLanding={(): void => setPageStep(PAGE_STEPS.ASSOCIATION)}
        />
      );
    } else if (pageStep === PAGE_STEPS.ASSOCIATION) {
      return (
        <Relationship
          closeRelationship={(): void => setPageStep(PAGE_STEPS.TOUR)}
        />
      );
    }
  }

  return (
    <OnBoardingTourPure
      currentStep={currentStep}
      isTourOpen={isTourOpen}
      handleClose={handleClose}
      setCurrentStep={setCurrentStep}
    />
  );
};

export default OnBoardingTour;
