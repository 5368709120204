import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  attachmentTh: {
    width: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  clickableTd: {
    cursor: 'pointer',
  },
  container: {
    borderRadius: '10px 0 10px 10px',
    padding: 20,
    width: 'calc(100% - 40px)',

    '& td, th': {
      fontFamily: adminFontFamily,
      overflow: 'hidden',
      padding: '10px 5px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  dateTd: {
    textTransform: 'capitalize',
  },
  dateTh: {
    width: 140,
  },
  emailAddressTd: {
    fontSize: 15,
    fontWeight: 500,
    maxWidth: 280,

    '&.bold': {
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
  emailAddressTh: {
    width: 0,
  },
  message: {
    fontWeight: 300,
    overflow: 'hidden',
    paddingLeft: 10,
    textOverflow: 'ellipsis',
  },
  messageContainer: {
    maxWidth: 600,
  },
  row: {
    '&:hover': {
      backgroundColor: COLORS.LIGHT_BLUE,
    },
  },
}));
