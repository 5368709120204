import { ReactNode, useContext, useEffect, useState } from 'react';
import {
  initiateOrResumeVideoCall,
  manageMissingParticipantTimeout,
  useActionMutations,
} from '../Actions';
import { alertContext } from '../../../../../components/Alert/AlertContext';
import { videoCallContext } from '../../../../../Context/Communicate/VideoCall/VideoCallContext';
import {
  VideoCallParticipantType,
  VideoCallState,
} from '../../../../../types/videocall/VideoCall';
import Calling from './VideoStateManager/Calling';
import { emptyFunction } from '../../../../../utils/Functions';
import useStyles from './VideoStateManager.styles';
import MessageWithTimeout from './VideoStateManager/MessageWithTimeout';
import { Fade } from '@mui/material';
import NotificationAcknowledgeBusy from './VideoStateManager/NotificationAcknowledgeBusy';
import NotificationAcknowledgeTimeout from './VideoStateManager/NotificationAcknowledgeTimeout';
import Ended from './VideoStateManager/Ended';
import Interrupted from './VideoStateManager/Interrupted';
import CallAnswerTimeout from './VideoStateManager/CallAnswerTimeout';
import CallStartTimeout from './VideoStateManager/CallStartTimeout';
import CallRefused from './VideoStateManager/CallRefused';
import { TimeoutType } from '../../../../../types/utils/Timeout';
import { useUserContext } from '../../../../../Context/UserContext';

type VideoStateManagerPureProps = {
  children: ReactNode;
};

const VideoStateManagerPure = ({
  children,
}: VideoStateManagerPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.mask} />
      <Fade in>
        <div className={classes.container}>{children}</div>
      </Fade>
    </>
  );
};

type VideoStateManagerProps = {
  isPreview: boolean;
};

const VideoStateManager = ({
  isPreview,
}: VideoStateManagerProps): JSX.Element | null => {
  const actionMutations = useActionMutations();
  const { showErrorMessage } = useContext(alertContext);
  const { api, numberOfParticipants, videoCall, setVideoCall } =
    useContext(videoCallContext);
  const [timeoutHandler, setTimeoutHandler] = useState<TimeoutType>();
  const { webUser } = useUserContext();

  useEffect(
    () => {
      // Check that api and videoCall are initialized
      if (api && videoCall && !isPreview) {
        if (videoCall.participantType === VideoCallParticipantType.CALLER) {
          manageMissingParticipantTimeout({
            numberOfParticipants,
            timeoutHandler,
            videoCall,
            videoCallMatchState: VideoCallState.WaitingCallStart,
            videoCallTargetState: VideoCallState.CallStartTimeout,
            setTimeoutHandler,
            setVideoCall,
          });
        }

        manageMissingParticipantTimeout({
          numberOfParticipants,
          timeoutHandler,
          videoCall,
          videoCallMatchState: VideoCallState.Running,
          videoCallTargetState: VideoCallState.Interrupted,
          setTimeoutHandler,
          setVideoCall,
        });

        initiateOrResumeVideoCall({
          ...actionMutations,
          numberOfParticipants,
          videoCall,
          webUser,
          setVideoCall,
          showErrorMessage,
        }).then(emptyFunction);

        return () => {
          if (timeoutHandler) {
            clearTimeout(timeoutHandler);
          }
        };
      }
    },
    // Need to remove timeoutHandler to avoid recursively calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      api,
      isPreview,
      numberOfParticipants,
      videoCall,
      setTimeoutHandler,
      setVideoCall,
      showErrorMessage,
    ],
  );

  switch (videoCall?.command.currentState) {
    // Calling states
    case VideoCallState.WaitingCalledAnswer:
    case VideoCallState.WaitingNotificationAcknowledge:
      return (
        <VideoStateManagerPure>
          <Calling />
        </VideoStateManagerPure>
      );

    case VideoCallState.WaitingCallStart:
      if (videoCall.participantType === VideoCallParticipantType.CALLED) {
        return null;
      } else {
        return (
          <VideoStateManagerPure>
            <Calling />
          </VideoStateManagerPure>
        );
      }

    // Error and messages
    case VideoCallState.NotificationAcknowledgeBusy:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <NotificationAcknowledgeBusy />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    case VideoCallState.NotificationAcknowledgeTimeout:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <NotificationAcknowledgeTimeout />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    case VideoCallState.CalledAnswerTimeout:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <CallAnswerTimeout />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    case VideoCallState.CallStartTimeout:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <CallStartTimeout />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    case VideoCallState.CallRefused:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <CallRefused />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    case VideoCallState.Interrupted:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <Interrupted />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    case VideoCallState.Ended:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout>
            <Ended />
          </MessageWithTimeout>
        </VideoStateManagerPure>
      );

    // Errors
    case VideoCallState.EXIT_WITH_ERROR:
      return (
        <VideoStateManagerPure>
          <MessageWithTimeout />
        </VideoStateManagerPure>
      );

    // Nothing to show
    case VideoCallState.Initial:
    case VideoCallState.Running:
    default:
      return null;
  }
};

export default VideoStateManager;
