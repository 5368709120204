import { useCallback, useContext } from 'react';
import { calendarContext } from '../CalendarContext';
import moment from 'moment';
import CalendarUtils from '../../../utils/CalendarUtils';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';

type PreviousMonthPureProps = {
  label: string;
  handlePreviousMonthClick: () => void;
};

const PreviousMonthPure = ({
  label,
  handlePreviousMonthClick,
}: PreviousMonthPureProps): JSX.Element => (
  <div data-test-id="previous-month-div" onClick={handlePreviousMonthClick}>
    {label}
  </div>
);

const PreviousMonth = (): JSX.Element => {
  const { currentDate, handleSelectedDateChange, setCurrentDate } =
    useContext(calendarContext);
  const isSmallScreen = useIsSmallScreen();
  const previousMonthDate = moment(currentDate).subtract(1, 'month');
  const label = CalendarUtils.formatDateToMonthAndYearString(previousMonthDate);

  const handlePreviousMonthClick = useCallback(() => {
    setCurrentDate(previousMonthDate);

    if (!isSmallScreen) {
      handleSelectedDateChange(previousMonthDate);
    }
  }, [
    isSmallScreen,
    previousMonthDate,
    handleSelectedDateChange,
    setCurrentDate,
  ]);

  return (
    <PreviousMonthPure
      label={label}
      handlePreviousMonthClick={handlePreviousMonthClick}
    />
  );
};

export default PreviousMonth;
