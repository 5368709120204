import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  firstTab: {
    borderBottom: `1px solid ${COLORS.DARKER_BLUE}`,

    [theme.breakpoints.up('md')]: {
      borderRadius: '10px 0 0 0',
    },
  },
  lastTab: {
    borderBottom: `1px solid ${COLORS.DARKER_BLUE}`,

    [theme.breakpoints.up('md')]: {
      borderRadius: '0 10px 0 0',
    },

    '&.MuiButtonBase-root': {
      borderLeft: 0,
    },
  },
  middleTab: {
    border: `1px solid ${COLORS.DARKER_BLUE}`,
    borderTop: 'none',
  },
  tab: {
    fontFamily: adminFontFamily,
    fontSize: 15,
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  tabSelector: {
    [theme.breakpoints.up('md')]: {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'end',
      },
    },

    '& .MuiButtonBase-root.Mui-selected': {
      color: COLORS.WHITE,
      background: COLORS.DARKER_BLUE,
    },
    '& .MuiButtonBase-root': {
      color: COLORS.DARKER_BLUE,
      background: COLORS.WHITE,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));
