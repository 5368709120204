import { createContext, ReactNode } from 'react';
import {
  AlarmEnum,
  DaysOfRepeatType,
  HandleDaysOfRepeatChangeType,
} from '../../../../../types/organize/Organizer';
import { StateSetter } from '../../../../../types/utils/React';
import { emptyFunction } from '../../../../../utils/Functions';
import { Moment } from 'moment';
import {
  DEFAULT_OCCURRENCES_VALUE,
  EventFormErrorsType,
  EventFormValuesType,
} from './EventFormHelpers';
import { RecurrenceTypeEnum } from '../../../../../types/event/Event';

export type EventFormContextProps = EventFormValuesType & {
  disabled: boolean;
  eventFormErrors: EventFormErrorsType;
  handleDateBeginChange: (newDate: Moment | null) => void;
  handleDateEndChange: (newDate: Moment | null) => void;
  handleDaysOfRepeatChange: HandleDaysOfRepeatChangeType;
  setAlarm: StateSetter<AlarmEnum>;
  setComments: StateSetter<string>;
  setEventFormErrors: StateSetter<EventFormErrorsType>;
  setHasRecurrence: StateSetter<boolean>;
  setIsAllDay: StateSetter<boolean>;
  setName: StateSetter<string>;
  setOccurrences: StateSetter<number>;
  setRecurrenceType: StateSetter<RecurrenceTypeEnum>;
  setRecurrenceUntilDate: StateSetter<Moment | null>;
};

export const defaultDayOfRepeatValue: DaysOfRepeatType = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];

const initialState = {
  alarm: AlarmEnum.NO_ALARM,
  comments: '',
  dateBegin: null,
  dateEnd: null,
  daysOfRepeat: defaultDayOfRepeatValue,
  disabled: false,
  eventFormErrors: {},
  hasRecurrence: false,
  isAllDay: false,
  name: '',
  occurrences: DEFAULT_OCCURRENCES_VALUE,
  recurrenceType: RecurrenceTypeEnum.ALWAYS,
  recurrenceUntilDate: null,
  handleDateBeginChange: emptyFunction,
  handleDateEndChange: emptyFunction,
  handleDaysOfRepeatChange: emptyFunction,
  setAlarm: emptyFunction,
  setComments: emptyFunction,
  setEventFormErrors: emptyFunction,
  setHasRecurrence: emptyFunction,
  setIsAllDay: emptyFunction,
  setName: emptyFunction,
  setOccurrences: emptyFunction,
  setRecurrenceType: emptyFunction,
  setRecurrenceUntilDate: emptyFunction,
};

const eventFormContext = createContext<EventFormContextProps>(initialState),
  { Provider } = eventFormContext;

export type EventFormContextProviderProps = EventFormContextProps & {
  children: ReactNode;
};

const EventFormContextProvider = ({
  children,
  ...props
}: EventFormContextProviderProps): JSX.Element => (
  <Provider
    value={{
      ...props,
    }}
  >
    {children}
  </Provider>
);

export { eventFormContext, EventFormContextProvider };
