import { GA_TRACKER_ID } from '../utils/constants';

/* eslint-disable */
const initGATracker = () => {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga',
  );

  window.ga('create', GA_TRACKER_ID, 'auto');
  window.ga('set', 'page', '/');
  window.ga('send', 'pageview');
};

export const listenAxeptioCookies = () => {
  void 0 === window._axcb && (window._axcb = []);
  window._axcb.push(function (axeptio) {
    axeptio.on('cookies:complete', function (choices) {
      if (choices.google_analytics) {
        initGATracker();
      }
    });
  });
};
