import { gql } from '@apollo/client';
import { EventOrTimeslotArrayType } from '../../types/event/Event';

export const EVENT_FRAGMENT = gql`
  fragment eventFields on Event {
    id
    alarm
    comments
    creator {
      id
    }
    date
    daysOfRepeat
    done
    duration
    endOfRepeatDate
    ignoreDate
    isAllDay
    name
    occurrence
    originalEvent {
      id
    }
    recurrence
    type
    untilDate
  }
`;

export const TIMESLOT_FRAGMENT = gql`
  fragment timeslotFields on Timeslot {
    date
    duration
  }
`;

export const GET_EVENTS_QUERY = gql`
  query GetEvents($getEventsInput: GetEventsInput!) {
    getEvents(getEventsInput: $getEventsInput) {
      __typename

      ... on GetEventsErrorOutput {
        errorReason
      }

      ... on GetEventsOutput {
        filteredEvents {
          __typename

          ... on Event {
            ...eventFields
          }

          ... on Timeslot {
            ...timeslotFields
          }
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
  ${TIMESLOT_FRAGMENT}
`;

export enum GetEventsErrorEnum {
  NOT_LINKED = 'NOT_LINKED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_LINK = 'WRONG_LINK',
}

export type GetEventsQueryOutputType = {
  getEvents: {
    errorReason?: GetEventsErrorEnum;
    filteredEvents?: EventOrTimeslotArrayType;
  };
};
