export const ab2str = (buf: ArrayBuffer): string => {
  const arr = new Uint16Array(buf);
  return String.fromCharCode.apply(null, Array.from(arr));
};

export const getDataURLFromFile = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (!reader.result) {
          reject('File read result is empty');
        } else if (reader.result instanceof ArrayBuffer) {
          resolve(ab2str(reader.result));
        } else {
          resolve(reader.result);
        }
      },
      false,
    );

    reader.readAsDataURL(file);
  });
