import { useCallback, useContext } from 'react';
import { Fade, Grid } from '@mui/material';
import Image from '../../components/Image/Image';
import Profile from '../../images/Profile/profile.svg';
import useStyles from './ProfileButton.styles';
import { getGoToSelectedChannelPageFunction } from '../../utils/history';
import { FormattedMessage } from 'react-intl';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import Route from '../../routes/Route';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';
import { tourContext } from '../../Context/TourContext';
import { TOUR_STEPS } from '../App/OnBoarding/OnBoardingTour';
import clsx from 'clsx';

interface ButtonPureProps {
  currentStep: TOUR_STEPS;
  isTourOpen: boolean;
  show: boolean;
  onClick: Function;
}

const ProfileButtonPure = ({
  currentStep,
  isTourOpen,
  show,
  onClick,
}: ButtonPureProps): JSX.Element => {
  const classes = useStyles();
  const selectedClassName =
    isTourOpen && currentStep === TOUR_STEPS.PROFILE
      ? classes.selected
      : undefined;

  return (
    <Fade in={show}>
      <div>
        <Grid
          className={clsx([
            classes.container,
            'tour-step-profile',
            selectedClassName,
          ])}
          container
          alignItems="center"
          onClick={() => onClick()}
        >
          <Grid item xs={8} className={classes.profileText}>
            <FormattedMessage id="button.userAccount" />
          </Grid>
          <Grid item xs={4}>
            <Image className={classes.icon} src={Profile} width={50} />
          </Grid>
        </Grid>{' '}
      </div>
    </Fade>
  );
};

const ProfileButton = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { currentStep, isTourOpen } = useContext(tourContext);

  const onClick = useCallback(() => {
    trackEvent(GAEventCategory.BUTTON, GACommonEventAction.CLICK, 'Mon profil');

    const goToPageFn = getGoToSelectedChannelPageFunction(
      selectedLink,
      Route.PROFILE,
    );
    goToPageFn();
  }, [selectedLink]);

  return (
    <ProfileButtonPure
      currentStep={currentStep}
      isTourOpen={isTourOpen}
      show={!isTourOpen || currentStep >= TOUR_STEPS.PROFILE}
      onClick={onClick}
    />
  );
};

export default ProfileButton;
