import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  contentsContainer: {
    display: 'inline-block',
    paddingTop: 10,

    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  description: {
    marginTop: 20,

    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
  leftContainer: {
    float: 'left',
    paddingBottom: 20,
    width: '60%',

    [theme.breakpoints.down('md')]: {
      float: 'none',
      paddingBottom: 10,
      width: '100%',
    },
  },
  rightContainer: {
    float: 'right',
    paddingLeft: 20,
    width: '38%',

    [theme.breakpoints.down('md')]: {
      float: 'none',
      paddingBottom: 10,
      paddingLeft: 0,
      width: '100%',
    },
  },
}));
