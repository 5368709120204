import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  bar: {
    userSelect: 'none',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    color: COLORS.WHITE,
    fontSize: 12,
  },

  progress: {
    flex: 1,
    borderRadius: 5,
    margin: '0 10px',
    height: 6,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  progressKnob: {
    position: 'relative',
    height: 8,
    width: 8,
    border: `1.5px solid ${COLORS.WHITE}`,
    borderRadius: '50%',
    backgroundColor: COLORS.ORANGE,
  },
}));
