export const relationshipMessages = {
  'relationship.error.already_accepted':
    'La tablette Ardoiz a déjà été attachée à votre compte',
  'relationship.error.not_found':
    "L'identifiant indiqué ne correspond à aucune demande d'association",
  'relationship.error.other':
    "Erreur lors de l'association de la tablette Ardoiz à votre compte",
  'relationship.accept.success':
    'La tablette Ardoiz est désormais associée à votre compte',
  'relationship.accept.success.description':
    'Vous pouvez dès à présent intéragir avec la tablette',

  'relationship.textfield.placeholder':
    'Adresse email de votre proche / utilisateur tablette',
  'relationship.textfield.error': 'Adresse email invalide',
  'relationship.exit': 'Ignorer cette étape',
  'relationship.success': 'Votre demande a bien été envoyée à votre proche',

  'relationship.error.ALREADY_EXISTS':
    "Une demande d'association avec cette tablette est déjà en cours",
  'relationship.error.MAIN_USER_NOT_FOUND':
    "L'email saisi ne correspond à aucune tablette",
  'relationship.error.MINIMAL_VERSION_NOT_SATISFIED':
    "Pour s'associer facilement avec le détenteur de la tablette, celui-ci doit d'abord accepter la dernière mise à jour Ardoiz",
  'relationship.error.WEB_USER_ALREADY_LINKED_TO_CHANNEL':
    'Vous êtes déjà associé à cette tablette',
  'relationship.error.internal':
    "Une erreur est survenue, veuillez contacter l'administrateur",

  'relationship.access.admin': 'Administrateur',
  'relationship.access.classic': 'Classique',

  'relationship.state.CONNECTED': 'Association active',
  'relationship.state.WAITING_TABLET_ACCEPTANCE':
    'Demande envoyée le {createdDate}',
  'relationship.state.WAITING_WEB_USER_ACCEPTANCE':
    'Demande reçue le {createdDate}',
};
