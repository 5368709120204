import { useEffect, useState } from 'react';
import PageContentHeader from '../../../../components/PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import PageContentContainer from '../../../../components/PageContent/PageContentContainer';
import useStyles from './PartnerPublications.styles';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';
import { useQuery } from '@apollo/client';
import {
  GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY,
  GetPartnerAccountsWithFamilyPortalOutputType,
} from '../../../../graphql/partnerdocuments/PartnerDocumentsQueries';
import { getGoToSelectedChannelPageFunction } from '../../../../utils/history';
import Route from '../../../../routes/Route';
import { useParams } from 'react-router';
import { PartnerParams } from '../../../../types/route/Params';
import { PartnerAccountType } from '../../../../types/partnerdocuments/PartnerAccount';
import PartnerBookmarkList from './PartnerPublications/PartnerBookmarkList';
import PartnerContentList from './PartnerPublications/PartnerContentList';
import PartnerDocumentList from './PartnerPublications/PartnerDocumentList';
import { PartnerPublicationsContextProvider } from './PartnerPublications/PartnerPublicationsContext';
import { PartnerContentType } from '../../../../types/partnerdocuments/PartnerDocument';
import PartnerContentView from './PartnerPublications/PartnerContent/PartnerContentView';
import { PartnerContentContextProvider } from './PartnerPublications/PartnerContent/PartnerContentContext';

type PartnerPublicationsPureProps = {
  partnerAccount: PartnerAccountType;
};

const PartnerPublicationsPure = ({
  partnerAccount,
}: PartnerPublicationsPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <PageContentContainer>
      <PageContentHeader title={partnerAccount.partnerProfile.name} />

      <div className={classes.description}>
        <FormattedMessage id="partner.publications.description" />
      </div>

      <div className={classes.contentsContainer}>
        <div className={classes.leftContainer}>
          <PartnerDocumentList />
        </div>

        <div className={classes.rightContainer}>
          <PartnerContentList />
        </div>

        <div className={classes.leftContainer}>
          <PartnerBookmarkList />
        </div>
      </div>
    </PageContentContainer>
  );
};

const PartnerPublications = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { partnerId } = useParams<PartnerParams>();

  const [contents, setContents] = useState<PartnerContentType[]>([]);
  const [selectedContent, setSelectedContent] = useState<
    PartnerContentType | undefined
  >();

  const { data, loading } =
    useQuery<GetPartnerAccountsWithFamilyPortalOutputType>(
      GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY,
      {
        variables: {
          channel: selectedLink?.channelKey,
        },
      },
    );

  const partnerAccounts =
    data?.getPartnerAccountsWithFamilyPortal?.partnerAccounts || [];

  const partnerAccount = partnerAccounts.find((_) => _.id === partnerId);

  useEffect(() => {
    if (!loading && !partnerAccount) {
      getGoToSelectedChannelPageFunction(selectedLink, Route.HELP)();
    }
  }, [loading, partnerAccount, selectedLink]);

  if (loading || !partnerAccount) {
    return null;
  } else if (selectedContent) {
    return (
      <PartnerContentContextProvider
        value={{
          contents,
          partnerAccount,
          selectedContent,
          setSelectedContent,
        }}
      >
        <PartnerContentView />
      </PartnerContentContextProvider>
    );
  }

  return (
    <PartnerPublicationsContextProvider
      value={{
        contents,
        partnerAccount,
        setContents,
        setSelectedContent,
      }}
    >
      <PartnerPublicationsPure partnerAccount={partnerAccount} />
    </PartnerPublicationsContextProvider>
  );
};

export default PartnerPublications;
