import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';

export default makeStyles(() => ({
  notSelectedButton: {
    borderColor: COLORS.BLUE,
    color: COLORS.BLUE,

    '&:hover': {
      borderColor: COLORS.BLUE,
    },
  },
  rightButton: {
    marginLeft: 20,
  },
  selectedButton: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,

    '&:hover': {
      backgroundColor: COLORS.BLUE,
    },

    '&.Mui-disabled': {
      backgroundColor: COLORS.BLUE,
      color: COLORS.WHITE,
    },
  },
}));
