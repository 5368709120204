import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    borderRadius: 8,
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 20,
    padding: 15,
    position: 'relative',
  },
  preview: {
    fontSize: 14,
    lineHeight: '20px',
    overflow: 'hidden',
    marginTop: 30,
    maxHeight: 200,
  },
  publicationDate: {
    float: 'right',
    fontSize: 12,
  },
  title: {
    float: 'left',
    fontWeight: 'bold',
  },
}));
