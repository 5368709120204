import { useCallback, useContext } from 'react';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';
import { eventFormContext } from './EventFormContext';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventFormTextField from './EventFormTextField';
import { EventFormErrorsType } from './EventFormHelpers';
import {
  DateTimePickerType,
  getDateTimeBaseProps,
} from '../../../../../utils/DateAndTimePicker';

type TimeSelectPureProps = {
  disabled: boolean;
  propName: keyof EventFormErrorsType;
  value: Moment | null;
  withOffset: boolean;
  handleValueChange: (date: Moment | null) => void;
};

const TimeSelectPure = ({
  disabled,
  propName,
  value,
  withOffset,
  handleValueChange,
}: TimeSelectPureProps): JSX.Element => (
  <>
    {withOffset && <Grid item xs={1} />}

    <Grid item xs={5}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          {...getDateTimeBaseProps(DateTimePickerType.Time)}
          minutesStep={5}
          value={value}
          onChange={handleValueChange}
          renderInput={(params) => {
            const textFieldProps = {
              ...params,
              inputProps: {
                ...params.inputProps,
                readOnly: true,
              },
              InputProps: {
                ...params.InputProps,
                endAdornment: disabled
                  ? undefined
                  : params.InputProps?.endAdornment,
              },
            };

            return (
              <EventFormTextField
                formProperty={propName}
                textFieldProps={textFieldProps}
                value={params.value}
                setValue={handleValueChange}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Grid>
  </>
);

type TimeSelectProps = {
  propName: 'dateBegin' | 'dateEnd';
  setPropFnName: 'handleDateBeginChange' | 'handleDateEndChange';
};

const TimeSelect = ({
  propName,
  setPropFnName,
}: TimeSelectProps): JSX.Element => {
  const eventFormContextProps = useContext(eventFormContext);
  const value: Moment | null = eventFormContextProps[propName];
  const setValue: (newDate: Moment | null) => void =
    eventFormContextProps[setPropFnName];
  const withOffset = propName === 'dateEnd';
  const { disabled, eventFormErrors, setEventFormErrors } =
    eventFormContextProps;

  const handleValueChange = useCallback(
    (date: Moment | null) => {
      const newValue = date ? moment(date) : null;
      setValue(newValue);

      setEventFormErrors({
        ...eventFormErrors,
        [propName]: undefined,
      });
    },
    [eventFormErrors, propName, setEventFormErrors, setValue],
  );

  return (
    <TimeSelectPure
      disabled={disabled}
      propName={propName}
      value={value}
      withOffset={withOffset}
      handleValueChange={handleValueChange}
    />
  );
};

export default TimeSelect;
