import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  attachment: {
    textAlign: 'end',
  },
  container: {
    borderBottom: `1px solid ${COLORS.LIGHT_GREY}`,
    padding: '15px 10px 25px 0',
  },
  date: {
    fontSize: 12,
    textAlign: 'end',
    textTransform: 'capitalize',
  },
  email: {
    fontSize: 12,
  },
  message: {
    fontSize: 14,
    fontWeight: 300,
    overflow: 'hidden',
    maxHeight: 20,
    textOverflow: 'ellipsis',
  },
}));
