import { ChangeEvent, useCallback, useState } from 'react';
import useStyles from './AdminMessages.styles';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs } from '@mui/material';
import AdminMessagesTablePanel from './AdminMessages/AdminMessagesTable/AdminMessagesTablePanel';
import {
  AdminMessagesTableFilteredData,
  AdminMessagesTableReceivedData,
  AdminMessagesTableSentData,
} from './AdminMessages/AdminMessagesTable/AdminMessagesTableData';
import clsx from 'clsx';
import { AdminMessagesContextProvider } from './AdminMessages/AdminMessagesContext';
import { MessageType } from '../../../types/Message';
import AdminMessagesDeleteButton from './AdminMessages/AdminMessagesDeleteButton/AdminMessagesDeleteButton';
import AdminMessagesContainer from './AdminMessages/AdminMessagesContainer';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';

type AdminMessagesPureProps = {
  currentTab: number;
  handleCurrentTabChange: (_e: ChangeEvent, value: number) => void;
};

const AdminMessagesPure = ({
  currentTab,
  handleCurrentTabChange,
}: AdminMessagesPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AdminMessagesContainer>
      <AdminMessagesDeleteButton />

      <Tabs
        className={classes.tabSelector}
        value={currentTab}
        onChange={handleCurrentTabChange}
      >
        <Tab
          className={clsx(classes.tab, classes.firstTab)}
          label={<FormattedMessage id="admin.messages.filter.received" />}
        />
        <Tab
          className={clsx(classes.tab, classes.middleTab)}
          label={<FormattedMessage id="admin.messages.filter.sent" />}
        />
        <Tab
          className={clsx(classes.tab, classes.lastTab)}
          label={<FormattedMessage id="admin.messages.filter.filtered" />}
        />
      </Tabs>

      <AdminMessagesTablePanel currentValue={currentTab} value={0}>
        <AdminMessagesTableReceivedData />
      </AdminMessagesTablePanel>

      <AdminMessagesTablePanel currentValue={currentTab} value={1}>
        <AdminMessagesTableSentData />
      </AdminMessagesTablePanel>

      <AdminMessagesTablePanel currentValue={currentTab} value={2}>
        <AdminMessagesTableFilteredData />
      </AdminMessagesTablePanel>
    </AdminMessagesContainer>
  );
};

const AdminMessages = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [selectedMessages, setSelectedMessages] = useState<MessageType[]>([]);

  const handleCurrentTabChange = useCallback(
    (_e: ChangeEvent, value: number) => {
      if (value !== currentTab) {
        setSelectedMessages([]);
      }

      setCurrentTab(value);
    },
    [currentTab, setCurrentTab, setSelectedMessages],
  );

  if (!selectedLink?.mainUser) {
    return null;
  }

  return (
    <AdminMessagesContextProvider
      selectedMessages={selectedMessages}
      setSelectedMessages={setSelectedMessages}
    >
      <AdminMessagesPure
        currentTab={currentTab}
        handleCurrentTabChange={handleCurrentTabChange}
      />
    </AdminMessagesContextProvider>
  );
};

export default AdminMessages;
