import ProfileButton from '../../views/Profile/ProfileButton';
import { UseStylesProps } from '../../utils/useStyles';
import { Grid } from '@mui/material';

type MobileHeaderProps = UseStylesProps;

const MobileHeader = ({ classes }: MobileHeaderProps): JSX.Element => (
  <div className={classes.container}>
    <Grid container alignItems="center" className={classes.profileContainer}>
      <Grid item md={4} xs={12}>
        <ProfileButton />
      </Grid>
    </Grid>
  </div>
);

export default MobileHeader;
