import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { getGoToSelectedChannelPageFunction } from './history';
import Route from '../routes/Route';
import { LinkType } from '../types/Link';
import { ShowMessageFunctionType } from '../components/Alert/AlertContext';

const transformTagToLink =
  (selectedLink: LinkType | undefined) => (parts: ReactNode[]) =>
    (
      <span
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => {
          const goToPageFn = getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.PROFILE,
          );
          goToPageFn();
        }}
      >
        {parts}
      </span>
    );

type ShowNoWebUserLinkToastInputType = {
  selectedLink: LinkType | undefined;
  showErrorMessage: ShowMessageFunctionType;
};

export const showNoWebUserLinkToast = ({
  selectedLink,
  showErrorMessage,
}: ShowNoWebUserLinkToastInputType): void =>
  showErrorMessage(
    <FormattedMessage
      id="channelLink.noWebUserLinkToast"
      values={{
        a: transformTagToLink(selectedLink),
      }}
    />,
  );
