import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  showMenuButton: {
    position: 'fixed',
    left: 0,
    top: 0,
  },
  showMenuIcon: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 20,
    height: '1.5em',
    width: '1.5em',
  },
}));
