import { useCallback, useContext } from 'react';
import { Moment } from 'moment';
import CalendarUtils from '../../../utils/CalendarUtils';
import { calendarContext } from '../CalendarContext';
import useStyles from './DayCell.styles';
import { ClassNameMap } from '@mui/material';
import DayEvents from './DayCell/DayEvents';

type GetComputedLabelClassNameInputProps = {
  classes: ClassNameMap;
  isOutsideRange: boolean;
  isToday: boolean;
};

const getComputedLabelClassName = ({
  classes,
  isOutsideRange,
  isToday,
}: GetComputedLabelClassNameInputProps): string => {
  if (isToday) {
    return classes.labelToday;
  } else if (isOutsideRange) {
    return classes.labelOutsideRange;
  }

  return classes.label;
};

type GetComputedCellClassNameInputProps = {
  classes: ClassNameMap;
  isSelected: boolean;
};

const getComputedCellClassName = ({
  classes,
  isSelected,
}: GetComputedCellClassNameInputProps): string => {
  if (isSelected) {
    return classes.cellSelected;
  }

  return classes.cellNotSelected;
};

type DayCellPureProps = {
  date: Moment;
  day: string;
  isOutsideRange: boolean;
  isSelected: boolean;
  isToday: boolean;
  handleCellClick: () => void;
};

const DayCellPure = ({
  date,
  day,
  isOutsideRange,
  isSelected,
  isToday,
  handleCellClick,
}: DayCellPureProps): JSX.Element => {
  const classes = useStyles();
  const computedCellClassname = getComputedCellClassName({
    classes,
    isSelected,
  });
  const computedLabelClassname = getComputedLabelClassName({
    classes,
    isOutsideRange,
    isToday,
  });

  return (
    <td
      className={classes.cell}
      data-test-id="day-cell-td"
      onClick={handleCellClick}
    >
      <div className={computedCellClassname}>
        <div className={computedLabelClassname}>{day}</div>

        <DayEvents date={date} />
      </div>
    </td>
  );
};

type DayCellProps = {
  date: Moment;
};

const DayCell = ({ date }: DayCellProps): JSX.Element => {
  const {
    currentDate,
    selectedDate,
    handleSelectedDateChange,
    setCurrentDate,
  } = useContext(calendarContext);
  const day = CalendarUtils.formatDateToDayInMonthString(date);
  const isToday = CalendarUtils.isToday(date);
  const isOutsideRange = !CalendarUtils.isSameMonth(date, currentDate);
  const isSelected = selectedDate
    ? CalendarUtils.isSameDate(date, selectedDate)
    : false;

  const handleCellClick = useCallback(() => {
    setCurrentDate(date);
    handleSelectedDateChange(date);
  }, [date, handleSelectedDateChange, setCurrentDate]);

  return (
    <DayCellPure
      date={date}
      day={day}
      isOutsideRange={isOutsideRange}
      isSelected={isSelected}
      isToday={isToday}
      handleCellClick={handleCellClick}
    />
  );
};

export default DayCell;
