import { Grid } from '@mui/material';
import Image from '../Image/Image';
import GenericErrorImage from '../../images/Errors/generic_error.svg';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../Button/FilledButton';
import useStyles from './ErrorPage.styles';

export type ErrorPageProps = {};

const ErrorPage = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.errorContainer} item xs={12}>
        <div className={classes.title}>
          <FormattedMessage id="error_page.title" />
        </div>

        <FilledButton color="violet" onClick={() => window.location.reload()}>
          <FormattedMessage id="button.reloadPage" />
        </FilledButton>
      </Grid>

      <Grid item xs={4} />
      <Grid item xs={4}>
        <Image src={GenericErrorImage} />
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default ErrorPage;
