import { StateSetter } from '../../../../../types/utils/React';
import useStyles from './AdminMessagesTablePagination.styles';
import clsx from 'clsx';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const MAX_DISPLAYED_PAGES = 5;

type AdminMessagesTablePaginationPureProps = {
  currentPage: number;
  pageCount: number;
  setCurrentPage: StateSetter<number>;
};

const AdminMessagesTablePaginationPure = ({
  currentPage,
  pageCount,
  setCurrentPage,
}: AdminMessagesTablePaginationPureProps): JSX.Element => {
  const classes = useStyles();
  const lastPageIndex = pageCount - 1;

  const getPageNumbers = () => {
    const pages = [];

    if (currentPage > 0) {
      pages.push(
        <span
          className={classes.pageNumber}
          key="first"
          onClick={() => setCurrentPage(0)}
        >
          <KeyboardDoubleArrowLeftIcon className={classes.goToButton} />
        </span>,
      );
    }

    const getFirstPageIndex = () => {
      if (currentPage <= 2) {
        return 0;
      } else if (currentPage >= lastPageIndex - 2) {
        return pageCount - MAX_DISPLAYED_PAGES;
      } else return currentPage - 2;
    };

    const firstPageIndex = getFirstPageIndex();

    for (
      let i = firstPageIndex;
      i < firstPageIndex + MAX_DISPLAYED_PAGES;
      i++
    ) {
      const classNames = clsx([
        classes.pageNumber,
        currentPage === i && classes.selectedPageNumber,
      ]);

      pages.push(
        <span className={classNames} key={i} onClick={() => setCurrentPage(i)}>
          {i + 1}
        </span>,
      );
    }

    if (currentPage < lastPageIndex) {
      pages.push(
        <span
          className={classes.pageNumber}
          key="last"
          onClick={() => setCurrentPage(lastPageIndex)}
        >
          <KeyboardDoubleArrowRightIcon className={classes.goToButton} />
        </span>,
      );
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return <div className={classes.container}>{pageNumbers}</div>;
};

type AdminMessagesTableSimplePaginationProps = {
  currentPage: number;
  pageCount: number;
  setCurrentPage: StateSetter<number>;
};

const AdminMessagesTableSimplePagination = ({
  currentPage,
  pageCount,
  setCurrentPage,
}: AdminMessagesTableSimplePaginationProps): JSX.Element => {
  const classes = useStyles();

  const getSimplePageNumbers = () => {
    const pages = [];

    for (let i = 0; i < pageCount; i++) {
      const classNames = clsx([
        classes.pageNumber,
        currentPage === i && classes.selectedPageNumber,
      ]);

      pages.push(
        <span className={classNames} key={i} onClick={() => setCurrentPage(i)}>
          {i + 1}
        </span>,
      );
    }

    return pages;
  };

  const pageNumbers = getSimplePageNumbers();

  return <div className={classes.container}>{pageNumbers}</div>;
};

type AdminMessagesTablePaginationProps = {
  currentPage: number;
  pageCount: number;
  setCurrentPage: StateSetter<number>;
};

const AdminMessagesTablePagination = ({
  currentPage,
  pageCount,
  setCurrentPage,
}: AdminMessagesTablePaginationProps): JSX.Element | null => {
  if (pageCount <= 1) {
    return null;
  } else if (pageCount <= MAX_DISPLAYED_PAGES) {
    return (
      <AdminMessagesTableSimplePagination
        currentPage={currentPage}
        pageCount={pageCount}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  return (
    <AdminMessagesTablePaginationPure
      currentPage={currentPage}
      pageCount={pageCount}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default AdminMessagesTablePagination;
