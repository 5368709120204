import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  icon: {
    marginBottom: -12,
    marginLeft: -12,
    position: 'relative',
    width: 24,
  },
}));
