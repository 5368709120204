import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import Image from '../../../../../components/Image/Image';
import FolderIcon from '../../../../../images/Share/ShareExplorer/folder.png';
import FolderSharedIcon from '../../../../../images/Share/ShareExplorer/folder_shared.png';
import FolderSharedOwnerIcon from '../../../../../images/Share/ShareExplorer/folder_shared_owner.png';
import { FolderType } from '../../../../../types/document/Document';
import useIsSmallScreen from '../../../../../utils/useIsSmallScreen';
import { useUserContext } from '../../../../../Context/UserContext';

type FolderItemIconPureType = {
  width: string;
};

export const FolderSharedOwnerIconPure = ({
  width,
}: FolderItemIconPureType): JSX.Element => (
  <Image src={FolderSharedOwnerIcon} width={width} />
);

export const FolderSharedIconPure = ({
  width,
}: FolderItemIconPureType): JSX.Element => (
  <Image src={FolderSharedIcon} width={width} />
);

export const FolderIconPure = ({
  width,
}: FolderItemIconPureType): JSX.Element => (
  <Image src={FolderIcon} width={width} />
);

type FolderItemIconProps = {
  folder: FolderType;
};

const FolderItemIcon = ({
  folder,
}: FolderItemIconProps): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { webUser } = useUserContext();
  const isSmallScreen = useIsSmallScreen();
  const imageWidth = isSmallScreen ? '50px' : 'auto';

  const isSharedFolder =
    folder.sharedWith.filter((contact) => contact.id !== webUser.id).length > 0;

  if (isSharedFolder) {
    if (selectedLink?.userKey === folder.creator) {
      return <FolderSharedOwnerIconPure width={imageWidth} />;
    } else {
      return <FolderSharedIconPure width={imageWidth} />;
    }
  }

  return <FolderIconPure width={imageWidth} />;
};

export default FolderItemIcon;
