import {
  EventOrTimeslotType,
  isEvent,
  isEventOwner,
} from '../../../../types/event/Event';
import useStyles from './DayEvents.styles';
import clsx from 'clsx';
import { ClassNameMap } from '@mui/material';
import { WebUserType } from '../../../../types/User';
import { useUserContext } from '../../../../Context/UserContext';

const getColorClassName = (
  currentWebUser: WebUserType,
  eventOrTimeslot: EventOrTimeslotType,
  classes: ClassNameMap,
): string => {
  if (isEvent(eventOrTimeslot)) {
    if (isEventOwner(eventOrTimeslot, currentWebUser)) {
      return classes.ownedEvent;
    } else {
      return classes.guestEvent;
    }
  } else {
    return classes.busyEvent;
  }
};

type DayEventPureProps = {
  classNames: string[];
};

const DayEventPure = ({ classNames }: DayEventPureProps): JSX.Element => (
  <div className={clsx(classNames)} data-test-id="day-event-or-timeslot-div" />
);

type DayEventProps = {
  eventOrTimeslot: EventOrTimeslotType;
};

const DayEvent = ({ eventOrTimeslot }: DayEventProps): JSX.Element => {
  const { webUser } = useUserContext();
  const classes = useStyles();
  const colorClassName = getColorClassName(webUser, eventOrTimeslot, classes);

  return <DayEventPure classNames={[classes.dayEvent, colorClassName]} />;
};

export default DayEvent;
