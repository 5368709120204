import { gql } from '@apollo/client';
import { PartnerAccountType } from '../../types/partnerdocuments/PartnerAccount';
import { DOCUMENT_FRAGMENT } from '../document/DocumentQueries';
import {
  PartnerContentType,
  PartnerDocumentType,
} from '../../types/partnerdocuments/PartnerDocument';
import { BLOB_FRAGMENT } from '../s3/S3Mutations';

export const PARTNER_ACCOUNT_FRAGMENT = gql`
  fragment partnerAccountFields on PartnerAccount {
    id
    partnerProfile {
      id
      name
      thumbnailBlob {
        ...blobFields
      }
    }
  }
  ${BLOB_FRAGMENT}
`;

export const PARTNER_DOCUMENT_FRAGMENT = gql`
  fragment partnerDocumentFields on Document {
    ...documentFields
    publicationStartDate
    uri
  }
  ${DOCUMENT_FRAGMENT}
`;

export const PARTNER_CONTENT_FRAGMENT = gql`
  fragment partnerContentFields on Content {
    id
    blob {
      ...blobFields
    }
    content
    publicationStartDate
    thumbnailBlob {
      ...blobFields
    }
    title
  }
  ${BLOB_FRAGMENT}
`;

export const GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY = gql`
  query GetPartnerAccountsWithFamilyPortal($channel: String!) {
    getPartnerAccountsWithFamilyPortal(channel: $channel) {
      partnerAccounts {
        ...partnerAccountFields
      }
    }
  }
  ${PARTNER_ACCOUNT_FRAGMENT}
`;

export type GetPartnerAccountsWithFamilyPortalOutputType = {
  getPartnerAccountsWithFamilyPortal: {
    partnerAccounts: PartnerAccountType[];
  };
};

export const GET_PARTNER_DOCUMENTS_QUERY = gql`
  query GetPartnerDocuments(
    $getPartnerDocumentsInput: GetPartnerDocumentsInput!
  ) {
    getPartnerDocuments(getPartnerDocumentsInput: $getPartnerDocumentsInput) {
      documents {
        ...partnerDocumentFields
      }
    }
  }
  ${PARTNER_DOCUMENT_FRAGMENT}
`;

enum GetPartnerDocumentsErrorType {
  NOT_CONNECTED_TO_PARTNER = 'NOT_CONNECTED_TO_PARTNER',
  NO_FAMILY_PORTAL = 'NO_FAMILY_PORTAL',
  PARTNER_NOT_FOUND = 'PARTNER_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetPartnerDocumentsOutputType = {
  getPartnerDocuments: {
    documents: PartnerDocumentType[];
    errorReason: GetPartnerDocumentsErrorType[];
  };
};

export const GET_PARTNER_BOOKMARKS_QUERY = gql`
  query GetPartnerBookmarks(
    $getPartnerBookmarksInput: GetPartnerBookmarksInput!
  ) {
    getPartnerBookmarks(getPartnerBookmarksInput: $getPartnerBookmarksInput) {
      bookmarks {
        ...partnerDocumentFields
      }
    }
  }
  ${PARTNER_DOCUMENT_FRAGMENT}
`;

enum GetPartnerBookmarksErrorType {
  NOT_CONNECTED_TO_PARTNER = 'NOT_CONNECTED_TO_PARTNER',
  NO_FAMILY_PORTAL = 'NO_FAMILY_PORTAL',
  PARTNER_NOT_FOUND = 'PARTNER_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetPartnerBookmarksOutputType = {
  getPartnerBookmarks: {
    bookmarks: PartnerDocumentType[];
    errorReason: GetPartnerBookmarksErrorType[];
  };
};

export const GET_PARTNER_CONTENTS_QUERY = gql`
  query GetPartnerContents($getPartnerContentsInput: GetPartnerContentsInput!) {
    getPartnerContents(getPartnerContentsInput: $getPartnerContentsInput) {
      contents {
        ...partnerContentFields
      }
    }
  }
  ${PARTNER_CONTENT_FRAGMENT}
`;

enum GetPartnerContentsErrorType {
  NOT_CONNECTED_TO_PARTNER = 'NOT_CONNECTED_TO_PARTNER',
  NO_FAMILY_PORTAL = 'NO_FAMILY_PORTAL',
  PARTNER_NOT_FOUND = 'PARTNER_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type GetPartnerContentsOutputType = {
  getPartnerContents: {
    contents: PartnerContentType[];
    errorReason: GetPartnerContentsErrorType[];
  };
};
