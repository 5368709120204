import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  header: {
    paddingTop: 5,

    '@media (max-width: 1199px)': {
      padding: '0 10px',
    },
  },
  title: {
    marginTop: 30,
  },
}));
