import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    bottom: 20,
    fontSize: 16,
    fontWeight: 'bold',
    left: '50%',
    padding: '15px 50px',
    position: 'absolute',
    textTransform: 'none',
    transform: 'translate(-50%, 0)',
  },
  container: {
    backgroundColor: COLORS.LIGHTER_GREY,
    borderRadius: 16,
    height: 360,
    padding: 25,
    position: 'relative',
    textAlign: 'center',
  },
  image: {
    maxHeight: 190,
    maxWidth: 250,
  },
  name: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 25,
  },
}));
