import useIsSmallScreen from '../../utils/useIsSmallScreen';
import Menu from './MainMenu/Menu';
import MainMobileMenu from '../../mobile/Navigation/MainMenu/MainMobileMenu';
import MenuBackground from './MainMenu/MenuBackground';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import { isSelfLink } from '../../utils/Link';

const MainMenu = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const isSmallScreen = useIsSmallScreen();

  if (isSelfLink(selectedLink)) {
    return null;
  } else if (isSmallScreen) {
    return (
      <MenuBackground>
        <MainMobileMenu />
      </MenuBackground>
    );
  }

  return (
    <MenuBackground>
      <Menu onMenuItemClick={(goToPageFn: Function) => goToPageFn()} />
    </MenuBackground>
  );
};

export default MainMenu;
