import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiInput-root': {
      '& input': {
        borderRadius: 16,
        color: COLORS.BLUE,
        cursor: 'pointer',
        fontSize: 24,
        fontWeight: 'bold',
        height: 'auto',
        maxWidth: 200,
        padding: 0,
        textAlign: 'center',
        textTransform: 'capitalize',

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },

        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
      },
      '& .MuiSvgIcon-root': {
        color: COLORS.BLUE,
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));
