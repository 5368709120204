import { useCallback, useContext } from 'react';
import { imageViewerContext } from './ImageViewerContext';
import { Button } from '@mui/material';
import PreviousImage from '../../images/components/ImageViewer/previous.svg';
import Image from '../Image/Image';
import useStyles from './PreviousImageButton.styles';
import useIsSmallScreen from '../../utils/useIsSmallScreen';

type PreviousImageButtonPureProps = {
  handlePreviousImageClick: () => void;
};

const PreviousImageButtonPure = ({
  handlePreviousImageClick,
}: PreviousImageButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      data-test-id="previous-image-button"
      onClick={handlePreviousImageClick}
    >
      <Image src={PreviousImage} width={80} />
    </Button>
  );
};

const PreviousImageButton = (): JSX.Element | null => {
  const { currentViewedImage, imageDocuments, handleCurrentViewedImage } =
    useContext(imageViewerContext);

  const isSmallScreen = useIsSmallScreen();

  const currentViewedImageIndex = imageDocuments.findIndex(
    (image) => image.id === currentViewedImage?.id,
  );
  const previousImage = imageDocuments[currentViewedImageIndex - 1];

  const handlePreviousImageClick = useCallback(
    () => handleCurrentViewedImage(previousImage),
    [previousImage, handleCurrentViewedImage],
  );

  if (isSmallScreen || currentViewedImageIndex < 1) {
    return null;
  }

  return (
    <PreviousImageButtonPure
      handlePreviousImageClick={handlePreviousImageClick}
    />
  );
};

export default PreviousImageButton;
