import { ApolloCache, MutationFunction } from '@apollo/client';
import {
  GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
  GetVisibleFoldersFromFolderTypeQueryOutputType,
} from '../../../../../graphql/document/DocumentQueries';
import { LinkType } from '../../../../../types/Link';
import { FileTypeSwitcherFileTypes } from '../../../../../types/share/FileTypeSwitcherFileTypes';
import { FolderType } from '../../../../../types/document/Document';
import { StateSetter } from '../../../../../types/utils/React';
import { FormattedMessage } from 'react-intl';

import {
  DeleteFolderMutationOutputType,
  SelfUnsubscribeMutationOutputType,
} from '../../../../../graphql/document/DocumentMutations';
import { ShowMessageFunctionType } from '../../../../../components/Alert/AlertContext';
import { trackEvent } from '../../../../../GoogleAnalytics/GATracker';
import {
  GAEventCategory,
  GAShareEventActions,
  GAShareEventLabels,
} from '../../../../../GoogleAnalytics/GAEvent';

type GetUpdateCacheFunction = {
  selectedFileType: FileTypeSwitcherFileTypes;
  selectedFolder?: FolderType;
  selectedLink?: LinkType;
  setSelectedFolder: StateSetter<FolderType | undefined>;
  setShowDeleteOrQuitModal: StateSetter<boolean>;
};

export const getUpdateCacheFunction =
  ({
    selectedFileType,
    selectedFolder,
    selectedLink,
    setSelectedFolder,
    setShowDeleteOrQuitModal,
  }: GetUpdateCacheFunction) =>
  (cache: ApolloCache<any>): void => {
    const variables = {
      getVisibleFoldersByFolderTypeInput: {
        channel: selectedLink?.channelKey,
        folderType: selectedFileType,
      },
    };

    const data =
      cache.readQuery<GetVisibleFoldersFromFolderTypeQueryOutputType>({
        query: GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
        variables,
      });

    const updatedData = {
      ...data,
      visibleFoldersFromFolderType: {
        ...data?.visibleFoldersFromFolderType,
        folders: data?.visibleFoldersFromFolderType.folders.filter(
          (folder) => folder.id !== selectedFolder?.id,
        ),
      },
    };

    cache.writeQuery({
      data: updatedData,
      query: GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
      variables,
    });

    setShowDeleteOrQuitModal(false);
    setSelectedFolder(undefined);
  };

type GetHandleValidateFunction = {
  isFolderAdmin: boolean;
  selectedLink?: LinkType;
  selectedFolder?: FolderType;
  deleteFolder: MutationFunction<DeleteFolderMutationOutputType>;
  selfUnsubscribe: MutationFunction<SelfUnsubscribeMutationOutputType>;
  showErrorMessage: ShowMessageFunctionType;
  showSuccessMessage: ShowMessageFunctionType;
};

export const getHandleValidateFunction =
  ({
    isFolderAdmin,
    selectedLink,
    selectedFolder,
    deleteFolder,
    selfUnsubscribe,
    showErrorMessage,
    showSuccessMessage,
  }: GetHandleValidateFunction) =>
  (): void => {
    const input = {
      channel: selectedLink?.channelKey,
      folderKey: selectedFolder?.key,
    };

    if (isFolderAdmin) {
      deleteFolder({
        variables: {
          deleteFolderInput: input,
        },
      })
        .then(({ data }) => {
          if (data?.deleteFolder.isDeleted) {
            trackEvent(
              GAEventCategory.SHARE,
              GAShareEventActions.DELETE_FOLDER,
              selectedFolder?.sharedWith.length || 0 > 1
                ? GAShareEventLabels.SHARED
                : GAShareEventLabels.NOT_SHARED,
            );

            showSuccessMessage(
              <FormattedMessage id="share.folder.delete.result.success" />,
            );
          } else {
            showErrorMessage(
              <FormattedMessage id="share.folder.delete.result.error" />,
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      selfUnsubscribe({
        variables: {
          selfUnsubscribeInput: input,
        },
      })
        .then(({ data }) => {
          if (data?.selfUnsubscribe.folder) {
            trackEvent(
              GAEventCategory.SHARE,
              GAShareEventActions.QUIT_SHARED_FOLDER,
              GAShareEventActions.QUIT_SHARED_FOLDER,
            );

            showSuccessMessage(
              <FormattedMessage id="share.folder.quit.result.success" />,
            );
          } else {
            showErrorMessage(
              <FormattedMessage id="share.folder.quit.result.error" />,
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

type GetModalMessagesIdInputType = {
  isFolderAdmin: boolean;
};

type GetModalMessagesIdOutputType = {
  descriptionMessageId: string;
  titleMessageId: string;
};

export const getModalMessagesId = ({
  isFolderAdmin,
}: GetModalMessagesIdInputType): GetModalMessagesIdOutputType =>
  isFolderAdmin
    ? {
        descriptionMessageId: 'share.folder.delete.description',
        titleMessageId: 'share.folder.settings.deleteFolder',
      }
    : {
        descriptionMessageId: 'share.folder.quit.description',
        titleMessageId: 'share.folder.settings.quitFolder',
      };
