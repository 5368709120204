import useStyles from './Relationship.styles';
import { MouseEventHandler, useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import TabletLeft from '../../../../images/OnBoarding/tablet-left.png';
import TabletRight from '../../../../images/OnBoarding/tablet-right.png';
import Image from '../../../../components/Image/Image';
import FilledButton from '../../../../components/Button/FilledButton';
import { useMutation } from '@apollo/client';
import {
  ADD_RELATIONSHIP_MUTATION,
  AddRelationshipOutputType,
} from '../../../../graphql/relationship/RelationshipMutations';
import { alertContext } from '../../../../components/Alert/AlertContext';
import { addRelationship } from './Relationship/Helpers';
import OnBoardingRelationshipTextField from './Relationship/OnBoardingRelationshipTextField';

type RelationshipPureProps = {
  email: string;
  hasEmailError: boolean;
  relationshipHasBeenAdded: boolean;
  closeRelationship: MouseEventHandler;
  handleAddRelationship: Function;
  setEmail: (newEmail: string) => void;
};

const RelationshipPure = ({
  email,
  hasEmailError,
  relationshipHasBeenAdded,
  closeRelationship,
  handleAddRelationship,
  setEmail,
}: RelationshipPureProps): JSX.Element => {
  const classes = useStyles();
  const submitMessage = relationshipHasBeenAdded
    ? 'button.resume'
    : 'button.validate';
  const submitHandler = relationshipHasBeenAdded
    ? closeRelationship
    : handleAddRelationship;
  return (
    <Grid className={classes.fullScreenContainer} container>
      <Grid item xs={2}>
        <Image src={TabletRight} width="100%" />
      </Grid>

      <Grid item xs={8}>
        <div className={classes.title}>
          <FormattedMessage id="onboarding.associate.title" />
        </div>

        <div className={classes.description}>
          <span className={classes.subtitle}>
            <FormattedMessage id="onboarding.associate.subtitle" />
          </span>
          <br />
          <FormattedMessage id="onboarding.associate.description" />
        </div>

        <div className={classes.textFieldContainer}>
          <OnBoardingRelationshipTextField
            email={email}
            inError={hasEmailError}
            relationshipHasBeenAdded={relationshipHasBeenAdded}
            setEmail={setEmail}
          />
        </div>

        <Grid className={classes.buttonsContainer} container>
          <Grid item xs={5} />
          <Grid item xs={2}>
            <FilledButton
              className={classes.validateButton}
              color="violet"
              onClick={submitHandler}
            >
              <FormattedMessage id={submitMessage} />
            </FilledButton>
          </Grid>

          <Grid className={classes.exitButton} item xs={2}>
            <span onClick={closeRelationship} hidden={relationshipHasBeenAdded}>
              <FormattedMessage id="relationship.exit" />
            </span>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Image src={TabletLeft} width="100%" />
      </Grid>
    </Grid>
  );
};

type RelationshipProps = {
  closeRelationship: MouseEventHandler;
};

const Relationship = ({
  closeRelationship,
}: RelationshipProps): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const { showErrorMessage } = useContext(alertContext);
  const [addRelationshipMutation] = useMutation<AddRelationshipOutputType>(
    ADD_RELATIONSHIP_MUTATION,
  );
  const [relationshipHasBeenAdded, setRelationshipHasBeenAdded] =
    useState<boolean>(false);

  const handleAddRelationship = useCallback(
    () =>
      addRelationship({
        email,
        addRelationshipMutation,
        setHasEmailError,
        setRelationshipHasBeenAdded,
        showErrorMessage,
      }),
    [
      email,
      addRelationshipMutation,
      setHasEmailError,
      setRelationshipHasBeenAdded,
      showErrorMessage,
    ],
  );

  const handleEmailChange = useCallback(
    (newEmail: string) => {
      setEmail(newEmail);
      setHasEmailError(false);
    },
    [setEmail, setHasEmailError],
  );

  return (
    <RelationshipPure
      email={email}
      hasEmailError={hasEmailError}
      relationshipHasBeenAdded={relationshipHasBeenAdded}
      closeRelationship={closeRelationship}
      handleAddRelationship={handleAddRelationship}
      setEmail={handleEmailChange}
    />
  );
};

export default Relationship;
