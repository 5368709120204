import { FormattedMessage } from 'react-intl';
import FileGrid, {
  FileGridType,
} from '../../../../components/FileGrid/FileGrid';

const TUTORIALS_PATH_PREFIX = '/pdf/help/tablet-tutorials';

const tabletTutorials: Array<FileGridType> = [
  {
    fileName: `Gmail.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.gmail" />
    ),
  },
  {
    fileName: `Instagram.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.instagram" />
    ),
  },
  {
    fileName: `Internet.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.internet" />
    ),
  },
  {
    fileName: `Jouer.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.play" />
    ),
  },
  {
    fileName: `L'e-mail.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.mail" />
    ),
  },
  {
    fileName: `L'interface guidée.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.guided_interface" />
    ),
  },
  {
    fileName: `L'écran tactile.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.touch_screen" />
    ),
  },
  {
    fileName: `Messenger.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.messenger" />
    ),
  },
  {
    fileName: `Playstore.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.playstore" />
    ),
  },
  {
    fileName: `Skype.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.skype" />
    ),
  },
  {
    fileName: `Vos contacts.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.contacts" />
    ),
  },
  {
    fileName: `WhatsApp.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.whatsapp" />
    ),
  },
  {
    fileName: `Zoom.pdf`,
    filePath: TUTORIALS_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.tablet-tutorials.zoom" />
    ),
  },
];

export const TabletTutorials = (): JSX.Element => (
  <FileGrid
    files={tabletTutorials}
    pageTitle="category.help.documents.tablet-tutorials.description"
  />
);

export default TabletTutorials;
