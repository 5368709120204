import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: 'calc(100vh - 230px)',
    marginTop: 20,
  },
  item: {
    backgroundColor: COLORS.LIGHT_VIOLET,
    borderRadius: 16,
  },
  leftItem: {
    padding: 35,
    width: '72%',

    [theme.breakpoints.down('md')]: {
      padding: '20px 10px',
      width: 'calc(100% - 20px)',
    },
  },
  rightItem: {
    marginLeft: 30,
    padding: 20,
    width: '25%',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
