import { useContext, useEffect } from 'react';
import useStyles from './Message.styles';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import {
  COMMIT_CALL_INTERRUPTED_VIDEO_CALL_MUTATION,
  CommitCallInterruptedVideoCallOutputType,
} from '../../../../../../graphql/communicate/videocall/VideoCallMutations';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { emptyFunction } from '../../../../../../utils/Functions';
import { getOtherParticipantFullName } from '../../../../../../types/videocall/VideoCall';

const InterruptedPure = (): JSX.Element => {
  const { videoCall } = useContext(videoCallContext);
  const fullName = getOtherParticipantFullName(videoCall);
  const classes = useStyles();

  return (
    <div className={classes.messageContainer}>
      <audio autoPlay loop src="/sounds/notification.mp3" />
      <FormattedMessage id="videoCall.message.Interrupted1" />
      <div className={classes.fullName}>{fullName}</div>
      <FormattedMessage id="videoCall.message.Interrupted2" />
    </div>
  );
};

const Interrupted = (): JSX.Element => {
  const { videoCall } = useContext(videoCallContext);
  const [commitCallInterruptedVideoCall, commitCallInterruptedVideoCallResult] =
    useMutation<CommitCallInterruptedVideoCallOutputType>(
      COMMIT_CALL_INTERRUPTED_VIDEO_CALL_MUTATION,
    );

  useEffect(() => {
    if (!commitCallInterruptedVideoCallResult.called && videoCall) {
      commitCallInterruptedVideoCall({
        variables: { videoCallID: videoCall.id },
      })
        .then(emptyFunction)
        .catch(emptyFunction);
    }
  });

  return <InterruptedPure />;
};

export default Interrupted;
