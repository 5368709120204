import { gql } from '@apollo/client';
import { VideoCallQueryOutputType } from '../../../types/videocall/VideoCall';
import { VIDEO_CALL_FRAGMENT } from './VideoCallQueries';

/******************************** CREATE ********************************/
export const CREATE_VIDEO_CALL_MUTATION = gql`
  mutation CreateVideoCallMutation($input: CreateVideoCallInput!) {
    createVideoCall(input: $input) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type CreateVideoCallOutputType = {
  createVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

/******************************** NOTIFY ********************************/
export const SEND_NOTIFICATION_VIDEO_CALL_MUTATION = gql`
  mutation SendNotificationVideoCallMutation($videoCallID: String!) {
    sendNotificationVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type SendNotificationVideoCallOutputType = {
  sendNotificationVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const WAIT_NOTIFICATION_ACKNOWLEDGMENT_VIDEO_CALL_MUTATION = gql`
  mutation WaitNotificationAcknowledgmentVideoCallMutation(
    $videoCallID: String!
  ) {
    waitNotificationAcknowledgmentVideoCall(videoCallID: $videoCallID) {
      missingCallNotificationHasBeenSent
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type WaitNotificationAcknowledgmentVideoCallOutputType = {
  waitNotificationAcknowledgmentVideoCall: {
    missingCallNotificationHasBeenSent: boolean;
    videoCall: VideoCallQueryOutputType;
  };
};

export const ACKNOWLEDGE_NOTIFICATION_VIDEO_CALL_MUTATION = gql`
  mutation AcknowledgeNotificationVideoCallMutation($videoCallID: String!) {
    acknowledgeNotificationVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type AcknowledgeNotificationVideoCallOutputType = {
  acknowledgeNotificationVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

/******************************** ACCEPT ********************************/
export const WAIT_ACCEPT_VIDEO_CALL_MUTATION = gql`
  mutation WaitAcceptVideoCallMutation($videoCallID: String!) {
    waitAcceptVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type WaitAcceptVideoCallOutputType = {
  waitAcceptVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const ACCEPT_VIDEO_CALL_MUTATION = gql`
  mutation AcceptVideoCallMutation($videoCallID: String!) {
    acceptVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type AcceptVideoCallOutputType = {
  acceptVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const REFUSE_VIDEO_CALL_MUTATION = gql`
  mutation RefuseVideoCallMutation($videoCallID: String!) {
    refuseVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type RefuseVideoCallOutputType = {
  refuseVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

/******************************** START ********************************/
export const WAIT_START_VIDEO_CALL_MUTATION = gql`
  mutation WaitStartVideoCallMutation($videoCallID: String!) {
    waitStartVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type WaitStartVideoCallOutputType = {
  waitStartVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const START_VIDEO_CALL_MUTATION = gql`
  mutation StartVideoCallMutation($videoCallID: String!) {
    startVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type StartVideoCallOutputType = {
  startVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const COMMIT_CALL_START_TIMEOUT_VIDEO_CALL_MUTATION = gql`
  mutation CommitCallStartTimeoutVideoCallMutation($videoCallID: String!) {
    commitCallStartTimeoutVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type CommitCallStartTimeoutVideoCallOutputType = {
  commitCallStartTimeoutVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

/******************************** STOP ********************************/
export const STOP_VIDEO_CALL_MUTATION = gql`
  mutation StopVideoCallMutation($videoCallID: String!) {
    stopVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type StopVideoCallOutputType = {
  stopVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};

export const COMMIT_CALL_INTERRUPTED_VIDEO_CALL_MUTATION = gql`
  mutation CommitCallStartTimeoutVideoCallMutation($videoCallID: String!) {
    commitCallInterruptedVideoCall(videoCallID: $videoCallID) {
      videoCall {
        ...videoCallFragment
      }
    }
  }
  ${VIDEO_CALL_FRAGMENT}
`;

export type CommitCallInterruptedVideoCallOutputType = {
  commitCallInterruptedVideoCall: {
    videoCall: VideoCallQueryOutputType;
  };
};
