import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    background: `linear-gradient(180deg, ${COLORS.DARK_BLUE} 50%, ${COLORS.LIGHT_BLUE} 50%)`,

    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 102px)',
      padding: '20px 30px',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh)',
    },
  },
  title: {
    color: COLORS.WHITE,
    fontFamily: adminFontFamily,
    fontSize: 30,
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      padding: '50px 20px 15px',
    },
  },
}));
