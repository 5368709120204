import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  hidden: {
    visibility: 'hidden',
  },
  image: {
    paddingRight: 20,
  },
}));
