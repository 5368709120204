import { FormattedMessage } from 'react-intl';
import useStyles from './EmptyShareExplorer.styles';

const EmptyShareExplorer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div>
        <FormattedMessage id="share.explorer.empty.0" />
      </div>
      <ol>
        <li className={classes.list}>
          <FormattedMessage id="share.explorer.empty.1" />
        </li>
        <li className={classes.list}>
          <FormattedMessage id="share.explorer.empty.2" />
        </li>
        <li>
          <FormattedMessage id="share.explorer.empty.3" />
        </li>
        <li>
          <FormattedMessage id="share.explorer.empty.4" />
        </li>
      </ol>
    </>
  );
};

export default EmptyShareExplorer;
