import { useCallback, useContext } from 'react';
import MobileExplorerToolbarMenuItemButton from '../MobileExplorerToolbarMenuItemButton';
import { openCreateFolderModalButtonContext } from '../../../../../views/App/Share/ExplorerToolbar/CreateFolder/OpenCreateFolderModalButtonContext';

type MobileOpenCreateFolderModalButtonPureProps = {
  handleClick: () => void;
};

const MobileOpenCreateFolderModalButtonPure = ({
  handleClick,
}: MobileOpenCreateFolderModalButtonPureProps): JSX.Element => (
  <MobileExplorerToolbarMenuItemButton
    messageId="share.folder.create.button"
    onClick={handleClick}
  />
);

const MobileOpenCreateFolderModalButton = (): JSX.Element => {
  const { setShowCreateFolderModal } = useContext(
    openCreateFolderModalButtonContext,
  );
  const handleClick = useCallback(
    () => setShowCreateFolderModal(true),
    [setShowCreateFolderModal],
  );

  return <MobileOpenCreateFolderModalButtonPure handleClick={handleClick} />;
};

export default MobileOpenCreateFolderModalButton;
