export enum GAEventCategory {
  ADMIN_MESSAGES = 'Administration Messages',
  BUTTON = 'Bouton',
  CATEGORY = 'Catégorie',
  CHANNEL_LINK = 'Tablette',
  HELP = 'Accompagner',
  LINK = 'Lien',
  MENU = 'Menu',
  POSTCARD = 'Carte postale',
  SHARE = 'Partage de document',
  VIDEO_CALL = 'Visio',
}

export type GAEventAction =
  | GACommonEventAction
  | GAShareEventActions
  | GAVideoCallEventAction
  | GAAdminMessagesEventActions;

export enum GACommonEventAction {
  CLICK = 'Clic',
  ERROR = 'Erreur',
  SELECT = 'Sélection',
  SEND = 'Envoi',
}

export enum GAVideoCallEventAction {
  VIDEO_CALL_END = "Fin de l'appel",
  VIDEO_CALL_START = "Début de l'appel",
}

export enum GAShareEventActions {
  ADD_DOCUMENT = 'Ajouter un document',
  CREATE_FOLDER = "Création d'un dossier",
  DELETE_FOLDER = "Suppression d'un dossier",
  DELETE_DOCUMENT = 'Supprimer un document',
  EDIT_SHARED_FOLDER_ACCESS = 'Modifier les accès à un dossier partagé',
  QUIT_SHARED_FOLDER = 'Quitter un dossier partagé',
}

export enum GAShareEventLabels {
  DOCUMENT = 'Document',
  MUSIC = 'Musique',
  NOT_SHARED = 'Non partagé',
  PICTURE_VIDEO = 'Photo/Vidéo',
  SHARED = 'Partagé',
}

export enum GAAdminMessagesEventActions {
  DELETE_MESSAGES = 'Suppression de messages',
}
