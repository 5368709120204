import { UserType, WebUserType } from '../types/User';
import { isSelfLink } from './Link';

export const getWebUserFullName = (webUser: WebUserType): string =>
  `${webUser.firstName} ${webUser.lastName}`;

export const getUserFullName = (user?: UserType): string | undefined =>
  user ? `${user.firstName} ${user.lastName}` : undefined;

export const webUserHasSelfLink = (webUser: WebUserType): boolean =>
  !!webUser.links.find((link) => isSelfLink(link));
