import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    borderRadius: 40,
    height: 64,
    width: 64,

    '&:hover': {
      opacity: 1,
    },

    '&.small': {
      height: 48,
      minWidth: 48,
      width: 48,
    },
  },

  label: {
    color: COLORS.WHITE,
    paddingBottom: 5,

    '&.small': {
      fontSize: 13,
    },
  },
}));
