import App from '../views/App';
import Communicate from '../views/App/Communicate';
import Help from '../views/App/Help';
import Home from '../views/App/Home';
import Organize from '../views/App/Organize';
import Share from '../views/App/Share';
import ErrorPage from '../components/Error/ErrorPage';
import VideoCall from '../views/App/Communicate/VideoCall';
import Postcard from '../views/App/Communicate/Postcard';
import Route from './Route';
import HelpDocuments from '../views/App/Help/HelpDocuments';
import Teamviewer from '../views/App/Help/Teamviewer';
import browserHistory from '../utils/history';
import {
  Route as ReactRoute,
  Router as ReactRouter,
  Switch,
} from 'react-router';
import TabletTutorials from '../views/App/Help/HelpDocuments/TabletTutorials';
import Admin from '../views/App/Admin';
import AcceptRelationship from '../views/AcceptRelationship';
import Profile from '../views/Profile';
import GettingStarted from '../views/App/Help/HelpDocuments/GettingStarted';
import FamilyPortalTutorials from '../views/App/Help/HelpDocuments/FamilyPortalTutorials';
import PartnerList from '../views/App/Help/PartnerList';
import PartnerPublications from '../views/App/Help/Partner/PartnerPublications';
import AdminMessages from '../views/App/Admin/AdminMessages';
import AdminWorkbook from '../views/App/Admin/AdminWorkbook';
import AdminOrganizer from '../views/App/Admin/AdminOrganizer';
import AdminBookmarks from '../views/App/Admin/AdminBookmarks';
import AdminApplications from '../views/App/Admin/AdminApplications';
import AdminSettings from '../views/App/Admin/AdminSettings';
import AdminContacts from '../views/App/Admin/AdminContacts';
import AdminMessageViewer from '../views/App/Admin/AdminMessages/AdminMessageViewer';

/* The more precise URL first */
const Router = (): JSX.Element => (
  <ReactRouter history={browserHistory}>
    <Switch>
      <ReactRoute path={Route.ERROR}>
        <ErrorPage />
      </ReactRoute>

      <ReactRoute path={Route.POSTCARD}>
        <App>
          <Postcard />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.VIDEO_CALL}>
        <App>
          <VideoCall />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.COMMUNICATE}>
        <App>
          <Communicate />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.SHARE}>
        <App>
          <Share />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ORGANIZE}>
        <App>
          <Organize />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.TABLET_TUTORIALS}>
        <App>
          <TabletTutorials />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.GETTING_STARTED}>
        <App>
          <GettingStarted />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.FAMILY_PORTAL_TUTORIALS}>
        <App>
          <FamilyPortalTutorials />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.HELP_DOCUMENTS}>
        <App>
          <HelpDocuments />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.TEAMVIEWER}>
        <App>
          <Teamviewer />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.PARTNER_LIST}>
        <App>
          <PartnerList />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.PARTNER_CONTENTS}>
        <App>
          <PartnerPublications />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.HELP}>
        <App>
          <Help />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_MESSAGE_VIEWER}>
        <App>
          <AdminMessageViewer />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_MESSAGES}>
        <App>
          <AdminMessages />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_WORKBOOK}>
        <App>
          <AdminWorkbook />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_ORGANIZER}>
        <App>
          <AdminOrganizer />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_BOOKMARKS}>
        <App>
          <AdminBookmarks />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_APPLICATIONS}>
        <App>
          <AdminApplications />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_SETTINGS}>
        <App>
          <AdminSettings />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN_CONTACTS}>
        <App>
          <AdminContacts />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ADMIN}>
        <App>
          <Admin />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.ACCEPT_RELATIONSHIP}>
        <AcceptRelationship />
      </ReactRoute>

      <ReactRoute path={Route.PROFILE}>
        <App>
          <Profile />
        </App>
      </ReactRoute>

      <ReactRoute path={Route.HOME}>
        <App>
          <Home />
        </App>
      </ReactRoute>

      <ReactRoute path="/">
        <App />
      </ReactRoute>
    </Switch>
  </ReactRouter>
);

export default Router;
