import { makeStyles } from '@mui/styles';
import { scrollContainer } from '../../../../../../../utils/Scroll';

export default makeStyles(() => ({
  scrollContainer,
  title: {
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
}));
