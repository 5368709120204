import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  day: {
    borderBottom: `solid 1px ${COLORS.LIGHT_GREY}`,
    color: COLORS.BLUE,
    fontSize: 20,
    fontWeight: 'bold',
    padding: '32px 20px',
    textAlign: 'center',
    textTransform: 'capitalize',

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      padding: 20,
      textAlign: 'left',
    },
  },
  dayContentContainer: {
    borderRadius: 16,
    boxShadow: '2px 3px 10px 3px rgb(0 0 0 / 16%)',
    height: '100%',
    position: 'relative',
  },
}));
