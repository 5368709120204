import {
  FolderType,
  SharedDocumentType,
} from '../../../types/document/Document';
import { FileRejection } from 'react-dropzone';
import { ab2str } from '../../../utils/Blob';
import {
  S3UploadOutputType,
  UploadFileToS3FnType,
} from '../../../utils/S3Upload';
import { LinkType } from '../../../types/Link';
import { FetchResult, MutationFunction } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AddDocumentError,
  AddDocumentMutationOutputType,
} from '../../../graphql/document/DocumentMutations';
import { StateSetter } from '../../../types/utils/React';
import { FileTypeSwitcherFileTypes } from '../../../types/share/FileTypeSwitcherFileTypes';
import * as Helpers from './Helpers';
import { ResultItemProps } from './DocumentOperationResultsModal/ResultItem';
import { trackEvent } from '../../../GoogleAnalytics/GATracker';
import {
  GAEventCategory,
  GAShareEventActions,
} from '../../../GoogleAnalytics/GAEvent';

export const getSortedDocuments = (
  documents: SharedDocumentType[],
): SharedDocumentType[] => {
  const arrayToSort = [...documents];

  return arrayToSort.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getSortedFolders = (
  folders: FolderType[] | undefined,
): FolderType[] | undefined => {
  if (folders === undefined) {
    return undefined;
  }

  const arrayToSort = [...folders];

  return arrayToSort.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export enum SendDroppedFileResultStatusType {
  DUPLICATE_FILENAME = 'DUPLICATE_FILENAME',
  SAVED = 'SAVED',
  NOT_SAVED = 'NOT_SAVED',
  NOT_SENT = 'NOT_SENT',
  NOT_UPLOADED = 'NOT_UPLOADED',
  TEMP_BLOB_NOT_FOUND = 'TEMP_BLOB_NOT_FOUND',
  TOO_LARGE = 'TOO_LARGE',
  TOO_MANY_FILES = 'TOO_MANY_FILES',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_DOCUMENT = 'UNKNOWN_DOCUMENT',
  WRONG_FILE_TYPE = 'WRONG_FILE_TYPE',
}

type SendDroppedFilesInputType = {
  selectedFolder?: FolderType;
  selectedLink?: LinkType;
  addDocument: MutationFunction<AddDocumentMutationOutputType>;
  setDocumentOperationResults: StateSetter<ResultItemProps[]>;
  setLoadingMessage: StateSetter<ReactNode | undefined>;
  showSuccessMessage: (msg: ReactNode) => void;
  uploadFileToS3: (
    dataURL: string,
    contentType: string,
  ) => Promise<S3UploadOutputType>;
};

export const sendDroppedFiles =
  ({
    selectedFolder,
    selectedLink,
    addDocument,
    setDocumentOperationResults,
    setLoadingMessage,
    showSuccessMessage,
    uploadFileToS3,
  }: SendDroppedFilesInputType) =>
  async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ): Promise<void> => {
    setLoadingMessage(<FormattedMessage id="share.document.add.loading" />);

    const results: ResultItemProps[] = await Promise.all<ResultItemProps>(
      acceptedFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = Helpers.uploadAndSaveDocument({
            file,
            selectedFolder,
            selectedLink,
            addDocument,
            resolve,
            uploadFileToS3,
          });

          reader.readAsDataURL(file);
        });
      }),
    );

    const rejectedResults = rejectedFiles.map((rejectedFile) => {
      const status = Helpers.getRejectedErrorCode(rejectedFile);

      return {
        fileName: rejectedFile.file.name,
        inError: true,
        messageId: `share.document.add.result.${status}`,
      };
    });

    const allResults = [...results, ...rejectedResults];

    if (!allResults.find((_) => _.inError)) {
      showSuccessMessage(<FormattedMessage id="share.document.add.success" />);
    } else {
      setDocumentOperationResults(allResults);
    }

    setLoadingMessage(undefined);
  };

export type UploadAndSaveDocumentInputType = {
  file: File;
  resolve: (value: ResultItemProps) => void;
  selectedFolder?: FolderType;
  selectedLink?: LinkType;
  addDocument: MutationFunction;
  uploadFileToS3: UploadFileToS3FnType;
};

export const uploadAndSaveDocument =
  ({
    file,
    selectedFolder,
    selectedLink,
    addDocument,
    resolve,
    uploadFileToS3,
  }: UploadAndSaveDocumentInputType) =>
  async (event: ProgressEvent<FileReader>): Promise<void> => {
    const result = event.target?.result;

    if (result) {
      const fileContent =
        result instanceof ArrayBuffer ? ab2str(result) : result;
      const res: S3UploadOutputType = await uploadFileToS3(
        fileContent,
        file.type,
      );

      if (res.key) {
        await Helpers.saveDocument({
          file,
          s3TempBlob: res,
          resolve,
          selectedFolder,
          selectedLink,
          addDocument,
        });
      } else {
        resolve({
          fileName: file.name,
          inError: true,
          messageId: `share.document.add.result.NOT_UPLOADED`,
        });
      }
    } else {
      resolve({
        fileName: file.name,
        inError: true,
        messageId: `share.document.add.result.NOT_SENT`,
      });
    }
  };

export const getStatusFromErrorReason = (
  errorReason?: AddDocumentError,
): SendDroppedFileResultStatusType => {
  switch (errorReason) {
    case AddDocumentError.DUPLICATE_FILENAME:
      return SendDroppedFileResultStatusType.DUPLICATE_FILENAME;
    case AddDocumentError.TEMP_BLOB_NOT_FOUND:
      return SendDroppedFileResultStatusType.TEMP_BLOB_NOT_FOUND;
    case AddDocumentError.UNAUTHORIZED:
      return SendDroppedFileResultStatusType.UNAUTHORIZED;
    case AddDocumentError.UNKNOWN_DOCUMENT:
      return SendDroppedFileResultStatusType.UNKNOWN_DOCUMENT;
    default:
      return SendDroppedFileResultStatusType.NOT_SAVED;
  }
};

type SaveDocumentInputType = {
  file: File;
  s3TempBlob: S3UploadOutputType;
  resolve: (value: ResultItemProps) => void;
  selectedFolder?: FolderType;
  selectedLink?: LinkType;
  addDocument: MutationFunction;
};

export const saveDocument = ({
  file,
  s3TempBlob,
  selectedFolder,
  selectedLink,
  addDocument,
  resolve,
}: SaveDocumentInputType): Promise<void> =>
  addDocument({
    variables: {
      addDocumentInput: {
        channel: selectedLink?.channelKey,
        folderKey: selectedFolder?.key,
        documentName: file.name,
        s3Path: s3TempBlob.key,
      },
    },
  })
    .then(({ data }: FetchResult<AddDocumentMutationOutputType>) => {
      if (data?.addDocument?.document) {
        trackEvent(
          GAEventCategory.SHARE,
          GAShareEventActions.ADD_DOCUMENT,
          data.addDocument.document.type,
        );

        resolve({
          fileName: file.name,
          inError: false,
          messageId: `share.document.add.result.SAVED`,
        });
      } else {
        const status = Helpers.getStatusFromErrorReason(
          data?.addDocument?.errorReason,
        );

        resolve({
          fileName: file.name,
          inError: true,
          messageId: `share.document.add.result.${status}`,
        });
      }
    })
    .catch(() =>
      resolve({
        fileName: file.name,
        inError: true,
        messageId: `share.document.add.result.NOT_SAVED`,
      }),
    );

export enum FileRejectionErrorCodeEnum {
  TOO_LARGE = 'file-too-large',
  TOO_MANY_FILES = 'too-many-files',
  WRONG_FILE_TYPE = 'file-invalid-type',
}

export const hasErrorCode = (
  rejectedFile: FileRejection,
  errorCode: FileRejectionErrorCodeEnum,
): boolean => !!rejectedFile.errors.find((_) => _.code === errorCode);

export const getRejectedErrorCode = (
  rejectedFile: FileRejection,
): SendDroppedFileResultStatusType => {
  if (hasErrorCode(rejectedFile, FileRejectionErrorCodeEnum.TOO_LARGE)) {
    return SendDroppedFileResultStatusType.TOO_LARGE;
  }

  if (hasErrorCode(rejectedFile, FileRejectionErrorCodeEnum.TOO_MANY_FILES)) {
    return SendDroppedFileResultStatusType.TOO_MANY_FILES;
  }

  if (hasErrorCode(rejectedFile, FileRejectionErrorCodeEnum.WRONG_FILE_TYPE)) {
    return SendDroppedFileResultStatusType.WRONG_FILE_TYPE;
  }

  return SendDroppedFileResultStatusType.UNKNOWN;
};

export const getAcceptedFiles = (
  selectedFileType: FileTypeSwitcherFileTypes,
): string => {
  switch (selectedFileType) {
    case FileTypeSwitcherFileTypes.OFFICE:
      return '.pdf,.eml,.doc,.docx,.xls,.xlsx,.ppt,.pps,.pptx,.ppsx';
    case FileTypeSwitcherFileTypes.MUSIC:
      return 'audio/*';
    case FileTypeSwitcherFileTypes.PICTURE_AND_VIDEO:
      return 'image/*, video/*';
    default:
      return '*';
  }
};
