import { makeStyles } from '@mui/styles';
import { COLORS } from '../../theme/MainTheme';

export default makeStyles(() => ({
  alert: {
    borderRadius: '0 0 16px 16px',
    padding: 10,

    '& .MuiAlert-action': {
      paddingRight: 5,
    },

    '& .MuiAlert-icon': {
      padding: '7px 5px',
    },

    '& .MuiAlert-message': {
      padding: '8px 20px',
    },
  },
  container: {
    top: 0,

    '& .MuiAlert-filledError': {
      backgroundColor: COLORS.RED,
    },

    '& .MuiAlert-filledSuccess': {
      backgroundColor: COLORS.GREEN,
    },

    '& .MuiAlert-filledWarning': {
      backgroundColor: COLORS.YELLOW,
    },
  },
}));
