export const profileMessages = {
  'profile.title': 'Mon compte',

  'profile.relationship_management.title': 'Gérer mes tablettes',
  'profile.relationship_management.sendButton':
    "Envoyer la demande d'association",
  'profile.relationship_management.associate.title':
    'Associer une nouvelle tablette à mon compte',
  'profile.relationship_management.associate.subtitle':
    'Adresse e-mail liée à la tablette',

  'profile.relationship_management.list.header.tablet': 'Tablette associée',
  'profile.relationship_management.list.header.state': 'État',
  'profile.relationship_management.list.header.accessType': "Type d'accès",

  'profile.on_boarding_replay.title':
    'Parcours de découverte du site Famille ardoiz',
  'profile.on_boarding_replay.button': 'Voir',
};
