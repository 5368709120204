import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

export enum DateTimePickerType {
  Date,
  Time,
}

type GetDateTimeBasePropsOutputType = {
  cancelText: ReactNode;
  toolbarTitle: ReactNode;
};

export const getDateTimeBaseProps = (
  type: DateTimePickerType,
): GetDateTimeBasePropsOutputType => {
  const baseProps = {
    cancelText: <FormattedMessage id="button.cancel" />,
  };

  if (type === DateTimePickerType.Time) {
    return {
      ...baseProps,
      toolbarTitle: <FormattedMessage id="select.hour" />,
    };
  } else {
    return {
      ...baseProps,
      toolbarTitle: <FormattedMessage id="select.date" />,
    };
  }
};
