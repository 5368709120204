import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    right: 20,
    position: 'absolute',
    textTransform: 'initial',

    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  container: {
    borderRadius: 8,
    padding: '15px 15px 35px 15px',
    position: 'relative',
  },
  preview: {
    fontSize: 14,
    lineHeight: '20px',
    overflow: 'hidden',
    marginTop: 15,
    maxHeight: 200,
  },
  title: {
    fontWeight: 'bold',
  },
}));
