import { useCallback, useContext, useEffect, useState } from 'react';
import VideoContent from './VideoCall/VideoContent';
import { VideoCallContextProvider } from '../../../Context/Communicate/VideoCall/VideoCallContext';
import {
  getVideoCallFromVideoCallQueryOutput,
  VideoCallState,
  VideoCallType,
} from '../../../types/videocall/VideoCall';
import { useParams } from 'react-router';
import { VideoCallParams } from '../../../types/route/Params';
import { alertContext } from '../../../components/Alert/AlertContext';
import { useQuery } from '@apollo/client';
import {
  GET_VIDEO_CALL_QUERY,
  GetVideoCallOutputType,
} from '../../../graphql/communicate/videocall/VideoCallQueries';
import { FormattedMessage } from 'react-intl';
import Loading from '../../../components/Loading/Loading';
import PageContentHeader from '../../../components/PageContent/PageContentHeader';
import PageContentContainer from '../../../components/PageContent/PageContentContainer';
import CallButton from './VideoCall/CallButton';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { isSelfLink } from '../../../utils/Link';
import { useUserContext } from '../../../Context/UserContext';
import { VIDEO_CALL_POLLING_INTERVAL } from '../../../utils/constants';

type VideoCallPureProps = {
  isPreview: boolean;
  handleCallButtonClick: Function;
};

const VideoCallPure = ({
  isPreview,
  handleCallButtonClick,
}: VideoCallPureProps): JSX.Element => (
  <PageContentContainer>
    <PageContentHeader
      title={<FormattedMessage id="videoCall.description" />}
    />

    <CallButton show={isPreview} onClick={handleCallButtonClick} />
    <VideoContent isPreview={isPreview} />
  </PageContentContainer>
);

const VideoCall = (): JSX.Element | null => {
  const { videoCallID } = useParams<VideoCallParams>();
  const [videoCall, setVideoCall] = useState<VideoCallType>();
  const [isPreview, setIsPreview] = useState<boolean>(true);
  const { showErrorMessage } = useContext(alertContext);
  const { selectedLink } = useSelectedLinkContext();
  const { webUser } = useUserContext();

  const { error, loading } = useQuery<GetVideoCallOutputType>(
    GET_VIDEO_CALL_QUERY,
    {
      variables: { id: videoCallID },
      pollInterval: VIDEO_CALL_POLLING_INTERVAL,
      onCompleted: async (data) => {
        const videoCallResult = getVideoCallFromVideoCallQueryOutput(
          data.videoCall.videoCall,
          webUser,
        );

        setVideoCall(videoCallResult);

        const inferIsPreviewFromState =
          videoCallResult?.command.currentState === VideoCallState.Initial;

        setIsPreview(inferIsPreviewFromState);
      },
    },
  );
  const handleCallButtonClick = useCallback(() => {
    setIsPreview(false);
  }, [setIsPreview]);

  useEffect(() => {
    if (error) {
      showErrorMessage(<FormattedMessage id="videoCall.error.getVideoCall" />);
    }
  });

  if (loading) {
    return <Loading />;
  }

  if (!videoCall) {
    return null;
  }

  if (isSelfLink(selectedLink)) {
    return (
      <VideoCallContextProvider
        isPreview={isPreview}
        videoCall={videoCall}
        setIsPreview={setIsPreview}
        setVideoCall={setVideoCall}
      >
        <CallButton show={isPreview} onClick={handleCallButtonClick} />
        <VideoContent isPreview={isPreview} isSelf />
      </VideoCallContextProvider>
    );
  }

  return (
    <VideoCallContextProvider
      isPreview={isPreview}
      videoCall={videoCall}
      setIsPreview={setIsPreview}
      setVideoCall={setVideoCall}
    >
      <VideoCallPure
        isPreview={isPreview}
        handleCallButtonClick={handleCallButtonClick}
      />
    </VideoCallContextProvider>
  );
};

export default VideoCall;
