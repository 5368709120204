import { useContext } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { eventFormContext } from '../EventFormContext';
import {
  DaysOfRepeatType,
  HandleDaysOfRepeatChangeIndexType,
  HandleDaysOfRepeatChangeType,
} from '../../../../../../types/organize/Organizer';
import useStyles from './RecurrenceDays.styles';
import { calendarContext } from '../../../../../../components/Calendar/CalendarContext';

const dayLabels = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

type RecurrenceDaysPureProps = {
  daysOfRepeat: DaysOfRepeatType;
  disabled: boolean;
  selectedDateWeekDay: number;
  handleDaysOfRepeatChange: HandleDaysOfRepeatChangeType;
};

const RecurrenceDaysPure = ({
  daysOfRepeat,
  disabled,
  selectedDateWeekDay,
  handleDaysOfRepeatChange,
}: RecurrenceDaysPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} item xs={12}>
      {dayLabels.map((label, index) => (
        <FormControlLabel
          className={classes.label}
          control={
            <Checkbox
              checked={daysOfRepeat[index]}
              className={classes.checkbox}
              disabled={disabled || selectedDateWeekDay === index}
              onChange={(e) =>
                handleDaysOfRepeatChange(
                  index as HandleDaysOfRepeatChangeIndexType,
                  e.target.checked,
                )
              }
            />
          }
          key={index}
          label={label}
        />
      ))}
    </Grid>
  );
};

const RecurrenceDays = (): JSX.Element | null => {
  const { daysOfRepeat, disabled, handleDaysOfRepeatChange } =
    useContext(eventFormContext);
  const { selectedDate } = useContext(calendarContext);

  if (!selectedDate) {
    return null;
  }

  const selectedDateWeekDay = selectedDate.weekday();

  return (
    <RecurrenceDaysPure
      daysOfRepeat={daysOfRepeat}
      disabled={disabled}
      selectedDateWeekDay={selectedDateWeekDay}
      handleDaysOfRepeatChange={handleDaysOfRepeatChange}
    />
  );
};

export default RecurrenceDays;
