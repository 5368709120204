import { useState } from 'react';
import {
  AlarmEnum,
  DayContentModeEnum,
  DaysOfRepeatType,
} from '../../../../../types/organize/Organizer';
import moment, { Moment } from 'moment';
import { StateSetter } from '../../../../../types/utils/React';
import { useMutation } from '@apollo/client';
import {
  CREATE_AFTER_WEEKLY_MUTATION,
  CREATE_ALWAYS_WEEKLY_MUTATION,
  CREATE_BASIC_EVENT_MUTATION,
  CREATE_UNTIL_WEEKLY_MUTATION,
  CreateEventOutputOrError,
  DELETE_ALL_EVENT_OCCURRENCES_MUTATION,
  DeleteAllEventOccurrencesOutputOrError,
} from '../../../../../graphql/event/EventMutations';
import { MutationFunction } from '../../../../../types/utils/GraphQL';
import {
  EventType,
  RecurrenceTypeEnum,
} from '../../../../../types/event/Event';
import { isMidnight } from '../../../../../utils/Date';

export const DEFAULT_OCCURRENCES_VALUE = 2;
export const DAY_MILLISECONDS_DURATION = 24 * 3600 * 1000;
export const EVENT_FORM_TITLE_MAX_LENGTH = 40;
export const EVENT_FORM_OCCURRENCES_MAX_VALUE = 1000;

export type EventFormErrorsType = {
  alarm?: string;
  comments?: string;
  dateBegin?: string;
  dateEnd?: string;
  daysOfRepeat?: string;
  hasRecurrence?: string;
  isAllDay?: string;
  name?: string;
  occurrences?: string;
  recurrenceType?: string;
  recurrenceUntilDate?: string;
};

export type EventFormValuesType = {
  alarm: AlarmEnum;
  comments: string;
  dateBegin: Moment | null;
  dateEnd: Moment | null;
  daysOfRepeat: DaysOfRepeatType;
  hasRecurrence: boolean;
  isAllDay: boolean;
  name: string;
  occurrences: number;
  recurrenceType: RecurrenceTypeEnum;
  recurrenceUntilDate: Moment | null;
};

const getFixedDateFromSelectedDate = (
  selectedDate: Moment | undefined,
  dateToUpdate: Moment,
) =>
  moment(selectedDate)
    .hours(dateToUpdate?.hours() || 0)
    .minutes(dateToUpdate?.minutes() || 0)
    .seconds(0)
    .milliseconds(0);

type UseEventFormStatesOutputType = EventFormValuesType & {
  eventFormErrors: EventFormErrorsType;
  handleDateBeginChange: (newDate: Moment | null) => void;
  handleDateEndChange: (newDate: Moment | null) => void;
  setAlarm: StateSetter<AlarmEnum>;
  setComments: StateSetter<string>;
  setDaysOfRepeat: StateSetter<DaysOfRepeatType>;
  setEventFormErrors: StateSetter<EventFormErrorsType>;
  setHasRecurrence: StateSetter<boolean>;
  setIsAllDay: StateSetter<boolean>;
  setName: StateSetter<string>;
  setOccurrences: StateSetter<number>;
  setRecurrenceType: StateSetter<RecurrenceTypeEnum>;
  setRecurrenceUntilDate: StateSetter<Moment | null>;
};

type UseEventFormStatesInputType = {
  dayContentMode?: DayContentModeEnum;
  initialEvent?: EventType;
  selectedDate?: Moment;
};

export const useEventFormStates = ({
  dayContentMode,
  initialEvent,
  selectedDate,
}: UseEventFormStatesInputType): UseEventFormStatesOutputType => {
  const eventFormDefaultValues = getEventFormDefaultValues({
    dayContentMode,
    initialEvent,
    selectedDate,
  });

  const [alarm, setAlarm] = useState<AlarmEnum>(eventFormDefaultValues.alarm);
  const [comments, setComments] = useState<string>(
    eventFormDefaultValues.comments,
  );
  const [dateBegin, setDateBegin] = useState<Moment | null>(
    eventFormDefaultValues.dateBegin,
  );

  const handleDateBeginChange = (newDateBegin: Moment | null) => {
    const date = newDateBegin
      ? getFixedDateFromSelectedDate(selectedDate, newDateBegin)
      : null;

    return setDateBegin(date);
  };

  const [dateEnd, setDateEnd] = useState<Moment | null>(
    eventFormDefaultValues.dateEnd,
  );

  const handleDateEndChange = (newDateEnd: Moment | null) => {
    if (newDateEnd && isMidnight(newDateEnd)) {
      const date = getFixedDateFromSelectedDate(
        moment(selectedDate).add(1, 'day'),
        newDateEnd,
      );

      return setDateEnd(date);
    } else if (newDateEnd) {
      const date = getFixedDateFromSelectedDate(selectedDate, newDateEnd);

      return setDateEnd(date);
    } else {
      return setDateEnd(newDateEnd);
    }
  };

  const [daysOfRepeat, setDaysOfRepeat] = useState<DaysOfRepeatType>(
    eventFormDefaultValues.daysOfRepeat,
  );
  const [eventFormErrors, setEventFormErrors] = useState<EventFormErrorsType>(
    {},
  );
  const [hasRecurrence, setHasRecurrence] = useState<boolean>(
    eventFormDefaultValues.hasRecurrence,
  );
  const [isAllDay, setIsAllDay] = useState<boolean>(
    eventFormDefaultValues.isAllDay,
  );
  const [name, setName] = useState<string>(eventFormDefaultValues.name);
  const [occurrences, setOccurrences] = useState<number>(
    eventFormDefaultValues.occurrences,
  );
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceTypeEnum>(
    eventFormDefaultValues.recurrenceType,
  );
  const [recurrenceUntilDate, setRecurrenceUntilDate] = useState<Moment | null>(
    eventFormDefaultValues.recurrenceUntilDate,
  );

  return {
    alarm,
    comments,
    dateBegin,
    dateEnd,
    daysOfRepeat,
    eventFormErrors,
    hasRecurrence,
    isAllDay,
    name,
    occurrences,
    recurrenceType,
    recurrenceUntilDate,
    handleDateBeginChange,
    handleDateEndChange,
    setAlarm,
    setComments,
    setDaysOfRepeat,
    setEventFormErrors,
    setHasRecurrence,
    setIsAllDay,
    setName,
    setOccurrences,
    setRecurrenceType,
    setRecurrenceUntilDate,
  };
};

export type SaveEventMutationsType = {
  createAfterWeeklyRecurrentEvent: MutationFunction<CreateEventOutputOrError>;
  createAlwaysWeeklyRecurrentEvent: MutationFunction<CreateEventOutputOrError>;
  createBasicEvent: MutationFunction<CreateEventOutputOrError>;
  createUntilWeeklyRecurrentEvent: MutationFunction<CreateEventOutputOrError>;
  deleteAllEventOccurrences: MutationFunction<DeleteAllEventOccurrencesOutputOrError>;
};

export const useSaveEventMutations = (): SaveEventMutationsType => {
  const [createAfterWeeklyRecurrentEventMutation] =
    useMutation<CreateEventOutputOrError>(CREATE_AFTER_WEEKLY_MUTATION);
  const [createAlwaysWeeklyRecurrentEventMutation] =
    useMutation<CreateEventOutputOrError>(CREATE_ALWAYS_WEEKLY_MUTATION);
  const [createBasicEventMutation] = useMutation<CreateEventOutputOrError>(
    CREATE_BASIC_EVENT_MUTATION,
  );
  const [createUntilWeeklyRecurrentEventMutation] =
    useMutation<CreateEventOutputOrError>(CREATE_UNTIL_WEEKLY_MUTATION);
  const [deleteAllEventOccurrencesMutation] =
    useMutation<DeleteAllEventOccurrencesOutputOrError>(
      DELETE_ALL_EVENT_OCCURRENCES_MUTATION,
    );

  return {
    createAfterWeeklyRecurrentEvent: createAfterWeeklyRecurrentEventMutation,
    createAlwaysWeeklyRecurrentEvent: createAlwaysWeeklyRecurrentEventMutation,
    createBasicEvent: createBasicEventMutation,
    createUntilWeeklyRecurrentEvent: createUntilWeeklyRecurrentEventMutation,
    deleteAllEventOccurrences: deleteAllEventOccurrencesMutation,
  };
};

type SaveEventInputType = EventFormValuesType & {
  channel?: string;
  saveEventMutations: SaveEventMutationsType;
  selectedDate?: Moment;
  handleCreateEventSuccess: () => void;
  setEventFormErrors: StateSetter<EventFormErrorsType>;
};

export const saveEvent = async ({
  alarm,
  channel,
  comments,
  dateBegin,
  dateEnd,
  daysOfRepeat,
  hasRecurrence,
  isAllDay,
  name,
  occurrences,
  recurrenceType,
  recurrenceUntilDate,
  saveEventMutations,
  selectedDate,
  handleCreateEventSuccess,
  setEventFormErrors,
}: SaveEventInputType): Promise<void> => {
  if (channel && selectedDate) {
    const eventBaseValues = getEventBaseValues({
      alarm,
      channel,
      comments,
      dateBegin,
      dateEnd,
      isAllDay,
      name,
      selectedDate,
      setEventFormErrors,
    });

    if (eventBaseValues) {
      if (hasRecurrence) {
        if (checkRecurrenceValues({ daysOfRepeat, setEventFormErrors })) {
          const actualDayOfRepeat = getActualDayOfRepeat(daysOfRepeat);

          switch (recurrenceType) {
            case RecurrenceTypeEnum.AFTER:
              return createAfterWeeklyRecurrentEvent(
                saveEventMutations.createAfterWeeklyRecurrentEvent,
                handleCreateEventSuccess,
                eventBaseValues,
                actualDayOfRepeat,
                occurrences,
                setEventFormErrors,
              );

            case RecurrenceTypeEnum.ALWAYS:
              return createAlwaysWeeklyRecurrentEvent(
                saveEventMutations.createAlwaysWeeklyRecurrentEvent,
                handleCreateEventSuccess,
                eventBaseValues,
                actualDayOfRepeat,
              );

            case RecurrenceTypeEnum.UNTIL:
              return createUntilWeeklyRecurrentEvent(
                saveEventMutations.createUntilWeeklyRecurrentEvent,
                handleCreateEventSuccess,
                eventBaseValues,
                actualDayOfRepeat,
                recurrenceUntilDate,
                setEventFormErrors,
              );
          }
        }
      } else {
        return createBasicEvent(
          saveEventMutations.createBasicEvent,
          handleCreateEventSuccess,
          eventBaseValues,
        );
      }
    }
  }

  return Promise.resolve();
};

type GetEventBaseValuesInputType = {
  alarm: AlarmEnum;
  channel: string;
  comments: string;
  dateBegin: Moment | null;
  dateEnd: Moment | null;
  isAllDay: boolean;
  name: string;
  selectedDate: Moment;
  setEventFormErrors: StateSetter<EventFormErrorsType>;
};

type EventBaseValuesType = {
  alarm: AlarmEnum;
  associatedWebUsers: string[];
  channel: string;
  comments?: string;
  date: number;
  duration: number;
  isAllDay: boolean;
  name: string;
};

export const getEventBaseValues = ({
  alarm,
  channel,
  comments,
  dateBegin,
  dateEnd,
  isAllDay,
  name,
  selectedDate,
  setEventFormErrors,
}: GetEventBaseValuesInputType): EventBaseValuesType | null => {
  if (!name) {
    setEventFormErrors({ name: 'event.form.errors.name.empty' });
    return null;
  } else if (name.length > EVENT_FORM_TITLE_MAX_LENGTH) {
    setEventFormErrors({ name: 'event.form.errors.name.tooLong' });
    return null;
  }

  const baseValues = {
    alarm,
    associatedWebUsers: [], // TODO share event in the future
    channel,
    comments,
    isAllDay,
    name,
  };

  if (isAllDay) {
    const date = +moment(selectedDate).startOf('day');

    return {
      ...baseValues,
      alarm: AlarmEnum.NO_ALARM,
      date,
      duration: DAY_MILLISECONDS_DURATION,
    };
  } else {
    if (!dateBegin) {
      setEventFormErrors({ dateBegin: 'event.form.errors.dateBegin.empty' });
      return null;
    } else if (!dateEnd) {
      setEventFormErrors({ dateEnd: 'event.form.errors.dateEnd.empty' });
      return null;
    } else if (moment(dateBegin).isSameOrAfter(dateEnd, 'seconds')) {
      // dateBegin should be before dateEnd (at least 1 minute duration)
      setEventFormErrors({
        dateEnd: 'event.form.errors.dateEnd.afterDateBegin',
      });
      return null;
    } else {
      const updatedDateBegin = moment(dateBegin)
        .year(selectedDate.year())
        .month(selectedDate.month())
        .date(selectedDate.date());

      const duration = dateEnd.diff(dateBegin, 'milliseconds');

      return {
        ...baseValues,
        date: +updatedDateBegin,
        duration,
      };
    }
  }
};

type CheckRecurrenceValuesInputType = {
  daysOfRepeat: DaysOfRepeatType;
  setEventFormErrors: StateSetter<EventFormErrorsType>;
};

export const checkRecurrenceValues = ({
  daysOfRepeat,
  setEventFormErrors,
}: CheckRecurrenceValuesInputType): boolean => {
  if (!daysOfRepeat.includes(true)) {
    setEventFormErrors({
      daysOfRepeat: 'event.form.errors.daysOfRepeat.empty',
    });
    return false;
  }

  return true;
};

// Days of repeat are showed to user from monday to sunday, but are saved from sunday to saturday
export const getActualDayOfRepeat = (
  daysOfRepeat: DaysOfRepeatType,
): DaysOfRepeatType => {
  const [monday, tuesday, wednesday, thursday, friday, saturday, sunday] =
    daysOfRepeat;

  return [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
};

const createAfterWeeklyRecurrentEvent = (
  mutate: MutationFunction<CreateEventOutputOrError>,
  handleCreateEventSuccess: () => void,
  eventBaseValues: EventBaseValuesType,
  daysOfRepeat: DaysOfRepeatType,
  occurrence: number,
  setEventFormErrors: StateSetter<EventFormErrorsType>,
): Promise<void> => {
  if (!occurrence || occurrence < 2) {
    setEventFormErrors({
      occurrences: 'event.form.errors.occurrences.atLeast2',
    });
    return Promise.resolve();
  } else if (occurrence > EVENT_FORM_OCCURRENCES_MAX_VALUE) {
    setEventFormErrors({
      occurrences: 'event.form.errors.occurrences.tooMuch',
    });
    return Promise.resolve();
  }

  return mutate({
    variables: {
      afterWeeklyRecurrentEventInput: {
        ...eventBaseValues,
        daysOfRepeat,
        occurrence,
      },
    },
  }).then(() => handleCreateEventSuccess());
};

const createAlwaysWeeklyRecurrentEvent = (
  mutate: MutationFunction<CreateEventOutputOrError>,
  handleCreateEventSuccess: () => void,
  eventBaseValues: EventBaseValuesType,
  daysOfRepeat: DaysOfRepeatType,
): Promise<void> =>
  mutate({
    variables: {
      alwaysWeeklyRecurrentEventInput: {
        ...eventBaseValues,
        daysOfRepeat,
      },
    },
  }).then(() => handleCreateEventSuccess());

const createUntilWeeklyRecurrentEvent = (
  mutate: MutationFunction<CreateEventOutputOrError>,
  handleCreateEventSuccess: () => void,
  eventBaseValues: EventBaseValuesType,
  daysOfRepeat: DaysOfRepeatType,
  recurrenceUntilDate: Moment | null,
  setEventFormErrors: StateSetter<EventFormErrorsType>,
): Promise<void> => {
  if (!recurrenceUntilDate) {
    setEventFormErrors({
      recurrenceUntilDate: 'event.form.errors.recurrenceUntilDate.empty',
    });
    return Promise.resolve();
  }

  return mutate({
    variables: {
      untilWeeklyRecurrentEventInput: {
        ...eventBaseValues,
        daysOfRepeat,
        untilDate: +moment(recurrenceUntilDate),
      },
    },
  }).then(() => handleCreateEventSuccess());
};

const createBasicEvent = (
  mutate: MutationFunction<CreateEventOutputOrError>,
  handleCreateEventSuccess: () => void,
  basicEventInput: EventBaseValuesType,
): Promise<void> =>
  mutate({
    variables: {
      basicEventInput,
    },
  }).then(() => handleCreateEventSuccess());

export const computeDefaultDayOfRepeatValue = (
  selectedDate?: Moment,
): DaysOfRepeatType => {
  if (selectedDate) {
    const weekDay = selectedDate.weekday();

    return [false, false, false, false, false, false, false].map((_, index) =>
      index === weekDay ? true : _,
    ) as DaysOfRepeatType;
  } else {
    return [false, false, false, false, false, false, false];
  }
};

type GetEventFormDefaultValuesInputType = {
  dayContentMode?: DayContentModeEnum;
  initialEvent?: EventType;
  selectedDate?: Moment;
};

export const getEventFormDefaultValues = ({
  dayContentMode,
  initialEvent,
  selectedDate,
}: GetEventFormDefaultValuesInputType): EventFormValuesType => {
  if (
    (dayContentMode === DayContentModeEnum.EDIT ||
      dayContentMode === DayContentModeEnum.VIEW) &&
    initialEvent
  ) {
    const dateBegin = initialEvent.date ? moment(initialEvent.date) : null;
    const dateEnd = dateBegin
      ? moment(dateBegin).add(initialEvent.duration, 'milliseconds')
      : null;
    const recurrenceUntilDate = initialEvent.untilDate
      ? moment(initialEvent.untilDate)
      : null;

    return {
      alarm: initialEvent.alarm,
      comments: initialEvent.comments || '',
      dateBegin,
      dateEnd,
      daysOfRepeat:
        initialEvent.daysOfRepeat ||
        computeDefaultDayOfRepeatValue(selectedDate),
      hasRecurrence: initialEvent.recurrence !== RecurrenceTypeEnum.NONE,
      isAllDay: initialEvent.isAllDay,
      name: initialEvent.name,
      occurrences: initialEvent.occurrence || DEFAULT_OCCURRENCES_VALUE,
      recurrenceType: initialEvent.recurrence,
      recurrenceUntilDate,
    };
  } else {
    return {
      alarm: AlarmEnum.NO_ALARM,
      comments: '',
      dateBegin: null,
      dateEnd: null,
      daysOfRepeat: computeDefaultDayOfRepeatValue(selectedDate),
      hasRecurrence: false,
      isAllDay: false,
      name: '',
      occurrences: DEFAULT_OCCURRENCES_VALUE,
      recurrenceType: RecurrenceTypeEnum.ALWAYS,
      recurrenceUntilDate: null,
    };
  }
};
