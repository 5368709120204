import { useContext } from 'react';
import EventFormLabel from './EventFormLabel';
import TimeSelect from './TimeSelect';
import { eventFormContext } from './EventFormContext';

type TimeFormPureProps = {
  disabled: boolean;
};

const TimeFormPure = ({ disabled }: TimeFormPureProps): JSX.Element => (
  <>
    <EventFormLabel
      mandatory={!disabled}
      messageId="event.form.begin_hour"
      size={6}
    />
    <EventFormLabel
      mandatory={!disabled}
      messageId="event.form.end_hour"
      size={6}
    />

    <TimeSelect propName="dateBegin" setPropFnName="handleDateBeginChange" />
    <TimeSelect propName="dateEnd" setPropFnName="handleDateEndChange" />
  </>
);

const TimeForm = (): JSX.Element | null => {
  const { disabled, isAllDay } = useContext(eventFormContext);

  if (isAllDay) {
    return null;
  }

  return <TimeFormPure disabled={disabled} />;
};

export default TimeForm;
