import moment, { Moment, MomentInput } from 'moment';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const getFormattedDate = (date: MomentInput): string =>
  moment(date).format('DD/MM/YYYY');

export const getTextFormattedDate = (date: number | undefined): string =>
  moment(date).format('LL');

export const isMidnight = (date: Moment): boolean =>
  date.hours() === 0 && date.minutes() === 0;

export const getDateAndHourString = (
  dateInput: number | MomentInput,
): ReactNode => {
  const d: MomentInput = moment(dateInput);
  const date = d.format('DD/MM/YYYY');
  const hour = d.format('H:mm');
  return (
    <>
      {date} <FormattedMessage id="at" /> {hour}
    </>
  );
};
