import { useCallback, useContext } from 'react';
import FilledButton from '../../../../../components/Button/FilledButton';
import { FormattedMessage } from 'react-intl';
import useStyles from './DeleteFilesButton.styles';
import { deleteModeContext } from '../../ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DELETE_DOCUMENT_MUTATION,
  DeleteDocumentMutationOutputType,
} from '../../../../../graphql/document/DocumentMutations';
import { deleteFiles } from './DeleteFilesHelpers';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import { shareContext } from '../../ShareContext';
import { alertContext } from '../../../../../components/Alert/AlertContext';
import {
  GET_VISIBLE_DOCUMENTS_FROM_FOLDER_QUERY,
  GetVisibleDocumentsFromFolderQueryOutputType,
} from '../../../../../graphql/document/DocumentQueries';

type DeleteFilesButtonPureProps = {
  disabled: boolean;
  handleDelete: () => void;
};

export const DeleteFilesButtonPure = ({
  disabled,
  handleDelete,
}: DeleteFilesButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FilledButton
      className={classes.button}
      color="red"
      data-test-id="delete-files-button"
      disabled={disabled}
      onClick={handleDelete}
    >
      <FormattedMessage id="button.delete" />
    </FilledButton>
  );
};

const DeleteFilesButton = (): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { selectedFolder, setDocumentOperationResults, setLoadingMessage } =
    useContext(shareContext);
  const { documentsToDelete, setDocumentsToDelete, setIsDeleteMode } =
    useContext(deleteModeContext);
  const { showSuccessMessage } = useContext(alertContext);

  const [deleteDocument] = useMutation<DeleteDocumentMutationOutputType>(
    DELETE_DOCUMENT_MUTATION,
  );
  const [getVisibleDocumentsFromFolder] =
    useLazyQuery<GetVisibleDocumentsFromFolderQueryOutputType>(
      GET_VISIBLE_DOCUMENTS_FROM_FOLDER_QUERY,
      {
        fetchPolicy: 'network-only',
        onCompleted: async () => {
          setDocumentsToDelete([]);
          setIsDeleteMode(false);
        },
      },
    );
  const refreshDocuments = () =>
    getVisibleDocumentsFromFolder({
      variables: {
        getVisibleDocumentsFromFolderInput: {
          channel: selectedLink?.channelKey,
          folderKey: selectedFolder?.key,
        },
      },
    });

  const deleteFilesFunction = deleteFiles({
    channel: selectedLink?.channelKey,
    documentsToDelete,
    folderKey: selectedFolder?.key,
    deleteDocument,
    refreshDocuments,
    setDocumentOperationResults,
    setLoadingMessage,
    showSuccessMessage,
  });

  const handleDelete = useCallback(deleteFilesFunction, [
    deleteFilesFunction,
    documentsToDelete,
    selectedFolder?.key,
    selectedLink?.channelKey,
    deleteDocument,
    refreshDocuments,
    setDocumentOperationResults,
    setLoadingMessage,
    showSuccessMessage,
  ]);

  return (
    <DeleteFilesButtonPure
      disabled={documentsToDelete.length === 0}
      handleDelete={handleDelete}
    />
  );
};

export default DeleteFilesButton;
