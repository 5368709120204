import { Grid, Paper } from '@mui/material';
import { MessageType } from '../../../../../types/Message';
import useStyles from './AdminMessagesMobileTable.styles';
import { AdminMessagesTableDataTypeEnum } from './AdminMessagesTableData';
import AdminMessagesMobileTableRow from './AdminMessagesMobileTable/AdminMessagesMobileTableRow';
import { FormattedMessage } from 'react-intl';

type AdminMessagesMobileTableProps = {
  messages: MessageType[];
  type: AdminMessagesTableDataTypeEnum;
  handleRowClick: (message: MessageType) => void;
};

const AdminMessagesMobileTable = ({
  messages,
  type,
  handleRowClick,
}: AdminMessagesMobileTableProps): JSX.Element => {
  const classes = useStyles();

  if (messages.length === 0) {
    return (
      <Paper className={classes.noMessageContainer}>
        <FormattedMessage id="admin.messages.noMessage" />
      </Paper>
    );
  }

  return (
    <Paper className={classes.container}>
      <Grid container>
        {messages.map((message) => (
          <Grid item key={message.id} xs={12}>
            <AdminMessagesMobileTableRow
              message={message}
              type={type}
              handleRowClick={handleRowClick}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default AdminMessagesMobileTable;
