import { useState } from 'react';
import { Button } from '@mui/material';
import SettingsIcon from '../../../../../images/Share/folder_settings.svg';
import Image from '../../../../../components/Image/Image';
import useStyles from './OpenSettingsModalButton.styles';
import SettingsModal from './SettingsModal';
import { StateSetter } from '../../../../../types/utils/React';
import DeleteOrQuitFolderConfirmationModal from './DeleteOrQuitFolderConfirmationModal';
import { SettingsContextProvider } from './SettingsContext';

type OpenSettingsModalButtonPureProps = {
  setShowSettingsModal: StateSetter<boolean>;
};

const OpenSettingsModalButtonPure = ({
  setShowSettingsModal,
}: OpenSettingsModalButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        data-test-id="open-settings-modal-button"
        onClick={() => setShowSettingsModal(true)}
      >
        <Image src={SettingsIcon} height={48} />
      </Button>

      <SettingsModal />

      <DeleteOrQuitFolderConfirmationModal />
    </>
  );
};

const OpenSettingsModalButton = (): JSX.Element => {
  const [showDeleteOrQuitModal, setShowDeleteOrQuitModal] =
    useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  return (
    <SettingsContextProvider
      showDeleteOrQuitModal={showDeleteOrQuitModal}
      showSettingsModal={showSettingsModal}
      setShowDeleteOrQuitModal={setShowDeleteOrQuitModal}
      setShowSettingsModal={setShowSettingsModal}
    >
      <OpenSettingsModalButtonPure
        setShowSettingsModal={setShowSettingsModal}
      />
    </SettingsContextProvider>
  );
};

export default OpenSettingsModalButton;
