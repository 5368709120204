import { useCallback, useContext } from 'react';
import { openCreateFolderModalButtonContext } from '../OpenCreateFolderModalButtonContext';
import { createFolderModalContext } from '../CreateFolderModalContext';
import { FormattedMessage, useIntl } from 'react-intl';
import FilledButton from '../../../../../../components/Button/FilledButton';
import useStyles from './CreateFolderButton.styles';
import { useMutation } from '@apollo/client';
import { useSelectedLinkContext } from '../../../../../../Context/SelectedLinkContext';
import { shareContext } from '../../../ShareContext';
import { alertContext } from '../../../../../../components/Alert/AlertContext';
import {
  CREATE_FOLDER_MUTATION,
  CreateFolderMutationOutputType,
} from '../../../../../../graphql/document/DocumentMutations';
import {
  GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
  GetVisibleFoldersFromFolderTypeQueryOutputType,
} from '../../../../../../graphql/document/DocumentQueries';
import { trackEvent } from '../../../../../../GoogleAnalytics/GATracker';
import {
  GAEventCategory,
  GAShareEventActions,
  GAShareEventLabels,
} from '../../../../../../GoogleAnalytics/GAEvent';

const CreateFolderButton = (): JSX.Element => {
  const classes = useStyles();
  const { contacts, folderName, resetCreateFolderFields, setTextFieldError } =
    useContext(createFolderModalContext);
  const { setShowCreateFolderModal } = useContext(
    openCreateFolderModalButtonContext,
  );
  const { selectedLink } = useSelectedLinkContext();
  const { selectedFileType } = useContext(shareContext);
  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);

  const [createFolder] = useMutation<CreateFolderMutationOutputType>(
    CREATE_FOLDER_MUTATION,
    {
      update(cache, { data }) {
        const folder = data?.createFolder.folder;

        if (folder) {
          const variables = {
            getVisibleFoldersByFolderTypeInput: {
              channel: selectedLink?.channelKey,
              folderType: selectedFileType,
            },
          };

          const getVisibleFoldersFromFolderTypeData =
            cache.readQuery<GetVisibleFoldersFromFolderTypeQueryOutputType>({
              query: GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
              variables,
            });

          const currentFolders =
            getVisibleFoldersFromFolderTypeData?.visibleFoldersFromFolderType
              .folders || [];

          const updatedData = {
            ...getVisibleFoldersFromFolderTypeData,
            visibleFoldersFromFolderType: {
              errorReason: null,
              ...getVisibleFoldersFromFolderTypeData?.visibleFoldersFromFolderType,
              folders: [...currentFolders, folder],
            },
          };

          cache.writeQuery({
            data: updatedData,
            query: GET_VISIBLE_FOLDERS_BY_FOLDER_TYPE_QUERY,
            variables,
          });
        }
      },
    },
  );

  const intl = useIntl();
  const channelKey = selectedLink?.channelKey;

  const handleValidate = useCallback(() => {
    if (!folderName) {
      setTextFieldError(
        intl.formatMessage({
          id: 'share.folder.create.error.MANDATORY_FOLDER_NAME',
        }),
      );
    } else {
      createFolder({
        variables: {
          createFolderInput: {
            channel: channelKey,
            folderName: folderName,
            folderType: selectedFileType,
            otherWebUserIds: contacts,
          },
        },
      })
        .then(({ data }) => {
          if (data?.createFolder?.errorReason) {
            showErrorMessage(
              <FormattedMessage
                id={`share.folder.create.error.${data?.createFolder?.errorReason}`}
              />,
            );
          } else {
            resetCreateFolderFields();
            setShowCreateFolderModal(false);
            showSuccessMessage(
              <FormattedMessage id="share.folder.create.success" />,
            );

            trackEvent(
              GAEventCategory.SHARE,
              GAShareEventActions.CREATE_FOLDER,
              contacts.length > 0
                ? GAShareEventLabels.SHARED
                : GAShareEventLabels.NOT_SHARED,
            );
          }
        })
        .catch((error) => {
          console.error(error);
          showErrorMessage(<FormattedMessage id="share.folder.create.error" />);
        });
    }
  }, [
    channelKey,
    contacts,
    folderName,
    intl,
    selectedFileType,
    createFolder,
    resetCreateFolderFields,
    setShowCreateFolderModal,
    setTextFieldError,
    showErrorMessage,
    showSuccessMessage,
  ]);

  return (
    <FilledButton
      className={classes.button}
      color="violet"
      data-test-id="create-folder-button"
      onClick={handleValidate}
    >
      <FormattedMessage id="button.validate" />
    </FilledButton>
  );
};

export default CreateFolderButton;
