import { useCallback, useContext } from 'react';
import PageContentContainer from '../../../components/PageContent/PageContentContainer';
import PageContentHeader from '../../../components/PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import HrefLink from '../../../components/Link/HrefLink';
import FilledButton from '../../../components/Button/FilledButton';
import { TEAMVIEWER_DOWNLOAD_URL } from '../../../utils/ExternalURLs';
import useStyles from './Teamviewer.styles';
import Image from '../../../components/Image/Image';
import TabletAskTeamviewer from '../../../images/Help/teamviewer/tablet_ask_teamviewer.png';
import { useMutation } from '@apollo/client';
import {
  SEND_GCM_BY_CHANNEL_MUTATION,
  SendGCMByChannelMutationOutputType,
} from '../../../graphql/gcm/GCMMutations';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { alertContext } from '../../../components/Alert/AlertContext';
import { trackEvent } from '../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../GoogleAnalytics/GAEvent';

type TeamviewerPureProps = {
  handleLaunchTeamviewerClick: Function;
};

export const TeamviewerPure = ({
  handleLaunchTeamviewerClick,
}: TeamviewerPureProps): JSX.Element => {
  const classes = useStyles();
  return (
    <PageContentContainer>
      <PageContentHeader
        title={<FormattedMessage id="teamviewer.description" />}
      />

      <Grid alignItems="center" className={classes.firstGrid} container>
        <Grid item>
          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p1" />
          </Typography>

          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p2" />
            <HrefLink href={TEAMVIEWER_DOWNLOAD_URL}>
              {TEAMVIEWER_DOWNLOAD_URL}
            </HrefLink>
            .
          </Typography>

          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p3" />
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.teamviewerButtonContainer} container>
        <Grid item>
          <FilledButton
            className={classes.button}
            color="violet"
            data-test-id="teamviewer-button"
            onClick={(): void => handleLaunchTeamviewerClick()}
          >
            <FormattedMessage id="teamviewer.button" />
          </FilledButton>
        </Grid>
      </Grid>

      <Grid alignItems="center" className={classes.secondGrid} container>
        <Grid item md={7}>
          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p4" />
            <span className={classes.launchApp}>
              <FormattedMessage id="teamviewer.p4.launchApp" />
            </span>
            .
          </Typography>

          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p5" />
          </Typography>

          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p6" />
          </Typography>

          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p7" />
          </Typography>

          <Typography className={classes.p} variant="body1">
            <FormattedMessage id="teamviewer.p8" />
            <HrefLink href="/pdf/help/Guide détaillé - Teamviewer.pdf">
              <FormattedMessage id="teamviewer.p8.link" />
            </HrefLink>
            .
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Image alt="Teamviewer" src={TabletAskTeamviewer} width="100%" />
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};

const Teamviewer = (): JSX.Element | null => {
  const [sendGCMByChannel] = useMutation<SendGCMByChannelMutationOutputType>(
    SEND_GCM_BY_CHANNEL_MUTATION,
  );
  const { selectedLink } = useSelectedLinkContext();
  const { showErrorMessage, showSuccessMessage } = useContext(alertContext);
  const channelKey = selectedLink?.channelKey;

  const handleLaunchTeamviewerClick = useCallback(() => {
    trackEvent(GAEventCategory.HELP, GACommonEventAction.CLICK, 'Teamviewer');

    sendGCMByChannel({
      variables: {
        channel: channelKey,
        gcmOrderType: 'TEAMVIEWER',
      },
    })
      .then(({ data }) => {
        if (data?.sendGCMByChannel?.sent) {
          showSuccessMessage(
            <FormattedMessage id="teamviewer.successMessage" />,
          );
        } else {
          showErrorMessage(<FormattedMessage id="teamviewer.errorMessage" />);
        }
      })
      .catch(() =>
        showErrorMessage(<FormattedMessage id="teamviewer.errorMessage" />),
      );
  }, [channelKey, sendGCMByChannel, showErrorMessage, showSuccessMessage]);

  if (!selectedLink) {
    return null;
  }

  return (
    <TeamviewerPure handleLaunchTeamviewerClick={handleLaunchTeamviewerClick} />
  );
};

export default Teamviewer;
