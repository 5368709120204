import FileGrid, {
  FileGridType,
} from '../../../../components/FileGrid/FileGrid';
import { FormattedMessage } from 'react-intl';

const GUIDES_PATH_PREFIX = '/pdf/help/guides';

const guides: Array<FileGridType> = [
  {
    fileName: `Guide de démarrage tablette.pdf`,
    filePath: GUIDES_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.getting_started.tablet_guide" />
    ),
  },
  {
    fileName: `Guide de démarrage station.pdf`,
    filePath: GUIDES_PATH_PREFIX,
    label: (
      <FormattedMessage id="category.help.documents.getting_started.dock_guide" />
    ),
  },
];

const GettingStarted = (): JSX.Element => (
  <FileGrid
    files={guides}
    pageTitle="category.help.documents.getting_started"
  />
);

export default GettingStarted;
