import { Moment } from 'moment';
import { StateSetter } from '../../types/utils/React';
import useStyles from './DatePicker.styles';
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';

import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import {
  DateTimePickerType,
  getDateTimeBaseProps,
} from '../../utils/DateAndTimePicker';

export type DatePickerProps = {
  className?: string;
  date: Moment | null;
  renderInput?: (props: MuiTextFieldPropsType) => React.ReactElement;
  setDate: StateSetter<Moment | null>;
};

const DatePicker = ({
  className,
  date,
  renderInput,
  setDate,
}: DatePickerProps): JSX.Element => {
  const classes = useStyles();
  const actualRenderInput =
    renderInput ||
    ((params) => (
      <TextField className={classes.textField} size="small" {...params} />
    ));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MUIDatePicker
        {...getDateTimeBaseProps(DateTimePickerType.Date)}
        className={className}
        PaperProps={{ className: classes.datePicker }}
        disablePast
        openTo="month"
        views={['year', 'month', 'day']}
        value={date}
        onChange={setDate}
        renderInput={actualRenderInput}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
