import { Grid } from '@mui/material';
import SwitcherButton, {
  SwitcherButtonColorType,
} from './FileTypeSwitcher/SwitcherButton';
import { FileTypeSwitcherFileTypes } from '../../../types/share/FileTypeSwitcherFileTypes';
import useStyles from './FileTypeSwitcher.styles';

const FileTypeSwitcher = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container spacing={4}>
      <Grid item>
        <SwitcherButton
          color={SwitcherButtonColorType.GREEN}
          dataTestId="photo-button"
          fileType={FileTypeSwitcherFileTypes.PICTURE_AND_VIDEO}
          labelId="share.switcher.photo"
        />
      </Grid>
      <Grid item>
        <SwitcherButton
          color={SwitcherButtonColorType.RED}
          dataTestId="document-button"
          fileType={FileTypeSwitcherFileTypes.OFFICE}
          labelId="share.switcher.document"
        />
      </Grid>
      <Grid item>
        <SwitcherButton
          color={SwitcherButtonColorType.YELLOW}
          dataTestId="music-button"
          fileType={FileTypeSwitcherFileTypes.MUSIC}
          labelId="share.switcher.music"
        />
      </Grid>
    </Grid>
  );
};

export default FileTypeSwitcher;
