import { createCtxWithMandatoryValue } from '../../../../../../Context/utils';
import { EventOrTimeslotType } from '../../../../../../types/event/Event';

interface DayEventContextProps {
  dayEventOrTimeslot: EventOrTimeslotType;
}

const userContext = createCtxWithMandatoryValue<DayEventContextProps>(),
  [useDayEventContext, DayEventContextProvider] = userContext;

export { useDayEventContext, DayEventContextProvider };
