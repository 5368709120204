import { LinkType } from '../../../../../types/Link';
import { S3UploadOutputType } from '../../../../../utils/S3Upload';
import { MutationFunctionOptions, OperationVariables } from '@apollo/client';
import { ExecutionResult } from 'graphql';
import { StateSetter } from '../../../../../types/utils/React';
import { getDataURLFromFile } from '../../../../../utils/Blob';
import { resizeDataURL } from '../../../../../utils/Image';
import { POSTCARD_IMAGE_SIZE } from './Front';
import { FormattedMessage } from 'react-intl';
import { KeyboardEvent, ReactNode } from 'react';
import { trackEvent } from '../../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../../GoogleAnalytics/GAEvent';
import { SendPostcardOutputType } from '../../../../../graphql/communicate/postcard/PostcardMutations';
import PostcardPlaceholderImage from '../../../../../images/Communicate/Postcard/postcard_placeholder.png';
import { getLineCount } from '../../../../../utils/String';

export const POSTCARD_CONTENT_MAX_LENGTH = 400;
export const POSTCARD_CONTENT_MAX_CHARS_PER_LINE = 45;
export const POSTCARD_CONTENT_MAX_ROWS = 14;

export type GetSendPostcardFunctionInputType = {
  postcardContent: string;
  s3UploadedImage: S3UploadOutputType | undefined;
  selectedLink: LinkType;
  sendPostcardMutation: (
    options?:
      | MutationFunctionOptions<SendPostcardOutputType, OperationVariables>
      | undefined,
  ) => Promise<ExecutionResult<SendPostcardOutputType>>;
  setImagePreview: (imagePreview: string) => void;
  setPostcardContent: (content: string) => void;
  setSending: StateSetter<boolean>;
  showErrorMessage: (msg: ReactNode) => void;
  showSuccessMessage: (msg: ReactNode) => void;
  setS3UploadedImage: (s3UploadedImage: S3UploadOutputType | undefined) => void;
};

export const getSendPostcardFunction =
  ({
    postcardContent,
    s3UploadedImage,
    selectedLink,
    sendPostcardMutation,
    setImagePreview,
    setPostcardContent,
    setSending,
    showErrorMessage,
    showSuccessMessage,
    setS3UploadedImage,
  }: GetSendPostcardFunctionInputType): Function =>
  (): void => {
    trackEvent(GAEventCategory.POSTCARD, GACommonEventAction.CLICK, 'Envoyer');

    const blobPath = s3UploadedImage?.key;

    if (blobPath) {
      const variables = {
        input: {
          blobPath: blobPath,
          channel: selectedLink?.channelKey,
          text: postcardContent,
        },
      };

      const resetPostcardInterface = () => {
        setImagePreview(PostcardPlaceholderImage);
        setPostcardContent('');
        setS3UploadedImage(undefined);
        setSending(false);
      };

      setSending(true);

      sendPostcardMutation({ variables })
        .then(({ data }) => {
          if (!data.sendPostcard.errorReason && data.sendPostcard.sent) {
            trackEvent(
              GAEventCategory.POSTCARD,
              GACommonEventAction.SEND,
              'Envoyer une carte postale',
            );
            showSuccessMessage(<FormattedMessage id="postcard.sent" />);
          } else {
            showErrorMessage(<FormattedMessage id="postcard.tryLater" />);
          }

          resetPostcardInterface();
        })
        .catch((): void => {
          trackEvent(
            GAEventCategory.POSTCARD,
            GACommonEventAction.ERROR,
            "Erreur d'envoi de carte postale",
          );

          showErrorMessage(<FormattedMessage id="postcard.saveError" />);

          resetPostcardInterface();
        });
    }
  };

export type HandleDropInputType = {
  acceptedFiles: File[];
  isSmallScreen: boolean;
  setImagePreview: StateSetter<string>;
  setShowCrop: StateSetter<boolean>;
};

export const handleDrop = async ({
  acceptedFiles,
  isSmallScreen,
  setImagePreview,
  setShowCrop,
}: HandleDropInputType): Promise<string | undefined> => {
  const file = acceptedFiles[0];

  if (file) {
    const imageDataUrl = await getDataURLFromFile(file);
    const resizedImageDataUrl = await resizeDataURL({
      dataURL: imageDataUrl,
      targetHeight: POSTCARD_IMAGE_SIZE.HEIGHT,
      targetWidth: POSTCARD_IMAGE_SIZE.WIDTH,
    });

    setImagePreview(resizedImageDataUrl);

    if (!isSmallScreen) {
      setShowCrop(true);
    }

    return Promise.resolve(resizedImageDataUrl);
  }

  return Promise.resolve(undefined);
};

type GetHandleShowFrontChangeFunctionInputType = {
  showFront: boolean;
  setShowFront: StateSetter<boolean>;
};

export const getHandleShowFrontChangeFunction =
  ({ showFront, setShowFront }: GetHandleShowFrontChangeFunctionInputType) =>
  (): void => {
    trackEvent(
      GAEventCategory.POSTCARD,
      GACommonEventAction.CLICK,
      'Retourner la carte',
    );
    setShowFront(!showFront);
  };

type GetOnKeyPressFunctionInputType = {
  postcardContent: string;
};

export const getOnKeyPressFunction =
  ({ postcardContent }: GetOnKeyPressFunctionInputType) =>
  (e: KeyboardEvent): void | boolean => {
    const keyCode = e.keyCode || e.which;
    const lineBreaks = postcardContent.split('\n');
    const lines = lineBreaks.reduce((acc: number, value: string) => {
      const lineCount = getLineCount(
        value,
        POSTCARD_CONTENT_MAX_CHARS_PER_LINE,
      );
      return acc + lineCount;
    }, 0);

    // If max number of lines reached and pressed key is new line or number of lines is higher than expected
    if (
      (keyCode === 13 && lines === POSTCARD_CONTENT_MAX_ROWS) ||
      lines > POSTCARD_CONTENT_MAX_ROWS
    ) {
      e.preventDefault();
      return false;
    }
  };
