import { Grid } from '@mui/material';
import useStyles from './AdminHeader.styles';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { isSelfLink } from '../../../utils/Link';
import AdminChannelLinkSelect from '../../ChannelLink/AdminChannelLinkSelect';

const AdminHeaderPure = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid alignItems="center" container>
        <Grid item md={8} xs={12}>
          <AdminChannelLinkSelect />
        </Grid>
      </Grid>
    </div>
  );
};

const AdminHeader = (): JSX.Element | null => {
  const isSmallScreen = useIsSmallScreen();
  const { selectedLink } = useSelectedLinkContext();

  if (isSelfLink(selectedLink) || isSmallScreen) {
    return null;
  }

  return <AdminHeaderPure />;
};

export default AdminHeader;
