import AdminCategory, {
  AdminCategoryProps,
} from './AdminCategories/AdminCategory';
import MessageCategoryIcon from '../../../images/Admin/categories/messages.svg';
import WorkbookCategoryIcon from '../../../images/Admin/categories/workbook.svg';
import OrganizerCategoryIcon from '../../../images/Admin/categories/organizer.svg';
import BookmarksCategoryIcon from '../../../images/Admin/categories/bookmarks.svg';
import ApplicationsCategoryIcon from '../../../images/Admin/categories/applications.svg';
import SettingsCategoryIcon from '../../../images/Admin/categories/settings.svg';
import ContactsCategoryIcon from '../../../images/Admin/categories/contacts.svg';
import { Grid } from '@mui/material';
import useStyles from './AdminCategories.styles';
import Route from '../../../routes/Route';

const categoriesProps: AdminCategoryProps[] = [
  {
    descriptionMessageId: 'admin.category.messages.description',
    icon: MessageCategoryIcon,
    route: Route.ADMIN_MESSAGES,
    titleMessageId: 'admin.menu.messages',
  },
  {
    descriptionMessageId: 'admin.category.bookmarks.description',
    icon: BookmarksCategoryIcon,
    route: Route.ADMIN_BOOKMARKS,
    titleMessageId: 'admin.menu.bookmarks',
  },
  {
    descriptionMessageId: 'admin.category.contacts.description',
    icon: ContactsCategoryIcon,
    route: Route.ADMIN_CONTACTS,
    titleMessageId: 'admin.menu.contacts',
  },
  {
    descriptionMessageId: 'admin.category.applications.description',
    icon: ApplicationsCategoryIcon,
    route: Route.ADMIN_APPLICATIONS,
    titleMessageId: 'admin.menu.applications',
  },
  {
    descriptionMessageId: 'admin.category.organizer.description',
    icon: OrganizerCategoryIcon,
    route: Route.ADMIN_ORGANIZER,
    titleMessageId: 'admin.menu.organizer',
  },
  {
    descriptionMessageId: 'admin.category.settings.description',
    icon: SettingsCategoryIcon,
    route: Route.ADMIN_SETTINGS,
    titleMessageId: 'admin.menu.settings',
  },

  {
    descriptionMessageId: 'admin.category.workbook.description',
    icon: WorkbookCategoryIcon,
    route: Route.ADMIN_WORKBOOK,
    titleMessageId: 'admin.menu.workbook',
  },
];

const AdminCategories = (): JSX.Element => {
  const classes = useStyles();

  const categories = categoriesProps.map((categoryProps, index) => (
    <AdminCategory key={index} {...categoryProps} />
  ));

  return (
    <Grid className={classes.container} container spacing={3}>
      {categories}
    </Grid>
  );
};

export default AdminCategories;
