import { InputAdornment, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import MailIcon from '../../../../../images/OnBoarding/mail.svg';
import useStyles from './OnBoardingRelationshipTextField.styles';
import Image from '../../../../../components/Image/Image';
import { RelationshipSuccessMessage } from './RelationshipSuccessMessage';

type OnBoardingRelationshipTextFieldProps = {
  email: string;
  inError: boolean;
  relationshipHasBeenAdded: boolean;
  setEmail: (newEmail: string) => void;
};

const OnBoardingRelationshipTextField = ({
  email,
  inError,
  relationshipHasBeenAdded,
  setEmail,
}: OnBoardingRelationshipTextFieldProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const helperText = inError ? (
    <FormattedMessage id="relationship.textfield.error" />
  ) : undefined;

  const placeholderText = intl.formatMessage({
    id: 'relationship.textfield.placeholder',
  });

  return (
    <>
      <TextField
        className={classes.textField}
        disabled={relationshipHasBeenAdded}
        error={inError}
        helperText={helperText}
        placeholder={placeholderText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Image className={classes.emailIcon} src={MailIcon} />
            </InputAdornment>
          ),
        }}
        value={email}
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
      />

      <RelationshipSuccessMessage
        className={classes.successMessage}
        show={relationshipHasBeenAdded}
      />
    </>
  );
};

export default OnBoardingRelationshipTextField;
