import { gql } from '@apollo/client';

export const SEND_GCM_BY_CHANNEL_MUTATION = gql`
  mutation SendGCMByChannelMutation(
    $channel: String!
    $gcmOrderType: GCMOrderType!
  ) {
    sendGCMByChannel(channel: $channel, gcmOrderType: $gcmOrderType) {
      sent
    }
  }
`;

export type SendGCMByChannelMutationOutputType = {
  sendGCMByChannel: {
    sent: boolean;
  };
};
