import { ReactNode } from 'react';
import useStyles from './FileGrid.styles';
import PageContentContainer from '../PageContent/PageContentContainer';
import PageContentHeader from '../PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import FileItem from './FileItem';

export type FileGridType = {
  fileName: string;
  filePath: string;
  label: ReactNode;
};

export type FileGridProps = {
  files: FileGridType[];
  pageTitle: string;
};

const FileGrid = ({ files, pageTitle }: FileGridProps): JSX.Element => {
  const classes = useStyles();
  return (
    <PageContentContainer>
      <PageContentHeader title={<FormattedMessage id={pageTitle} />} />

      <Grid alignItems="center" className={classes.fileGridContainer} container>
        {files.map((file, index) => (
          <FileItem file={file} index={index} key={index} />
        ))}
      </Grid>
    </PageContentContainer>
  );
};

export default FileGrid;
