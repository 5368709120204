import { createContext, ReactNode } from 'react';
import { emptyFunction } from '../../../../../utils/Functions';
import { StateSetter } from '../../../../../types/utils/React';

export type SettingsContextProps = {
  showDeleteOrQuitModal: boolean;
  showSettingsModal: boolean;
  setShowDeleteOrQuitModal: StateSetter<boolean>;
  setShowSettingsModal: StateSetter<boolean>;
};

const initialState = {
  showDeleteOrQuitModal: false,
  showSettingsModal: false,
  setShowDeleteOrQuitModal: emptyFunction,
  setShowSettingsModal: emptyFunction,
};

const settingsContext = createContext<SettingsContextProps>(initialState),
  { Provider } = settingsContext;

type SettingsContextProviderProps = {
  children: ReactNode;
  showDeleteOrQuitModal: boolean;
  showSettingsModal: boolean;
  setShowDeleteOrQuitModal: StateSetter<boolean>;
  setShowSettingsModal: StateSetter<boolean>;
};

const SettingsContextProvider = ({
  children,
  showDeleteOrQuitModal,
  showSettingsModal,
  setShowDeleteOrQuitModal,
  setShowSettingsModal,
}: SettingsContextProviderProps): JSX.Element => (
  <Provider
    value={{
      showDeleteOrQuitModal,
      showSettingsModal,
      setShowDeleteOrQuitModal,
      setShowSettingsModal,
    }}
  >
    {children}
  </Provider>
);

export { settingsContext, SettingsContextProvider };
