import { ReactNode } from 'react';
import HistoryBackButton, {
  HandleHistoryBackType,
} from '../HistoryBack/HistoryBackButton';
import { Typography } from '@mui/material';
import useStyles from './PageContentHeader.styles';

export type PageContentHeaderProps = {
  title: ReactNode;
  handleHistoryBack?: HandleHistoryBackType;
};

const PageContentHeader = ({
  title,
  handleHistoryBack,
}: PageContentHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <HistoryBackButton onClick={handleHistoryBack} />

      <Typography className={classes.title} color="textPrimary" variant="h2">
        {title}
      </Typography>
    </div>
  );
};

export default PageContentHeader;
