import { useCallback, useState } from 'react';
import { CalendarContextProvider } from '../../../components/Calendar/CalendarContext';
import Calendar from '../../../components/Calendar/Calendar';
import moment, { Moment } from 'moment';
import { Grid } from '@mui/material';
import useStyles from './Organizer.styles';
import { useQuery } from '@apollo/client';
import {
  GET_EVENTS_QUERY,
  GetEventsQueryOutputType,
} from '../../../graphql/event/EventQueries';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import DayContent from './Organizer/DayContent';
import CalendarUtils from '../../../utils/CalendarUtils';
import {
  EventOrTimeslotArrayType,
  EventType,
  isEvent,
} from '../../../types/event/Event';
import { OrganizerContextProvider } from './OrganizerContext';
import DayContainer from './Organizer/DayContainer';
import EventForm from './Organizer/EventForm';
import { DayContentModeEnum } from '../../../types/organize/Organizer';
import DeleteEventModal from './Organizer/DayContent/DayEvent/DeleteEventModal';

type OrganizerPureProps = {
  shouldShowEventForm: boolean;
};

const OrganizerPure = ({
  shouldShowEventForm,
}: OrganizerPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.container} container spacing={6}>
        <Grid className={classes.calendarContainer} item md={8}>
          <Calendar />
        </Grid>

        <Grid className={classes.dayContainer} item md={4}>
          <DayContainer>
            <DayContent />

            {shouldShowEventForm && <EventForm />}
          </DayContainer>
        </Grid>
      </Grid>

      <DeleteEventModal />
    </>
  );
};

const getSelectedDayEventsAndTimeslots = (
  currentMonthEventsAndTimeslots: EventOrTimeslotArrayType,
  selectedDate: Moment | undefined,
): EventOrTimeslotArrayType => {
  if (selectedDate) {
    return currentMonthEventsAndTimeslots
      .filter((eventOrTimeslot) =>
        CalendarUtils.isSameDate(moment(eventOrTimeslot.date), selectedDate),
      )
      .sort((a, b) => (a.date < b.date ? -1 : 1));
  } else {
    return [];
  }
};

const getCurrentMonthEventsAndTimeslots = (
  eventsAndTimeslots?: EventOrTimeslotArrayType,
): EventOrTimeslotArrayType => {
  if (eventsAndTimeslots) {
    return eventsAndTimeslots.map((eventOrTimeslot) => {
      if (isEvent(eventOrTimeslot) && eventOrTimeslot.daysOfRepeat) {
        const [sunday, monday, tuesday, wednesday, thursday, friday, saturday] =
          eventOrTimeslot.daysOfRepeat;

        return {
          ...eventOrTimeslot,
          daysOfRepeat: [
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
          ],
        };
      } else {
        return eventOrTimeslot;
      }
    });
  } else {
    return [];
  }
};

const Organizer = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const [currentDate, setCurrentDate] = useState<Moment>(moment());
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>(
    moment(),
  );
  const [dayContentMode, setDayContentMode] = useState<
    DayContentModeEnum | undefined
  >(undefined);
  const [eventToDelete, setEventToDelete] = useState<EventType>();
  const [eventToEdit, setEventToEdit] = useState<EventType>();

  const { data } = useQuery<GetEventsQueryOutputType>(GET_EVENTS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      getEventsInput: {
        channel: selectedLink?.channelKey,
        month: currentDate.month() + 1,
        year: currentDate.year(),
      },
    },
  });

  const handleSelectedDateChange = useCallback(
    (newSelectedDate: Moment | undefined) => {
      setSelectedDate(newSelectedDate);
      setDayContentMode(undefined);
    },
    [setDayContentMode, setSelectedDate],
  );

  const currentMonthEventsAndTimeslots = getCurrentMonthEventsAndTimeslots(
    data?.getEvents?.filteredEvents,
  );
  const selectedDayEventsAndTimeslots = getSelectedDayEventsAndTimeslots(
    currentMonthEventsAndTimeslots,
    selectedDate,
  );

  const shouldShowEventForm = dayContentMode !== undefined;

  return (
    <OrganizerContextProvider
      dayContentMode={dayContentMode}
      eventToDelete={eventToDelete}
      eventToEdit={eventToEdit}
      setDayContentMode={setDayContentMode}
      setEventToDelete={setEventToDelete}
      setEventToEdit={setEventToEdit}
    >
      <CalendarContextProvider
        currentDate={currentDate}
        currentMonthEventsAndTimeslots={currentMonthEventsAndTimeslots}
        disabled={!!dayContentMode}
        selectedDate={selectedDate}
        selectedDayEventsAndTimeslots={selectedDayEventsAndTimeslots}
        setCurrentDate={setCurrentDate}
        handleSelectedDateChange={handleSelectedDateChange}
      >
        <OrganizerPure shouldShowEventForm={shouldShowEventForm} />
      </CalendarContextProvider>
    </OrganizerContextProvider>
  );
};

export default Organizer;
