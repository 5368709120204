import { BlobType } from '../../../../../types/Blob';
import { Grid, Tooltip } from '@mui/material';
import ExcelIcon from '../../../../../images/Share/ShareExplorer/Documents/excel-icon.png';
import PdfIcon from '../../../../../images/Share/ShareExplorer/Documents/pdf-icon.png';
import PowerpointIcon from '../../../../../images/Share/ShareExplorer/Documents/powerpoint-icon.png';
import WordIcon from '../../../../../images/Share/ShareExplorer/Documents/word-icon.png';
import UnknownIcon from '../../../../../images/Share/ShareExplorer/Documents/unknown-icon.png';
import useStyles from './AdminMessageViewerAttachments.styles';
import Image from '../../../../../components/Image/Image';
import AudioIcon from '../../../../../images/Share/ShareExplorer/Documents/audio-icon.png';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { emptyFunction } from '../../../../../utils/Functions';
import AudioPlayer from '../../../../../components/AudioPlayer/AudioPlayer';
import clsx from 'clsx';
import {
  downloadFileFromURL,
  parseFileName,
} from '../../../../../utils/Document';

type DocumentPreviewPureProps = {
  icon: string;
  handleDownload: () => void;
};

export const DocumentPreviewPure = ({
  icon,
  handleDownload,
}: DocumentPreviewPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Image className={classes.image} src={icon} onClick={handleDownload} />
  );
};

type ImagePreviewProps = {
  url: string;
  handleDownload: () => void;
};

export const ImagePreview = ({
  url,
  handleDownload,
}: ImagePreviewProps): JSX.Element => {
  const classes = useStyles();

  return <Image className={classes.image} src={url} onClick={handleDownload} />;
};

type AudioPreviewPureProps = {
  url: string;
  handleDownload: () => void;
};

export const AudioPreviewPure = ({
  url,
  handleDownload,
}: AudioPreviewPureProps): JSX.Element => {
  const classes = useStyles();
  const [isAudioVisible, setIsAudioVisible] = useState<boolean>(false);

  return (
    <div
      className={classes.audioContainer}
      onMouseLeave={() => setIsAudioVisible(false)}
      onMouseOver={() => setIsAudioVisible(true)}
    >
      <DocumentPreviewPure icon={AudioIcon} handleDownload={emptyFunction} />

      <AudioPlayer
        className={clsx([classes.audioControls, isAudioVisible && 'visible'])}
        url={url}
        handleDownload={handleDownload}
      />
    </div>
  );
};

type VideoPreviewPureProps = {
  url: string;
};

export const VideoPreviewPure = ({
  url,
}: VideoPreviewPureProps): JSX.Element => (
  <video width="150" controls preload="metadata">
    <source src={url} type="video/mp4" />
  </video>
);

const getAttachmentPreview = (attachment: BlobType): JSX.Element => {
  const fileName = parseFileName(attachment.filename);
  const handleDownload = () => downloadFileFromURL(attachment.s3URL, fileName);

  if (attachment.contentType.includes('audio/')) {
    return (
      <AudioPreviewPure
        url={attachment.s3URL}
        handleDownload={handleDownload}
      />
    );
  }

  if (attachment.contentType.includes('video/')) {
    return <VideoPreviewPure url={attachment.s3URL} />;
  }

  if (attachment.contentType.includes('image/')) {
    return (
      <ImagePreview url={attachment.s3URL} handleDownload={handleDownload} />
    );
  }

  return (
    <DocumentPreviewPure
      icon={getIconFromContentType(attachment.contentType)}
      handleDownload={handleDownload}
    />
  );
};

const getIconFromContentType = (contentType: string) => {
  if (
    [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(contentType)
  ) {
    return ExcelIcon;
  } else if (contentType === 'application/pdf') {
    return PdfIcon;
  } else if (
    [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ].includes(contentType)
  ) {
    return PowerpointIcon;
  } else if (
    [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ].includes(contentType)
  ) {
    return WordIcon;
  } else {
    return UnknownIcon;
  }
};

const formatFileName = (fileName: string): string => {
  const parts = fileName.split('/');
  return parts[parts.length - 1];
};

type AdminMessageViewerAttachmentsProps = {
  attachments: BlobType[];
};

const AdminMessageViewerAttachments = ({
  attachments,
}: AdminMessageViewerAttachmentsProps): JSX.Element | null => {
  const classes = useStyles();

  if (attachments.length === 0) {
    return null;
  }

  return (
    <Grid alignItems="center" className={classes.container} container>
      <Grid className={classes.label} item xs={12}>
        <FormattedMessage id="admin.messages.messageView.attachments" />
      </Grid>

      {attachments.map((_) => {
        const formattedFileName = formatFileName(_.filename);

        return (
          <Grid className={classes.item} item key={_.id}>
            {getAttachmentPreview(_)}

            <Tooltip title={formattedFileName}>
              <div className={classes.filename}>{formattedFileName}</div>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AdminMessageViewerAttachments;
