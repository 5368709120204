import { useContext } from 'react';
import { calendarContext } from './CalendarContext';
import CalendarUtils from '../../utils/CalendarUtils';
import { Moment } from 'moment';
import DayCell from './CalendarContent/DayCell';

type CalendarContentPureProps = {
  monthDates: Moment[][];
};

const CalendarContentPure = ({
  monthDates,
}: CalendarContentPureProps): JSX.Element => {
  const content = monthDates.map((week, weekIndex) => (
    <tr key={weekIndex}>
      {week.map((day, index) => (
        <DayCell date={day} key={index} />
      ))}
    </tr>
  ));

  return <>{content}</>;
};

const CalendarContent = (): JSX.Element => {
  const { currentDate } = useContext(calendarContext);
  const monthDates = CalendarUtils.getFullWeekMonthDates(currentDate);

  return <CalendarContentPure monthDates={monthDates} />;
};

export default CalendarContent;
