import { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useMessagesStyles from './Message.styles';
import useStyles from './Calling.styles';
import CallingImage0 from '../../../../../../images/Communicate/VideoCall/calling_0.png';
import CallingImage1 from '../../../../../../images/Communicate/VideoCall/calling_1.png';
import CallingImage2 from '../../../../../../images/Communicate/VideoCall/calling_2.png';
import CallingImage3 from '../../../../../../images/Communicate/VideoCall/calling_3.png';
import Image from '../../../../../../components/Image/Image';
import { VIDEO_CALL_CALLING_IMAGE_CHANGE_DELAY } from '../../../../../../utils/constants';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { getOtherParticipantFullName } from '../../../../../../types/videocall/VideoCall';
import useIsSmallScreen from '../../../../../../utils/useIsSmallScreen';

const CALLING_IMAGES = [
  CallingImage0,
  CallingImage1,
  CallingImage2,
  CallingImage3,
];

type CallingPureProps = {
  callingImage: string;
};

const CallingPure = ({ callingImage }: CallingPureProps): JSX.Element => {
  const { videoCall } = useContext(videoCallContext);
  const fullName = getOtherParticipantFullName(videoCall);
  const classes = useStyles();
  const messagesClasses = useMessagesStyles();
  const isSmallScreen = useIsSmallScreen();
  const imageHeight = isSmallScreen ? 150 : 200;

  return (
    <div className={messagesClasses.messageContainer}>
      <audio autoPlay loop src="/sounds/ring.mp3" />
      <FormattedMessage id="videoCall.calling" />
      <div className={classes.fullName}>{fullName}</div>
      <Image height={imageHeight} src={callingImage} />
    </div>
  );
};

const Calling = (): JSX.Element => {
  const [callingImageIndex, setCallingImageIndex] = useState<number>(0);
  const callingImage = CALLING_IMAGES[callingImageIndex];
  const handleCallingImageIndexChange = useCallback(() => {
    if (callingImageIndex < 3) {
      setCallingImageIndex(callingImageIndex + 1);
    } else {
      setCallingImageIndex(0);
    }
  }, [callingImageIndex, setCallingImageIndex]);

  useEffect(() => {
    const timeout = setTimeout(
      () => handleCallingImageIndexChange(),
      VIDEO_CALL_CALLING_IMAGE_CHANGE_DELAY,
    );
    return () => clearTimeout(timeout);
  });

  return <CallingPure callingImage={callingImage} />;
};

export default Calling;
