import { useContext } from 'react';
import { useUserContext } from '../../Context/UserContext';
import { getLinkLabel, linkHasAdminAccess, LinkType } from '../../types/Link';
import SimpleSelect, {
  SimpleSelectOptionType,
} from '../../components/Select/SimpleSelect';
import { ADMIN_LINK_CHANNEL_KEY } from '../../utils/Link';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import ChannelLinkLabel from './ChannelLinkLabel';
import useStyles from './AdminChannelLinkSelect.styles';
import { FormattedMessage } from 'react-intl';
import { trackEvent } from '../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../GoogleAnalytics/GAEvent';
import { tourContext } from '../../Context/TourContext';

type AdminChannelLinkSelectPureProps = {
  options: SimpleSelectOptionType<LinkType>[];
  value: string;
  handleChange: Function;
};

const AdminChannelLinkSelectPure = ({
  options,
  value,
  handleChange,
}: AdminChannelLinkSelectPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormattedMessage id="channelLink.adminManageTablet" />

      <SimpleSelect
        className={classes.select}
        options={options}
        value={value}
        handleChange={handleChange}
      />
    </div>
  );
};

const AdminChannelLinkSelect = (): JSX.Element | null => {
  const { webUser } = useUserContext();
  const { selectedLink, handleSelectedLinkChange } = useSelectedLinkContext();
  const { isTourOpen } = useContext(tourContext);
  const classes = useStyles();

  const webUserLinks = webUser.links.filter(
    (link) =>
      link.channelKey !== ADMIN_LINK_CHANNEL_KEY && linkHasAdminAccess(link),
  );

  if (isTourOpen) {
    return null;
  }

  if (webUserLinks.length === 0 || !selectedLink) {
    return (
      <ChannelLinkLabel
        label={
          <span className={classes.noWebUserLink}>
            <FormattedMessage id="channelLink.noWebUserLink" />
          </span>
        }
      />
    );
  }

  if (webUserLinks.length === 1) {
    return (
      <div className={classes.container}>
        <FormattedMessage id="channelLink.adminManageTablet" />
        &nbsp;
        <span className={classes.fullName}>{getLinkLabel(selectedLink)}</span>
      </div>
    );
  }

  const options = webUserLinks.map((link) => ({
    disabled: !link.metadata.subscriptionEnabled,
    fullValue: link,
    label: getLinkLabel(link),
    value: link.channelKey,
  }));

  const handleChange = (val: LinkType): void => {
    trackEvent(
      GAEventCategory.CHANNEL_LINK,
      GACommonEventAction.SELECT,
      'Sélectionner une tablette',
    );
    handleSelectedLinkChange({ isAdmin: true, newSelectedLink: val });
  };

  return (
    <AdminChannelLinkSelectPure
      options={options}
      value={selectedLink.channelKey}
      handleChange={handleChange}
    />
  );
};

export default AdminChannelLinkSelect;
