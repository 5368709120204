import { gql } from '@apollo/client';
import { RelationshipType } from '../../types/Relationship';

export const RELATIONSHIP_FRAGMENT = gql`
  fragment relationshipFields on Relationship {
    id
    channel
    created
    email
    state
    uuid
    mainUser {
      emails
    }
    user {
      familyAccess
      mainUserAccess
    }
  }
`;

export const GET_RELATIONSHIPS_QUERY = gql`
  query GetRelationships {
    relationships {
      relationships {
        ...relationshipFields
      }
    }
  }
  ${RELATIONSHIP_FRAGMENT}
`;

export type GetRelationshipsOutputType = {
  relationships: {
    relationships: RelationshipType[];
  };
};
