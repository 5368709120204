import { ReactNode, useEffect } from 'react';
import { useSelectedLinkContext } from '../../../../../../Context/SelectedLinkContext';
import { exitVideoCall } from '../../Actions';

type MessageWithTimeoutProps = {
  children?: ReactNode;
};

const MessageWithTimeout = ({
  children,
}: MessageWithTimeoutProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  useEffect(() => {
    exitVideoCall(selectedLink);
  });

  return <>{children}</>;
};

export default MessageWithTimeout;
