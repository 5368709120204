import { useCallback, useContext } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { COLORS } from '../../../../../../theme/MainTheme';
import useStyles from './ActionEventButton.styles';
import FilledButton from '../../../../../../components/Button/FilledButton';
import { organizerContext } from '../../../OrganizerContext';
import { useDayEventContext } from './DayEventContext';
import { isEvent, isEventOwner } from '../../../../../../types/event/Event';
import { useUserContext } from '../../../../../../Context/UserContext';

type DeleteEventButtonPureProps = {
  handleShowModal: () => void;
};

const DeleteEventButtonPure = ({
  handleShowModal,
}: DeleteEventButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FilledButton
      className={classes.button}
      color="blue"
      onClick={handleShowModal}
    >
      <DeleteIcon className={classes.icon} htmlColor={COLORS.WHITE} />
    </FilledButton>
  );
};

const DeleteEventButton = (): JSX.Element | null => {
  const { webUser } = useUserContext();
  const { dayEventOrTimeslot } = useDayEventContext();
  const { setEventToDelete } = useContext(organizerContext);

  const handleShowModal = useCallback(() => {
    if (isEvent(dayEventOrTimeslot)) {
      setEventToDelete(dayEventOrTimeslot);
    }
  }, [dayEventOrTimeslot, setEventToDelete]);

  if (
    isEvent(dayEventOrTimeslot) &&
    isEventOwner(dayEventOrTimeslot, webUser)
  ) {
    return <DeleteEventButtonPure handleShowModal={handleShowModal} />;
  }

  return null;
};

export default DeleteEventButton;
