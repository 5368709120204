import { SharedDocumentType } from '../../../../../types/document/Document';
import { StateSetter } from '../../../../../types/utils/React';

export const isDocumentSelected = (
  document: SharedDocumentType,
  documentsToDelete: SharedDocumentType[],
): boolean => !!documentsToDelete.find((_) => _.id === document.id);

export type GetDocumentItemSelectFunctionInputType = {
  document: SharedDocumentType;
  documentsToDelete: SharedDocumentType[];
  isDeleteMode: boolean;
  setDocumentsToDelete: StateSetter<SharedDocumentType[]>;
};

export const getDocumentItemSelectFunction =
  ({
    document,
    documentsToDelete,
    isDeleteMode,
    setDocumentsToDelete,
  }: GetDocumentItemSelectFunctionInputType) =>
  (): void => {
    if (isDeleteMode) {
      const selected = isDocumentSelected(document, documentsToDelete);

      if (selected) {
        const updatedDocumentsToDelete = documentsToDelete.filter(
          (_) => _.id !== document.id,
        );
        setDocumentsToDelete(updatedDocumentsToDelete);
      } else {
        setDocumentsToDelete([...documentsToDelete, document]);
      }
    }
  };
