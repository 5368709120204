import { useCallback, useContext } from 'react';
import { SharedDocumentType } from '../../../../../types/document/Document';
import DocumentPreview from '../../../../../components/DocumentPreview/DocumentPreview';
import useStyles from './DocumentItem.styles';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import { deleteModeContext } from './DeleteMode/DeleteModeContext';
import DocumentToDeleteOverlay from './DeleteMode/DocumentToDeleteOverlay';
import {
  getDocumentItemSelectFunction,
  isDocumentSelected,
} from './DocumentItemHelpers';

type DocumentItemPureProps = {
  document: SharedDocumentType;
  isDeleteMode: boolean;
  selected: boolean;
  handleDocumentItemSelect: () => void;
};

const DocumentItemPure = ({
  document,
  isDeleteMode,
  selected,
  handleDocumentItemSelect,
}: DocumentItemPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={clsx([
        classes.container,
        isDeleteMode && classes.selectingContainer,
      ])}
      onClick={handleDocumentItemSelect}
    >
      <Grid container>
        <Grid className={classes.imageContainer} item xs={12}>
          <div className={clsx([selected && classes.selectedDocumentPreview])}>
            <DocumentPreview document={document} />
          </div>

          <DocumentToDeleteOverlay selected={selected} />
        </Grid>

        <Grid className={classes.labelContainer} item xs={12}>
          {document.name}
        </Grid>
      </Grid>
    </div>
  );
};

type DocumentItemProps = {
  document: SharedDocumentType;
};

const DocumentItem = ({ document }: DocumentItemProps): JSX.Element => {
  const { documentsToDelete, isDeleteMode, setDocumentsToDelete } =
    useContext(deleteModeContext);

  const documentItemSelect = getDocumentItemSelectFunction({
    document,
    documentsToDelete,
    isDeleteMode,
    setDocumentsToDelete,
  });

  const handleDocumentItemSelect = useCallback(documentItemSelect, [
    document,
    documentItemSelect,
    documentsToDelete,
    isDeleteMode,
    setDocumentsToDelete,
  ]);

  const selected = isDocumentSelected(document, documentsToDelete);

  return (
    <DocumentItemPure
      document={document}
      isDeleteMode={isDeleteMode}
      selected={selected}
      handleDocumentItemSelect={handleDocumentItemSelect}
    />
  );
};

export default DocumentItem;
