import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop/dist/ReactCrop';
import { Crop } from 'react-image-crop';
import { StateSetter } from '../../types/utils/React';

export type ImageCropProps = {
  crop: Crop;
  src: string;
  setCrop: StateSetter<Crop>;
};

const ImageCrop = ({ crop, src, setCrop }: ImageCropProps): JSX.Element => (
  <ReactCrop
    imageStyle={{ maxHeight: 400, maxWidth: 712 }}
    src={src}
    crop={crop}
    onChange={(newCrop) => setCrop(newCrop)}
  />
);

export default ImageCrop;
