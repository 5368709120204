import { useState } from 'react';
import VideoCallImage from '../../../images/Communicate/SubCategories/video-call.png';
import { useSelectedLinkContext } from '../../../Context/SelectedLinkContext';
import { StateSetter } from '../../../types/utils/React';
import VideoCallSubCategoryDisabledOverlay from './VideoCallSubCategory/VideoCallSubCategoryDisabledOverlay';
import { LinkMetadataType } from '../../../types/Link';
import { VideoCallDisabledReasonsType } from '../../../types/videocall/VideoCallDisabledReasons';
import Image from '../../../components/Image/Image';
import { Grid } from '@mui/material';
import useStyles from './VideoCallSubCategory.styles';
import useSubCategoryStyles from '../../../components/SubCategory/SubCategory.styles';

type VideoCallSubCategoryPureProps = {
  disabledReason?: VideoCallDisabledReasonsType;
  showHoverComponent: boolean;
  handleVideoCallClick: Function;
  setShowHoverComponent: StateSetter<boolean>;
};

const VideoCallSubCategoryPure = ({
  disabledReason,
  showHoverComponent,
  handleVideoCallClick,
  setShowHoverComponent,
}: VideoCallSubCategoryPureProps): JSX.Element => {
  const classes = useStyles();
  const subCategoryClasses = useSubCategoryStyles();

  return (
    <Grid className={classes.container} item md={6} xs={12}>
      <Image
        alt="Visio"
        className={subCategoryClasses.subCategory}
        src={VideoCallImage}
        width="100%"
        onClick={() => handleVideoCallClick()}
        onMouseOver={() => setShowHoverComponent(true)}
      />

      <VideoCallSubCategoryDisabledOverlay
        disabledReason={disabledReason}
        showHoverComponent={showHoverComponent}
        onMouseLeave={() => setShowHoverComponent(false)}
      />
    </Grid>
  );
};

const getDisabledReason = (
  linkMetadata: LinkMetadataType | undefined,
): VideoCallDisabledReasonsType | undefined => {
  if (!linkMetadata?.hasMinimalAndroidVersionForVideoCall) {
    return VideoCallDisabledReasonsType.MINIMAL_ANDROID_VERSION;
  }

  if (!linkMetadata?.hasMinimalArdoizCoreVersionForVideoCall) {
    return VideoCallDisabledReasonsType.MINIMAL_ARDOIZ_CORE_VERSION;
  }
};

type VideoCallSubCategoryProps = {
  handleVideoCallClick: Function;
};

const VideoCallSubCategory = ({
  handleVideoCallClick,
}: VideoCallSubCategoryProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const [showHoverComponent, setShowHoverComponent] = useState<boolean>(false);
  const disabledReason = getDisabledReason(selectedLink?.metadata);

  return (
    <VideoCallSubCategoryPure
      disabledReason={disabledReason}
      showHoverComponent={showHoverComponent}
      handleVideoCallClick={handleVideoCallClick}
      setShowHoverComponent={setShowHoverComponent}
    />
  );
};

export default VideoCallSubCategory;
