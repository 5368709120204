import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  textField: {
    width: '50%',

    '& .MuiInputBase-root': {
      borderRadius: 8,
    },

    '& .Mui-focused .MuiInputBase-root': {
      borderSize: 1,
    },

    '& input': {
      fontSize: 20,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
