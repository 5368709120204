import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  subCategories: {
    [theme.breakpoints.up('md')]: {
      padding: 50,
    },
  },
}));
