import { useCallback, useContext, useState } from 'react';
import FilledButton from '../../../../../../components/Button/FilledButton';
import Image from '../../../../../../components/Image/Image';
import CameraOnImage from '../../../../../../images/Communicate/VideoCall/camera-on.png';
import CameraOffImage from '../../../../../../images/Communicate/VideoCall/camera-off.png';
import { videoCallContext } from '../../../../../../Context/Communicate/VideoCall/VideoCallContext';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './CommonButton.styles';
import { useControlToolbarContext } from '../../../../../../Context/Communicate/VideoCall/VideoContent/ControlToolbarContext';
import clsx from 'clsx';
import { trackEvent } from '../../../../../../GoogleAnalytics/GATracker';
import {
  GACommonEventAction,
  GAEventCategory,
} from '../../../../../../GoogleAnalytics/GAEvent';

type ToggleCameraButtonPureProps = {
  isCameraOn: boolean;
  small: boolean;
  toggleCamera: Function;
};

const ToggleCameraButtonPure = ({
  isCameraOn,
  small,
  toggleCamera,
}: ToggleCameraButtonPureProps): JSX.Element => {
  const classes = useStyles();
  const cameraImage = isCameraOn ? CameraOnImage : CameraOffImage;
  const label = isCameraOn ? (
    <FormattedMessage id="videoCall.activated.female" />
  ) : (
    <FormattedMessage id="videoCall.deactivated.female" />
  );
  const imageWidth = small ? 24 : 36;

  return (
    <Grid container direction="column">
      <Grid className={clsx(classes.label, small && 'small')} item>
        <FormattedMessage id="videoCall.camera" />
        <br />
        {label}
      </Grid>
      <Grid item>
        <FilledButton
          className={clsx(classes.button, small && 'small')}
          color="white"
          data-test-id="toggle-camera-button"
          onClick={(): void => toggleCamera()}
        >
          <Image src={cameraImage} width={imageWidth} />
        </FilledButton>
      </Grid>
    </Grid>
  );
};

const ToggleCameraButton = (): JSX.Element => {
  const { api } = useContext(videoCallContext);
  const [isCameraOn, setIsCameraOn] = useState<boolean>(true);
  const { small } = useControlToolbarContext();

  const toggleCamera = useCallback(() => {
    api.executeCommand('toggleVideo');
    trackEvent(
      GAEventCategory.VIDEO_CALL,
      GACommonEventAction.CLICK,
      isCameraOn ? 'Désactiver la caméra' : 'Réactiver la caméra',
    );
    setIsCameraOn(!isCameraOn);
  }, [api, isCameraOn, setIsCameraOn]);

  return (
    <ToggleCameraButtonPure
      isCameraOn={isCameraOn}
      small={small}
      toggleCamera={toggleCamera}
    />
  );
};

export default ToggleCameraButton;
