import PageContentContainer from '../components/PageContent/PageContentContainer';
import PageContentHeader from '../components/PageContent/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import DisconnectButton from './Profile/DisconnectButton';
import RelationshipManagement from './Profile/RelationshipManagement';
import OnBoardingReplay from './Profile/OnBoardingReplay';

const Profile = (): JSX.Element => (
  <PageContentContainer>
    <PageContentHeader title={<FormattedMessage id="profile.title" />} />

    <DisconnectButton />

    <RelationshipManagement />

    <OnBoardingReplay />
  </PageContentContainer>
);

export default Profile;
