import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  category: {
    transform: 'scale(0.95)',
    transition: 'all .2s ease-in-out',
    verticalAlign: 'bottom',
    width: '85%',

    '&:hover': {
      transform: 'scale(1)',
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
