import { useContext } from 'react';
import DayEventList from './DayContent/DayEventList';
import CreateEventButton from './DayContent/CreateEventButton';
import { organizerContext } from '../OrganizerContext';

const DayContent = (): JSX.Element | null => {
  const { dayContentMode } = useContext(organizerContext);

  if (dayContentMode !== undefined) {
    return null;
  }

  return (
    <>
      <DayEventList />
      <CreateEventButton />
    </>
  );
};

export default DayContent;
