import { createCtxWithMandatoryValue } from '../../../../../Context/utils';
import { PartnerAccountType } from '../../../../../types/partnerdocuments/PartnerAccount';
import { PartnerContentType } from '../../../../../types/partnerdocuments/PartnerDocument';
import { StateSetter } from '../../../../../types/utils/React';

interface PartnerPublicationsContextProps {
  contents: PartnerContentType[];
  partnerAccount: PartnerAccountType;
  setContents: StateSetter<PartnerContentType[]>;
  setSelectedContent: StateSetter<PartnerContentType | undefined>;
}

const partnerPublicationsContext =
    createCtxWithMandatoryValue<PartnerPublicationsContextProps>(),
  [usePartnerPublicationsContext, PartnerPublicationsContextProvider] =
    partnerPublicationsContext;

export { usePartnerPublicationsContext, PartnerPublicationsContextProvider };
