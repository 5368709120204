import { makeStyles } from '@mui/styles';
import { scrollContainer } from '../../../../../utils/Scroll';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxHeight: 430,
      ...scrollContainer,
    },
  },
}));
