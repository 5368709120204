import { useCallback, useContext } from 'react';
import { calendarContext } from '../CalendarContext';
import moment from 'moment';
import CalendarUtils from '../../../utils/CalendarUtils';
import useIsSmallScreen from '../../../utils/useIsSmallScreen';

type NextMonthPureProps = {
  label: string;
  handleNextMonthClick: () => void;
};

const NextMonthPure = ({
  label,
  handleNextMonthClick,
}: NextMonthPureProps): JSX.Element => (
  <div data-test-id="next-month-div" onClick={handleNextMonthClick}>
    {label}
  </div>
);

const NextMonth = (): JSX.Element => {
  const { currentDate, handleSelectedDateChange, setCurrentDate } =
    useContext(calendarContext);
  const isSmallScreen = useIsSmallScreen();
  const nextMonthDate = moment(currentDate).add(1, 'month');
  const label = CalendarUtils.formatDateToMonthAndYearString(nextMonthDate);

  const handleNextMonthClick = useCallback(() => {
    setCurrentDate(nextMonthDate);

    if (!isSmallScreen) {
      handleSelectedDateChange(nextMonthDate);
    }
  }, [isSmallScreen, nextMonthDate, handleSelectedDateChange, setCurrentDate]);

  return (
    <NextMonthPure label={label} handleNextMonthClick={handleNextMonthClick} />
  );
};

export default NextMonth;
