import React, { useCallback, useContext, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import Image from '../../../components/Image/Image';
import SettingsIcon from '../../../images/Share/folder_settings.svg';
import useStyles from '../../../views/App/Share/ExplorerToolbar/SettingsModal/OpenSettingsModalButton.styles';
import { SettingsContextProvider } from '../../../views/App/Share/ExplorerToolbar/SettingsModal/SettingsContext';
import DeleteOrQuitFolderConfirmationModal from '../../../views/App/Share/ExplorerToolbar/SettingsModal/DeleteOrQuitFolderConfirmationModal';
import CreateFolderModal from '../../../views/App/Share/ExplorerToolbar/CreateFolder/CreateFolderModal';
import { OpenCreateFolderModalButtonContextProvider } from '../../../views/App/Share/ExplorerToolbar/CreateFolder/OpenCreateFolderModalButtonContext';
import { shareContext } from '../../../views/App/Share/ShareContext';
import EditContactsModal from '../../../views/App/Share/ExplorerToolbar/EditContacts/EditContactsModal';
import { EditContactsContextProvider } from '../../../views/App/Share/ExplorerToolbar/EditContacts/EditContactsContext';

type MobileExplorerToolbarPureProps = {
  anchorEl?: HTMLElement;
  isMenuVisible: boolean;
  visibleComponents: JSX.Element[];
  handleCloseMenu: () => void;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
};

const MobileExplorerToolbarPure = ({
  anchorEl,
  isMenuVisible,
  visibleComponents,
  handleCloseMenu,
  handleOpenMenu,
}: MobileExplorerToolbarPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        data-test-id="open-settings-modal-button"
        onClick={handleOpenMenu}
      >
        <Image src={SettingsIcon} height={48} />
      </Button>

      <Menu anchorEl={anchorEl} open={isMenuVisible} onClose={handleCloseMenu}>
        {visibleComponents.map((component, index) => (
          <MenuItem disableRipple key={index} onClick={handleCloseMenu}>
            {component}
          </MenuItem>
        ))}
      </Menu>

      <DeleteOrQuitFolderConfirmationModal />
      <CreateFolderModal />
      <EditContactsModal />
    </>
  );
};

type MobileExplorerToolbarProps = {
  visibleComponents: JSX.Element[];
};

const MobileExplorerToolbar = ({
  visibleComponents,
}: MobileExplorerToolbarProps): JSX.Element => {
  const { selectedFolder } = useContext(shareContext);
  const [contacts, setContacts] = React.useState<string[]>(
    selectedFolder?.sharedWith.map((_) => _.id) || [],
  );
  const [showEditContactsModal, setShowEditContactsModal] =
    useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const isMenuVisible = Boolean(anchorEl);

  const [showDeleteOrQuitModal, setShowDeleteOrQuitModal] =
    useState<boolean>(false);

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = useCallback(
    () => setAnchorEl(undefined),
    [setAnchorEl],
  );

  return (
    <EditContactsContextProvider
      contacts={contacts}
      showEditContactsModal={showEditContactsModal}
      setContacts={setContacts}
      setShowEditContactsModal={setShowEditContactsModal}
    >
      <OpenCreateFolderModalButtonContextProvider
        showCreateFolderModal={showCreateFolderModal}
        setShowCreateFolderModal={setShowCreateFolderModal}
      >
        <SettingsContextProvider
          showDeleteOrQuitModal={showDeleteOrQuitModal}
          showSettingsModal={showSettingsModal}
          setShowDeleteOrQuitModal={setShowDeleteOrQuitModal}
          setShowSettingsModal={setShowSettingsModal}
        >
          <MobileExplorerToolbarPure
            anchorEl={anchorEl}
            isMenuVisible={isMenuVisible}
            visibleComponents={visibleComponents}
            handleCloseMenu={handleCloseMenu}
            handleOpenMenu={handleOpenMenu}
          />
        </SettingsContextProvider>
      </OpenCreateFolderModalButtonContextProvider>
    </EditContactsContextProvider>
  );
};

export default MobileExplorerToolbar;
