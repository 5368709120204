import { createCtxWithMandatoryValue } from '../../../utils';

interface ControlToolbarContextProps {
  containerRef: React.RefObject<HTMLDivElement>;
  small: boolean;
}

const userContext = createCtxWithMandatoryValue<ControlToolbarContextProps>(),
  [useControlToolbarContext, ControlToolbarContextProvider] = userContext;

export { useControlToolbarContext, ControlToolbarContextProvider };
