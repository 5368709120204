import { ReactNode } from 'react';
import {
  EventOrTimeslotType,
  isEvent,
  isEventOwner,
} from '../../../../../types/event/Event';
import { FormattedMessage } from 'react-intl';
import useStyles from './DayEvent.styles';
import { ClassNameMap, Grid } from '@mui/material';
import clsx from 'clsx';
import CalendarUtils from '../../../../../utils/CalendarUtils';
import moment from 'moment';
import { DayEventContextProvider } from './DayEvent/DayEventContext';
import EditOrViewEventButton from './DayEvent/EditOrViewEventButton';
import DeleteEventButton from './DayEvent/DeleteEventButton';
import AlarmLabel from './DayEvent/AlarmLabel';
import { AlarmEnum } from '../../../../../types/organize/Organizer';
import { useUserContext } from '../../../../../Context/UserContext';
import { WebUserType } from '../../../../../types/User';

type DayEventPureProps = {
  alarm: AlarmEnum;
  eventClassName: string;
  hour: ReactNode;
  label: ReactNode;
};

const DayEventPure = ({
  alarm,
  eventClassName,
  hour,
  label,
}: DayEventPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid item xs={10}>
        <div
          className={clsx(classes.event, eventClassName)}
          data-test-id="day-event-color-div"
        />

        <div className={classes.eventLabel} data-test-id="day-event-label-div">
          {label}
        </div>

        <div className={classes.eventHour} data-test-id="day-event-hour-div">
          {hour}
        </div>

        <AlarmLabel alarm={alarm} />
      </Grid>

      <Grid item xs={2}>
        <Grid container>
          <Grid className={classes.editButtonContainer} item xs={12}>
            <EditOrViewEventButton />
          </Grid>
          <Grid item xs={12}>
            <DeleteEventButton />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

type GetLabelOutput = {
  eventClassName: string;
  label: ReactNode;
};

const getLabel = (
  currentWebUser: WebUserType,
  eventOrTimeslot: EventOrTimeslotType,
  classes: ClassNameMap,
): GetLabelOutput => {
  if (isEvent(eventOrTimeslot)) {
    if (isEventOwner(eventOrTimeslot, currentWebUser)) {
      return {
        eventClassName: classes.ownedEvent,
        label: eventOrTimeslot.name,
      };
    } else {
      return {
        eventClassName: classes.guestEvent,
        label: eventOrTimeslot.name,
      };
    }
  } else {
    return {
      eventClassName: classes.busyEvent,
      label: <FormattedMessage id="busy" />,
    };
  }
};

// Used to fix not precise durations (0.99752 hour for example)
export const roundDuration = (value: number): number => {
  const rest = value % 1;

  if (rest < 0.01 || rest > 0.99) {
    return Math.round(value);
  } else {
    return Math.floor(value);
  }
};

export const getDuration = (duration: number): string => {
  const durationHours = roundDuration(duration / 1000 / 3600);
  const durationMinutes = roundDuration(duration / 1000 / 60);
  const roundedDuration = durationMinutes * 1000 * 60;
  const durationMoment = moment.utc(roundedDuration);

  if (durationHours < 1) {
    // Less than 1 hour => only display minutes
    return durationMoment.format('m[min]');
  } else if (durationMinutes % 60 === 0) {
    // More or equals 1 hour without minutes => only display hours
    return durationMoment.format('H[h]');
  } else {
    return durationMoment.format('H[h]mm[min]');
  }
};

export const getEventHourLabel = (
  eventOrTimeslot: EventOrTimeslotType,
): ReactNode => {
  if (isEvent(eventOrTimeslot) && eventOrTimeslot.isAllDay) {
    return <FormattedMessage id="event.all_day" />;
  }

  const beginHour = CalendarUtils.formatDateToHourString(
    moment(eventOrTimeslot.date),
  );
  const duration = getDuration(eventOrTimeslot.duration);

  return `${beginHour} - ${duration}`;
};

type DayEventProps = {
  eventOrTimeslot: EventOrTimeslotType;
};

const DayEvent = ({ eventOrTimeslot }: DayEventProps): JSX.Element => {
  const { webUser } = useUserContext();
  const classes = useStyles();
  const { eventClassName, label } = getLabel(webUser, eventOrTimeslot, classes);

  const hour = getEventHourLabel(eventOrTimeslot);
  const alarm = isEvent(eventOrTimeslot)
    ? eventOrTimeslot.alarm
    : AlarmEnum.NO_ALARM;

  return (
    <DayEventContextProvider value={{ dayEventOrTimeslot: eventOrTimeslot }}>
      <DayEventPure
        alarm={alarm}
        eventClassName={eventClassName}
        hour={hour}
        label={label}
      />
    </DayEventContextProvider>
  );
};

export default DayEvent;
