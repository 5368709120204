import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import useStyles from './AlarmLabel.styles';
import { AlarmEnum } from '../../../../../../types/organize/Organizer';
import { FormattedMessage } from 'react-intl';

type AlarmLabelPureProps = {
  messageId: string;
};

const AlarmLabelPure = ({ messageId }: AlarmLabelPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <NotificationsActiveIcon className={classes.icon} />

      <span className={classes.label} data-test-id="alarm-label">
        <FormattedMessage id={messageId} />
      </span>
    </div>
  );
};

const getMessageId = (alarm: AlarmEnum): string => {
  switch (alarm) {
    case AlarmEnum.BEFORE_15_MIN:
      return 'alarm.BEFORE_15_MIN';
    case AlarmEnum.BEFORE_30_MIN:
      return 'alarm.BEFORE_30_MIN';
    case AlarmEnum.BEFORE_60_MIN:
      return 'alarm.BEFORE_60_MIN';
    case AlarmEnum.ON_TIME:
      return 'alarm.ON_TIME';
    default:
      return '';
  }
};

type AlarmLabelProps = {
  alarm: AlarmEnum;
};

const AlarmLabel = ({ alarm }: AlarmLabelProps): JSX.Element | null => {
  if (alarm === AlarmEnum.NO_ALARM) {
    return null;
  }

  const messageId = getMessageId(alarm);

  return <AlarmLabelPure messageId={messageId} />;
};

export default AlarmLabel;
