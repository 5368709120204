import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import FilledButton from '../../../../../components/Button/FilledButton';
import { postcardContext } from '../../../../../Context/Communicate/Postcard/PostcardContext';

type SendPostcardButtonPureProps = {
  className: string;
  disabled: boolean;
  messageId: string;
  sendPostcard: Function;
};

const SendPostcardButtonPure = ({
  className,
  disabled,
  messageId,
  sendPostcard,
}: SendPostcardButtonPureProps): JSX.Element => (
  <FilledButton
    className={className}
    color="violet"
    data-test-id="send-button"
    disabled={disabled}
    onClick={(): void => sendPostcard()}
  >
    <FormattedMessage id={messageId} />
  </FilledButton>
);

type SendPostcardButtonProps = {
  canSendPostcard: boolean;
  className: string;
  sendPostcard: Function;
};

const SendPostcardButton = ({
  canSendPostcard,
  className,
  sendPostcard,
}: SendPostcardButtonProps): JSX.Element => {
  const { sending } = useContext(postcardContext);

  const messageId = sending ? 'button.sending' : 'button.send';

  return (
    <SendPostcardButtonPure
      className={className}
      disabled={!canSendPostcard || sending}
      messageId={messageId}
      sendPostcard={sendPostcard}
    />
  );
};

export default SendPostcardButton;
