import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../theme/MainTheme';
import { Theme } from '@mui/material';
import { scrollContainer } from '../../../utils/Scroll';

export default makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    paddingTop: 10,
  },
  explorerContainer: {
    backgroundColor: COLORS.LIGHT_VIOLET,
    borderRadius: 16,
    height: '100%',
    lineHeight: '30px',

    padding: 10,
  },
  folderItemContainer: {
    display: 'inline-flex',
    margin: 20,
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      margin: 10,
    },
  },
  folderItemsContainer: {
    height: 'calc(100% - 40px)',
    padding: 20,
  },
  scrollContainer,
}));
