import { ReactNode } from 'react';
import { Button, ClassNameMap } from '@mui/material';
import useStyles from './FilledButton.styles';
import clsx from 'clsx';

type FilledButtonPureProps = {
  children: ReactNode;
  className: string;
  'data-test-id'?: string;
  disabled?: boolean;
  size?: SizeType;
  onClick: Function;
};

const FilledButtonPure = ({
  children,
  className,
  disabled,
  size,
  onClick,
  ...props
}: FilledButtonPureProps): JSX.Element => (
  <Button
    className={className}
    data-test-id={props['data-test-id'] || 'filled-button'}
    disabled={disabled}
    size={size}
    variant="contained"
    onClick={(e): void => onClick(e)}
  >
    {children}
  </Button>
);

type ColorsType = 'blue' | 'green' | 'lightred' | 'red' | 'violet' | 'white';

type SizeType = 'small' | 'medium' | 'large';

export type FilledButtonProps = {
  children: ReactNode;
  className?: string;
  color: ColorsType;
  'data-test-id'?: string;
  disabled?: boolean;
  size?: SizeType;
  onClick: Function;
};

const getClassNameFromColor = (
  classes: ClassNameMap,
  color: ColorsType,
): string => {
  switch (color) {
    case 'blue':
      return classes.blue;
    case 'green':
      return classes.green;
    case 'lightred':
      return classes.lightred;
    case 'red':
      return classes.red;
    case 'violet':
      return classes.violet;
    case 'white':
      return classes.white;
    default:
      return '';
  }
};

const FilledButton = ({
  children,
  className,
  color,
  size,
  ...props
}: FilledButtonProps): JSX.Element => {
  const classes = useStyles();
  const computedSize = size || 'medium';

  return (
    <FilledButtonPure
      className={clsx(
        classes.button,
        getClassNameFromColor(classes, color),
        className,
      )}
      size={computedSize}
      {...props}
    >
      {children}
    </FilledButtonPure>
  );
};

export default FilledButton;
