import { FormattedMessage } from 'react-intl';

type RelationshipSuccessMessageProps = {
  className: string;
  show: boolean;
};

export const RelationshipSuccessMessage = ({
  className,
  show,
}: RelationshipSuccessMessageProps): JSX.Element | null => {
  if (!show) {
    return null;
  }

  return (
    <div className={className}>
      <FormattedMessage id="relationship.success" />
    </div>
  );
};
