import { useCallback, useContext } from 'react';
import { imageViewerContext } from './ImageViewerContext';
import { Button, Grid } from '@mui/material';
import useStyles from './CloseButton.styles';
import Image from '../Image/Image';
import CloseImage from '../../images/components/ImageViewer/close.svg';
import { FormattedMessage } from 'react-intl';

const CloseButton = (): JSX.Element => {
  const { handleCurrentViewedImage } = useContext(imageViewerContext);
  const classes = useStyles();

  const handleClose = useCallback(
    () => handleCurrentViewedImage(undefined),
    [handleCurrentViewedImage],
  );

  return (
    <Button
      className={classes.button}
      data-test-id="close-button"
      onClick={handleClose}
    >
      <Grid container>
        <Grid item xs={12}>
          <Image src={CloseImage} width={40} />
        </Grid>
        <Grid className={classes.label} item xs={12}>
          <FormattedMessage id="button.close" />
        </Grid>
      </Grid>
    </Button>
  );
};

export default CloseButton;
