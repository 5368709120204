import { MouseEventHandler, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import LeftArrowIcon from '../../../../images/Share/ShareExplorer/left-arrow.png';
import Image from '../../../../components/Image/Image';
import useStyles from './BackButton.styles';
import { shareContext } from '../ShareContext';

type BackButtonPureProps = {
  onClick: MouseEventHandler;
};

const BackButtonPure = ({ onClick }: BackButtonPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      data-test-id="back-button"
      onClick={onClick}
    >
      <Image className={classes.image} src={LeftArrowIcon} />{' '}
      <FormattedMessage id="button.back" />
    </Button>
  );
};

const BackButton = (): JSX.Element => {
  const { setSelectedFolder } = useContext(shareContext);
  return <BackButtonPure onClick={() => setSelectedFolder(undefined)} />;
};

export default BackButton;
