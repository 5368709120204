import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  cancelButton: {
    fontSize: 11,
    position: 'absolute',
    right: 10,
    textTransform: 'capitalize',
    top: 25,

    [theme.breakpoints.down('md')]: {
      top: 10,
    },
  },
  container: {
    padding: '10px 25px',
  },
  field: {
    color: COLORS.BLUE,
    width: '100%',

    '& .MuiOutlinedInput-root, &.MuiOutlinedInput-root, &.MuiOutlinedInput-root.Mui-disabled':
      {
        '& input, & .MuiSelect-select': {
          color: COLORS.BLUE,
          '-webkit-text-fill-color': 'inherit',
        },
        '& fieldset, & fieldset.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${COLORS.BLUE}`,
        },
        '&:hover fieldset': {
          border: `1px solid ${COLORS.BLUE}`,
        },
        '&.Mui-focused fieldset': {
          border: `1px solid ${COLORS.BLUE}`,
        },
        '& .MuiSvgIcon-root': {
          color: COLORS.BLUE,
        },
      },

    '& .MuiOutlinedInput-root.Mui-error, &.MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        border: `1px solid ${COLORS.DARK_RED}`,
      },
      '&:hover fieldset': {
        border: `1px solid ${COLORS.DARK_RED}`,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${COLORS.DARK_RED}`,
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  mandatoryFieldsLabel: {
    color: COLORS.BLUE,
    fontSize: 11,
    paddingTop: 10,
  },
  saveButton: {
    backgroundColor: COLORS.BLUE,
    display: 'inline-block',
    marginTop: 30,
    textTransform: 'lowercase',
    width: '100%',

    '&:first-letter': {
      textTransform: 'uppercase',
    },

    '&:hover': {
      backgroundColor: COLORS.BLUE,
    },
  },
}));
