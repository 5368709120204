import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  fullName: {
    fontSize: 32,
    fontWeight: 'bold',
    padding: 50,

    '@media (max-width: 1199px)': {
      fontSize: 30,
    },
  },
  messageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 30,
    height: '100%',
    justifyContent: 'center',
  },
}));
