import { makeStyles } from '@mui/styles';
import { adminFontFamily, COLORS } from '../../../../../theme/MainTheme';

export default makeStyles(() => ({
  container: {
    textAlign: 'center',
  },
  goToButton: {
    marginTop: 5,
    width: 20,
  },
  pageNumber: {
    color: COLORS.GREY,
    cursor: 'pointer',
    fontFamily: adminFontFamily,
    fontSize: 16,
    fontWeight: 500,
    padding: 10,
    verticalAlign: 'middle',
  },
  selectedPageNumber: {
    color: COLORS.BLACK,
    cursor: 'initial',
    fontSize: 20,
    fontWeight: 'bold',
  },
}));
