import { useState } from 'react';
import { usePartnerPublicationsContext } from './PartnerPublicationsContext';
import { useQuery } from '@apollo/client';
import {
  GET_PARTNER_DOCUMENTS_QUERY,
  GetPartnerDocumentsOutputType,
} from '../../../../../graphql/partnerdocuments/PartnerDocumentsQueries';
import { useSelectedLinkContext } from '../../../../../Context/SelectedLinkContext';
import PartnerListContainer from './PartnerListContainer';
import { Grid } from '@mui/material';
import { PartnerDocumentType } from '../../../../../types/partnerdocuments/PartnerDocument';
import {
  filterImageDocument,
  sortContentsByPublicationStartDate,
} from '../../../../../utils/Document';
import PartnerDocumentItem from './PartnerDocument/PartnerDocumentItem';
import { ImageViewerContextProvider } from '../../../../../components/ImageViewer/ImageViewerContext';
import { SharedDocumentType } from '../../../../../types/document/Document';
import ImageViewer from '../../../../../components/ImageViewer/ImageViewer';

type PartnerDocumentListPureProps = {
  documents: PartnerDocumentType[];
};

const PartnerDocumentListPure = ({
  documents,
}: PartnerDocumentListPureProps): JSX.Element => (
  <PartnerListContainer titleMessageId="partner.publications.document.title">
    <Grid container spacing={3}>
      {documents.map((_) => (
        <Grid item key={_.id}>
          <PartnerDocumentItem document={_} />
        </Grid>
      ))}
    </Grid>

    <ImageViewer />
  </PartnerListContainer>
);

const PartnerDocumentList = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { partnerAccount } = usePartnerPublicationsContext();
  const [currentViewedImage, setCurrentViewedImage] =
    useState<SharedDocumentType>();

  const { data, loading } = useQuery<GetPartnerDocumentsOutputType>(
    GET_PARTNER_DOCUMENTS_QUERY,
    {
      variables: {
        getPartnerDocumentsInput: {
          partnerId: partnerAccount.id,
          tabletChannel: selectedLink?.channelKey,
        },
      },
    },
  );

  if (loading) {
    return null;
  }

  const documents = data?.getPartnerDocuments.documents || [];
  const filteredAndSortedDocuments =
    sortContentsByPublicationStartDate<PartnerDocumentType>(
      documents.filter((document) => !!document.blob?.s3URL),
    );
  const imageDocuments = filterImageDocument(filteredAndSortedDocuments);

  return (
    <ImageViewerContextProvider
      currentViewedImage={currentViewedImage}
      imageDocuments={imageDocuments}
      setCurrentViewedImage={setCurrentViewedImage}
    >
      <PartnerDocumentListPure documents={filteredAndSortedDocuments} />
    </ImageViewerContextProvider>
  );
};

export default PartnerDocumentList;
