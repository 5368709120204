import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    height: '90%',
    left: '15%',
    position: 'absolute',
    top: '5%',
    width: '70%',

    [theme.breakpoints.down('md')]: {
      height: '100%',
      left: 0,
      top: 0,
      width: '100%',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  image: {
    left: '50%',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
