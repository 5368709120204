import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { scrollContainer } from '../../../../../../../utils/Scroll';

export default makeStyles((theme: Theme) => ({
  content: {
    height: '100%',
    marginTop: 30,
    overflow: 'auto',

    [theme.breakpoints.up('md')]: {
      ...scrollContainer,
    },
  },
  publicationDate: {
    float: 'right',
  },
  thumbnail: {
    maxHeight: 200,

    [theme.breakpoints.up('md')]: {
      float: 'left',
      maxWidth: '50%',
      padding: '20px 20px 20px 0',
    },
  },
  title: {
    float: 'left',
    fontSize: 24,
    fontWeight: 'bold',
  },
}));
