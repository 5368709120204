import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: 30,

    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  gridItem: {
    textAlign: 'right',
  },
  profileContainer: {
    float: 'right',
  },
  profileText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));
