import SubCategory from '../../components/SubCategory/SubCategory';
import TeamviewerImage from '../../images/Help/teamviewer.png';
import DocumentsImage from '../../images/Help/documents.png';
import PartnerImage from '../../images/Help/partner.svg';
import { getGoToSelectedChannelPageFunction } from '../../utils/history';
import SubCategories from '../../components/SubCategory/SubCategories';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelectedLinkContext } from '../../Context/SelectedLinkContext';
import Route from '../../routes/Route';
import { GridSize } from '@mui/material/Grid/Grid';
import { useQuery } from '@apollo/client';
import {
  GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY,
  GetPartnerAccountsWithFamilyPortalOutputType,
} from '../../graphql/partnerdocuments/PartnerDocumentsQueries';
import { PartnerAccountType } from '../../types/partnerdocuments/PartnerAccount';

export type HelpPureProps = {
  displayPartnerSection: boolean;
  subCategorySize: GridSize;
};

export const HelpPure = ({
  displayPartnerSection,
  subCategorySize,
}: HelpPureProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const intl = useIntl();

  return (
    <SubCategories title={<FormattedMessage id="category.help.description" />}>
      <SubCategory
        alt={intl.formatMessage({ id: 'category.help.teamviewer' })}
        image={TeamviewerImage}
        subCategorySize={subCategorySize}
        onClick={getGoToSelectedChannelPageFunction(
          selectedLink,
          Route.TEAMVIEWER,
        )}
      />

      <SubCategory
        alt={intl.formatMessage({ id: 'category.help.documents.description' })}
        enableEvenIfNoSelectedLink
        image={DocumentsImage}
        subCategorySize={subCategorySize}
        onClick={getGoToSelectedChannelPageFunction(
          selectedLink,
          Route.HELP_DOCUMENTS,
        )}
      />

      {displayPartnerSection && (
        <SubCategory
          alt={intl.formatMessage({
            id: 'partner.description',
          })}
          image={PartnerImage}
          subCategorySize={subCategorySize}
          onClick={getGoToSelectedChannelPageFunction(
            selectedLink,
            Route.PARTNER_LIST,
          )}
        />
      )}
    </SubCategories>
  );
};

type GetSubCategoriesPropsOutputType = {
  displayPartnerSection: boolean;
  subCategorySize: GridSize;
};

export const getHelpPureProps = (
  partnerAccounts: PartnerAccountType[] | undefined,
): GetSubCategoriesPropsOutputType => {
  if (partnerAccounts?.length) {
    return {
      displayPartnerSection: true,
      subCategorySize: 4,
    };
  } else {
    return {
      displayPartnerSection: false,
      subCategorySize: 6,
    };
  }
};

const Help = (): JSX.Element | null => {
  const { selectedLink } = useSelectedLinkContext();
  const { data, loading } =
    useQuery<GetPartnerAccountsWithFamilyPortalOutputType>(
      GET_PARTNER_ACCOUNTS_WITH_FAMILY_PORTAL_QUERY,
      {
        skip: !selectedLink,
        variables: {
          channel: selectedLink?.channelKey,
        },
      },
    );

  if (loading) {
    return null;
  }

  const partnerAccounts =
    data?.getPartnerAccountsWithFamilyPortal?.partnerAccounts;

  const subCategoriesProps = getHelpPureProps(partnerAccounts);

  return <HelpPure {...subCategoriesProps} />;
};

export default Help;
