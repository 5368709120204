import { useCallback, useContext } from 'react';
import { Button } from '@mui/material';
import useStyles from './SwitcherButton.styles';
import { shareContext } from '../ShareContext';
import { FileTypeSwitcherFileTypes } from '../../../../types/share/FileTypeSwitcherFileTypes';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { deleteModeContext } from '../ShareExplorer/FolderContentExplorer/DeleteMode/DeleteModeContext';

export enum SwitcherButtonColorType {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}

type GetBackgroundClassNameOutputType =
  | 'green'
  | 'green-selected'
  | 'red'
  | 'red-selected'
  | 'yellow'
  | 'yellow-selected';

const getBackgroundClassName = (
  color: SwitcherButtonColorType,
  selected: boolean,
): GetBackgroundClassNameOutputType => {
  return selected ? `${color}-selected` : color;
};

export type SwitcherButtonProps = {
  color: SwitcherButtonColorType;
  dataTestId: string;
  fileType: FileTypeSwitcherFileTypes;
  labelId: string;
};

const SwitcherButton = ({
  color,
  dataTestId,
  fileType,
  labelId,
}: SwitcherButtonProps): JSX.Element => {
  const { selectedFileType, setSelectedFileType, setSelectedFolder } =
    useContext(shareContext);
  const { isDeleteMode } = useContext(deleteModeContext);
  const selected = fileType === selectedFileType;
  const classes = useStyles();
  const backgroundClassName = classes[getBackgroundClassName(color, selected)];

  const handleSwitchFileType = useCallback(() => {
    if (!isDeleteMode) {
      setSelectedFileType(fileType);
      setSelectedFolder(undefined);
    }
  }, [fileType, isDeleteMode, setSelectedFileType, setSelectedFolder]);

  return (
    <Button
      className={clsx(classes.button, backgroundClassName)}
      data-test-id={dataTestId}
      onClick={() => handleSwitchFileType()}
    >
      <FormattedMessage id={labelId} />
    </Button>
  );
};

export default SwitcherButton;
