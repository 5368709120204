import { useCallback, useEffect, useState } from 'react';
import { PartnerContentType } from '../../../../../../../types/partnerdocuments/PartnerDocument';
import Interweave from 'interweave';
import { Paper } from '@mui/material';
import { getPartnerContentContentFromS3 } from '../../../../../../../utils/Document';
import useStyles from './PartnerContentArticleListItem.styles';
import { usePartnerContentContext } from '../PartnerContentContext';
import { getTextFormattedDate } from '../../../../../../../utils/Date';

type PartnerContentArticleListItemPureProps = {
  preview?: string;
  publicationDate: string;
  title: string;
  handleContentClick: () => void;
};

const PartnerContentArticleListItemPure = ({
  preview,
  publicationDate,
  title,
  handleContentClick,
}: PartnerContentArticleListItemPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.container}
      data-test-id="partner-content-article-list-item"
      onClick={handleContentClick}
    >
      <div className={classes.title}>{title}</div>

      <div className={classes.publicationDate}>{publicationDate}</div>

      <div
        className={classes.preview}
        data-test-id="partner-content-article-list-item-preview"
      >
        <Interweave content={preview} noHtml />
      </div>
    </Paper>
  );
};

type PartnerContentArticleListItemProps = {
  content: PartnerContentType;
};

const PartnerContentArticleListItem = ({
  content,
}: PartnerContentArticleListItemProps): JSX.Element => {
  const { setSelectedContent } = usePartnerContentContext();
  const [preview, setPreview] = useState<string | undefined>(content?.content);
  const s3URL = content?.blob?.s3URL;

  useEffect(() => {
    if (!preview) {
      getPartnerContentContentFromS3({
        contentId: content.id,
        s3URL,
        callback: setPreview,
      });
    }
  }, [content.id, s3URL, preview, setPreview]);

  const handleContentClick = useCallback(
    () => setSelectedContent(content),
    [content, setSelectedContent],
  );

  const publicationDate = getTextFormattedDate(content.publicationStartDate);

  return (
    <PartnerContentArticleListItemPure
      preview={preview}
      publicationDate={publicationDate}
      title={content.title}
      handleContentClick={handleContentClick}
    />
  );
};

export default PartnerContentArticleListItem;
