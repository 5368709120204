import { ReactNode } from 'react';
import { UseStylesProps } from '../../utils/useStyles';
import useStyles from './ChannelLinkLabel.styles';
import Image from '../../components/Image/Image';
import TabletImage from '../../images/ChannelLink/tablet.png';

type ChannelLinkLabelPureProps = UseStylesProps & ChannelLinkLabelProps;

const ChannelLinkLabelPure = ({
  classes,
  label,
}: ChannelLinkLabelPureProps): JSX.Element => (
  <div className={classes.label}>
    <Image
      alt="Tablette Ardoiz"
      className={classes.tabletImage}
      src={TabletImage}
    />
    {label}
  </div>
);

type ChannelLinkLabelProps = {
  label: ReactNode;
};

const ChannelLinkLabel = ({ label }: ChannelLinkLabelProps): JSX.Element => {
  const classes = useStyles();
  return <ChannelLinkLabelPure classes={classes} label={label} />;
};

export default ChannelLinkLabel;
