import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  button: {
    right: '5%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));
