import { FormattedMessage } from 'react-intl';
import useStyles from './AdminMessagesContainer.styles';
import { ReactNode } from 'react';
import { useSelectedLinkContext } from '../../../../Context/SelectedLinkContext';

type AdminMessagesContainerProps = {
  children: ReactNode;
};

const AdminMessagesContainer = ({
  children,
}: AdminMessagesContainerProps): JSX.Element => {
  const { selectedLink } = useSelectedLinkContext();
  const { firstName, lastName } = selectedLink.mainUser;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <FormattedMessage
          id="admin.messages.title"
          values={{ firstName, lastName }}
        />
      </div>

      {children}
    </div>
  );
};

export default AdminMessagesContainer;
