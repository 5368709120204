import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { COLORS } from '../../../theme/MainTheme';

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: COLORS.DARKER_BLUE,
    padding: '10px 30px',

    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  profileText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));
