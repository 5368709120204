import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../theme/MainTheme';

export default makeStyles(() => ({
  button: {
    color: COLORS.VIOLET,
    fontSize: 16,
    fontWeight: 'bold',
    padding: 0,
    marginLeft: 20,
    textTransform: 'none',
  },
  image: {
    paddingRight: 5,
  },
}));
