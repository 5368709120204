import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  label: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  tabletImage: {
    paddingRight: 10,
    verticalAlign: 'middle',
  },
}));
