import { MessageType } from '../../../../types/Message';
import PaperClipIcon from '../../../../images/icons/paperclip.svg';

import Image from '../../../../components/Image/Image';

type AttachmentIconProps = {
  message: MessageType;
  small?: boolean;
};

const AttachmentIcon = ({
  message,
  small,
}: AttachmentIconProps): JSX.Element | null => {
  if (message.hasAttachment) {
    return <Image src={PaperClipIcon} width={small ? 15 : 20} />;
  } else {
    return null;
  }
};

export default AttachmentIcon;
