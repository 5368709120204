import { ReactNode, useCallback, useContext } from 'react';
import { AlarmEnum } from '../../../../../types/organize/Organizer';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { eventFormContext } from './EventFormContext';
import { FormattedMessage } from 'react-intl';
import useStyles from '../EventForm.styles';

type AlarmSelectPureProps = {
  alarm: AlarmEnum;
  alarms: AlarmSelectItemType[];
  disabled: boolean;
  handleAlarmChange: (event: SelectChangeEvent<AlarmEnum>) => void;
};

const AlarmSelectPure = ({
  alarm,
  alarms,
  disabled,
  handleAlarmChange,
}: AlarmSelectPureProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Select
      className={classes.field}
      data-test-id="folder-contacts-select"
      disabled={disabled}
      size="small"
      value={alarm}
      onChange={handleAlarmChange}
    >
      {alarms.map((alarmItem) => (
        <MenuItem key={alarmItem.value} value={alarmItem.value}>
          {alarmItem.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export type AlarmSelectItemType = {
  label: ReactNode;
  value: AlarmEnum;
};

const getAlarms = (): AlarmSelectItemType[] => [
  {
    label: <FormattedMessage id="alarm.NO_ALARM" />,
    value: AlarmEnum.NO_ALARM,
  },
  { label: <FormattedMessage id="alarm.ON_TIME" />, value: AlarmEnum.ON_TIME },
  {
    label: <FormattedMessage id="alarm.BEFORE_15_MIN" />,
    value: AlarmEnum.BEFORE_15_MIN,
  },
  {
    label: <FormattedMessage id="alarm.BEFORE_30_MIN" />,
    value: AlarmEnum.BEFORE_30_MIN,
  },
  {
    label: <FormattedMessage id="alarm.BEFORE_60_MIN" />,
    value: AlarmEnum.BEFORE_60_MIN,
  },
];

const AlarmSelect = (): JSX.Element => {
  const { alarm, disabled, setAlarm } = useContext(eventFormContext);

  const handleAlarmChange = useCallback(
    (event: SelectChangeEvent<AlarmEnum>) => {
      const alarmValue: AlarmEnum = event.target.value as AlarmEnum;

      return setAlarm(alarmValue);
    },
    [setAlarm],
  );

  const alarms = getAlarms();

  return (
    <AlarmSelectPure
      alarm={alarm}
      alarms={alarms}
      disabled={disabled}
      handleAlarmChange={handleAlarmChange}
    />
  );
};

export default AlarmSelect;
